.Share-Draft {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.Warrants-conatiner {
  border: 1px solid #eaedf3;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.search-input {
  padding: 10px;
  border: none;
  outline: none;
  font-size: 17px;
  border: 1px solid grey;
}

.custom-class {
  margin-bottom: 50px;
  width: 200px;
  margin-left: 10px;
}

.searchbox-Draft {
  padding: 10px;
  flex: 1;
}

.search-button {
  border: none;
  outline: none;
}

.Custom-hr-tag {
  border: 1px solid grey;
}

.draft-share-data {
  display: flex;
  justify-content: space-between;
  height: 50px;
  align-items: center;
}

.draft-share-data h1 {
  color: var(--Black, #3e3f42);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  text-transform: uppercase;
}

.box-data {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.box-data-container {
  border: 1px solid #eaedf3;
  padding-top: 60px;
  width: 350px;
  height: 180px;
  align-items: center;
  text-align: center;
}

.Warrants-button {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.Warrants-dropdown {
  width: 130px;
}

.Warrants-Fliter-button {
  width: 80px;
  background-color: white;
  color: black;
  border: none;
  align-items: center;
  height: fit-content;
}

.Warrants-Shares-button {
  padding: 0;
  margin: 0;
  width: 100px;
  height: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  border: none;
  color: black;
  background-color: white;
  border-radius: 4px;
  border: 1px solid var(--Primary-Purple-50, #eaedf3);
  background: #fff;
}

.Warrants-View-button {
  width: 150px;
  background-color: transparent;
  border: none;
  height: fit-content;
}

.Warrants-Filter {
  padding: 0;
  margin: 0;
  height: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  border: none;
  color: black;
  background-color: white;
  background: white;
  border-radius: 4px;
  border: 1px solid var(--Primary-Purple-50, #eaedf3);
}

.View-Share-cLasses {
  white-space: nowrap;
}

.box-data {
  height: 60vh;
}

.Warrants-data {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
}

.Warrants-data-container {
  border: 1px solid #eaedf3;
  padding-top: 60px;
  width: 350px;
  height: 200px;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.Warrants-error {
  width: 100px;
  height: 60px;
  display: flex;
  justify-content: center;
  margin: auto;
  align-items: center;
}

@media (max-width: 768px) {
  .Share-Draft {
    flex-direction: column;
    height: auto;
  }

  .Warrants-button {
    flex-direction: column;
  }

  .Warrants-conatiner {
    padding: 10px;
  }

  .Warrants-data-container {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .Warrants-Filter, .Warrants-Shares-button {
    width: 100%;
  }

  .select-wrapper, .View-Share-cLasses {
    width: 100%;
    text-align: center;
  }
}

