.property-slider-wrap {
  position: relative;

  .f-thumbs {
    --f-thumb-width: 65px;
    --f-thumb-height: 65px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 20px);
    bottom: 10px;
    background-color: rgba($color: #000000, $alpha: 0.6);
    border-radius: 4px;
    padding: 0 40px;

    .f-thumbs__viewport {
      padding: 0 10px;
    }

    .f-thumbs__track {
      padding: 10px 0;
    }
  }

  .f-carousel {
    --f-button-width: 24px;
    --f-button-height: 24px;
    --f-button-color: white;
    --f-button-hover-color: white;
    --f-button-prev-pos: 25px;
    --f-button-next-pos: 25px;

    .f-button.is-prev,
    .f-button.is-next,
    .f-button.is-prev,
    .f-button.is-next {
      top: unset;
      bottom: 30px;
    }
  }

  .f-carousel__slide {
    position: relative;

    &::after {
      content: "";
      display: block;
      padding-bottom: 65%;
    }

    & > .thumb-content {
      position: absolute;
      width: 100% !important;
      height: 100% !important;
      left: 0;
      top: 0;
      border-radius: 4px !important;
    }

    .thumb-play-btn {
      // position: absolute;
    }

    .thumb-caption {
      position: absolute;
      bottom: 100px;
      left: 10px;
      color: white;
      text-shadow: 2px 3px rgba($color: #000000, $alpha: 0.6);
    }
  }

  .is-nav-selected .f-thumbs__slide__button {
    border: 3px solid white;
    border-radius: 4px;
  }

  .f-thumbs__slide__button {
    .f-thumbs__slide__img {
      width: 100% !important;
      height: 100% !important;
      object-fit: cover;
    }
  }

  .slide-zoom {
    position: absolute;
    z-index: 1;
    right: 10px;
    top: 10px;
    border: none;
    background: transparent;
    cursor: pointer;
  }

  .thumb-nav {
    display: flex;
    gap: 10px;
    bottom: 100px;
    position: absolute;
    z-index: 1;
    right: 10px;

    button {
      font-size: 12px;
      background-color: rgba($color: #fff, $alpha: 0.6);
      border-radius: 5px;
      border: none;
      padding: 4px 8px;
      font-family: "Roboto";
      font-weight: 500;
      color: rgba(62, 63, 66, 1);
      text-align: center;
      backdrop-filter: blur(10px);

      &.active {
        background-color: rgba($color: #fff, $alpha: 0.9);
      }
    }
  }
}

