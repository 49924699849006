.payment-method {
  display: flex;
  justify-content: center;

  .payment-container {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
    padding: 20px;

    .header {
      border-bottom: 1px solid #eaedf3;
      padding-bottom: 15px;
      margin-bottom: 20px;

      h2 {
        font-size: 18px;
        font-weight: 500;
        color: #3e3f42;
        margin: 0;
      }
    }

    .saved-card {
      position: relative;
      width: 200px; 
      height: 100px;
      background: linear-gradient(135deg, #7d55c7, #8865c8);
      color: white;
      border-radius: 10px; 
      padding: 10px; 
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
      transition: transform 0.3s ease, box-shadow 0.3s ease;
      cursor: pointer;

      &:hover {
        transform: scale(1.05);
        box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
      }

      &.active {
        border: 3px solid white;
      }

      .card-design {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;

        .card-top {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .chip {
            width: 30px; 
            height: 20px; 
            background: #f9c74f;
            border-radius: 5px;
          }

          .card-type {
            font-size: 14px; 
            font-weight: bold;
            text-transform: uppercase;
          }
        }

        .card-number {
          font-size: 16px; 
          letter-spacing: 2px;
          text-align: center;
        }

        .card-details {
          display: flex;
          justify-content: space-between;
          font-size: 12px; 

          .card-holder {
            font-weight: bold;
          }

          .expiry {
            opacity: 0.8;
          }
        }
      }

      .remove-btn {
        position: absolute;
        bottom: -20px; 
        right: 5px;
        background: #d9534f;
        color: white;
        border: none;
        padding: 5px 8px; 
        border-radius: 5px;
        font-size: 10px; 
        cursor: pointer;

        &:hover {
          background: #c12e2a;
        }
      }
    }

    .form {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .card-icon {
        margin-bottom: 10px;
        text-align: center;

        svg {
          width: 60px;
          height: auto;
        }
      }

      .expiry-cvv {
        display: flex;
        gap: 12px;
        align-items: center;

        .input-box {
          width: 80px;
        }
      }
    }

    .buttons {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 20px;

      .update-btn {
        width: 100%;
        padding: 10px 15px;
        background: linear-gradient(0deg, #7d55c7 0%, #8865c8 107.78%);
        color: #fff;
        border: none;
        border-radius: 4px;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
        transition: background 0.3s ease;

        &:hover {
          background: #6b4eb7;
        }
      }
    }

    .secure-text {
      text-align: center;
      font-size: 12px;
      color: #9ea0a5;
      margin-top: 20px;

      span {
        font-weight: 500;
        color: #7d55c7;
      }
    }
  }
}
.card-preview {
  margin-top: 15px;

  .rccs__card {
    width: 100%;
    max-width: 320px;
  }
}
.close-icon-PaymentMethod {
  position: absolute;
  top: 0px;
  width: 24px;
  right: -27px;
  background: red;
  border-radius: 100%;
  color: white;
  font-size: 18px;
  cursor: pointer;
  padding: 2px 5px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    color: white;
  }
}
.saved-cards {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 23px;
}
