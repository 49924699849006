.chat_dropdown_wrapper.expand {
  width: 1210px !important;
}

.seller-chat-expanded {
  width: 730px;
}

.seller-chat-right-expanded {
//   width: 498px !important;
}

.seller-chat-left-expanded {
//   width: 498px !important;
}

.chatbox {
  .chatbox_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    height: 66px;
    padding: 20px;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.05), 0px 1px 0px 0px #eaedf3;
    border-left: 1px solid #eaedf3;
    .chatbox_header_left {
      display: flex;
      align-items: center;
      gap: 20px;

      h3 {
        color: #3e3f42;
        font-family: Roboto;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
      }
    }
    .chatbox_header_right {
      display: flex;
      align-items: center;
      gap: 20px;
      > div {
        display: flex;
        align-items: center;
        gap: 10px;
      }
      img {
        height: 20px;
        width: 20px;
      }
    }
  }
}

.chat_dropdown_wrapper {
  position: fixed;
  top: 80px;
  right: 0;
  background: #fff;
  box-shadow: -1px 0px 0px 0px #eaedf3;
  width: 380px;
  height: calc(100vh - 80px);
  overflow-y: auto;
  z-index: 1111;
  display: flex;

  img {
    cursor: pointer;
  }

  .chat_dropdown {
    background: #fff;
    box-shadow: -1px 0px 0px 0px #eaedf3;
    width: 380px;
    overflow-y: auto;

    z-index: 1111;
    border-right: 1px solid #eaedf3;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px;
      height: 66px;
      border-bottom: 1px solid #eaedf3;
      // background-color: #1665D8;

      .header_right {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-top: 17px;
      }
    }

    .chat_nav {
      margin-top: 17px;

      .asset-nav {
        border-bottom: none !important;
        max-width: 200px;
        height: 45px !important;
      }

      .deals-room-nav-active {
        border-bottom: 3px solid #1665d8;
      }
    }

    .teambox.active {
      background-color: #eaedf3;
      border-left: 3px solid #1665d8;
    }

    .teambox {
      display: flex;
      gap: 20px;
      padding: 20px;
      border-bottom: 1px solid #eaedf3;

      .teambox_left {
        img {
          margin-top: 10px;
          height: 38px !important;
          min-width: 38px !important;
        }
      }

      .teambox_right {
        .right_upper {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
    }

    h3 {
      color: #3e3f42;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }

    .time {
      color: #9ea0a5;
      text-align: right;
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
    }

    .text {
      color: #6b6c6f;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
    }

    .chat_title {
      padding: 20px;
      border-bottom: 1px solid #eaedf3;

      p {
        color: #9ea0a5;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        text-transform: uppercase;
      }
    }

    .personalbox {
      .text {
        padding-bottom: 20px;
      }
    }

    .right_lower {
      display: flex;
      align-items: center;
      gap: 14px;
      border-top: 1px solid #eaedf3;
      padding-top: 20px;

      img {
        height: 26px;
        width: 26px;
        border-radius: 4px;
      }

      p {
        color: #6b6c6f;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
      }
    }

    .contactsbox {
      padding: 20px;

      .title {
        color: #9ea0a5;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        text-transform: uppercase;
      }

      .contact {
        padding: 12px 0 0 0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .contact_left {
          display: flex;
          align-items: center;
          gap: 10px;

          .left_lower {
            margin-top: -6px;

            span {
              color: #6b6c6f;
              font-family: Roboto;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 18px;
            }
          }

          img {
            height: 38px;
            width: 38px;
          }

          .name {
            color: #3e3f42;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px;
          }
        }
      }
    }
  }

  .seller-chat-container {
    margin-top: 30px;
    padding: 0 30px;
    max-width: 100%;
  }

  .seller-chat-box {
    height: 65px;
    fill: #fff;
    border-top: 1px solid #eaedf3;
    box-shadow: 0px -1px 4px 0px rgba(0, 0, 0, 0.05) inset;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px 0 10px;

    .chat_action_icon {
        // width: 19px;
        height: 19px;
    }
    .send_icon {
    width: 33px;
    height: 30px;
    }
    .hidden{
        visibility: hidden;
    }
  }

  .seller-chat-box #input-box {
    width: 100%;
    height: 40px;
    background: #fff;
    border: none;
    outline: none;

    &::placeholder {
      color: #9ea0a5;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
    }
  }

  .chat-attacment {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    align-items: center;
    gap: 5px;
    justify-items: center;
  }

  #chatimg {
    height: 28px;
    width: 28px;
    border-radius: 50%;
  }

  .seller-chat-activity-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .chat-user {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 12px;
  }

  .chat-user span:nth-child(2) {
    color: #3e3f42;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    /* 157.143% */
  }

  .chat-user span:nth-child(3) {
    color: #9ea0a5;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    /* 150% */
  }

  .user-active span:nth-child(3) {
    font-size: 14px;
  }

  .chat-box-right {
    // width: 240px;
    fill: #fff;
    stroke-width: 1px;
    border: 1px solid #eaecea;
    stroke: #eaecee;
    padding: 16px 24px;
    margin-bottom: 30px;
    border-radius: 10px 0 10px 10px;

    > p {
      color: #3e3f42;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      /* 157.143% */
    }
  }

  .chat-box-left {
    display: flex;
    width: 240px;
    gap: 12px;
    margin-bottom: 30px;

    > p {
      color: #fff;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      /* 157.143% */
      padding: 18px 24px;
    }
  }

  .chat-color-read p {
    border-radius: 0px 10px 10px 10px;
    background: linear-gradient(90deg, #6b5de7 0%, #915cf4 101.5%);
  }

  .chat-color-unread p {
    border-radius: 0px 10px 10px 10px;
    background: linear-gradient(90deg, #4187ee 1.89%, #4f95fc 98.11%);
  }

  .chat-box-img-upload {
    display: flex;
    gap: 10px;
  }

  #uploadimg {
    height: 80px;
    width: 80px;
    border-radius: 10px;
  }

  .versions_wrapper {
    padding: 0px 30px;

    .deals-room-center-header {
      padding: 0px !important;
    }

    .versions_header {
      display: flex;
      align-items: center;

      padding: 40px 0px;
      justify-content: space-between;

      .left {
        h2 {
          color: #000;

          /* h5/Medium 18px */
          font-family: Roboto;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }

      .right {
        display: flex;
        gap: 20px;
        align-items: center;

        .PrimaryButton {
          display: flex;
          width: 104px;
          height: 38px;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;
          background-color: #fff;
          background: #fff;
          color: #3e3f42;
          border: 1px solid #d8dce6;
          margin-top: 0px;
        }

        p {
          display: flex;
          align-items: center;
          gap: 5px;
          color: #3e3f42;
          font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 300;
          line-height: 28px;
          /* 175% */
        }

        span {
          color: #6b6c6f;
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px;
        }
      }
    }
  }
}

.select__multi-value {
  background-color: purple !important;
  color: white !important;
  padding: 3px !important;
  border-radius: 20px !important;
}
.select__multi-value__label {
  color: white !important;
}
.select__multi-value__remove {
  cursor: pointer !important;

  &:hover {
    background-color: purple !important;
  }
}
.asset-multi-select {
  border: none !important;
  width: 90% !important;
  margin: auto !important;
  margin-top: 20px !important;
}
