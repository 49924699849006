.payments_container {
  .heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 0px 0px 1px #eaedf3 inset;
    width: 825px;
    height: 74px;
    flex-shrink: 0;
    padding: 0px 20px 0px 20px;

    h1 {
      color: #3e3f42;
      font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px; /* 155.556% */
    }
    .actions {
      display: flex;
      align-items: center;
      gap: 10px;

      img {
        cursor: pointer;
      }
    }
  }
  .details {
    .payment_info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 825px;
      height: 70px;
      flex-shrink: 0;
      background: rgba(255, 255, 255, 0);
      box-shadow: 0px -1px 0px 0px #eaedf3 inset;
      padding: 0px 20px 0px 20px;

      .date_and_bank {
        display: flex;
        align-items: center;
        gap: 20px;
        .date {
          display: flex;
          flex-direction: column;
          p {
            color: #9ea0a5;
            text-align: center;
            font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px; /* 150% */
            text-transform: uppercase;
          }
          span {
            color: #9ea0a5;
            text-align: center;
            font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px; /* 150% */
            text-transform: uppercase;
          }
        }
        .bank {
          display: flex;
          flex-direction: column;

          p {
            color: #3e3f42;
            font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px; /* 157.143% */
          }
          span {
            color: #9ea0a5;
            font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;

            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px; /* 150% */
          }
        }
      }
      .status_and_amount {
        display: flex;
        align-items: center;
        gap: 50px;
        .completed_status {
          display: inline-flex;
          padding: 3px 10px;
          justify-content: center;
          align-items: center;
          border-radius: 4px;
          background: var(--959595-mid-grey-2, #959595);
          color: #fff;
          text-align: center;
          font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 18px; /* 150% */
          text-transform: uppercase;
        }

        .pending_status {
          display: inline-flex;
          padding: 3px 10px;
          justify-content: center;
          align-items: center;
          border-radius: 4px;
          background: var(--Orange, #ed962d);
          color: #fff;
          text-align: center;
          font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 18px; /* 150% */
          text-transform: uppercase;
        }
        .amount {
          display: flex;
          flex-direction: column;
          align-items: end;

          p {
            color: #3e3f42;
            text-align: right;
            font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px; /* 157.143% */
          }
          span {
            color: #9ea0a5;
            text-align: right;
            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px; /* 150% */
          }
        }
      }
    }
  }
}
