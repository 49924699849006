.BulkDealsUpload-dialog {
    font-family: "Arial", sans-serif;
  }
  
  .BulkDealsUpload-title {
    font-size: 1.8rem;
    font-weight: bold;
    text-align: center;
    color: #333;
    margin-bottom: 12px;
  }
  
  .BulkDealsUpload-content {
    width: 600px; /* Increased width for bulk upload */
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: #fefefe;
    border-radius: 8px;
    border: 1px solid #e0e0e0;
  }
  
  .BulkDealsUpload-file-input {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .BulkDealsUpload-upload-icon {
    background-color: #f0f0f0;
    border-radius: 50%;
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .BulkDealsUpload-upload-icon:hover {
    background-color: #e0e0e0;
  }
  
  .BulkDealsUpload-file-name {
    font-size: 1rem;
    color: #555;
    font-weight: 500;
  }
  
  .BulkDealsUpload-progress-bar {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  
  .BulkDealsUpload-progress-bar span {
    font-size: 0.9rem;
    color: #555;
  }
  
  .BulkDealsUpload-actions {
    padding: 16px;
    justify-content: space-between;
    border-top: 1px solid #e0e0e0;
  }
  
  .BulkDealsUpload-cancel-button {
    color: #888;
    font-weight: 500;
    border: none;
    background-color: transparent;
    cursor: pointer;
    transition: color 0.2s;
  }
  
  .BulkDealsUpload-cancel-button:hover {
    color: #555;
  }
  
  .BulkDealsUpload-submit-button {
    background-color: #1976d2;
    color: white;
    font-weight: bold;
    padding: 10px 20px;
    font-size: 1rem;
    border-radius: 6px;
    border: none;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .BulkDealsUpload-submit-button:hover {
    background-color: #115293;
  }
  
  .BulkDealsUpload-loader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    color: #1976d2;
    font-size: 1rem;
  }
  
  .BulkDealsUpload-small-fields {
    display: flex;
    gap: 16px;
  }
  
  .BulkDealsUpload-small-textfield {
    flex: 1;
    background-color: #f9f9f9;
    border-radius: 6px;
  }
  
  .BulkDealsUpload-placeholder {
    font-size: 0.9rem;
    color: #999;
    margin-top: -10px;
    font-style: italic;
  }
  
  .BulkDealsUpload-file-input-multiple {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: space-between;
  }
  
  .BulkDealsUpload-file-input-multiple span {
    font-size: 0.85rem;
    color: #555;
    background-color: #f0f0f0;
    border-radius: 4px;
    padding: 5px 10px;
    display: inline-block;
  }
  
  .BulkDealsUpload-file-input-multiple span:hover {
    background-color: #e0e0e0;
  }
  