* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.asset-center-con {
  @media screen and (max-width: 900px) {
    width: 874px;
    // margin-left: -238px;
  }
}

.deals-room-center {
  width: 1120px;
}

.deals-room-center-header-StartSyndicate {
  height: 67px;
  /* background: aqua; */
  /* background: #fff; */
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.04);
  display: flex;
  align-items: center;
  padding: 12px 20px 6px 35px;
  position: sticky;
  top: 0px;
  z-index: 100;
  justify-content: space-between;
  align-items: center;
  // width: 89%;

  p {
    color: var(--Black, #3e3f42);
    leading-trim: both;
    text-edge: cap;
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 155.556% */
  }
}

.StartSyndicate-nav {
  padding: 15px 0 0 30px;
  // width: 968px;
  width: 100% !important;
  height: 60px;
  justify-content: space-between;
  border: 1px solid #eaedf3;
  border-radius: 4px;
  margin-top: 0 !important;
}

.header-button {
  display: flex;
  padding: 8px 11px;
  border-radius: 4px;
  border: 1px solid #d8dce6;
  background: linear-gradient(0deg, #f6f7f9 0%, #fff 100%);
  box-shadow: 0px 1px 1px 0px rgba(22, 29, 37, 0.05), 0px 2px 0px 0px rgba(255, 255, 255, 0.05) inset;
  margin: 10px;
  align-items: center;
  justify-content: space-around;
  gap: 10px;
  cursor: pointer;

  > p {
    cursor: pointer;

    @media screen and (max-width: 900px) {
      font-size: 12px !important;
    }
  }

  @media screen and (max-width: 900px) {
    padding: 7px 7px !important;
  }
}

.deals-room-header-button {
  display: flex;
  gap: 20px;
}

.header-button-first {
  display: flex;
  padding: 8px 11px;
  border-radius: 4px;
  border: 1px solid var(--red-pantone-1797-c-72, rgba(203, 51, 59, 0.72));
  // background: linear-gradient(0deg, #f6f7f9 0%, #fff 100%);
  box-shadow: 0px 1px 1px 0px rgba(22, 29, 37, 0.05), 0px 2px 0px 0px rgba(255, 255, 255, 0.05) inset;
  margin: 10px;
  align-items: center;
  justify-content: space-around;
  gap: 10px;
  cursor: pointer;
}

.header-button-first p {
  color: var(--red-pantone-1797-c, #cb333b);
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */
  cursor: pointer;
}

.header-button-last {
  display: flex;
  height: 46px;
  padding: 19px 20px;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  border: 1px solid var(--purple-pantone-2665-c-71, rgba(125, 85, 199, 0.71));
  // background: linear-gradient(0deg, rgba(125, 85, 199, 0.3) 0%, rgba(255, 255, 255, 0) 107.78%), #7d55c7;
  background-blend-mode: multiply, normal;
  box-shadow: 0px 1px 1px 0px rgba(19, 31, 21, 0.1), 0px 2px 0px 0px rgba(255, 255, 255, 0.06) inset;
  margin-top: 10px;
  cursor: pointer;
}

.header-button-last p {
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  cursor: pointer;
}

.header-button-last img {
  display: flex;
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
}

.StartSyndicate-deals-room-container {
  display: flex;
  padding: 30px;
  gap: 40px;
  justify-content: space-between;
  // width: 88%;
  @media screen and (max-width: 900px) {
    flex-direction: column;
  }

  .dealsRoomRightWrapper {
    position: relative;

    .deals-room-right-container {
      position: sticky;
      top: 100px;
      right: 20px;
    }
  }
}

#mt-7px {
  margin-top: 9px;
}

.StartSyndicate-deals-room-left-container {
  overflow: hidden;
  // width: 273px;
  /* margin-right: 30px; */
  width: 100%;

  .scroll-handler {
    overflow-x: scroll;
  }
}

.deals-room-right-container {
  max-width: 360px;
  display: flex;
  height: fit-content;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  flex-shrink: 0;
  padding: 12px 12px 10px 12px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  @media screen and (max-width: 900px) {
    margin: auto;
  }
}

.deals-left-cornor {
  position: absolute;
  top: 12px;
  left: 12px;
  display: inline-flex;
  padding: 5px 10px;
  align-items: center;
  gap: 10px;
  border-radius: 4px 0px;
  background: var(--LP-very-light--_lib, #eaedf3);
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.1);
}

.deals-left-cornor .cornor-text {
  color: var(--LP-mid-grey-_lib, #6b6c6f);
  text-align: center;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.deals-room-small-title {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.inner-deals-room-small-title {
  display: flex;
  align-items: center;
  gap: 10px;
}

.inner-deals-room-small-title .detailss {
  color: var(--LP-black--_lib, #3e3f42);
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  /* 16.8px */
}

.deals-room-round {
  width: 21px;
  height: 21px;
  flex-shrink: 0;
  border-radius: 50%;
  background-color: #617d92;
  display: flex;
  justify-content: center;
  align-items: center;
}

.debt {
  border-radius: 20px;
  background-color: #cbd5e1;
  padding: 4px 10px;
  display: flex;
  align-items: center;

  span {
    color: var(--LP-black--_lib, #3e3f42);
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 16.8px */
  }
}

.waltham {
  width: 326px;
  height: 19px;
  color: var(--LP-black, #3e3f42);
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 133.333% */
}

.deals-room-icon-location {
  display: flex;
  align-items: center;
  gap: 10px;
}

.deals-room-icon-location p {
  width: 306px;
  color: #9ea0a5;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  /* 133.333% */
}

.deals-room-offer {
  display: flex;
  align-items: center;
  gap: 21px;
}

.deals-room-offer-inner {
  width: 202px;
  display: flex;
  justify-content: space-between;
}

.numbers {
  color: var(--purple-pantone-2665-c, #7d55c7);
  text-align: center;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 110%;
  /* 26.4px */
}

.words {
  color: var(--purple-pantone-2665-c-71, rgba(125, 85, 199, 0.71));
  text-align: center;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
}

.offer-header {
  color: var(--LP-mid-grey-_lib, #6b6c6f);
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  /* 150% */
}

.yield {
  display: flex;
  flex: 1;
  width: 100%;
  overflow: hidden;
  padding: 6px 7px;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  border: 0px solid var(--LP-very-light, #eaedf3);
  background: rgba(125, 85, 199, 0.15);
}

.yield .first-child {
  color: var(--LP-mid-grey-_lib, #6b6c6f);
  text-align: center;
  font-family: Roboto;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.yield .last-child {
  color: var(--LP-black--_lib, #3e3f42);
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  /* 128.571% */
}

.yield-flex {
  display: flex;
  gap: 10px;
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
}

.yield-flexx {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  width: 100%;
  align-items: center;
}

.yieldd {
  padding: 6px 0;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  border: 1px solid var(--LP-very-light--_lib, #eaedf3);
  background: rgba(234, 237, 243, 0.5);
  overflow: hidden;
}

.yieldd .firstt-child {
  color: var(--LP-mid-grey-_lib, #6b6c6f);
  text-align: center;
  font-family: Roboto;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 4px;
}

.yieldd .lastt-child {
  color: var(--LP-black--_lib, #3e3f42);
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  /* 128.571% */
}

.yield-flexxx {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  width: 100%;
  align-items: center;
}

.yielddd {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
  border-radius: 4px;
  border: 1px solid var(--LP-very-light, #eaedf3);
  background: var(--ffffff-white, #fff);
  padding: 10px;
  height: 100%;
  justify-content: flex-start;
}

.yielddd .firsttt-child {
  color: var(--LP-mid-grey-_lib, #6b6c6f);
  text-align: center;
  font-family: Roboto;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 4px;
}

.yielddd .lasttt-child {
  color: var(--LP-black--_lib, #3e3f42);
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  /* 128.571% */
}

.yielddd .lasttt-child-child {
  color: var(--LP-mid-grey-_lib, #6b6c6f);
  text-align: center;
  font-family: Roboto;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.hero-section-image {
  margin-top: 0;
  width: 100%;
  height: 308px;
  position: relative;
  .StartSyndicate-hero-img {
    width: 100%;
    border-radius: 5px;
    object-fit: cover;
    height: 285px;
  }

  .hero-section-img-title {
    width: 100%;
    position: absolute;
    top: 66%;
    padding: 0 10px 0 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      color: #fff;
      font-family: Roboto;
      font-size: 22px;
      font-style: normal;
      font-weight: 500;
      line-height: 32px;
      /* 145.455% */
    }

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      width: 125px;
      height: 38px;
      border-radius: 4px;
      background-color: rgba(15, 15, 15, 0.495);

      span {
        color: #fff;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        /* 157.143% */
      }
    }
  }
}

.first-box {
  // height: 169px;
  flex-shrink: 0;
  fill: #fff;
  stroke-width: 1px;
  stroke: #eaedf3;
  // filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.04));
  border: 1px solid #cbd5e1;
  margin-top: 30px;
  border-radius: 4px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  .setup-general {
    padding: 0;
  }

  .box_desc {
    p {
      color: #9ea0a5;
      font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      margin-top: 5px;
      margin-bottom: 15px;
    }
  }

  .financial-body {
    // padding: 20px;
    margin-top: 20px;
  }
  .dropDownOuterCon {
    margin-top: 0;
  }

  @media screen and (max-width: 1440px) {
    width: 100% !important;
    margin: auto;
    margin-top: 30px;
  }

  @media screen and (max-width: 900px) {
    width: 100% !important;
    margin: auto;
    margin-top: 30px;
  }
}

.first-inner-box {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  gap: 16px;
  // border-bottom: 1px solid #eaedf3;
  stroke: #eaedf3;
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.04));
}

.first-inner-box p {
  color: #9ea0a5;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  /* 157.143% */
}

.syndicate-dropdown {
  width: 670px;

  @media screen and (max-width: 1440px) {
    width: 100% !important;
  }

  @media screen and (max-width: 900px) {
    width: 100% !important;
  }
}

.second-inner-box {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  border-radius: 4px;
  margin: 30px;
  padding: 8px 16px;
}

.new-para {
  border: 1px solid #cbd5e1;
}

.second-inner-box p {
  width: 638px;
  height: 22px;
  color: var(--LP-black--_lib, #3e3f42);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.box-new {
  // width: 730px;
  width: 100%;
  // height: 255px;
  flex-shrink: 0;

  padding: 20px;

  .setup-general {
    padding: 0;
  }
}

.box-new-para p {
  color: #9ea0a5;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  width: 670px;
  height: 110px;
  flex-shrink: 0;
  border: 1px solid #cbd5e1;
  border-radius: 4px;
}

.setup-general {
}

.switch-syndicate {
  width: 275px;
  margin: 20px 208px !important;
}

.synd-link {
  margin: 30px;
}

.big-box {
  width: 670px;
  height: 140px;
  border-radius: 4px;
  border: 1px solid #e2e5ed;
  background: #fcfcfc;
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.05) inset;
  margin: 20px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  @media screen and (max-width: 1440px) {
    width: 550px !important;
    margin: auto !important;
  }

  @media screen and (max-width: 900px) {
    width: 500px !important;
    margin: auto !important;
  }
}

.inner-big-box .first-para .first-span {
  color: #9ea0a5;
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */
  text-decoration-line: underline;
}

.inner-big-box .first-para .second-span {
  color: #9ea0a5;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-left: 2px;
  text-align: center;
}

.inner-big-box .second-para {
  color: #9ea0a5;
  text-align: center;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin-top: 8px;
}

.new-div-one {
  display: flex;
  width: 670px;
  height: 91px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 7px;
  flex-shrink: 0;
  padding: 30px;
}

.new-div-one .image-para {
  color: #9ea0a5;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  /* 150% */
  text-transform: uppercase;
}

.new-div-one-inner {
  display: flex;
  border: 1px solid #e2e5ed;
  width: 100%;
  height: 38px;
  align-items: center;
  padding-left: 10px;
  border-radius: 4px;
}

.new-div-one-inner p {
  color: #9ea0a5;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */
  margin-left: 11px;
}

.new-div-one .last-para {
  color: #9ea0a5;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.manager {
  width: 730px;
  border: 1px solid #e2e5ed;
  border-radius: 4px;

  @media screen and (max-width: 1440px) {
    width: 640px !important;
    margin: auto !important;
  }

  @media screen and (max-width: 900px) {
    width: 539px !important;
    margin: auto !important;
  }
}

.first-manager {
  display: flex;
  border-bottom: 1px solid #e2e5ed;
  padding: 30px;
}

.first-manager p {
  color: #9ea0a5;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  margin-left: 16px;
}

.admin {
  color: #9ea0a5;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  /* 150% */
  text-transform: uppercase;
  padding: 12px 28px;
}

.user-detail img {
  border-radius: 50%;
}

.name-you {
  width: 110%;
  margin-left: 10px;
}

.name-you .mark {
  color: #3e3f42;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.name-you .you {
  color: #9ea0a5;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.repeat {
  margin-bottom: 40px;
  border-bottom: 1px solid #eaedf3;
}

.inner-manager {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #cbd5e1;
  padding-bottom: 20px;
}

.inner-manager button {
  width: 87px;
  height: 38px;
  color: #3e3f42;
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  background-color: #d8dce6;
  border: 1px solid #9ea0a5;
  border-radius: 4px;
  margin-right: 10px;
}

.inner-manager-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 200px;
  margin-left: 20px;
}

.collaborators {
  color: #9ea0a5;
  font-family: Roboto;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  /* 150% */
  text-transform: uppercase;
  margin-top: 30px;
  margin-left: 20px;
}

.collaborators > span:last-child {
  margin: 8px;
}

.add-people {
  color: #9ea0a5;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  /* 150% */
  text-transform: uppercase;
  margin-top: 27px;
  margin-left: 20px;
}

.people {
  display: flex;
  margin-top: 10px;
  margin-left: 20px;
}

#cars {
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  left: -20px;
}

.people > input[type="text"]:first-child {
  width: 400px;
  height: 38px;
  flex-shrink: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border: 1px solid #e2e5ed;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(102, 113, 123, 0.21) inset;

  @media screen and (max-width: 1440px) {
    width: 330px !important;
  }

  @media screen and (max-width: 900px) {
    width: 203px !important;
  }
}

.people .second-input {
  width: 200px;
  height: 38px;
  flex-shrink: 0;
  border: 1px solid #e2e5ed;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(102, 113, 123, 0.21) inset;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;

  @media screen and (max-width: 1440px) {
    width: 190px !important;
  }

  @media screen and (max-width: 900px) {
    width: 180px !important;
  }
}

.people button {
  width: 66px;
  height: 38px;
  flex-shrink: 0;
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */
  color: white;
  background-color: #7d55c7;
  border: none;
  margin-left: 10px;
  border-radius: 4px;
}

.notified {
  width: 470px;
  color: #9ea0a5;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin: 16px 0 25px 20px;
}

.inner-manager .temp span {
  color: var(--red-pantone-1797-c, #cb333b);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */
  margin-left: 5px;
}

.temp {
  display: flex;
  align-items: center;
  position: relative;
  left: -40px;
}

.test-manager {
  border: none;
}

.user-box {
  // width: 620px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  margin-top: -30px;
}

.user-box > div {
  display: flex;
  justify-content: space-between;

  margin-top: 15px;
}

.user-box span {
  color: #6b6c6f;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */
  margin-left: 5px;
  text-align: center;
}

// input[type="checkbox"]:checked {
//   background-color: #7d55c7;
//   color: #7d55c7;
// }

.unique {
  position: relative;
  left: -22px;
}

.second-repeat {
  margin-top: 30px;
}

// Last Section
.last-section {
  width: 730px;
  height: 283px;
  flex-shrink: 0;
  border: 1px solid #cbd5e1;
  border-radius: 4px;
  margin-top: 30px;
  position: relative;
  // left: -20px;

  @media screen and (max-width: 1440px) {
    width: 640px !important;
    margin: auto;
    margin-top: 30px;
  }

  @media screen and (max-width: 900px) {
    width: 536px !important;
    margin: auto;
    margin-top: 30px;
  }
}

.last-section div > span {
  color: #9ea0a5;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  text-align: center;
  position: relative;
  top: -4px;
  left: 8px;
}

.inner-section {
  margin: 20px;
}

.private {
  margin-left: 30px;
}

.private label {
  color: #3e3f42;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */
  margin-left: 16px;
}

.private p {
  color: #9ea0a5;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */
  padding: 4px 10px 16px 27px;
  width: 445px;
}

.private p > span {
  color: var(--LP-Pantone, #7d55c7);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  text-decoration-line: underline;
}

::placeholder {
  position: relative;
  // left: 20px;
}

/*===================================================*/
/*              INVESTMENT SYNDICATE START           */
/*===================================================*/
.investment-main-container {
  h1 {
    color: var(--LP-gray, #9ea0a5);
    font-family: Roboto;
    font-size: 26px;
    font-style: normal;
    font-weight: 300;
    line-height: 38px;
    /* 146.154% */
    margin-top: 50px;

    @media screen and (max-width: 1440px) {
      // margin-left: 50px !important;
    }

    @media screen and (max-width: 900px) {
      // margin-left: 50px !important;
      font-size: 22px !important;
      // margin-left: 100px !important;
    }
  }

  .custom_info_heading,
  .additional_doc_heading {
    color: var(--LP-gray, #9ea0a5);
    font-family: Roboto;
    font-size: 26px;
    font-style: normal;
    font-weight: 300;
    line-height: 38px;
    /* 146.154% */
    margin-top: 50px;

    @media screen and (max-width: 1440px) {
      margin-left: 29px !important;
    }

    @media screen and (max-width: 900px) {
      // margin-left: 50px !important;
      font-size: 22px !important;
      margin-left: 80px !important;
    }
  }
}

.financial-container,
.summary_parent_container {
  width: 730px;
  fill: #fff;
  stroke-width: 1px;
  stroke: #eaedf3;
  border: 1px solid #eaedf3;
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.04));
  margin-top: 30px;
  border-radius: 4px;
  margin: auto;
}

.financial-body {
  display: flex;
  gap: 30px;
  flex-direction: column;
}

.financial-body-left {
  width: 320px;
}

.slider-label {
  margin-top: 20px;

  span {
    color: #9ea0a5;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    /* 150% */
    text-transform: uppercase;
  }
}

.financial-body-right {
  width: 320px;
}

.fin-input-time {
  display: flex;
  gap: 10px;

  input[type="time"] {
    margin-top: 18px;
  }
}

.last-input {
  margin-bottom: 102px;
}

.general-syndicate-body {
  display: flex;
  gap: 10px;
  margin: 30px;
  justify-content: space-between;
}

.add-info-man {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 160px;
  height: 163px;
  border: 1px solid #eaedf3;
  border-radius: 4px;
  cursor: pointer;

  span {
    color: var(--LP-mid-gray, #6b6c6f);
    text-align: center;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 171.429% */
  }
}

.add-info-img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  border: 1px solid #eaedf3;
  margin-bottom: 15px;
}

.custom-detail-container {
  display: flex;
  gap: 20px;
  align-items: center;
  flex-wrap: wrap;
}

.custom-div {
  margin-top: 30px;
  width: 130px;
}

/*===================================================*/
/*              SPONSOR SYNDICATE START             */
/*===================================================*/
.sponsor-body {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.internal-inves {
  width: 670px;
  height: 78px;
  display: flex;
  justify-content: space-between;
  padding: 0 30px 0 20px;
  border-radius: 4px;
  border: 2px solid var(--purple-pantone-2665-c, #7d55c7);
  background: linear-gradient(0deg, rgba(125, 85, 199, 0.02) 0%, rgba(125, 85, 199, 0.02) 100%), #fff;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.08);
  align-items: center;

  @media screen and (max-width: 1440px) {
    width: 578px !important;
  }

  @media screen and (max-width: 900px) {
    width: 480px !important;
  }
}

.internal-left {
  display: flex;
  align-items: center;

  > div {
    h1 {
      color: #3e3f42;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      /* 150% */
    }

    p {
      color: #6b6c6f;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      /* 157.143% */
    }
  }
}

.internal-right {
  display: flex;
  align-items: center;
  gap: 20px;

  span {
    color: #3e3f42;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    /* 157.143% */
  }

  .img-div {
    display: flex;
    padding: 8px 11px;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    border: 1px solid #d8dce6;
    background: linear-gradient(0deg, #f6f7f9 0%, #fff 100%);
    box-shadow: 0px 1px 1px 0px rgba(22, 29, 37, 0.05), 0px 2px 0px 0px rgba(255, 255, 255, 0.05) inset;
  }

  div {
    display: flex;
    gap: 10px;
  }
}

.publish-deal {
  display: flex;
  gap: 12px;

  span {
    color: var(--LP-mid-gray, #6b6c6f);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    /* 157.143% */
  }
}

.investor-meet {
  > p {
    color: var(--LP-mid-grey-_lib, #6b6c6f);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    /* 157.143% */
  }
}

.meet-time-body {
  border-bottom: 1px solid #eaedf3;
  box-shadow: 0px 1px 1px 0px rgba(22, 29, 37, 0.05), 0px 2px 0px 0px rgba(255, 255, 255, 0.05) inset;
  padding-bottom: 50px;
}

.meet-time {
  width: 670px;
  height: 38px;
  margin: 16px 0 30px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  padding: 0 4px;
  border: 1px solid #d8dce6;
  box-shadow: 0px 1px 1px 0px rgba(22, 29, 37, 0.05), 0px 2px 0px 0px rgba(255, 255, 255, 0.05) inset;

  @media screen and (max-width: 1440px) {
    width: 580px !important;
  }

  @media screen and (max-width: 1440px) {
    width: 480px !important;
  }

  div {
    border: 1px solid #eaedf3;
    height: 30px;
  }

  span {
    width: 208px;
    color: var(--LP-mid-grey-_lib, #6b6c6f);
    text-align: center;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    /* 157.143% */
    padding: 4px 0;
    border-radius: 4px;
    cursor: pointer;

    @media screen and (max-width: 1440px) {
      width: 180px !important;
    }

    @media screen and (max-width: 900px) {
      width: 150px !important;
    }
  }
}

.publish-date {
  display: flex;
  gap: 30px;

  input[type="date"] {
    width: 320px;
  }
}

.invitation {
  width: 670px;
  border: 1px solid #eaedf3;
  border-radius: 4px;
  box-shadow: 0px 1px 1px 0px rgba(22, 29, 37, 0.05), 0px 2px 0px 0px rgba(255, 255, 255, 0.05) inset;

  @media screen and (max-width: 1440px) {
    width: 574px !important;
  }

  h1 {
    color: #3e3f42;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 150% */
    margin: 15px 0 0 66px;
  }
}

.invitation-input {
  width: 584px;
}

.invitation-detail {
  width: 584px;
  display: flex;
  align-items: center;
  margin-left: 20px;

  > div > span:last-child {
    color: #9ea0a5;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    /* 157.143% */
  }
}

.last-detail {
  display: flex;
  align-items: center;
  margin: 16px 0 30px 66px;

  span {
    color: #6b6c6f;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    /* 157.143% */
  }
}

/*===================================================*/
/*              BUSINESS SYNDICATE START             */
/*===================================================*/
.business {
  width: 730px;
  fill: #fff;
  stroke-width: 1px;
  border: 1px solid #eaedf3;
  border-radius: 4px;
  stroke: #eaedf3;
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.04));
  margin-top: 30px;

  @media screen and (max-width: 1440px) {
    width: 640px !important;
    margin: auto !important;
    margin-top: 30px;
  }

  @media screen and (max-width: 1440px) {
    width: 540px !important;
  }
}

.business-first-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 30px;
  border-bottom: 1px solid #eaedf3;
}

.business-first-hr {
  display: flex;
  align-items: center;
  gap: 16px;

  span {
    color: var(--LP-mid-gray, #6b6c6f);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    /* 157.143% */
  }
}

.business-first-body {
  margin: 20px 30px 30px 30px;

  .video-btn {
    width: 130px;
    display: flex;
    padding: 8px 20px;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    margin-top: 30px;
    border: 1px solid var(--purple-pantone-2665-c-71, rgba(125, 85, 199, 0.71));
    background: linear-gradient(0deg, rgba(125, 85, 199, 0.3) 0%, rgba(255, 255, 255, 0) 107.78%), #7d55c7;
    background-blend-mode: multiply, normal;
    box-shadow: 0px 1px 1px 0px rgba(19, 31, 21, 0.1), 0px 2px 0px 0px rgba(255, 255, 255, 0.06) inset;

    span {
      color: #fff;
      text-align: center;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      /* 157.143% */
    }
  }
}

.custom-divtwo {
  width: 118px;
}

/*===================================================*/
/*              KEYDEAL SYNDICATE START             */
/*===================================================*/
.keydeal-main-container {
  // width: 730px;
  margin-top: 11px;
  display: flex;
  gap: 20px;
}

.keydeal-body-left {
  height: 38px;
  width: 38px;
  margin-top: 38px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  background: #eaedf3;
  justify-content: center;
}

.keydeal-input {
  width: 672px !important;
}

.save-btn {
  display: inline-flex;
  padding: 8px 20px;
  align-items: center;
  gap: 12px;
  border-radius: 4px;
  border: 1px solid var(--purple-pantone-2665-c-71, rgba(125, 85, 199, 0.71));
  background: linear-gradient(0deg, rgba(125, 85, 199, 0.3) 0%, rgba(255, 255, 255, 0) 107.78%), #7d55c7;
  background-blend-mode: multiply, normal;
  box-shadow: 0px 1px 1px 0px rgba(19, 31, 21, 0.1), 0px 2px 0px 0px rgba(255, 255, 255, 0.06) inset;

  span {
    color: #fff;
    text-align: center;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    /* 157.143% */
  }
}

.keydeal-body-right {
  .keydeal-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid #eaedf3;

    @media screen and (max-width: 1440px) {
      width: 503px !important;
    }

    @media screen and (max-width: 900px) {
      width: 263px !important;
    }
  }
}

.delete-btn {
  display: inline-flex;
  padding: 8px 11px;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  border: 1px solid #d8dce6;
  background: var(--eaeaea-background-grey, #eaeaea);
  box-shadow: 0px 1px 1px 0px rgba(22, 29, 37, 0.05), 0px 2px 0px 0px rgba(255, 255, 255, 0.05) inset;

  span {
    color: #3e3f42;
    text-align: center;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    /* 157.143% */
  }
}

/*===================================================*/
/*              SMART CONTRACT SYNDICATE START       */
/*===================================================*/
.contract-table-head {
  height: 74px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  border-bottom: 1px solid #eaedf3;

  .contract-head-left {
    span:first-child {
      color: #3e3f42;
      font-family: Roboto;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
      /* 155.556% */
      margin-right: 20px;
    }

    span:last-child {
      color: var(--LP-grey-_lib, #9ea0a5);
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
      /* 200% */
    }
  }

  .contract-head-right {
    display: flex;
    gap: 10px;
    align-items: center;

    span {
      color: var(--red-pantone-1797-c, #cb333b);
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      /* 157.143% */
    }
  }
}

.switch_wrapper {
  display: flex;
  align-items: center;
  gap: 10px;

  .testing {
    scale: 0.8;

    span {
      color: #fff !important;
    }
  }
}

/*===================================================*/
/*              DISCLAIMER SYNDICATE START           */
/*===================================================*/
#disc-heading {
  color: var(--LP-gray, #9ea0a5);
  font-family: Roboto;
  font-size: 26px;
  font-style: normal;
  font-weight: 300;
  line-height: 38px;
  /* 146.154% */
  margin: 40px 0 30px 0;
}

.disclaimer-card {
  gap: 30px;
  margin: 0;
}

/*===================================================*/
/*              SUMMARY SYNDICATE START             */
/*===================================================*/
.summary-container {
  padding: 30px 30px 0 30px;
  width: 100%;

  h1 {
    color: #3e3f42;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    /* 200% */
    margin-bottom: 18px;
  }

  .summary-image {
    display: flex;
    gap: 45px;
    width: 416px;
    height: 259px;
    margin-bottom: 30px;

    @media screen and (max-width: 1440px) {
      width: 267px !important;
      height: max-content !important;
    }

    @media screen and (max-width: 900px) {
      width: 225px !important;
      height: max-content !important;
    }
  }
}

.contact-network {
  padding: 0 30px 30px 30px;

  h1 {
    color: var(--LP-mid-gray, #6b6c6f);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    /* 157.143% */
    margin-bottom: 12px;
  }

  .contact-network-body {
    display: flex;
    gap: 60px;
    margin-bottom: 25px;
  }
}

.contact-network-left,
.contact-network-right {
  width: 730px;

  .network-left-body,
  .network-right-body {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
  }

  .network-profile {
    display: flex;
    align-items: center;
    gap: 16px;

    > div p {
      color: #3e3f42;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      /* 157.143% */
    }

    span {
      color: #9ea0a5;
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      /* 150% */
    }
  }

  .network-status {
    display: flex;
    align-items: center;
    gap: 10px;

    span {
      color: #3e3f42;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      /* 157.143% */
    }
  }
}

.contact-network-center {
  span {
    color: var(--LP-mid-gray, #6b6c6f);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    /* 157.143% */
    margin-bottom: 12px;
    display: block;
  }

  p {
    color: var(--LP-black--_lib, #3e3f42);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    /* 157.143% */
    margin-bottom: 18px;
  }

  div {
    width: 184px;
    border: 1px solid #eaedf3;
    display: flex;
    align-items: center;
    gap: 8px;
    padding-left: 10px;

    span {
      color: #3e3f42;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      /* 157.143% */
      margin-bottom: 0 !important;
    }
  }
}

.contact-us-container {
  margin-top: 15px;
  width: 600px;

  p {
    color: #3e3f42;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    /* 200% */
    margin-bottom: 8px;

    @media screen and (max-width: 900px) {
      margin-top: 30px !important;
    }
  }
}

.contact-us-body {
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 900px) {
    flex-direction: column;
    gap: 40px;
  }

  .contact-us-body-left {
    .contact-flex {
      span {
        color: var(--LP-black, #3e3f42);
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        /* 157.143% */
      }

      #contact-info {
        display: flex;
        gap: 15px;
        margin-bottom: 12px;

        > div {
          display: flex;
          flex-direction: column;
          gap: 10px;
        }
      }
    }
  }

  .contact-us-body-right {
    .social-flex {
      div {
        display: flex;
        gap: 10px;
        margin-bottom: 12px;
      }

      #social-link {
        color: #9ea0a5;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        /* 157.143% */
      }

      span {
        color: var(--LP-black--_lib, #3e3f42);
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        /* 157.143% */
      }
    }
  }
}

.term-box {
  padding: 30px;
}

.terms-box-top {
  width: 670px;
  border-radius: 4px;
  background: #fcfcfc;
  padding: 20px 30px;
  margin-bottom: 30px;

  @media screen and (max-width: 1440px) {
    width: 585px !important;
  }

  @media screen and (max-width: 900px) {
    width: 488px !important;
  }

  h1 {
    color: var(--LP-mid-gray, #6b6c6f);
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    /* 122.222% */
  }

  h2 {
    color: var(--LP-mid-gray, #6b6c6f);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    margin: 20px 0;
  }

  p {
    color: var(--LP-mid-gray, #6b6c6f);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
}

.term-box-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;

  div span {
    color: var(--LP-mid-grey-_lib, #6b6c6f);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
}

.custom-tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
  transition: all 0.3s;
}

.tooltip-content {
  visibility: hidden;
  width: 130px;
  height: 40px;
  background-color: var(--333333-dark-grey, #333);
  color: #fff;
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
  border-radius: 4px;
  position: absolute;
  padding: 5px 0;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -75px;
  /* Center the tooltip horizontally */
  white-space: nowrap;
  line-height: 1.2;
  transition: all 0.3s;
}

.custom-tooltip:hover .tooltip-content {
  visibility: visible;
}

// .summary_overview {
//   display: flex;
//   align-items: center;
//   flex-wrap: wrap;
//   gap: 20px;
//   justify-content: space-between;

//   // background-color: red;
//   .summaryCard {
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//     // gap: 100px;
//     width: 36%;
//     p{
//       width: max-content;
//     }
//   }
// }
.summary_overview {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 40px;
  height: max-content !important;
  overflow: scroll !important;

  .summaryBox {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .summaryCard {
    display: flex;
    justify-content: space-between;
    color: #9ea0a5;
    font-weight: 500;

    .card_heading {
      color: #5b5c5e;
    }
  }
  h1 {
    font-weight: 600;
  }
}

.lender-table-main-summary {
  height: 71px;
  display: flex;
  align-items: center;
  justify-content: start;
  border-bottom: 1px solid #e7e7e7;
  gap: 20px;
}

.img-wrapper-summary {
  display: flex;
  align-items: center;
  gap: 16px;

  div > img {
    border-radius: 50%;
  }

  div > span {
    display: block;
  }

  div > span:first-child {
    color: #3e3f42;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    /* 157.143% */
  }

  div > span:last-child {
    color: #9ea0a5;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    /* 150% */
  }
}

.StartSyndicate-conatiner {
  width: 65%;
  @media screen and (max-width: 900px) {
    margin: auto;
    width: 250px;
  }
}
.deals-room-nav {
  width: 891px;
}

// last sectiuon
.StartSyndicate-Checkbox {
  display: flex;
  align-items: center;
  label {
    color: #3e3f42;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    margin-left: 16px;
    margin-bottom: 0;
  }
}

.StartSyndicate-button {
  display: inline-flex;
  height: 38px;
  padding: 12px;
  /* justify-content: center; */
  align-items: center;
  margin-top: 10px;
  justify-content: center;
  display: flex;
  gap: 10px;
  color: black;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid var(--Primary-Purple-50, #eaedf3);
  background: white;
}

.file {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.file > input[type="file"] {
  display: none;
}

.file > label {
  font-size: 1rem;
  font-weight: 300;
  cursor: pointer;
  outline: 0;
  user-select: none;
  border-color: rgb(216, 216, 216) rgb(209, 209, 209) rgb(186, 186, 186);
  border-style: solid;
  border-radius: 4px;
  border-width: 1px;
  background-color: hsl(0, 0%, 100%);
  color: hsl(0, 0%, 29%);
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.file > label:hover {
  border-color: hsl(0, 0%, 21%);
}

.file > label:active {
  background-color: hsl(0, 0%, 96%);
}

.file > label > i {
  padding-right: 5px;
}

.file-upload {
  display: flex;
  width: 94px;
  height: 38px;
  padding: 0px 17.5px 0px 11.5px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid var(--Primary-Purple-50, #eaedf3);
}

.Upload_buttton_StartSyndicate {
  display: flex;
  width: 128px;
  height: 38px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: var(--Primary-Purple-500, #7d55c7);
  color: var(--White, var(--White, #fff));
  leading-trim: both;
  text-edge: cap;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
  border: none;
}

.Button_Combine {
  display: flex;
  gap: 20px;
}

// Loader
/* Add this CSS to your stylesheet */
.loading-spinner-StartSyndicate {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #09f;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.SyndicateData_image {
  width: 102%;
  border-radius: 5px;
  height: 170px;
}

.StartSyndicate-data {
  display: flex;
  flex-direction: row;
  gap: 21px;
  cursor: pointer;
}

// Summary----

.summary-container {
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-top: 17px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: space-around;
  justify-content: space-between;
}

.summary-image-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 20px;
}

.summary-image {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}

.summary-details {
  text-align: left;
}

.summary-details h3 {
  text-align: left;
  margin-bottom: 0px;
  color: var(--Black, #3e3f42);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}

.summary-details p {
  margin: 10px 0 !important;
  font-size: 16px !important;
  color: #333 !important;
  display: flex !important;
  gap: 20px;
}

.summary-details strong {
  color: #9ea0a5;
  margin-right: 10px; /* Adding space between the label and value */
  min-width: 180px; /* Optional: Ensures consistent label width for better alignment */
}

.back-button {
  // padding: 10px 20px;
  // background-color: #007bff;
  margin: 0;
  width: 100%;
  max-width: 250px;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.back-button:hover {
  background-color: #0056b3;
}

.Summary_data_column {
  display: flex;

  height: 38px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: var(--Primary-Purple-500, #7d55c7);
  color: var(--White, var(--White, #fff));
  leading-trim: both;
  text-edge: cap;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
  border: none;
}

//
.setup-StartSyndicate {
  display: block;
}
.StartSyndicate_Summary {
  /* background: aqua; */
  float: right;
  display: flex;
  justify-content: right;
}