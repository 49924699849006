.signupMainCon {
  .signupCon-L {
    .top_header {
      .text_wrapper {
        margin: 37px 0px 44px 0px;
        h1 {
          color: var(--purple-pantone-2665-c, #7d55c7);
          font-family: Roboto;
          font-size: 22px;
          font-style: normal;
          font-weight: 400;
          line-height: 32px; /* 145.455% */
          margin: 4px 0px;
        }
        h2 {
          color: #3e3f42;
          font-family: Roboto;
          font-size: 22px;
          font-style: normal;
          font-weight: 400;
          line-height: 32px; /* 145.455% */
          margin: 4px 0px;
        }
        p {
          color: var(--LP-gray, #9ea0a5);
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px; /* 157.143% */
        }
      }
    }
  }
  .blackoverlay {
    height: 100%;
  }
  .signin_svg_wrapper {
    height: 100%;
    background: #6b4d9f;
    background-image: url("../../assets/svg/signinRight.svg");
    background-position: right;
    background-size: 140%;
    background-position-y: 10%;
    background-repeat: no-repeat;
  }
}


.sigin-button{
  padding: 10px;
  margin:  120;
  height: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  border: none;
  color: white;
  border-radius: 4px;
  border: 1px solid var(--Primary-Purple-50, #eaedf3);
  background: var(--Primary-Purple-500, #7D55C7);
}

/* Media Query */
@media screen and (max-width: 1000px) {
  .signupCon-L {
    width: 50%;
    background-color: #6b4d9f;
    margin: auto;
  }

  .signin_svg_wrapper {
    display: none;
  }
  .signupMainCon{
    flex-direction: column;
  }
}
