/* errorStyles.css */

.error-container {
  background-color: #f8d7da;
  color: #721c24;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.error-title {
  font-weight: bold;
  margin-bottom: 5px;
}

.error-message {
  font-size: 14px;
}
