.investment {
  margin-left: 20px;
  padding-bottom: 30px;
  .table {
    border-collapse: collapse;
    width: 100%;
    margin-top: 12px;
  }

  .table_header {
    padding-left: 20px !important;
  }

  .table_header,
  .table_row {
    height: 40px;
    border: 1px solid #eaedf3;
    text-align: left;

    th {
      color: #9ea0a5;
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
      text-transform: uppercase;
    }
  }

  .table_row:first-child {
    border-top: none;
  }

  .table_row:last-child {
    border-bottom: 1px solid #eaedf3;
  }

  .table_data {
    border-top: 1px solid #eaedf3;
    text-align: left;
    color: #3e3f42;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    padding-left: 30px;
  }
}

.investment_table {
  h2 {
    color: #3e3f42;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    padding: 0 30px;
  }
}

.investment_header {
  margin-bottom: 20px;
  h2 {
    color: #3e3f42;
    font-family: Roboto;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    text-transform: capitalize;
  }

  button {
    padding: 16px 22px;
    outline: none;
  }

  .investment_header_right {
    gap: 20px;
  }

  .btn_left {
    border-radius: 4px;
    border: 1px solid #d8dce6;
    background: linear-gradient(0deg, #f6f7f9 0%, #fff 100%);
    box-shadow: 0px 1px 1px 0px rgba(22, 29, 37, 0.05), 0px 2px 0px 0px rgba(255, 255, 255, 0.05) inset;
  }

  .btn_right {
    background-color: #7d55c7b5;
    border: none;
    border-radius: 4px;
    color: #ffffff;
  }
}

.investment_container {
  padding: 0 20px;
  display: flex;
  gap: 30px;
  margin-top: 90px !important;
}

.investment_center {
  width: 730px;
  min-height: 524px;
  padding: 0 !important;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border: 1px solid #eaedf3;
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.04));
  // background-color: rgba(15, 160, 227, 0.623);

  .investment_info {
    padding: 30px 30px 0 30px;

    p {
      color: #6b6c6f;
      font-family: Roboto;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
    }
  }
}

.investment_right {
  width: 350px;
  height: 524px;
  position: sticky !important;
  // top: 70px !important;
  // background-color: rgba(15, 227, 15, 0.623);
}

.investment_nav {
  padding: 0 30px;

  .asset-nav {
    border: none !important;
  }
}

.investment_bottom {
  padding: 10px 30px;
  h2 {
    max-width: 379px;
    color: #3e3f42;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
  }

  p {
    color: #6b6c6f;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
  }
}
