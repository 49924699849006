.details_container {
  .overview_container {
    width: 825px;
    height: 400px;
    flex-shrink: 0;
    fill: #fff;
    stroke-width: 1px;
    stroke: #eaedf3;
    padding: 10px;

    @media screen and (max-width: 1200px) {
      width: 700px !important;
    }
    @media screen and (max-width: 1100px) {
      width: 600px !important;
    }
    @media screen and (max-width: 900px) {
      width: 510px !important;
    }

    .overview_without_contact {
      fill: #fff;
      stroke-width: 1px;
      stroke: #eaedf3;
      border: 1px solid rgba(0, 0, 0, 0.04);
    }

    .overview_heading {
      // width: 825px;
      height: 74px;
      flex-shrink: 0;
      padding: 10px;
      border-bottom: 1px solid #eaedf3;
      display: flex;
      align-items: center;

      h1 {
        color: #3e3f42;

        font-family: Roboto;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
        /* 155.556% */
      }
    }

    .bio_container {
      .bio_heading {
        display: flex;
        align-items: center;

        h1 {
          color: #9ea0a5;

          font-family: Roboto;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 18px;
          /* 150% */
          text-transform: uppercase;
          padding: 10px;
        }
      }

      .bio_info {
        color: #6b6c6f;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        /* 157.143% */
        width: 764px;
        padding: 10px;
        margin-top: 20px;

        @media screen and (max-width: 1200px) {
          width: 655px !important;
        }
        @media screen and (max-width: 1100px) {
          width: 570px !important;
        }
        @media screen and (max-width: 900px) {
          width: 490px !important;
        }
      }

      .tags {
        margin-top: 20px;
        padding: 10px;

        h1 {
          color: #9ea0a5;

          font-family: Roboto;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 18px;
          /* 150% */
          text-transform: uppercase;
          padding: 10px;
        }
      }

      .tags_names {
        margin-top: 10px;
        padding: 10px;

        p {
          display: flex;
          padding: 3px 10px;
          align-items: center;
          gap: 10px;
          color: #fff;
          text-align: center;
          font-family: Roboto;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 18px;
          /* 150% */
          text-transform: uppercase;
          border-radius: 4px;
        }
      }
    }

    .hr_line {
      width: 765px;
      height: 1px;
      flex-shrink: 0;
      background-color: #eaedf3;
      margin: 30px;

      @media screen and (max-width: 1200px) {
        width: 600px !important;
      }
      @media screen and (max-width: 1100px) {
        width: 512px !important;
      }
      @media screen and (max-width: 900px) {
        width: 430px !important;
      }
    }

    .total_profit {
      margin-top: 20px;
      display: flex;
      // justify-content: space-between;
      padding: 10px;
      flex-wrap: wrap;
      gap: 20px;

      div {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 10px;

        h1 {
          color: var(--LP-black, #3e3f42);

          /* h3/Regular 24px */
          font-family: Roboto;
          font-size: 24px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }

    .contact_info {
      // width: 825px;
      height: 395px;
      margin-top: 50px;
      border: 1px solid #eaedf3;
      padding: 10px;

      @media screen and (max-width: 1100px) {
        height: max-content !important;
        padding-bottom: 20px !important;
      }

      .contact_info_heading {
        // width: 825px;
        height: 74px;
        flex-shrink: 0;
        border-bottom: 1px solid #eaedf3;
        padding: 10px;
        display: flex;
        align-items: center;

        h1 {
          color: #3e3f42;
          font-family: Roboto;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 28px;
          /* 155.556% */
        }
      }

      .contact_info_details {
        div {
          p {
            color: #3e3f42;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            /* 157.143% */
          }
        }

        .contact_type {
          display: flex;
          justify-content: space-between;
          width: 825px;
          height: 74px;
          flex-shrink: 0;

          @media screen and (max-width: 1200px) {
            width: 688px !important;
          }
          @media screen and (max-width: 1100px) {
            width: max-content !important;
            flex-direction: column;
            height: max-content !important;
          }

          div {
            display: flex;
            width: 375px;
            padding-top: 2px;
            gap: 21px;
            padding-top: 10px;
            padding-bottom: 10px;
            // border-bottom: 1px solid #eaedf3;

            @media screen and (max-width: 1200px) {
              width: 345px !important;
            }

            span {
              color: #9ea0a5;

              font-family: Roboto;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 18px;
              /* 150% */
            }
          }
        }
      }
    }

    .career {
      margin-top: 20px;
      padding: 10px;
      border: 1px solid #eaedf3;
      // width: 825px;

      .career_heading {
        // width: 825px;
        height: 60px;
        flex-shrink: 0;
        padding: 10px;
        border-bottom: 1px solid #eaedf3;

        h1 {
          color: #3e3f42;
          font-family: Roboto;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 28px;
        }
      }

      .career_info {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .career_item_1 {
          display: flex;
          gap: 20px;
          background: rgba(255, 255, 255, 0);
          box-shadow: 0px -1px 0px 0px #eaedf3 inset;
          padding-top: 20px;
          padding-bottom: 20px;

          .invest_info {
            display: flex;
            flex-direction: column;

            p {
              color: #3e3f42;
              font-family: Roboto;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 24px;
              /* 150% */
            }

            b {
              color: #3e3f42;
              font-family: Roboto;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 22px;
              /* 157.143% */
            }

            span {
              color: #9ea0a5;
              font-family: Roboto;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 18px;
              /* 150% */
            }
          }
        }

        .career_item_2 {
          display: flex;
          gap: 20px;
          background: rgba(255, 255, 255, 0);
          padding-bottom: 20px;

          .invest_info {
            display: flex;
            flex-direction: column;

            p {
              color: #3e3f42;
              font-family: Roboto;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 24px;
              /* 150% */
            }

            span {
              color: #3e3f42;
              font-family: Roboto;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 22px;
              /* 157.143% */
            }

            b {
              color: #9ea0a5;
              font-family: Roboto;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 18px;
              /* 150% */
            }
          }
        }
      }
    }

    .education {
      margin-top: 20px;
      padding: 10px;
      border: 1px solid #eaedf3;

      .education_heading {
        width: 100% !important;
        height: 74px;
        flex-shrink: 0;
        padding: 10px;
        border-bottom: 1px solid #eaedf3;

        h1 {
          color: #3e3f42;
          font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          /* 150% */
        }
      }

      .education_info {
        display: flex;
        flex-direction: column;

        p {
          color: #3e3f42;
          font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          /* 150% */
        }

        b {
          color: #3e3f42;
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px;
          /* 157.143% */
        }

        span {
          color: #9ea0a5;
          font-family: Roboto;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
          /* 150% */
        }

        .education_item_1 {
          display: flex;
          gap: 20px;
          //  padding-top: 20px;
          padding-bottom: 20px;
          box-shadow: 0px -1px 0px 0px #eaedf3 inset;
        }
      }
    }

    .licenses {
      margin-top: 20px;
      padding: 10px;

      .licenses_item {
        display: flex;
        align-items: center;
        gap: 20px;
        padding-top: 20px;
        padding-bottom: 20px;

        .licenses_desc {
          display: flex;
          flex-direction: column;

          p {
            color: #3e3f42;
            font-family: Roboto;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            /* 150% */
          }

          span {
            color: #9ea0a5;
            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            /* 150% */
          }
        }
      }

      .licenses_heading {
      }
    }

    .Investment {
      margin-top: 30px;
      // width: 825px;
      height: max-content;
      flex-shrink: 0;
      fill: #fff;
      stroke-width: 1px;
      stroke: #eaedf3;
      border: 1px solid #eaedf3;
      padding-left: 10px;
      padding-bottom: 10px;

      .investment_heading {
        // width: 825px;
        height: 74px;
        flex-shrink: 0;
        fill: rgba(255, 255, 255, 0);
        border-bottom: 1px solid #eaedf3;
        padding: 10px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        h1 {
          color: #3e3f42;
          font-family: Roboto;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 28px;
          /* 155.556% */
        }

        span {
          color: var(--LP-gray, #9ea0a5);
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
          /* 128.571% */
        }
      }

      .deal_match {
        display: flex;
        align-items: center;
        gap: 60px;
        padding-top: 20px;
        padding-left: 10px;

        .deal_item_1 {
          display: flex;
          align-items: center;
          gap: 18px;

          .total_deals {
            width: 34px;
            height: 34px;
            border-radius: 4px;
            background: var(--LP-gray, #9ea0a5);
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            text-align: center;
            font-family: Roboto;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            /* 150% */
          }

          p {
            color: #6b6c6f;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            /* 157.143% */
          }
        }

        .deal_item_2 {
          display: flex;
          align-items: center;
          gap: 18px;
        }
      }

      .property_holdPeriod {
        margin-top: 30px;
        padding: 10px;

        .property_type {
          width: 367px;
          flex-shrink: 0;

          h2 {
            margin-bottom: 10px;
            color: var(--LP-black, #3e3f42);
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px;
            /* 157.143% */
          }

          div {
            p {
              color: #3e3f42;
              font-family: Roboto;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 22px;
              /* 157.143% */
            }
          }
        }

        .hold_period {
          width: 367px;

          h2 {
            color: #3e3f42;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px;
            /* 157.143% */
            margin-bottom: 7px;
          }

          div {
            p {
              color: #3e3f42;
              font-family: Roboto;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 22px;
              /* 157.143% */
            }
          }
        }
      }

      .size_regions {
        margin-top: 20px;
        padding: 10px;

        h2 {
          color: var(--LP-black, #3e3f42);
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px;
          /* 157.143% */
        }

        .size__ {
          width: 367px;

          p {
            color: #3e3f42;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            /* 157.143% */
          }

          h2 {
            margin-bottom: 7px;
          }
        }

        .regions {
          width: 367px;

          p {
            color: #3e3f42;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            /* 157.143% */
          }

          h2 {
            margin-bottom: 7px;
          }
        }
      }
    }

    .investment_experience {
      width: 825px;
      height: 634px;
      flex-shrink: 0;
      padding: 10px;
      @media screen and (max-width: 1200px) {
        width: 100% !important;
        height: max-content !important;
      }

      // border: 1px solid #EAEDF3;
      .investment_experience_heading {
        width: max-content !important;
        height: 74px;
        flex-shrink: 0;
        padding: 10px;
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        h1 {
          color: #3e3f42;
          font-family: Roboto;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 28px;
          /* 155.556% */
        }
      }

      .investment_experience_details {
        display: grid;
        grid-template-columns: repeat(4, 1fr);

        @media screen and (max-width: 1200px) {
          grid-template-columns: repeat(3, 1fr);
        }
        @media screen and (max-width: 1100px) {
          grid-template-columns: repeat(2, 1fr);
        }

        .investment_experience_item {
          display: flex;
          height: 240px;
          padding: 20px;
          flex-direction: column;
          align-items: center;
          gap: 10px;
          flex: 1 0 0;

          p {
            color: #000;

            text-align: center;
            font-family: Roboto;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 28px;
            /* 155.556% */

            @media screen and (max-width: 1200px) {
              font-size: 16px !important;
            }
            @media screen and (max-width: 900px) {
              font-size: 15px !important;
            }
          }

          span {
            color: #6b6c6f;
            text-align: center;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            /* 157.143% */
          }
        }
      }
    }

    .recommended_syndicates {
      .syndicats_list {
        margin-top: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 30px;
        padding: 10px;
        flex-wrap: wrap;
      }

      .heading {
        display: flex;
        align-items: center;
        gap: 20px;
        padding: 10px;

        h1 {
          color: #3e3f42;

          /* h4/Regular 20px */
          font-family: Roboto;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        span {
          color: var(--LP-mid-grey-_lib, #6b6c6f);
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
          /* 157.143% */
        }
      }

      .show_btn {
        display: flex;
        width: 825px;
        padding: 8px 11px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 4px;
        border: 1px solid #d8dce6;
        background: linear-gradient(0deg, #f6f7f9 0%, #fff 100%);
        box-shadow: 0px 1px 1px 0px rgba(22, 29, 37, 0.05), 0px 2px 0px 0px rgba(255, 255, 255, 0.05) inset;
        margin: auto;
        margin-top: 60px;
      }

      .commercial_list {
        display: flex;
        align-items: flex-start;
        gap: 50px;
      }

      .single_commercial {
        width: 255px;

        flex-shrink: 0;
        border-radius: 4px;
        border: 1px solid var(--LP-very-light, #eaedf3);
        background: #fff;
        box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.15);
        padding: 6px;
        padding-bottom: 0px;
      }

      .commercial_slider_btns {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 0px 0px 4px 4px;
        opacity: 0.5;
        background: var(--LP-black, #3e3f42);
        padding: 5px;
        bottom: 5px;
        width: 100%;
        z-index: 19;
        color: white;

        p {
          color: #fff;
          font-family: Roboto;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        span {
        }
      }

      .single_commercial {
        span {
          border-radius: 4px 0px;
          background: var(--LP-very-light-_lib, #eaedf3);
          box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.1);
          display: inline-flex;
          padding: 5px 10px;
          align-items: center;
          gap: 10px;
          position: absolute;
          top: 0px;
          left: 0;
          font-size: 12px;
          color: var(--LP-mid-grey-_lib, #6b6c6f);
          text-align: center;
          font-family: Roboto;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        .heading_location {
          display: flex;
          flex-direction: column;
          padding: 10px;
          gap: 10px;

          h2 {
            font-size: 14px;
            font-weight: 500;
            font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
          }

          p {
            color: #9ea0a5;

            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            /* 133.333% */
          }
        }

        .offer_text {
          color: var(--LP-mid-grey-_lib, #6b6c6f);
          font-family: Roboto;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
          /* 150% */
          padding-left: 10px;
          margin-top: 10px;
        }

        .time_period {
          display: flex;
          align-items: center;
          justify-content: space-around;
          margin-top: 10px;

          div {
            p {
              color: var(--purple-pantone-2665-c, #7d55c7);
              text-align: center;
              font-family: Roboto;
              font-size: 24px;
              font-style: normal;
              font-weight: 300;
              line-height: 110%;
              /* 26.4px */
            }

            :nth-child(2) {
              color: var(--purple-pantone-2665-c-71, rgba(125, 85, 199, 0.71));
              text-align: center;
              font-family: Roboto;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 110%;
              /* 13.2px */
            }
          }
        }

        .LTV {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 10px;
          gap: 9px;

          div {
            display: flex;
            padding: 6px 7px;
            flex-direction: column;
            align-items: center;
            gap: 4px;
            flex: 1 0 0;
            border-radius: 4px;
            border: 0px solid var(--LP-very-light, #eaedf3);
            background: rgba(125, 85, 199, 0.15);

            p {
              color: var(--LP-mid-grey-_lib, #6b6c6f);
              text-align: center;
              font-family: Roboto;
              font-size: 10px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }

            h2 {
              color: var(--LP-black-_lib, #3e3f42);
              text-align: center;
              font-family: Roboto;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 18px;
              /* 128.571% */
            }
          }
        }

        .investments {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 10px;
          gap: 9px;

          div {
            display: flex;
            padding: 6px 2px;
            flex-direction: column;
            align-items: center;
            gap: 4px;
            flex: 1 0 0;
            align-self: stretch;
            border-radius: 4px;
            border: 1px solid var(--LP-very-light-_lib, #eaedf3);
            background: rgba(234, 237, 243, 0.5);

            h2 {
              color: var(--LP-black-_lib, #3e3f42);
              text-align: center;
              font-family: Roboto;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 18px;
              /* 128.571% */
            }

            p {
              color: var(--LP-mid-grey-_lib, #6b6c6f);
              text-align: center;
              font-family: Roboto;
              font-size: 10px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }
        }

        .raised {
          display: flex;
          align-items: center;
          justify-content: space-between;
          // margin: 10px;
          margin: 10px;
          margin-bottom: 0px;

          img {
            padding-left: 10px;
          }
        }

        .view_details {
          width: 100%;
          height: 38px;
          flex-shrink: 0;
          border-radius: 0px 0px 4px 4px;
          background: linear-gradient(0deg, rgba(125, 85, 199, 0.3) 0%, rgba(255, 255, 255, 0) 107.78%), #7d55c7;
          background-blend-mode: multiply, normal;
          border: none;
          margin-top: 15px;
          color: white;
          color: var(--ffffff-white, #fff);
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
          /* 157.143% */
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;
          margin-left: -6px;
        }
      }
    }
  }

  //  contact details ----------
}

.members {
  .pepole_work_here {
    padding: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #eaedf3;
    height: 90px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h1 {
      color: var(--LP-black, #3e3f42);

      /* h3/Regular 24px */
      font-family: Roboto;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    span {
      color: #9ea0a5;
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
      /* 150% */
      text-transform: uppercase;
    }
  }

  .peple_you_know {
    h1 {
      color: #3e3f42;
      font-family: Roboto;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
      /* 150% */
      height: 80px;
      display: flex;
      align-items: center;
      padding: 10px;
    }
  }

  .user_list {
    .userOne {
      display: flex;
      width: 375px;
      padding: 26px 20px;
      justify-content: space-between;
      align-items: center;

      @media screen and (max-width: 1100px) {
        flex-direction: column !important;
        align-items: start !important;
        gap: 14px !important;
        width: 220px !important;
      }
    }

    h2 {
      color: #3e3f42;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      /* 150% */
    }

    span {
      color: var(--LP-mid-grey-_lib, #6b6c6f);
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      /* 16.8px */
    }

    p {
      display: flex;
      padding: 4px 7px;
      align-items: center;
      gap: 7px;
      align-self: stretch;
      border-radius: 100px;
      border: 1px solid var(--d-4-d-4-d-4-light-grey, #d4d4d4);
    }
  }

  //   .location {
  //     width: 825px;
  //     height: 445px;
  //     flex-shrink: 0;

  //     .location_heading {
  //       h1 {
  //         color: #3e3f42;
  //         font-family: Roboto;
  //         font-size: 18px;
  //         font-style: normal;
  //         font-weight: 500;
  //         line-height: 28px; /* 155.556% */
  //         color: red;
  //       }
  //     }

  //   }
}

.location {
  .location_details {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 40px;
    @media screen and (max-width: 1100px) {
      flex-direction: column !important;
    }

    .headquarter {
      display: flex;
      flex-direction: column;
      width: 372px;
      padding: 8px 10px;
      align-items: flex-start;
      gap: 14px;
      border-radius: 4px;
      background: rgba(125, 85, 199, 0.1);
      position: relative;

      h1 {
        color: var(--LP-black-_lib, #3e3f42);
        font-family: Roboto;
        font-size: 22px;
        font-style: normal;
        font-weight: 300;
        line-height: 140%;
        /* 30.8px */
      }

      p {
        color: #3e3f42;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        /* 157.143% */
      }

      .primary_btn {
        position: absolute;
        top: 10px;
        right: 10px;
      }
    }

    .ny_office {
      display: flex;
      width: 350px;
      padding-right: 30px;

      flex-direction: column;
      gap: 14px;

      h1 {
        color: var(--LP-black-_lib, #3e3f42);
        font-family: Roboto;
        font-size: 22px;
        font-style: normal;
        font-weight: 300;
        line-height: 140%;
        /* 30.8px */
      }

      p {
        color: #3e3f42;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        /* 157.143% */
      }
    }
  }
}
