.asset-card {
  width: 100% !important;
  max-width: 333px !important;
  height: 524px;
  fill: #fff;
  border: 1px solid #eaedf3;
  stroke-width: 1px;
  stroke: #eaedf3;
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.04));

  &.sticky {
    position: fixed;
    top: 80px;
  }
}

.asset-card-inner {
  padding: 0px 20px 0 20px !important;
  box-sizing: border-box;
}

.contact-head-AssetCard {
  display: flex;
  // flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  padding: 20px 0;
  border-bottom: 1px solid #eaedf3;
  flex-direction: column;

  p {
    color: var(--LP-mid-gray, #6b6c6f);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }

  h2 {
    color: var(--purple-pantone-2665-c, #7d55c7);
    text-align: center;
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: 100%;
  }
}

.contact-head > span {
  color: #9ea0a5;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  text-transform: uppercase;
}

.asset-card-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 20px 0;

  div {
    display: flex;
    align-items: center;

    p {
      color: #9ea0a5;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      width: 160px;
    }
    span {
      color: #3e3f42;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
    }
  }
}

.start_chat_btn {
  padding-left: 10px !important;
}
