.task {
  display: flex;
  gap: 30px;
  background: #fbfbfd;
  height: 100%;

  .checkbox {
    padding-left: 0px !important;
    color: #3e3f42;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    align-items: start;
  }

  .timetable_bar {
    width: 285px;
    margin-right: 17px;
    background: #fff;
    box-shadow: 1px 0px 0px 0px #eaedf3;
    padding: 30px;

    .timetable_bar_header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 4px;
      border: 1px solid #d8dce6;
    }

    .timetable_bar_agenda {
      margin-top: 20px;

      .agenda_list {
        height: 250px;
        padding: 20px 0;
        display: flex;
        flex-direction: column;
        gap: 20px;
        /* align-items: center; */

        div {
          img {
            height: 18px;
            width: 18px;
          }

          p {
            display: flex;
            align-items: center;
            padding-left: 8px;
            gap: 4px;
            color: #6b6c6f;
            font-family: Roboto;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
          }
        }
      }
    }
  }

  .calendar {
    // width: 825px;
    width: 100%;
    border-radius: 4px;
    background: #fff;
    box-shadow: 1px 0px 0px 0px #eaedf3;
    border: 1px solid #eaedf3;
    margin-bottom: 30px;

    .calendar_header {
      height: 75px;
      padding: 20px;
      border-bottom: 1px solid #eaedf3;
      display: flex;
      justify-content: space-between;

      h3 {
        color: #3e3f42;
        font-family: Roboto;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
      }

      .btns {
        display: flex;
        align-items: center;
        gap: 12px;

        .add_btn {
          color: #ffffff;
          display: inline-flex;
          padding: 8px 20px;
          align-items: center;
          justify-content: center;
          gap: 12px;
          border-radius: 4px;
          border: 1px solid var(--purple-pantone-2665-c-71, rgba(125, 85, 199, 0.71));
          background: linear-gradient(0deg, rgba(125, 85, 199, 0.3) 0%, rgba(255, 255, 255, 0) 107.78%), #7d55c7;
          background-blend-mode: multiply, normal;
          box-shadow: 0px 1px 1px 0px rgba(19, 31, 21, 0.1), 0px 2px 0px 0px rgba(255, 255, 255, 0.06) inset;
        }
      }
    }
  }

  .task_sidebar {
    width: 270px;
    background: #fff;
    box-shadow: 1px 0px 0px 0px #eaedf3;
    padding: 20px;
    position: relative;
    @media (max-width: 1136px) {
      .hamburger-menu {
        display: block;
      }

      .task_sidebar {
        display: none;
      }

      .hamburger-menu.active + .task_sidebar {
        display: block;
      }
    }

    @media (min-width: 1136px) {
      .hamburger-menu {
        display: none;
      }
    }

    .task_sidebar_header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media screen and (max-width: 900px) {
        margin-top: 20px;
      }
      div {
        display: flex;
        gap: 12px;
      }
    }

    .task_calendar {
      padding-bottom: 20px;
      border-bottom: 1px solid #eaedf3;
      margin: 20px 0;
    }

    .task_calendar_list {
      display: flex;
      flex-direction: column;
      gap: 12px;
      margin-top: 20px;
      transition: height 0.3s ease, opacity 0.3s ease;
    }
  }
}
.setting_image {
  width: 14px;
}

.add_project {
  border-radius: 4px;
  border: 1px solid #eaedf3;
  background: #fff;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.04);
  width: 324px;
  height: 673px;
  padding: 20px;
  position: absolute;
  top: 100px;
  left: 270px;
  z-index: 1111;

  .desc {
    .inputBoxMain input {
      height: 68px !important;
    }
  }

  .add_project_header {
    padding-bottom: 20px;
    border-bottom: 1px solid #eaedf3;

    h2 {
      color: #3e3f42;
      font-family: Roboto;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  .inputs {
    padding-top: 20px;

    span {
      color: #9ea0a5;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
    }

    .title {
      color: #9ea0a5;
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
      text-transform: uppercase;
      margin-top: 20px;
    }

    .attachment {
      border: 1px solid #eaedf3;
      border-radius: 3px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 84px;
      padding: 20px;

      p {
        display: flex;
        align-items: center;
        gap: 6px;
        color: #9ea0a5;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
      }
    }
  }

  .btns {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 12px;
    padding-top: 20px;

    .btn_cancel {
      outline: none;
      background: linear-gradient(#f6f7f9, #ffffff);
      border: 1px solid #d8dce6;
      border-radius: 4px;
      padding: 7px 10px;
    }

    .btn_create {
      color: #ffffff;
      display: inline-flex;
      padding: 7px 10px;
      align-items: center;
      justify-content: center;
      gap: 12px;
      border-radius: 4px;
      border: 1px solid var(--purple-pantone-2665-c-71, rgba(125, 85, 199, 0.71));
      background: linear-gradient(0deg, rgba(125, 85, 199, 0.3) 0%, rgba(255, 255, 255, 0) 107.78%), #7d55c7;
      background-blend-mode: multiply, normal;
      box-shadow: 0px 1px 1px 0px rgba(19, 31, 21, 0.1), 0px 2px 0px 0px rgba(255, 255, 255, 0.06) inset;
    }
  }
}

.task_sidebar_list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 66px;
  cursor: pointer;
  .list_left {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  .task_initials {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 38px;
    width: 38px;
    border-radius: 4px;
    color: #fff;
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    background: #34aa44;
  }

  .task_content {
    h3 {
      color: #3e3f42;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
    }

    p {
      color: #9ea0a5;
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
    }
  }
}

.title {
  color: #9ea0a5;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  text-transform: uppercase;
}

.task_sidebar {
  
  .hamburger-menu {
    display: none;
    cursor: pointer;
    div {
      width: 30px;
      height: 3px;
      background-color: #333;
      margin: 6px 0;
    }
  }
}

.task_sidebar.show {
  display: block;
}

.hamburger-menu.active + .task_sidebar {
  display: block;
}

@media (min-width: 1136px) {
  .hamburger-menu {
    display: none;
  }
}

.Task-flex {
  display: flex;
  gap: 20px;
  width: 100%;
  padding: 10px;
  @media screen and (max-width: 1280px) {
    flex-direction: column;
  }
}

/* Add this CSS to your stylesheet */
.arrow-up {
  transition: transform 0.3s ease;
  cursor: pointer;
}

.rotate-up {
  transform: rotate(180deg);
}

.agenda_list {
  max-height: 40vh;
  overflow-y: auto;
  padding-right: 10px;
}

.agenda_list::-webkit-scrollbar {
  width: 8px;
}

.agenda_list::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

.agenda_list::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

// .fc-h-event .fc-event-main{
//    padding: 6px;
// }


.button_task_left{
  background: none;
  outline: none;
  border-radius: 4px;
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 38px;
  width: 225px;
  color: #3e3f42;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.button_task_right{
  background: none;
  outline: none;
  border-radius: 4px;
  border: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 38px;
  width: 225px;
  color: #3e3f42;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}