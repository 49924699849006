.searches {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    flex-direction: column;
    @media screen and (max-width: 900px) {
        width: 450px;
      }
    .searchbox {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
        border: 1px solid #EAEDF3;
        background: #FFF;
        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.04);
        width: 100%;
        padding: 20px;
        border-radius: 4px;
        flex-wrap: wrap;

        h2 {
            color: #3E3F42;
            font-family: Roboto;
            font-size: 18px;
            font-weight: 500;
            line-height: 28px;
        }

        p {
            color: #6B6C6F;
            font-family: Roboto;
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
        }

        span {
            color: var(--purple-pantone-2665-c, #7D55C7);
            text-align: center;
            font-family: Roboto;
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
            display: flex;
            align-items: center;
            gap: 4px;
        }
    }

    .searchbox_left{
        >div{
            display: flex;
            align-items: center;
            gap: 12px;
        }
    }
    .searchbox_right {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;

        button {
            background-color: #7D55C7;
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 12px;
            height: 38px;
            outline: none;
            border: none;
            padding: 20px;
            font-size: 14px;
            text-wrap: nowrap;
            border-radius: 4px;
        }
    }
    .dropDownOuterCon{
        margin-top: 0px !important;
        p{
            display: none;
        }
        .dropDownMain{
            margin-top: 0px !important;
            height: 42px !important;
        }
    }
}