.dialog-title-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 8px;
  }
  
  .dialog-error-message {
    color: red;
    text-align: center;
    font-size: 0.9rem;
    margin-bottom: 12px;
  }
  
  .avatar-container {
    // display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 16px;
  }
  
  .avatar-name-box {
    background-color: #f0f0f0;
    padding: 6px 12px;
    border-radius: 8px;
  }
  
  .project-input {
    margin-bottom: 8px;
  }
  
  .submit-button {
    text-transform: none;
    background-color: #4caf50;
    color: white;
    padding: 10px;
    border-radius: 4px;
    font-size: 1rem;
    font-weight: bold;
    transition: background-color 0.3s ease;
  
    &:hover {
      background-color: #388e3c;
    }
  }
  
  .icon-buttons-container {
    display: flex;
    gap: 8px;
  }
  
  .icon-buttons-container button {
    color: #666;
    transition: color 0.3s ease;
  
    &:hover {
      color: #333;
    }
  }
  
  .form-buttons-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    gap: 10px;
  }
  .MuiDialogTitle-root{
    background: none;
  }