.basic {
    border: 1px solid #EAEDF3;
    filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.04));

    .basic_header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        border-bottom: 1px solid #EAEDF3;
        padding: 20px;

        >div {
            display: flex;
            flex-direction: column;
            gap: 12px;
        }

        p {
            color: #3E3F42;
            font-family: Roboto;
            font-size: 18px;
            font-weight: 500;
            line-height: 28px;
        }

        span {
            color: #9EA0A5;
            font-family: Roboto;
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
        }
    }

    .inputs {
        color: #9EA0A5;
        font-family: Roboto;
        font-size: 14px;
        font-weight: 400;
        padding: 20px;
        border-bottom: 1px solid #EAEDF3;

        .input_box {
            border-bottom: 1px solid #EAEDF3;
            padding-bottom: 20px;
        }

        .title {
            color: #3E3F42;
            font-family: Roboto;
            font-size: 16px;
            font-weight: 500;
            line-height: 28px;
            margin-bottom: 20px;
        }

        span {
            color: var(--LP-black, #3e3f42);
            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px;
            text-transform: uppercase;
            opacity: 0.5;
            margin-bottom: 7px;
        }

        .input_lower {
            margin-top: 20px;

            textarea {
                width: 100%;
                border: 1px solid #eaedf3;
                height: 71px;
                resize: none;
            }
        }
    }


}