.CreateNewSAFE-Container {
  display: flex;
  gap: 20px;
}
@media screen and (max-width: 900px) {
  .CreateNewSAFE-Container {
    flex-direction: column;
  }
}
.Container-header {
  border: 1px solid #eaedf3;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.04);
}
.Container-header-data {
  padding: 10px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.flex-container {
  border: 1px solid #eaedf3;
  width: 50%;
  height: fit-content;
}

@media screen and (max-width: 900px) {
  .flex-container {
    width: 100%;
  }
}
.flex-container-data {
  margin: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.flex-container-data-flex {
  display: flex;
  gap: 30px;
  align-items: center;
}

.flex-container-data p {
  color: var(--primary-gray-100-text, #9ea0a5);
  leading-trim: both;
  text-edge: cap;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.Create-data {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.hr-tag-background {
  height: 10px;
  background: var(--purple-pantone-2665-c, #7d55c7);
}
.CreateNewSAFE-checkbox {
  display: flex;
  gap: 20px;
}
.Container-header-data p {
  color: var(--primary-gray-100-text, #9ea0a5);
  leading-trim: both;
  text-edge: cap;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
  text-transform: capitalize;
}

.Container-header-data span {
  color: var(--Primary-Gray-200, #9ea0a5);
  leading-trim: both;
  text-edge: cap;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
  text-transform: uppercase;
}
.input-text {
  width: 200px;
  height: 30px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid var(--LP-very-light--_lib, #eaedf3);
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(102, 113, 123, 0.21) inset;
  padding: 10px;
}
.CreateNewSAFE-Filter {
  padding: 0;
  margin: 0;
  height: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  border: none;
  color: black;
  background-color: white;
  border-radius: 4px;
  border: 1px solid var(--Primary-Purple-50, #eaedf3);
  background: #fff;
  width: 150px;
}
