.background-color {
  padding: 20px;
  border: 1px solid #eaedf3;
}
Conatiner-Boardmeetings-data .Container-Header-data {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Container-Header-button {
  display: flex;
  gap: 20px;
}

.Boardmeetings-Meeting-data {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.Container-Boardmeetings-time {
  display: flex;
  gap: 20px;
  align-items: center;
}
.Container-Boardmeetings-Team-member {
  display: flex;
  align-items: center;
  gap: 10px;
}
.Boardmeetings-Header {
  display: flex;
  gap: 20px;

  // Add padding and align-items as needed for better appearance
  padding: 10px; 
  @media (max-width: 1208px) {
    flex-direction: column;
    padding: 20px; 
  }
}

.Conatiner-Boardmeetings-box {
  border: 1px solid #eaedf3;
  height: fit-content;
}
.Conatiner-Boardmeetings-Better {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.Conatiner-Boardmeetings-Better p {
  color: var(--primary-gray-100-text, #9ea0a5);
  leading-trim: both;
  text-edge: cap;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}
.Conatiner-Boardmeetings-data-button {
  display: flex;
  gap: 20px;
}
.Boardmeetings-header-data {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
// box

.Boardmeetings-rules-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 20px;

  margin-top: 20px;
}

.Boardmeetings-rules-container-data {
  border: 1px solid var(--Primary-Purple-50, #eaedf3);
}
.Boardmeetings-rules-container-second {
  display: flex;
  flex-direction: column;
  gap: 20px;
  border: 1px solid var(--Primary-Purple-50, #eaedf3);
}

.Boardmeetings-rules-container-thid {
  border: 1px solid var(--Primary-Purple-50, #eaedf3);
}
.Boardmeetings-rules-container-datafirst {
  padding: 20px;
  text-align: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
.Boardmeetings-rules-container-secondbox {
  padding: 20px;
  text-align: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
.Boardmeetings-rules-container-secondbox p {
  color: var(--Primary-Gray-200, #9ea0a5);
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 157.143% */
}
.Boardmeetings-rules-container-thiddata {
  padding: 20px;
  text-align: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
.Boardmeetings-pen {
  padding: 0;
  margin: 0;
  height: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  border: none;
  color: black;
  font-size: 10px;
  border-radius: 4px;
  border: 1px solid var(--Primary-Purple-50, #eaedf3);
  background: white;
}

.CaptableStake-Filter {
  padding: 0;
  margin: 0;
  height: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  border: none;
  color: black;
  width: 180px;
  background-color: white;
  border-radius: 4px;
  border: 1px solid var(--Primary-Purple-50, #eaedf3);
  background: #fff;
}

.Boardmeetings-Add {
  padding: 0;
  margin: 0;
  height: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  border: none;
  color: black;
  background-color: white;
  border-radius: 4px;
  border: 1px solid var(--Primary-Purple-50, #eaedf3);
  background: #fff;
}
.Boardmeetings-flex {
  display: flex;
  width: 550px;
  padding: 5px;
  justify-content: space-between;
  align-items: center;
}
.Container-Header-meeting {
  display: flex;
}
.meeting-time p {
  color: var(--Black, #3e3f42);
  leading-trim: both;
  text-edge: cap;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 183.333% */
}
.Boardmeetings-data {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  gap: 20px;
}
.Boardmeetings-Team {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 5px;
}
.Boardmeetings-Upgrade {
  padding: 0;
  margin: 0;
  height: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  border: none;
  color: rgb(255, 255, 255);
  width: 150px;
  border-radius: 4px;
  background: var(--Primary-Purple-500, #7d55c7);
}
.Boardmeetings-copylink {
  padding: 0;
  margin: 0;
  height: 40px;
  color: black;
  align-items: center;
  display: flex;
  justify-content: center;
  border: none;
  width: 150px;
  border-radius: 4px;
  border: 1px solid #eaedf3;
  background: white;
}
.Boardmeetings-Plan {
  padding: 0;
  margin: 0;
  height: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  border: none;
  color: black;
  width: 150px;
  background-color: white;
  border-radius: 4px;
  border: 1px solid var(--Primary-Purple-50, #eaedf3);
  background: #fff;
}
.calander-image{
  width: 100%;
}
.Boardmeetings-calender-data{
  display: flex;
}
.backcolor-data{
  display: flex;
}
.calander{
  padding: 50px;
}
.calander-image{
  height: fit-content;
}
.Boardmeetings-Meeting{
  display: flex;
  gap: 10px;
  margin-top: 10px;
}