.process-keyword-message {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  
    .message-bubble {
      max-width: 80%;
      padding: 15px 20px;
      border-radius: 12px;
      font-size: 16px;
      line-height: 1.7;
      word-wrap: break-word;
  
      &.plain-background {
        background-color: #7d55c7;
        color: white;
      }
  

      p > strong {
        display: block; 
        font-weight: bold;
        font-size: 18px; 
        margin-top: 20px;
        margin-bottom: 10px; 
        color: #f2f2f2; 
      }
  
     
      p {
        margin: 10px 0; 
        color: #ffffff; 
      }
  
      ul,
      ol {
        margin: 10px 0 20px;

       
      }
  
      li {
        margin-bottom: 10px;
      }
  
      blockquote {
        margin: 15px 0;
        padding-left: 15px;
        border-left: 4px solid #ccc;
        color: #ddd;
        font-style: italic;
      }
    }
    ol li p strong{
       
    }
  }
  