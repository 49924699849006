.admin_settings {
  display: flex;
  justify-content: space-between;
  gap: 20px;

  @media screen and (max-width:1000px) {
    flex-direction: column;
    gap: 40px !important;

  }

  .settings_left {
    width: 40% !important;
    border-radius: 4px;
    border: 1px solid #eaedf3;
    background: #fff;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.04);

    @media screen and (max-width:1000px) {
      width: 50% !important;
      
    }

    .settings_left_upper {
      border-bottom: 1px solid #eaedf3;

      .settings_left_upper_img_wrapper {
        background-color: #f7f8fa;
        // width: 350px;
        height: 290px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 27px;
        justify-content: flex-end;

        div {
          display: flex;
          justify-content: center;
        }
      }

      div {
      }

      p {
        color: #3e3f42;
        font-family: Roboto;
        font-size: 22px;
        font-weight: 500;
        line-height: 32px;
      }

      span {
        color: #9ea0a5;
        font-family: Roboto;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
      }
    }

    .settings_left_lower {
      padding-top: 20px;

      > span {
        color: #9ea0a5;
        font-family: Roboto;
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        text-transform: uppercase;
        padding: 30px;
        margin-bottom: 20px;
      }

      > div {
        margin-top: 20px;
      }

      .left_menu_title {
        color: #6b6c6f;
        font-family: Roboto;
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
      }

      .title_menu {
        color: #6b6c6f;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
      }

      .title_menu_active {
        color: #ffffff !important;
      }

      .admin_menu_active {
        background-color: #004f71;
        width: 89%;
        margin: auto;
        border-radius: 4px;
        color: #ffffff !important;
        padding: 13px;
      }
    }
  }

  .settings_right {
    width: 70% !important;

    @media screen and (max-width:1000px) {
      width: 100% !important;
      
    }
  }

  .lower {
    padding: 30px 20px;

    span {
      color: #9ea0a5;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
    }
  }
}

.basic_header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 1px solid #eaedf3;
  padding: 20px;

  > div {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  p {
    color: #3e3f42;
    font-family: Roboto;
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
  }

  span {
    color: #9ea0a5;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
  }
}

.genral-input {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
 


  @media screen and (max-width: 980px) {
    .basic_profile_input {
      width: 100% !important;
    }
  }
}


@media screen and (max-width: 908px) {
  .admin_settings {
    .settings_left {
      width: 550px !important;
    }

    .settings_right {
      width: 550px !important;
    }
  }
}
@media screen and (max-width: 808px) {
  .admin_settings {
    .settings_left {
      width: 500px !important;
    }

    .settings_right {
      width: 500px !important;
    }
  }
}
@media screen and (max-width: 708px) {
  .admin_settings {
    .settings_left {
      width: 450px !important;
    }

    .settings_right {
      width: 450px !important;
    }
  }
}
@media screen and (max-width: 608px) {
  .admin_settings {
    .settings_left {
      width: 350px !important;
    }

    .settings_right {
      width: 350px !important;
    }
  }
}

@media screen and (max-width: 480px) {
  .admin_settings {
    .settings_left {
      .settings_left_upper_img_wrapper {
        height: 200px;
      }
    }
  }
}
