.gp_profile_wrapper {
  display: grid;
  grid-template-columns: 73% 24%;
  grid-gap: 30px;
  margin-left: 40px;
  @media screen and (max-width: 1100px) {
    grid-template-columns: 66% 28%;
  }

  @media screen and (max-width: 900px) {
    grid-template-columns: 93%;
    margin-left: 18px;
  }

  .gp_profile_top {
    background-color: #fff;
    border: 1px solid #eaedf3;
    filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.04));
    position: relative;
  }

  .img_text {
    position: absolute;
    bottom: 100px;
    left: 30px;

    p {
      color: #fff;
      font-family: Roboto;
      font-size: 22px;
      font-style: normal;
      font-weight: 500;
      line-height: 32px;
      /* 145.455% */
    }

    span {
      color: #fff;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      /* 157.143% */
    }
  }

  .gp_profile_top_nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    padding: 0 30px;

    .top_nav_left {
      display: flex;
      align-items: center;
      gap: 20px;
      span {
        color: #9ea0a5;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 150% */
      }
    }

    button {
      background-color: transparent;
      border: 1px solid rgba(216, 220, 230, 1);
      width: 131px;
      height: 38px;
      color: #3e3f42;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      /* 157.143% */
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 0 20px;
    }
  }

  .gp_nav {
    max-width: 200px;
    margin: 26px 0 0 0;

    span {
      color: #6b6c6f;
      text-align: right;
      font-family: Roboto;
      font-size: 16px !important;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      /* 150% */
      /* 150% */
    }

    .ut-nav-active {
      color: #3e3f42;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      /* 150% */
    }
  }

  .gp_profile_center {
    margin-top: 30px;
  }

  .gp_profile_left {
    @media screen and (min-width: 1450px) {
      min-width: 825px;
      width: 100%;
    }
    width: 100%;
  }

  .gp_profile_right {
    h2 {
      color: #3e3f42;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 171.429% */
    }
    .contact_info {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
    span {
      color: var(--LP-black, #3e3f42);
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 157.143% */
      display: flex;
      align-items: center;
      gap: 15px;
    }
  }

  .about {
    display: flex;
    gap: 30px;
    @media screen and (max-width: 1100px) {
      flex-direction: column;
    }

    h4 {
      color: #9ea0a5;
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
      /* 150% */
      text-transform: uppercase;
    }

    .about_left {
      max-width: 540px;

      .about_text_wrapper {
        margin-top: 10px;
        padding-bottom: 30px;
        border-bottom: 1px solid #eaedf3;

        span {
          color: #9ea0a5;
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
          /* 157.143% */
        }

        p {
          margin-top: 19px;
          color: #6b6c6f;
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
          /* 157.143% */
        }
      }

      h1 {
        color: #3e3f42;
        font-family: Roboto;
        font-size: 26px;
        font-style: normal;
        font-weight: 400;
        line-height: 38px;
        /* 146.154% */
      }
    }
  }

  .about_data {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-bottom: 30px;
    padding-top: 20px;
    border-bottom: 1px solid #eaedf3;

    .data_item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      p {
        color: #3e3f42;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        /* 157.143% */
      }
    }
  }

  .about_right {
    .about_right_header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      p {
        color: #9ea0a5;
        text-align: center;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        /* 157.143% */
      }
    }

    .about_right_img_box {
      margin-top: 24px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      @media screen and (max-width: 1100px) {
        max-width: fit-content;
        flex-direction: row;
        img {
          width: 100%;
          height: 100px !important;
        }
        .img_wrapper_1,
        .img_wrapper_2 {
          display: none !important;
        }
      }

      .img_wrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 10px;
      }
    }
  }

  .investment_data {
    margin-top: 24px;

    h1 {
      color: #3e3f42;
      font-family: Roboto;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
      /* 150% */
      margin-bottom: 24px;

      span {
        color: var(--LP-gray, #9ea0a5);
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px;
        /* 187.5% */
      }
    }

    .investment_data_box {
      display: flex;
      flex-direction: column;
      gap: 30px;

      .row1,
      .row2 {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 30px;
      }
    }

    .data_box_wrapper {
      h3 {
        color: var(--LP-black, #3e3f42);
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        /* 157.143% */
      }

      .data_box_item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        p {
          color: #3e3f42;
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
          /* 157.143% */
        }
      }

      .data_box {
        padding-top: 10px;
        margin-top: 10px;
        border-top: 1px solid #eaedf3;
      }
    }
  }

  .gp_investment {
    @media screen and (max-width: 1100px) {
      max-width: calc(100vw - 270px);
      overflow: auto;
    }
    .gp_nav {
      margin: 6px 0 0 0;
      height: 47px !important;
    }

    .upper {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;
      height: 74px;
      @media screen and (max-width: 1100px) {
        display: flex;
        justify-content: flex-start;
      }

      .upper_heading {
        height: 40px;
        padding-right: 30px;
        border-right: 1px solid rgba(234, 237, 243, 1);
        margin-top: 20px;
      }

      h2 {
        margin-right: 6px;
        color: #3e3f42;
        font-family: Roboto;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
        /* 155.556% */
      }
    }
  }
}
