.search-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}

.search-form {
  position: relative;
}

.search-label {
  display: flex;
  align-items: center;
}

.search-field {
  background-color: white;
  background-image: url('../../../assets//svg/search-svgrepo-com.svg');
  background-position: 5px center;
  background-repeat: no-repeat;
  background-size: 24px 24px;
  border: none;
  height: 40px;
  margin: 3px 0;
  padding: 0 0 0 34px;
  min-width: 40px;
  width: 40px;
  border-radius: 20px;
  transition: width 400ms ease, background 400ms ease, box-shadow 400ms ease;
  cursor: pointer;
}

.search-field:focus {
  border: 1px solid #eaedf3;
  outline: 0;
  width: 250px;
  padding: 0 20px 0 34px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
}

.search-form .search-submit {
  display: none;
}
