.Convertibles-container-header {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.Convertibles-container {
  border: 1px solid var(--Primary-Purple-50, #eaedf3);
  background: rgba(125, 85, 199, 0.08);
}
.Convertibles-container-data {
  display: flex;
 justify-content: space-between;
  gap: 20px;
  padding: 10px;
}
.Convertibles-container-data p {
  color: var(--Black, #3E3F42);
  leading-trim: both;
  text-edge: cap;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  width: 50%;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}
.Dontshowagain {
  width: 150px;
  background-color: white;
  border: none;
  display: flex;
  height: 38px;
  padding: 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.Convertibles-container-data-button {
  display: flex;
  gap: 20px;
  padding: 10px;
}
.financing {
  margin: 0;
  padding: 0;
  margin-left: 20px;
  height: fit-content;
  padding: 10px;
  border: 1px solid var(--purple-pantone-2665-c-71, rgba(125, 85, 199, 0.71));
  color: var(--ffffff-white, #fff);
  background: linear-gradient(0deg, rgba(125, 85, 199, 0.3) 0%, rgba(255, 255, 255, 0) 107.78%), #7d55c7;
}
.select-wrapper {
  position: relative;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  overflow: hidden;

  select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    border: none;
    outline: none;
    padding: 10px;
    font-size: 16px;
    width: 100%;
    cursor: pointer;
  }

  &::after {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    pointer-events: none;
  }
}

.CabTable-data {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.CabTable-data-flex {
  display: flex;
  gap: 20px;
  align-items: center;
}

/* Style the dropdown button */
.dropbtn {
  border: none;
  padding: 10px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  background: none;
}

/* Style the dropdown content (hidden by default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Style the links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #ddd;
}

.Cabtable-container {
  display: flex;
  justify-content: space-between;
}
.Cabtable-container-rest-data {
  display: flex;
  gap: 20px;
}

.Cabtable-container-data {
  justify-content: space-between;
  margin: 10px;
}
.Cabtable-container-first-data {
  display: flex;
  gap: 20px;
}

.container {
  display: flex;
  align-items: center;
}

.dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 8px;
}

.user-details {
  display: flex;
  justify-content: space-between;
  margin: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.user-details-container {
  border: 1px solid #ccc;
  padding-top: 10px;
  padding-bottom: 10px;
  align-items: center;
}

.color {
  display: flex;
  flex-direction: column;
  gap: 0px;
}
.CabTable-data {
  margin: 10px;
  padding: 10px;
}

.colordata {
  border: 1px solid #eaedf3;
}

.select-wrapper {
  position: relative;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  overflow: hidden;

  select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    border: none;
    outline: none;
    padding: 10px;
    font-size: 16px;
    width: 100%;
    cursor: pointer;
  }

  &::after {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    pointer-events: none;
  }
}

.CabTable-data {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.CabTable-data-flex {
  display: flex;
  gap: 20px;
  align-items: center;
}

/* Style the dropdown button */
.dropbtn {
  border: none;
  padding: 10px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  background: none;
}

/* Style the dropdown content (hidden by default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Style the links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #ddd;
}

.Cabtable-container-rest-data {
  display: flex;
  gap: 20px;
}

// Dropdown header

.container {
  display: flex;
  align-items: center;
}

.dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 8px;
}

.user-details {
  display: flex;
  justify-content: space-between;
  margin: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.user-details-container {
  border: 1px solid #ccc;
  padding-top: 10px;
  padding-bottom: 10px;
  align-items: center;
}

.color {
  display: flex;
  flex-direction: column;
  gap: 0px;
}
.CabTable-data {
  padding: 5%;
}

.colordata {
  border: 1px solid #eaedf3;
}
.CabTable-Filter {
  padding: 0;
  margin: 0;
  height: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  border: none;
  color: black;
  background-color: white;
  border-radius: 4px;
  border: 1px solid var(--Primary-Purple-50, #eaedf3);
  background: #fff;
}

.select-wrapper .option-icon::after {
  content: "\f062"; /* Unicode for the up arrow icon */
  font-family: "Font Awesome 5 Free";
  margin-left: 5px; /* Adjust spacing between text and icon */
}

.CabTable-container-first-data {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}
.CabTable-dropdown {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
}
.dropdown button {
  color: var(--primary-gray-100-text, #9ea0a5);
  leading-trim: both;
  text-edge: cap;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  text-transform: uppercase;
}
.Convertibles-Goto{
  padding: 0;
  margin: 0;
  height: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  border: none;
  color: white;
  background-color: white;
  border-radius: 4px;
  background: var(--Primary-Purple-500, #7D55C7);
}
.CaptableStake-show{
  padding: 0;
  margin: 0;
  height: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  border: none;
  color: black;
  background-color: white;
  border-radius: 4px;
  border: 1px solid var(--Primary-Purple-50, #eaedf3);
  background: #fff;
}
.user-details h5{
  color: #3E3F42;
  leading-trim: both;
  text-edge: cap;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 157.143% */
}
.CabTaConvertiblesble-Filter{
  padding: 0;
  margin: 0;
  height: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  border: none;
  color: black;
  background-color: white;
  border-radius: 4px;
  border: 1px solid var(--Primary-Purple-50, #eaedf3);
  background: #fff;
}