.deals-room {
  display: grid;
  grid-template-columns: 18% 1fr;
  position: relative;

  .center-wrapper {
    margin-left: 272px;

    .deals-room-center {
      width: 100%;

      .crowdfunding_header {
        top: 75px !important;
        max-width: 80vw;

        @media only screen and (min-width: 1600px) {
          max-width: 1120px;
        }
      }

      .crowdFunding_second_header {
        top: 140px !important;
        max-width: 80vw;
        @media only screen and (min-width: 1600px) {
          max-width: 1120px;
        }
      }

      .asset-main-container {
        margin-top: 150px;
      }
    }
  }
}

.deals-room-left {
  width: 270px;
  height: 1280px;
  border-right: 1px solid #92939777;
  position: fixed;
  // position: sticky;
  // top: 80px;

  @media screen and (max-width: 1300px) {
    width: 240px !important;
  }
}

.acqu {
  background: linear-gradient(0deg, rgba(240, 241, 245, 0.4) 0%, rgba(240, 241, 245, 0.4) 100%), #fff;
  padding-left: 30px;
  height: 67px;
  align-items: center;
  display: flex;
  gap: 17px;

  span {
    color: #3e3f42;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 157.143% */
  }
}

.deal-title {
  display: flex;
  gap: 16px;
  align-items: center;
  padding-left: 30px;
  margin-top: 11px;
}

.deal-left-img img {
  width: 38px;
  height: 38px;
  border-radius: 4px;
}

.deal-left-img-text > span:first-child {
  color: #3e3f42;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 157.143% */
  display: block;
}

.deal-left-img-text > span:last-child {
  width: 135px;
  display: block;
  color: #9ea0a5;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
}

.deal-room-contact {
  width: 100%;
  padding: 12px 15px;
  margin: 10px 15px;
  border-radius: 8px;
  background: linear-gradient(0deg, rgba(139, 131, 153, 0.05) 0%, rgba(139, 131, 153, 0.05) 100%), #fff;
}

.deal-room-contact > span {
  color: #9ea0a5;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
  text-transform: uppercase;
  display: block;
}

.deal-contact-detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.deal-contact-detail-left > span:nth-child(1) {
  color: #3e3f42;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
  display: block;
}

.deal-contact-detail-left > span:nth-child(2) {
  color: #6b6c6f;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 183.333% */
}

.deal-contact-detail-right {
  display: flex;
  gap: 10px;
  // overflow: hidden;
}

.deal-room-left-menu > span {
  color: #9ea0a5;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
  text-transform: uppercase;
  margin-left: 30px;
}

.deals-left-menu {
  cursor: pointer;
  box-sizing: border-box;
  width: 100%;
  height: 46px;
  // flex-shrink: 0;
  // padding: 13px 0 13px 30px;
  display: flex;
  gap: 20px;
  align-items: center;
  border-left: 3px solid transparent;

  &.deals-menu-active {
    width: 100%;
    // background: rgba(125, 85, 199, 0.05);
    background-color: #6b6c6f;
    box-shadow: none;
    border-left: 3px solid #6758f3;

    color: var(--purple-pantone-2665-c, #7d55c7);
    .clickable-svg {
      &.clicked {
        rect {
          fill: #4700cb;
        }
        path {
          fill: #602fbc;
          fill-opacity: 1;
        }
      }
    }
  }

  &.deals-menu-active-sort {
    background: rgba(125, 85, 199, 0.05);
    color: var(--purple-pantone-2665-c, #7d55c7);

    .clickable-svg {
      &.clicked {
        rect {
          fill: #602fbc;
        }
        path {
          fill: #602fbc;
          fill-opacity: 1;
        }
      }
    }
  }

  .left-menu-title {
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
  }
}

.left-menu-sort {
  padding-left: 67px !important;
  .left-menu-title {
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
  &.deals-menu-active-sort,
  &:active {
    background: rgba(125, 85, 199, 0.05); /* Set the background color for active state */
    span {
      color: var(--purple-pantone-2665-c, #7d55c7); /* Set the text color for active state */
    }
  }
}

.drop {
  padding-right: 20px;
  > div {
    display: flex;
    gap: 20px;
  }
}

/* =================================== */
/* DEALS ROOM CENTER STYLING */
/* =================================== */
.deals-room-center {
  width: 1120px;
  min-height: 1900px;
}

.deals-room-center-header {
  height: 67px;
  background: #fff;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.04);
  display: flex;
  align-items: center;
  position: fixed !important;
  top: 74px !important;
  z-index: 100;
  width: 100%;
  max-width: 78vw !important;
  .left {
    p {
      color: var(--LP-black, #3e3f42);
      font-family: Roboto;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 30px; /* 150% */
      margin: 22px 0 14px 0;
    }
  }
  .right {
    display: flex;
    gap: 20px;
  }

  > p {
    color: var(--LP-black, #3e3f42);
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px; /* 150% */
    margin: 22px 0 14px 0;
  }
}

.deals-room-center-del {
  margin: 30px 0 0 30px;

  &.lender_responses {
    margin: 0px;
    display: flex;
    .left {
      .search_section {
        border: 1px solid #eaedf3;
        height: 100%;

        .search_bar {
          // margin-left: -20px;
          padding: 22px 30px;
          border-bottom: 1px solid #eaedf3;
          display: flex;
          input {
            all: unset;
          }
          svg {
            margin-bottom: -5px;
          }
        }
        .letter {
          padding: 15px 30px;
          border-bottom: 1px solid #eaedf3;
        }
        .contact {
          padding: 22px 30px;
          border-bottom: 1px solid #eaedf3;
          display: flex;
          gap: 20px;
          &.active {
            border-left: 4px solid #7d55c7;
          }
          .text_wrapper {
            p {
              color: #3e3f42;
              font-family: Roboto;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 24px; /* 150% */
            }
            span {
              color: #6b6c6f;
              font-family: Roboto;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 22px; /* 157.143% */
              display: flex;
              align-items: center;
              gap: 5px;
            }
          }
        }
      }
    }
    .right {
      padding: 25px 30px;
      width: 100%;
      .deals-room-doc {
        width: 100%;
      }
    }
  }
}
.deals-room-stats {
  display: flex;
  justify-content: space-between;
  padding: 20px 0px;
  .statsbox {
    justify-content: flex-start;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    h2 {
      color: var(--LP-black, #3e3f42);

      /* h3/Medium 24px */
      font-family: Roboto;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      span {
        color: var(--Green-2, #27ae60);
        text-align: center;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 150% */
        text-transform: uppercase;
      }
    }
    p {
      color: var(--LP-mid-grey-_lib, #6b6c6f);
      text-align: center;
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px; /* 150% */
      text-transform: uppercase;
    }
  }
}
.deals-room-heading {
  display: flex;
  margin: 0 0 20px 0;
  gap: 24px;
  // justify-content: space-between;
  .heading_left {
    display: flex;
    gap: 20px;
  }
  .heading_right {
    display: flex;
    gap: 10px;
    .button {
      height: 40px;
      display: flex;
      padding: 8px 11px;
      align-items: center;
      gap: 10px;
      border-radius: 4px;
      border: 1px solid #d8dce6;
      background: linear-gradient(0deg, #f6f7f9 0%, #fff 100%);
      box-shadow: 0px 1px 1px 0px rgba(22, 29, 37, 0.05), 0px 2px 0px 0px rgba(255, 255, 255, 0.05) inset;
    }
  }
}

.deals-room-heading-left {
  width: 100px;
  height: 100px;
  border-radius: 50%; /* Adjusted to make it a circle */
  display: flex;
  justify-content: center;
  background: url(../../assets/dealsroom/images/BigPic.png), lightgray 0px -8.14px / 100% 116.279% no-repeat;

  p {
    color: var(--ffffff-white, #fff);
    font-family: Roboto;
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 83.333% */
    padding-top: 26px;

    span {
      color: var(--ffffff-white, #fff);
      font-family: Roboto;
      font-size: 36px;
      font-style: normal;
      font-weight: 900;
      line-height: 30px;
    }
  }
}

.deals-room-heading-right-top {
  display: flex;
  align-items: center;
  gap: 8px;

  > span {
    color: #3e3f42;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px; /* 150% */
  }
}

.deals-room-heading-right-detail > p {
  color: #6b6c6f;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}

.deals-room-heading-right-bottom {
  display: flex;
  align-items: center;
  margin-top: 13px;
  gap: 33px;
}

.deals-room-heading-right-text {
  display: flex;
  gap: 12px;
  align-items: center;

  .dls-room-head-back {
    border-radius: 4px;
    background: var(--LP-gray, #9ea0a5);
    padding: 6px 8px;

    > span {
      color: #fff;
      text-align: center;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
    }
  }

  span:nth-child(2) {
    color: #6b6c6f;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
  }
}

.deals-room-heading-right-img {
  display: flex;
  gap: 12px;
  align-items: center;

  span {
    color: #3e3f42;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 14px */
  }
}

/* =================================== */
/* DEALS ROOM NAVIGATION */
/* =================================== */
.deals-room-nav {
  // width: 600px;
  // width: 100% !important;
  width: 100% !important;
  height: 47px;
  display: flex;
  gap: 25px;
  // justify-content: space-between;
  margin-top: 20px;
  border-bottom: 1px solid var(--LP-very-light--_lib, #eaedf3);
}

.deals-room-nav span {
  color: var(--LP-mid-grey-_lib, #6b6c6f);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  text-transform: capitalize;
  text-wrap: nowrap;
  cursor: pointer;
}

.deals-room-nav-active {
  border-bottom: 2px solid blueviolet;
}

.deals-room-nav-detail {
  width: 505px;
  height: 60px;
  margin-top: 30px;
}

.deals-room-nav-detail > span {
  color: #9ea0a5;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
  text-transform: uppercase;
  display: block;
}

.deals-room-nav-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.deals-room-nav-contact-details {
  display: flex;
  align-items: center;
  color: #3e3f42;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
  gap: 14px;
}

.deals-room-nav-det-btn {
  display: flex;
  width: 198px;
  height: 38px;
  justify-content: space-around;
  align-items: center;
  margin-top: 30px;
  border-radius: 4px;
  background-color: #7d55c7;

  span {
    color: #fff;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
  }
}

.deals-room-nav-bio {
  width: 540px;
  // height: 163px;
  margin-top: 30px;
  border-bottom: 1px solid #eaedf3;
  padding-bottom: 20px;
}

.deals-room-nav-bio span:nth-child(1) {
  color: #9ea0a5;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
  text-transform: uppercase;
}

.deals-room-nav-bio p {
  color: #6b6c6f;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
  margin: 10px 0 20px 0;
}

.deals-room-nav-bio span:nth-child(3) {
  color: #9ea0a5;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
  text-transform: uppercase;
}

.deals-room-nav-bio-button {
  display: flex;
  gap: 6px;
}

.deals-room-nav-bio-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 7px;

  span {
    color: #fff !important;
    text-align: center;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 150% */
    text-transform: uppercase;
    padding: 3px 11px;
  }
}

.bio-btn-first {
  background-color: #34aa44;
  border-radius: 4px;
}

.bio-btn-second {
  background-color: #f6ab2f;
  border-radius: 4px;
}

.bio-btn-third {
  background-color: #1665d8;
  border-radius: 4px;
}

.deals-room-nav-fact-box {
  height: 230px;
  width: 540px;
  display: flex;
  gap: 0 40px;
  flex-wrap: wrap;
  padding: 20px 0 20px 0;
  border-bottom: 1px solid #eaedf3;
}

.deals-room-nav-fact {
  width: 150px;
  height: 97px;
}

.deals-room-nav-fact p:nth-child(1) {
  color: #9ea0a5;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
  text-transform: uppercase;
}

.deals-room-nav-fact p:nth-child(2) {
  color: var(--LP-black, #3e3f42);

  /* h3/Medium 24px */
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 35px;
}

.deals-room-nav-fact p:nth-child(3) {
  color: #9ea0a5;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 183.333% */
}

.deals-room-nav-bio-cond {
  width: 540px;
  padding-top: 25px;
}

.deals-room-nav-bio-cond-top {
  display: flex;
  justify-content: space-between;
}

.deals-room-nav-bio-cond-top > p {
  color: #9ea0a5;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
  text-transform: uppercase;
}

.deals-room-nav-bio-cond-top-left > span {
  color: #9ea0a5;
  text-align: right;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
  margin-right: 6px;
}

.deals-room-nav-bio-cond-top-left {
  margin-bottom: 8px;
}

.deals-room-nav-bio-cond p:last-child {
  color: #6b6c6f;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}

/* =================================== */
/* DEALS ROOM NAV DOCUMENT  */
/* =================================== */

.deals-room-doc {
  width: 1110px;
  // height: 545px;
  border: 1px solid #eaedf3;
  fill: #fff;
  stroke-width: 1px;
  stroke: #eaedf3;
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.04));
  margin-top: 30px;
}
.deals-room-doc-nav {
  height: 74px;
  border: 1px solid #eaedf3;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px 0 30px;
}
.deals-room-doc-nav-left {
  display: flex;
  gap: 30px;
  align-items: center;
}
.deals-room-doc-nav-left p {
  color: #6b6c6f;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 155.556% */
}
.deals-room-doc-nav-right {
  display: flex;
  justify-content: space-between;
}

.deals-room-doc-nav-left-all {
  border-right: 1px solid #eaedf3;
  padding-right: 30px;
  display: flex;
  gap: 6px;
  align-items: center;
}

.doc-nav {
  display: flex;
  gap: 6px;
  align-items: center;
}
.doc-nav span {
  color: #9ea0a5;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}
.deals-room-doc-nav-count {
  background-color: #ed962d;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.deals-room-doc-nav-count span {
  color: #fff;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
}

.deals-room-doc-nav-right {
  display: flex;
  gap: 10px;
}
.deals-room-doc-nav-filt {
  width: 94px;
  height: 39px;
  background: rgb(246, 247, 249);
  background: linear-gradient(185deg, rgba(246, 247, 249, 1) 26%, rgba(238, 238, 238, 1) 71%);
  border-radius: 4px;
  border: 1px solid #d8dce6;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.deals-room-doc-nav-filt span {
  color: #3e3f42;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}
.deals-room-doc-nav-addmore {
  width: 165px;
  height: 38px;
  border-radius: 4px;
  border: 1px solid var(--purple-pantone-2665-c-71, rgba(125, 85, 199, 0.71));
  background: linear-gradient(0deg, rgba(125, 85, 199, 0.3) 0%, rgba(255, 255, 255, 0) 107.78%), #7d55c7;
  background-blend-mode: multiply, normal;
  box-shadow: 0px 1px 1px 0px rgba(19, 31, 21, 0.1), 0px 2px 0px 0px rgba(255, 255, 255, 0.06) inset;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.deals-room-doc-nav-addmore span {
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}

/* =================================== */
/* DEALS ROOM NAV DOCUMENT HEADINGS */
/* =================================== */

.deals-room-doc-nav-head {
  height: 43px;
  background: #fff;
  box-shadow: 0px 1px 0px 0px #eaedf3;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 80px 0 20px;
}
.deals-room-doc-nav-head span {
  color: #9ea0a5;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 14.4px */
  text-transform: uppercase;
}
.deals-room-doc-nav-head-first {
  display: flex;
  gap: 15px;
  align-items: center;
}
/* =================================== */
/* DEALS ROOM NAV DOCUMENT  UPLOAD */
/*-------------------------------------*/
/* =================================== */

.deals-room-doc-upload {
  height: 70px;
  background: rgba(255, 255, 255, 0);
  box-shadow: 0px -1px 0px 0px #eaedf3 inset;
  padding: 0 35px 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.deals-room-doc-pdf {
  display: flex;
  gap: 15px;
}
.deals-room-doc-pdf span {
  color: #3e3f42;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 157.143% */
}

.deals-room-doc-date span:nth-child(1) {
  color: #3e3f42;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 157.143% */
  display: block;
}
.deals-room-doc-date span:nth-child(3) {
  color: #9ea0a5;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
}
.deals-room-doc-sign {
  border-radius: 100px;
  background: linear-gradient(0deg, rgba(86, 197, 104, 0.1) 0%, rgba(86, 197, 104, 0.1) 100%), #fff;
  padding: 8px 18px;
  display: flex;
  align-items: center;
  gap: 8px;
}
.deals-room-doc-sign span {
  color: #6b6c6f;
  text-align: right;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 183.333% */
}
.deals-room-doc-import {
  padding: 8px 18px;
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 100px;
  background: linear-gradient(0deg, rgba(255, 196, 0, 0.2) 0%, rgba(255, 196, 0, 0.2) 100%), #fff;
}
.deals-room-doc-import span {
  color: #6b6c6f;
  text-align: right;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 183.333% */
}
.deals-room-doc-org {
  display: flex;
  align-items: center;
  gap: 10px;
}
#womenimg {
  width: 38px;
  height: 38px;
  border-radius: 50%;
}
.deals-room-doc-org-name p {
  color: #3e3f42;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
}
.deals-room-doc-org-name span {
  color: #6b6c6f;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}
.deals-room-doc-tag span {
  display: flex;
  flex-direction: column;
  padding: 2px 8px;
  align-items: center;
  gap: 12px;
  border-radius: 4px;
  border: 1px solid #eaedf3;
  background: #fbfbfd;
  color: #3e3f42;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}
.deals-room-doc-tag span:first-child {
  height: 23px;
  width: 22px;
  margin-bottom: 4px;
}
.deals-room-doc-action {
  display: flex;
  gap: 30px;
}
.deals-room-doc-page-nav {
  height: 75px;
  display: flex;
  align-items: center;
}
.page-nav-cont {
  border: 1px solid #d8dce6;
  display: flex;
  // width: 150px;
  justify-content: center;
  gap: 10px;
  padding: 10px;
  border-radius: 4px;
  margin-left: 30px;
}
.page-nav-cont span {
  color: #3e3f42;
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
  border-left: 1px solid #eaedf3;
  border-right: 1px solid #eaedf3;
  padding: 0 15px;
}
/* =================================== */
/* DEALS ROOM NAV TOKEN CONTAINER */
/*-------------------------------------*/
/* =================================== */

.token-container {
  width: 825px;
  height: 510px;
  fill: #fff;
  border: 1px solid #eaedf3;
  stroke-width: 1px;
  stroke: #eaedf3;
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.04));
  margin-top: 30px;
  overflow: hidden;
}
.token-head {
  height: 70px;
  border-bottom: 1px solid #eaedf3;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px 0 30px;
}
.token-head-title h2 {
  color: #3e3f42;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 155.556% */
}
.token-head-btn {
  display: inline-flex;
  padding: 8px 20px;
  align-items: center;
  gap: 12px;
  border-radius: 4px;
  border: 1px solid var(--purple-pantone-2665-c-71, rgba(125, 85, 199, 0.71));
  background: linear-gradient(0deg, rgba(125, 85, 199, 0.3) 0%, rgba(255, 255, 255, 0) 107.78%), #7d55c7;
  background-blend-mode: multiply, normal;
  box-shadow: 0px 1px 1px 0px rgba(19, 31, 21, 0.1), 0px 2px 0px 0px rgba(255, 255, 255, 0.06) inset;
}
.token-head-btn span {
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}
.token-link {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.token-link span {
  color: var(--purple-pantone-2665-c, #7d55c7);
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 111.111% */
}
.token-option {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-bottom: 15px;
}
.token-line {
  border-right: 1px solid #eaedf3;
  padding-right: 20px;
}
.token-opt {
  display: flex;
  align-items: center;
  gap: 10px;
}
.token-opt span {
  color: #3e3f42;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}
.token-frame {
  height: 325px;
  background: url(../../assets/dealsroom/svg/tokenframe.svg);
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.token-frame img {
  position: absolute;
  top: 6%;
  left: 40%;
}

/* =================================== */
/* DEALS ROOM NAV SELLER ACTIVITY */
/*-------------------------------------*/
/* =================================== */
.seller-activity-container {
  width: 730px;
  height: 850px;
  margin-top: 30px;
}
.seller-card-container {
  border-bottom: 1px solid #eaedf3;
  display: flex;
  gap: 15px;
  padding-bottom: 25px;
  margin-bottom: 30px;
}
.seller-details p:nth-child(1) {
  color: #3e3f42;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 157.143% */
}
.seller-details p span {
  font-weight: 400;
}
.seller-details p:nth-child(2) {
  color: #6b6c6f;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
  margin-bottom: 10px;
}
.seller-details p:nth-child(3) {
  color: #6b6c6f;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
  margin-bottom: 10px;
}
.seller-details #date {
  color: #9ea0a5;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
}
.seller-det-doc {
  display: flex;
  gap: 6px;
  margin-bottom: 15px;
}
.seller-det-doc #pdf {
  padding: 14px;
  border-radius: 4px;
  background: var(--LP-very-light--_lib, #eaedf3);
}
.seller-det-invest {
  display: flex;
  gap: 16px;
  margin-bottom: 15px;
}
.seller-invst {
  border: 1px solid var(--LP-very-light--_lib, #eaedf3);
  background: rgba(234, 237, 243, 0.5);
}
.invst-card {
  width: 160px;
  height: 84px;
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  border-radius: 10px;
}
#time {
  color: #9ea0a5;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 233.333% */
}
#min {
  color: #9ea0a5;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 233.333% */
  text-transform: uppercase;
}
.seller-invst span:nth-child(3) {
  color: var(--LP-mid-grey-_lib, #6b6c6f);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 16px */
}
.seller-invst-now {
  border: 1px solid var(--LP-very-light--_lib, #eaedf3);
  background: var(--LP-white-_lib, #fff);
}
.seller-invst-now span:last-child {
  color: var(--LP-Pantone, #7d55c7);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 16px */
}
.seller-comment {
  width: 644px;
  fill: #fbfbfd;
  stroke-width: 1px;
  border: 1px solid #e2e5ed;
  border-radius: 4px;
  margin-bottom: 15px;
}
.seller-comment > p {
  margin: 15px;
  color: #3e3f42;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}
.seller-cmt-date {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.seller-cmt-reply {
  display: flex;
  gap: 5px;
  align-items: center;
}
.seller-cmt-reply span {
  color: #1665d8;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.seller-activity #imgmedal {
  position: absolute;
  margin: 30px 0 0 40px;
  z-index: 1;
}
.seller-detail-top {
  display: flex;
  justify-content: space-between;
}
.seller-detail-time span {
  color: #9ea0a5;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
}

/* =================================== */
/* DEALS ROOM NAV SELLER COMMUNICATION */
/*-------------------------------------*/
/* =================================== */

.seller-chat-container {
  width: 825px;
  margin-top: 30px;
  padding: 0 30px;
}
.seller-chat-box {
  height: 65px;
  fill: #fff;
  border-top: 1px solid #eaedf3;
  box-shadow: 0px -1px 4px 0px rgba(0, 0, 0, 0.05) inset;
  // filter: drop-shadow(0px -1px 0px #f1f1f3);

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px 0 10px;
}
.seller-chat-box #input-box {
  width: 100%;
  height: 40px;
  background: #fff;
  border: none;
  outline: none;
  &::placeholder {
    color: #9ea0a5;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
}

.chat-attacment {
  display: flex;
  gap: 15px;
}
#chatimg {
  height: 28px;
  width: 28px;
  border-radius: 50%;
}
.seller-chat-activity-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.chat-user {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 12px;
}
.chat-user span:nth-child(2) {
  color: #3e3f42;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 157.143% */
}
.chat-user span:nth-child(3) {
  color: #9ea0a5;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
}
.user-active span:nth-child(3) {
  font-size: 14px;
}
.chat-box-right {
  // width: 500px;
  fill: #fff;
  stroke-width: 1px;
  border: 1px solid #eaecea;
  stroke: #eaecee;
  padding: 16px 24px;
  margin-bottom: 30px;
  border-radius: 10px 0 10px 10px;
  > p {
    color: #3e3f42;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
  }
}

.chat-box-left {
  display: flex;
  width: 500px;
  gap: 12px;
  margin-bottom: 30px;
  > p {
    color: #fff;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
    padding: 18px 24px;
  }
}
.chat-color-read p {
  border-radius: 0px 10px 10px 10px;
  background: linear-gradient(90deg, #6b5de7 0%, #915cf4 101.5%);
}
.chat-color-unread p {
  border-radius: 0px 10px 10px 10px;
  background: linear-gradient(90deg, #4187ee 1.89%, #4f95fc 98.11%);
}
.chat-box-img-upload {
  display: flex;
  gap: 10px;
}
#uploadimg {
  height: 80px;
  width: 80px;
  border-radius: 10px;
}
.versions_wrapper {
  padding: 0px 30px;
  .deals-room-center-header {
    padding: 0px !important;
  }
  .versions_header {
    display: flex;
    align-items: center;

    padding: 40px 0px;
    justify-content: space-between;
    .left {
      h2 {
        color: #000;

        /* h5/Medium 18px */
        font-family: Roboto;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
    .right {
      display: flex;
      gap: 20px;
      align-items: center;
      .PrimaryButton {
        display: flex;
        width: 104px;
        height: 38px;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        background-color: #fff;
        background: #fff;
        color: #3e3f42;
        border: 1px solid #d8dce6;
        margin-top: 0px;
      }
      p {
        display: flex;
        align-items: center;
        gap: 5px;
        color: #3e3f42;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 28px; /* 175% */
      }
      span {
        color: #6b6c6f;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
      }
    }
  }
  .versions_container {
    display: flex;
    justify-content: space-between;
    .prediction_card {
      border: 1px solid #eaf1f7;
      border-radius: 6px;
      max-width: 350px;
      max-height: 492px;
      justify-content: center;
      padding: 10px 12px;
      gap: 20px;
      display: flex;
      flex-direction: column;
      .prediction_card-T {
        display: flex;
        justify-content: center;
      }
      .prediction_card-M {
        padding: 0px 10px;
        border-bottom: 1px solid #f2f4f7;
        .text_wrapper {
          h3 {
            color: #3e3f42;
            font-family: Roboto;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 28px; /* 155.556% */
          }
          .p {
            color: #9ea0a5;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px; /* 157.143% */
          }
          margin-bottom: 15px;
        }
        .details_wrapper {
          display: flex;
          gap: 5px;
          justify-content: space-between;
          .left,
          .right {
            display: flex;
            align-items: flex-start;
            gap: 10px;
            img,
            svg {
              margin-top: 5px;
            }
          }
          .text_wrapper {
            span {
              color: #9ea0a5;
              font-family: Roboto;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 18px; /* 150% */
            }
            p {
              color: #3e3f42;
              font-family: Roboto;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 22px; /* 157.143% */
            }
          }
        }
      }
      .prediction_card-B {
        .prediction_card-B-T {
          display: flex;
          justify-content: space-between;

          .PrimaryButton {
            margin-top: 0px;
            padding: 5px 40px;
          }
        }
        .prediction_card-B-B {
          padding: 25px 0px;
          display: flex;
          gap: 10px;
          align-items: center;
          p {
            color: #3e3f42;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px; /* 157.143% */
          }
        }
      }
    }
  }
}
.todo_wrapper {
  .MuiCheckbox-root {
    padding: 5px;
  }
  .MuiFormControlLabel-root {
    margin-left: 20px;
  }
  .MuiFormControlLabel-label {
    color: var(--LP-black, #3e3f42);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
  }
  padding: 0px 30px;
  .deals-room-center-header {
    padding: 0px !important;
  }
  .todo_header {
    display: flex;
    align-items: center;

    padding: 40px 0px;
    justify-content: space-between;
    .left {
      h2 {
        color: #000;

        /* h5/Medium 18px */
        font-family: Roboto;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
    .right {
      display: flex;
      gap: 20px;
      align-items: center;
      .PrimaryButton {
        display: flex;
        width: 104px;
        height: 38px;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        background-color: #fff;
        background: #fff;
        color: #3e3f42;
        border: 1px solid #d8dce6;
        margin-top: 0px;
      }
      p {
        display: flex;
        align-items: center;
        gap: 5px;
        color: #3e3f42;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 28px; /* 175% */
      }
      span {
        color: #6b6c6f;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
      }
    }
  }
  .todo_container {
    display: flex;
    flex-direction: column;
    gap: 50px;
    border: 1px solid #eaedf3;

    border-left: none;
    border-right: none;
    .todo_block-T {
      padding: 20px 20px;
      border: 1px solid #eaedf3;
      border-top: 2px solid #34aa44;
      // display: flex;
      justify-content: space-between;

      .L {
        display: flex;
        width: 200px;
        gap: 20px;
        align-items: center;
        p {
          color: #3e3f42;
          font-family: Roboto;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 28px; /* 155.556% */
        }
      }
      .R {
        display: flex;
        gap: 10px;
      }
    }
    .todo_block-B {
      .header {
        display: flex;
        justify-content: space-between;
        border: 1px solid #eaedf3;

        padding: 20px 40px;
        .right {
          width: 350px;
          display: flex;
          justify-content: space-between;
          margin-right: 100px;
        }
        span {
          color: #9ea0a5;
          font-family: Roboto;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 18px; /* 150% */
          text-transform: uppercase;
        }
      }
      .row {
        padding: 10px 20px;
        border: 1px solid #eaedf3;

        display: flex;
        justify-content: space-between;

        p {
          color: #3e3f42;
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px; /* 157.143% */
        }
        span {
          color: #9ea0a5;
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px; /* 157.143% */
        }
        .row-L {
          display: flex;
          align-items: center;
          span {
            margin: 0px 10px;
          }
          .title {
            margin-right: 30px;
          }
        }
        .row-R {
          display: flex;
          align-items: center;
          gap: 100px;
        }
      }
    }
  }
}
.lender_wrapper {
  padding: 0px 30px;
  .search_bar {
    margin-right: 10px;
    position: relative;
    width: 150px;

    border: 2px solid #eaedf3;
    border-radius: 5px;
    display: flex;
    align-items: center;

    input {
      all: unset;
      width: 120px;
      padding-right: 25px;
      padding-left: 10px;
    }
    svg {
      position: absolute;
      right: 5px;
    }
  }
  .deals-room-center-header {
    padding: 0px !important;
  }
  .lender_header {
    display: flex;
    align-items: center;

    padding: 40px 0px;
    justify-content: space-between;
  }
  .lender_container {
    width: 66%;
    .history_table {
      border: 1px solid #eaedf3;
      border-radius: 10px;
    }
    .loan_details {
      width: 100%;
      .summary_box {
        border: 1px solid #eaedf3;

        border-radius: 4px;

        .summary_box-T {
          gap: 20px;
          display: flex;
          padding: 15px 30px;
          border-bottom: 1px solid #eaedf3;
          p {
            color: #9ea0a5;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px; /* 157.143% */
          }
        }
        .summary_box-B {
          padding: 40px 30px;
          .heading {
            margin-bottom: 20px;
            h2 {
              color: #3e3f42;
              font-family: Roboto;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 32px; /* 200% */
            }
          }
          .stats {
            display: flex;
            justify-content: space-between;
            .summary_box-left,
            .summary_box-right {
              width: 45%;
              display: flex;
              flex-direction: column;
              gap: 20px;
            }
            .row {
              display: flex;
              width: 100%;
              justify-content: space-between;
              text-wrap: nowrap;
              p {
                color: var(--LP-mid-gray, #6b6c6f);
                font-family: Roboto;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px; /* 157.143% */
              }
              span {
                display: inline-flex;
                padding: 2px 8px;
                align-items: center;
                gap: 10px;
              }
            }
          }
        }
      }
    }

    .documents,
    .lender_table {
      width: 70vw;
      .documents_box {
        border: 1px solid #eaedf3;

        border-radius: 4px;
        margin-bottom: 30px;

        .documents_box-T {
          padding: 15px 30px;
          display: flex;
          border-bottom: 1px solid #eaedf3;
          .left {
            gap: 20px;
            display: flex;
            p {
              color: #9ea0a5;
              font-family: Roboto;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 22px; /* 157.143% */
            }
            h2 {
              margin-top: 5px;
              color: #3e3f42;
              font-family: Roboto;
              font-size: 18px;
              font-style: normal;
              font-weight: 500;
              line-height: 28px; /* 155.556% */
              text-wrap: nowrap;
            }
          }
          .right {
            display: flex;

            justify-content: right;

            .button {
              gap: 10px;
              display: flex;
              align-items: center;
              padding: 0px 10px;
              border-right: 1px solid #eaedf3;
              &:last-of-type {
                border: none;
              }
            }
            .button2 {
              margin-right: 10px;
              display: flex;
              align-items: center;
              padding: 0px 10px;
              border: 2px solid #eaedf3;
              border-radius: 5px;
              span {
                margin-left: 0px !important;
              }
            }
            &:last-child {
              margin-right: 0px;
            }
            .white {
              padding: 7px 14px;
              background: #fff;
              color: #3e3f42;
              border: none;
              margin-top: 0px;
              box-shadow: none;
            }
          }
        }
        .documents_box-B {
          padding: 40px 30px;
          &.table {
            // padding: 0px 30px;
            // border: ;
            padding: 0px;
          }
          p {
            color: var(--purple-pantone-2665-c, #7d55c7);
            font-family: Roboto;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 111.111% */
          }
          .uploaded_docs {
            width: 100%;
            .header {
              display: flex;
              padding: 14px 40px;
              width: 100%;
              justify-content: space-between;
              border-bottom: 1px solid #eaedf3;
              .left {
                display: flex;
                p {
                  width: 150px;
                }
              }
              .right {
                p {
                  margin-left: auto;
                }
              }
              p {
                text-wrap: nowrap;
                color: #9ea0a5;
                font-family: Roboto;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 18px; /* 150% */
                text-transform: uppercase;
              }
            }
            .table_row {
              padding: 10px 25px;
              border: 1px solid #eaedf3;
              display: flex;
              justify-content: space-between;
              border-top: none;
              &.table_row2 {
                border-bottom: 1px solid #eaedf3;
              }
              p {
                color: var(--LP-black--_lib, #3e3f42);
                font-family: Roboto;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px; /* 157.143% */
              }
              span {
                color: #9ea0a5;
                font-family: Roboto;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 18px; /* 150% */
                text-transform: uppercase;
              }
              .MuiCheckbox-root {
                padding: none;
              }
              .MuiFormControlLabel-root {
                margin-right: 0px;
              }
              .left {
                display: flex;

                .item {
                  display: flex;
                  gap: 10px;
                  align-items: center;
                  width: 164px;
                  &.first {
                    margin-right: 15px;
                  }
                  .text_wrapper {
                  }
                }
              }
              .right {
                display: flex;
                width: fit-content;
                .action_buttons {
                  display: flex;
                  gap: 10px;
                  .button {
                    display: flex;
                    width: 50px;
                    justify-content: center;
                    align-items: center;
                    flex-shrink: 0;
                    border: 1px solid #eaedf3;
                    border-radius: 5px;
                  }
                }
              }
            }
          }
        }
      }
    }
    .lender_table {
      .documents_box {
        width: 77vw;

        .table_row {
          border: none !important;
          .left {
            .item {
              width: 150px !important;
              align-items: flex-start !important;
            }
          }
          .right {
            gap: 25px;
            align-items: center;
            .item {
              width: auto !important;
            }
            .action_buttons {
              height: 100%;
              align-items: center;
              .button {
                height: 100%;
              }
            }
          }
        }
      }
    }
    .comments_panel {
      display: flex;
      flex-direction: column;
      gap: 20px;
      .comment_box {
        display: flex;
        gap: 20px;
        padding: 30px 20px;
        padding-top: 0px;
        padding-right: 0px;
        border-bottom: 1px solid #e2e5ed;
        .left {
        }
        .right {
          display: flex;
          gap: 10px;
          flex-direction: column;
          .right-T {
            display: flex;
            justify-content: space-between;
            p {
              color: #3e3f42;
              font-family: Roboto;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 22px;
              span {
                color: #3e3f42;
                font-family: Roboto;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 22px; /* 157.143% */
              }
            }
            span {
              color: #9ea0a5;
              font-family: Roboto;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 18px; /* 150% */
            }
          }
          .right-M {
            border: 1px solid #e2e5ed;
            border-radius: 5px;
            padding: 20px;
            padding-bottom: 10px;
            background: #fbfbfd;
            p {
              color: #3e3f42;
              font-family: Roboto;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 22px; /* 157.143% */
            }
          }
          .right-B {
            display: flex;
            justify-content: space-between;
            align-items: center;
            p {
              color: #9ea0a5;
              font-family: Roboto;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 18px; /* 150% */
            }
            span {
              color: #1665d8;
              font-family: Roboto;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }
        }
      }
    }
    .updates_panel {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 20px;

      .updates_photos {
        display: flex;
        gap: 20px;
        align-items: flex-start;
        border-bottom: 1px solid #eaedf3;
        padding-bottom: 20px;
        .left {
          display: flex;
          align-items: center;
          gap: 10px;
          .updatesIcon {
            border-radius: 50%;
          }
        }
        .right {
          display: flex;
          flex-direction: column;
          gap: 10px;
          .right-T {
            h3 {
              color: #3e3f42;
              font-family: Roboto;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 22px; /* 157.143% */
            }
            p {
              color: #6b6c6f;
              font-family: Roboto;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 22px; /* 157.143% */
            }
          }
          .right-M {
            p {
              color: #3e3f42;
              font-family: Roboto;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 22px; /* 157.143% */
              margin-bottom: 10px;
            }
          }
          .right-B {
            span {
            }
            color: #9ea0a5;
            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px; /* 150% */
          }
        }
      }
    }

    .react-tabs {
      // padding: 0px 30px;

      .inputBoxMain {
        margin-top: 0px;
      }
      .react-tabs__tab-list {
        border-bottom: 1px solid #eaedf3;
        // padding: 0% 14%;
        margin: 0 auto;
        display: flex;
        // justify-content: space-between;

        gap: 20px;

        li {
          display: flex;
          justify-content: center;
          color: var(--LP-mid-gray, #6b6c6f);
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px; /* 171.429% */
          text-transform: capitalize;
          display: flex;
          height: 36px;
          align-items: flex-start;
          min-width: 60px;
        }
        .active {
          color: #3e3f42 !important;
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px; /* 171.429% */
          background: var(--ffffff-white, #fff);
          box-shadow: 0px -2px 0px 0px #7d55c7 inset;
        }
      }
      .react-tabs__tab-panel--selected {
        // padding-right: 15%;
        // padding-left: 10%;
        // padding-bottom: 4.5%;
        // padding-top: 3%;
        display: flex;
        margin-top: 40px;
        // border-bottom: 1px solid #eaedf3;
        &.innerTabination {
          padding-left: 0%;
          padding-top: 2%;
          padding-right: 0px;
        }
        .left {
          .react-tabs {
            display: flex;
            padding: 0px;
            .react-tabs__tab-list {
              flex-direction: column;
              justify-content: flex-start;
              align-items: baseline;

              padding: 0px;
              margin: 0px;
              li {
                padding: 5px 16px;
                display: flex;
                align-items: center;
                justify-content: start;
              }
              .active {
                box-shadow: 3px 0px 0px 0px #7d55c7 inset;
              }
              border-bottom: none;
            }
            .react-tabs__tab-panel--selected {
              padding: 0px;
              padding-top: 1%;
              border-bottom: none;
            }
          }
          .right {
            width: 100%;
            display: flex;
            justify-content: space-between;
            gap: 105px;
            .column {
              :first-child {
                margin-top: 0px !important;
              }
              .row {
                width: 286px;
                margin-top: 20px;

                p {
                  color: var(--LP-black, #3e3f42);
                  font-family: Roboto;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 22px; /* 157.143% */
                }
                span {
                  color: var(--LP-gray, #9ea0a5);
                  text-align: right;
                  font-family: Roboto;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 22px; /* 157.143% */
                }
                .subCat {
                  display: flex;
                  justify-content: space-between;
                  .subCat-L {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                  }
                }
                .option {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  .MuiCheckbox-root {
                    padding: 5px;
                  }
                  .MuiFormControlLabel-root {
                    margin-left: 20px;
                  }
                  .MuiFormControlLabel-label {
                    color: var(--LP-black, #3e3f42);
                    font-family: Roboto;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 22px; /* 157.143% */
                  }
                  .option-L {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                  }
                }
              }
            }
          }
        }
        .right {
          width: 100%;
          display: flex;
          justify-content: space-between;
          .column {
            .row {
              width: 307px;
            }
          }
        }
        .location {
          width: 100%;
        }
        .asset {
        }
      }
    }
  }
}
