.dropdown-select-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.dropdown-arrow {
  margin-left: 5px;
  margin-right: 10px;
}

.dropdown-arrow img {
  width: 20px;
}
