.compare_assets {
    margin-left: 300px;
    max-width: 1120px;
    padding-right: 30px;

    .compare_assets_header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #eaedf3;

        h2 {
            color: #3E3F42;
            font-family: Roboto;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 30px;
        }

        div {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 20px;
        }

    }

    .btn_download {
        color: #ffffff;
        border: 1px solid var(--purple-pantone-2665-c-71, rgba(125, 85, 199, 0.71));
        background: linear-gradient(0deg, rgba(125, 85, 199, 0.30) 0%, rgba(255, 255, 255, 0.00) 107.78%), #7D55C7;
        background-blend-mode: multiply, normal;
        box-shadow: 0px 1px 1px 0px rgba(19, 31, 21, 0.10), 0px 2px 0px 0px rgba(255, 255, 255, 0.06) inset;
    }


    .btn_save {
        color: #3E3F42;
        text-align: center;
        border: 1px solid #D8DCE6;
        background: linear-gradient(0deg, #F6F7F9 0%, #FFF 100%);
        box-shadow: 0px 1px 1px 0px rgba(22, 29, 37, 0.05), 0px 2px 0px 0px rgba(255, 255, 255, 0.05) inset;
    }

    button {
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        display: inline-flex;
        padding: 8px 20px;
        align-items: center;
        justify-content: center;
        gap: 12px;
        border-radius: 4px;
    }


    .compare_table_box {
        width: 100%;
        border: 1px solid #EAEDF3;
        filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.04));
        margin-bottom: 20px;

        .table_header {
            padding: 20px;
            height: 70px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            border-bottom: 1px solid #EAEDF3;
            display: flex;
            justify-content: space-between;
            align-items: center;

            h3 {
                color: #3E3F42;
                font-family: Roboto;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 28px;
            }
        }
    }

    .table_btn {
        position: relative;
        padding: 0 26px;
        height: 38px;
        background-color: #FFFFFF00;
        border: 1px solid #eaedf3;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 12px;
        color: #3E3F42;
        font-family: Roboto;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;


    }

    .infobox_wrapper {
        padding: 20px;

        .infobox_row {
            display: flex;
            align-items: center;
            gap: 20px;
        }

        h3 {
            color: var(--Dark-Color, rgba(0, 0, 0, 0.87));
            font-family: Roboto;
            font-size: 16px;
            font-weight: 500;
            line-height: 28px;
            margin-bottom: 10px;
        }

        .infobox {
            width: 158px;
            height: 97px;
            border-radius: 4px;
            border: 1px solid var(--d-4-d-4-d-4-light-grey, #D4D4D4);
            display: flex;
            flex-direction: column;
            gap: 4px;
            padding: 10px 4px;

            .title {
                color: #9EA0A5;
                font-family: Roboto;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 18px;
                text-transform: uppercase;
            }

            .value {
                color: var(--LP-black, #3E3F42);
                font-family: Roboto;
                font-size: 24px;
                font-style: normal;
                font-weight: 500;
                margin: 0;
            }

            span {
                color: var(--LP-mid-gray, #6B6C6F);
                font-family: Roboto;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;
            }
        }
    }



.compare-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    padding: 0 20px;

.table-row {
    display: flex;
    border-bottom: 1px solid #eaedf3;
    height: 60px;
    align-items: center;

    &:last-child {
        border-bottom: none;
    }

    .column {
        flex: 1;
        padding: 0 10px;
        color: #9EA0A5;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        text-transform: uppercase;
    }

    .column:first-child {
        width: 200px;
    }
}

.cell1 {
    flex: 1;
    padding: 0 10px;
    color: #3E3F42;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    min-width: 300px;
    width: 300px;
}

.cell {
    flex: 1;
    padding: 0 10px;
    color: #3E3F42;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}
.right {
    text-align: right;
}
}

.header {
    font-size: 12px;
    font-weight: 500;
    color: #9EA0A5;
    line-height: 18px;
    text-transform: uppercase;
}


.upload_wrapper {

    width: 100%;
    border: 1px solid #EAEDF3;
    filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.04));
    margin-bottom: 20px;

    .upload_header {
        padding: 20px;
        height: 70px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border-bottom: 1px solid #EAEDF3;
        display: flex;
        justify-content: space-between;
        align-items: center;

        h3 {
            color: #3E3F42;
            font-family: Roboto;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 28px;
        }
    }

    .upload_box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 6px;
        height: 127px;
        border-radius: 4px;
        border: 1px dashed var(--purple-pantone-2665-c-71, rgba(125, 85, 199, 0.71));
        background: linear-gradient(0deg, rgba(125, 85, 199, 0.08) 0%, rgba(125, 85, 199, 0.08) 100%), #FFF;

        p {
            color: #9EA0A5;
            text-align: center;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;

            span {
                text-decoration: underline;
            }
        }
    }
}

.charts {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;

    .progressbar_wrapper {
        display: flex;
        flex-direction: column;
        gap: 20px;


        .progress_bar {
            background-color: rgb(192, 192, 192);
            width: 290px;
            border-radius: 15px;
            height: 4px;
        }

        .bar {
            background-color: #7D55C7;
            color: white;
            text-align: right;
            font-size: 20px;
            border-radius: 15px;
            height: 4px;
        }

        .size {
            width: 60%;
        }

        .name {
            color: #3E3F42;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
        }

        span {
            color: #9EA0A5;
            text-align: right;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
        }
    }
}








.chart_box_wrapper {
    min-width: 254px;
    height: 384px;
    border: 1px solid #EAEDF3;
    filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.04));

    .header {
        padding: 20px;
        height: 70px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border-bottom: 1px solid #EAEDF3;
        display: flex;
        justify-content: space-between;
        align-items: center;

        h3 {
            color: #3E3F42;
            font-family: Roboto;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 140%;
            text-transform: capitalize;
        }
    }

    .chart_wrapper {
        padding: 20px;
        height: 310px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        background: linear-gradient(0deg, rgba(240, 241, 245, 0.40) 0%, rgba(240, 241, 245, 0.40) 100%), linear-gradient(0deg, #FFF 0%, #FFF 100%), #FFF;

        img {
            margin: 30px 0;
        }

        p {
            color: #3E3F42;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
        }

        span {
            color: var(--LP-gray, #9EA0A5);
            text-align: center;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
        }
    }
}

}