.Datarooms-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.Datarooms-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.Datarooms-controls {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.Datarooms-Filter {
  padding: 0;
  margin: 0;
  height: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  border: none;
  width: 100px;
  color: black;
  background-color: white;
  border-radius: 4px;
  border: 1px solid var(--Primary-Purple-50, #eaedf3);
  background: #fff;
}

@media screen and (max-width: 768px) {
  .Datarooms-controls {
    flex-direction: column;
    align-items: center;
  }

  .Datarooms-Filter {
    width: auto;
    padding: 10px;
  }
}
