// .chatbox-container {
//     height: 73vh;
//     overflow: auto;
// }

/* Add this to your CSS file */
.ant-modal {
  z-index: 1050; /* Ensure this is higher than other elements */
}

.ant-modal-mask {
  z-index: 1040; /* Ensure this is higher than other elements */
}
.image_container {
  display: flex;
  align-items: center;
  gap: 20px;
  width: 400px;

  img {
    height: 100px;
    width: 100px;

    object-fit: cover;
  }

  .img_box {
    position: relative;

    .closebtn {
      position: absolute;
      right: -10px;
      top: -10px;
      background: none;
      border: none;
      outline: none;
      background-color: purple;
      border-radius: 50%;
      height: 20px;
      width: 20px;
      color: white;
      font-size: 13px;
    }
    .container_loading {
      background-color: #0000006e;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: white;
    }
  }
}

.chat_image_container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
  row-gap: 10px;
  justify-items: center;
  align-items: center;
  .img_wrapper {
    margin-top: 20px;
    img {
      object-fit: cover;
      border-radius: 8px;
      width: 100px;
      height: 100px;
    }
  }
}

// .ant-image-preview-wrap {
//   z-index: 19999999999 !important;
//   background-color: rgba(0, 0, 0, 0.438) !important;
// }
// .ant-image-preview-footer {
//   z-index: 1999999999999 !important; 
// }
// .ant-image-preview-mask {

//   z-index: 19999999999 !important;
// }
// .ant-image-mask-info {
//   display: none !important;
// }