.investment_card_wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(256px, 1fr));
  gap: 30px;
  .investment_card {
    max-width: 350px;
    border-radius: 4px;
    border: 1px solid #eaedf3;
    background: #fff;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.04);
    .investment_card_header {
      .topSection {
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        position: relative;
        height: 70px;
        padding: 0 30px;
        margin-bottom: 35px;
        .investment_card_header_img {
          position: absolute;
          right: 140px;
          top: 30px;
          border-radius: 100%;
        }
        .btn_3dots {
          position: relative;
          button {
            width: 20px;
            height: 20px;
            background: none;
            outline: none;
            border: 0;
          }
          .del_btn_wrapper {
            position: absolute;
            left: -25px;
            border: 1px solid #e2e5ed;
            background: var(--ffffff-white, #fff);
            border-radius: 6px;
            button {
              display: flex;
              align-items: center;
              padding: 0 10px;
              width: 90px !important;
              color: red;
              height: 38px;
              gap: 5px;
              img {
                width: 20px;
                height: 20px;
              }
            }
          }
        }
        &:hover {
          .investment_card_checkbox {
            input {
              display: block;
            }
          }
        }
        .investment_card_checkbox {
          input {
            display: none;
            cursor: pointer;
          }
        }
      }
      .bottomSection {
        padding-bottom: 10px;
        .investment_card_header_title {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          h2 {
            color: #3e3f42;
            text-align: center;
            font-family: Roboto;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px; /* 150% */
          }
          p {
            color: #9ea0a5;
            text-align: center;
            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px; /* 150% */
          }
        }
      }
    }
    .investment_card_body {
      padding: 12px 30px 19px 30px;
      display: flex;
      flex-direction: column;
      gap: 12px;
      .investment_card_detail {
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-wrap: nowrap;

        .investment_card_detail_label {
          color: #9ea0a5;
          font-family: Roboto;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 18px; /* 150% */
        }
        .investment_card_detail_value {
          color: var(--LP-black, #3e3f42);
          text-align: right;
          font-family: Roboto;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px; /* 150% */
        }
      }
      .investment_card_cash_flow {
        text-wrap: nowrap;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .investment_card_detail_label {
          flex: 0.5;
          color: #9ea0a5;
          font-family: Roboto;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 18px; /* 150% */
        }
      }
    }

    .investment_card_footer {
      padding: 16px 0;
      border-top: 1px solid #eaedf3;
      display: flex;
      align-items: center;
      justify-content: center;

      button {
        background: none;
        border: none;
        color: #9ea0a5;
        text-align: center;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px; /* 150% */
        text-transform: uppercase;
      }
    }
  }
}

.cash_flow_bars {
  display: flex;
  flex-direction: column;
  gap: 5px;
  flex: 1;
  .progressbar_container {
    display: flex;
    flex-direction: column;

    &.wrapper1 {
      width: 100% !important;
      border-radius: 5px;
      //   background-color: #85abe8; /* Blue color */
    }
    &.wrapper2 {
      width: 100% !important;
      border-radius: 5px;
      background-color: #79e495; /* Green color */
    }
  }

  .progress-bar {
    border-radius: 5px;
    background-color: transparent;
  }

  .progress-bar.blue {
    width: 70% !important;
    background-color: #4285f4; /* Blue color */
  }

  .progress-bar.green {
    width: 50% !important;
    background-color: #34a853; /* Green color */
  }
}
