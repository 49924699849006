.Communications-header {
  display: flex;
  justify-content: space-between;
}
.Communications-header-data {
  border: 1px solid #eaedf3;

  .Communications-header-span span {
    color: var(--Black, #3e3f42);
    leading-trim: both;
    text-edge: cap;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    text-transform: uppercase;
  }
}
.Communications-header-span {
  padding: 20px;
  justify-content: space-between;
  display: flex;
}

.Communications-container-data {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.Communications-Cognier {
  justify-content: center;
  align-items: center;
  border: 1px solid #eaedf3;
  width: fit-content;
  padding: 20px;
}

.Communications-Cognier > div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Communications-Cognier img {
  margin-bottom: 10px;
}

.Communications-New-share {
  padding: 0;
  margin: 0;
  height: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  border: none;
  color: white;
  width: 150px;
  background: var(--Primary-Purple-500, #7d55c7);
  box-shadow: 0px 2px 0px 0px rgba(255, 255, 255, 0.06) inset, 0px 1px 1px 0px rgba(19, 31, 21, 0.1);
}
.Communications-flex-data {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.Communications-data {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

.Communications-data-container {
  border: 1px solid #eaedf3;
  padding-top: 60px;
  width: 350px;
  height: 180px;
  align-items: center;
  text-align: center;
}
.Communications-data-container-image {
  background-color: #eaedf3;
  width: 80px;
  justify-content: center;
  display: flex;
  align-items: center;
  height: 80px;
  border-radius: 100%;
}

.Communications-Error-Container {
  border: 1px solid #eaedf3;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 350px;
  height: 200px;
  align-items: center;
  text-align: center;
}
.Communications-Error-Container p {
  color: #3e3f42;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px; /* 144.444% */
}
