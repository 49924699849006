.search-filters {
  //   max-width: 300px;
  margin: 0 auto;
  background: #fff;
  border-radius: 8px;
  border: 1px solid #eaedf3;
  width: 100%;

  h3 {
    padding: 20px;

    color: #3e3f42;
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 155.556% */
  }

  .filter-options {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .filter-option {
      display: flex;
      align-items: flex-start;
      gap: 20px;
      border-bottom: 1px solid #eaedf3;
      padding: 20px;

      p {
        color: #3e3f42;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px; /* 157.143% */
      }

      &:last-child {
        border-bottom: none !important;
      }

      input[type="checkbox"] {
        margin-right: 10px;
        margin-top: 5px;
        cursor: pointer;
      }

      label {
        display: flex;
        align-items: center;
        gap: 20px;
        cursor: pointer;
      }
    }
  }

  .search-box,
  .date-filter {
    padding: 20px;

    label {
      color: #9ea0a5;
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px; /* 150% */
      text-transform: uppercase;
      margin-bottom: 7px;
    }

    input,
    select {
      width: 100%;
      padding: 8px;
      border: 1px solid #ddd;
      border-radius: 4px;
    }
  }
}
