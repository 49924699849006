.porfolio_overview_main {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 20px;
  .porfolio_overview_one {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    @media screen and (max-width: 1100px) {
      flex-direction: column;
    }
  }
  .porfolio_overview_heading {
    margin-bottom: 10px;
    h2 {
      color: #3e3f42;
      font-family: Roboto;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 30px; /* 150% */
    }
  }
  .investment_summary {
    display: flex;
    flex-direction: column;
    gap: 20px;
    @media screen and (max-width: 1100px) {
      flex-direction: row;
      width: 100%;
    }
  }

  .investment_summary_one {
    display: flex;
    flex-direction: column;
    gap: 4px;
    p {
      color: #9ea0a5;
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px; /* 150% */
      text-transform: uppercase;
    }
    h3 {
      color: var(--LP-black, #3e3f42);

      /* h4/Regular 20px */
      font-family: Roboto;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  .investment_summary_two {
    display: flex;
    flex-direction: column;
    gap: 4px;
    p {
      color: #9ea0a5;
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px; /* 150% */
      text-transform: uppercase;
    }
    h3 {
      color: var(--LP-black, #3e3f42);

      /* h4/Regular 20px */
      font-family: Roboto;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  //   Syndicates_Highilites
  .syndicates_highilites {
    @media screen and (max-width: 1100px) {
      max-width: calc(100vw - 240px);
      overflow: auto;
    }
    .syndicates_highilites_header {
      display: flex;
      justify-content: space-between;
      padding: 20px;
      border: 1px solid #eaedf3;

      .syndicates_highilites_calander_and_button {
        display: flex;
        gap: 20px;
        .syndicates_highilites_calander {
          display: flex;
          gap: 10px;
          border: 1px solid #eaedf3;
          padding: 10px;
          div {
            display: flex;
            align-items: center;
            gap: 5px;
            p {
              color: #3e3f42;
              font-family: Roboto;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 22px; /* 157.143% */
              text-wrap: nowrap;
            }
          }
        }
        .all_syndicates {
          padding: 10px;
          width: 237px;
          height: 38px;
          flex-shrink: 0;
          color: #3e3f42;
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px; /* 157.143% */
          border: 1px solid #eaedf3;
          height: 100%;
        }
      }
    }
  }
  .syndicates_highilites_data {
    display: flex;
    width: 100%;
    padding: 20px;
    gap: 50px;
    border: 1px solid #eaedf3;

    .syndicates_highilites_data_one {
      display: flex;
      width: 100%;
      flex-direction: column;
      border-right: 1px solid #eaedf3;
      &:last-child {
        border-right: none;
      }
      p {
        color: #9ea0a5;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px; /* 150% */
        text-transform: uppercase;
      }
      h4 {
        color: var(--LP-black, #3e3f42);

        /* h3/Regular 24px */
        font-family: Roboto;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .syndicates_highilites_data_one_percentage {
        display: flex;
        gap: 0px;
        p {
          color: var(--Green-2, #27ae60);
          text-align: center;
          font-family: Roboto;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px; /* 150% */
          text-transform: uppercase;
        }
        .negative {
          color: #e6492d !important;
          text-align: center;
          font-family: Roboto;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px; /* 150% */
          text-transform: uppercase;
        }
      }
    }
  }
  .GP_and_Investment {
    display: flex;
    // padding: 20px;
    // border: 1px solid #eaedf3;
    gap: 20px;
    @media screen and (max-width: 1100px) {
      flex-direction: column;
    }
    .GP_list_Main {
      border: 1px solid #eaedf3;
      width: 50%;
      @media screen and (max-width: 1100px) {
        width: 100%;
      }

      .GP_list_header {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #eaedf3;
        padding: 20px;
        h3 {
          color: #3e3f42;
          font-family: Roboto;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 28px; /* 155.556% */
        }
      }
      .GP_list_one {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #eaedf3;
        padding: 20px;
        p {
          color: #9ea0a5;
          font-family: Roboto;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 18px; /* 150% */
          text-transform: uppercase;
        }
      }
      .GP_list_two {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        padding: 20px;
      }
      .GP_list_two_investment {
        div {
          display: flex;
          gap: 10px;
          margin-bottom: 10px;
        }
        h3 {
          color: #3e3f42;
          font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 100%; /* 16px */
        }
        p {
          color: var(--LP-black-_lib, #3e3f42);
          font-family: Roboto;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%; /* 14.4px */
        }
      }
      .GP_list_two_inner {
        h3 {
          color: #3e3f42;
          font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px; /* 150% */
        }
        p {
          color: #9ea0a5;
          font-family: Roboto;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px; /* 150% */
        }
      }
    }
  }
}
