.dropDownOuterCon {
  width: 100% !important;
}
.dropDownMAIN {
  width: 100%;
  // max-width: 354px;
  height: 38px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid var(--LP-very-light--_lib, #eaedf3);
  background: #fff;

  @media screen and (max-width: 880px) {
    // width: 150px !important;
  }
  cursor: pointer;
  display: flex;
  align-items: center;

  position: relative;

  img {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
  }

  img {
    position: relative;
    right: 10px;
    top: 10%;
    align-self: right;
    margin-left: auto;
    transform: translateY(-50%);
  }
  .dropDownOuter {
    padding-left: 16px;
    padding-right: 16px;
    color: var(--LP-black--_lib, #3e3f42);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    box-shadow: 0 !important;
  }
  .dropDownDrop {
    position: absolute;
    flex-direction: column;
    width: 100%;
    top: 38px;
    left: 0px;
    border-radius: 4px;
    border: 1px solid #e2e5ed;
    display: none;
    background-color: #fff;
    z-index: 10;
    &.active {
      display: flex;
      max-height: 25vh;
      overflow: scroll;
    }
    .dropDownDropData {
      padding-left: 16px;

      width: auto;
      // min-width: 180px;
      height: 38px;
      background: #fff;
      box-shadow: 0px 1px 0px 0px #eaedf3;
      color: #3e3f42;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 157.143% */
      display: flex;
      align-items: center;
      &:hover {
        cursor: pointer;
        background-color: #f6f7f9;
      }
    }
  }
}
.dropDownOuterCon {
  // max-width: 285px;
  margin-top: 19px;
  &.small135 {
    width: 135px;
  }
  p {
    color: var(--LP-black, #3e3f42);
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 150% */
    text-transform: uppercase;
    opacity: 0.5;
    margin-bottom: 7px;
  }
}
