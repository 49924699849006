.setupDoc_container {
  .setupDocLeftSide_wrapper {
    border: 1px solid var(--LP-very-light, #eaedf3);
    background: #fff;
    box-shadow: 1px 0px 0px 0px #f1f1f3;
    height: 100%;
    max-height: 80vh;
    .actionOptionSelector {
      .backbtn {
        padding: 20px 10px;
        display: flex;
        gap: 20px;
        align-items: center;
        background: transparent;
        border: none;
        border-bottom: 1px solid #eaedf3;
        width: 100%;
        color: #3e3f42;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px; /* 137.5% */
      }
    }
    .contractHandlingBtns {
      padding: 0 20px;
      button {
        color: #fff;
        text-align: center;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 157.143% */
        margin-top: 20px;
        width: 100%;
        display: flex;
        align-items: center;
        padding: 8px 0px;
        gap: 12px;
        border-radius: 4px;
        border: 1px solid var(--purple-pantone-2665-c-71, rgba(125, 85, 199, 0.71));
        background: linear-gradient(0deg, rgba(125, 85, 199, 0.3) 0%, rgba(255, 255, 255, 0) 107.78%), #7d55c7;
        background-blend-mode: multiply, normal;
        box-shadow: 0px 2px 0px 0px rgba(255, 255, 255, 0.06) inset, 0px 1px 1px 0px rgba(19, 31, 21, 0.1);
        justify-content: center;
      }
    }
    .contactName {
      padding: 20px;
      p {
        color: var(--LP-black-_lib, #3e3f42);
        font-family: Roboto;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px; /* 122.222% */
        text-wrap: balance;
      }
    }
  }
  .setupDocContent_wrapper {
    height: 100vh;
    .contractRenderBox {
      display: flex;
      flex-direction: column;
      height: 100%;
      .contractRenderHeader {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 66px;
        background: #fff;
        box-shadow: 0px 1px 0px 0px #eaedf3, 0px 1px 3px 0px rgba(0, 0, 0, 0.05);
        padding: 0 20px;
        .headerLeftSection {
          height: 100%;
          display: flex;
          align-items: center;
          width: 100%;
          h5 {
            color: #3e3f42;
            font-family: Roboto;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px; /* 137.5% */
          }
        }

        .headerRightSection {
          height: 100%;
          display: flex;
          align-items: center;
          width: 100%;
          justify-content: flex-end;
          button {
            border-radius: 4px;
            background: linear-gradient(0deg, #f6f7f9 0%, #fff 100%);
            box-shadow: 0px 2px 0px 0px rgba(255, 255, 255, 0.05) inset, 0px 1px 1px 0px rgba(22, 29, 37, 0.05);
            border: 1px solid #d8dce6;
            display: flex;
            padding: 8px 0px;
            align-items: center;
            justify-content: center;
            gap: 10px;
            width: 33%;
            color: #3e3f42;
            text-align: center;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px; /* 157.143% */

            &.closeBtn {
              max-width: 58px;
              margin-left: 10px;
            }
          }
          .docHandlingDelBtns {
            width: 100%;
            // padding-right: 10px;
            // border-right: 1px solid #c4c4c4;
            display: flex;
            justify-content: flex-end;
            button {
              color: var(--red-pantone-1797-c, #cb333b);
              border: 1px solid var(--red-pantone-1797-c-72, rgba(203, 51, 59, 0.72));

              max-width: 160px;
            }
          }
        }
      }
      .contractRendering {
        display: grid;
        grid-template-columns: 3fr 1fr;
        height: 100%;

        .contractDetail {
          margin: 20px;
          height: 100%;
          max-height: 80vh;
          border-radius: 4px;
          border: 1px solid var(--LP-very-light, #eaedf3);
          background: #fff;
          box-shadow: 1px 0px 0px 0px #f1f1f3;
        }
        .contractUploadBtn {
          display: flex;
          max-height: 73vh;
          border: 1px solid var(--LP-very-light-_lib, #eaedf3);
          background: #fff;
          align-items: center;
          flex-direction: column;
          padding: 0 20px;
          button {
            width: 100%;
            margin-top: 20px;
            display: flex;
            justify-content: center;
            padding: 8px 0px;
            align-items: center;
            gap: 12px;
            color: #fff;
            text-align: center;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px; /* 157.143% */
            border-radius: 4px;
            border: 1px solid var(--purple-pantone-2665-c-71, rgba(125, 85, 199, 0.71));
            background: linear-gradient(0deg, rgba(125, 85, 199, 0.3) 0%, rgba(255, 255, 255, 0) 107.78%), #7d55c7;
            background-blend-mode: multiply, normal;
            box-shadow: 0px 2px 0px 0px rgba(255, 255, 255, 0.06) inset, 0px 1px 1px 0px rgba(19, 31, 21, 0.1);
          }
          .contactName {
            width: 100%;
            margin-top: 20px;
            p {
              color: var(--LP-black-_lib, #3e3f42);
              font-family: Roboto;
              font-size: 18px;
              font-style: normal;
              font-weight: 500;
              line-height: 22px; /* 122.222% */
              text-wrap: balance;
              text-align: left;
            }
          }
        }
      }
    }
  }
}


.Remove_buttonPDFupload{
  background: transparent;
  color: rgb(244, 67, 54);
  border: none;
  position: relative;
  top: -35px;
  bottom: 29px;
  right: -16px;
  font-size: 18px;
  cursor: pointer;
}

.pdfList_SetupDoc{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  .pdfItem{
    width: 100%;
  }
}