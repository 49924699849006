.ManageStakeholders-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #9ea0a5;
}

.search-container {
  display: flex;
  align-items: center;
}

.search-input {
  padding: 10px;
  border: none;
  outline: none;
  font-size: 17px;
  width: calc(100% - 40px); /* Adjust width to accommodate the icon */
}

.search-button,
.normal-button,
.dropdown-button {
  padding: 10px;
  font-size: 17px;
  border: 1px solid grey;
  cursor: pointer;
  background-color: transparent;
}

.search-button:focus,
.normal-button:focus,
.dropdown-button:focus {
  outline: none;
}

.search-button svg,
.normal-button svg,
.dropdown-button svg {
  color: grey;
}

.dropdown {
  position: relative;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 120px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.button-container {
  display: flex;
  gap: 20px;
}

// Dropdown
.dropdown-data-conatiner {
  margin-top: 10px;
}

.dropdown-data {
  display: flex;
  gap: 20px;
  margin-top: 10px;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--Primary-Purple-50, #eaedf3);
  border-top: 1px solid var(--Primary-Purple-50, #eaedf3);
}

.dropdown-data select {
  border: none;
  color: var(--primary-gray-100-text, #9ea0a5);
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  text-transform: uppercase;
}

.hrtag {
  margin-top: 10px;
}

// Stack Holder

.userdata {
  margin-top: 10px;
  display: flex;
  gap: 210px;
  margin-left: 60px;
  align-items: center;
}
.userdata_ptag {
  margin-left: 13%;
}

.StackHolder-details {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #eaedf3;
  border-radius: 10px;
  height: 70px;
  flex-shrink: 0;
  padding: 0 20px; /* Added padding to the left and right */
}

.StackHolder-details-profile {
  display: flex;
  align-items: center;
  gap: 20px;
}

.StackHolder-details-profile img {
  border-radius: 50%;
}

.StackHolder-details p {
  margin: 0;
  padding: 0 10px;
}

.three-dots {
  cursor: pointer;
  font-size: 1.5em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.three-dots:after {
  cursor: pointer;
  color: black;
  content: '\2807';
  font-size: 20px;
  padding: 0 5px;
}

.dropdown-container {
  position: relative;
  margin-left: auto; /* Pushes the dropdown to the right end */
  display: flex;
  align-items: center;
}

.dropdown {
  position: absolute;
  right: 0;
  top: 100%;
  background-color: white;
  border: 1px solid #eaedf3;
  outline: none;
  opacity: 0;
  z-index: -1;
  max-height: 0;
  transition: opacity 0.2s ease, max-height 0.2s ease, z-index 0.2s;
}

.dropdown a {
  display: block;
  padding: 10px 20px;
  color: #333;
  text-decoration: none;
}

.dropdown-container:focus-within .dropdown {
  opacity: 1;
  z-index: 100;
  max-height: 300px; /* Adjust based on your content */
}

.dropdown-container:focus {
  outline: none;
}

.ManageStakeholders-Manage, .ManageStakeholders-Download {
  padding: 0;
  margin: 0;
  height: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  border: none;
  color: black;
  background-color: white;
  border-radius: 4px;
  border: 1px solid var(--Primary-Purple-50, #eaedf3);
  background: #fff;
}