.CapTab-details-chart {
  width: 450px;
  height: 409px;
  border: 1px solid #eceef4;
}
.CapTab-details-chart-data {
  display: flex;
  width: 390px;
  height: 141px;
  padding: 34px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid #eceef4;
}

.view_data p {
  color: var(--Table, #a8aaaf);
  leading-trim: both;
  text-edge: cap;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.view_details {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.view_details p {
  color: var(--Text-Primary, #7d55c7);
  font-family: Roboto;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px; /* 110% */
  letter-spacing: -0.8px;
  display: flex;
}

.view_details_enddata {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.view_details_enddata p {
  color: var(--Black, #3e3f42);
  font-family: Roboto;
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px; /* 110% */
  letter-spacing: -0.8px;
}
.ViewCapital-calender {
  display: flex;
  float: right;
  align-items: center;
}

.CapTab-details-chart-box {
  display: flex;
  gap: 20px;
  width: 90%;
}
@media screen and (max-width: 1200px) {
  .CapTab-details-chart-box {
    flex-direction: column;
  }
}

.second-table {
  display: flex;
  gap: 20px;
  margin: 10px;
}
.CapTab-details-chart {
  width: 100%;
}
.table-data {
  border: 1px solid #eceef4;
  padding-top: 20px;
  padding-bottom: 20px;
  width: 90%;
  padding-left: 10px;
}

.table {
  margin-top: 20px;
}
.images {
  border-radius: 22.82px;
  background: var(--Success-50, #ecfdf3);
  justify-content: center;
  align-items: center;
  display: flex;
  width: 50px;
}
.CapTab-flex {
  display: flex;
  padding: 20px;
  gap: 20px;
}
.continer-captab {
  width: 630px;
  height: 409px;
  flex-shrink: 0;
  fill: var(--White, #fff);
  stroke-width: 0.964px;
  stroke: #eaedf3;
  border: 1px solid #eaedf3;
  padding: 20px;
}
.captab-flex-data {
  display: flex;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 10px;
  justify-content: space-between;
}
.image-container {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  margin-left: 20px;
  margin-top: 10px;
  margin-right: 20px;
}

.color-graph {
  width: 11.562px;
  height: 11.562px;
  border-radius: 2.891px;
  background: var(--Action-Green, #50cd89);
}
.CapTab-color-flex {
  display: flex;
  gap: 20px;
  align-items: center;
}
.CapTab-color-flex-conatiner {
  align-items: center;
  display: flex;
  justify-content: center;
}
.CapTab-color-flex-conatiner-next {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

@media screen and (max-width: 1000px) {
  .View-details-chart-box {
    flex-direction: column;
    align-items: center;
  }
  .View-details-chart-data{
    width: 100%;
  }
}

.CapTab-alldata{
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}