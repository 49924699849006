.draft-option-grants {
  padding: 20px;
}

.Share-Draft-data {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.draft-share-data-container {
  border: 1px solid #eaedf3;
}

.draft-share-data {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  flex-wrap: wrap;
}

.draft-share-data h1 {
  color: var(--Black, #3e3f42);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
}

.Equityplans-data {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Equityplans-data-container-maindiv {
  text-align: center;
}

.Equityplans-Error-Container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Equityplans-data-container-image img {
  max-width: 100%;
  height: auto;
}

@media (max-width: 768px) {
  .Share-Draft-data {
    flex-direction: column;
  }

  .draft-share-data {
    flex-direction: column;
  }

  .draft-share-data h1 {
    text-align: center;
    width: 100%;
  }
}


.Draftoptiongrants-share{
  padding: 0;
  margin: 0;
  height: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  border: none;
  color: white;
  width: 150px;
  border-radius: 4px;
  border: 1px solid rgba(125, 85, 199, 0.00);
  background: var(--Primary-Purple-500, #7D55C7);
  box-shadow: 0px 2px 0px 0px rgba(255, 255, 255, 0.06) inset, 0px 1px 1px 0px rgba(19, 31, 21, 0.10);
}
.Draftoptiongrants-search-data{
  display: flex;
  padding: 10px;
  justify-content: space-between;
  width: 100%;
}