.syndicate-general {
  width: 810px !important;
  // margin-left: 230px;
  padding: 0 40px 60px 40px;

  @media screen and (max-width:1440px) {
    width: 700px !important;
  }

  @media screen and (max-width:900px) {
    width: 574px !important;
  }
}

.newloan-nav {
  width: 125px;

  .steps {
    gap: 30px;
  }
}

.steps .loan-progress {
  position: absolute;
  height: 610px;
  width: 3px;
  background: #d4d4d4;
  left: 89%;
  z-index: -1;
}

.syndicate-doc-right {
  display: flex;
  gap: 45px;

  // @media screen and (max-width:1300px) {
  //   flex-direction: column;
  // }
  #pre-head {
    color: #9ea0a5;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    /* 157.143% */
    margin-bottom: 5px;
  }
}

/*===============================================*/
/*               LOCATION SYNDICATE              */
/*===============================================*/
.syndicate-general {
  .addr {
    width: 670px;

    @media screen and (max-width:1440px) {
      width: 592px !important;
    }

    @media screen and (max-width:900px) {
      width: 480px !important;
    }

    .inputBoxMain input {
      padding: 0;
    }

    input {
      width: 100% !important;
    }
  }

  .location-body {
    margin: 30px;
    width: 612px;

    .location-body-bottom {
      display: flex;
      align-items: center;
      gap: 30px;

      @media screen and (max-width:900px) {
        gap: 0px !important;
      }

      .addr-body {
        width: 320px;

        .inputBoxMain input {
          padding: 0;
        }
      }

      @media screen and (max-width:1440px) {
        .city_dropdown {
          width: 87% !important;
        }

        .state_dropdown {
          width: 87% !important;
        }
      }

      @media screen and (max-width:900px) {
        .city_dropdown {
          width: 72% !important;
        }

        .state_dropdown {
          width: 72% !important;
        }
      }
    }
  }
}

.addressButton {
  .PrimaryButton {
    padding: 8px 20px;
  }
}

/*===============================================*/
/*               KEYDEAL SYNDICATE              */
/*===============================================*/
.keydeal-input-B {
  width: 612px;

  @media screen and (max-width:1440px) {
    width: 560px !important;
  }
  @media screen and (max-width:900px) {
    width: 500px !important;
  }
}

.keydeal-bottom {
  display: flex;
  gap: 10px;
  margin: 30px 0 30px 87px;

  span {
    color: #3e3f42;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    /* 157.143% */
  }
}

/*===============================================*/
/*               BUSINESS SYNDICATE              */
/*===============================================*/
.business-body {
  position: relative;

  #videoslider {
    position: absolute;
    left: 15px;
    top: 85%;
  }
}

.switch-business {
  width: 275px;
  margin: 30px 208px;
}

.business-body-bottom {
  margin: 30px;

  p {
    color: #6b6c6f;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    /* 157.143% */
    margin-top: 18px;
  }

  .bottom-head-B {
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      color: #6b6c6f;
      text-align: right;
      font-family: Roboto;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
      /* 150% */
    }

    .bottom-head-left {
      display: flex;
      gap: 24px;
      align-items: center;

      h3 {
        color: #3e3f42;
        font-family: Roboto;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px;
        /* 150% */
      }

      span {
        color: #9ea0a5;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        /* 157.143% */
      }

      p {
        color: #6b6c6f;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        display: inline;
      }

      #buildimg {
        width: 60px;
        height: 60px;
        background-color: var(--Cyan-Gray, #617d92);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;

        img {
          height: 37px;
          width: 37px;
        }
      }
    }
  }
}

.business-A-main {
  h1 {
    color: var(--LP-gray, #9ea0a5);
    font-family: Roboto;
    font-size: 26px;
    font-style: normal;
    font-weight: 300;
    line-height: 38px;
    /* 146.154% */
    margin: 50px 0 30px 0;
  }

  .business-bottom-A {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p {
      color: #6b6c6f;
      text-align: center;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      /* 171.429% */
      margin-top: 20px;
    }
  }
}

.box-content-right {
  .big-btn {
    width: 265px;
    height: 38px;
    display: flex;
    align-items: center;
    padding-left: 12px;
    cursor: pointer;
  }

  .refine-btn {
    gap: 25px;
    display: flex;
    border-radius: 4px;
    border: 1px solid #d8dce6;
    background: linear-gradient(0deg, #f6f7f9 0%, #fff 100%);
    box-shadow: 0px 1px 1px 0px rgba(22, 29, 37, 0.05), 0px 2px 0px 0px rgba(255, 255, 255, 0.05) inset;
    margin-bottom: 15px;

    span {
      color: #3e3f42;
      text-align: center;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      /* 157.143% */
    }
  }

  .discard-btn {
    gap: 40px;
    display: flex;
    border-radius: 4px;
    border: 1px solid var(--red-pantone-1797-c-72, rgba(203, 51, 59, 0.72));
    background: linear-gradient(0deg, #f6f7f9 0%, #fff 100%);
    box-shadow: 0px 1px 1px 0px rgba(22, 29, 37, 0.05), 0px 2px 0px 0px rgba(255, 255, 255, 0.05) inset;

    span {
      color: var(--red-pantone-1797-c, #cb333b);
      text-align: center;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      /* 157.143% */
    }
  }
}

.general-business-container {
  border: 1px solid #eaedf3;
  fill: #fff;
  stroke-width: 1px;
  stroke: #eaedf3;
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.04));
  margin-top: 30px;
  border-radius: 4px;

  .general-detail {
    padding: 30px 30px 19px 30px;

    p:first-child {
      color: #6b6c6f;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      /* 157.143% */
      margin-bottom: 30px;
    }
  }
}

.business-table {
  margin-bottom: 30px;

  p:nth-child(1) {
    color: #3e3f42;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    /* 157.143% */
    margin: 0 0 13px 30px;
  }

  p:nth-child(3) {
    width: 380px;
    color: #3e3f42;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    /* 157.143% */
    margin: 30px 0 10px 30px;
  }

  p:nth-child(4) {
    color: #6b6c6f;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    /* 157.143% */
    margin: 0 30px 0 30px;
  }
}

.custom-table {
  .custom-head-cell {
    color: #9ea0a5;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    text-transform: uppercase;
  }

  .custom-body-cell {
    color: #3e3f42;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
}

@media screen and (max-width:1300px) {
  .doc_navigator_extra {
    display: block;
  }

  .doc_navigator {
    display: none;
  }
}

@media screen and (min-width:1300px) {
  .doc_navigator_extra {
    display: none;
  }
}

.location-container {
  width: 730px;
  fill: #fff;
  stroke-width: 1px;
  stroke: #eaedf3;
  border: 1px solid #eaedf3;
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.04));
  margin-top: 30px;

  @media screen and (max-width:1440px) {
    width: 640px !important;


    input {
      width: 75% !important;
    }

    select {
      width: 75% !important;
    }
  }

  @media screen and (max-width:900px) {
    width: 540px !important;

    input {
      width: 52% !important;
    }
  }
}

.Asset-container {

  width: 730px;
  fill: #fff;
  stroke-width: 1px;
  stroke: #eaedf3;
  border: 1px solid #eaedf3;
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.04));
  margin-top: 30px;

  @media screen and (max-width:1440px) {
    width: 640px !important;
    // margin: auto;
    margin-top: 30px;

    input {
      width: 75% !important;
    }

    select {
      width: 75% !important;
    }
  }

  @media screen and (max-width:900px) {
    width: 540px !important;

    input {
      width: 52% !important;
    }
  }
}