.AiSearch {
  .ai-search-1 {
    padding: 30px 0 0 30px;
    display: block;
    min-height: 513px;

    .search-upper {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: flex-start;
      padding-right: 30px;

      .left {
        display: flex;
        gap: 16px;
        align-items: flex-start;
        > div {
          color: var(--LP-mid-gray, #6b6c6f);
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 21px */
          display: flex;
          gap: 20px;
          flex-direction: column;
          > div {
            > span {
              display: block;
              color: #000;
              font-family: Roboto;
              font-size: 24px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              text-transform: capitalize;
            }
            > span:last-child {
              color: var(--LP-mid-gray, #6b6c6f);
              font-family: Roboto;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 150%; /* 21px */
              margin-top: 10px;
            }
          }
        }
      }
      .ai-slider {
        width: 750px;
      }
    }

    .ai-search-heading {
      margin-top: 40px;
      display: flex;
      gap: 55px;
      align-items: center;
      > div {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 0;
        width: 105px;
        height: 40px;
        flex-shrink: 0;
        border-radius: 0px 500px 500px 0px;
        border: 0px solid var(--purple-pantone-2665-c-71, rgba(125, 85, 199, 0.71));
        background: rgba(125, 85, 199, 0.5);
        color: #fff;
      }
      > span {
        color: #000;
        font-family: Roboto;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        display: block;
        margin-left: 125px;
      }
    }
    .ai-btn-con {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-bottom: 50px;
      padding-top: 20px;
      padding-left: 25px;
      .btn {
        background: linear-gradient(184deg, #ffffff 6%, #f6f7f9 81.78%), #ffffff;
        border: 1px solid #eaedf3;
        color: #3e3f42;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 157.143% */
        width: 93px;
        display: flex;
        margin: 0;
        align-items: center;
        gap: 12px;
        padding-left: 15px;
        span {
          margin: 0 !important;
        }
        img {
          margin: 0 !important;
        }
      }
    }
  }
  #background-img {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: -10;
  }
  &.AiSearch2 {
    .search-upper {
      .buttons_wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;
        height: 98px;
        .edit_button {
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 5px;
          color: #3e3f42;
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px; /* 157.143% */
        }
      }
    }
    .AiSearch_container {
      width: 50%;
      left: 50%;
      min-height: 460px;

      .MarketSecondAi {
        margin-top: 54px;
      }
    }
    .ai-search-heading {
      span {
        margin: 0px;
        color: #000;
        font-family: Roboto;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
    .search-lower {
      margin-left: 40px;
      margin-right: 40px;
      .inputBox-wrapper-img {
        flex: 3;
        margin-top: 20px;
        display: flex;
        align-items: center;
        position: relative;
        z-index: 1;
        .inputBoxMain {
          width: 100%;
        }

        svg {
          position: absolute;

          top: 63%;
          left: 10px;
          z-index: 10;
          transform: translate(-50%, -50%);
        }
      }
      .starting-point-wrapper {
        display: flex;
        align-items: flex-start;
        gap: 20px;
        max-width: 440px;
        .icon {
          margin-top: 30px;
        }
        .dropDownOuterCon {
          flex: 1.5;
        }

        .inputBox-wrapper-img {
          svg {
            left: 15px;
          }
          .inputBoxMain {
            .basic_profile_input {
              input {
                padding-left: 30px;
              }
            }
          }
        }
        .icon {
          margin-top: 54px;
        }
      }
      .ai-btn-con {
        margin: 0px;
        margin-top: 20px;
        padding-left: 0;
      }
    }
    // market Survey
    .market-tab-container {
      padding: 0px 2rem;

      .market-header {
        font-family: Roboto;
        color: #3e3f42;
        font-weight: 500;
        font-size: 18px;
        line-height: 28px;
      }

      .market-tabs {
        margin-top: 14px;
        display: flex;
        flex-direction: row;
        column-gap: 20px;
        border-bottom: 1px solid #eaedf3;

        a {
          font-family: Roboto;
          color: #6b6c6f;
          font-weight: 400;
          font-size: 12px;
          padding-bottom: 14px;

          &.active {
            border-bottom: 2px solid #7d55c7;
            transition: all 0.3;
          }
        }
      }

      .market-tab-content {
        padding: 20px 0px;
        padding-top: 0px;
        .sort_header {
          display: flex;
          justify-content: space-between;
          padding: 20px 0px;
          align-items: center;
          .buttons {
            display: flex;
            gap: 10px;
          }

          h3 {
            color: #3e3f42;
            font-family: Roboto;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 30px; /* 150% */
          }
        }
      }
      &.AiSearch {
        padding: 0px;

        .market-tab-content {
          // display: none;
          .asset_reviews_wrapper {
            h1 {
              display: none;
            }
            .ratings {
              margin-top: 0px;
            }
          }
          .social_networks {
            h1 {
              display: none;
            }
          }
          .news-summaries,
          .social_network_wrapper {
            height: 281px;
            overflow: scroll;
            .market-s-main-wrapper,
            .twitter_post {
              width: 100%;
            }
          }
          .social_network_wrapper {
            .filter_results {
              display: none;
            }
          }
          .pros-and-cons {
            grid-template-columns: 1fr;
            .recommendations-container {
              padding: 0px;
            }

            .recommendations-content {
              max-height: 281px;

              overflow-y: scroll;
              .card-container {
                width: 100%;
                border: 1px solid #eaedf3;
                .card-footer {
                  margin: 10px 10px;
                }
              }
            }

            .map-wrapper {
              display: none;
            }
          }
        }
      }
    }
  }
}

//first aisearch
.first-ai {
  margin-top: 40px;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding-right: 30px;
  flex-wrap: wrap;
  .stragySelector {
    width: 297px;
    height: 240px;
    flex-shrink: 0;
    padding: 20px 30px 16px 30px;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    border: 1px solid #e2e5ed;
    background: linear-gradient(180deg, rgb(56, 56, 56) -7%, rgb(255, 255, 255) 2%);
    h1 {
      //   margin-top: 20px;
      color: #3e3f42;
      text-align: center;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
    }
    h2 {
      margin-top: 12px;
      color: #6b6c6f;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 157.143% */
      // min-height: 132px;
      margin-bottom: 18px;
    }
    h3 {
      color: var(--LP-gray, #9ea0a5);
      font-family: Roboto;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    h4 {
      color: #3e3f42;
      font-family: Roboto;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 220% */
    }
    // .spacerstrategy {
    //   width: 215px;
    //   height: 1px;
    //   flex-shrink: 0;
    //   margin-top: 40px;
    //   margin-bottom: 12px;
    //   background: var(--LP-very-light, #eaedf3);
    // }

    .svgText {
      h3,
      h4 {
        line-height: normal;
      }
    }

    .svgWholeCon {
      display: flex;
      width: 100%;
      justify-content: space-between;
      // margin-bottom: 37px;
      .svgCon {
        display: flex;
        gap: 6px;
        margin-right: 10px;
      }
    }
    .strategyBtn {
      cursor: pointer;
      border-radius: 4px;
      border: 1px solid var(--purple-pantone-2665-c-71, rgba(125, 85, 199, 0.71));
      background: linear-gradient(0deg, rgba(125, 85, 199, 0.3) 0%, rgba(255, 255, 255, 0) 107.78%), #7d55c7;
      background-blend-mode: multiply, normal;
      box-shadow: 0px 1px 1px 0px rgba(19, 31, 21, 0.1), 0px 2px 0px 0px rgba(255, 255, 255, 0.06) inset;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      text-align: center;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 157.143% */
      width: 215px;
      padding: 8px 0;
      box-sizing: border-box;
    }
  }
  .investment_objectives {
    width: 100%;
    border-radius: 4px;
    border: 1px solid #e2e5ed;
    background: #fff;
    .top {
      border-bottom: 1px solid #e2e5ed;
      display: flex;
      padding: 20px 30px;
      gap: 20px;
      p {
        color: #9ea0a5;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px; /* 157.143% */
      }
    }
    .bottom {
      padding: 30px;

      .upper,
      .lower {
        display: flex;
        gap: 40px;
        .selector_container {
          width: 324px;
          .dropDownOuterCon p {
            color: #515151;

            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px; /* 150% */
            text-transform: uppercase;
            width: 100%;
          }
          p {
            color: #9ea0a5;
            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px; /* 157.143% */
            width: 70%;
          }
        }
      }
    }
  }
  .investment_vehicles {
    margin-bottom: 20px;
    width: 99%;
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    .container {
      width: 350px;
    }
  }
  .location {
    width: 100%;
    display: flex;
    .left {
      min-width: 40%;
    }
    .right {
      width: 100%;
      .container {
        width: 200px;
      }
    }
    .lower {
      margin-top: 20px;
      p {
        color: #3e3f42;

        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 157.143% */
      }
      .input_Wrapper {
        .inputBoxMain {
          width: 100%;
        }
        .delete_button {
          margin-top: 45px;
          padding-left: 10px;
        }
        gap: 40px;
        display: flex;
        align-items: center;

        .dropDownOuterCon {
          width: 27%;
        }
        input {
          height: auto;
        }
      }
    }
  }
  .marketSize {
    width: 100%;
  }
  .property_row {
    padding: 20px 0px 40px 0px;
    .title {
      h2 {
        display: flex;
        align-items: center;
        gap: 10px;
        color: var(--LP-black--_lib, #3e3f42);
        font-family: Roboto;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px; /* 155.556% */
        margin-bottom: 20px;
      }
    }
    .boxes_container {
      .investment_vehicles {
        flex-wrap: wrap;
        gap: 20px;
        .container {
          width: 360px;
        }
        &.twoBoxes {
          justify-content: left;
        }
      }
    }
    .checkBoxCon {
      margin-top: 30px;
      display: flex;
      align-items: center;
      gap: 12px;
      p {
        margin-top: 4px;
        color: #3e3f42;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 157.143% */
      }
    }
    .investment_objectives {
      width: 99%;
      .sliders_wrapper {
        padding: 30px;
        .switch_wrapper {
          align-self: center;
          margin-top: 14px;
        }
        .upper,
        .middle,
        .lower {
          display: flex;
          gap: 30px;
          align-items: end;
        }
        .dropDownOuterCon {
          max-width: 320px;
        }
        .slider_container {
          margin: 10px 0px;
          position: relative;
          .top_div {
            // font-size: 12px;
            border-radius: 4px;
            border: 1px solid #e4e8ee;
            padding: 6px 10px;
            z-index: 2;
            p {
              font-size: 14px;
              text-align: center;
              margin: 0px;
            }
            span {
              color: var(--LP-black, #3e3f42);
              text-align: right;
              font-family: Roboto;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 22px; /* 157.143% */
            }
          }
          .MuiSlider-root {
            position: absolute;
            z-index: 1;
            top: 12px;
            .MuiSlider-rail {
              color: #b7b7b7 !important;
            }
            .MuiSlider-track,
            .MuiSlider-thumb {
              color: #7d55c7;
              width: 18px;
              height: 18px;
            }
            .MuiSlider-track {
              height: 2px;
            }
          }
        }
      }
    }
  }
  .hold_period {
    width: 100%;
    .investment_vehicles {
      display: flex;
      gap: 20px;
    }
    .container {
      width: 100%;
    }
  }
  .current_data {
    width: 99%;
    .investment_objectives {
      .bottom {
        margin: 0 auto;
        padding: 30px;
        width: 50%;
      }
    }
    .additional_parameters {
      margin-top: 40px;
      .title {
        h2 {
          display: flex;
          align-items: center;
          gap: 10px;
          color: var(--LP-black--_lib, #3e3f42);
          font-family: Roboto;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 28px; /* 155.556% */
          margin-bottom: 20px;
        }
        span {
          color: var(--LP-mid-grey-_lib, #6b6c6f);
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px; /* 157.143% */
        }
      }
      .switches_wrapper {
        .spacerLine30 {
          margin: 15px 0px;
        }
        .row {
          .container {
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: space-between;
            p {
              color: #3e3f42;
              font-family: Roboto;
              font-size: 15px;
              font-style: normal;
              font-weight: 400;
              line-height: 18px; /* 157.143% */
            }
            .switch_wrapper {
              display: flex;
              align-items: center;
            }
          }
        }
      }
    }
  }
  .prospects {
    width: 99%;
    .property_row {
      padding-top: 0px;
      .title {
        h2 {
          color: #6b6c6f;
        }
      }
      .investment_objectives {
      }
      .strategies {
        display: flex;
        width: 100%;
        justify-content: space-between;
        gap: 30px;
        .strategy_card {
          // width: 100%;
          flex-grow: 1;
          padding: 30px;
          border-radius: 4px;
          border: 1px solid #eaedf3;
          background: #fff;
          box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.04);
          max-width: 350px !important;
          .title {
            h2 {
              color: #3e3f42;
              justify-content: space-between;
              font-family: Roboto;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 24px; /* 150% */
            }
          }
          p {
            color: #9ea0a5;
            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px; /* 150% */
            text-transform: uppercase;
          }
          .textarea_wrapper {
            position: relative;
            // padding: 8px 12px;
            width: 100%;
            margin: 8px 0px;
            textarea {
              all: unset;
              padding: 16px;
              width: 90%;
              border-radius: 4px;
              border: 1px solid #e2e5ed;
              background: #fff;
              box-shadow: 0px 1px 2px 0px rgba(102, 113, 123, 0.21) inset;
              ::placeholder {
                color: #9ea0a5;
                font-family: Roboto;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px; /* 157.143% */
              }
            }
            .buttons_wrapper {
              position: absolute;
              bottom: 16px;
              left: 16px;

              align-items: baseline;
              display: flex;
              width: 90%;
              justify-content: space-between;
              button {
                all: unset;
                border-radius: 4px;
                border: 1px solid var(--LP-very-light, #eaedf3);
                background: #fff;
                display: inline-flex;
                padding: 4px 12px;
                align-items: center;
                gap: 10px;
                span {
                  color: var(--LP-mid-gray, #6b6c6f);
                  font-family: Roboto;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 22px; /* 157.143% */
                  text-transform: capitalize;
                }
              }
            }
          }
        }
      }
    }
  }
}

//custom-goal

.custom-goal-wrapper {
  padding-right: 50px;
  margin-top: 30px;
  margin-bottom: 30px;
}
.property_row-ai {
  padding-top: 0px;
  .title {
    h2 {
      color: #6b6c6f;
      display: flex;
      align-items: center;
      gap: 10px;
      margin-bottom: 20px;
    }
  }
  .investment_objectives {
  }
  .strategies {
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 30px;
    flex-wrap: wrap;
    .strategy_card {
      // width: 100%;
      flex-grow: 1;
      padding: 30px;
      border-radius: 4px;
      border: 1px solid #eaedf3;
      background: #fff;
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.04);
      // max-width: 350px !important;
      width: 400px;
      // height: 320px;
      flex-shrink: 0;
      .title {
        h2 {
          color: #3e3f42;
          justify-content: space-between;
          font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px; /* 150% */
        }
      }
      p {
        color: #9ea0a5;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px; /* 150% */
        text-transform: uppercase;
      }
      .textarea_wrapper {
        position: relative;
        // padding: 8px 12px;
        width: 100%;
        margin: 8px 0px;
        textarea {
          all: unset;
          padding: 16px;
          width: 90%;
          border-radius: 4px;
          border: 1px solid #e2e5ed;
          background: #fff;
          box-shadow: 0px 1px 2px 0px rgba(102, 113, 123, 0.21) inset;
          ::placeholder {
            color: #9ea0a5;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px; /* 157.143% */
          }
        }
        .buttons_wrapper {
          position: absolute;
          bottom: 16px;
          left: 16px;

          align-items: baseline;
          display: flex;
          width: 90%;
          justify-content: space-between;
          button {
            all: unset;
            border-radius: 4px;
            border: 1px solid var(--LP-very-light, #eaedf3);
            background: #fff;
            display: inline-flex;
            padding: 4px 12px;
            align-items: center;
            gap: 10px;
            span {
              color: var(--LP-mid-gray, #6b6c6f);
              font-family: Roboto;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 22px; /* 157.143% */
              text-transform: capitalize;
            }
          }
        }
      }
    }
  }
}

.sectionSignupQ-B {
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .stragySelector {
    width: 255px;
    height: 381px;
    padding: 0 20px;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    border: 1px solid #e2e5ed;
    background: #fff;
    h1 {
      //   margin-top: 20px;
      color: #3e3f42;
      text-align: center;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
    }
    h2 {
      margin-top: 12px;
      color: #6b6c6f;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 157.143% */
      min-height: 132px;
    }
    h3 {
      color: var(--LP-gray, #9ea0a5);
      font-family: Roboto;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    h4 {
      color: #3e3f42;
      font-family: Roboto;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 220% */
    }
    .spacerstrategy {
      width: 215px;
      height: 1px;
      flex-shrink: 0;
      margin-top: 40px;
      margin-bottom: 12px;
      background: var(--LP-very-light, #eaedf3);
    }
    .svgWholeCon {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-bottom: 37px;
      .svgCon {
        display: flex;
        gap: 6px;
        margin-right: 10px;
      }
    }
    .strategyBtn {
      cursor: pointer;
      border-radius: 4px;
      border: 1px solid var(--purple-pantone-2665-c-71, rgba(125, 85, 199, 0.71));
      background: linear-gradient(0deg, rgba(125, 85, 199, 0.3) 0%, rgba(255, 255, 255, 0) 107.78%), #7d55c7;
      background-blend-mode: multiply, normal;
      box-shadow: 0px 1px 1px 0px rgba(19, 31, 21, 0.1), 0px 2px 0px 0px rgba(255, 255, 255, 0.06) inset;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      text-align: center;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 157.143% */
      width: 215px;
      padding: 8px 0;
      box-sizing: border-box;
    }
  }
  .investment_objectives {
    width: 100%;
    border-radius: 4px;
    border: 1px solid #e2e5ed;
    background: #fff;
    .top {
      border-bottom: 1px solid #e2e5ed;
      display: flex;
      padding: 20px 30px;
      gap: 20px;
      p {
        color: #9ea0a5;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px; /* 157.143% */
      }
    }
    .bottom {
      padding: 30px;

      .upper,
      .lower {
        display: flex;
        gap: 40px;
        .selector_container {
          width: 324px;
          .dropDownOuterCon p {
            color: #515151;

            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px; /* 150% */
            text-transform: uppercase;
            width: 100%;
          }
          p {
            color: #9ea0a5;
            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px; /* 157.143% */
            width: 70%;
          }
        }
      }
    }
  }
  .investment_vehicles {
    margin-bottom: 20px;
    width: 99%;
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    .container {
      width: 350px;
    }
  }
  .location {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    .left {
      min-width: 40%;
    }
    .right {
      // width: 100%;
      .container {
        width: 200px;
      }
    }
    .lower {
      margin-top: 20px;
      p {
        color: #3e3f42;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 0;
      }
      .input_Wrapper {
        margin-top: 10px;
        > div {
          display: flex;
          flex-direction: column;
          gap: 10px;
        }

        .dropDownOuterCon {
          margin-top: 0;
        }

        .inputBoxMain {
          width: 100%;

          @media screen and (max-width: 900px) {
            width: 56% !important;
          }

          .basic_profile_input {
            input {
              height: 38px;
            }
          }
        }
        .delete_button {
          justify-content: flex-end;
          cursor: pointer;
        }
        gap: 40px;
        display: flex;
        align-items: stretch;

        @media screen and (max-width: 1400px) {
          margin-bottom: 30px;
        }

        .dropDownOuterCon {
          width: 27%;
        }
        input {
          height: auto;
        }
      }
    }
  }
  .marketSize {
    width: 100%;
  }
  .property_row {
    padding: 20px 0px 40px 0px;
    .title {
      h2 {
        display: flex;
        align-items: center;
        gap: 10px;
        color: var(--LP-black--_lib, #3e3f42);
        font-family: Roboto;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px; /* 155.556% */
        margin-bottom: 20px;
      }
    }
    .boxes_container {
      .investment_vehicles {
        flex-wrap: wrap;
        gap: 20px;
        .container {
          width: 360px;
        }
        &.twoBoxes {
          justify-content: left;
        }
      }
    }
    .checkBoxCon {
      margin-top: 30px;
      display: flex;
      align-items: center;
      gap: 12px;
      p {
        margin-top: 4px;
        color: #3e3f42;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 157.143% */
      }
    }
    .investment_objectives {
      width: 99%;
      .sliders_wrapper {
        padding: 30px;
        .switch_wrapper {
          align-self: center;
          margin-top: 14px;
        }
        .upper,
        .middle,
        .lower {
          display: flex;
          gap: 30px;
          align-items: end;
        }
        .dropDownOuterCon {
          max-width: 320px;
        }
        .slider_container {
          margin: 10px 0px;
          position: relative;
          .top_div {
            // font-size: 12px;
            border-radius: 4px;
            border: 1px solid #e4e8ee;
            padding: 6px 10px;
            z-index: 2;
            p {
              font-size: 14px;
              text-align: center;
              margin: 0px;
            }
            span {
              color: var(--LP-black, #3e3f42);
              text-align: right;
              font-family: Roboto;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 22px; /* 157.143% */
            }
          }
          .MuiSlider-root {
            position: absolute;
            z-index: 1;
            top: 12px;
            .MuiSlider-rail {
              color: #b7b7b7 !important;
            }
            .MuiSlider-track,
            .MuiSlider-thumb {
              color: #7d55c7;
              width: 18px;
              height: 18px;
            }
            .MuiSlider-track {
              height: 2px;
            }
          }
        }
      }
    }
  }
  .hold_period {
    width: 100%;
    .investment_vehicles {
      display: flex;
      gap: 20px;
    }
    .container {
      width: 100%;
    }
  }
  .current_data {
    width: 99%;
    .investment_objectives {
      .bottom {
        margin: 0 auto;
        padding: 30px;
        width: 50%;
      }
    }
    .additional_parameters {
      margin-top: 40px;
      .title {
        h2 {
          display: flex;
          align-items: center;
          gap: 10px;
          color: var(--LP-black--_lib, #3e3f42);
          font-family: Roboto;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 28px; /* 155.556% */
          margin-bottom: 20px;
        }
        span {
          color: var(--LP-mid-grey-_lib, #6b6c6f);
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px; /* 157.143% */
        }
      }
      .switches_wrapper {
        .spacerLine30 {
          margin: 15px 0px;
        }
        .row {
          .container {
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: space-between;
            p {
              color: #3e3f42;
              font-family: Roboto;
              font-size: 15px;
              font-style: normal;
              font-weight: 400;
              line-height: 18px; /* 157.143% */
            }
            .switch_wrapper {
              display: flex;
              align-items: center;
            }
          }
        }
      }
    }
  }
  .prospects {
    width: 99%;
    .property_row {
      padding-top: 0px;
      .title {
        h2 {
          color: #6b6c6f;
        }
      }
      .investment_objectives {
      }
      .strategies {
        display: flex;
        width: 100%;
        justify-content: space-between;
        gap: 30px;
        .strategy_card {
          // width: 100%;
          flex-grow: 1;
          padding: 30px;
          border-radius: 4px;
          border: 1px solid #eaedf3;
          background: #fff;
          box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.04);
          max-width: 350px !important;
          .title {
            h2 {
              color: #3e3f42;
              justify-content: space-between;
              font-family: Roboto;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 24px; /* 150% */
            }
          }
          p {
            color: #9ea0a5;
            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px; /* 150% */
            text-transform: uppercase;
          }
          .textarea_wrapper {
            position: relative;
            // padding: 8px 12px;
            width: 100%;
            margin: 8px 0px;
            textarea {
              all: unset;
              padding: 16px;
              width: 90%;
              border-radius: 4px;
              border: 1px solid #e2e5ed;
              background: #fff;
              box-shadow: 0px 1px 2px 0px rgba(102, 113, 123, 0.21) inset;
              ::placeholder {
                color: #9ea0a5;
                font-family: Roboto;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px; /* 157.143% */
              }
            }
            .buttons_wrapper {
              position: absolute;
              bottom: 16px;
              left: 16px;

              align-items: baseline;
              display: flex;
              width: 90%;
              justify-content: space-between;
              button {
                all: unset;
                border-radius: 4px;
                border: 1px solid var(--LP-very-light, #eaedf3);
                background: #fff;
                display: inline-flex;
                padding: 4px 12px;
                align-items: center;
                gap: 10px;
                span {
                  color: var(--LP-mid-gray, #6b6c6f);
                  font-family: Roboto;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 22px; /* 157.143% */
                  text-transform: capitalize;
                }
              }
            }
          }
        }
      }
    }
  }
}

// -----------skkkk--------------

.sector-comp-container {
  padding: 30px;

  @media screen and (max-width: 900px) {
    margin: 20px !important;
  }

  .property_row {
    .boxes_container {
      .investment_vehicles {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 29px;

        @media screen and (max-width: 1400px) {
          grid-template-columns: repeat(3, 1fr);
        }
        @media screen and (max-width: 1000px) {
          grid-template-columns: repeat(2, 1fr);
        }
        .container {
        }
        &.twoBoxes {
          justify-content: left;
        }
      }
    }
    .checkBoxCon {
      margin-top: 30px;
      display: flex;
      align-items: center;
      gap: 12px;
      p {
        color: #3e3f42;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 157.143% */
      }
    }
  }
}

.deal-role-container {
  display: flex;
  gap: 30px;
  margin: 40px 0 30px 70px;
  flex-wrap: wrap;
  .container {
    width: 297px;
    height: 171px;
    position: relative;
    padding-top: 20px;
  }
  .checkBoxCon {
    margin-top: 30px;
    display: flex;
    align-items: center;
    gap: 12px;
    p {
      margin-top: 4px;
      color: #3e3f42;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 157.143% */
    }
  }
  .selectBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 151px;
  }
  #selectbox-img {
    height: 42px;
    width: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #6758f3;
    border-radius: 50%;
    position: absolute;
    top: 1px;
    left: 43%;
    img {
      width: 20px;
      height: 20px;
    }
  }
}

.capital-container {
  margin: 30px;
  .equity-body-bottom {
    display: flex;
    // align-items: center;
    gap: 30px;
    margin: 10px 30px 30px 30px;
    flex-wrap: wrap;
    .equity-bottom-left {
      width: 200px;
      .bottom-left-A {
        padding-bottom: 12px;
        border-bottom: 1px solid #eaedf3;
        margin-bottom: 20px;
        p {
          text-align: right;
          font-size: 26px;
          font-weight: 400;
          line-height: 38px; /* 146.154% */
          color: var(--purple-pantone-2665-c, #7d55c7);
        }
      }
      .bottom-left-B {
        padding-bottom: 12px;
        margin-bottom: 20px;
        border-bottom: 1px solid #eaedf3;
        p {
          text-align: right;
          font-size: 26px;
          font-weight: 400;
          line-height: 38px; /* 146.154% */
          color: var(--red-pantone-1797-c, #cb333b);
        }
      }
      .bottom-left-C {
        margin-bottom: 20px;
        p {
          text-align: right;
          font-size: 26px;
          font-weight: 400;
          line-height: 38px; /* 146.154% */
          color: var(--LP-mid-gray, #6b6c6f);
        }
      }
      .bottom-left-flex {
        margin-left: 30px;
        width: 145px;
        div {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          gap: 10px;

          span {
            color: var(--LP-gray, #9ea0a5);
            text-align: right;
            font-size: 14px;
            font-weight: 500;
            line-height: 18px; /* 128.571% */
            text-transform: capitalize;
          }
        }
      }
      .bottom-left-D {
        display: flex;
        align-self: center;
        gap: 20px;
        margin-left: 25px;
        width: 200px;
        div {
          p {
            color: var(--LP-mid-gray, #6b6c6f);
            text-align: right;
            font-size: 18px;
            font-weight: 400;
            line-height: 38px; /* 211.111% */
          }
          span {
            color: var(--LP-gray, #9ea0a5);
            text-align: right;
            font-size: 12px;
            font-weight: 500;
            line-height: 18px; /* 150% */
            text-transform: capitalize;
          }
          div {
            display: flex;
            align-items: center;
            gap: 8px;
          }
        }
      }
    }
  }
}
