.communication_parent_container {
  .input_box {
    box-shadow: 0px 0px 1px 0px rgb(0 0 0 / 24%) inset;
    width: 885px;
    padding: 0px 20px 0px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
    height: 65px;

    input {
      height: 60px;
      border: none;
      outline: none;
      width: 70%;

      &::placeholder {
        color: #9ea0a5;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 157.143% */
      }
    }
    .more_actions {
        display: flex;
        align-items: center;
        gap: 13px;
    }
  }

  .communication_container {
    display: flex;
    flex-direction: column;
    gap: 25px;
    .user_message {
      display: flex;
      flex-direction: column;
      gap: 10px;
      .info {
        display: flex;
        align-items: center;
        gap: 10px;

        p {
          color: #3e3f42;
          font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px; /* 157.143% */
        }
        span {
          color: #9ea0a5;
          font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px; /* 150% */
        }
      }
      .msg_text_parent {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .msg_text {
          border-radius: 0px 10px 10px 10px;
          background: linear-gradient(90deg, #6b5de7 0%, #915cf4 101.5%);
          width: 465.113px;
          height: 124px;
          flex-shrink: 0;
          padding: 10px 20px 10px 20px;
          p {
            color: #fff;
            font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px; /* 157.143% */
          }
        }
      }
    }
  }
}
