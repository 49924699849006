.ai_support {
  border-radius: 4px;
  background: #ffffff;
  width: 380px;
  position: absolute;
  right: 0;
  top: 73px;
  z-index: 111;
  box-shadow: -1px 0px 0px 0px #eaedf3;
  height: calc(100vh - 73px);
  overflow-y: auto;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    height: 66px;
    border-bottom: 1px solid #eaedf3;

    .header_right {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-top: 10px;
      margin-left: 12px;
    }
  }

  .ai_nav {
    margin-top: 22px;

    .asset-nav {
      border-bottom: none !important;
      max-width: 200px;
      height: 40px !important;

      span {
        color: #6b6c6f;
        font-family: Roboto;
        font-size: 14px !important;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
      }
    }
  }

  .text {
    color: var(--LP-mid-gray, #6b6c6f);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }

  .insights {
    padding: 30px;
  }

  .recommendations {
    padding: 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .btn_wrapper {
      display: flex;

      button {
        width: 113px;
        height: 38px;
        color: #3e3f42;
        text-align: center;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        outline: none;
        border: 1px solid #d8dce6;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4px;
        background-color: #ffffff;
        border-radius: 4px;
      }
      .btn_active {
        color: #ffffff;
        border-radius: 4px 0px 0px 4px;
        border: 1px solid var(--purple-pantone-2665-c, #7d55c7);
        background: var(--purple-pantone-2665-c-71, rgba(125, 85, 199, 0.71));
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.08);
      }
    }
  }

  .support {
    .support_upper {
      height: 180px;
      background: var(--purple-pantone-2665-c-71, rgba(125, 85, 199, 0.71));
      padding: 30px;
      display: flex;
      flex-direction: column;
      gap: 20px;

      h2 {
        color: var(--ffffff-white, #fff);
        font-family: Roboto;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        text-transform: capitalize;
        display: flex;
        align-items: center;
        gap: 12px;

        span {
          font-size: 24px;
        }
      }

      span {
        color: var(--LP-very-light, #eaedf3);
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
    }
  }

  .question_box_wrapper {
    margin-top: -38px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
  }

  .question_box {
    padding: 20px;
    width: 320px;
    height: 144px;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0px 8px 15px 0px rgba(0, 0, 0, 0.2), 0px -3px 0px 0px #7d55c7 inset;

    label {
      color: #9ea0a5;
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
      text-transform: uppercase;
    }

    .question_input_wrapper {
      border-radius: 4px;
      border: 1px solid #e2e5ed;
      background: #fff;
      box-shadow: 0px 1px 2px 0px rgba(102, 113, 123, 0.21) inset;
      width: 280px;
      height: 38px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: 10px;
      margin: 8px 0;

      input {
        outline: none;
        border: none;
      }
    }

    span {
      color: #9ea0a5;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
    }
  }

  .find_answers {
    .header {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 40px;
      padding: 20px 30px;

      h3 {
        color: var(--LP-mid-gray, #6b6c6f);
        text-align: center;
        font-family: Roboto;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
    }

    .find_answers_box {
      width: 320px;
      margin: auto;
      border-radius: 4px;
      background: #fff;
      box-shadow: 0px 8px 15px 0px rgba(0, 0, 0, 0.2), 0px -3px 0px 0px #7d55c7 inset;

      .find_answers_box_upper {
        background: var(--purple-pantone-2665-c-71, rgba(125, 85, 199, 0.71));
        padding: 20px;
        height: 135px;
      }

      .find_question_box {
        width: 320px;
        height: 144px;
        border-radius: 4px;

        label {
          color: var(--ffffff-white, #fff);
          font-family: Roboto;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 18px;
          text-transform: uppercase;
        }

        .question_input_wrapper {
          border-radius: 4px;
          border: 1px solid #e2e5ed;
          background: #fff;
          box-shadow: 0px 1px 2px 0px rgba(102, 113, 123, 0.21) inset;
          width: 280px;
          height: 38px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-left: 10px;
          margin: 8px 0;

          input {
            outline: none;
            border: none;
          }

          img {
            margin: 2px 0 0 29px;
          }
        }

        span {
          color: var(--LP-very-light, #eaedf3);
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
        }
      }

      .btn_wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 20px;

        button {
          width: 165px;
          padding: 8px 11px;
          border-radius: 4px;
          border: 1px solid #d8dce6;
          background: linear-gradient(0deg, #f6f7f9 0%, #fff 100%);
          box-shadow: 0px 1px 1px 0px rgba(22, 29, 37, 0.05), 0px 2px 0px 0px rgba(255, 255, 255, 0.05) inset;
        }
      }
    }
  }

  .results {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    padding: 16px;

    p {
      color: var(--LP-gray, #9ea0a5);
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }

    .answer_box {
      border-bottom: 1px solid #eaedf3;
      padding-bottom: 12px;

      p {
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        color: #1665d8;
      }

      span {
        color: var(--LP-mid-gray, #6b6c6f);
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
    }
  }

  .help_center {
    .header {
      background: var(--purple-pantone-2665-c-71, rgba(125, 85, 199, 0.71));
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 80px;

      h3 {
        color: var(--ffffff-white, #fff);
        text-align: center;
        font-family: Roboto;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        text-transform: capitalize;
      }
    }

    .help_center_box {
      padding: 30px;

      h2 {
        color: var(--LP-black, #3e3f42);
        font-family: Roboto;
        font-size: 36px;
        font-style: normal;
        font-weight: 300;
        line-height: 24px;
      }

      p {
        padding: 12px 0;
      }
    }
  }

  .pagination {
    display: flex;
    align-items: center;
    gap: 20px;
    border-top: 1px solid #eaedf3;
    padding: 20px 0;
    margin-top: 20px;

    .btns_wrapper {
      display: flex;
      align-items: center;
      gap: 12px;
    }

    .btns {
      display: flex;
      align-items: center;
      background: #ffffff00;
      border-radius: 4px;
      border: 1px solid #eaedf3;
      height: 38px;

      div {
        border-right: 1px solid #eaedf3;
        height: 38px;
        padding: 9px;
      }
    }
  }

  .aibox {
    .header {
      background: var(--purple-pantone-2665-c-71, rgba(125, 85, 199, 0.71));
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 80px;

      h3 {
        color: var(--ffffff-white, #fff);
        text-align: center;
        font-family: Roboto;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        text-transform: capitalize;
      }
    }
  }

  .accepted_box {
    border-radius: 4px;
    border: 1px solid #eaedf3;
    background: #fff;

    .accepted_box_lower {
      padding: 20px;
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 12px;

      h3 {
        color: #000;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
      }

      .header_left {
        display: flex;
        align-items: center;
        gap: 20px;
      }
    }

    p {
      color: #3e3f42;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
    }
    .reactions {
      display: flex;
      align-items: center;
      gap: 12px;
      div {
        padding: 10px;
        border: 1px solid #eaedf3;
        border-radius: 50%;
      }
    }
    .inputs {
      .inputBoxMain input {
        height: 60px !important;
      }
      .btns {
        display: flex;
        justify-content: space-between;
        align-items: center;
        span {
          color: #9ea0a5;
          font-family: Roboto;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
        }
        button {
          background: linear-gradient(#f6f7f9, #ffffff);
          border: 1px solid #d8dce6;
          border-radius: 4px;
          padding: 8px 12px;
          color: #3e3f42;
          text-align: center;
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
        }
      }
    }
  }
}
