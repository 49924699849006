.opportunitiesSerach-container {
  font-family: Roboto;
  display: grid;
  grid-template-columns: 28% 1fr;
  // height: 100vh;

  .sidebar {
    border-right: 1px solid #ccc;

    .sidebar-header {
      position: sticky;
      top: 76px;
      background-color: #fbfbfd;
      z-index: 10;
      display: flex;
      align-items: center;
      text-decoration: none;
      gap: 20px;
      border-bottom: 1px solid #eaedf3;
      padding: 0 20px;
      height: 73px;
      justify-content: space-between;

      img {
        height: 48px;
        width: 48px;
      }

      .sideheader-textWrapper {
        display: flex;
        flex-direction: column;
        row-gap: 5px;

        h2 {
          font-size: 16px;
          color: #3e3f42;
          font-weight: 500;

          @media screen and (max-width: 1200px) {
            font-size: 13px !important;
          }
        }

        h4 {
          text-transform: uppercase;
          font-weight: 500;
          font-size: 12px;
          color: #959595;
        }
      }

      &:focus,
      &:active {
        h2 {
          color: #7757c0;
        }

        background-color: #f8f6fb;
        border-left: 4px solid #7757c0;
      }
    }

    .search-input-wrapper {
      height: 60px;
      padding: 6px 10px;
      border-bottom: 1px solid #eaedf3;
      display: flex;
      flex-direction: row;
      align-items: center;
      column-gap: 20px;
      justify-content: space-around;

      input {
        outline: none;
        border: none;
        height: 38px;
        width: 300px;
      }

      ::placeholder {
        font-family: Roboto;
        font-weight: 400;
        font-size: 14px;
        color: #9ea0a5;
      }

      .search-icons {
        display: flex;
        column-gap: 20px;

        img {
          width: 20px;
          height: 20px;
          cursor: pointer;
        }
      }
    }

    .sidebar-content {
      position: sticky;
      top: 142px;
      overflow-y: auto;
      max-height: calc(100vh - 142px);
      padding-bottom: 20px;
      display: flex;
      flex-direction: column;

      .nav-card {
        display: flex;
        flex-direction: row;
        // justify-content: space-evenly;
        column-gap: 20px;
        padding: 20px 25px;
        border-bottom: 1px solid #eaedf3;

        @media screen and (max-width: 1100px) {
          flex-direction: column;
        }

        img {
          width: 100px;
          height: 94px;
        }

        .nav-card-info {
          height: 94px;
          font-family: Roboto;
          display: flex;
          flex-direction: column;
          // justify-content: space-around;
          row-gap: 5px;
          width: 100%;

          h2 {
            font-size: 16px;
            font-weight: 500;
            color: #3e3f42;
          }

          p {
            font-size: 12px;
            color: #9ea0a5;
            line-height: 16px;
          }

          h4 {
            font-weight: 400;
            font-size: 12px;
            color: #3e3f42;
          }

          .price-wrapper {
            display: flex;
            flex-direction: row;
            // justify-content: space-between;
            gap: 5px;
            align-items: center;

            .status-wrapper {
              display: flex;
              align-items: center;
              column-gap: 6px;

              p {
                width: 6px;
                height: 6px;
                border-radius: 100%;
              }

              h4 {
                font-size: 14px;
                font-weight: 400;
                color: #3e3f42;
              }
              span {
                border-radius: 16px;
                border: 1px solid #d4d4d4;
                padding: 0px 12px;
                color: var(--LP-black-_lib, #3e3f42);
                text-align: center;
                font-family: Roboto;
                font-size: 12px;
                font-style: normal;
                font-weight: 700;
                line-height: 18px; /* 150% */
              }
            }

            h2 {
              font-size: 18px;
              font-weight: 500;
              color: #3e3f42;
            }
          }
          .tags_wrapper {
            display: flex;
            gap: 5px;
            p {
              display: flex;
              padding: 0px 8px;
              align-items: center;
              gap: 4px;
              border-radius: 100px;
              opacity: 0.8;
              background: var(--LP-very-light-_lib, #eaedf3);
              color: var(--LP-black-_lib, #3e3f42);
              text-align: center;
              font-family: Roboto;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 18px; /* 150% */
            }
          }
        }

        &:focus,
        &:active {
          h2 {
            color: #7757c0;
          }

          background-color: #f8f6fb;
          border-left: 4px solid #7757c0;
        }

        &.selected {
          h2 {
            color: #7757c0;
          }

          background-color: #f8f6fb;
          border-left: 4px solid #7757c0;
        }
      }
    }
  }

  .main-container {
    .main-header {
      position: sticky;
      top: 76px;
      background-color: #fbfbfd;
      z-index: 10;
      display: flex;
      // justify-content: flex-end;
      align-items: center;
      border-bottom: 1px solid #eaedf3;
      padding: 0 20px;
      height: 73px;
    }

    .main-content {
      overflow-y: auto;

      .dashboard-container {
        background-color: #fbfbfd;
        padding: 20px;
        display: flex;
        flex-wrap: wrap;
        gap: 20px;

        .card {
          width: calc(33.33% - 20px);
          padding: 10px;
          box-sizing: border-box;

          .card img {
            width: 100%;
            height: auto;
          }

          .card-info {
            margin-top: 10px;
            font-family: Roboto;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            row-gap: 10px;

            h2 {
              font-size: 16px;
              font-weight: 500;
              color: #3e3f42;
            }

            p {
              font-size: 14px;
              color: #9ea0a5;
              line-height: 20px;
            }

            .price-wrapper {
              display: flex;
              justify-content: space-between;

              h2 {
                color: #3e3f42;
                font-size: 18px;
              }

              p {
                font-size: 14px;
              }
            }

            .asset-link-btn {
              // width: 100%;
              font-size: 14px;
            }
          }
        }
      }

      .property-details-container {
        height: 100vh;

        .details-header-container {
          height: 48px;
          background: #fff;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 30px;

          .details-header-left {
            display: flex;
            align-items: center;
            gap: 20px;

            Link {
              align-items: center;
            }

            a {
              color: inherit;
              text-decoration: none;
              display: flex;
              flex-direction: row;
              align-items: center;
              column-gap: 10px;

              img {
                width: 20px;
                height: 20px;
              }

              span {
                font-family: Roboto;
                font-weight: 500;
                font-size: 12px;
                color: #6b6c6f;
                text-transform: uppercase;
              }

              &.active {
                color: #3e3f42;

                span {
                  color: #3e3f42;
                }
              }
            }

            a:not(:first-child) {
              border-left: 1px solid #eaedf3;
              /* Adjust the color and size as needed */
              padding-left: 10px;
              height: 40px;
            }
          }

          .details-header-right {
            display: flex;
            align-items: center;
            gap: 20px;

            h2 {
              color: #9ea0a5;
              font-family: Roboto;
              font-weight: 500;
              font-size: 12px;
            }

            .status-input-wrapper {
              display: flex;
              flex-direction: row;
              align-items: center;
              gap: 10px;

              p {
                width: 10px;
                height: 10px;
                border-radius: 100%;
              }
            }
          }
        }

        .property-details {
          display: grid;
          grid-template-columns: 65% 1fr;
          padding: 0px 2rem;
          column-gap: 2rem;

          .property-main-container {
            img {
              height: 430px;
              width: 100%;
              object-fit: cover;
              border-radius: 5px;
            }

            .temp {
              height: 430px;
              width: 100%;
              display: flex;
              justify-content: center;
            }

            .property-tabs {
              margin-top: 14px;
              display: flex;
              flex-direction: row;
              column-gap: 20px;
              border-bottom: 1px solid #eaedf3;

              a {
                font-family: Roboto;
                color: #6b6c6f;
                font-weight: 400;
                font-size: 12px;
                padding-bottom: 14px;

                &.active {
                  border-bottom: 2px solid #7d55c7;
                  transition: all 0.3;
                }
              }
            }

            .tab-content {
              padding: 30px 0px;

              .property-details-card {
                display: grid;
                grid-template-columns: 65% 1fr;
                border: 1px solid #eaedf3;
                border-radius: 5px;
                padding: 30px;
                width: 100%;
                .detail-card-left-container {
                  display: flex;
                  flex-direction: column;
                  justify-content: space-evenly;
                  row-gap: 14px;

                  h4 {
                    text-transform: uppercase;
                    font-family: Roboto;
                    font-weight: 500;
                    font-size: 12px;
                    color: #9ea0a5;
                    line-height: 18px;
                  }

                  h1 {
                    font-family: Roboto;
                    font-weight: 400;
                    font-size: 28px;
                    color: #3e3f42;
                    line-height: 38px;
                  }

                  h2 {
                    font-family: Roboto;
                    font-weight: 400;
                    font-size: 14px;
                    color: #9ea0a5;
                    line-height: 22px;
                  }

                  p {
                    font-family: Roboto;
                    font-weight: 400;
                    font-size: 14px;
                    color: #6b6c6f;
                    max-width: 85%;
                    line-height: 22px;
                  }
                }

                .detail-card-right-container {
                  display: flex;
                  flex-direction: column;
                  // justify-content: space-evenly;
                  row-gap: 14px;

                  h4 {
                    display: flex;
                    flex-direction: row;
                    column-gap: 5px;
                    align-items: center;
                    text-transform: uppercase;
                    font-family: Roboto;
                    font-weight: 500;
                    font-size: 12px;
                    color: #9ea0a5;
                    line-height: 18px;

                    .info-icon {
                      width: 17px;
                      height: 17px;
                      margin-bottom: -2px;
                    }
                  }

                  h1 {
                    display: flex;
                    flex-direction: row;
                    column-gap: 10px;
                    align-items: last baseline;
                    font-family: Roboto;
                    font-weight: 500;
                    font-size: 20px;
                    color: #3e3f42;
                    line-height: 38px;

                    span {
                      font-family: Roboto;
                      font-size: 14px;
                      line-height: 18px;
                      font-weight: 400;
                      color: #6b6c6f;
                    }
                  }

                  h2 {
                    display: flex;
                    flex-direction: row;
                    column-gap: 5px;
                    align-items: center;
                    text-transform: uppercase;
                    font-family: Roboto;
                    font-weight: 500;
                    font-size: 12px;
                    color: #9ea0a5;
                    line-height: 18px;

                    .info-icon {
                      width: 17px;
                      height: 17px;
                      margin-bottom: -2px;
                    }
                  }

                  .economic-pricing-text {
                    // align-items: center;
                    span {
                      display: flex;
                      column-gap: 5px;
                      align-items: baseline;
                    }

                    p {
                      color: #009639;
                      font-size: 14px;
                      font-weight: 400;
                    }

                    img {
                      width: 20px;
                      height: 20px;
                      margin-bottom: -10px;
                    }
                  }
                }
              }
            }
          }

          .property-features-container {
            .property-features {
              border: 1px solid #eaedf3;
              border-radius: 5px;
              padding: 35px 30px;
              display: flex;
              flex-direction: column;
              row-gap: 25px;
              box-shadow: 5px 5px 9px rgba(0, 0, 0, 0);
              -webkit-box-shadow: 5px 5px 9px rgba(0, 0, 0, 0);
              -moz-box-shadow: 5px 5px 9px rgba(0, 0, 0, 0);
            }

            h2 {
              text-transform: uppercase;
              font-family: Roboto;
              font-weight: 500;
              font-size: 12px;
              color: #9ea0a5;
            }

            .details-wrapper {
              display: flex;
              flex-direction: column;
              row-gap: 15px;

              .detail-item {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                .item-type {
                  display: flex;
                  align-items: center;
                  column-gap: 8px;

                  img {
                    width: 20px;
                    height: 20px;
                  }

                  h4 {
                    font-size: 14px;
                    font-weight: 400;
                    color: #6b6c6f;
                  }
                }

                p {
                  font-weight: 400;
                  font-size: 14px;
                  color: #3e3f42;
                }
              }
            }

            .line {
              background-color: #eaedf3;
              height: 0px;
              border: solid 1px #eaedf3;
              width: 100%;
            }
          }
        }

        //-----------------------------------------------------

        .overview-container {
          fill: #fff;
          stroke-width: 1px;
          stroke: #eaedf3;
          filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.04));
          flex-shrink: 0;

          .overview_heading {
            width: 825px;
            height: 74px;
            flex-shrink: 0;
            border: 1px solid rgba(255, 255, 255, 0);

            h2 {
              color: #3e3f42;

              font-family: Roboto;
              font-size: 18px;
              font-style: normal;
              font-weight: 500;
              line-height: 28px; /* 155.556% */
              color: red;
            }
          }

          .overview-header {
            font-family: Roboto;
            color: #3e3f42;
            font-weight: 500;
            font-size: 18px;
            line-height: 28px;
          }

          .overview-tabs {
            margin-top: 14px;
            display: flex;
            flex-direction: row;
            column-gap: 20px;
            border-bottom: 1px solid #eaedf3;

            a {
              font-family: Roboto;
              color: #6b6c6f;
              font-weight: 400;
              font-size: 12px;
              padding-bottom: 14px;

              &.active {
                border-bottom: 2px solid #7d55c7;
                transition: all 0.3;
              }
            }
          }

          .overview-tab-content {
            padding: 30px 0px;

            .financial-kpi-cards {
              display: grid;
              grid-template-columns: 24% 24% 24% 24%;
              grid-gap: 20px;
            }
          }
        }

        .market-tab-container {
          padding: 0px 2rem;

          .market-header {
            font-family: Roboto;
            color: #3e3f42;
            font-weight: 500;
            font-size: 18px;
            line-height: 28px;
          }

          .market-tabs {
            margin-top: 14px;
            display: flex;
            flex-direction: row;
            column-gap: 20px;
            border-bottom: 1px solid #eaedf3;

            a {
              font-family: Roboto;
              color: #6b6c6f;
              font-weight: 400;
              font-size: 12px;
              padding-bottom: 14px;

              &.active {
                border-bottom: 2px solid #7d55c7;
                transition: all 0.3;
              }
            }
          }

          .market-tab-content {
            padding: 30px 0px;
          }
        }
      }
      .investorDetails_wrapper {
        padding: 20px;
        .profile_container {
          display: flex;
          gap: 20px;
          align-items: flex-start;
          // .profile_container-L {
          //   img {
          //     scale: 1.4;
          //     margin-top: 10px;
          //   }
          // }
          @media screen and (max-width: 900px) {
            flex-direction: column !important;
          }
          .profile_container-R {
            .top {
              display: flex;
              flex-direction: row;
              // justify-content: space-evenly;
              column-gap: 20px;
              margin-bottom: 20px;

              img {
                width: 100px;
                height: 94px;
              }

              .nav-card-info {
                height: 60px;
                font-family: Roboto;
                display: flex;
                flex-direction: column;
                // justify-content: space-around;
                row-gap: 5px;
                width: 100%;

                h2 {
                  font-size: 18px;
                  font-weight: 700;
                  color: #3e3f42;
                }

                p {
                  font-size: 14px;
                  color: #9ea0a5;
                  line-height: 16px;
                  font-weight: 400;
                }

                h4 {
                  font-weight: 400;
                  font-size: 12px;
                  color: #3e3f42;
                }

                .price-wrapper {
                  display: flex;
                  flex-direction: row;
                  // justify-content: space-between;
                  gap: 8px;
                  align-items: center;

                  .status-wrapper {
                    display: flex;
                    align-items: center;
                    column-gap: 10px;
                    color: var(--LP-mid-grey-_lib, #6b6c6f);
                    text-align: center;
                    font-family: Roboto;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 100%; /* 12px */

                    p {
                      width: 6px;
                      height: 6px;
                      border-radius: 100%;
                    }

                    h4 {
                      font-size: 14px;
                      font-weight: 400;
                      color: #3e3f42;
                    }
                    span {
                      border-radius: 16px;
                      border: 1px solid #d4d4d4;
                      padding: 0px 12px;
                      color: var(--LP-black-_lib, #3e3f42);
                      text-align: center;
                      font-family: Roboto;
                      font-size: 12px;
                      font-style: normal;
                      font-weight: 500;
                      line-height: 18px; /* 150% */

                      color: #6b6c6f;
                    }
                  }

                  h2 {
                    font-size: 18px;
                    font-weight: 500;
                    color: #3e3f42;
                  }
                }
                .tags_wrapper {
                  display: flex;
                  gap: 5px;
                  p {
                    display: flex;
                    padding: 0px 8px;
                    align-items: center;
                    gap: 4px;
                    border-radius: 100px;
                    opacity: 0.8;
                    background: var(--LP-very-light-_lib, #eaedf3);
                    color: var(--LP-black-_lib, #3e3f42);
                    text-align: center;
                    font-family: Roboto;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 18px; /* 150% */
                  }
                }
              }

              &:focus,
              &:active {
                h2 {
                  color: #7757c0;
                }

                background-color: #f8f6fb;
                border-left: 4px solid #7757c0;
              }

              &.selected {
                h2 {
                  color: #7757c0;
                }

                background-color: #f8f6fb;
                border-left: 4px solid #7757c0;
              }
            }
            .bottom {
              display: inline-flex;
              align-items: flex-start;
              gap: 10px;

              .btn_open_profile {
                color: #fff;
                text-align: center;
                font-family: Roboto;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px; /* 157.143% */
                display: flex;
                padding: 8px 20px;
                align-items: center;
                gap: 12px;
                border-radius: 4px;
                border: 1px solid var(--purple-pantone-2665-c-71, rgba(125, 85, 199, 0.71));
                background: linear-gradient(0deg, rgba(125, 85, 199, 0.3) 0%, rgba(255, 255, 255, 0) 107.78%), #7d55c7;
                background-blend-mode: multiply, normal;
                box-shadow: 0px 1px 1px 0px rgba(19, 31, 21, 0.1), 0px 2px 0px 0px rgba(255, 255, 255, 0.06) inset;
              }
              .btn_follow {
                display: flex;
                width: 100px;
                padding: 8px 20px;
                justify-content: center;
                align-items: center;
                gap: 6px;
                border-radius: 4px;
                border: 1px solid #d8dce6;
                background: linear-gradient(0deg, #f6f7f9 0%, #fff 100%);
                box-shadow: 0px 1px 1px 0px rgba(22, 29, 37, 0.05), 0px 2px 0px 0px rgba(255, 255, 255, 0.05) inset;
                color: #3e3f42;
                text-align: center;
                font-family: Roboto;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px; /* 157.143% */
              }
              .btn_chat {
                display: flex;
                width: 87px;
                height: 38px;
                padding: 8px 20px;
                justify-content: center;
                align-items: center;
                gap: 11px;
                border-radius: 4px;
                border: 1px solid #d8dce6;
                background: linear-gradient(0deg, #f6f7f9 0%, #fff 100%);
                box-shadow: 0px 1px 1px 0px rgba(22, 29, 37, 0.05), 0px 2px 0px 0px rgba(255, 255, 255, 0.05) inset;
                color: #3e3f42;
                text-align: center;
                font-family: Roboto;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px; /* 157.143% */
              }
            }
          }
        }
        .navigationTabs_container {
          .property-tabs {
            margin-top: 30px;
            display: flex;
            flex-direction: row;
            column-gap: 20px;
            border-bottom: 1px solid #eaedf3;

            a {
              font-family: Roboto;
              color: #6b6c6f;
              font-weight: 400;
              font-size: 14px;
              padding-bottom: 14px;

              &.active {
                border-bottom: 2px solid #7d55c7;
                transition: all 0.3;
              }
            }
          }
        }
        .details_container {
          display: flex;
          flex-direction: column;
          gap: 40px;
          margin-top: 40px;
        }
      }
    }
  }
}

.financial-kpi-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px 10px;
  border: 1px solid #eaedf3;
  row-gap: 5px;
  position: relative;
  box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.1);

  h2 {
    font-family: Roboto;
    color: #9ea0a5;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 10px;
  }

  p {
    font-family: Roboto;
    color: #3e3f42;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
  }

  h1 {
    font-family: Roboto;
    color: #3e3f42;
    font-weight: 500;
    font-size: 26px;
    line-height: 38px;
  }

  .benchmark {
    display: flex;
    column-gap: 10px;
    align-items: center;

    .info-icon {
      width: 20px;
      height: 20px;
    }

    p {
      font-family: Roboto;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #34aa44;
    }
  }

  .rental-graph {
    width: 100%;
    height: 120px;
    object-fit: fill;
  }

  .kpi-owner-details-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .kpi-owner-details {
      padding-top: 10px;
      display: flex;
      flex-direction: column;
      row-gap: 8px;

      .owner-status-wrapper {
        display: flex;
        align-items: center;
        column-gap: 6px;
        padding-left: 5px;

        p {
          width: 6px;
          height: 6px;
          border-radius: 100%;
        }

        h4 {
          font-family: Roboto;
          font-size: 12px;
          font-weight: 500;
          color: #9ea0a5;
          text-transform: uppercase;
        }
      }
    }

    .owner-info-icon {
      width: 20px;
      height: 20px;
      fill: #9ea0a5;

      &.active {
        fill: #7d55c7b5;
      }
    }
  }

  .finance-card-info {
    position: absolute;
    top: 20px;
    right: 10px;
  }
}

.operating-income-cards {
  display: grid;
  grid-template-columns: 60% 0.8fr;
  grid-gap: 40px;

  @media screen and (max-width: 1200px) {
    grid-gap: 10px !important;
  }

  @media screen and (max-width: 1000px) {
    display: flex;
    flex-direction: column;
    gap: 20px !important;
  }

  .income-expense-left-grid {
    flex: 1;
    padding: 0px 20px;
    row-gap: 40px;
    justify-content: center;

    @media screen and (max-width: 900px) {
      padding: 0px !important;
    }

    .asset-benchmark-set-cards {
      margin-top: 20px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      column-gap: 20px;

      @media screen and (max-width: 900px) {
        width: 100% !important;
        column-gap: 15px !important;
      }

      .asset-benchmark-income-card {
        border: 1px solid #eaecee;
        border-radius: 5px;
        width: 50%;

        h1 {
          font-family: Roboto;
          font-size: 18px;
          font-weight: 500;
          color: #3e3f42;
          line-height: 28px;
          padding: 30px 25px;
          @media screen and (max-width: 900px) {
            font-size: 14px !important;
          }
        }

        .line {
          background-color: #eaecee;
          height: 0px;
          border: solid 1px #eaecee;
          width: 100%;
        }

        .asset-card-table {
          color: #9ea0a5;
          display: flex;
          justify-content: space-around;
          padding: 10px 20px;

          @media screen and (max-width: 1000px) {
            padding: 10px 0px;
          }

          p {
            font-family: Roboto;
            font-size: 12px;
            font-weight: 500;
            color: #9ea0a5;
            line-height: 18px;
            width: 20px;
            text-align: right;
          }

          &.content {
            padding: 30px 20px;

            p {
              color: #3e3f42;
            }
          }
        }
      }
    }
  }

  .income-expense-right-grid {
    padding: 0x 20px !important;
    display: flex;
    flex: 1;
    flex-direction: column;
    row-gap: 20px;
    width: 100%;
    border: 1px solid #eaedf3;
    border-radius: 2px;
    border-left: 3px solid #34aa44;
    justify-content: center;
    @media screen and (max-width: 1000px) {
      border: 1px solid #eaedf3 !important;
    }

    .difference-wrapper {
      display: flex;
      justify-content: space-between;

      h2 {
        font-family: Roboto;
        font-size: 12px;
        line-height: 18px;
        font-weight: 500;
        color: #9ea0a5;
        text-transform: uppercase;
      }
    }

    .asset-benchmark {
      display: flex;
      flex-direction: column;
      row-gap: 10px;

      h2 {
        font-family: Roboto;
        font-size: 14px;
        line-height: 22px;
        font-weight: 500;
        color: #6b6c6f;
      }

      h1 {
        font-family: Roboto;
        font-size: 26px;
        line-height: 38px;
        font-weight: 500;
        color: #3e3f42;
      }
    }

    .progress-status {
      display: flex;
      justify-content: space-between;
      width: 100%;

      p {
        font-family: Roboto;
        font-size: 12px;
        font-weight: 400;
        color: #3e3f42;
        width: 20px;
      }
    }
  }
}

.market-analysis-card {
  h1 {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    color: #3e3f42;
  }

  .market-analysis-grids {
    display: grid;
    grid-template-columns: 60% 1.5fr 0.6fr;
    grid-gap: 20px;
    margin-bottom: 40px;

    @media screen and (max-width: 1000px) {
      grid-template-columns: 50% 0.5fr 0.4fr;
      grid-gap: 30px;
    }

    .market-analysis-grid-1 {
      height: 250px;
      width: 100%;
      // object-fit: contain;
      display: flex;
      flex: 1;
    }

    .market-analysis-grid-2 {
      display: flex;
      justify-content: center;
      height: 250px;
      width: 100%;
      column-gap: 10px;

      img {
        height: 100%;
        object-fit: contain;
      }

      .graph-labels {
        padding-top: 10px;
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        justify-content: end;

        .label {
          display: flex;
          align-items: center;
          column-gap: 6px;
          padding-left: 5px;

          p {
            width: 6px;
            height: 6px;
            border-radius: 100%;
          }

          h4 {
            font-family: Roboto;
            font-size: 12px;
            font-weight: 500;
            color: #9ea0a5;
            text-transform: uppercase;
          }
        }
      }
    }

    .market-analysis-grid-3 {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 230px;
      column-gap: 10px;
      align-items: end;
      padding-top: 20px;

      p {
        font-family: Roboto;
        font-size: 12px;
        font-weight: 500;
        color: #fff;
        padding: 5px 8px;
        background-color: #8ec14b;
        width: 38px;
        border-radius: 4px;
      }

      h1 {
        font-family: Roboto;
        font-size: 38px;
        font-weight: 300;
        line-height: 60px;
        color: #3e3f42;
      }

      h6 {
        font-family: Roboto;
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        color: #9ea0a5;
      }

      h2 {
        font-family: Roboto;
        font-size: 22px;
        font-weight: 400;
        line-height: 32px;
        color: #3e3f42;
      }
    }
  }
}

.utilities-card {
  h2 {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    color: #3e3f42;
    margin-bottom: 15px;
  }

  h1 {
    font-family: Roboto;
    font-size: 26px;
    line-height: 38px;
    font-weight: 400;
    color: #3e3f42;

    @media screen and (max-width: 900px) {
      font-size: 20px !important;
    }
  }

  .unit-details-grid {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
  }

  .unit-details {
    padding: 20px 0px;

    .unit-details-building-label {
      display: flex;
      align-items: center;
      column-gap: 6px;

      p {
        width: 10px;
        height: 10px;
        border-radius: 2px;
      }

      h4 {
        font-family: Roboto;
        font-size: 12px;
        font-weight: 500;
        color: #9ea0a5;
        text-transform: uppercase;
      }
    }
  }

  .unit-details:not(:first-child) {
    padding-left: 20px;
    border-left: 1px solid #eaedf3;
  }

  .unit-graph {
    margin: 20px 0px;
  }

  .consuming-value-grid {
    display: grid;
    grid-template-columns: 55% 1.8fr;
    grid-gap: 30px;
    margin-top: 20px;
    align-items: center;

    @media screen and (max-width: 900px) {
      grid-template-columns: 50% 0.8fr;
    }

    .consuming-value-header {
      h2 {
        margin-bottom: 20px;
      }
    }

    .consuming-value-content {
      display: grid;
      grid-template-columns: 32.5% 60%;
      grid-gap: 20px;
      align-items: center;

      @media screen and (max-width: 900px) {
        grid-gap: 15px !important;
      }
      .value-cost-header {
        flex: 1;

        .value-content-cost {
          display: flex;
          align-items: center;
          column-gap: 6px;
          padding-left: 5px;
          width: fit-content;
          p {
            width: 10px;
            height: 10px;
            border-radius: 2px;
          }

          h4 {
            font-family: Roboto;
            font-size: 12px;
            font-weight: 500;
            color: #9ea0a5;
            text-transform: uppercase;
          }
        }
      }

      .value-budget-indicator {
        display: flex;
        flex: 1;
        flex-direction: row;
        column-gap: 10px;
        align-items: center;

        h4 {
          font-family: Roboto;
          font-size: 14px;
          line-height: 22px;
          font-weight: 500;
          width: 90px;
        }

        img {
          width: 20px;
          height: 20px;
        }

        p {
          font-family: Roboto;
          font-size: 12px;
          font-weight: 500;
          line-height: 22px;
          padding: 2px 0px;
          height: 24px;
          width: 38px;
          text-align: center;
          color: white;
          border-radius: 2px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}

.maintenance-card {
  display: grid;
  grid-template-columns: 60% 1fr;
  column-gap: 50px;
  row-gap: 40px;
  align-items: center;

  @media screen and (max-width: 1000px) {
    column-gap: 10px;
  }

  h4 {
    font-family: Roboto;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    color: #9ea0a5;
    text-transform: uppercase;
  }

  .maintenance-bar-graph {
    flex: 1;

    img {
      height: 454px;
    }
  }

  .maintenance-pie-chart {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    row-gap: 30px;

    .pie-chart-wrapper {
      display: block;
      text-align: center;

      img {
        width: 250px;
        height: 250px;

        @media screen and (max-width: 1000px) {
          width: 200px;
          height: 200px;
        }
      }
    }

    .pie-chart-details {
      display: grid;
      grid-template-columns: 20% 10px 1fr;
      grid-gap: 10px;
      width: fit-content;
      align-items: center;

      h4 {
        font-family: Roboto;
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
        color: #3e3f42;
        padding-right: 5px;
      }

      p {
        width: 10px;
        height: 10px;
        border-radius: 2px;
      }

      h6 {
        font-family: Roboto;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        color: #3e3f42;
        width: 110px;
      }
    }
  }
}

.news-summaries {
}

.pros-and-cons {
  display: grid;
  grid-template-columns: 45% 1fr;
  grid-gap: 40px;

  @media screen and (max-width: 1200px) {
    display: flex !important;
    flex-direction: column !important;

    img {
      width: 100% !important;
    }
  }

  .map-wrapper {
    img {
      border-radius: 5px;

      @media screen and (max-width: 1200px) {
        width: 40% !important;
      }
    }
  }

  .recommendations-container {
    @media screen and (max-width: 900px) {
      padding: 0px !important;
    }

    .recommendations-header {
      margin: 20px 0px;
      margin-top: 0;
      display: flex;
      flex-direction: rows;
      align-items: center;
      justify-content: space-between;

      h2 {
        font-family: Roboto;
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
        color: #3e3f42;

        @media screen and (max-width: 1000px) {
          font-size: 16px;
        }
      }
    }

    .recommendations-content {
      display: flex;
      flex-direction: column;
      row-gap: 20px;
    }
  }
}

.line {
  background-color: #eaedf3;
  height: 0px;
  border: solid 1px #eaedf3;
  width: 100%;
}

.pagination {
  .page-number {
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 5px 8px;
    margin: 0px 5px;
    color: #6b6c6f;

    &.active {
      color: #3e3f42;
      background-color: #ebedf2;
      border-radius: 2px;
    }
  }

  .pagination-button {
    color: #9ea0a4;
    background: transparent;
    border: none;
    cursor: pointer;

    &:hover {
      color: #7757c0;
    }
  }
}

.overview-header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media only screen and (max-width: 900px) {
    padding-left: 40px;
  }

  .overview-header-left {
    // margin-top: 14px;
    display: flex;
    flex-direction: row;
    column-gap: 40px;
    align-items: center;
    height: 100%;

    a {
      font-family: Roboto;
      color: #6b6c6f;
      font-weight: 400;
      font-size: 16px;
      padding: 13% 0%;
      // height: 100%;

      &.active {
        border-bottom: 3px solid #7d55c7;
        transition: all 0.3;
        font-weight: 500;
        color: #3e3f42;
      }
    }
  }

  .overview-header-right {
    display: flex;
    column-gap: 10px;

    .filter-button {
      display: flex;
      column-gap: 5px;
      padding: 5px 20px;
      background-color: #fff;
      align-items: center;
      border: 1px solid #e2e5ed;
      border-radius: 5px;

      img {
        width: 20px;
        height: 20px;
      }

      h4 {
        font-family: Roboto;
        color: #3e3f42;
        font-weight: 400;
        font-size: 14px;
      }
    }
  }
}

.asset-header-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 100%;
  column-gap: 5px;

  img {
    width: 153px;
    height: 38px;
    object-fit: contain;

    @media screen and (max-width: 1000px) {
      width: 100px;
      height: 30px;
    }
  }

  h6 {
    font-family: Roboto;
    color: #9ea0a5;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-transform: uppercase;
    margin-right: 10px;

    @media screen and (max-width: 900px) {
      font-size: 10px !important;
    }
  }

  .invite-button {
    display: flex;
    column-gap: 5px;
    padding: 10px 15px;
    background-image: linear-gradient(to top, #f7f8fa, #fefefe);
    align-items: center;
    border: 1px solid #e2e5ed;
    border-radius: 5px;
    column-gap: 8px;

    @media screen and (max-width: 1000px) {
      column-gap: 5px !important;
      padding: 7px 10px !important;
    }

    img {
      width: 22px;
      height: 22px;
    }

    h4 {
      font-family: Roboto;
      color: #3e3f42;
      font-weight: 400;
      font-size: 14px;

      @media screen and (max-width: 1000px) {
        font-size: 12px !important;
      }
      @media screen and (max-width: 900px) {
        font-size: 10px !important;
      }
    }
  }

  .vertical-line {
    // background-color: #eaedf3;
    height: 40px;
    border-left: solid 1px #eaedf3;
    width: 0px;
    margin: 0px 10px;
  }
}

.showAi {
  position: absolute;
  bottom: 77%;
  right: 366px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  z-index: 1111111111;
  transition: 0.3s ease;

  p {
    display: none;
  }
}

.ai_support_btn {
  position: fixed;
  bottom: 0;
  right: 12px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;

  img {
    width: 48px;
    height: 48px;
  }

  p {
    color: #6b6c6f;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }
}

.invitebox_modal {
  position: absolute;
  right: 60px;
}

.invitebox_wrapper {
  padding: 20px;
  width: 530px;
  height: 354px;
  border-radius: 4px;
  border: 1px solid #eaedf3;
  background: #fff;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.04);
  display: flex;
  flex-direction: column;
  gap: 20px;

  .invitebox_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    h2 {
      color: #9ea0a5;
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
      /* 150% */
      text-transform: uppercase;
    }
  }

  .invitebox_profile {
    display: flex;
    align-items: center;
    gap: 20px;

    img {
      height: 60px;
      width: 60px;
    }

    p {
      color: #3e3f42;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      /* 171.429% */
    }

    span {
      color: #9ea0a5;
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      /* 183.333% */
    }
  }

  .invitebox_people {
    display: flex;
    align-items: center;
    gap: 20px;
    padding-bottom: 16px;
    border-bottom: 1px solid #eaedf3;

    .people {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-left: 0 !important;

      img {
        height: 38px;
        width: 38px;
      }
    }

    .text {
      color: var(--666666-mid-grey, #666);
      font-family: Roboto;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
    }
  }

  .add_people {
    > div {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    span {
      color: #9ea0a5;
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      /* 150% */
    }

    button {
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 16px;
      height: 38px;
      width: 66px;
      align-items: center;
      gap: 12px;
      border-radius: 4px;
      border: 1px solid var(--purple-pantone-2665-c-71, rgba(125, 85, 199, 0.71));
      background: linear-gradient(0deg, rgba(125, 85, 199, 0.3) 0%, rgba(255, 255, 255, 0) 107.78%), #7d55c7;
      background-blend-mode: multiply, normal;
      box-shadow: 0px 1px 1px 0px rgba(19, 31, 21, 0.1), 0px 2px 0px 0px rgba(255, 255, 255, 0.06) inset;
    }
  }
}
.social_network_wrapper {
  display: flex;
  align-items: flex-start;
  gap: 40px;

  @media screen and (max-width: 1000px) {
    justify-content: space-between;
    gap: 20px;
  }
  .filter_results {
    h2 {
      color: #3e3f42;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      margin-bottom: 16px;
    }

    .show {
      color: #9ea0a5;
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
      text-transform: uppercase;
    }

    .checkbox_wrapper {
      display: flex;
      flex-direction: column;
      gap: 6px;
      margin-top: 10px;

      p {
        color: #9ea0a5;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        margin-left: 30px;
      }
    }

    .checkbox {
      padding-left: 0px !important;
    }
  }
}

.social_networks {
  h1 {
    padding: 20px 0;
    color: #3e3f42;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    /* 150% */
  }
}

.twitter_wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media screen and (max-width: 1000px) {
    gap: 35px !important;
  }
}

.twitter_post {
  width: 658px;
  height: 235px;
  border-radius: 4px;
  border: 1px solid #e2e5ed;
  background: #fff;
  display: flex;
  flex-direction: column;
  gap: 14px;
  height: max-content;

  @media screen and (max-width: 1200px) {
    width: 485px;
    padding-bottom: 2px;
  }
  @media screen and (max-width: 1000px) {
    width: 404px !important;
  }
  @media screen and (max-width: 900px) {
    width: 308px !important;
  }

  .twitter_profile {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 0 20px;
    margin-top: 20px;

    img {
      height: 38px;
      width: 38px;
    }

    p {
      color: #3e3f42;
      font-family: Roboto;
      font-size: 19px;
      font-style: normal;
      font-weight: 700;
      line-height: 22px;
      display: flex;
      align-items: center;
      gap: 4px;

      @media screen and (max-width: 1000px) {
        font-size: 16px;
      }
    }

    span {
      color: var(--LP-gray, #9ea0a5);
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      /* 157.143% */
    }
  }

  .tweet {
    padding: 0 20px;

    .text {
      color: #3e3f42;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      /* 157.143% */

      @media screen and (max-width: 1000px) {
        font-size: 12px;
      }
    }
  }

  .tweet_details {
    padding: 0 20px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
    width: 100%;

    > div {
      display: flex;
      align-items: center;
      gap: 20px;
    }

    span {
      color: var(--LP-gray, #9ea0a5);
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }

  .tweet_lower {
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 38px;
    border-top: 1px solid #eaedf3;

    > div {
      display: flex;
      align-items: center;
      gap: 12px;

      p {
        color: var(--3-fa-2-f-7-links-informational-blue, #3fa2f7);
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
      }
    }
  }
}

.asset_reviews_wrapper {
  .asset_reviews {
    width: 658px;

    @media screen and (max-width: 1000px) {
      width: 400px;
    }
    @media screen and (max-width: 1200px) {
      width: 500px;
    }
    @media screen and (max-width: 950px) {
      width: 350px;
    }
  }
  .ratings {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
    padding: 20px;
    border-bottom: 1px solid #eaedf3;
    border-top: 1px solid #eaedf3;

    p {
      color: #3e3f42;
      font-family: Roboto;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
    }

    .rating_text {
      margin-left: 10px;
      color: #f9c125;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px; /* 157.143% */
    }
    .ratings_row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 20px;

      div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
      }

      span {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .review_wrapper {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 40px;

    .review_text {
      padding-bottom: 20px;

      .text2 {
        color: #6b6c6f;
        font-family: Roboto;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
      }
    }
  }

  .review_box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    border-bottom: 1px solid #eaedf3;
  }

  .review_box_upper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    width: 100%;

    .review_box_left {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;

      .text1 {
        color: #9ea0a5;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
      }

      .name {
        color: #3e3f42;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
      }

      img {
        height: 38px;
        width: 38px;
        border-radius: 100%;
      }
    }

    .review_box_right_wrapper {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;

      span {
        color: #9ea0a5;
        text-align: right;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        /* 157.143% */
      }
    }

    .review_box_right {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.apply_header {
  display: flex;
  width: 100%;
  height: 70px;
  gap: 15px;
  flex-wrap: wrap;
  align-items: center;
  flex-shrink: 0;
  padding: 10px;
  height: max-content;
  /* 3dp/Light */
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.1);

  .search {
    display: flex;

    align-items: center;
    gap: 39px;
    border-radius: 4px;
    border: 1px solid var(--LP-very-light-_lib, #eaedf3);
    background: #fff;
    box-shadow: 0px 1px 2px 0px rgba(102, 113, 123, 0.21) inset;

    input {
      width: 240px;
      padding: 8px 16px;
      border: none;
    }
  }
  .select {
    display: flex;

    justify-content: center;
    align-items: center;
    border-radius: 4px;
    border: 1px solid #d8dce6;
    background: #fff;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.08);

    select {
      width: 184px;
      height: 38px;
      padding: 0px 1px;
      border: none;
    }
  }
  .location {
    display: flex;
    width: 190px;
    padding: 4px 16px;
    align-items: center;
    gap: 39px;
    border-radius: 4px;
    border: 1px solid var(--LP-very-light-_lib, #eaedf3);
    background: #fff;
    box-shadow: 0px 1px 2px 0px rgba(102, 113, 123, 0.21) inset;
  }
  .all_types {
    display: flex;

    justify-content: center;
    align-items: center;
    border-radius: 4px;
    border: 1px solid #d8dce6;
    background: #fff;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.08);
    select {
      width: 162px;
      height: 38px;
      padding: 0px 1px;
      border: none;
    }
    p {
      margin: 0;
      padding: 0;
      font-weight: normal;
      color: #9ea0a5;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      padding-left: 10px;
    }
  }
  .date_added {
    display: flex;

    justify-content: center;
    gap: 4px;
    padding-left: 5px;
    align-items: center;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.08);
    border: 1px solid #d8dce6;

    select {
      width: 150px;
      height: 38px;
      border: none;
    }
  }
  .match {
    display: flex;
    width: 160px;
    height: 38px;
    padding: 0px 1px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    border: 1px solid #d8dce6;
    background: #fff;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.08);
  }
  .apply_btn {
    button {
      display: flex;
      padding: 8px 20px;
      justify-content: flex-end;
      align-items: center;
      gap: 12px;
      border-radius: 4px;
      border: 1px solid var(--purple-pantone-2665-c-71, rgba(125, 85, 199, 0.71));
      background: linear-gradient(0deg, rgba(125, 85, 199, 0.3) 0%, rgba(255, 255, 255, 0) 107.78%), #7d55c7;
      background-blend-mode: multiply, normal;
      box-shadow: 0px 1px 1px 0px rgba(19, 31, 21, 0.1), 0px 2px 0px 0px rgba(255, 255, 255, 0.06) inset;
      color: #fff;

      text-align: center;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 157.143% */
    }
  }
}

.details_container {
  .overview_container {
    width: 825px;
    height: 400px;
    flex-shrink: 0;
    fill: #fff;
    stroke-width: 1px;
    stroke: #eaedf3;
    padding: 10px;

    .overview_without_contact {
      fill: #fff;
      stroke-width: 1px;
      stroke: #eaedf3;
      border: 1px solid rgba(0, 0, 0, 0.04);
    }
    .overview_heading {
      // width: 825px;
      height: 74px;
      flex-shrink: 0;
      padding: 10px;
      border-bottom: 1px solid #eaedf3;
      display: flex;
      align-items: center;

      h1 {
        color: #3e3f42;

        font-family: Roboto;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px; /* 155.556% */
      }
    }

    .bio_container {
      .bio_heading {
        h1 {
          color: #9ea0a5;

          font-family: Roboto;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 18px; /* 150% */
          text-transform: uppercase;
          padding: 10px;
        }
      }
      .bio_info {
        color: #6b6c6f;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 157.143% */
        width: 764px;
        padding: 10px;
        margin-top: 20px;
      }
      .tags {
        margin-top: 20px;
        padding: 10px;
        h1 {
          color: #9ea0a5;

          font-family: Roboto;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 18px; /* 150% */
          text-transform: uppercase;
          padding: 10px;
        }
      }

      .tags_names {
        margin-top: 10px;
        padding: 10px;
        p {
          display: flex;
          padding: 3px 10px;
          align-items: center;
          gap: 10px;
          color: #fff;
          text-align: center;
          font-family: Roboto;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 18px; /* 150% */
          text-transform: uppercase;
          border-radius: 4px;
        }
      }
    }
    .hr_line {
      width: 765px;
      height: 1px;
      flex-shrink: 0;
      background-color: #eaedf3;
      margin: 30px;
    }
    .total_profit {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      padding: 10px;

      div {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 10px;

        h1 {
          color: var(--LP-black, #3e3f42);

          /* h3/Regular 24px */
          font-family: Roboto;
          font-size: 24px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }

    .contact_info {
      // width: 825px;
      height: 395px;
      margin-top: 50px;
      border: 1px solid #eaedf3;
      padding: 10px;

      .contact_info_heading {
        // width: 825px;
        height: 74px;
        flex-shrink: 0;
        border-bottom: 1px solid #eaedf3;
        padding: 10px;
        display: flex;
        align-items: center;

        h1 {
          color: #3e3f42;
          font-family: Roboto;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 28px; /* 155.556% */
        }
      }
      .contact_info_details {
        div {
          p {
            color: #3e3f42;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px; /* 157.143% */
          }
        }
        .contact_type {
          display: flex;
          justify-content: space-between;
          width: 825px;
          height: 74px;
          flex-shrink: 0;

          div {
            display: flex;
            width: 375px;
            padding-top: 2px;
            gap: 21px;
            padding-top: 10px;
            padding-bottom: 10px;
            // border-bottom: 1px solid #eaedf3;

            span {
              color: #9ea0a5;

              font-family: Roboto;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 18px; /* 150% */
            }
          }
        }
      }
    }

    .career {
      margin-top: 20px;
      padding: 10px;
      border: 1px solid #eaedf3;
      // width: 825px;

      .career_heading {
        // width: 825px;
        height: 60px;
        flex-shrink: 0;
        padding: 10px;
        border-bottom: 1px solid #eaedf3;

        h1 {
          color: #3e3f42;
          font-family: Roboto;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 28px;
        }
      }
      .career_info {
        display: flex;
        flex-direction: column;
        gap: 20px;
        .career_item_1 {
          display: flex;
          gap: 20px;
          background: rgba(255, 255, 255, 0);
          box-shadow: 0px -1px 0px 0px #eaedf3 inset;
          padding-top: 20px;
          padding-bottom: 20px;
          .invest_info {
            display: flex;
            flex-direction: column;

            p {
              color: #3e3f42;
              font-family: Roboto;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 24px; /* 150% */
            }
            b {
              color: #3e3f42;
              font-family: Roboto;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 22px; /* 157.143% */
            }
            span {
              color: #9ea0a5;
              font-family: Roboto;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 18px; /* 150% */
            }
          }
        }
        .career_item_2 {
          display: flex;
          gap: 20px;
          background: rgba(255, 255, 255, 0);
          padding-bottom: 20px;
          .invest_info {
            display: flex;
            flex-direction: column;

            p {
              color: #3e3f42;
              font-family: Roboto;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 24px; /* 150% */
            }
            span {
              color: #3e3f42;
              font-family: Roboto;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 22px; /* 157.143% */
            }

            b {
              color: #9ea0a5;
              font-family: Roboto;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 18px; /* 150% */
            }
          }
        }
      }
    }
    .education {
      margin-top: 20px;
      padding: 10px;
      border: 1px solid #eaedf3;

      .education_heading {
        width: 825px;
        height: 74px;
        flex-shrink: 0;
        padding: 10px;
        border-bottom: 1px solid #eaedf3;

        h1 {
          color: #3e3f42;
          font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px; /* 150% */
        }
      }

      .education_info {
        display: flex;
        flex-direction: column;

        p {
          color: #3e3f42;
          font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px; /* 150% */
        }
        b {
          color: #3e3f42;
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px; /* 157.143% */
        }
        span {
          color: #9ea0a5;
          font-family: Roboto;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px; /* 150% */
        }
        .education_item_1 {
          display: flex;
          gap: 20px;
          //  padding-top: 20px;
          padding-bottom: 20px;
          box-shadow: 0px -1px 0px 0px #eaedf3 inset;
        }
      }
    }
    .licenses {
      margin-top: 20px;
      padding: 10px;

      .licenses_item {
        display: flex;
        align-items: center;
        gap: 20px;
        padding-top: 20px;
        padding-bottom: 20px;

        .licenses_desc {
          display: flex;
          flex-direction: column;

          p {
            color: #3e3f42;
            font-family: Roboto;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px; /* 150% */
          }
          span {
            color: #9ea0a5;
            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px; /* 150% */
          }
        }
      }

      .licenses_heading {
      }
    }
    .Investment {
      margin-top: 30px;
      // width: 825px;
      height: 457px;
      flex-shrink: 0;
      fill: #fff;
      stroke-width: 1px;
      stroke: #eaedf3;
      border: 1px solid #eaedf3;
      padding-left: 10px;
      padding-bottom: 10px;

      .investment_heading {
        // width: 825px;
        height: 74px;
        flex-shrink: 0;
        fill: rgba(255, 255, 255, 0);
        border-bottom: 1px solid #eaedf3;
        padding: 10px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        h1 {
          color: #3e3f42;
          font-family: Roboto;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 28px; /* 155.556% */
        }
        span {
          color: var(--LP-gray, #9ea0a5);
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px; /* 128.571% */
        }
      }

      .deal_match {
        display: flex;
        align-items: center;
        gap: 60px;
        padding-top: 20px;
        padding-left: 10px;
        .deal_item_1 {
          display: flex;
          align-items: center;
          gap: 18px;
          .total_deals {
            width: 34px;
            height: 34px;
            border-radius: 4px;
            background: var(--LP-gray, #9ea0a5);
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            text-align: center;
            font-family: Roboto;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px; /* 150% */
          }
          p {
            color: #6b6c6f;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px; /* 157.143% */
          }
        }
        .deal_item_2 {
          display: flex;
          align-items: center;
          gap: 18px;
        }
      }
      .property_holdPeriod {
        margin-top: 30px;
        padding: 10px;
        .property_type {
          width: 367px;
          flex-shrink: 0;

          h2 {
            margin-bottom: 10px;
            color: var(--LP-black, #3e3f42);
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px; /* 157.143% */
          }

          div {
            p {
              color: #3e3f42;
              font-family: Roboto;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 22px; /* 157.143% */
            }
          }
        }
        .hold_period {
          width: 367px;

          h2 {
            color: #3e3f42;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px; /* 157.143% */
            margin-bottom: 7px;
          }
          div {
            p {
              color: #3e3f42;
              font-family: Roboto;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 22px; /* 157.143% */
            }
          }
        }
      }

      .size_regions {
        margin-top: 20px;
        padding: 10px;

        h2 {
          color: var(--LP-black, #3e3f42);
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px; /* 157.143% */
        }

        .size__ {
          width: 367px;

          p {
            color: #3e3f42;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px; /* 157.143% */
          }
          h2 {
            margin-bottom: 7px;
          }
        }
        .regions {
          width: 367px;

          p {
            color: #3e3f42;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px; /* 157.143% */
          }
          h2 {
            margin-bottom: 7px;
          }
        }
      }
    }

    .investment_experience {
      width: 825px;
      height: 634px;
      flex-shrink: 0;
      padding: 10px;
      // border: 1px solid #EAEDF3;
      .investment_experience_heading {
        width: 786px;
        height: 74px;
        flex-shrink: 0;
        padding: 10px;
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        h1 {
          color: #3e3f42;
          font-family: Roboto;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 28px; /* 155.556% */
        }
      }
      .investment_experience_details {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        .investment_experience_item {
          display: flex;
          height: 240px;
          padding: 20px;
          flex-direction: column;
          align-items: center;
          gap: 10px;
          flex: 1 0 0;

          p {
            color: #000;

            text-align: center;
            font-family: Roboto;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 28px; /* 155.556% */
          }
          span {
            color: #6b6c6f;
            text-align: center;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px; /* 157.143% */
          }
        }
      }
    }

    .recommended_syndicates {
      .syndicats_list {
        margin-top: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 30px;
        padding: 10px;
      }
      .heading {
        display: flex;
        align-items: center;
        gap: 20px;
        padding: 10px;
        h1 {
          color: #3e3f42;

          /* h4/Regular 20px */
          font-family: Roboto;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
        span {
          color: var(--LP-mid-grey-_lib, #6b6c6f);
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px; /* 157.143% */
        }
      }
      .show_btn {
        display: flex;
        width: 825px;
        padding: 8px 11px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 4px;
        border: 1px solid #d8dce6;
        background: linear-gradient(0deg, #f6f7f9 0%, #fff 100%);
        box-shadow: 0px 1px 1px 0px rgba(22, 29, 37, 0.05), 0px 2px 0px 0px rgba(255, 255, 255, 0.05) inset;
        margin: auto;
        margin-top: 60px;
      }
      .commercial_list {
        display: flex;
        align-items: flex-start;
        gap: 50px;
      }

      .single_commercial {
        width: 255px;

        flex-shrink: 0;
        border-radius: 4px;
        border: 1px solid var(--LP-very-light, #eaedf3);
        background: #fff;
        box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.15);
        padding: 6px;
        padding-bottom: 0px;
      }

      .commercial_slider_btns {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 0px 0px 4px 4px;
        opacity: 0.5;
        background: var(--LP-black, #3e3f42);
        padding: 5px;
        bottom: 5px;
        width: 100%;
        z-index: 199999;
        color: white;

        p {
          color: #fff;
          font-family: Roboto;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        span {
        }
      }
      .single_commercial {
        span {
          border-radius: 4px 0px;
          background: var(--LP-very-light-_lib, #eaedf3);
          box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.1);
          display: inline-flex;
          padding: 5px 10px;
          align-items: center;
          gap: 10px;
          position: absolute;
          top: 0px;
          left: 0;
          font-size: 12px;
          color: var(--LP-mid-grey-_lib, #6b6c6f);
          text-align: center;
          font-family: Roboto;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
        .heading_location {
          display: flex;
          flex-direction: column;
          padding: 10px;
          gap: 10px;

          h2 {
            font-size: 14px;
            font-weight: 500;
            font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
          }
          p {
            color: #9ea0a5;

            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px; /* 133.333% */
          }
        }
        .offer_text {
          color: var(--LP-mid-grey-_lib, #6b6c6f);
          font-family: Roboto;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px; /* 150% */
          padding-left: 10px;
          margin-top: 10px;
        }
        .time_period {
          display: flex;
          align-items: center;
          justify-content: space-around;
          margin-top: 10px;

          div {
            p {
              color: var(--purple-pantone-2665-c, #7d55c7);
              text-align: center;
              font-family: Roboto;
              font-size: 24px;
              font-style: normal;
              font-weight: 300;
              line-height: 110%; /* 26.4px */
            }

            :nth-child(2) {
              color: var(--purple-pantone-2665-c-71, rgba(125, 85, 199, 0.71));
              text-align: center;
              font-family: Roboto;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 110%; /* 13.2px */
            }
          }
        }
        .LTV {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 10px;
          gap: 9px;

          div {
            display: flex;
            padding: 6px 7px;
            flex-direction: column;
            align-items: center;
            gap: 4px;
            flex: 1 0 0;
            border-radius: 4px;
            border: 0px solid var(--LP-very-light, #eaedf3);
            background: rgba(125, 85, 199, 0.15);

            p {
              color: var(--LP-mid-grey-_lib, #6b6c6f);
              text-align: center;
              font-family: Roboto;
              font-size: 10px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }

            h2 {
              color: var(--LP-black-_lib, #3e3f42);
              text-align: center;
              font-family: Roboto;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 18px; /* 128.571% */
            }
          }
        }

        .investments {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 10px;
          gap: 9px;

          div {
            display: flex;
            padding: 6px 2px;
            flex-direction: column;
            align-items: center;
            gap: 4px;
            flex: 1 0 0;
            align-self: stretch;
            border-radius: 4px;
            border: 1px solid var(--LP-very-light-_lib, #eaedf3);
            background: rgba(234, 237, 243, 0.5);

            h2 {
              color: var(--LP-black-_lib, #3e3f42);
              text-align: center;
              font-family: Roboto;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 18px; /* 128.571% */
            }

            p {
              color: var(--LP-mid-grey-_lib, #6b6c6f);
              text-align: center;
              font-family: Roboto;
              font-size: 10px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }
        }

        .raised {
          display: flex;
          align-items: center;
          justify-content: space-between;
          // margin: 10px;
          margin: 10px;
          margin-bottom: 0px;

          img {
            padding-left: 10px;
          }
        }
        .view_details {
          width: 100% !important;
          height: 38px;
          flex-shrink: 0;
          border-radius: 0px 0px 4px 4px;
          background: linear-gradient(0deg, rgba(125, 85, 199, 0.3) 0%, rgba(255, 255, 255, 0) 107.78%), #7d55c7;
          background-blend-mode: multiply, normal;
          border: none;
          margin-top: 15px;
          color: white;
          color: var(--ffffff-white, #fff);
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px; /* 157.143% */
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;
        }
      }
    }
  }

  //  contact details ----------
}
