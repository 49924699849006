.deals-room-table {
  width: calc(100% - 50px);
  margin: 0 auto;

  th {
    text-align: left;
    padding: 0 8px 8px 15px; // Padding at the bottom of the heading
    border-bottom: 1px solid #eaedf3; // Border at the bottom of the heading
    height: 43px;

    .table-header-text {
      color: #9ea0a5;
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
      text-transform: uppercase;
    }
  }

  .deals-room-doc-pdf {
    margin-right: 14px;

    > div {
      span:first-child {
        display: block;
      }

      span:last-child {
        color: #9ea0a5;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }

  .deals-room-doc-action {
    display: flex;
    align-items: center;
    gap: 14px;
  }

  .deals-room-doc-date {
    display: flex;
    flex-direction: column;

    div span:last-child {
      color: #9ea0a5;
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  .deals-room-doc-org {
    display: flex;
    align-items: center;
  }

  .deals-room-doc-org-name {
    margin-right: 14px;
    display: inline;
  }

  .deals-room-doc-tag {
    height: 70px;
    display: flex;
    align-items: center;
    margin-right: 30px;
  }

  .deals-room-doc-action,
  .action-btn {
    height: 70px;
  }

  .action-btn {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .even-row {
    background: rgba(125, 85, 199, 0.05);
  }

  tr {
    display: table;
    padding: 0 30px 0 20px;
    width: 730px;
  }

  .deals-room-doc-action-button {
    background: linear-gradient(0deg, rgba(125, 85, 199, 0.3) 0%, rgba(255, 255, 255, 0) 107.78%), #7d55c7;
    background-blend-mode: multiply, normal;
    box-shadow: 0px 1px 1px 0px rgba(19, 31, 21, 0.1), 0px 2px 0px 0px rgba(255, 255, 255, 0.06) inset;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .deals-room-doc-action-button img {
    width: 24px;
    height: 24px;
  }

  .deals-room-doc-action-button span {
    color: #ffffff;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    text-transform: uppercase;
  }

  .table-btn {
    width: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
  }
}

