.SyndicateOwnerAnalytics_main {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 20px;

  .SyndicateOwnerAnalytics_header {
    h3 {
      color: #3e3f42;
      font-family: Roboto;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 30px; /* 150% */
    }
  }

  //   Syndicates_Highilites

  .syndicates_highilites_Chart {
    border: 1px solid #eaedf3;
  }

  .syndicates_highilites {
    @media screen and (max-width: 1100px ) {
    max-width: calc(100vw - 240px);
    overflow: auto;
    }
    // margin: 20px;
  }

  .syndicates_highilites_header {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    border: 1px solid #eaedf3;

    .syndicates_highilites_calander_and_button {
      display: flex;
      gap: 20px;
      .syndicates_highilites_calander {
        display: flex;
        gap: 10px;
        border: 1px solid #eaedf3;
        padding: 10px;
        div {
          display: flex;
          align-items: center;
          gap: 5px;
          p {
            color: #3e3f42;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px; /* 157.143% */
            text-wrap: nowrap;
          }
        }
      }
      .all_syndicates {
        padding: 10px;
        width: 237px;
        height: 38px;
        flex-shrink: 0;
        color: #3e3f42;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 157.143% */
        border: 1px solid #eaedf3;
        height: 100%;
      }
    }
  }

  .syndicates_highilites_data {
    display: flex;
    width: 100%;
    padding: 20px;
    gap: 50px;
    border: 1px solid #eaedf3;

    .syndicates_highilites_data_one {
      display: flex;
      width: 100%;
      flex-direction: column;
      border-right: 1px solid #eaedf3;
      &:last-child {
        border-right: none;
      }
      p {
        color: #9ea0a5;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px; /* 150% */
        text-transform: uppercase;
      }
      h4 {
        color: var(--LP-black, #3e3f42);

        /* h3/Regular 24px */
        font-family: Roboto;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .syndicates_highilites_data_one_percentage {
        display: flex;
        gap: 0px;
        p {
          color: var(--Green-2, #27ae60);
          text-align: center;
          font-family: Roboto;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px; /* 150% */
          text-transform: uppercase;
        }
        .negative {
          color: #e6492d !important;
          text-align: center;
          font-family: Roboto;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px; /* 150% */
          text-transform: uppercase;
        }
      }
    }
  }

  // Table One
  .porfolio_performence_tableOne {
    border: 1px solid #eaedf3;
    // margin: 20px;

    .porfolio_performence_tableOne_status {
      background-color: #34aa44;

      padding: 5px 10px;
      border-radius: 5px;

      color: #fff !important;
      text-align: center !important;
      font-family: Roboto;
      font-size: 12px !important;
      font-style: normal;
      font-weight: 500;
      line-height: 18px; /* 150% */
      text-transform: uppercase;
    }

    h3 {
      color: #3e3f42;
      font-family: Roboto;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px; /* 155.556% */
      text-wrap: nowrap;
      border-bottom: 1px solid #eaedf3;
      padding: 20px 10px;
    }
    table {
      td,
      th {
        padding: 10px !important;
        border-bottom: 1px solid #eaedf3;
        padding: 20px 0px;
        text-align: left !important;
      }
      .syndicate-cell {
        display: flex;
        align-items: center;
        gap: 10px;
        text-align: left !important;
        p {
          color: #3e3f42;
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px; /* 157.143% */
        }
      }
      .posted-by-cell {
        display: flex;
        gap: 10px;
        .user-image {
          width: 50px;
          height: 50px;
          border-radius: 50%;
        }
        div {
          display: flex;
          flex-direction: column;
          p:first-child {
            color: #3e3f42;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px; /* 157.143% */
            text-align: left !important;
          }
          p {
            color: #9ea0a5;
            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px; /* 150% */
            text-align: left !important;
          }
        }
      }
      th {
        text-wrap: wrap !important;
      }
      .porfolio_performence_tableOne_Exit_Date {
        color: #7f8083 !important;
        font-family: Roboto;
        font-size: 12px !important;
        font-style: normal;
        font-weight: 500;
        line-height: 18px; /* 150% */
        text-transform: uppercase;
      }
    }
  }
  .porfolio_performence_tableOne_investment {
    display: flex !important;
    gap: 20px !important;
    width: 100% !important;
    .PortfolioPerformence_investment_inner {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      h3 {
        border: none !important;
        padding: 0px !important;
      }
      p {
        padding: 0px !important;
      }
    }
  }
}
