.chart_main_wrapper {
  display: flex;
  flex-direction: column;

  .chart_config {
    padding: 23px 29px 16px 29px;
    height: 107px;
    border-bottom: 1px solid #eaedf3;
    display: flex;
    flex-direction: column;
    gap: 10px;
    .top_box {
      display: grid;
      grid-template-columns: 47% 53%;
      align-items: center;
      .chart_action_btns {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        .cloud_download_btn {
          button {
            background: none;
            border: none;
            outline: none;
          }
        }

        .action_btn {
          display: flex;
          flex-direction: row;
          gap: 10px;
          button {
            width: 100%;
            min-width: 110px;
            height: 38px;
            border: 1px solid #e2e5ed;
            background: var(--ffffff-white, #fff);
            border-radius: 6px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 20px;
          }
        }

        .filter_switch {
          display: flex;
          height: 38px;
          width: 100%;
          max-width: 86px;
          padding: 0 4px;
          align-items: center;
          border-radius: 6px;
          border: 1px solid #e2e5ed;
          background: var(--ffffff-white, #fff);

          .internal_switch {
            display: flex;
            padding: 4px 14px;
            align-items: center;
            border-radius: 3px;
            height: 30px;
            cursor: pointer;
            &.active_filter_switch {
              border: 0px solid var(--purple-pantone-2665-c-71, rgba(125, 85, 199, 0.71));
              background: var(--LP-very-light, #eaedf3);
              box-shadow: 0px 2px 0px 0px rgba(255, 255, 255, 0.06) inset, 0px 1px 1px 0px rgba(19, 31, 21, 0.1);
            }
          }
        }
      }
    }

    .bottom_box {
      display: flex;
      flex-direction: row;
      gap: 10px;
      .legends {
        display: flex;
        padding: 0px 10px;
        align-items: center;
        gap: 10px;
        border-radius: 4px;
        border: 1px solid var(--LP-very-light, #eaedf3);
        background: var(--ffffff-white, #fff);
      }
    }
  }
}
