.projects {
    display: flex;
    gap: 30px;
    background: #FBFBFD;
    height: 100%;

     .length{
        color: #9EA0A5;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;     
     }
    .project_list_wrapper {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        gap: 20px;

        .project_box_wrapper {
            width: 350px;
            border-radius: 4px;
            border: 1px solid #EAEDF3;
            background: #FFF;
            box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.04);
        }

        .project_box {
            border-bottom: 1px solid #EAEDF3;
            padding: 25px 30px;

            .progress {
                width: 20px;
                height: 2px;
                border-radius: 1px;
                background: #34AA44;
                margin-bottom: 14px;
            }

            .project_box_lower {
                margin-top: 20px;
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            .lower_left {
                display: flex;
                align-items: center;
                gap: 12px;
            }

            .lower_right {
                display: flex;
                align-items: center;
                gap: 6px;

                span {
                    color: #9EA0A5;
                    font-family: Roboto;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 22px;
                }
            }
        }
    }

    .project_tab {
        padding: 20px;
        border-radius: 4px;
        border: 1px solid #EAEDF3;
        background: #FFF;
        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.04);
        width: 350px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;

        h3 {
            color: #3E3F42;
            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px;
            text-transform: uppercase;
        }

        >div {
            display: flex;
            align-items: center;
            gap: 12px;
            cursor:pointer;

        }
    }

    .project_sidebar {
        background-color: #ffffff;
        width: 270px;
        min-width: 270px;
        max-width: 270px;
        box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.15);

        .project_sidebar_header {
            display: flex;
            align-items: center;
            gap: 20px;
            background: rgba(226, 229, 237, 0.40);
            height: 66px;
            padding: 20px;
            cursor:pointer;

        }

        .project_sidebar_details {
            .task_sidebar_list {
                padding: 0 20px;
            }

            .project_sidebar_menu {
                padding: 20px 0;
                border-top: 1px solid #EAEDF3;
                cursor:pointer;

                .title {
                    padding: 0 20px;

                }

                .menu_list_wrapper {
                    margin-top: 12px;
                }

                .menu_list {
                    display: flex;
                    align-items: center;
                    gap: 20px;
                    height: 46px;
                    padding: 0 20px;

                }

                .menu_list.active {
                    background-color: #EAEDF3;
                    border-left: 2px solid #1665D8;
                }
            }

            .project_sidebar_performance {
                padding: 0 20px;

                h3 {
                    color: #3E3F42;
                    font-family: Roboto;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 22px;
                    margin-top: 20px;
                }

                p {
                    color: #9EA0A5;
                    font-family: Roboto;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 18px;
                }
            }

            .project_sidebar_activity {
                padding: 0 20px;

                .activity_list_wrapper {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    margin-top: 16px;
                }

                .activity_list {
                    display: flex;
                    align-items: flex-start;
                    gap: 16px;

                    img {
                        height: 28px;
                        width: 28px;
                        margin-top: 10px;
                    }

                    .text {
                        color: #3E3F42;
                        font-family: Roboto;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 22px;
                    }

                    span {
                        color: #9EA0A5;
                        font-family: Roboto;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 18px;
                    }
                }
            }

        }

        h3 {
            color: #3E3F42;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px;
        }
    }

    .project_main {
        padding: 30px 20px;
        width: 100%;

        .project_header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            ;

            .header_left {
                display: flex;
                align-items: center;
                gap: 40px;

                h2 {
                    color: #3E3F42;
                    font-family: Roboto;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 30px;
                }
            }

            .header_right {
                display: flex;
                align-items: center;
                gap: 20px;

                .btn {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 20px;
                    color: #9EA0A5;
                    font-family: Roboto;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 18px;
                    text-transform: uppercase;
                    padding-right: 20px;
                }

                .btn_list {
                    border-right: 1px solid #EAEDF3;
                    cursor:pointer;

                }

                .btn.active {
                    color: var(--purple-pantone-2665-c, #7D55C7);
                }

                button {
                    cursor:pointer;
                    color: #ffffff;
                    display: inline-flex;
                    padding: 8px 20px;
                    align-items: center;
                    justify-content: center;
                    gap: 12px;
                    border-radius: 4px;
                    border: 1px solid var(--purple-pantone-2665-c-71, rgba(125, 85, 199, 0.71));
                    background: linear-gradient(0deg, rgba(125, 85, 199, 0.30) 0%, rgba(255, 255, 255, 0.00) 107.78%), #7D55C7;
                    background-blend-mode: multiply, normal;
                    box-shadow: 0px 1px 1px 0px rgba(19, 31, 21, 0.10), 0px 2px 0px 0px rgba(255, 255, 255, 0.06) inset;
                    cursor:pointer;

                }
            }
        }
    }
}

.settings_popup {
    width: 176px;
    height: 112px;
    border-radius: 4px;
    border: 1px solid #EAEDF3;
    background: #FFF;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.04);
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 28px;
    position: absolute;
    z-index: 11;
    margin-left: 181px;
    p{
        color: #3E3F42;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; 
        display: flex;
        justify-content: center;
        align-items: center;   
        gap: 6px;
    }
}