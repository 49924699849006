a {
  text-decoration: none;
}
.signupMainCon {
  &.signupBroker {
    h2 {
      color: var(--LP-black, #6b6c6f);
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 157.143% */
    }
  }
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  //   background-color: red;
  //   padding: 0 68px;
  box-sizing: border-box;
  height: 100vh;
  overflow-y: hidden;
  h1 {
    color: var(--LP-black, #6b6c6f);
    font-family: Roboto;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 145.455% */
  }
  h2 {
    margin-top: 3px;
    // margin-bottom: 12px;
    color: var(--LP-mid-gray, #6b6c6f);
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
  }
  h3 {
    margin-top: 30px;
    color: var(--LP-black, #6b6c6f);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    display: flex;
    gap: 10px;
    line-height: 22px; /* 157.143% */
    span {
      color: var(--3-fa-2-f-7-links-informational-blue, #3fa2f7);
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
    }
  }
  .signupScroller {
    width: 100%;
    position: relative;
    top: 0vh;
    transition: all 0.3s ease;
    &.two {
      top: -115vh;
      margin-top: 50px;
    }
    &.three {
      top: -216vh;
      margin-top: 50px;
    }
    &.four {
      top: -300vh;
      margin-top: -80px;
      
    }
    &.five {
      top: -425vh;
      margin-top: 100px;
    }
    &.six {
      top: -528vh;
      margin-top: 91px;
    }
    &.seven {
      top: -628vh;
      margin-top: 60px;
    }
    &.eight {
      top: -700vh;
      margin-top: -70%;
    }
    &.nine {
      top: -804vh;
      margin-top: -80%;
    }
    &.ten {
      top: -910vh;
      margin-top: -70%;
    }
  }

  .signupCon-L {
    width: 40%;
    min-height: 100vh;
    padding: 0 98px;
    padding-left: 165px;
    background: var(--light-grad, linear-gradient(0deg, #f6f7f9 0%, #fff 100%));
    /* 6dp/Light */
    // background-color: #6A4D9F;
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.15);

    @media screen and (max-width: 1200px) {
      width: 100%;
      padding-left: 60px;
    }
 
    .signupsection {
      // margin-top: 20px;
      height: 100vh;
      position: relative;
      h2 {
        // margin-top: 20px;
        color: var(--LP-mid-gray, #6b6c6f);
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 157.143% */
      }
      .inputFlexLine{
        margin-top: 10px;
      }
    }

    .inputFlexLine-inputbox {
      margin-top: 30px;
    }
    .signupFirstSection{
      h1{
        margin-top: 10px;

      }
      h2{
        margin-top: 10px;
      }
    }

    .signupSecondSection {
      margin-top: 30%;
      // width: 123%;
      .checkBoxCon {
        display: flex;
        align-items: center;
        // margin-top: 19px;
        h2 {
          margin-left: 8px;
        }
        a {
          color: var(--LP-black, #6b6c6f);
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px; /* 157.143% */
          text-decoration: underline;
          padding-bottom: 4px;
          color: var(--LP-black, #6b6c6f);
        }
        input {
          position: relative;
          top: 3px;
          margin-right: 12px;
          cursor: pointer;
        }
      }
    }

    .signupFourthSection{

    }

    .signupFifthSection {
    }
    .signupThirdSection {
      h2 {
        color: var(--LP-mid-gray, #6b6c6f);
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 157.143% */
        svg {
          position: relative;
          top: 4px;
          left: 3px;
        }
      }
    }
    .signupSixthSection {
      overflow-y: scroll;
      // width: calc(100% + 100px);
      // padding-bottom: 20px;
  

      .entity_upload_buttons {
        margin: 40px 0px;
        display: flex;
        flex-direction: column;
        gap: 20px;
      }
      .inputFlexLine {
        margin-top: 10px;
        .dropDownOuterCon {
          width: 100%;
        }
        .inputBoxMain {
          width: 100%;
        }
      }

      .slider_container {
        position: relative;
        .top_div {
          // font-size: 12px;
          border-radius: 4px;
          border: 1px solid #e4e8ee;
          padding: 4px 10px;
          z-index: 2;
          p {
            font-size: 18px;
            text-align: center;
            margin: 0px;
          }
        }
        .MuiSlider-root {
          position: absolute;
          z-index: 1;
          top: 8px;
          .MuiSlider-rail {
            color: #b7b7b7 !important;
          }
          .MuiSlider-track,
          .MuiSlider-thumb {
            color: #7d55c7;
          }
          .MuiSlider-track {
            height: 2px;
          }
        }
      }
    }
    .signupsevenSection {
      overflow-y: scroll;
      // width: calc(100% + 100px);
      // padding-bottom: 20px;
     margin-top: 30px;
      .entity_upload_buttons {
        margin: 40px 0px;
        display: flex;
        flex-direction: column;
        gap: 20px;
      }
      .inputFlexLine {
        margin-top: 10px;
        .dropDownOuterCon {
          width: 100%;
        }
        .inputBoxMain {
          width: 100%;
        }
      }

      .slider_container {
        position: relative;
        .top_div {
          // font-size: 12px;
          border-radius: 4px;
          border: 1px solid #e4e8ee;
          padding: 4px 10px;
          z-index: 2;
          p {
            font-size: 18px;
            text-align: center;
            margin: 0px;
          }
        }
        .MuiSlider-root {
          position: absolute;
          z-index: 1;
          top: 8px;
          .MuiSlider-rail {
            color: #b7b7b7 !important;
          }
          .MuiSlider-track,
          .MuiSlider-thumb {
            color: #7d55c7;
          }
          .MuiSlider-track {
            height: 2px;
          }
        }
      }
    }
    .signupSixthSection::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }
    .signupEightSection {
      overflow-y: scroll;
      // width: calc(100% + 100px);
      // padding-bottom: 20px;
    //  margin-top: 40px;
      .entity_upload_buttons {
        margin: 40px 0px;
        display: flex;
        flex-direction: column;
        gap: 20px;
      }
      .inputFlexLine {
        margin-top: 10px;
        .dropDownOuterCon {
          width: 100%;
        }
        .inputBoxMain {
          width: 100%;
        }
      }

      .slider_container {
        position: relative;
        .top_div {
          // font-size: 12px;
          border-radius: 4px;
          border: 1px solid #e4e8ee;
          padding: 4px 10px;
          z-index: 2;
          p {
            font-size: 18px;
            text-align: center;
            margin: 0px;
          }
        }
        .MuiSlider-root {
          position: absolute;
          z-index: 1;
          top: 8px;
          .MuiSlider-rail {
            color: #b7b7b7 !important;
          }
          .MuiSlider-track,
          .MuiSlider-thumb {
            color: #7d55c7;
          }
          .MuiSlider-track {
            height: 2px;
          }
        }
      }
    }
    .signupnine {
      margin-top: 127px;
      overflow-y: scroll;
      padding-right: 50px;
      width: calc(100% + 100px);
      padding-bottom: 20px;
    }
    .signupten{
      margin-top: 230px;
      overflow-y: scroll;
      padding-right: 50px;
      width: calc(100% + 100px);
      padding-bottom: 20px;
    }
  }
  .signupCon-R {
    width: 60%;
    height: 100%;
    position: relative;
    pointer-events: none;
    .imgOneSignup {
      pointer-events: none;
      width: 160%;
      position: absolute;
      right: 0%;
      top: 30px;
      z-index: -1;
      max-width: none;
    }
    .imgemailConfirm {
      pointer-events: none;
      // width: 100%;
      position: absolute;
      // right: 0%;
      top: 30px;
      left: 30px;
      z-index: -1;
      max-width: none;
    }
    // min-height: 100vh;

    @media screen and (max-width: 1200px) {
      width: 50%;
    }
  }
}


.AccountSeclector_Individual {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  padding: 0 30px;
  width: 285px;
  height: 98px;
  border-radius: 4px;
  border: 2px solid #e2e5ed;
  margin-bottom: 20px;
  color: var(--LP-black, #6b6c6f);
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  cursor: pointer;
  position: relative;
  &.org {
    padding: 40px 30px;
    flex-direction: column;
    overflow-y: hidden;
    overflow-x: hidden;
  }
  &.org.active {
    padding: 40px 30px;
    flex-direction: column;
    height: 240px;
  }
  .AccountSeclector_Individual-T {
    display: flex;
    position: relative;
    top: -10px;
    width: 100%;
    gap: 20px;
    align-items: center;
  }
  .organisationExpand {
    width: 100%;
    margin-left: 20px;
  }
  &.active {
    border: 2px solid var(--purple-pantone-2665-c, #7d55c7);
    background: rgba(125, 85, 199, 0.1);
    color: var(--purple-pantone-2665-c, #7d55c7);
  }
  .accountTypeCheck {
    position: absolute;
    bottom: 10px;
    right: 10px;
  }
}
.acknowlegeCON {
  display: flex;
  // margin-top: 20px;
  // margin-bottom: 40px;

  svg {
    flex: 1;
    width: 40px;
    margin-top: 6px;
    // margin-right: 10px;
  }
  h2 {
    flex: 11;
  }
}
.linkedAccountCon {
  .linkAccountImgCon {
    margin-top: 20px;
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    // justify-content: space-between;
    gap: 20px;
    img {
      cursor: pointer;
      width: 120%;
    }
  }
}

.phoneInpCon {
  margin-top: 20px;
  position: relative;

  .PhoneInputCountry {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 16px;
  }
  input {
    display: flex;
    align-items: center;

    width: 100%;
    height: 38px;
    border-radius: 4px;
    border: 1px solid var(--LP-very-light--_lib, #eaedf3);
    background: #fff;
    outline: none;
    box-shadow: 0px 1px 2px 0px rgba(102, 113, 123, 0.21) inset;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
    padding-left: 60px;
  }
}

//successful signup
.SuccessfulSignup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h1 {
    margin-top: 50px;
    color: var(--purple-pantone-2665-c, #7d55c7);
    text-align: center;
    font-family: Roboto Slab;
    font-size: 40px;
    font-style: normal;
    font-weight: 300;
    line-height: 58px; /* 145% */
  }
  h2 {
    margin-top: 28px;
    color: var(--LP-black--_lib, #6b6c6f);
    text-align: center;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
  }
  svg {
    margin-top: 40px;
  }
  .successFulsignupBtn {
    cursor: pointer;
    margin-top: 40px;
    display: flex;
    width: 285px;
    height: 38px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    color: var(--ffffff-white, #fff);
    text-align: center;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
    border-radius: 4px;
    border: 1px solid #d8dce6;
    background: var(--purple-pantone-2665-c, #7d55c7);
    box-shadow: 0px 1px 1px 0px rgba(22, 29, 37, 0.05), 0px 2px 0px 0px rgba(255, 255, 255, 0.05) inset;
  }
}

//Components

.spacerMT4 {
  margin-top: 4px;
}
.smallwidth {
  width: 40%;
}

.spacerLine30 {
  border: 1px solid #eaedf3;
  margin: 30px 0;
  // max-width: 285px;
}
.spacerMT200 {
  margin-top: 200px;
}
.spacerMT390 {
  margin-top: 390px;
}
.spacerMTM30 {
  margin-top: -30px;
}
.radioButtonCon {
  display: flex;
  align-items: center;
  margin-top: 16px;
  color: var(--LP-black, #3e3f42);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
  &.accountselector {
    color: var(--purple-pantone-2665-c, #7d55c7);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
  .radioButtonO {
    cursor: pointer;
    margin-right: 12px;
    width: 17px;
    height: 17px;
    border-radius: 50%;
    border: 1px solid #d8dce5;
    display: flex;
    align-items: center;
    justify-content: center;
    .radioButtonI {
      width: 6px;
      height: 6px;
      background-color: #fff;
      border-radius: 50%;
    }
  }
  &.active {
    .radioButtonO {
      border: 1px solid #7d55c7;
      background-color: #7d55c7;
    }
  }
}

.tabSelector,
.tabselectorL {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;

  .tabSelT {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .tabline {
    width: 100%;
    height: 2px;
    background-color: #e2e5ed;
    position: relative;
    .tablineInner {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0px;
      width: 72px;
      height: 2px;
      background-color: #7d55c7;
      position: relative;
    }
  }
  .tabItem {
    width: 90px;
    color: var(--LP-black, #6b6c6f);
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 200% */
    text-transform: capitalize;
    margin-bottom: 5px;
    // background-color: red;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    padding-right: 10px;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  }
  &.manual {
    .tablineInner {
      left: 89px;
      width: 82px;
    }
  }

  .activeDiv {
    width: 100%;
    height: 2px;
    background-color: #7d55c7;
    position: relative;
    top: 4px;
    &.active {
      top: 0px;
    }
  }
}

.flexlineC {
  margin-top: 20%;
  display: flex;
  align-items: center;
  //   justify-content: flex-start;
  gap: 15px;
}

.searchBar {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: 20px;
  input {
    display: flex;
    align-items: center;

    width: 100%;
    height: 38px;
    border-radius: 4px;
    border: 1px solid var(--LP-very-light--_lib, #eaedf3);
    background: #fff;
    outline: none;
    box-shadow: 0px 1px 2px 0px rgba(102, 113, 123, 0.21) inset;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
    padding-left: 16px;
  }
  img {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.cardsContainerB {
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  margin-top: 50px;
  /* width: 90%; */
  width: 100%;
  justify-content: center;

  .card {
    width: 330px;
    display: flex;
    padding: 60px 30px 50px 30px;
    flex-direction: column;
    align-items: center;
    /* gap: 30px; */
    border-radius: 4px;
    border: 1px solid #e2e5ed;
    background: #fff;
    /* justify-content: center; */
    /* align-items: center; */
  }
  .top {
    .text_wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 225px;
      h2 {
        color: var(--LP-black--_lib, #3e3f42);
        text-align: center;
        font-family: Roboto;
        font-size: 24px;
        font-style: normal;
        font-weight: 300;
        line-height: 24px; /* 100% */
        margin-bottom: 20px;
      }
      p {
        color: var(--LP-black--_lib, #3e3f42);
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 157.143% */
      }
    }
  }
  .bottom {
    .row {
      display: flex;
      gap: 10px;
      margin: 10px 0px;
    }
    p {
      color: var(--LP-mid-grey-_lib, #6b6c6f);
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 157.143% */
    }
    span {
      color: var(--LP-black--_lib, #3e3f42);
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px; /* 157.143% */
    }
  }
}

.cardsContainer {
  width: 90%;
  margin: 0 auto;
}
.Individual_address_line_1 {
  input {
    width: 100%;
    height: 38px;
    border: 1px solid var(--LP-border, #e5e5e5);
    border-radius: 4px;
    padding: 8px 16px;
    outline: none;
    color: var(--LP-black, #3e3f42);
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
  }
  label {
    color: var(--LP-black, #3e3f42);
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    text-transform: uppercase;
    opacity: 0.5;
    margin-bottom: 7px;
  }
}
.bank_details,
.entity_input,
.joint_details,
.trus_details {
  input {
    width: 100%;
    height: 38px;
    border: 1px solid var(--LP-border, #e5e5e5);
    border-radius: 4px;
    padding: 8px 16px;
    outline: none;
    color: var(--LP-black, #3e3f42);
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
  label {
    color: var(--LP-black, #3e3f42);
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    text-transform: uppercase;
    opacity: 0.5;
    margin-bottom: 7px;
  }
}


.error-message {
  color: var(--eb-5757-error-negative-red, #EB5757);
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  margin-top: 5px;
  display: flex;
  align-items: center;
}


