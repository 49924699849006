.sponsors_main {
  display: flex;
  gap: 20px;
  width: 100%;
  padding: 20px;

  table {
    thead th {
      color: #9ea0a5;
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px; /* 150% */
      text-transform: uppercase;
    }
    tbody td {
      color: #3e3f42;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 157.143% */
    }
    div {
      text-align: left !important;
    }
  }

  .sponsors_left {
    width: 60%;
    border: 1px solid #eaedf3;
    height: fit-content;

    .sponsor-entity {
      display: flex;
      gap: 10px;
      .sponsor-type {
        color: #9ea0a5;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 150% */
      }
    }
    .action {
      display: flex;
      align-items: center;

      .eye_icon {
        display: flex;
        padding: 8px 11px;
        align-items: center;
        gap: 10px;

        border-radius: 4px;
        border: 1px solid #d8dce6;
        background: linear-gradient(0deg, #f6f7f9 0%, #fff 100%);
        box-shadow: 0px 2px 0px 0px rgba(255, 255, 255, 0.05) inset, 0px 1px 1px 0px rgba(22, 29, 37, 0.05);
      }

      .three_dot {
        scale: 0.5;
      }
    }
  }

  .sponsors_right {
    width: 40%;
    border: 1px solid #eaedf3;

    .Syndicate_Statistic_header {
      padding: 20px;
      h3 {
        color: #3e3f42;
        font-family: Roboto;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px; /* 155.556% */
      }
    }

    .weekly-performance {
      padding: 20px;
      border-top: 1px solid #eaedf3;

      .header {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        color: gray;

        p:last-child {
          color: black;
        }
      }

      .chart {
        margin: 20px 0;
      }

      .metrics {
        .metric {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 10px 0;

          p {
            color: #9ea0a5;
            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px; /* 150% */
          }

          h2 {
            color: #3e3f42;
            text-align: right;
            font-family: Roboto;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 30px; /* 150% */
          }
          h3 {
            color: #3e3f42;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px; /* 157.143% */
          }

          .comparison {
            color: gray;
            font-size: 12px;
          }
        }
      }
    }
  }

  // topinvestor
  .top-investors {
    border-top: 1px solid #eaedf3;
    padding: 20px;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      color: gray;
      margin-bottom: 15px;

      .title {
        color: #3e3f42;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px; /* 200% */
      }

      .see-more {
        color: #9ea0a5;
        text-align: center;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 157.143% */
      }
    }

    .chart {
      .apexcharts-toolbar {
        display: none; // Hide the ApexCharts toolbar
      }

      .apexcharts-yaxis-label {
        font-size: 14px !important;
      }

      .apexcharts-datalabel {
        font-size: 14px !important;
        fill: #ffffff !important;
      }

      .apexcharts-bar-area {
        stroke-width: 0 !important;
      }
    }
  }
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideOut {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
  z-index: 3999;
}

.modal-overlay.enter {
  opacity: 1;
}

.modal-overlay.exit {
  opacity: 0;
}

.modal-content {
  background: white;
  max-width: 600px;
  height: 100%;
  overflow-y: auto;
  position: absolute;
  right: 0px;
  top: 100px;
}

.modal-content.slide-in {
  animation: slideIn 0.3s forwards;
}

.modal-content.slide-out {
  animation: slideOut 0.3s forwards;
}

.modal-header {
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
}

.close-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.modal-body {
  padding: 1rem;
}
