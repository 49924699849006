.container-Equityplan {
  display: flex;
  justify-content: flex-end;
}

.container-Equityplan-data {
  padding: 10px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin: 10px;
}
.Equityplans-Filter {
  padding: 0;
  margin: 0;
  height: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  border: none;
  color: black;
  color: white;
  border-radius: 4px;
  border: 1px solid var(--Primary-Purple-50, #eaedf3);
  border-radius: 4px;
  background: var(--Primary-Purple-500, #7d55c7);
  width: 150px;
}
.Equityplans-Filter-blue {
  padding: 0;
  margin: 0;
  height: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  border: none;
  color: black;
  background-color: white;
  border-radius: 4px;
  border: 1px solid var(--Primary-Purple-50, #eaedf3);
  background: #fff;
  width: 150px;
}
.box-images {
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  height: 100px;
  background-color: #eaedf3;
}

.box-data-container {
  height: 250px;
  display: flex;
  flex-direction: column;
}


.Equityplans-data {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

.Equityplans-data-container {
  border: 1px solid #eaedf3;
  padding-top: 60px;
  width: 350px;
  height: 180px;
  align-items: center;
  text-align: center;
}
.Equityplans-data-container-image {
  background-color: #eaedf3;
  width: 80px;
  justify-content: center;
  display: flex;
  align-items: center;
  height: 80px;
  border-radius: 100%;
}

.Equityplans-Error-Container {
  border: 1px solid #eaedf3;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 350px;
  height: 200px;
  align-items: center;
  text-align: center;
}
.Equityplans-Error-Container p {
  color: #3e3f42;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px; /* 144.444% */
}
