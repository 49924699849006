body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  scroll-behavior: smooth;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

//reset css
* {
  box-sizing: border-box;
  font-size: 16px;
  transition: all 0.3s ease;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

// body, h1, h2, h3, h4, h5, h6, p, ol, ul {
//   margin: 0;
//   padding: 0;
//   font-weight: normal;
// }

ol,
ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
  cursor: pointer;
}
//reset css END
button {
  cursor: pointer !important;
}
