.comments-section {
  display: flex;
  gap: 20px;
  flex-direction: column;
  max-width: 730px;

  margin-bottom: 30px;
  .comment-main {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;

    h3 {
      color: #3e3f42;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px; /* 157.143% */
    }
    p {
      color: #3e3f42;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 157.143% */
    }
  }
  .comment {
    display: flex;
    gap: 20px;
    align-items: flex-start;
    .comment-right {
      background: #fbfbfd;
      border: 1px solid #e2e5ed;
      border-radius: 8px;
      padding: 15px;
      //   margin-bottom: 20px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    &-header {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      .avatar {
        width: 50px;
        height: 50px;
        margin-right: 10px;
      }

      .comment-details {
        display: flex;
        flex-direction: column;

        .comment-name {
          font-weight: bold;
          font-size: 14px;
          margin-bottom: 2px;
        }

        .comment-time {
          font-size: 12px;
          color: gray;
        }
      }
    }

    &-body {
      font-size: 14px;
      color: #333;
      margin-bottom: 10px;

      p {
        margin: 0;
      }
    }

    &-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .reply-link {
        font-size: 12px;
        color: #4472c4;
        text-decoration: none;
      }
    }
  }

  .reply-modal {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    // width: 500px;
    position: relative;

    .replyMain {
      display: flex;
      gap: 30px;
    }

    .replyMai_bottom {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 100%;

      textarea {
        width: 100%;
        height: 100px;
        padding: 10px;
        border: 1px solid #e2e5ed;
        border-radius: 5px;
        margin-bottom: 10px;

        color: #3e3f42;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 157.143% */
      }

      button {
        border-radius: 4px;
        border: 1px solid var(--purple-pantone-2665-c-71, rgba(125, 85, 199, 0.71));
        background: linear-gradient(0deg, rgba(125, 85, 199, 0.3) 0%, rgba(255, 255, 255, 0) 107.78%), #7d55c7;
        background-blend-mode: multiply, normal;
        box-shadow: 0px 2px 0px 0px rgba(255, 255, 255, 0.06) inset, 0px 1px 1px 0px rgba(19, 31, 21, 0.1);

        color: #fff;
        text-align: center;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 183.333% */

        display: inline-flex;
        padding: 2px 20px;
        align-items: center;
        gap: 10px;

        max-width: fit-content;
      }
    }
  }
}

.reply-modal_overlay {
  // position: fixed;
  // top: 0;
  // left: 0;
  // width: 100%;
  // height: 100%;
  // background: rgba(0, 0, 0, 0.5);
  // display: flex;
  // justify-content: center;
  // align-items: center;
}
