.selectBox {
  // min-width: 320px;
  // min-height: 120px;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #e2e5ed;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  flex-direction: column;
  padding: 16px;

  &.activeBox {
    border-radius: 4px;
    border: 1px solid var(--purple-pantone-2665-c-71, rgba(125, 85, 199, 0.71));
    background: rgba(125, 85, 199, 0.05);
  }

  .topIconWrapper {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    gap: 10px;
    .greenswitch {
      display: flex;
      align-items: center;
      gap: 5px;
      justify-content: flex-end;
    }
  }
  .checkbox_container {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
  }

  .icon_wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    p {
      color: var(--LP-mid-grey-_lib, #6b6c6f);
      text-align: center;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 137.5% */
    }
  }
  .checkbox_container {
  }
}

.password_eye {
  position: absolute;
  left: 90%;
  top: 50%;
}

.upload-btn-wrapper {
  // position: relative;
  // overflow: hidden;
  // display: inline-block;
  border-radius: 4px;
  // border: 2px dashed #e2e5ed;
  position: relative;

  button {
    all: unset;
    color: #a4a5a7;
  }
  .btn {
    color: #a4a5a7;
    // background-color: white;
    padding: 14px 0px;
    border-radius: 8px;
    font-size: 14px;
    gap: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.upload-btn-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
.inputBoxMain {
  // max-width: 285px;
  // margin-top: 19px;
  position: relative;
  &.S {
    .upload-btn-wrapper {
      padding: 10px;
    }
  }
  &.L {
    .upload-btn-wrapper {
      padding: 20px;
    }
  }
  &.XL {
    .upload-btn-wrapper {
      padding: 30px;
    }
  }
  .calenderIcon {
    position: absolute;
    top: 50%;
    left: 5px;
  }
  p {
    color: var(--LP-black, #3e3f42);
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 150% */
    text-transform: uppercase;
    opacity: 0.5;
    margin-bottom: 7px;
    &.labelBtm {
      margin-top: 7px;
    }
  }
  input {
    width: 100%;
    height: 38px;
    border: 1px solid var(--LP-border, #e5e5e5);
    border-radius: 4px;
    padding: 8px 16px;
    outline: none;
    color: var(--LP-black, #3e3f42);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
  }
}

.date-range-picker {
  display: flex;
  align-items: center;
  column-gap: 10px;
  border: 1px solid #e2e5ed;
  border-radius: 2px;
  width: fit-content;
  padding: 10px 15px;

  p {
    font-family: Roboto;
    color: #3e3f42;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: -4px;
    opacity: 1;
  }
}
input[type="date"] {
  // padding-left: 28px;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}
.inputFlexLine {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
}
.infoBox {
  background: rgba(177, 219, 255, 0.2);
  box-shadow: 4px 0px 0px 0px #56ccf2 inset;
  margin-top: 20px;
  border-radius: 6px;
  .container {
    align-items: flex-start;
    padding: 20px;
    p {
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      color: #6b6c6f;
    }
  }
  .sigin-Pointer {
    cursor: pointer;
  }
}

.greenswitch {
}
