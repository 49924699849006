.keydeal {
    margin-left: 20px;
}


.keydeal_box {
    display: grid;
    grid-template-columns: 8% 92%;
    gap: 20px;
    padding: 20px;
    background-color: #ffffff;
    border: 1px solid #EAEDF3;
    filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.04));

    .keydeal_box_left {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 62px;
        width: 62px;
        border-radius: 50%;
        background-color: rgba(22, 101, 216, 0.1);
        color: #00629B;
    }

    .keydeal_box_right {
        h2 {
            color: #3E3F42;
            font-family: Roboto;
            font-size: 22px;
            font-style: normal;
            font-weight: 400;
            line-height: 32px;
        }

        p {
            color: #6B6C6F;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
        }
    }

}

.keydeal_header {
    margin-bottom: 20px;
    h2 {
        color: #3E3F42;
        font-family: Roboto;
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
        text-transform: capitalize;
    }

    button {
        padding: 12px 16px;
        outline: none;
        
    }
    .keydeal_header_right {
        gap: 20px;
    }

    .btn_left {
        display: flex;
        align-items: center;
        gap: 4px;
        border-radius: 4px;
        border: 1px solid #D8DCE6;
        background: linear-gradient(0deg, #F6F7F9 0%, #FFF 100%);
        box-shadow:  0px 1px 1px 0px rgba(22, 29, 37, 0.05), 0px 2px 0px 0px rgba(255, 255, 255, 0.05) inset;
        }

        .btn_right {
            background-color: #7D55C7;
            border: none;
            border-radius: 4px;
            color: #ffffff;
        }
    }


    .keydeal_container {
        padding: 0 20px;
        display: flex;
        gap: 30px;
    }

    .keydeal_center {
        width: 730px;
        min-height: 524px;
        padding: 0 !important;
        display: flex;
        flex-direction: column;
        gap: 20px;
        // background-color: rgba(15, 160, 227, 0.623);s
    }

    .keydeal_right {
        width: 350px;
        height: 524px;
        position: sticky !important;
        // top: 70px !important;
        // background-color: rgba(15, 227, 15, 0.623);
    }