.ISO-conatiner {
  border: 1px solid var(--Primary-Purple-50, #eaedf3);
  background: rgba(125, 85, 199, 0.08);
}



.ISO-conatiner-data {
  display: flex;
  padding: 20px;
  align-items: flex-start;
  justify-content: space-between;
}
@media screen and (max-width: 768px) {
  .ISO-conatiner-data {
    display: flex;
    flex-direction: column;
  }
}

.ISO-stakeholder-data {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  padding-top: 30px;
}

@media screen and (max-width: 768px) {
  .ISO-stakeholder-data {
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
  }
}

.ISO-stakeholder-ISO {
  border: 1px solid #eaedf3;
  justify-content: center;
  align-items: center;
  width: 40%;
  height: 250px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
}

@media screen and (max-width: 900px) {
  .ISO-stakeholder-ISO {
    width: 100%;
  }
}

.ISO-stakeholder-ISO span {
  color: #3e3f42;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px; /* 144.444% */
}
.ISO-stakeholder-ISO-image {
  background-color: #efebf8;
  width: 80px;
  border-radius: 100%;
  height: 80px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
}
.ISO-conatiner-div-data {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.left-ISO-Container {
  border: 1px solid #eaedf3;
  display: flex;
  width: 60%;
  flex-direction: column;
  gap: 30px;
}

.left-ISO-Container-data {
  margin: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

@media screen and (max-width: 800px) {
  .left-ISO-Container-data {
    width: 100%;

  }
}
.left-ISO-Container-data p {
  color: var(--primary-gray-100-text, #9ea0a5);
  leading-trim: both;
  text-edge: cap;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}
.ISO-stakeholder-ISO-partnership {
  background-color: #ed962d;
  width: 60px;
  height: 60px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
}
.ISO100K-data-Container {
  display: flex;
  justify-content: space-between;
}
.ISO100K-data-Container h5 {
  color: var(--Black, #3e3f42);
  leading-trim: both;
  text-edge: cap;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 155.556% */
  width: 80%;
}
