.budgettable {
    .bt_header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
        width: 100%;
        padding: 20px;
    }

    .bt_header_left {
        display: flex;
        align-items: center;
        gap: 12px;

        button {
            outline: none;
            background: linear-gradient(#F6F7F9, #FFFFFF);
            border: 1px solid #D8DCE6;
            border-radius: 4px;
            padding: 8px 16px;
            color: #3E3F42;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 12px;
        }

        h4 {
            color: var(--LP-mid-gray, #6B6C6F);
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            /* 157.143% */
        }

        .dropDownOuterCon {
            margin-top: -5px !important;
        }

        .dropDownMAIN {
            height: 40px !important;
        }
    }

    .bt_header_right {
        display: flex;
        align-items: center;
        gap: 12px;

        .btn_right {
            color: #FFF;
            text-align: center;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            border-radius: 4px;
            border: 1px solid var(--purple-pantone-2665-c-71, rgba(125, 85, 199, 0.71));
            background: linear-gradient(0deg, rgba(125, 85, 199, 0.30) 0%, rgba(255, 255, 255, 0.00) 107.78%), #7D55C7;
            background-blend-mode: multiply, normal;
            box-shadow: 0px 1px 1px 0px rgba(19, 31, 21, 0.10), 0px 2px 0px 0px rgba(255, 255, 255, 0.06) inset;
            display: inline-flex;
            padding: 8px 20px;
            align-items: center;
            justify-content: center;
            gap: 12px;
        }
    }

    .table_wrapper {
        min-width: 890px;
        max-width: 895px;
    }

    table {
        width: 100%;
        border-collapse: collapse;
        max-width: 890px;

        tr {
            border: 1px solid #eaedf3;
        }

        tr td:first-child {
            display: flex;
            align-items: center;
            gap: 8px;
        }

        .main_row {
            color: #3E3F42;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }

        .second_row {
            height: 60px;

            td:first-child {
                margin-top: 20px;
            }

            td:last-child {}
        }

        .expanded_last {
            color: #3E3F42;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }

        .expanded {
            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            color: #3E3F42;
        }

        th,
        td {
            text-align: left;
            padding: 8px;
            height: 32px;

        }

        th {
            color: #9EA0A5;
            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px;
            text-transform: uppercase;
        }

    }

    .dropdown-icon {
        margin-right: 5px;
        cursor: pointer;
    }

    .bt_wrapper {
        display: flex;

        .bt_sidebar {
            padding: 16px;
            display: flex;
            flex-direction: column;
            gap: 12px;
            border-left: 1px solid #eaedf3;

            span {
                color: #9EA0A5;
                font-family: Roboto;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 18px;
                /* 150% */
                text-transform: uppercase;
            }

            .box {
                padding: 12px;
                width: 247px;
                height: 184px;
                border-radius: 11px;
                background: rgba(242, 201, 76, 0.15);
                border: 2px solid rgba(242, 201, 76, 0.60);
                display: flex;
                flex-direction: column;
                gap: 8px;

                .box_btns {
                    display: flex;
                    width: 100%;
                    justify-content: space-between;
                    align-items: center;
                    gap: 20px;

                    button {
                        outline: none;
                        background: linear-gradient(#F6F7F9, #FFFFFF);
                        border: 1px solid #D8DCE6;
                        border-radius: 4px;
                        padding: 8px 16px;
                        color: #3E3F42;
                        font-family: Roboto;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 22px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 12px;
                    }
                }

                .input_label {
                    color: var(--LP-black, #3E3F42);
                    font-family: Roboto;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 22px;
                    /* 157.143% */
                }
            }
        }

        .textbox {
            h2 {
                color: #3E3F42;
                font-family: Roboto;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 22px;
                /* 157.143% */
            }

            p {
                color: #6B6C6F;
                font-family: Roboto;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;
                /* 157.143% */
            }

            .textbox_bottom {
                display: flex;
                justify-content: space-between;
                width: 93%;

                p {
                    color: var(--green-pantone-355-c, #009639);
                    font-family: Roboto;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 22px;
                    /* 183.333% */
                }

                span {
                    display: flex;
                    align-items: center;
                    gap: 4px;
                    color: var(--blue-pantone-3015-c, #00629B);
                    text-align: right;
                    font-family: Roboto;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 22px;
                    /* 183.333% */
                }
            }
        }
    }


    .downloadbox {
        width: 195px;
        height: 182px;
        border: 1px solid #EAEDF3;
        filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.06));
        position: absolute;
        right: 100px;
        z-index: 111;
        background: #ffffff;
        padding: 20px;
        margin-top: -20px;
        div{
            display: flex;
            flex-direction: column;
            gap: 20px;
            padding-top: 20px;
        }
        h5 {
            color: #9EA0A5;
            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px;
            /* 150% */
            text-transform: uppercase;
        }

        span {
            color: #3E3F42;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            display: flex;
            align-items: center;
            gap: 12px;
            /* 157.143% */
        }
    }

}