.LoanRequstComp-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  @media screen and (max-width: 900px) {
    margin-left: -204px;
  }
}

.deals-room-center-header p {
  font-size: 24px;
  font-weight: bold;
}

.loan-req-main-conatiner {
  width: 100%;
  max-width: 1110px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  padding: 20px;
  background: #fff;
  border: 1px solid #eaedf3;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  box-sizing: border-box;
  flex-wrap: wrap;
  @media screen and (max-width: 900px) {
      flex-direction: column;
      width: 52%;
  }
}

.loan-req-left {
  flex: 1;
  min-width: 300px;
}

.loan-req-left-title {
  margin-top: 20px;
}

.loan-req-left-title > span {
  color: #3e3f42;
  font-family: Roboto;
  font-size: 28px;
  font-weight: 300;
  line-height: 1.4;
}

.loan-req-left-para {
  margin-bottom: 20px;
}

.loan-req-left-para > span {
  color: #6b6c6f;
  font-family: Roboto;
  font-size: 14px;
  line-height: 1.6;
}

.loan-req-btn {
  display: inline-flex;
  padding: 10px 20px;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  border: 1px solid rgba(125, 85, 199, 0.71);
  background: linear-gradient(0deg, rgba(125, 85, 199, 0.3) 0%, rgba(255, 255, 255, 0) 100%), #7d55c7;
  box-shadow: 0px 1px 1px rgba(19, 31, 21, 0.1), 0px 2px 0px rgba(255, 255, 255, 0.06) inset;
}

.loan-req-btn > span {
  color: #fff;
  font-family: Roboto;
  font-size: 14px;
}

.loan-req-right {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 300px;
  @media screen and (max-width: 900px) {
    min-width: 200px;
  }
}

.loan-req-right img {
  max-width: 100%;
  height: auto;
}

.loan-req-skeleton {
  margin-top: 20px;
  width: 100%;
  max-width: 730px;
  background: linear-gradient(264deg, rgba(255, 255, 255, 0.55) 45.42%, #fff 73.84%, rgba(255, 255, 255, 0) 100%), #f6f7f9;
  padding: 20px;
  border-radius: 8px;
}

.skeleton-div {
  background: #eaedf3;
  border-radius: 4px;
}

.skeleton-div:first-child {
  width: 70%;
  height: 32px;
  margin-bottom: 16px;
}

.skeleton-div:nth-child(2) {
  width: 90%;
  height: 24px;
  margin-bottom: 12px;
}

.skeleton-div:last-child {
  width: 90%;
  height: 24px;
}


.loan-req-scalton-loanreq {
  margin: 30px;
  padding: 30px;
  height: 164px;
  flex-shrink: 0;
  border-radius: 4px;
  background: linear-gradient(264deg, rgba(255, 255, 255, 0.55) 45.42%, #fff 73.84%, rgba(255, 255, 255, 0) 100%), #f6f7f9;
  width: 100%;
  box-sizing: border-box;

  @media screen and (max-width: 1200px) {
    width: 80%;
  }

  @media screen and (max-width: 900px) {
    width: 70%;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    margin-left: 0;
    padding-left: 20px;
    padding-right: 20px;
  }

  @media screen and (max-width: 480px) {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.scalton-div {
  border-radius: 4px;
  background: #eaedf3;
  margin-bottom: 12px;

  &:first-child {
    width: 70%;
    height: 32px;
    margin-bottom: 16px;
  }

  &:nth-child(2) {
    width: 90%;
    height: 24px;
    margin-bottom: 12px;
  }

  &:last-child {
    width: 90%;
    height: 24px;
  }

  @media screen and (max-width: 768px) {
    &:first-child, &:nth-child(2), &:last-child {
      width: 100%;
    }
  }
}


