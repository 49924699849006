.full-page-slider-container {
  display: flex;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 11111111;
  top: 0;
  left: 0;
  overflow: auto;

  .upload-button {
    width: 130px;
    height: 88px;
    color: var(--LP-gray, #9ea0a5);
    text-align: center;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;
    border-radius: 8px;
    background: var(--LP-mid-gray, #6b6c6f);
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25) inset;
    margin-top: 20px;
  }
}

.main-slider-FullPageSlider {
  flex: 1 1;
  transition: transform 0.5s ease-in-out;
  overflow: auto;
  width: 100%;
  background-color: #888;
  border-radius: 5px;
  height: 100vh;
  .slider-header {
    position: fixed;
    z-index: 3;
  }

  .main-slider-wrapper {
    overflow: auto;
    margin-top: 90px;
    padding-bottom: 30px;
  }
}
// #main-slider {
//   flex: 1 1;
//   transition: transform 0.5s ease-in-out;
//   overflow: auto;
//   width: 100%;
//   background-color: #888;
//   border-radius: 5px;
//   height: 60vh;
//   .slider-header {
//     position: fixed;
//     z-index: 3;
//   }

//   .main-slider-wrapper {
//     overflow: auto;
//     margin-top: 90px;
//     padding-bottom: 30px;
//   }
// }

.main-slider-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.slider-header {
  display: flex;
  flex-wrap: wrap;
  padding: 0 20px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100px;
  background-color: #fff;

  .left-header {
    display: flex;
    align-items: center;
    gap: 40px;
    flex-wrap: wrap;
  }

  .details-header {
    display: flex;
    align-items: center;
    gap: 20px;
    border-left: 1px solid #eaedf3;
    padding: 0 20px;

    img {
      height: 68px;
      width: 64px;
    }

    h3 {
      color: #3e3f42;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }

    p {
      color: #9ea0a5;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }

    span {
      color: #3e3f42;
      text-align: right;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
    }
  }

  .cross {
    height: 40px;
    width: 40px;
    cursor: pointer;
  }

  .right-header {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
}

.main-image {
  width: 50%;
  max-width: 100%;
  height: auto;
  object-fit: contain;
  background-color: white;
  margin-top: 30px;
}

#thumbnail-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  background: var(--LP-black, rgba(62, 63, 66, 0.8));
  position: absolute;
  right: 0;
  top: 15.5%;
  max-height: calc(105.5% - 150px);
  overflow-y: auto;
}

.thumbnail {
  width: 130px;
  height: 88px;
  cursor: pointer;
  border: 2px solid #ddd;
  border-radius: 4px;
  transition: border-color 0.3s ease-in-out;
}

.thumbnail:hover {
  border-color: #555;
}

.thumbnail.active {
  border-color: #333;
}

@media (max-width: 768px) {
  .slider-header {
    flex-direction: column;
    height: auto;
    gap: 20px;
  }

  .details-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .main-slider-wrapper {
    flex-direction: column;
  }

  .main-image {
    width: 100%;
    height: auto;
  }

  #thumbnail-container {
    flex-direction: row;
    gap: 10px;
    overflow-x: auto;
    height: auto;
    width: 100%;
    position: relative;
    top: auto;
    background: transparent;
    padding: 10px;
  }
}

.floorplan_download {
  background-color: white;
}

.upload_floor_plan {
}
