.generate_budget {
    .generate_tabs {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 12px;
        padding: 30px;

        a {
            color: var(--LP-gray, #9EA0A5);
            text-align: center;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 19px;
            text-align: center;
            max-width: 88px;
        }


        .generate_tab.active {
            color: var(--purple-pantone-2665-c, #7D55C7);
            text-align: center;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 19px;
            /* 135.714% */
        }

    }

    .tab_content {
        padding: 30px;

        .content_header {
            display: flex;
            flex-direction: column;
            gap: 12px;
            align-items: flex-start;

            h2 {
                color: var(--LP-black, #3E3F42);
                font-family: Roboto;
                font-size: 24px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }

            span {
                color: var(--LP-gray, #9EA0A5);
                font-family: Roboto;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;
                /* 157.143% */
            }
        }

        h3 {
            color: #3E3F42;
            font-family: Roboto;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            /* 150% */
        }

        .content_details {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            gap: 20px;
            max-width: 90%;
            margin: auto;
            padding: 20px;
            margin-top: 40px;
        }

        .content_details_lower {
            display: flex;
            flex-direction: column;
            gap: 20px;

            .checkbox {
                padding-left: 0px !important;
            }
        }

        .draganddrop {
            height: 75px;
            width: 840px;
            margin: auto;
            border-radius: 4px;
            border: 1px dashed #E2E5ED;
            background: #FCFCFC;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05) inset;
            display: flex;
            justify-content: center;
            align-items: center;

            >div {
                display: flex;
                gap: 12px;
                align-items: flex-start;

                p {
                    color: #9EA0A5;
                    font-family: Roboto;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 22px;
                }

                span {
                    font-size: 12px;
                }
            }

        }
    }

    .ps_box_wrapper {
        max-width: 90%;
        margin: auto;
        padding: 40px;
        margin-top: 20px;
        display: flex;
        align-items: center;
        gap: 30px;
    }



    .fs_wrapper {
        max-width: 90%;
        margin: auto;
        padding: 40px;
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        gap: 30px;

        .fs_charts_wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 2px;
        }

        .inputs {
            display: flex;
            gap: 20px;

            span {
                color: #9EA0A5;
                font-family: Roboto;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;
                /* 157.143% */
            }


        }
    }

    .ps_box {
        width: 350px;
        height: 240px;
        border-radius: 0px 4px 4px 0px;
        border: 1px solid #EAEDF3;
        border-left: 3px solid #1665D8;
        background: #FFF;
        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.04);
        padding: 26px 30px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        position: relative;

        .ps_img {
            height: 40px;
            width: 40px;
            position: absolute;
            right: 20px;
        }

        p {
            color: #9EA0A5;
            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px;
            opacity: 1;
            text-transform: uppercase;
        }


    }

    .addinc_box_wrapper {
        padding: 20px 0;
    }

    .table_box {
        width: 100%;
        border-collapse: collapse;
        margin-top: 20px;
        padding: 0 20px;
        border: 1px solid #EAEDF3;
        width: 730px;
        border-radius: 4px;
        max-height: 320px;
        overflow-y: auto;

        .table-row {
            display: flex;
            border-bottom: 1px solid #eaedf3;
            height: 60px;
            align-items: center;

            &:last-child {
                border-bottom: none;
            }

            .column {
                flex: 1;
                padding: 0 10px;
                color: #9EA0A5;
                font-family: Roboto;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 18px;
                text-transform: uppercase;
            }

            .column:first-child {
                width: 200px;
            }
        }

        .cell1 {
            flex: 1;
            padding: 0 10px;
            color: #3E3F42;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
        }

        .cell {
            flex: 1;
            padding: 0 10px;
            color: #3E3F42;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
        }

        .right {
            text-align: right;
        }
    }

    .range_inputs {
        margin-top: 24px;
        display: flex;
        align-items: center;
        gap: 20px;
    }


    .ut_box {
        width: 635px;
        height: 184px;
        border-radius: 0px 4px 4px 0px;
        border: 1px solid #EAEDF3;
        border-left: 3px solid #7D55C7;
        background: #FFF;
        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.04);
        padding: 26px 27px;
        position: relative;
        margin-top: 40px;

        .utsvg {
            position: absolute;
            right: 20px;
        }

        p {
            color: #9EA0A5;
            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px;
            /* 150% */
            text-transform: uppercase;
        }

    }

    .label {
        color: #9EA0A5;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        text-transform: uppercase;
        margin-bottom: 10px;
    }

    .inputbox {
        width: 150px;
        height: 38px;
        border: 1px solid #eaedf3;
        border-radius: 6px;
        margin-top: 10px;

        .MuiSlider-root .MuiSlider-track,
        .MuiSlider-root .MuiSlider-thumb {
            color: #7d55c7;

        }

        span {
            margin-top: -2px;
            font-size: 14px;

        }

        .range {
            width: 150px;
            accent-color: #7D55C7;
            border: none;
            outline: none;
            margin-top: 6px;
        }
    }

    .ds {
        .ds_inputs {
            margin-top: 40px;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 20px;

            .range {
                width: 255px !important;
            }

            .dollar_input {
                width: 255px !important;
            }

            .inputbox {
                width: 255px !important;
            }
        }

        p {
            color: #9EA0A5;
            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px;
            text-transform: uppercase;
        }
    }


    .btns {
        display: flex;
        align-items: center;
        background: #FFFFFF00;
        border-radius: 4px;
        border: 1px solid #eaedf3;
        height: 38px;
        position: absolute;
        right: 20px;

        div {
            border-right: 1px solid #eaedf3;
            height: 38px;
            padding: 9px;

        }
    }

    .as_inputs {
        margin-top: 40px;
        display: grid;
        grid-template-columns: repeat(2, 350px);
        grid-gap: 20px;

        p {
            color: #9EA0A5;
            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px;
            text-transform: uppercase;
            padding-bottom: 10px;
        }

        .dollar_input {
            width: 310px;
        }
    }

    .as_btns {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 90%;
        margin-top: 200px;
        border-top: 1px solid #eaedf3;
        padding: 20px 0;

        .btn_next {
            color: #FFF;
            text-align: center;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            border-radius: 4px;
            border: 1px solid var(--purple-pantone-2665-c-71, rgba(125, 85, 199, 0.71));
            background: linear-gradient(0deg, rgba(125, 85, 199, 0.30) 0%, rgba(255, 255, 255, 0.00) 107.78%), #7D55C7;
            background-blend-mode: multiply, normal;
            box-shadow: 0px 1px 1px 0px rgba(19, 31, 21, 0.10), 0px 2px 0px 0px rgba(255, 255, 255, 0.06) inset;
            display: inline-flex;
            padding: 8px 20px;
            align-items: center;
            justify-content: center;
            gap: 12px;
        }


        .btn_back {
            outline: none;
            background: linear-gradient(#F6F7F9, #FFFFFF);
            border: 1px solid #D8DCE6;
            border-radius: 4px;
            padding: 8px 16px;
            color: #3E3F42;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 12px;
        }

    }
}





.dollar_input {
    border: 1px solid #EAEDF3;
    width: 150px;
    height: 38px;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 16px;
    padding-right: 10px;
    gap: 10px;

    p {
        color: #9EA0A5;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        /* 157.143% */
    }



    input {
        outline: none;
        border: none;
        height: 30px;
        width: 65px;
        color: #9EA0A5;
        text-align: right;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        /* 157.143% */
        &::placeholder{
            color: #9EA0A5;
            text-align: right;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px; /* 157.143% */  
        }
    }

}