.dealeconomics-container {
  font-family: Roboto;
  display: grid;
  grid-template-columns: auto;
  // height: 100vh;
  // @media screen and (max-width: 800px) {
  //   margin-left: -76px;
  //   margin-top: -25px;
  // }
}

/*=============================================*/
/*                 HEADER                     */
/*=============================================*/

.body-B-header {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 16px;

  h2 {
    color: #3e3f42;
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    /* 155.556% */
    text-transform: capitalize;
  }

  div {
    padding: 0 14px;
    border-radius: 4px;
    border: 1px solid var(--LP-very-light, #eaedf3);
    display: flex;
    align-items: center;
    gap: 4px;
    p {
      color: var(--LP-mid-gray, #6b6c6f);
      text-align: right;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
      /* 200% */
      text-transform: capitalize;
    }
    span {
      color: var(--3-fa-2-f-7-links-informational-blue, #3fa2f7);
      text-align: right;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px; /* 200% */
      text-transform: capitalize;
    }
  }
}

.price-summary-B-lower {
  padding: 16px 0;
  border-top: 1px solid #eaedf3;
  margin-top: 10px;
  span {
    color: #3fa2f7 !important;
  }
}

.development-charts {
  margin-top: 20px;
  .dropdown-blocks {
    display: flex;
    flex-direction: column;
    gap: 12px;
    div {
      display: flex;
      align-items: center;
      gap: 12px;
    }
    p {
      color: #3e3f42;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px; /* 157.143% */
    }
  }
}

.economics-header {
  width: 95%;
  // height: 66px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  background: linear-gradient(0deg, #fff 0%, #fff 100%), #fbfbfd;
  box-shadow: 0px 1px 1px 0px #eaedf3;
  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }

  .ec-header-left {
    span {
      color: #3e3f42;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 30px;
      /* 150% */
    }
  }

  .ec-header-right {
    display: flex;
    align-items: center;
    gap: 24px;

    img {
      cursor: pointer;
    }
  }

  .savebtn-val {
    display: flex;
    align-items: center;
    padding: 8px 11px;
    margin: 0;
    border-radius: 4px;
    border: 1px solid #d8dce6;
    background: linear-gradient(0deg, #f6f7f9 0%, #fff 100%);
    box-shadow: 0px 1px 1px 0px rgba(22, 29, 37, 0.05), 0px 2px 0px 0px rgba(255, 255, 255, 0.05) inset;

    span {
      color: #3e3f42;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      /* 157.143% */
    }
  }

  .downloadbtn-val {
    margin: 0;
    padding: 8px 10px;

    span {
      color: #fff;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      /* 157.143% */
    }
  }
}

/*=============================================*/
/*                BODY HEADER                   */
/*=============================================*/
// .economics-body-head {
//   display: flex;
//   align-items: center;
//   padding: 0 30px;
//   gap: 70px;
//   height: 75px;
//   border-bottom: 1px solid #eaedf3;
// }

// .economics-nav {
//   height: 35px;
//   border: none;
//   margin: 0;
// }

// .switch-economics {
//   width: 255px;
//   height: 39px;
//   border-radius: 10px;
//   border: 1px solid var(--light-grad, #f6f7f9);
//   background: rgba(234, 237, 243, 0.5);
//   box-shadow: 1px 1px 2px 0px rgba(14, 11, 20, 0.1) inset;

//   span {
//     display: flex;
//     border-radius: 8px;
//     // background: var(--purple-pantone-2665-c, #7d55c7);
//     width: 124px;
//     height: 28px;
//     justify-content: center;
//     align-items: center;
//     color: var(--ffffff-white, #fff);
//   }

//   .btn-active {
//     display: flex;
//     border-radius: 8px;
//     background: var(--purple-pantone-2665-c, #7d55c7);
//     width: 124px;
//     height: 28px;
//     justify-content: center;
//     align-items: center;
//     color: var(--ffffff-white, #fff);
//   }
// }
.economics-body-head {
  display: flex;
  align-items: center;
  // padding: 0 30px;
  gap: 70px;
  // height: 75px;
  // border-bottom: 1px solid #eaedf3;
}

.switch-btn.switch-economics {
  display: flex;
  gap: 30px;
}

.switch-btn.switch-economics span {
  color: #6b6c6f;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.switch-btn.switch-economics .btn-active {
  color: #7d55c7;
  border-bottom: 2px solid #7d55c7;
}

.deals-room-nav.economics-nav {
  display: flex;
  gap: 30px;
}

.deals-room-nav.economics-nav span {
  color: #6b6c6f;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
}

.deals-room-nav.economics-nav .deals-room-nav-active {
  color: #7d55c7;
  border-bottom: 2px solid #7d55c7;
}

@media (max-width: 900px) {
  .economics-body-head {
    flex-direction: column;
    height: auto;
    padding: 20px 30px;
    gap: 20px;
  }

  .switch-btn.switch-economics {
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }

  .switch-btn.switch-economics span {
    font-size: 12px;
  }

  .deals-room-nav.economics-nav {
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }

  .deals-room-nav.economics-nav span {
    font-size: 12px;
  }
}

@media (max-width: 480px) {
  .economics-body-head {
    padding: 15px 20px;
  }

  .switch-btn.switch-economics {
    gap: 10px;
  }

  .switch-btn.switch-economics span {
    font-size: 10px;
  }

  .deals-room-nav.economics-nav {
    gap: 10px;
  }

  .deals-room-nav.economics-nav span {
    font-size: 10px;
  }
}

/*=============================================*/
/*                BODY SUMMARY                 */
/*=============================================*/
.summary-main-container {
  width: 730px;
}

.economics-body {
  display: flex;
  // max-width: 1200px;
  overflow-x: auto;
  @media screen and (max-width: 900px) {
    flex-direction: column;
  }

  .economics-body-right {
    margin: 25px 0;
    padding: 0 0 0 20px;
  }
}

.summary-body-A {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 25px;

  .price-summary {
    width: 150px;
    height: 105px;
    display: flex;
    flex-direction: column;

    span:nth-child(1) {
      color: #9ea0a5;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
      /* 150% */
      text-transform: uppercase;
      margin-bottom: 6px;
    }

    span:nth-child(2) {
      color: var(--LP-black, #3e3f42);
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .div-img {
      display: flex;
      align-items: center;

      span {
        color: var(--Green-2, #27ae60);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        /* 150% */
        text-transform: uppercase;
      }
    }

    .img-red {
      span {
        color: var(--red-pantone-1797-c, #cb333b);
      }
    }

    span:nth-child(4) {
      color: #9ea0a5;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      /* 183.333% */
    }
  }
}

.summary-body-B {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 25px;

  .price-summary-B {
    width: 166px;
    height: 95px;
    padding: 10px 15px;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    background: #faf9fb;

    span:nth-child(1) {
      color: #9ea0a5;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
      /* 150% */
      text-transform: uppercase;
      margin-bottom: 6px;
    }

    span:nth-child(2) {
      color: var(--LP-black, #3e3f42);
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .div-img {
      display: flex;
      align-items: center;

      p {
        color: var(--Green-2, #27ae60);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        /* 150% */
        text-transform: uppercase;
      }

      span {
        color: var(--LP-gray, #9ea0a5);
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
      }
    }
  }
}

.income-container {
  border-radius: 4px;
  background: #faf9fb;
  margin-top: 20px;
  display: flex;
  gap: 25px;
  padding: 15px 50px 28px 15px;

  h1 {
    color: #9ea0a5;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    /* 150% */
    text-transform: uppercase;
    margin-bottom: 28px;
  }

  img {
    margin-top: 50px;
  }

  .income-left {
    width: 280px;

    .income-flex {
      display: flex;
      align-items: center;
      justify-content: space-between;

      span,
      p {
        color: var(--LP-black, #3e3f42);
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
      }

      div {
        display: flex;
        gap: 0 20px;
        margin: 8px 0 5px 0;

        div {
          display: flex;
          flex-direction: column;

          span {
            color: var(--LP-gray, #9ea0a5);
            font-size: 10px;
            font-weight: 500;
            line-height: normal;
          }
        }
      }
    }
  }

  .income-right {
    width: 295px;

    div {
      display: flex;
      align-items: center;
      margin-bottom: 14px;
      justify-content: space-between;

      p {
        color: var(--LP-black, #3e3f42);
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
      }

      span {
        color: var(--LP-mid-gray, #6b6c6f);
        font-size: 12px;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
}

.unit-container {
  margin: 40px 0;

  .unit-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    padding-right: 12px;

    span {
      color: #3e3f42;
      font-size: 16px;
      font-weight: 500;
      line-height: 28px;
      /* 175% */
    }
  }

  .unit-card-body {
    display: flex;
    gap: 10px 30px;
    flex-wrap: wrap;
  }

  .unit-card {
    width: 350px;
    padding: 20px 40px 30px 20px;
    border-radius: 4px;
    border: 1px solid var(--eaeaea-background-grey, #eaeaea);
    background: #fff;

    h1 {
      color: var(--LP-black, #3e3f42);
      font-size: 24px;
      font-weight: 500;
      line-height: normal;
    }

    .unit-card-head {
      display: flex;
      gap: 30px;
      align-items: center;

      > div {
        display: flex;
        gap: 10px;
      }
    }
  }

  .unit-card-price {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    padding-bottom: 25px;
    border-bottom: 1px solid #eaedf3;

    div:nth-child(2) {
      border: 1px solid #eaedf3;
      height: 65px;
    }

    div {
      display: flex;
      flex-direction: column;
      gap: 8px;

      span:nth-child(1) {
        color: #9ea0a5;
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        /* 150% */
        text-transform: uppercase;
      }

      span:nth-child(2) {
        color: var(--LP-black, #3e3f42);
        font-size: 20px;
        font-weight: 500;
        line-height: normal;
      }

      span:nth-child(3) {
        color: #9ea0a5;
        font-size: 12px;
        font-weight: 400;
        line-height: 22px;
        /* 183.333% */
      }
    }
  }

  .unit-card-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;

    img {
      padding: 10px;
      border-radius: 8px;
      background: var(--LP-very-light, #eaedf3);
    }

    div {
      display: flex;
      gap: 15px;

      div {
        display: flex;
        gap: 4px;
        flex-direction: column;

        p {
          color: #9ea0a5;
          font-size: 12px;
          font-weight: 500;
          line-height: 18px;
          /* 150% */
          text-transform: uppercase;
        }

        span {
          color: var(--LP-black, #3e3f42);
          font-size: 18px;
          font-weight: 500;
          line-height: normal;
          display: block;
        }
      }
    }
  }
}

.net-income-cont {
  fill: #fff;
  margin-bottom: 40px;
  stroke-width: 1px;
  stroke: #eaedf3;
  border: 1px solid #eaedf3;
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.04));

  .net-income-head {
    display: flex;
    height: 74px;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #eaedf3;
    padding: 0 30px;

    h1 {
      color: #3e3f42;
      font-size: 18px;
      font-weight: 500;
      line-height: 28px;
      /* 155.556% */
    }

    .income-head-right {
      display: flex;
      align-items: center;
      gap: 30px;

      div {
        border: 1px solid #eaedf3;
        fill: #d8dce6;
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 8px 16px;
        border-radius: 4px;
        cursor: pointer;
      }
    }
  }

  .income-time {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 10px 0 20px 30px;

    div {
      padding: 8px 15px;
      border-radius: 100px;
      border: 1px solid #eaedf3;
      cursor: pointer;
    }

    span {
      color: var(--LP-black, #3e3f42);
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      /* 157.143% */
    }
  }

  .timeactive {
    background-color: #eaedf3;
  }

  .income-type {
    display: flex;
    gap: 10px;
    align-items: center;
    flex-wrap: wrap;
    padding-left: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid #eaedf3;

    div {
      display: flex;
      gap: 10px;
      align-items: center;
      height: 22px;
      padding: 0 10px;
      border-radius: 4px;
      border: 1px solid var(--LP-very-light, #eaedf3);
      background: var(--ffffff-white, #fff);

      span {
        color: var(--LP-black, #3e3f42);
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        /* 157.143% */
      }
    }
  }

  .income-graphs {
    padding: 0 30px 20px 30px;

    img:nth-child(2) {
      margin: 0 0 30px 16px;
    }
  }

  .loan-ammount {
    display: flex;
    gap: 50px;
    align-items: center;
    margin: 20px 30px;

    div {
      display: flex;
      flex-direction: column;

      span:first-child {
        color: #9ea0a5;
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        /* 150% */
        text-transform: uppercase;
      }

      span:last-child {
        color: var(--LP-black, #3e3f42);
        font-size: 20px;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
}

.equity-loan-container {
  > h1 {
    color: var(--LP-black, #3e3f42);
    font-size: 18px;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 30px;
  }

  .net-income-cont {
    .net-income-head {
      display: flex;
      align-items: center;
      padding: 0 30px;
      justify-content: space-between;

      .income-head-left {
        display: flex;
        align-items: center;
        gap: 20px;

        div img {
          border-radius: 4px;
          background: var(--purple-pantone-2665-c, #7d55c7);
          padding: 12px;
        }
      }
    }
  }

  .equity-body {
    .equity-body-top {
      margin: 30px;
      display: flex;
      align-items: center;

      // gap: 30px;
      div:last-child {
        width: 155px;
        border: none;
      }

      > div {
        display: flex;
        align-items: flex-start;
        flex-direction: column;

        border-right: 1px solid #eaedf3;
        // padding: 0 30px;
        width: 130px;
        margin-left: 30px;

        p {
          color: var(--LP-black, #3e3f42);
          text-align: right;
          font-size: 26px;
          font-weight: 400;
          line-height: 38px;
          /* 146.154% */
        }

        span {
          color: #9ea0a5;
          text-align: right;
          font-size: 12px;
          font-weight: 500;
          line-height: 100%;
          /* 12px */
          text-transform: uppercase;
        }

        div {
          display: flex;
          align-items: center;
          gap: 12px;
          flex-direction: row;
          border: none;
        }
      }
    }

    .equity-body-bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 10px 30px 30px 30px;

      .equity-bottom-left {
        width: 180px;

        .bottom-left-A {
          padding-bottom: 12px;
          border-bottom: 1px solid #eaedf3;
          margin-bottom: 20px;

          p {
            text-align: right;
            font-size: 26px;
            font-weight: 400;
            line-height: 38px;
            /* 146.154% */
            color: var(--purple-pantone-2665-c, #7d55c7);
          }
        }

        .bottom-left-B {
          padding-bottom: 12px;
          margin-bottom: 20px;
          border-bottom: 1px solid #eaedf3;

          p {
            text-align: right;
            font-size: 26px;
            font-weight: 400;
            line-height: 38px;
            /* 146.154% */
            color: var(--red-pantone-1797-c, #cb333b);
          }
        }

        .bottom-left-C {
          margin-bottom: 20px;

          p {
            text-align: right;
            font-size: 26px;
            font-weight: 400;
            line-height: 38px;
            /* 146.154% */
            color: var(--LP-mid-gray, #6b6c6f);
          }
        }

        .bottom-left-flex {
          margin-left: 30px;
          width: 135px;

          div {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 10px;

            span {
              color: var(--LP-gray, #9ea0a5);
              text-align: right;
              font-size: 14px;
              font-weight: 500;
              line-height: 18px;
              /* 128.571% */
              text-transform: capitalize;
            }
          }
        }

        .bottom-left-D {
          display: flex;
          align-self: center;
          gap: 20px;
          margin-left: 25px;
          width: 195px;

          div {
            p {
              color: var(--LP-mid-gray, #6b6c6f);
              text-align: right;
              font-size: 18px;
              font-weight: 400;
              line-height: 38px;
              /* 211.111% */
            }

            span {
              color: var(--LP-gray, #9ea0a5);
              text-align: right;
              font-size: 12px;
              font-weight: 500;
              line-height: 18px;
              /* 150% */
              text-transform: capitalize;
            }

            div {
              display: flex;
              align-items: center;
              gap: 8px;
            }
          }
        }
      }
    }
  }
}

.net-income-cont {
  .net-income-head {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .income-head-left {
      display: flex;
      align-items: center;
      gap: 20px;

      div img {
        border-radius: 4px;
        background: var(--3-fa-2-f-7-links-informational-blue, #3fa2f7);
        padding: 12px;
      }

      .head-left-upper {
        display: flex;
        align-items: center;
        gap: 15px;

        div:nth-child(1) {
          height: 10px;
          width: 10px;
          border-radius: 2px;
          background: #00629b;
        }

        div:nth-child(2) {
          p {
            color: #3e3f42;
            font-size: 18px;
            font-weight: 500;
            line-height: 28px;
            /* 155.556% */
          }

          span {
            color: #9ea0a5;
            font-size: 18px;
            font-weight: 300;
            line-height: 28px;
          }
        }

        div:nth-child(3) {
          height: 10px;
          width: 10px;
          border-radius: 2px;
          background: #56ccf2;
        }

        div:nth-child(4) {
          display: flex;
          align-items: center;
          gap: 8px;

          p {
            color: #3e3f42;
            font-size: 18px;
            font-weight: 500;
            line-height: 28px;
            /* 155.556% */
          }

          span {
            color: #9ea0a5;
            font-size: 18px;
            font-weight: 300;
            line-height: 28px;
          }
        }
      }
    }
  }

  .waterfall-body {
    overflow: hidden;
    display: flex;
    align-items: flex-start;
    gap: 20px;
    padding: 30px;
  }
}

.cash-flow-body {
  .cash-flow-body-upper {
    display: flex;

    div {
      width: 180px;
      height: 90px;
      border-left: 1px solid #eaedf3;
      padding: 20px 0 0 30px;
    }

    h1 {
      color: #9ea0a5;
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
      /* 150% */
      text-transform: uppercase;
    }

    span {
      color: var(--LP-black, #3e3f42);
      font-size: 20px;
      font-weight: 500;
      line-height: normal;
    }
  }

  img {
    padding: 30px;
    border-top: 1px solid #eaedf3;
  }
}

.Slider-range {
  margin-top: 20px;
}

/*=============================================*/
/*                BODY RENT ROLL               */
/*=============================================*/
.rent-roll-container {
  width: 730px;
  border-radius: 4px;
  // border: 1px solid #eaedf3;
  background: #fff;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.04);
  margin-top: 30px;

  .rent-roll-head {
    height: 80px;
    padding: 0 30px;
    display: flex;
    align-items: center;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    justify-content: space-between;
    border: 1px solid #eaedf3;
    border-bottom: none;

    h1 {
      color: #3e3f42;
      font-size: 18px;
      font-weight: 500;
      line-height: 28px;
      /* 155.556% */
    }

    .rent-roll-right {
      display: flex;
      align-items: center;
      gap: 20px;

      div {
        width: 94px;
        padding: 8px 10px;
        display: flex;
        align-items: center;
        gap: 10px;
        fill: #d8dce6;
        border-radius: 4px;
        border: 1px solid #eaedf3;
        cursor: pointer;

        span {
          color: #3e3f42;
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
          /* 157.143% */
        }
      }
    }

    .uploadbtn {
      margin: 0;
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 8px 20px 8px 12px;

      span {
        margin-left: 0 !important;
      }
    }
  }

  .roll-doc {
    width: 100%;
    display: flex;
    gap: 0;
    background-color: transparent !important;
    box-shadow: none;
    border-radius: 4px;
    border: 1px dashed var(--purple-pantone-2665-c-71, rgba(125, 85, 199, 0.71));
    background: #fff;

    .upload-btn-wrapper {
      border: none !important;
    }
  }

  .table-btn {
    display: flex;
    padding: 3px 10px;
    align-items: center;
    border-radius: 4px;
    background: #b9b8bd;
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    /* 150% */
    text-transform: uppercase;
  }
}

.rent-roll-card-cont {
  display: flex;
  gap: 30px;

  .rent-roll-card {
    width: 223px;
    margin-top: 40px;
    border-radius: 4px;
    border: 1px solid #eaedf3;
    background: linear-gradient(0deg, rgba(240, 241, 245, 0.4) 0%, rgba(240, 241, 245, 0.4) 100%), linear-gradient(0deg, #fff 0%, #fff 100%), #fff;
    stroke-width: 1px;
    stroke: #eaedf3;
    filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.04));

    .roll-card-head {
      height: 74px;
      padding: 0 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 4px 4px 0px 0px;
      background: var(--ffffff-white, #fff);

      h1 {
        color: #3e3f42;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        /* 22.4px */
      }
    }

    #card-img {
      padding: 35px 30px 20px 30px;
    }
  }
}

.rent-roll-card-B {
  width: 350px;
  border-radius: 4px;
  border: 1px solid #eaedf3;
  background: #fff;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.04);
  margin-top: 30px;

  .roll-card-head {
    height: 74px;
    padding: 0 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px 4px 0px 0px;
    background: var(--ffffff-white, #fff);
    border-bottom: 1px solid#eaedf3;

    h1 {
      color: #3e3f42;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
      /* 22.4px */
    }
  }

  #card-img-B {
    padding: 30px;
  }
}

.leasing-trend-cont {
  width: 100%;
  fill: #fff;
  border: 1px solid #eaedf3;
  margin-top: 30px;
  stroke-width: 1px;
  stroke: #eaedf3;
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.04));

  .trend-head {
    height: 103px;
    padding: 0 30px;
    border-bottom: 1px solid #eaedf3;

    .trend-head-B {
      width: 100%;
      // height: 103px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 25px;

      > div {
        display: flex;
        align-items: center;
        gap: 15px;
      }

      h1 {
        color: #3e3f42;
        font-family: Roboto;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
        /* 155.556% */
      }

      p {
        margin: 0;
      }
    }

    .trend-switch {
      width: 86px;
      height: 38px;
      margin: 0;

      span {
        width: 38px;
        height: 33px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .trend-head-C {
      width: 100%;
      display: flex;
      align-items: center;
      margin-top: 15px;
      gap: 15px;

      > div {
        height: 22px;
        padding: 0 10px;
        display: flex;
        align-items: center;
        gap: 10px;
        border-radius: 4px;
        background: var(--fafafa-background-grey-3, #fafafa);
        cursor: pointer;
      }

      .trend-active {
        background: #eaedf3;
        box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.1) inset;
      }
    }
  }

  #leasing-img {
    padding: 30px 30px 20px 3px;
  }
}

/*=============================================*/
/*                BODY COMP DATA               */
/*=============================================*/
.comp-summary-container {
  width: 1110px;
  border-radius: 4px;
  border: 1px solid #eaedf3;
  background: #fff;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.04);
  margin-bottom: 30px;

  .comp-summary-head {
    height: 74px;
    fill: rgba(255, 255, 255, 0);
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #eaedf3;
    padding: 0 30px;

    h1 {
      color: #3e3f42;
      font-size: 18px;
      font-weight: 500;
      line-height: 28px;
      /* 155.556% */
    }

    > div {
      display: flex;
      align-items: center;
      gap: 10px;
      border-radius: 4px;
      padding: 8px 11px;
      border: 1px solid #d8dce6;
      cursor: pointer;
      box-shadow: 0px 1px 1px 0px rgba(22, 29, 37, 0.05), 0px 2px 0px 0px rgba(255, 255, 255, 0.05) inset;

      span {
        color: #3e3f42;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        /* 157.143% */
      }
    }
  }

  .comp-summary-body {
    > h1 {
      color: var(--Dark-Color, rgba(0, 0, 0, 0.87));
      font-size: 16px;
      font-weight: 500;
      line-height: 28px;
      /* 175% */
      margin: 30px 0 10px 30px;
    }

    .summary-body-card {
      display: flex;
      align-items: center;
      gap: 20px;
      // justify-content: space-between;
      margin-bottom: 30px;
      padding: 0 30px;

      > div {
        width: 150px;
        height: 90px;
        padding: 10px;
        gap: 2px;
        display: flex;
        flex-direction: column;
        border-radius: 4px;
        border: 1px solid var(--d-4-d-4-d-4-light-grey, #d4d4d4);

        h1 {
          color: #9ea0a5;
          font-size: 12px;
          font-weight: 500;
          line-height: 18px;
          /* 150% */
          text-transform: uppercase;
        }

        span:nth-child(2) {
          color: var(--LP-black, #3e3f42);
          font-size: 24px;
          font-weight: 500;
          line-height: normal;
        }

        > div span {
          color: var(--LP-black, #3e3f42);
          font-size: 24px;
          font-weight: 500;
          line-height: normal;
        }

        span:nth-child(3) {
          color: var(--LP-mid-gray, #6b6c6f);
          font-size: 12px;
          font-weight: 400;
          line-height: 22px;
          /* 183.333% */
        }
      }
    }
  }

  .comptable {
    thead {
      th {
        padding-left: 34px; // Adjust as needed
      }
    }

    tbody {
      td {
        padding: 10px 16px 8px 34px; // Adjust as needed
      }
    }
  }

  .uploadbtn {
    margin: 0;

    span {
      color: #fff !important;
      margin-left: 0 !important;
    }
  }

  .comp-doc {
    width: 100%;
    border-radius: 4px;
    border: 1px dashed var(--purple-pantone-2665-c-71, rgba(125, 85, 199, 0.71));
    background: linear-gradient(0deg, rgba(125, 85, 199, 0.08) 0%, rgba(125, 85, 199, 0.08) 100%), #fff;
  }
}

.comp-card-cont {
  display: flex;
  gap: 30px;

  .rent-roll-card {
    width: 254px;
    height: 384px;
    margin-top: 40px;
    border-radius: 4px;
    border: 1px solid #eaedf3;
    background: linear-gradient(0deg, rgba(240, 241, 245, 0.4) 0%, rgba(240, 241, 245, 0.4) 100%), linear-gradient(0deg, #fff 0%, #fff 100%), #fff;
    stroke-width: 1px;
    stroke: #eaedf3;
    filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.04));

    .roll-card-head {
      height: 74px;
      padding: 0 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 4px 4px 0px 0px;
      background: var(--ffffff-white, #fff);

      h1 {
        color: #3e3f42;
        font-size: 16px;
        font-weight: 500;
        line-height: 140%;
        /* 22.4px */
      }
    }

    #card-img {
      padding: 35px 30px 20px 30px;
    }
  }

  .comp-card {
    width: 542px;
    height: 384px;
    margin-top: 40px;
    border-radius: 4px;
    border: 1px solid #eaedf3;
    background: linear-gradient(0deg, rgba(240, 241, 245, 0.4) 0%, rgba(240, 241, 245, 0.4) 100%), linear-gradient(0deg, #fff 0%, #fff 100%), #fff;
    stroke-width: 1px;
    stroke: #eaedf3;
    filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.04));

    .comp-card-head {
      height: 74px;
      padding: 0 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 4px 4px 0px 0px;
      background: var(--ffffff-white, #fff);

      h1 {
        color: #3e3f42;
        font-size: 18px;
        font-weight: 500;
        line-height: 140%;
        /* 22.4px */
      }
    }

    #card-img {
      padding: 35px 30px 20px 30px;
    }
  }
}

// .........................for tabination sliderr ......................
.economics-body-left {
  margin: 25px 0;
  padding: 0 20px;
  .dateDiv {
    margin-bottom: 40px;
    .inputBoxMain {
      max-width: 244px;
    }
    .calenderIcon {
      position: absolute;
      top: 21%;
      left: 5px;
    }
  }
  h2 {
    color: #3e3f42;
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 155.556% */
    margin-left: 14px;
    margin-top: 24px;
  }

  .tabination_slider {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 350px;
    height: 54px;
    flex-shrink: 0;
    padding: 0 20px;
    background-color: #eaedf3;
  }

  .sildermain {
    width: 100%;
    display: flex;
    gap: 20px;
    align-items: center;
  }

  .slider_container {
    // width: 80%;
  }

  .dropDownOuterCon {
    padding: 0 15px;
  }

  .priceBtn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    // margin-bottom: 25px;
  }

  .slider_name {
    color: var(--LP-black, #3e3f42) !important;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    opacity: 1;
    line-height: 150%;
    /* 21px */
  }
}

.compdata_lower {
  .compare_nav {
    span {
      font-size: 16px !important;
    }
  }
  .chartbox {
    margin-top: 50px;
    height: 374px;
    width: 100%;
    border: 1px solid #eaedf3;
    filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.04));

    .chart_wrapper {
      padding: 20px;

      img {
        margin-top: -90px;
      }
    }
  }

  .chartbox_header {
    padding: 20px;
    height: 70px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid #eaedf3;

    p {
      color: #3e3f42;
      font-family: Roboto;
      font-size: 18px;
      font-weight: 500;
      line-height: 28px;
    }
  }
}

.similar_wrapper {
  margin-top: 40px;

  > div {
    display: flex;
    gap: 30px;
    margin-top: 25px;
  }

  p {
    color: #3e3f42;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
  }

  .comparision_box {
    width: 350px;
    .sort {
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      span {
        color: #9ea0a5;
      }
    }

    .comparision_box_row {
      display: flex;
      align-items: center;
      gap: 20px;
      border-bottom: 1px solid #eaedf3;
      padding-bottom: 20px;

      span {
        color: #9ea0a5;
        font-family: Roboto;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
      }

      img {
        width: 100px;
        height: 88px;
        border-radius: 4px;
      }

      .right {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 100%;

        .right_upper {
          display: flex;
          flex-direction: column;
          gap: 0;
        }

        .right_lower {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;

          img {
            height: 20px;
            width: 20px;
            margin-left: 10px;
          }
        }
      }
    }
  }
}
// .apexcharts-legend {
//   display: flex !important;
//   justify-content: space-between !important;
//   flex-direction: column !important;
//   height: 30%;
// }
.custom-legend .apexcharts-legend-series .apexcharts-legend-marker {
  border-radius: 0 !important; /* Make the marker square */
}

.acquisition_dealEconomics_container {
  .economics-header,
  .acquition_dealEconomics_contentWrapper {
    max-width: 80vw;
  }

  .economics-header {
    width: 100%;
    padding: 0 20px;
    justify-content: space-between;
  }

  .acquition_dealEconomics_contentWrapper {
    padding: 0 20px;

    .economics-body {
      grid-template-columns: 59% 40%;

      .economics-body-right,
      .economics-body-left {
        padding: 0;
      }
    }
  }
}
