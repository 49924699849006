.token_container {
  width: 730px;
  // height: 510px;
  flex-shrink: 0;
  box-shadow: 0px 0px 0px 1px #eaedf3 inset;
  display: flex;
  flex-direction: column;
  gap: 50px;
  .heading {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 20px;
    box-shadow: 0px 0px 0px 1px #eaedf3 inset;

    h1 {
      color: #3e3f42;
      font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px; /* 155.556% */
    }
    button {
      display: inline-flex;
      padding: 8px 20px;
      align-items: center;
      gap: 12px;
      border-radius: 4px;
      border: 1px solid var(--purple-pantone-2665-c-71, rgba(125, 85, 199, 0.71));
      background: linear-gradient(0deg, rgba(125, 85, 199, 0.3) 0%, rgba(255, 255, 255, 0) 107.78%), #7d55c7;
      background-blend-mode: multiply, normal;
      box-shadow: 0px 1px 1px 0px rgba(19, 31, 21, 0.1), 0px 2px 0px 0px rgba(255, 255, 255, 0.06) inset;
      color: #fff;
      text-align: center;
      font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 157.143% */
      cursor: pointer;
    }
  }
  .generated_token {
    color: var(--purple-pantone-2665-c, #7d55c7);
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 111.111% */
    text-align: center;
    // margin-top: 20px;
  }
  .action_header {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;

    div {
      display: flex;
      align-items: center;
      gap: 5px;

      p {
        color: #3e3f42;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 157.143% */
        cursor: pointer;
        border-right: 1px solid rgb(231, 224, 224);
        padding: 0px 10px 0px 10px;
      }
    }
  }
}
