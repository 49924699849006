.assets_list-container {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../../../assets/Openai/SeletectAssets.png");
    background-size: cover;
    background-position: center;
  }
  
  .assets_list-content {
    width: 100%;
    max-width: 550px;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    background-color: rgba(255, 255, 255, 0.8);
    font-family: Arial, sans-serif;
    text-align: center;
  }
  
  .assets_list-title {
    font-size: 1.5rem;
    margin-bottom: 15px;
    color: #333;
  }
  
  .assets_list-dropdown-container {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }
  
  .assets_list-dropdown-button {
    width: 100%;
    padding: 12px 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #ffffff;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    font-size: 1rem;
    font-weight: 400;
    text-align: left;
    transition: background-color 0.3s ease;
  }
  
  .assets_list-dropdown-button:hover {
    background-color: #f0f0f0;
  }
  
  .assets_list-dropdown-arrow {
    font-size: 1.2rem;
    color: #888;
  }
  
  .assets_list-dropdown-content {
    position: absolute;
    top: calc(100% + 5px);
    left: 0;
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    transition: max-height 0.4s ease, opacity 0.3s ease;
    z-index: 10;
  }
  
  .assets_list-dropdown-content.open {
    max-height: 220px;
    opacity: 1;
  }
  
  .assets_list-dropdown-item {
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .assets_list-dropdown-item:hover {
    background-color: #f0f0f0;
  }
  
  .assets_list-item-image {
    width: 40px;
    height: 40px;
    border-radius: 5px;
    margin-right: 10px;
  }
  
  .assets_list-item-title {
    font-size: 1rem;
    font-weight: bold;
    margin: 0;
    color: #333;
  }
  
  .assets_list-item-description {
    font-size: 0.875rem;
    color: #666;
    margin: 0;
  }
  
  .assets_list-query-button {
    width: 100%;
    padding: 12px;
    font-size: 1rem;
    border: 1px solid #ddd;
    border-radius: 10px;
    background-color: #f5f5f5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
  }
  
  .assets_list-query-button:hover {
    background-color: #e8e8e8;
  }
  
  .assets_list-query-arrow {
    font-size: 1.2rem;
    color: #6a0dad;
  }
  