.DealTeam-Conatiner {
  border: 1px solid #eaedf3;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.15);
  background: rgba(125, 85, 199, 0.08);
}
.DealTeam-Conatiner-data {
  margin: 10px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
}
.DealTeam-Data {
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.15);
}
.DealTeam-container-image {
  display: flex;
  gap: 20px;
}
.DealTeam-container-image-data {
  display: flex;
  gap: 20px;
}
.DealTeam-Data {
  border: 1px solid #eaedf3;
  padding: 10px;
  width: 419px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.DealTeam-Data p {
  width: 50%;
}

// Dropdown
.DealTeam-wrapper {
  h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }

  .EquityAdvisory {
    border: 1px solid #eaedf3;

    .EquityAdvisory-accordion {
      cursor: pointer;
      background-color: white;
      width: 700px;
      padding: 15px;
      padding-top: 20px;
      padding-bottom: 20px;
      border: none;
      text-align: left;
      outline: none;

      i {
        float: right;
      }

      &:hover {
      }

      &.active {
        i {
          transform: rotate(180deg);
        }
      }
    }

    .EquityAdvisory-pannel {
      padding: 0 15px;
      display: none;
      background-color: white;
      overflow: hidden;
      transition: max-height 0.2s ease-out;

      &.active {
        display: block;
        max-height: 1000px;
      }

      p {
        margin-top: 0;
      }
    }
  }
}

// flex-data
.EquityAdvisory-container {
  display: flex;
}
.EquityAdvisory-container-data {
  display: flex;
}
.linetag {
  height: 5px;
  background-color: #7d55c7;
}
.EquityAdvisory-flex {
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: center;
}
.EquityAdvisory-flex p {
  color: var(--Black, #3e3f42);
  leading-trim: both;
  text-edge: cap;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}
.EquityAdvisory-Data button {
  display: flex;
  width: 375px;
  height: 38px;
  padding: 0px 146px 0px 134px;
  align-items: center;
  border: none;
  color: white;
  font-size: 14px;
  flex-shrink: 0;
  border-radius: 4px;
  background: var(--Primary-Purple-500, #7d55c7);
}

.DealTeam-Deal {
  padding: 0;
  margin: 0;
  height: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  border: none;
  color: black;
  background-color: white;
  border-radius: 4px;
  background: rgba(125, 85, 199, 0.07);
}

.DealTeam-Add {
  padding: 0;
  float: right;
  margin: 0;
  height: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  border: none;
  color: blue;
  float: right;
  width: 100px;
  background-color: white;
  border-radius: 4px;
  border: 1px solid var(--Primary-Purple-50, #eaedf3);
  background: #fff;
}

// flex-data

.DealTeam-flex {
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: center;
}
.DealTeam-flex p {
  color: var(--Black, #3e3f42);
  leading-trim: both;
  text-edge: cap;
  font-family: Roboto;
  font-size: 14px;
  width: 250px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}
.DealTeam-Data button {
  display: flex;
  width: 375px;
  height: 38px;
  padding: 0px 146px 0px 134px;
  align-items: center;
  border: none;
  color: white;
  font-size: 14px;
  flex-shrink: 0;
  border-radius: 4px;
  background: var(--Primary-Purple-500, #7d55c7);
}

.DealTeam-image {
  background-color: #efebf8;
  width: 100px;
  border-radius: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.DealTeam-divconatiner {
  justify-content: center;
  display: flex;
  align-items: center;
  height: 150px;
}
.DealTeam-Tender {
  padding: 0;
  margin: 0;
  height: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  border: none;
  color: black;
  background-color: white;
  border-radius: 4px;
  border: 1px solid var(--Primary-Purple-50, #eaedf3);
  background: #fff;
}
.DealTeam-Add {
  padding: 0;
  margin: 0;
  height: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  border: none;
  width: 100px;
  float: right;
  color: blue;
  background-color: white;
  border-radius: 4px;
  border: 1px solid var(--Primary-Purple-50, #eaedf3);
  background: #fff;
}

.CaptableStake-Filter {
  padding: 0;
  margin: 0;
  height: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  border: none;
  color: white;
  width: 100%;
  background-color: white;
  border-radius: 4px;
  background: var(--Primary-Purple-500, #7d55c7);
}
