.add-deal-filter-wrapper-main {
  width: 200px;
  position: absolute;
  top: 143px;
  right: 85px;

  border-radius: 4px;
  border: 1px solid #d8dce6;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.08);

  .divider {
    border: 1px solid #9ea0a5;
    margin: 0;
    padding: 0;
  }

  div {
    padding: 9px 10px;
    display: flex;
    gap: 12px;
    cursor: pointer;
    transition: background 0.3s ease;

    &:hover {
      background: #f5f5f5;
    }

    span {
      color: #3e3f42;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
    }
  }
}
