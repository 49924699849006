.onBoard_container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;

  .heading {
    h1 {
      color: var(--Neutral-N-250, #475467);
      font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: capitalize;
    }
  }
  // .tabination_header {
  //   height: 70px;
  //   flex-shrink: 0;
  //   display: flex;
  //   align-items: center;
  //   margin: auto;
  //   gap: 35px;
  //   margin: 20px;
  //   background: #fff;
  //   box-shadow: 0px 1px 0px 0px #eaedf3;

  //   p {
  //     color: var(--LP-mid-grey-_lib, #6b6c6f);
  //     font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  //     font-size: 13px;
  //     font-style: normal;
  //     font-weight: 400;
  //     line-height: 24px;
  //     text-transform: capitalize;
  //     padding: 20px 0px 20px 0px;
  //     text-align: center;
  //     border-bottom: 2px solid transparent;
  //     cursor: pointer;
  //     display: flex;
  //     align-items: center;
  //     gap: 10px;
  //     width: max-content;
  //   }
  //   .activeTab {
  //     color: #7d55c7;
  //     border-bottom: 2px solid #7d55c7;
  //     cursor: pointer;
  //   }
  // }
  .tabination_header {
    height: 70px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    margin: auto;
    gap: 20px;
    margin: 10px;
    background: #fff;
    box-shadow: 0px 1px 0px 0px #eaedf3;
    @media (max-width: 500px) {
      .tabination_header {
        height: auto;
        flex-wrap: wrap;
      }
    }
  }

  .tabination_header p {
    color: var(--LP-mid-grey-_lib, #6b6c6f);
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    text-transform: capitalize;
    padding: 20px 0px 20px 0px;
    text-align: center;
    border-bottom: 2px solid transparent;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
    width: max-content;
  }

  .tabination_header .activeTab {
    color: #7d55c7;
    border-bottom: 2px solid #7d55c7;
    cursor: pointer;
  }

  @media (max-width: 768px) {
    .tabination_header {
      height: auto;
      flex-wrap: wrap;
    }

    .tabination_header p {
      font-size: 12px;
      padding: 10px 0;
    }
  }



  .content {
    width: 887px;
    flex-shrink: 0;
    border-radius: 40px;

    // padding: 40px 20px 40px 20px;
  }

  .BlockchainOnboard-container {
    display: flex;
    flex-direction: column;
    gap: 50px;
    align-items: center;
    padding: 20px;
  
    .basic_info, .documents {
      width: 100%;
      max-width: 800px;
      padding: 20px;
      box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.33);
      border-radius: 10px;
      background-color: #fff;
    }
  
    .indentity_information, .documents {
      display: flex;
      flex-direction: column;
      gap: 30px;
  
      h1 {
        color: #475467;
        font-family: 'Segoe UI', sans-serif;
        font-size: 16px;
        font-weight: 600;
        text-transform: capitalize;
      }
  
      label {
        color: #667086;
        font-family: 'Segoe UI', sans-serif;
        font-size: 12px;
        font-weight: 400;
        text-transform: capitalize;
      }
  
      .form_container {
        display: flex;
        flex-direction: column;
        gap: 20px;
      }
  
      .form_row {
        display: flex;
        flex-direction: row;
        gap: 20px;
  
        @media (max-width: 768px) {
          flex-direction: column;
        }
      }
  
      .input_group {
        display: flex;
        flex-direction: column;
        gap: 6px;
        width: 100%;
  
        input, select {
          width: 100%;
          padding: 10px 15px;
          border: none;
          outline: none;
          box-shadow: 0px 0px 0px 1px #80808042;
          border-radius: 5px;
  
          &::placeholder {
            color: #98a2b3;
            font-family: 'Segoe UI', sans-serif;
            font-size: 14px;
            text-transform: capitalize;
          }
        }
      }
  
      .dob_input {
        display: flex;
        align-items: center;
        width: 100%;
        box-shadow: 0px 0px 0px 1px #80808042;
        border-radius: 5px;
        padding-left: 15px;
  
        input {
          width: 100%;
          border: none;
          outline: none;
          padding: 10px 15px;
        }
      }
  
      .select_country {
        display: flex;
        align-items: center;
        gap: 10px;
        width: 100%;
        box-shadow: 0px 0px 0px 1px #80808042;
        border-radius: 5px;
        padding: 10px;
  
        select {
          width: 100%;
          border: none;
          outline: none;
        }
      }
    }
  
    .documents {
      .video_upload_box {
        display: flex;
        flex-direction: column;
        gap: 10px;
  
        p {
          color: #475467;
          font-family: 'Segoe UI', sans-serif;
          font-size: 16px;
          font-weight: 600;
          text-transform: capitalize;
        }
  
        .video_input {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 10px;
          padding: 50px 20px;
          border: 2px dashed #eaecf0;
          border-radius: 8px;
          background-color: #f9fafb;
  
          img {
            width: 40px;
            height: 40px;
          }
  
          p {
            color: #667085;
            font-family: 'Segoe UI', sans-serif;
            font-size: 14px;
            font-weight: 500;
          }
  
          span {
            color: #98a2b3;
            font-family: 'Segoe UI', sans-serif;
            font-size: 14px;
            font-weight: 400;
          }
  
          .choose_btn {
            background-color: #ffffff;
            box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
            border: 1px solid #d0d5dd;
            border-radius: 8px;
            padding: 10px 16px;
            color: #344054;
            font-family: 'Segoe UI', sans-serif;
            font-size: 14px;
            font-weight: 600;
            cursor: pointer;
            text-align: center;
          }
        }
      }
    }
  }
  
}

.next_back_btns {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 100px;
  //   width: 80%;
  margin-top: 30px;

  .prev_btn {
    display: inline-flex;
    padding: 8px 42px;
    align-items: flex-start;
    gap: 10px;
    border-radius: 40px;
    background: var(--Neutral-N-200, #f2f4f7);
    border: none;
    outline: none;
    color: var(--Neutral-N-350, #667086);
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    box-shadow: 0px 0px 0px 1px rgb(128 128 128 / 26%);
    cursor: pointer;
  }
  .next_btn {
    display: inline-flex;
    padding: 8px 42px;
    align-items: flex-start;
    gap: 10px;
    border-radius: 40px;
    background: var(--Gradient-G-50, linear-gradient(52deg, #530de9 -21.09%, #996ff0 100.58%));
    border: none;
    outline: none;
    color: var(--Neutral-N-50, #fff);
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    cursor: pointer;
  }
}

.tokenize_container {
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 783px;
  padding: 30px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.33);
  border-radius: 10px;
  h1 {
    color: var(--Neutral-N-250, #475467);
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
  }
  .tokenize {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 20px;

    .tokenize_box {
      display: flex;
      width: 344px;
      height: 200px;
      padding: 30px 33px 30px 24px;
      align-items: flex-start;
      gap: 31px;
      flex-shrink: 0;
      border-radius: 30px;
      border: 0.75px solid var(--Neutral-N-200, #f2f4f7);
      background: var(--Neutral-N-150, #f2f4f7);
      h1 {
        color: var(--Neutral-N-250, #475467);
        font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: capitalize;
      }
      p {
        color: var(--Neutral-N-350, #667086);
        font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 156.8%; /* 15.68px */
        text-transform: capitalize;
      }
    }
    .selectedBox {
      // border-radius: 30px;
      border: 1px solid var(--Gradient-G-100, #530de9);
      background-color: white;

      img {
        fill: rgb(2, 2, 2);
      }
    }
  }
}
.create-token_container {
  width: 783px;

  .content {
    width: 783px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    label {
      color: var(--Neutral-N-350, #667086);
      font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: capitalize;
    }
  }

  .tabination {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    margin: auto;
    gap: 35px;
    background: #fff;

    p {
      color: var(--LP-mid-grey-_lib, #6b6c6f);
      font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      text-transform: capitalize;
      padding: 20px 0px 20px 0px;
      text-align: center;
      border-bottom: 2px solid transparent;
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 10px;
    }
    .activeTab {
      color: #7d55c7;
      border-bottom: 2px solid #7d55c7;
      cursor: pointer;
    }
  }
  .logo_and_symbol {
    display: flex;
    gap: 50px;

    .upload_box {
      display: flex;
      // width: 344px;
      padding: 30px;
      justify-content: center;
      gap: 16px;
      flex-shrink: 0;
      border-radius: 30px;
      border: 0.75px solid var(--Neutral-N-200, #f2f4f7);
      background: var(--Neutral-N-100, #f4f6f9);

      p {
        color: var(--Neutral-N-350, #667086);
        font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 156.8%; /* 15.68px */
        text-transform: capitalize;
      }

      h1 {
        color: var(--Neutral-N-250, #475467);
        font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
      }
      button {
        display: flex;
        padding: 8px 42px;
        align-items: flex-start;
        gap: 10px;
        border-radius: 40px;
        background: var(--Gradient-G-50, linear-gradient(52deg, #530de9 -21.09%, #996ff0 100.58%));
        color: var(--Neutral-N-50, #fff);
        font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: capitalize;
        border: none;
        outline: none;
      }
    }
    .selected_box {
      // border-radius: 30px;
      border: 1px solid var(--Gradient-G-100, #530de9);
      background: #fff;
    }
  }

  .form_container {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .token_name,
    .numberOfToken {
      display: flex;
      flex-direction: column;
      gap: 10px;

      input {
        // border-radius: 28px;
        border: none;
        display: inline-flex;
        height: 40px;
        padding: 10px 584px 9px 25px;
        align-items: center;
        flex-shrink: 0;
        box-shadow: 0px 0px 0px 1px rgba(128, 128, 128, 0.2588235294);
        outline: none;
      }
    }
    .token_holders {
      input {
        display: flex;
        width: 516px;
        height: 40px;
        padding: 10px 379px 9px 25px;
        align-items: center;
        flex-shrink: 0;
        // border-radius: 28px;
        border: none;
        box-shadow: 0px 0px 0px 1px rgba(128, 128, 128, 0.2588235294);
        margin-bottom: 20px;
        margin-top: 10px;
      }
      select {
        // border-radius: 28px;
        box-shadow: 0px 0px 0px 1px rgba(128, 128, 128, 0.2588235294);
        display: inline-flex;
        height: 40px;
        padding: 10px 21.281px 9px 25px;
        justify-content: center;
        align-items: flex-start;
        gap: 102.719px;
        flex-shrink: 0;
        margin-bottom: 20px;
        margin-top: 10px;
        border: none;
        outline: none;
      }
    }
    .add_more {
      button {
        display: flex;
        width: 516px;
        padding: 12px 100px 12px 25px;
        align-items: center;
        border-radius: 28px;
        background: var(--Gradient-G-50, linear-gradient(52deg, #530de9 -21.09%, #996ff0 100.58%));
        border: none;
        outline: none;
        color: var(--Neutral-N-50, #fff);
        font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-transform: capitalize;
      }
    }
  }
  .compliance_container {
    display: flex;
    flex-direction: column;
    gap: 30px;
    .complience_boxes {
      display: flex;
      align-items: center;
      gap: 40px;
      .compliance_box {
        display: flex;
        width: 219px;
        height: 140px;
        padding: 16px 24px;
        flex-direction: column;

        gap: 10px;
        border-radius: 30px;
        border: 0.75px solid var(--Neutral-N-200, #f2f4f7);
        background: var(--Neutral-N-150, #f2f4f7);

        .img {
          text-align: end;
        }
        .descri {
          width: 75%;
        }
        p {
          font-size: 14px;
        }
      }
      .selected_box {
        // border-radius: 30px;
        border: 1px solid var(--Gradient-G-100, #530de9);
        background: #fff;
      }
    }
    .contract_name {
      display: flex;
      flex-direction: column;
      gap: 6px;
      h1 {
        color: var(--Neutral-N-250, #475467);
        font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
      }
      input {
        display: inline-flex;
        height: 40px;
        padding: 12px 412px 7px 25px;
        align-items: center;
        flex-shrink: 0;
        // border-radius: 28px;
        box-shadow: 0px 0px 0px 1px rgba(128, 128, 128, 0.2588235294);
        border: none;
        outline: none;

        &::placeholder {
          color: var(--Neutral-N-300, #98a2b3);
          font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-transform: capitalize;
        }
      }
    }
    .feature {
      display: flex;
      flex-direction: column;
      gap: 10px;
      align-items: start;
      h1 {
        color: var(--Neutral-N-250, #475467);
        font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
      }
      p {
        color: var(--Neutral-N-350, #667086);
        font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 156.8%; /* 18.816px */
        text-transform: capitalize;
      }
      select {
        display: inline-flex;
        padding: 11px 24px;
        align-items: flex-start;
        gap: 48px;
        // border-radius: 30px;
        border: none;
        outline: none;
        box-shadow: 0px 0px 0px 1px rgba(128, 128, 128, 0.2588235294);
      }
    }
  }

  .execute_code {
    display: flex;
    flex-direction: column;
    gap: 30px;

    label {
      color: var(--Neutral-N-300, #98a2b3);
      font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: capitalize;
    }
    .token {
      display: inline-flex;
      padding: 24px 48px;
      width: 350px;
      align-items: center;
      gap: 28px;
      border-radius: 30px;
      box-shadow: 0px 0px 0px 1px rgba(128, 128, 128, 0.2588235294);

      h1 {
        color: var(--Neutral-N-250, #475467);
        font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
      }
    }
    .unit_in_circulation {
      display: flex;
      flex-direction: column;
      gap: 6px;
      input {
        display: inline-flex;
        height: 40px;
        padding: 10px 525px 9px 25px;
        align-items: center;
        flex-shrink: 0;
        // border-radius: 28px;
        border: none;
        box-shadow: 0px 0px 0px 1px rgba(128, 128, 128, 0.2588235294);
        outline: none;
      }
      label {
        color: var(--Neutral-N-300, #98a2b3);
        font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: capitalize;
      }
    }
    .cost_usd {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      gap: 40px;

      .cost {
        background-color: red($color: #000000);
        width: 40%;
        display: flex;
        flex-direction: column;
        gap: 6px;
        select {
          display: inline-flex;
          padding: 8px 16px;
          align-items: center;
          gap: 187px;
          // border-radius: 28px;
          box-shadow: 0px 0px 0px 1px rgba(128, 128, 128, 0.2588235294);
          border: none;
          outline: none;
        }
      }
      .usd {
        width: 60%;
        display: flex;
        flex-direction: column;
        gap: 6px;
        select {
          display: inline-flex;
          padding: 8px 16px;
          align-items: center;
          gap: 187px;
          // border-radius: 28px;
          box-shadow: 0px 0px 0px 1px rgba(128, 128, 128, 0.2588235294);
          border: none;
          outline: none;
        }
      }
    }
    .token_address {
      display: flex;
      flex-direction: column;
      gap: 6px;

      .token_input {
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        gap: 376.913px;
        flex-shrink: 0;
        // border-radius: 28px;
        border: 1px solid var(--Gradient-G-100, #530de9);
        border: none;
        outline: none;
        box-shadow: 0px 0px 0px 1px rgba(128, 128, 128, 0.2588235294);
        padding-right: 10px;

        input {
          color: var(--Neutral-N-250, #475467);
          font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          text-transform: capitalize;
          padding: 10px 19.053px 9px 25px;
          height: 40px;
          // border-radius: 28px;
          border: none;
          outline: none;
        }
      }
    }
    .users {
      display: flex;
      align-items: center;
      gap: 25px;
      width: 760px;
      overflow: scroll;

      .user_box {
        border-radius: 30px;
        border: 0.75px solid var(--Neutral-N-200, #f2f4f7);
        background: var(--Gradient-G-150, linear-gradient(52deg, rgba(83, 13, 233, 0.1) -21.09%, rgba(153, 111, 240, 0.1) 100.58%));
        width: 218px;
        height: 140px;
        flex-shrink: 0;
        padding: 20px;

        .img {
          text-align: end;
        }
        p {
          color: var(--Neutral-N-250, #475467);
          font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 142%; /* 19.88px */
          text-transform: capitalize;
        }
        span {
          color: var(--Neutral-N-300, #98a2b3);
          font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-transform: capitalize;
        }
      }
    }
    .code {
      width: 717px;
      height: 447px;
      flex-shrink: 0;
      border-radius: 28px;
      display: flex;
      flex-direction: column;
      gap: 30px;

      .heading {
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
          color: var(--Neutral-N-300, #98a2b3);
          font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 156.8%; /* 18.816px */
          text-transform: capitalize;
        }
        h1 {
          color: var(--Neutral-N-250, #475467);
          font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          text-transform: capitalize;
        }

        button {
          color: var(--Neutral-N-50, #fff);
          font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          text-transform: capitalize;
          border-radius: 40px;
          background: var(--Gradient-G-50, linear-gradient(52deg, #530de9 -21.09%, #996ff0 100.58%));
          display: inline-flex;
          padding: 8px 42px;
          align-items: flex-start;
          gap: 10px;
          border: none;
          outline: none;
        }
      }
      .code_img {
        width: 622px;
        height: 312px;
        flex-shrink: 0;
        box-shadow: 0px 0px 0px 1px rgba(128, 128, 128, 0.2588235294);
        padding: 20px;
        border-radius: 20px;
      }
    }
  }
}

.Accreditation_container {
  .acc_content {
    width: 720px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    gap: 30px;

    .heading {
      display: flex;
      flex-direction: column;
      align-items: start;
      gap: 6px;

      img {
        width: 21.408px;
        height: 31px;
        flex-shrink: 0;
      }
      p {
        color: var(--Neutral-N-250, #475467);
        font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
      }
    }
    .sub_heading {
      display: flex;
      flex-direction: column;
      gap: 20px;
      h1 {
        display: flex;
        width: 719px;
        height: 40px;
        padding: 10px 127px 9px 25px;
        align-items: center;
        flex-shrink: 0;
        border-radius: 28px;
        background: var(--Gradient-G-150, linear-gradient(52deg, rgba(83, 13, 233, 0.1) -21.09%, rgba(153, 111, 240, 0.1) 100.58%));
        color: var(--Neutral-N-250, #475467);
        font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      p {
        color: var(--Neutral-N-350, #667086);
        font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 223.3%; /* 26.796px */
        text-transform: capitalize;
      }
      .agree_notAgree {
        width: 720px;
        padding: 30px;
        display: flex;
        gap: 10px;
        border-radius: 20px;
        border: 0.75px solid var(--Neutral-N-150, #f2f4f7);
        background: var(--Neutral-N-150, #f2f4f7);
        transition: 0.4s;

        h2 {
          color: var(--Neutral-N-250, #475467);
          font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
        p {
          color: var(--Neutral-N-350, #667086);
          font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 161.8%; /* 19.416px */
          text-transform: capitalize;
        }
      }
      .selectedBox {
        border: 1px solid var(--Gradient-G-100, #530de9);
        background: #fff;
      }
    }
  }
}

.payment_container {
  display: flex;
  flex-direction: column;
  gap: 40px;

  .option_container {
    display: flex;
    width: 799px;
    padding: 38px 32px 31px 31px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 32px;
    border-radius: 40px;
    background: var(--Neutral-N-50, #fff);
    box-shadow: 0px 0px 0px 1px rgba(128, 128, 128, 0.2588235294);
    h1 {
      color: var(--Neutral-N-250, #475467);
      font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: capitalize;
    }

    .payment_option {
      display: flex;
      align-items: center;
      gap: 30px;
      .option {
        width: 218px;
        height: 113px;
        flex-shrink: 0;
        border-radius: 30px;
        border: 0.75px solid var(--Neutral-N-150, #f2f4f7);
        background: var(--Neutral-N-150, #f2f4f7);
        padding: 20px;

        .logo {
          text-align: end;
        }

        h1 {
          color: var(--Neutral-N-250, #475467);
          font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 142%; /* 22.72px */
          text-transform: capitalize;
        }
        p {
          color: var(--Neutral-N-300, #98a2b3);
          font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 156.8%; /* 18.816px */
        }
      }
      .selected_option {
        // border-radius: 30px;
        border: 1px solid var(--Gradient-G-100, #530de9);
        background-color: #fff;
      }
    }
  }
  .convert_amount {
    width: 799px;
    height: 395px;
    flex-shrink: 0;
    border-radius: 40px;
    background: var(--Neutral-N-50, #fff);
    display: flex;
    flex-direction: column;
    gap: 30px;
    box-shadow: 0px 0px 0px 1px rgba(128, 128, 128, 0.2588235294);
    padding: 30px;
    h2 {
      color: var(--Neutral-N-250, #475467);
      font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: capitalize;
    }
    .pay_receive {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 30px;
      width: 100%;
      select {
        padding: 17px 69px 17px 20px;
        align-items: center;
        gap: 32px;
        flex-shrink: 0;
        // border-radius: 30px;
        box-shadow: 0px 0px 0px 1px rgba(128, 128, 128, 0.2588235294);
        border: none;
        outline: none;
        width: 300px;
      }
      div {
        display: flex;
        flex-direction: column;
        gap: 6px;
      }
      label {
        color: var(--Neutral-N-250, #475467);
        font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }
    .info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      p {
        color: var(--Neutral-N-300, #98a2b3);
        font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 164.8%;
        text-transform: capitalize;
        width: 344px;
      }
      span {
        color: var(--Neutral-N-300, #98a2b3);
        font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 164.8%; /* 19.776px */
        text-transform: capitalize;
      }
    }
    button {
      display: flex;
      padding: 12px;
      align-items: center;
      gap: 10px;
      border-radius: 28px;
      background: var(--Gradient-G-50, linear-gradient(52deg, #530de9 -21.09%, #996ff0 100.58%));
      width: 150px;
      justify-content: center;
      color: white;
      border: none;
      outline: none;
      margin: auto;
    }
  }
}

@media (max-width: 900px) {
  .deals-room-left-container {
    display: none;
  }

  .deals-room .BlockchainOnboard-hamburger {
    display: block;
    cursor: pointer;
    padding: 10px;
    position: fixed;
    top: 80px;
    left: 20px;
    z-index: 11111111;
  }

  .deals-room .BlockchainOnboard-hamburger span {
    display: block;
    width: 25px;
    height: 3px;
    background-color: #333;
    margin-bottom: 5px;
    z-index: 5;
  }

  .deals-room-left-container.open {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 1111;
    overflow-y: auto;
    background-color: white;
    margin-top: 71px;
  }
}

@media screen and (max-width:  740px) {
  .onBoard_container {
    margin-left: -83px;
    margin-top: 25px;
  }
}
@media screen and (max-width:  740px) {
  .BlockchainOnboard-container {
    width: 70%;
  }
}
@media screen and (max-width:  640px) {
  .BlockchainOnboard-container {
    width: 60%;
  }
}
@media screen and (max-width:  540px) {
  .BlockchainOnboard-container {
    width: 50%;
  }
}
