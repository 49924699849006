.search-container {
  position: relative;
  //   width: 300px;
  // border: 1px solid #e2e5ed;
  border-radius: 5px;

  .search-input {
    width: 100%;
    height: 100%;
    padding-right: 30px;
    // padding-left: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
  }
  ::placeholder {
    font-family: Roboto;
    color: #9ea0a5;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
  }

  .search-icon {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}

.custom-dropdown {
  position: relative;
  font-family: Roboto;

  .dropdown-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
    height: 100%;

    .sort-placeholder {
      font-family: Roboto;
      color: #9ea0a5;
      font-weight: 400;
      font-size: 14px;
    }

    .image-icon {
      margin: 0 8px;
      width: 21px;
      height: 20px;
    }

    .sort-label {
      font-family: Roboto;
      font-weight: 400;
      font-size: 14px;
      color: #3e3f42;
      margin-left: 8px;
    }
    .dropdown-arrow {
      font-size: 12px;
      margin-left: 8px;
    }
    .dropdown-options {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      border: 1px solid #ccc;
      border-top: none;
      border-radius: 0 0 4px 4px;
      background-color: #fff;
      display: none;

      .option {
        padding: 8px;
        cursor: pointer;
      }
    }
  }
  .custom-dropdown.open .dropdown-options {
    display: block;
  }
}

.input-with-image {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  label {
    font-family: Roboto;
    font-weight: 500;
    font-size: 12px;
    color: #9ea0a5;
    margin-bottom: 10px;
  }

  .input-field {
    position: relative;

    input[type="text"] {
      width: 100%;
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 5px;
      box-sizing: border-box;
      padding-left: 40px; /* Increased to make space for the icon */
      height: 38px;
      outline: none;
    }

    ::placeholder {
      font-family: Roboto;
      font-weight: 400;
      font-size: 14px;
      color: #9ea0a5;
    }

    .image-container {
      position: absolute;
      left: -30px; 
      top: 8px; 
      transform: translateY(-50%);
      display: flex;
      align-items: center;

      img {
        width: 22px;
        height: 22px;
        margin-right: 5px; /* Adjust the spacing between the image and the text */
      }

      span {
        font-size: 14px;
        color: #3e3f42;
      }
    }
  }

  p {
    margin-top: 10px;
    color: #9ea0a5;
  }
}


.input-with-add {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  label {
    font-family: Roboto;
    font-weight: 500;
    font-size: 12px;
    color: #9ea0a5;
    margin-bottom: 10px;
  }

  .input-field {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .input-container {
      // position: relative;
      // flex: 1;
      

      input[type="text"] {
        width: calc(100% - 20px);
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
        box-sizing: border-box;
        padding-left: 40px; // Space for icon
        height: 38px;
        outline: none;
      }

      .image-container {
        position: absolute;
        left: -30px; 
        top: 8px; 
        transform: translateY(-50%);
        display: flex;
        align-items: center;

        img {
          width: 22px;
          height: 22px;
          margin-left: 5px;
        }

        span {
          font-size: 14px;
          color: #888;
        }
      }
    }

    .add-input-btn {
      background: none;
      border: none;
      cursor: pointer;
      padding: 0;
      margin-left: 10px;

      img {
        width: 24px;
        height: 24px;
      }
    }
  }
}

.file-upload-container {
  width: 100%;
  background-color: #fcfcfc;
  padding: 10px;
  height: 140px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 1px dotted #e2e5ed;
  border-radius: 5px;
  box-shadow: -20px -20px 10px -20px #0000000d inset;

  .upload-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 10px;
    background-color: transparent;
    border: none;
    width: 100%;
    cursor: pointer;

    img {
      width: 20px;
      height: 20px;
    }
    .upload-text {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      p {
        font-family: Roboto;
        font-weight: 400;
        font-size: 14px;
        color: #9ea0a5;
        text-align: center;
      }
      .file-format {
        font-size: 12px;
      }
    }
  }
}

.text-input-container {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .input-label {
    font-family: Roboto;
    // font-weight: 500;
    font-size: 12px;
    color: #9ea0a5;
    margin-bottom: 15px;
  }

  .input-group {
    position: relative;

    .text-input {
      width: 203px;
      height: 37px;
      padding: 8px;
      border: 1px solid #ccc;
      border-radius: 5px;
      box-sizing: border-box;
      outline: none;
    }
    ::placeholder {
      font-family: Roboto;
      color: #9ea0a5;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
    }

    .option-text {
      position: absolute;
      top: 50%;
      right: 30px;
      transform: translateY(-50%);
      font-size: 14px;
      color: #888;
      pointer-events: none;
    }

    .text-input::after {
      content: attr(data-option-text);
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      font-size: 14px;
      color: #888;
      pointer-events: none;
    }
  }
}

.dropdown {
  position: relative;

  .dropdown-btn {
    height: 38px;
    width: 100%;
    border: 1px solid #d8dce6;
    background-color: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0px 10px;
    justify-content: space-between;
    p {
      font-family: Roboto;
      color: #9ea0a5;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      display: flex;
    }
  }

  .dropdown-content {
    // display: none;
    position: absolute;
    z-index: 1;
    background-color: #fff;
    border: 1px solid #ccc;
    border-top: none;
    width: 300px;

    label {
      padding: 5px;
    }

    input[type="checkbox"] {
      margin-right: 5px;
    }
  }

  .show {
    display: block;
  }
}
