.savedHeader {
    .btns {
        text-wrap: nowrap;
        padding: 10px;
        margin-top: 20px;
        display: flex;
        align-items: center;
        gap: 10px;
        margin-right: 20px;
        color: var(--LP-mid-gray, #6B6C6F);
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        text-transform: uppercase;
        cursor: pointer;
    }

    .btns.active {
        color: #7D55C7;
    }
}