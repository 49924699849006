.loan-req-main {
  width: 1110px;
  height: 322px;
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 41px;
  margin-left: 30px;

  padding: 0 30px 0 30px;
  fill: #fff;
  stroke-width: 1px;
  stroke: #eaedf3;
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.04));
  border: 1px solid #eaedf3;
  box-sizing: border-box;
}
.loan-req-left-title {
  margin-top: 30px;
}
.loan-req-left-title > span {
  color: #3e3f42;
  font-family: Roboto;
  font-size: 36px;
  font-style: normal;
  font-weight: 300;
  line-height: 50px; /* 138.889% */
}
.loan-req-left-para {
  margin-bottom: 60px;
}
.loan-req-left-para > span {
  color: #6b6c6f;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}
.loan-req-btn {
  display: inline-flex;
  padding: 8px 20px;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid var(--purple-pantone-2665-c-71, rgba(125, 85, 199, 0.71));
  background: linear-gradient(0deg, rgba(125, 85, 199, 0.3) 0%, rgba(255, 255, 255, 0) 107.78%), #7d55c7;
  background-blend-mode: multiply, normal;
  box-shadow: 0px 1px 1px 0px rgba(19, 31, 21, 0.1), 0px 2px 0px 0px rgba(255, 255, 255, 0.06) inset;
}

.loan-req-btn > span {
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}

.loan-req-scalton {
  margin-left: 30px;
  margin-top: 30px;
  padding-left: 30px;
  padding-top: 30px;
  width: 730px;
  height: 164px;
  flex-shrink: 0;
  border-radius: 4px;
  background: linear-gradient(264deg, rgba(255, 255, 255, 0.55) 45.42%, #fff 73.84%, rgba(255, 255, 255, 0) 100%), #f6f7f9;
}

.scalton-div {
  border-radius: 4px;
  background: #eaedf3;
}
.scalton-div:first-child {
  width: 439px;
  height: 32px;
  flex-shrink: 0;
  margin-bottom: 24px;
}
.scalton-div:nth-child(2) {
  width: 636px;
  height: 24px;
  flex-shrink: 0;
  margin-bottom: 12px;
}
.scalton-div:last-child {
  width: 636px;
  height: 24px;
  flex-shrink: 0;
}

.syndicate-start {
  margin-top: 20px;
}
.syndicate-start > span:first-child {
  color: #6b6c6f;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}
.syndicate-start > span:last-child {
  color: #6b6c6f;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}
