.general_investments {
    border: 1px solid #EAEDF3;
    filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.04));

    h2 {
        color: #3E3F42;
        font-family: Roboto;
        font-size: 18px;
        font-weight: 500;
        line-height: 28px
    }

    .upper {
        padding: 30px;
        border-bottom: 1px solid #EAEDF3;
        ;

        >div {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 50px;
            width: 98%;
            padding: 0;
            margin: 0;
            height: 30px;
            padding-top: 24px;

        }

        .search_bar {
            margin-top: -5px;
            position: relative;
            width: 150px;
            height: 38px;
            border: 2px solid #eaedf3;
            border-radius: 5px;
            display: flex;
            align-items: center;

            input {
                all: unset;
                width: 120px;
                padding-right: 25px;
                padding-left: 10px;
            }

            svg {
                position: absolute;
                right: 5px;
            }
        }

        .general_investment_nav {
            max-width: 90%;
            height: 56px;

            .asset-nav {
                border: none !important;
                max-width: 400px;
            }
        }

    }

    .lower {
        margin-top: 20px;
        .documents_box-B {
            padding: 40px 30px;

            &.table {
                // padding: 0px 30px;
                // border: ;
                padding: 0px;
            }

            p {
                color: var(--purple-pantone-2665-c, #7d55c7);
                font-family: Roboto;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
            }

            .uploaded_docs {
                width: 100%;

                .header {
                    display: flex;
                    padding: 14px 40px;
                    width: 100%;
                    justify-content: space-between;
                    border-bottom: 1px solid #eaedf3;

                    .left {
                        display: flex;

                        p {
                            width: 164px;
                        }
                    }

                    .right {
                        p {
                            margin-left: auto;
                        }
                    }

                    p {
                        text-wrap: nowrap;
                        color: #9ea0a5;
                        font-family: Roboto;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 18px;
                        /* 150% */
                        text-transform: uppercase;
                    }
                }

                .table_row {
                    padding: 10px 25px;
                    border: 1px solid #eaedf3;
                    display: flex;
                    justify-content: space-between;
                    border-top: none;

                    &.table_row2 {
                        border-bottom: 1px solid #eaedf3;
                    }

                    p {
                        color: var(--LP-black--_lib, #3e3f42);
                        font-family: Roboto;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 22px;
                        /* 157.143% */
                    }

                    span {
                        color: #9ea0a5;
                        font-family: Roboto;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 18px;
                        /* 150% */
                        text-transform: uppercase;
                    }

                    .MuiCheckbox-root {
                        padding: none;
                    }

                    .MuiFormControlLabel-root {
                        margin-right: 0px;
                    }

                    .left {
                        display: flex;

                        .item {
                            display: flex;
                            gap: 20px;
                            align-items: center;
                            width: 164px;

                            &.first {
                                margin-right: 15px;

                                img {
                                    height: 66px;
                                    width: 66px;
                                    border-radius: 4px;
                                }

                                .text_wrapper {
                                    p {
                                        color: #3E3F42;
                                        font-family: Roboto;
                                        font-size: 14px;
                                        font-weight: 500;
                                        line-height: 22px
                                    }
                                }
                            }

                            .text_wrapper {}
                        }
                    }

                    .right {
                        display: flex;
                        width: fit-content;

                        .action_buttons {
                            display: flex;
                            gap: 10px;

                            .button {
                                display: flex;
                                width: 50px;
                                justify-content: center;
                                align-items: center;
                                flex-shrink: 0;
                                border: 1px solid #eaedf3;
                                border-radius: 5px;
                            }
                        }
                    }
                }
            }
        }
        .table_lower{
            .page-nav-cont{
                margin-left: 20px !important;
            }
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 96%;
            p{
                text-wrap: nowrap;
                color: #9ea0a5;
                font-family: Roboto;
                font-size: 12px;
                font-weight: 500;
                line-height: 18px;
                text-transform: uppercase;
            }
        }
    }
}