.headerO {
  height: 75px;

  .menu_icon_sideNav {
    display: none;

    @media screen and (max-width: 1000px) {
      display: block !important;
    }
  }
}

.headerO_CON {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 24px;
  height: 73px;
  background: #fff;
  max-width: 1600px;
  width: 100%;
  box-sizing: border-box;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.05), 0px 1px 0px 0px #eaedf3;
  position: fixed;
  z-index: 1000001;

  @media screen and (max-width: 950px) {
    padding: 0px 10px !important;
  }

  .headerO-L {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    height: 100%;

    @media screen and (max-width: 1200px) {
      gap: 20px;
    }

    .header-tabs {
      display: flex;
      flex-direction: row;
      column-gap: 10px;
      align-items: center;
      height: 100%;

      @media screen and (max-width: 1000px) {
        position: absolute;
        background-color: #fbfbfb;
        right: 0;
        top: 0px;
        flex-direction: column;
        gap: 30px !important;
        display: flex;
        gap: 30px;
        padding-top: 80px;
        height: 100vh !important;
        width: 0px;
        box-shadow: 1px 85px 30px grey;
        transition: width 0.2s ease-in-out;
        overflow: hidden;
        z-index: 1999999999;

        a {
          height: fit-content !important;
          color: #3e3f42;
        }

        .menu_icon_sideNav {
          display: block !important;
        }

        img {
          width: 20px !important;
          margin-right: 10px;
        }
      }

      @media screen and (max-width: 1200px) {
        gap: 5px;

        a {
          font-size: 12px !important;
        }

        .headerO-L {
          gap: 20px !important;
        }
      }

      a {
        display: flex;
        align-items: center;
        padding: 0px 15px;
        text-decoration: none;
        font-family: Roboto;
        color: #3e3f42;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        height: 100%;
        border-bottom: 3px solid var(--purple-pantone-2665-c-71, rgba(125, 85, 199, 0));
        transition: all 0.3s ease-in-out;
        text-wrap: nowrap;

        &.active {
          border-bottom: 3px solid var(--blue-pantone-2935-c-100, rgba(125, 85, 199, 0.71));
          position: relative;
          left: 5px;
        }
      }

      a:first-child {
        flex-direction: column;
        justify-content: center;
        row-gap: 5px;
        padding: 0px 10px;

        p {
          font-family: Roboto;
          color: #6b6c6f;
          font-weight: 400;
          font-size: 12px;
          line-height: 12px;
        }
      }
    }

    .side_nav {
      width: 360px;
      display: flex;
      background-color: #d8dce6;
    }
  }

  .headerO-R {
    display: flex;
    align-items: center;
    gap: 30px;

    p {
      color: #3e3f42;
      leading-trim: both;
      text-edge: cap;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
    }

    .headerO-R {
      .menu_icon_sideNav {
        display: none !important;
        font-size: 12px;
      }
    }

    @media screen and (max-width: 1200px) {
      gap: 20px;
      font-size: 12px;
      width: fit-content;

      .req_demo {
        font-size: 12px !important;
        font-size: 12px;
        width: fit-content;
      }
    }

    .PrimaryButton {
      margin: 0px;
      width: 80px;
    }

    .header-info {
      display: flex;
      column-gap: 10px;
      align-items: center;

      img {
        cursor: pointer;
      }

      .profile-details {
        display: flex;
        column-gap: 10px;
        cursor: pointer;

        .profile-pic {
          width: 53px;
          height: 38px;
          object-fit: contain;
        }

        h6 {
          font-family: Roboto;
          color: #3e3f42;
          font-weight: 400;
          font-size: 12px;
          line-height: 20px;

          span {
            color: #9ea0a5;
            font-size: 12px;
          }
        }
      }
    }
  }
}

.vertical-line {
  height: 40px;
  border-left: solid 1px #eaedf3;
  width: 0px;
  margin: 0px 10px;
}

.productDropdownCon {
  position: relative;

  .productDropDown {
    background-color: linear-gradient(0deg, #f6f7f9 0%, #fff 100%);
    stroke-width: 1px;
    stroke: #d8dce6;
    position: absolute;
    top: 50px;
    left: -295px;
    width: 550px;
    height: 403px;
    display: none;

    &.active {
      display: flex;
    }
  }
}

.header_profile {
  width: 40px !important;
  height: 40px !important;
  overflow: hidden !important;
  border-radius: 50% !important;
  padding: 0 !important;
  border: 1px solid rgba(168, 161, 161, 0.678);
  margin: auto;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
