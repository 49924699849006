// .feedback-form-container {
//   width: 500px;
//   background-color: #fff;
//   padding: 20px;
//   border-radius: 20px;
//   box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
//   font-family: Arial, sans-serif;
//   margin: 20px auto;
//   text-align: left;
// }

// .feedback-form-container h3 {
//   font-size: 18px;
//   margin-bottom: 15px;
// }

.dislikeFeedbackContainer {
  .MuiPaper-root {
    margin: 0;
    padding: 20px;
    gap: 20px;
    h2 {
      padding: 0;
      color: #3e3f42;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 800;
      line-height: 19px; /* 118.75% */
    }

    .MuiDialogContent-root {
      padding: 0;
    }

    .MuiDialogActions-root {
      padding: 0;

      button {
        margin: 0;
        margin-left: 0 !important;
        width: 100%;
        display: flex;
        height: 34px;
        padding: 10px 20px;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        cursor: pointer;
        &:hover {
          background-color: #5300d6;
        }
      }
    }
  }
}

.reasons-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  .reason-button {
    cursor: pointer;
    display: flex;
    height: 34px;
    padding: 10px;
    align-items: center;
    border-radius: 10px;
    border: 1px solid #a1a1a1;
    background: #ffff;
    color: #a1a1a1;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: fit-content;
    &:hover {
      border: 1px solid #7d55c7;
      background: #ece6f7;
      color: #3e3f42 !important;
    }
    &.selected {
      border: 1px solid #7d55c7;
      background: #ece6f7;
      color: #3e3f42 !important;
    }
  }
}

.other-feedback {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 12px;
  label {
    color: #3e3f42;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 150% */
    text-transform: capitalize;
  }

  input {
    width: 100%;
    display: flex;
    height: 34px;
    padding: 7px;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    border: 1px solid #d6d6d8;
    background: var(--White, #fff);

    color: #3e3f42;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */

    &::placeholder {
      color: #a1a1a1;
    }

    &:focus-visible {
      border: 1px solid #7d55c7;
      outline: 0;
    }
  }
}

.submit-button .feedback-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.close-modal-btn {
  margin-top: 10px;
  background: #ccc;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
}

.feedback-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}
.header_text {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 800;
  line-height: 19px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.SubmitFeedback_button {
  width: 85px !important;
  height: 34px !important;
  padding: 10px 20px !important;
  gap: 6px !important;
  border-radius: 10px !important;
  background-color: #7d55c7 !important;
  color: #fff !important;
  cursor: pointer !important;
  text-transform: none !important;
  font-size: 14px !important;
  margin-left: 13px !important;
}

.SubmitFeedback_button:hover {
  background-color: #6c4cb2 !important;
}
