// Active Investment
.active_investment {
  border: 1px solid #eaedf3;
  width: 50%;
  position: relative;
  @media screen and (max-width: 1100px) {
    width: 100%;
    max-width: calc(100vw - 240px);
    overflow: auto;
  }

  .active_investment_genralPartner {
    display: none;
  }

  .active_investment_header {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    border-bottom: 1px solid #eaedf3;
    h3 {
      color: #3e3f42;
      font-family: Roboto;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px; /* 155.556% */
    }
  }
  .active_investment_one {
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }
}
.active_investment_middle {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
  border-bottom: 1px solid #eaedf3;
  p {
    color: #9ea0a5;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 150% */
    text-transform: uppercase;
  }
}

.active_investment_lower {
  .active_investment_inner {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    padding: 20px;

    .active_investment_one {
      display: flex;
      h3 {
        color: #3e3f42;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 150% */
      }
      p {
        color: #9ea0a5;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 150% */
      }
      h4 {
        color: #6b6c6f;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 183.333% */
      }
    }

    .active_investment_two {
      h3 {
        color: #6b6c6f;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 157.143% */
      }
    }

    .active_investment_four {
      display: flex;
      flex-direction: column;
      gap: 10px;
      .performence {
        display: flex;
        gap: 10px;
      }
    }
  }
}
.genralPartner {
  display: none !important;
}

.active_investment_filter_button {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  padding: 5px 14px;
  border: 1px solid #eaedf3;
  background: #fff;
  border-radius: 5px;
  p {
    color: #3e3f42;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
  }
}

.filter_modal_overlay {
  position: absolute;
  width: 300px;
  height: 300px;
  z-index: 1;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(137, 137, 137, 0.5);
  right: 74px;
  top: 57px;
  .filter_modal {
    padding: 30px;
    display: flex;
    gap: 20px;
    flex-direction: column;
    p {
      color: var(--LP-gray, #9ea0a5);

      /* All Caps/Medium 12px */
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: uppercase;
    }
    button {
      padding: 10px 20px;
      background-color: transparent;
      border: 1px solid #eaedf3;
      width: 100%;
    }
    .filter_modal_one,
    .filter_modal_two {
      display: flex;
      flex-direction: column;
      gap: 10px;
      button {
        text-align: left;
      }
    }
    .filter_modal_button {
      display: flex;
      gap: 20px;
      width: 60%;
    }
    .apply {
      padding: 10px 20px;
      background: #7d55c7;
      color: #ffffff;
      border-radius: 5px;
    }
    .cancel {
      padding: 10px 20px;
      background: transparent;
      border: 1px solid #eaedf3;
      border-radius: 5px;
    }
  }
}

// .active_investment_lower p{
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   // margin-top: 40px;
// }

.active_investment_image{
  width: 114px;
  border-radius: 5px;
}
.active_investment_assetdetailscontainer p{
  width: 160px;

}

// simmer effect

.shimmer-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.shimmer-wrapper {
  display: flex;
  align-items: center;
  gap: 20px;
}

.shimmer {
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
}

.shimmer-image {
  width: 80px;
  height: 80px;
  border-radius: 4px;
}

.shimmer-text {
  flex: 1;
  height: 20px;
  border-radius: 4px;
}

@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}



