.syndicatePerformence {
  padding: 20px;

  .syndicate_main_lower_left {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .reply-modal_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3999;

    .configure_settings_modal {
      background-color: #ffffff;
      display: flex;
      flex-direction: column;
      max-height: 600px;
      // padding: 23px 30px;

      .deals-room-nav {
        margin: 0px !important;
      }

      .nav_box {
        padding: 0px 30px;
      }
      max-width: 550px;
      .header_box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 30px;

        h2 {
          color: #3e3f42;
          font-family: Roboto;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: 30px;
          text-transform: capitalize;
        }
      }
      .content_box {
        max-height: 100%;
        overflow: auto;
        .check_payment_wrapper {
          display: flex;
          flex-direction: column;
          height: 100%;
          overflow: auto;

          .topSection {
            display: flex;
            flex-direction: column;

            .groupA {
              display: flex;
              flex-direction: column;
              padding: 0px 30px;
              padding-bottom: 20px;
              border-bottom: 1px solid #f3f3f6;
              gap: 20px !important;

              h3 {
                color: #3e3f42;
                font-family: Roboto;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px; /* 150% */
              }

              .option_box1 {
                display: flex;
                flex-direction: column;
                border-bottom: 1px solid #f3f3f6;
                gap: 20px !important;

                .topBox {
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;

                  .checkbox {
                    padding: 0;

                    p {
                      color: #3e3f42;
                      font-family: Roboto;
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 22px; /* 157.143% */
                    }
                  }
                }
                .bottomBox {
                  p {
                    color: #3e3f42;
                    font-family: Roboto;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 22px; /* 157.143% */
                  }
                }
              }
              .option_box2 {
                .checkbox {
                  display: flex;
                  flex-direction: row;
                  padding: 0;

                  p {
                    color: #3e3f42;
                    font-family: Roboto;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 22px; /* 157.143% */
                  }
                }
              }
            }
          }

          .bottomSection {
            .groupB {
              display: flex;
              flex-direction: column;
              padding: 0px 30px;
              padding-bottom: 20px;
              border-bottom: 1px solid #f3f3f6;
              gap: 20px !important;

              h3 {
                color: #3e3f42;
                font-family: Roboto;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px; /* 150% */
              }

              .option_box {
                display: flex;

                .topBox {
                  display: flex;
                  flex-direction: column;
                  border-bottom: 1px solid #f3f3f6;
                }

                .checkbox {
                  padding: 0;
                }

                p {
                  color: #3e3f42;
                  font-family: Roboto;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 22px; /* 157.143% */
                }
              }

              .viewAll_btn {
                color: var(--purple-pantone-2665-c, #7d55c7);
                font-family: Roboto;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px; /* 157.143% */
                cursor: pointer;
              }
            }
          }
        }
      }

      .groupC {
        display: flex;
        justify-content: space-between;
        padding: 20px 30px;

        .cancel {
          border-radius: 4px;
          border: 1px solid #d8dce6;
          background: linear-gradient(0deg, #f6f7f9 0%, #fff 100%);
          box-shadow: 0px 2px 0px 0px rgba(255, 255, 255, 0.05) inset, 0px 1px 1px 0px rgba(22, 29, 37, 0.05);

          display: inline-flex;
          padding: 8px 11px;
          align-items: center;
          gap: 10px;

          color: #3e3f42;
          text-align: center;
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px; /* 157.143% */
        }

        .save_changes {
          border-radius: 4px;
          border: 1px solid var(--purple-pantone-2665-c-71, rgba(125, 85, 199, 0.71));
          background: linear-gradient(0deg, rgba(125, 85, 199, 0.3) 0%, rgba(255, 255, 255, 0) 107.78%), #7d55c7;
          background-blend-mode: multiply, normal;
          box-shadow: 0px 2px 0px 0px rgba(255, 255, 255, 0.06) inset, 0px 1px 1px 0px rgba(19, 31, 21, 0.1);

          color: #fff;
          text-align: center;
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px; /* 157.143% */

          display: inline-flex;
          padding: 8px 20px;
          align-items: center;
          gap: 12px;
        }
      }

      .wire-transfer-payment-instructions {
        padding: 20px;
        max-width: 800px;
        margin: 0 auto;
        background: #fff;
        border-radius: 8px;
        // box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        flex-direction: column;
        height: 100%;
        overflow: auto;

        h2 {
          text-align: center;
          margin-bottom: 20px;
        }

        .tabs {
          display: flex;
          justify-content: space-around;
          margin-bottom: 20px;

          .tab {
            padding: 10px 20px;
            cursor: pointer;
          }

          .active {
            border-bottom: 2px solid #6200ee;
            color: #6200ee;
          }
        }

        .form-header {
          display: flex !important;
          flex-direction: row !important;
          justify-content: space-between !important;
          h3 {
            color: #3e3f42;
            font-family: Roboto;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px; /* 150% */
          }
        }

        .form {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          .form-section {
            width: 48%;
            display: flex !important;
            flex-direction: column !important;

            div {
              display: flex !important;
              flex-direction: column !important;
              gap: 12px !important;
            }

            .form-group {
              margin-bottom: 20px;

              label {
                display: block;
                margin-bottom: 5px;

                color: #9ea0a5;
                font-family: Roboto;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 18px; /* 150% */
                text-transform: uppercase;
              }

              input,
              textarea {
                width: 100%;
                padding: 8px;
                border: 1px solid #ddd;
                border-radius: 4px;
              }

              .checkbox-group {
                display: flex !important;
                align-items: center;
                margin-top: 10px;
                flex-direction: row !important;

                #intermediary-bank {
                  width: auto !important;
                }

                input {
                  margin-right: 10px;
                }
              }
            }

            .form-group.custom {
              flex-direction: row !important;
            }
          }
        }

        .positions-section {
          margin-top: 40px;
          flex-direction: column;

          h3 {
            margin-bottom: 20px;

            color: #3e3f42;
            font-family: Roboto;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px; /* 150% */
          }

          label {
            color: #9ea0a5;
            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px; /* 150% */
            text-transform: uppercase;
          }

          .radio-group {
            display: flex;
            align-items: flex-start;
            margin-bottom: 10px;

            .radio-group_div {
              display: flex;
              flex-direction: column !important;
            }
            .view_position_link {
              color: #6200ee;
              font-family: Roboto;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 24px;
            }

            input {
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
}
