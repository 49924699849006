.Conatiner {
  width: 100%;
}
.Captable-Conatiner {
  border: 1px solid #eaedf3;
}
.Captable-Conatiner-data {
  display: flex;
  gap: 40px;
  margin: 5px;
  padding: 5px;
}
.Captable-Conatiner-data span {
  font-size: 12px;
  font-weight: 600;
}
.Conatiner-flex-header {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 550px;
}
.Conatiner-Group-access {
  border: 1px solid var(--Primary-Purple-50, #eaedf3);
  background: rgba(125, 85, 199, 0.08);
}
.Conatiner-Group-access-data {
  margin: 5px;
  padding: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Conatiner-Founder {
  border: 1px solid #eaedf3;
}
.Conatiner-Founder-data {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
  padding: 5px;
  margin: 5px;
}
.Conatiner-Founder-data h1 {
  color: #3e3f42;
  leading-trim: both;
  text-edge: cap;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 171.429% */
  text-transform: capitalize;
}

.Conatiner-Founder-data h5 {
  color: #3E3F42;
  leading-trim: both;
  text-edge: cap;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 157.143% */
}
.custom-dropdown {
  align-items: center;
  margin-top: 0;
  width: 150px;
}
.Conatiner-head {
  display: flex;
  gap: 40px;
}


@media (max-width: 1200px) {
  .Conatiner-head {
    gap: 30px; 
    flex-direction: column;
  }
  .Conatiner-flex{
    width: 100%;
  }
}

@media (max-width: 700px) {
  .Conatiner-head {
    gap: 20px; 
    flex-direction: column;
  }
  .Conatiner-flex{
    width: 552px;
    background-color: #9ea0a5;
  }
}

@media (max-width: 400px) {
  .Conatiner-head {
    flex-direction: column;
    gap: 10px; 
  }
  .Conatiner-flex{
    width: 100%;
    background-color: #9ea0a5;
  }
}



.Conatiner-data h1 {
  color: #3e3f42;
  leading-trim: both;
  text-edge: cap;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
}
.Conatiner-data p {
  color: var(--Primary-Gray-200, #9ea0a5);
  leading-trim: both;
  text-edge: cap;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  width: 80%;
}

.Captable-Header {
  border: 1px solid #eaedf3;
  margin-top: 10px;
}
.Captable-Header-data {
  display: flex;
  align-items: center;
  margin: 10px;
  padding: 10px;
  justify-content: space-between;
}
.Captable-Header-data p {
  color: var(--Primary-Gray-200, #9ea0a5);
  leading-trim: both;
  text-edge: cap;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  width: 52%;
}
.Captable-Header-data a {
  text-decoration: underline;
}
.image-level {
  width: 100px;
  height: 50px;
  display: flex;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  background-color: #ed962d;
}
.image-level img {
  width: 30px;
}
.header-access {
  padding: 10px;
  text-decoration: underline;
}

.Conatiner-flex {
  border: 1px solid #eaedf3;
}
.Conatiner-flex-data {
  margin: 10px;
  padding: 10px;
  display: flex;
}
.Conatiner-notifications-data {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.Conatiner-notifications-data h1 {
  color: #3e3f42;
  leading-trim: both;
  text-edge: cap;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
}

.Conatiner-notifications-data p {
  color: var(--Primary-Gray-200, #9ea0a5);
  leading-trim: both;
  text-edge: cap;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}
