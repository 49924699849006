.teams {
  max-width: 1168px;

  .team_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .filter_btn {
    margin-top: -5px;
    position: relative;
    padding: 0 26px;
    width: 94px;
    height: 38px;
    border: 2px solid #eaedf3;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    color: #3e3f42;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;

    img {
      rotate: 90deg;
      height: 20px;
      width: 20px;
    }
  }

  .dropdown {
    .dropDownOuterCon {
      margin-top: "0px" !important;
    }
  }

  .btn_add {
    color: #ffffff;
    display: inline-flex;
    padding: 7px 20px;
    align-items: center;
    justify-content: center;
    gap: 12px;
    border-radius: 4px;
    border: 1px solid var(--purple-pantone-2665-c-71, rgba(125, 85, 199, 0.71));
    background: linear-gradient(0deg, rgba(125, 85, 199, 0.3) 0%, rgba(255, 255, 255, 0) 107.78%), #7d55c7;
    background-blend-mode: multiply, normal;
    box-shadow: 0px 1px 1px 0px rgba(19, 31, 21, 0.1), 0px 2px 0px 0px rgba(255, 255, 255, 0.06) inset;
    width: 100%;
  }

  .search_bar {
    position: relative;
    width: 220px;
    height: 38px;
    border: 2px solid #eaedf3;
    border-radius: 5px;
    display: flex;
    align-items: center;

    input {
      all: unset;
      width: 200px;
      padding-right: 25px;
      padding-left: 2px;
      color: #9ea0a5;
      font-family: Roboto;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
    }

    svg {
      position: absolute;
      right: 5px;
    }
  }

  .lender_wrapper {
    padding-left: 0px;
  }

  .lender_table {
    overflow: hidden;
    max-width: 100%;

    .documents_box {
      border: 1px solid #eaedf3;

      border-radius: 4px;
      margin-bottom: 30px;

      .documents_box-T {
        padding: 15px 30px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #eaedf3;

        .left {
          gap: 20px;
          display: flex;

          p {
            color: #9ea0a5;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px;
            /* 157.143% */
          }

          h2 {
            margin-top: 5px;
            color: #3e3f42;
            font-family: Roboto;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 28px;
            /* 155.556% */
            text-wrap: nowrap;
          }
        }

        .right {
          display: flex;

          justify-content: right;

          .button {
            gap: 10px;
            display: flex;
            align-items: center;
            padding: 0px 10px;
            border-right: 1px solid #eaedf3;

            &:last-of-type {
              border: none;
            }
          }

          .button2 {
            margin-right: 10px;
            display: flex;
            align-items: center;
            padding: 0px 10px;
            border: 2px solid #eaedf3;
            border-radius: 5px;

            span {
              margin-left: 0px !important;
            }
          }

          &:last-child {
            margin-right: 0px;
          }

          .white {
            padding: 7px 14px;
            background: #fff;
            color: #3e3f42;
            border: none;
            margin-top: 0px;
            box-shadow: none;
          }
        }
      }

      .documents_box-B {
        padding: 40px 30px;

        &.table {
          // padding: 0px 30px;
          // border: ;
          padding: 0px;
        }

        .btn_green {
          width: 83px;
          height: 24px;
          text-transform: uppercase;
          color: #fff;
          text-align: center;
          font-family: Roboto;
          font-size: 12px;
          font-weight: 500;
          line-height: 18px;
          background-color: #34aa44;
          outline: none;
          border: none;
          border-radius: 3px;
        }

        p {
          color: var(--purple-pantone-2665-c, #7d55c7);
          font-family: Roboto;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          /* 111.111% */
        }

        .uploaded_docs {
          width: 100%;

          .header {
            display: flex;
            padding: 14px 40px;
            width: 100%;
            justify-content: space-between;
            border-bottom: 1px solid #eaedf3;

            .left {
              display: flex;

              p {
                width: 124px;
              }
            }

            .right {
              p {
                margin-left: auto;
              }
            }

            p {
              text-wrap: nowrap;
              color: #9ea0a5;
              font-family: Roboto;
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: 18px;
              /* 150% */
              text-transform: uppercase;
            }
          }

          .table_row {
            padding: 10px 25px;
            border: 1px solid #eaedf3;
            display: flex;
            justify-content: space-between;
            border-top: none;
            border-bottom: 1px solid #eaedf3 !important;

            &.table_row2 {
              border-bottom: 1px solid #eaedf3;
            }

            p {
              color: var(--LP-black--_lib, #3e3f42);
              font-family: Roboto;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 22px;
              /* 157.143% */
            }

            span {
              color: #9ea0a5;
              font-family: Roboto;
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: 18px;
              /* 150% */
              text-transform: uppercase;
            }

            .left {
              display: flex;

              .item {
                display: flex;
                gap: 10px;
                align-items: inherit;
                width: 124px;

                &.first {
                  margin-right: 15px;
                  display: flex;
                  width: 250px;

                  .user_img_name {
                    display: flex;
                    gap: 5px;
                  }

                  @media screen and (max-width: 1300px) {
                    .user_img_name {
                      flex-direction: column;
                    }
                  }

                  .person {
                    height: 38px;
                    width: 38px;
                  }
                }

                .assets_box {
                  width: 38px;
                  height: 24px;
                  border: 1px solid #eaedf3;
                  background-color: #fbfbfd;
                  color: #3e3f42;
                  text-align: center;
                  font-family: Roboto;
                  font-size: 12px;
                  font-weight: 500;
                  line-height: 18px;
                  text-transform: uppercase;
                }

                .btn_status {
                  width: 92px;
                  height: 24px;
                  border-radius: 3px;
                  border: 1px solid #d8d8d8;
                  color: #3e3f42;
                  font-family: Roboto;
                  font-size: 12px;
                  font-weight: 500;
                  line-height: 18px;
                  text-transform: uppercase;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  gap: 6px;
                  background-color: #ffffff;

                  .active {
                    width: 6px;
                    height: 6px;
                    border-radius: 50%;
                  }
                }

                .text_wrapper {
                  p {
                    text-wrap: nowrap;
                  }

                  color: #3e3f42;
                  font-family: Roboto;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 24px;

                  span {
                    color: #9ea0a5;
                    font-family: Roboto;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 18px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .invite_modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
  }

  .invitebox {
    background: #ffffff;
    width: 540px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 4px;
    filter: drop-shadow(3px 0 13px #cddddd);

    .inputs {
      border-bottom: 1px solid #eaedf3;
      padding-bottom: 20px;
      display: flex;
      flex-direction: column;
      gap: 12px;
      padding: 20px 20px;

      span {
        color: #9ea0a5;
        font-family: Roboto;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        margin: 20px 0 0 0;
      }
    }

    .btns {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px;
    }

    .btn_right {
      color: #ffffff;
      display: inline-flex;
      padding: 7px 10px;
      align-items: center;
      justify-content: center;
      gap: 12px;
      border-radius: 4px;
      border: 1px solid var(--purple-pantone-2665-c-71, rgba(125, 85, 199, 0.71));
      background: linear-gradient(0deg, rgba(125, 85, 199, 0.3) 0%, rgba(255, 255, 255, 0) 107.78%), #7d55c7;
      background-blend-mode: multiply, normal;
      box-shadow: 0px 1px 1px 0px rgba(19, 31, 21, 0.1), 0px 2px 0px 0px rgba(255, 255, 255, 0.06) inset;
    }
  }

  .btn_cancel {
    outline: none;
    background: linear-gradient(#f6f7f9, #ffffff);
    border: 1px solid #d8dce6;
    border-radius: 4px;
    padding: 7px 10px;
  }

  .deletebox {
    background: #ffffff;
    width: 445px;
    border-radius: 4px;
    filter: drop-shadow(3px 0 13px #cddddd);

    .deletebox_middle {
      padding: 20px;
      border-bottom: 1px solid #eaedf3;

      p {
        color: #3e3f42;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
      }
    }

    .btns {
      padding: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .btn_delete {
      background-color: #cb333b;
      color: #ffffff;
      outline: none;
      border-radius: 4px;
      padding: 7px 10px;
      border: none;
      color: #fff;
      text-align: center;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
    }
  }
}
