.Mui-checked {
  .MuiSwitch-track {
    background-color: aqua !important;
  }
}

.App {
  display: flex;
  justify-content: center;
  .PageL-M {
    max-width: 1600px;
    width: 100%;
  }
}

// .fc-direction-ltr .fc-daygrid-event.fc-event-end, .fc-direction-rtl .fc-daygrid-event.fc-event-start{
//   background: #E7EFFB !important;
//   color: #1665D8 !important;
//   visibility: visible !important;
//   box-shadow: 3px 0px 0px 0px #1665D8 inset !important;
//   border: none !important;
//   height: 86px !important;

// }
.fc .fc-daygrid-event-harness {
  position: relative;
  top: -5px;
}

.fc-daygrid-event-harness {
  margin-top: 0;
}

.fc-h-event {
  background-color: transparent;
  border: none;
  display: block;
}