.task-modal {
  
  position: absolute;
  left: 50%;
  top: 59%;
  transform: translate(-50%, -50%);
  z-index: 1111;

  .modal-content {
    background: white;
    border-radius: 4px;
    width: 540px;
    z-index: 11;
    position: absolute;
    left: 50%;
    top: 59%;
    transform: translate(-50%, -50%);
    filter: drop-shadow(3px 0 13px #cddddd);

    .modal-header {
      padding: 20px;
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 74px;
      width: 100%;
      box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.10);

      h2 {
        margin: 0;
      }

      button {
        background: none;
        border: none;
        cursor: pointer;
        font-size: 20px;
        color: #333;

      }

    }
  }

  .modal-footer {
    text-align: right;
    padding: 20px;

    button {
      background-color: #4caf50;
      color: #fff;
      padding: 8px 16px;
      border: none;
      border-radius: 4px;
      cursor: pointer;

      &:hover {
        background-color: #45a049;
      }
    }
  }

  .modal-body {
    margin-bottom: 15px;
    padding: 20px;

  }

  .btn_add {
    outline: none;
    background: linear-gradient(#F6F7F9, #FFFFFF);
    border: 1px solid #D8DCE6;
    border-radius: 4px;
    padding: 7px 10px;
    color: #333;
    margin-left: 12px;
  }

  .btns {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    padding-top: 20px;
    width: 100%;

    .btn_cancel {
      outline: none;
      background: linear-gradient(#F6F7F9, #FFFFFF);
      border: 1px solid #D8DCE6;
      border-radius: 4px;
      padding: 7px 10px;
      color: #333;
    }

    .btn_create {
      color: #ffffff;
      display: inline-flex;
      padding: 7px 10px;
      align-items: center;
      justify-content: center;
      gap: 12px;
      border-radius: 4px;
      border: 1px solid var(--purple-pantone-2665-c-71, rgba(125, 85, 199, 0.71));
      background: linear-gradient(0deg, rgba(125, 85, 199, 0.30) 0%, rgba(255, 255, 255, 0.00) 107.78%), #7D55C7;
      background-blend-mode: multiply, normal;
      box-shadow: 0px 1px 1px 0px rgba(19, 31, 21, 0.10), 0px 2px 0px 0px rgba(255, 255, 255, 0.06) inset;
    }
  }

  .desc {
    .inputBoxMain input {
      height: 95px !important;
    }
  }

  .title {
    color: #9EA0A5;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    text-transform: uppercase;
    margin-top: 20px;
  }

  .attachment {
    border: 1px solid #EAEDF3;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    padding: 20px;

    p {
      display: flex;
      align-items: center;
      gap: 6px;
      color: #9EA0A5;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
    }

  }

  .tags_wrapper {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-top: 20px;
    border: 1px solid #eaedf3;
    padding: 20px;

  }

  .tag {
    border-radius: 4px;
    padding: 4px 10px;
    display: flex;
    align-items: center;
    gap: 4px;
    color: #ffffff;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    text-transform: uppercase;
  }

  .tag.green {
    background-color: #34aa44;
  }

  .tag.orange {
    background-color: #f6ab2f;
  }

  .tag.blue {
    background-color: #1665d8;
  }


}