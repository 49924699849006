.shimmer-effect {
  background-color: #f6f7f8; 
  padding: 10px; 
  margin-bottom: 20px; 
  border-radius: 8px; 
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.shimmer-line {
  background: linear-gradient(to right, transparent 0%, #e6e6e6 50%, transparent 100%);
  height: 70px; 
  margin-bottom: 10px; 
  animation: shimmer 1s infinite linear;
}

@keyframes shimmer {
  100% {
    background-position: 100% 0;
  }
}
