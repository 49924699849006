.TotalCompensation-Conatiner {
  border: 1px solid #eaedf3;
  background: rgba(125, 85, 199, 0.08);
}
.TotalCompensation-Conatiner-data {
  margin: 10px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
}
.TotalCompensation-container-image {
  display: flex;
  gap: 20px;
}
.TotalCompensation-container-image-data {
  display: flex;
  gap: 20px;
}
.TotalCompensation-Data {
  border: 1px solid #eaedf3;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.15);
  padding: 10px;
  width: 419px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.TotalCompensation-Data p {
  width: 50%;
}

// Dropdown
.TotalCompensation-wrapper {
  .TotalCompensation {
    border: 1px solid #eaedf3;
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.15);
    .TotalCompensation-accordion {
      cursor: pointer;
      background-color: white;
      padding: 15px;
      padding-top: 20px;
      padding-bottom: 20px;
      border: none;
      text-align: left;
      width: 610px;
      outline: none;
      leading-trim: both;
      text-edge: cap;
      font-family: Roboto;
      font-size: 16.123px;
      font-style: normal;
      font-weight: 500;
      line-height: 26.2px; /* 162.5% */
      i {
        float: right;
      }

      &:hover {
      }

      &.active {
        i {
         width: 610px;
        }
      }
    }

    .TotalCompensation-pannel {
      padding: 0 15px;
      display: none;
      background-color: white;
      overflow: hidden;
      transition: max-height 0.2s ease-out;

      &.active {
      display: block;
        display: block;
        width: 610px;
        padding: 20px;
        color: var(--Primary-Gray-200, #9ea0a5);
        leading-trim: both;
        text-edge: cap;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 26px; /* 185.714% */      }

      p {
        margin-top: 0;
      }
    }
  }
}


// flex-data
.TotalCompensation-container {
  display: flex;
}
.TotalCompensation-container-data {
  display: flex;
}
.linetag {
  height: 5px;
  background-color: #7d55c7;
}
.TotalCompensation-flex {
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: center;
}
.TotalCompensation-flex p {
  color: var(--Black, #3e3f42);
  leading-trim: both;
  text-edge: cap;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}
.TotalCompensation-Data button {
  display: flex;
  width: 375px;
  height: 38px;
  padding: 0px 146px 0px 134px;
  align-items: center;
  border: none;
  color: white;
  font-size: 14px;
  flex-shrink: 0;
  border-radius: 4px;
  background: var(--Primary-Purple-500, #7d55c7);
}

@media screen and (max-width: 1200px) {
  .TotalCompensation-accordion {
    width: 500px;
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 900px) {
  .TotalCompensation-accordion {
    width: 90%;
    flex-direction: column; /* Change to column layout */
  }
}

@media screen and (max-width: 800px) {
  .TotalCompensation-accordion {
    width: 90%;
  }
}

@media screen and (max-width: 700px) {
  .TotalCompensation-accordion {
    width: 90%;
  }
}
