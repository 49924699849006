.success_modal_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(1.5px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
  
    .successModalWrapper {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  
    .success_modal_container {
      box-sizing: border-box;
      border-radius: 16px;
      min-width: 590px;
      // height: 326px;
      display: flex;
      justify-content: space-around;
      overflow: hidden;
      flex-direction: column;
      align-items: center;
      // padding: 12.76vh 4.87vw 6.38vh 4.87vw;
  
      @media (max-width: 640px) {
        min-width: 80vw !important;
        margin: 0 20px;
        // padding: 100px 35px 50px 35px;
      }
    }
    .success_modal_container_top {
      position: relative;
      top: 101px;
  
      .tick_icon {
        // margin-left: 10px;
      }
    }
    .success_modal_container_bottom {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 14px;
      justify-content: space-around;
      border-radius: 16px;
      background: var(--White-50, #f6fffa);
      padding: 100px 70px 50px 70px;

      @media (max-width: 640px) {
        padding: 100px 35px 50px 35px;
      }
  
      div {
        width: 100%;
      }
  
      p {
        color: var(--primary-gray-100-text, #9EA0A5);
        leading-trim: both;
        text-edge: cap;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 157.143% */
      }
  
      h1 {
        color: var(--Action-green, #7D55C7);
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
      }
  
      .success_modal_close_btn {
        border-radius: 32px;
        background: var(--Action-green, #7D55C7);
        border: none;
        color: var(--BG, var(--White-50, #f6fffa));
        text-align: center;
        // font-family: "DM Sans" !important;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        padding: 16px;
        min-height: 56px;
        width: 100%;
        max-width: 450px !important;
        cursor: pointer;
      }
    }
  }
  
 