.invoicing {
  display: flex;
  justify-content: center;
  gap: 30px;

  @media screen and (max-width: 800px) {
    flex-direction: column;
    // width: 1600px;
  }

  .second {
    width: 150px !important;
  }
  .card_btn {
    display: flex;
    align-items: center;
    gap: 4px;
    border-radius: 4px;
    border: 1px solid #e2e5ed;
    color: var(--LP-mid-gray, #6b6c6f);
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    background: transparent;
    padding: 10px;
  }
  .btn_green {
    width: 83px;
    height: 24px;
    text-transform: uppercase;
    color: #fff;
    text-align: center;
    font-family: Roboto;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    background-color: #34aa44;
    outline: none;
    border: none;
    border-radius: 3px;
  }

  .cardsvg {
    position: absolute;
    right: 30px;
  }

  .invoicing_left {
    // width: 70% !important;
    border-radius: 3px;
    @media screen and (max-width: 900px) {
        border-radius: 3px;
        width: 712px;
    }
  }

  .invoicing_upper {
    border: 1px solid #eaedf3;

    .invoicing_header {
      display: flex;
      align-items: baseline;
      padding: 0 20px;
      gap: 20px;
      border: 1px solid #eaedf3;

      h2 {
        color: #3e3f42;
        font-family: Roboto;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
        /* 155.556% */
      }

      span {
        color: #3e3f42;
        font-family: Roboto;
        font-size: 16px !important;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
        /* 175% */
      }
    }

    .invoicing_nav {
      .asset-nav {
        max-width: 250px;
        border: none !important;

        .ut-nav-active {
          height: 58px;
        }
      }
    }
  }

//   .invoicing_right {
//     @media screen and (max-width: 1400px) {
//       border-radius: 3px;
//       width: 25% !important;
//     }
//     @media screen and (max-width: 700px) {
//         border-radius: 3px;
//         width: 377px;
//       }

//     .invoicing_right_box {
//       position: relative;
//       height: 464px;
//       border-radius: 3px;
//       border: 1px solid #eaedf3;
//       // filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.10));
//       @media screen and (max-width: 900px) {
//         width: 100%;
//       }
//       .invoicing_header {
//         padding: 20px 30px;
//         border-bottom: 1px solid #eaedf3;
//       }

//       h2 {
//         color: #3e3f42;
//         font-family: Roboto;
//         font-size: 18px;
//         font-style: normal;
//         font-weight: 500;
//         line-height: 28px;
//       }

//       .inputs {
//         padding: 30px 30px;
//         border-bottom: 1px solid #eaedf3;
//       }

//       .btns {
//         display: flex;
//         flex-direction: column;
//         justify-content: center;
//         align-items: center;
//       }

//       button {
//         width: 290px;
//         height: 38px;
//         border-radius: 4px;
//       }

//       .btn_one {
//         border: 1px solid var(--purple-pantone-2665-c-71, rgba(125, 85, 199, 0.71));
//         background: linear-gradient(0deg, #7d55c7 0%, #8865c8 107.78%), #7d55c7;
//         box-shadow: 0px 1px 1px 0px rgba(19, 31, 21, 0.1), 0px 2px 0px 0px rgba(255, 255, 255, 0.06) inset;
//         color: #fff;
//         text-align: center;
//         font-family: Roboto;
//         font-size: 14px;
//         font-style: normal;
//         font-weight: 400;
//         line-height: 22px;
//       }

//       .btn_two {
//         margin-top: 10px;
//         color: var(--LP-mid-gray, #6b6c6f);
//         text-align: center;
//         font-family: Roboto;
//         font-size: 14px;
//         font-style: normal;
//         font-weight: 400;
//         line-height: 22px;
//         /* 157.143% */
//         border: none;
//         outline: none;
//         background: transparent;
//       }
//     }

//     .text {
//       color: var(--LP-gray, #9ea0a5);
//       text-align: center;
//       font-family: Roboto;
//       font-size: 12px;
//       font-style: normal;
//       font-weight: 400;
//       line-height: 18px;
//       margin-top: 20px;

//       span {
//         color: var(--LP-gray, #9ea0a5);
//         font-family: Roboto;
//         font-size: 12px;
//         font-style: normal;
//         font-weight: 500;
//         line-height: 18px;
//       }
//     }
//   }

  .invoicing_lower {
    margin-top: 30px;
    .lender_wrapper {
      padding-left: 0px;
    }

    .lender_table {
      max-width: 730px;
      overflow: hidden;

      .documents_box {
        border: 1px solid #eaedf3;

        border-radius: 4px;
        margin-bottom: 30px;

        .documents_box-T {
          padding: 15px 30px;
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid #eaedf3;

          .left {
            gap: 20px;
            display: flex;

            p {
              color: #9ea0a5;
              font-family: Roboto;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 22px;
              /* 157.143% */
            }

            h2 {
              margin-top: 5px;
              color: #3e3f42;
              font-family: Roboto;
              font-size: 18px;
              font-style: normal;
              font-weight: 500;
              line-height: 28px;
              /* 155.556% */
              text-wrap: nowrap;
            }
          }

          .right {
            display: flex;

            justify-content: right;

            .button {
              gap: 10px;
              display: flex;
              align-items: center;
              padding: 0px 10px;
              border-right: 1px solid #eaedf3;

              &:last-of-type {
                border: none;
              }
            }

            .button2 {
              margin-right: 10px;
              display: flex;
              align-items: center;
              padding: 0px 10px;
              border: 2px solid #eaedf3;
              border-radius: 5px;

              span {
                margin-left: 0px !important;
              }
            }

            &:last-child {
              margin-right: 0px;
            }

            .white {
              padding: 7px 14px;
              background: #fff;
              color: #3e3f42;
              border: none;
              margin-top: 0px;
              box-shadow: none;
            }
          }
        }

        .documents_box-B {
          padding: 40px 30px;

          &.table {
            // padding: 0px 30px;
            // border: ;
            padding: 0px;
          }

          .btn_green {
            width: 83px;
            height: 24px;
            text-transform: uppercase;
            color: #fff;
            text-align: center;
            font-family: Roboto;
            font-size: 12px;
            font-weight: 500;
            line-height: 18px;
            background-color: #34aa44;
            outline: none;
            border: none;
            border-radius: 3px;
          }

          .btn_red {
            width: 83px;
            height: 24px;
            text-transform: uppercase;
            border: 1px solid #cb333b;
            background-color: #ffffff;
            text-align: center;
            font-family: Roboto;
            font-size: 12px;
            font-weight: 500;
            line-height: 18px;
            color: #cb333b;
            outline: none;
            border-radius: 3px;
          }

          p {
            color: var(--purple-pantone-2665-c, #7d55c7);
            font-family: Roboto;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            /* 111.111% */
          }

          .uploaded_docs {
            width: 100%;

            .header {
              display: flex;
              padding: 14px 40px;
              width: 100%;
              justify-content: space-between;
              border-bottom: 1px solid #eaedf3;

              .left {
                display: flex;

                p {
                  width: 124px;
                }
              }

              .right {
                p {
                  margin-left: auto;
                }
              }

              p {
                text-wrap: nowrap;
                color: #9ea0a5;
                font-family: Roboto;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 18px;
                /* 150% */
                text-transform: uppercase;
              }
            }

            .table_row {
              padding: 10px 25px;
              border: 1px solid #eaedf3;
              display: flex;
              justify-content: space-between;
              border-top: none;
              border-bottom: 1px solid #eaedf3 !important;

              &.table_row2 {
                border-bottom: 1px solid #eaedf3;
              }

              p {
                color: var(--LP-black--_lib, #3e3f42);
                font-family: Roboto;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;
                /* 157.143% */
              }

              span {
                color: #9ea0a5;
                font-family: Roboto;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 18px;
                /* 150% */
                text-transform: uppercase;
              }

              .MuiCheckbox-root {
                padding: none;
              }

              .MuiFormControlLabel-root {
                margin-right: 0px;
              }

              .left {
                display: flex;

                .item {
                  display: flex;
                  gap: 10px;
                  align-items: center;
                  width: 124px;

                  &.first {
                    margin-right: 15px;
                  }

                  .text_wrapper {
                    p {
                      text-wrap: nowrap;
                    }

                    color: #3e3f42;
                    font-family: Roboto;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 24px;

                    /* 150% */
                    span {
                      color: #9ea0a5;
                      font-family: Roboto;
                      font-size: 12px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 18px;
                    }
                  }
                }
              }

              .right {
                display: flex;
                width: fit-content;

                .action_buttons {
                  display: flex;
                  gap: 10px;

                  .button {
                    display: flex;
                    width: 50px;
                    justify-content: center;
                    align-items: center;
                    flex-shrink: 0;
                    border: 1px solid #eaedf3;
                    border-radius: 5px;
                  }
                }
              }
            }
          }
        }
      }
    }

    .lender_table {
      .documents_box {
        .table_row {
          border: none !important;

          .left {
            .item {
              align-items: flex-start !important;
            }
          }

          .right {
            gap: 25px;
            align-items: center;

            .item {
              width: auto !important;
            }

            .action_buttons {
              height: 100%;
              align-items: center;

              .button {
                height: 100%;
              }
            }
          }
        }
      }
    }
  }
}




.Payment_history_method{
  max-width: 50px;
}

.dot-icon-wrapper {
  position: relative;

  .dot-icon {
    cursor: pointer;
    transform: rotate(90deg);
  }

  .popup-menu {
     position: absolute;
    top: 115%;
    right: 0;
    background-color: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    z-index: 1000;
    padding: 10px;
    /* width: 150px; */
    animation: fadeIn 0.3s ease;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 158px;

    .popup-item {
      padding: 8px 12px;
      cursor: pointer;
      color: #333;
      font-size: 11px;
      display: flex;
      align-items: center;

      &:hover {
        background-color: #f0f0f0;
        color: #000;
      }
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.loader_for_Generatinginvoice {
  margin-left: 5px; 
}

.No_payment{
  
}