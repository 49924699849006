.Safefinancings-first-box-data-flex {
  display: flex;
  padding: 10px;
  gap: 20px;
}
.Safefinancings-first-box-data {

  display: flex;
}
.Safefinancings-first-box-data-flex {
  display: flex;
}
.text-data-safe h1 {
  color: #3e3f42;
  leading-trim: both;
  text-edge: cap;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
}

.text-data-safe p {
  color: var(--Primary-Gray-200, #9ea0a5);
  leading-trim: both;
  text-edge: cap;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  width: 300px;
  margin-top: 10px;
}

.container-data {
  padding: 10px;
  align-items: center;
  
}
.container-data p {
  color: var(--Black, #3e3f42);

  text-align: right;
  leading-trim: both;
  text-edge: cap;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 18.2px */
  letter-spacing: 0.056px;
}
.process-bar {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 10px;
  padding-top: 10px;
}

.button-components {
  display: flex;
  justify-content: space-between;
}

.button-componts-buttoncompo {
  display: flex;
  gap: 10px;
  height: fit-content;
  align-items: center;
}

.Safefinancings-received {
  border: 1px solid #eaedf3;
  padding: 20px;
  gap: 10px;
  width: 40%;
}
.progress-bar {
  width: 100%;
}
.processbar-data {
  height: 50px;
  align-items: center;

  align-items: center;
}


.safe-flex-box {
  display: flex;
  justify-content: space-between;
}
.flex-box-data {
  display: flex;
  width: fit-content;
}
.flex-box-data h1 {
  overflow: hidden;
  color: #3e3f42;
  text-align: center;
  display: flex;
  text-overflow: ellipsis;

  /* Text/Regular 14px */
  font-family: Roboto;
  font-size: 14px;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.Safefinancings-data {
  padding: 20px;
  margin-top: 10px;
  border: 1px solid #eaedf3;
}
.safe-pending {
  border: 1px solid #eaedf3;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 250px;
}
.safe-box-data {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.safe-second-box {
  display: flex;
  gap: 20px;
}
.safe-fincal-target {
  border: 1px solid #eaedf3;
  align-items: center;
  padding: 20px;
  width: 250px;
}
.safe-finance-data {
  width: 450px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.safe-finance-data {
  line-height: 25px;
}

.Safe-Carta {
  display: flex;
}
.Safe-Carta-data {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 10px;
}
.Safe-Carta-data span {
  color: var(--Primary-Purple-500, #7d55c7);
}
.safe-image {
  border-radius: 4px;
  height: fit-content;
  padding: 10px;
  margin-left: 10px;
  background: var(--Action-Blue, #1665d8);
}

.Safe-first-box-data {
  padding-left: 10px;
}
.Safe-first-box-Conatiner {
  border: 1px solid #eaedf3;
}
.shuttle {
  border-radius: 4px;
  background: var(--Action-Green, #50cd89);
  height: fit-content;
  height: 60px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin: 10px;
}

.Safe-Carta-data p {
  color: var(--Primary-Gray-200, #9ea0a5);
  leading-trim: both;
  text-edge: cap;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 157.143% */
  max-width: 75%;
}
.Safefinancings-Issue {
  padding: 0;
  margin: 0;
  height: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  border: none;
  width: 130px;
  color: black;
  background-color: white;
  border-radius: 4px;
  border: 1px solid var(--Primary-Purple-50, #eaedf3);
  background: #fff;
}
.button-Create {
  padding: 0;
  margin: 0;
  height: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  border: none;
  width: 130px;
  color: black;
  background-color: white;
  border-radius: 4px;
  border: 1px solid var(--Primary-Purple-50, #eaedf3);
  background: #fff;
}

.Safefinancings-Download {
  padding: 0;
  margin: 0;
  height: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  border: none;
  color: black;
  background-color: white;
  border-radius: 4px;
  border: 1px solid var(--Primary-Purple-50, #eaedf3);
  background: #fff;
}
.Safefinancings-Filter {
  padding: 0;
  margin: 0;
  height: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  border: none;
  color: black;
  background-color: white;
  border-radius: 4px;
  border: 1px solid var(--Primary-Purple-50, #eaedf3);
  background: #fff;
}
.Safefinancings-progress-bar {
  height: 50px;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 0px;
}
.safe-pending p{
  color: #3E3F42;  
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.Safefinancings-received p{
  color: #3E3F42;  
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}