.notes-main-container {
  width: 100%;
  box-sizing: border-box;
  padding: 0 30px;
  display: flex;
  gap: 30px;
  margin-top: 24px;
  @media screen and (max-width: 900px) {
      flex-direction: column;
  }
  .notes-main-left {
    > h1 {
      color: #3e3f42;
      font-family: Roboto;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 30px; /* 150% */
      margin-top: 10px;
      margin-bottom: 36px;
    }

    .notes-main {
      width: 665px;
      height: 1002px;
      flex-shrink: 0;
      border-radius: 4px;
      border: 1px solid #eaedf3;
      background: #fff;
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.04);
      margin-bottom: 30px;

      .notes-nav {
        margin: 0;
        padding: 23px 30px 0px 30px;
        height: 74px !important;

        > span {
          display: block;
          padding-bottom: 23px;
          color: #6b6c6f;
          font-family: Roboto;
          font-size: 18px !important;
          font-style: normal;
          font-weight: 400;
          line-height: 28px; /* 155.556% */
        }
        .ut-nav-active {
          color: #3e3f42;
          font-family: Roboto;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 28px; /* 155.556% */
        }
      }

      .counter-party-container {
        width: 100%;
        padding: 30px 30px;

        .counter-party-upper {
          // width: 670px;
          //   height: 153px;
          flex-shrink: 0;
          border-radius: 4px;
          border: 1px solid #eaedf3;
          background: #fff;
          padding: 16px 20px 12px 20px;
          margin-bottom: 24px;

          .c-p-u-u {
            display: flex;
            gap: 16px;
            margin-bottom: 36px;
            > img {
              border-radius: 50%;
            }
            input {
              border: none;
              outline: none;
              &::placeholder {
                color: #9ea0a5;
                font-family: Roboto;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px; /* 157.143% */
              }
            }
          }

          .c-p-u-d {
            display: flex;
            gap: 10px;

            align-items: center;
            > div:first-child {
              padding: 8px 12px;
              border-radius: 4px;
              border: 1px solid #eaedf3;
              display: flex;
              align-items: center;
              gap: 10px;
            }
            .inp {
              margin-top: 0;
              width: 80%;
            }
          }
        }

        .counter-party-center {
          border-radius: 4px;
          border: 1px solid #eaedf3;
          background: #fff;

          > h3 {
            color: #3e3f42;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px; /* 157.143% */
            margin-left: 20px;
          }

          .c-p-c-i {
            padding: 15px 20px 20px 20px;
            display: flex;

            gap: 10px;
            .pic-left > img {
              width: 376px;
              height: 340px;
              flex-shrink: 0;
              max-width: none;
            }

            pic-right {
              width: 245px;
              height: 165px;
              flex-shrink: 0;
              max-width: none;
              display: flex;
              flex-direction: column;
              gap: 15px;
            }
          }

          .c-p-comment {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 8px;
            > div {
              display: flex;
              align-items: center;
              gap: 133px;
              > div {
                display: flex;
                align-items: center;
                gap: 14px;
                > span {
                  color: #3e3f42;
                  font-family: Roboto;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 22px; /* 157.143% */
                }
              }
            }
          }
        }
      }

      .user-card {
        display: flex;
        justify-content: space-between;
        padding: 11px 20px;
        .left {
          display: flex;
          gap: 16px;
          > img {
            height: 38px;
            width: 38px;
            border-radius: 50px;
          }
          > div > h3 {
            color: #3e3f42;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px; /* 157.143% */
          }
          > div > span {
            color: #9ea0a5;
            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px; /* 150% */
          }
        }
        .right {
          display: flex;
          align-items: center;
          gap: 10px;
        }
      }

      .counter-party-down {
        border-radius: 4px;
        border: 1px solid #eaedf3;
        background: #fff;
        margin-top: 24px;
      }
    }
  }

  .notes-main-right {
    .search-for-note {
      width: 350px;
      height: 454px;
      border-radius: 4px;
      border: 1px solid #eaedf3;
      background: #fff;
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.04);
      margin-bottom: 30px;
      
      .search-f-n-header {
        margin: 23px 0;
        padding: 0 30px 25px 30px;
        border-bottom: 1px solid #eaedf3;

        > h3 {
          color: #3e3f42;
          font-family: Roboto;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 28px; /* 155.556% */
        }
        > span {
          color: #9ea0a5;
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px; /* 157.143% */
        }
      }

      .search-f-n-main {
        padding: 0 30px;
        .btn {
          border-radius: 4px;
          border: 1px solid #d8dce6;
          background: linear-gradient(0deg, #f6f7f9 0%, #fff 100%);
          box-shadow: 0px 1px 1px 0px rgba(22, 29, 37, 0.05), 0px 2px 0px 0px rgba(255, 255, 255, 0.05) inset;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 30px;
          gap: 5px;
          > span {
            color: #3e3f42;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px; /* 157.143% */
          }
        }
      }
    }

    .tags-notes-container {
      width: 350px;
      height: 374px;
      flex-shrink: 0;
      border-radius: 4px;
      border: 1px solid #eaedf3;
      background: #fff;
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.04);
      .search-f-n-header {
        // margin: 23px 0;
        padding: 23px 30px;
        border-bottom: 1px solid #eaedf3;
        display: flex;
        gap: 6px;
        > h3 {
          color: #3e3f42;
          font-family: Roboto;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 28px; /* 155.556% */
        }
        > span {
          color: var(--LP-gray, #9ea0a5);
          font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 28px;
        }
      }

      .tags-main-wrapper {
        .notes-tag {
          height: 60px;
          border-bottom: 1px solid #eaedf3;

          padding: 20px 30px;
          display: flex;
          justify-content: space-between;
          .notes-tag-left {
            display: flex;
            gap: 20px;
            align-items: center;
            > span {
              color: #3e3f42;
              font-family: Roboto;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 22px; /* 157.143% */
            }
          }
          > div {
            color: var(--LP-gray, #9ea0a5);
            text-align: right;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 28px; /* 200% */
          }
        }
      }
    }
  }
}
