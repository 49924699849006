.header-draft-share {
  display: flex;
  justify-content: space-between;
}

.Draftshares-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.Share-Draft {
  display: flex;
  height: 74px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  align-items: center;
}

.search-input {
  border: none;
  outline: none;
  font-size: 17px;
  border: none;
  outline: none;
  border-radius: 5px;
}
// Button
.custom-class {
  margin-bottom: 50px;
  width: 200px;
  margin-left: 10px;
}

.search-button {
  border: none;
  outline: none;
}
.Custom-hr-tag {
  background-color: rgb(170, 169, 169);
}
.draft-share-data {
  display: flex;
  justify-content: space-between;
  height: 50px;
  align-items: center;
}
.draft-share-data h1 {
  color: var(--Black, #3e3f42);
  leading-trim: both;
  text-edge: cap;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  text-transform: uppercase;
}

.box-data {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30vh;
}

.Error-Container {
  border: 1px solid #eaedf3;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 350px;
  height: 200px;
  align-items: center;
  text-align: center;
}

.search-input-container {
  // border: 1px solid #eaedf3;
  padding: 10px;
  align-items: center;
  border-radius: 5px;
}
.search-input-container input {
  border: none;
  outline: none;
}
.box-data-container-image {
  background-color: rgb(239, 235, 248);
  width: 80px;
  height: 80px;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  margin: auto;
}

.draft-share-data-container {
  border-bottom: 1px solid #eaedf3;
  border-top: 1px solid #eaedf3;
}
