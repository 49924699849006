.activity_history {
    .box_wrapper {
        display: flex;
        flex-direction: column;
        gap: 20px;

        p {
            color: #9EA0A5;
            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px;

        }
    }
}


.activity_history_box {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
    border-bottom: 1px solid #eaedf3;
    max-width: 500px;

    .box_comment {
        padding: 16px;
        width: 347px;
        height: 117px;
        background-color: #FBFBFD;
        color: #3E3F42;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        border: 1px solid #E2E5ED;
        border-radius: 3px;
    }

    .comment {
        display: flex;
        align-items: center;
        gap: 6px;
        color: var(--purple-pantone-2665-c, #7D55C7);
        font-family: Roboto;
        font-size: 12px;
        font-weight: 400;
        margin: 14px 0;
        img {
            width: 16px !important;
            height: 7px !important;
        }
    }

    .box_left {
        display: flex;
        justify-content: center;
        gap: 20px;

        p {
            max-width: 347px;
            color: #3E3F42;
            font-family: Roboto;
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
        }

        span {

            color: #9EA0A5;
            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
        }

        img {
            height: 38px;
            width: 38px;
        }
    }
}