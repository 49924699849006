.password_upper {
    border: 1px solid #EAEDF3;
    filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.04));

    .inputs {
        padding: 20px;
    }

    .update_btn {
        background-color: transparent;
        border-radius: 4px;
        color: #b6b6b6 !important;
        width: 145px;
        height: 38px;
        outline: none;
        border: 1px solid #EAEDF3;
        font-size: 14px;
        margin-top: 30px;
        cursor: not-allowed; 
        transition: background-color 0.3s ease-in-out; 
    }
    
    .update_btn.enabled {
        background-color: #7d55c7;
        color: #ffffff !important;
        cursor: pointer; 
        border: none; 
        margin-top: 56px;
    }
    
}

.password_lower {
    border: 1px solid #EAEDF3;
    filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.04));

    .inputs {
        padding: 20px;
    }

    .privacy_row {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
}

.password_match {
    color: #9EA0A5;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    margin-right: 184px;
    display: flex;
    align-items: center;
    gap: 4px;
}


.progress-bar {
    height: 8px;
    background-color: lightgray;
    border-radius: 4px;
}

.progress-bar-inner {
    height: 100%;
    transition: width 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.progress-bar-text {
    color: #9EA0A5;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    margin-top: 10px;
}


.Old-Password-input{
    background-color: #a386d7;
    width: 50%;
}

.password-genral-input{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 50px;
}