.SignupPricing.questions {
  .pricingContainerT {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    h1 {
      width: 1110px;
    }
  }
  .activeSlider {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: #d8d8d9;
    height: 2px;
    width: 1110px;
    margin-top: 26px;
    box-sizing: border-box;
    @media screen and (max-width: 1200px) {
      width: 1021px;
    }
    @media screen and (max-width: 1100px) {
      width: 934px;
    }
    @media screen and (max-width: 1000px) {
      width: 806px;
    }
    @media screen and (max-width: 900px) {
      width: 708px;
    }
    @media screen and (max-width: 800px) {
      width: 571px;
    }
    // @media screen and (max-width: 700px) {
    //   width: 571px;
    // }

    .sliderItem {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 10px;
      position: absolute;
      top: -12px;
      color: var(--LP-grey-_lib, #9ea0a5);
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 16.8px */
      text-transform: capitalize;
      .sliderCircle {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        border: 2px solid #d4d4d4;
        background-color: #ffffff;
      }
      &.active {
        color: var(--LP-Pantone, #7d55c7);
        .sliderCircle {
          border: 2px solid #a386d7 !important;
        }
      }
      &.one {
        left: 0%;
        align-items: flex-start;
      }

      &.two {
        left: 8.5%;
      }
      &.three {
        left: 19%;
      }
      &.four {
        left: 29.5%;
      }
      &.five {
        left: 41%;
      }
      &.six {
        left: 52.5%;
      }
      &.seven {
        left: 66%;
      }
      &.eight {
        left: 76.5%;
      }
      &.nine {
        left: 87.5%;
      }
      &.ten {
        left: 97%;
        align-items: flex-end;
        z-index: 2;
        .sliderELine {
          position: absolute;
          width: 80px;
          top: 12px;
          z-index: -1;
  
          background-color: #d8d8d9;
          height: 2px;
        }
      }
    }

  }

  .sectionSignupQ-Slide-Con {
    position: relative;
    left: 30px;
    width: 1160px;
    overflow: hidden;
    display: -webkit-inline-box;

    &.one {
      .sectionSignupQ {
        left: 0px;
      }
    }

    &.two {
      .sectionSignupQ {
        left: -1160px;
      }
    }

    &.three {
      .sectionSignupQ {
        left: -2320px;
      }
    }

    &.four {
      .sectionSignupQ {
        left: -3480px;
      }
    }

    &.five {
      .sectionSignupQ {
        left: -4640px;
      }
    }

    &.six {
      .sectionSignupQ {
        left: -5800px;
      }
    }

    &.seven {
      .sectionSignupQ {
        left: -6960px;
      }
    }

    &.eight {
      .sectionSignupQ {
        left: -8120px;
      }
    }

    &.nine {
      .sectionSignupQ {
        left: -9280px;
      }
    
    }
    &.ten {
      .sectionSignupQ {
        left: -10440px;
      }
    }
  }
  .sectionSignupQ {
    position: relative;
    left: 0px;
    width: 1160px;

    .sectionSignupQ-T {
      h1 {
        margin-top: 30px;
        color: var(--LP-black--_lib, #3e3f42);
        font-family: Roboto;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px; /* 116.667% */
        display: flex;
        align-items: center;
        // justify-content: center;
        gap: 10px;
      }
      h2 {
        color: var(--LP-mid-grey-_lib, #6b6c6f);
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 157.143% */
        margin-top: 10px;
      }
    }
  }

  .FirstSectionSQ {
    .sectionSignupQ-B {
      margin-top: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .stragySelector {
        width: 255px;
        height: 381px;
        padding: 0 20px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        border: 1px solid #e2e5ed;
        background: #fff;

        h1 {
          //   margin-top: 20px;
          color: #3e3f42;
          text-align: center;
          font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
        }
        h2 {
          margin-top: 12px;
          color: #6b6c6f;
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px; /* 157.143% */
          min-height: 132px;
        }
        h3 {
          color: var(--LP-gray, #9ea0a5);
          font-family: Roboto;
          font-size: 10px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
        h4 {
          color: #3e3f42;
          font-family: Roboto;
          font-size: 10px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px; /* 220% */
        }
        .spacerstrategy {
          width: 215px;
          height: 1px;
          flex-shrink: 0;
          margin-top: 40px;
          margin-bottom: 12px;
          background: var(--LP-very-light, #eaedf3);
        }
        .svgWholeCon {
          display: flex;
          width: 100%;
          justify-content: space-between;
          margin-bottom: 20px;
          .svgCon {
            display: flex;
            gap: 6px;
            margin-right: 10px;
          }
        }
        .strategyBtn {
          cursor: pointer;
          border-radius: 4px;
          border: 1px solid var(--purple-pantone-2665-c-71, rgba(125, 85, 199, 0.71));
          background: linear-gradient(0deg, rgba(125, 85, 199, 0.3) 0%, rgba(255, 255, 255, 0) 107.78%), #7d55c7;
          background-blend-mode: multiply, normal;
          box-shadow: 0px 1px 1px 0px rgba(19, 31, 21, 0.1), 0px 2px 0px 0px rgba(255, 255, 255, 0.06) inset;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          text-align: center;
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px; /* 157.143% */
          width: 215px;
          padding: 8px 0;
          box-sizing: border-box;
        }
      }
      .investment_objectives {
        width: 100%;
        border-radius: 4px;
        border: 1px solid #e2e5ed;
        background: #fff;
        .top {
          border-bottom: 1px solid #e2e5ed;
          display: flex;
          padding: 20px 30px;
          gap: 20px;
          p {
            color: #9ea0a5;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px; /* 157.143% */
          }
        }
        .bottom {
          padding: 30px;

          .upper,
          .lower {
            display: flex;
            gap: 40px;
            .selector_container {
              width: 324px;
              .dropDownOuterCon p {
                color: #515151;

                font-family: Roboto;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 18px; /* 150% */
                text-transform: uppercase;
                width: 100%;
              }
              p {
                color: #9ea0a5;
                font-family: Roboto;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px; /* 157.143% */
                width: 70%;
              }
            }
          }
        }
      }
      .investment_vehicles {
        margin-bottom: 20px;
        width: 99%;
        flex-direction: row;
        display: flex;
        justify-content: space-between;
        .container {
          width: 350px;
        }
      }
      .location {
        width: 100%;
        display: flex;
        .left {
          min-width: 40%;
        }
        .right {
          width: 100%;
          .container {
            width: 200px;
          }
        }
        .lower {
          margin-top: 20px;
          p {
            color: #3e3f42;

            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px; /* 157.143% */
          }
          .input_Wrapper {
            .inputBoxMain {
              width: 100%;
            }
            .delete_button {
              margin-top: 45px;
              padding-left: 10px;
            }
            gap: 40px;
            display: flex;
            align-items: center;

            .dropDownOuterCon {
              width: 27%;
            }
            input {
              height: auto;
            }
          }
        }
      }
      .marketSize {
        width: 100%;
      }
      .property_row {
        padding: 20px 0px 40px 0px;
        .title {
          h2 {
            display: flex;
            align-items: center;
            gap: 10px;
            color: var(--LP-black--_lib, #3e3f42);
            font-family: Roboto;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 28px; /* 155.556% */
            margin-bottom: 20px;
          }
        }
        .boxes_container {
          .investment_vehicles {
            flex-wrap: wrap;
            gap: 20px;
            .container {
              width: 360px;
            }
            &.twoBoxes {
              justify-content: left;
            }
          }
        }
        .checkBoxCon {
          margin-top: 30px;
          display: flex;
          align-items: center;
          gap: 12px;
          p {
            margin-top: 4px;
            color: #3e3f42;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px; /* 157.143% */
          }
        }
        .investment_objectives {
          width: 99%;
          .sliders_wrapper {
            padding: 30px;
            .switch_wrapper {
              align-self: center;
              margin-top: 14px;
            }
            .upper,
            .middle,
            .lower {
              display: flex;
              gap: 30px;
              align-items: end;
            }
            .dropDownOuterCon {
              max-width: 320px;
            }
            .slider_container {
              margin: 10px 0px;
              position: relative;
              .top_div {
                // font-size: 12px;
                border-radius: 4px;
                border: 1px solid #e4e8ee;
                padding: 6px 10px;
                z-index: 2;
                p {
                  font-size: 14px;
                  text-align: center;
                  margin: 0px;
                }
                span {
                  color: var(--LP-black, #3e3f42);
                  text-align: right;
                  font-family: Roboto;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 22px; /* 157.143% */
                }
              }
              .MuiSlider-root {
                position: absolute;
                z-index: 1;
                top: 12px;
                .MuiSlider-rail {
                  color: #b7b7b7 !important;
                }
                .MuiSlider-track,
                .MuiSlider-thumb {
                  color: #7d55c7;
                  width: 18px;
                  height: 18px;
                }
                .MuiSlider-track {
                  height: 2px;
                }
              }
            }
          }
        }
      }
      .hold_period {
        width: 100%;
        .investment_vehicles {
          display: flex;
          gap: 20px;
        }
        .container {
          width: 100%;
        }
      }
      .current_data {
        width: 99%;
        .investment_objectives {
          .bottom {
            margin: 0 auto;
            padding: 30px;
            width: 50%;
          }
        }
        .additional_parameters {
          margin-top: 40px;
          .title {
            h2 {
              display: flex;
              align-items: center;
              gap: 10px;
              color: var(--LP-black--_lib, #3e3f42);
              font-family: Roboto;
              font-size: 18px;
              font-style: normal;
              font-weight: 500;
              line-height: 28px; /* 155.556% */
              margin-bottom: 20px;
            }
            span {
              color: var(--LP-mid-grey-_lib, #6b6c6f);
              font-family: Roboto;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 22px; /* 157.143% */
            }
          }
          .switches_wrapper {
            .spacerLine30 {
              margin: 15px 0px;
            }
            .row {
              .container {
                display: flex;
                width: 100%;
                align-items: center;
                justify-content: space-between;
                p {
                  color: #3e3f42;
                  font-family: Roboto;
                  font-size: 15px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 18px; /* 157.143% */
                }
                .switch_wrapper {
                  display: flex;
                  align-items: center;
                }
              }
            }
          }
        }
      }
      .prospects {
        width: 99%;
        .property_row {
          padding-top: 0px;
          .title {
            h2 {
              color: #6b6c6f;
            }
          }
          .investment_objectives {
          }
          .strategies {
            display: flex;
            width: 100%;
            justify-content: space-between;
            gap: 30px;
            .strategy_card {
              // width: 100%;
              flex-grow: 1;
              padding: 30px;
              border-radius: 4px;
              border: 1px solid #eaedf3;
              background: #fff;
              box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.04);
              max-width: 350px !important;
              .title {
                h2 {
                  color: #3e3f42;
                  justify-content: space-between;
                  font-family: Roboto;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 24px; /* 150% */
                }
              }
              p {
                color: #9ea0a5;
                font-family: Roboto;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 18px; /* 150% */
                text-transform: uppercase;
              }
              .textarea_wrapper {
                position: relative;
                // padding: 8px 12px;
                width: 100%;
                margin: 8px 0px;
                textarea {
                  all: unset;
                  padding: 16px;
                  width: 90%;
                  border-radius: 4px;
                  border: 1px solid #e2e5ed;
                  background: #fff;
                  box-shadow: 0px 1px 2px 0px rgba(102, 113, 123, 0.21) inset;
                  ::placeholder {
                    color: #9ea0a5;
                    font-family: Roboto;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 22px; /* 157.143% */
                  }
                }
                .buttons_wrapper {
                  position: absolute;
                  bottom: 16px;
                  left: 16px;

                  align-items: baseline;
                  display: flex;
                  width: 90%;
                  justify-content: space-between;
                  button {
                    all: unset;
                    border-radius: 4px;
                    border: 1px solid var(--LP-very-light, #eaedf3);
                    background: #fff;
                    display: inline-flex;
                    padding: 4px 12px;
                    align-items: center;
                    gap: 10px;
                    span {
                      color: var(--LP-mid-gray, #6b6c6f);
                      font-family: Roboto;
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 22px; /* 157.143% */
                      text-transform: capitalize;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  &.lenderQuestions {
    .activeSlider .sliderItem {
      &.two {
        left: 25%;
      }
      &.three {
        left: 50%;
      }
      &.four {
        left: 75%;
      }
      &.five {
        left: 98%;
        text-wrap: nowrap;
      }
    }
    .sectionSignupQ-B .current_data .investment_objectives .bottom {
      width: auto;
      &.loan_figures {
        width: 70%;
        .dropdowns {
          .loan_amount {
            display: flex;
            gap: 40px;
          }
          .slider_button {
            display: flex;
            align-items: center;
            gap: 45px;
            .switch_wrapper {
              margin-top: 10px;
            }
            .dropDownOuterCon {
              max-width: 47%;
            }
          }
        }
      }
      .label {
        color: #9ea0a5;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px; /* 150% */
        text-transform: uppercase;
        padding-bottom: 20px;
      }
      .label2 {
        padding-top: 20px;
        color: #9ea0a5;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px; /* 150% */
        text-transform: uppercase;
        padding-bottom: 20px;
      }
    }
    .loan_type {
      max-width: 30%;
    }
    .switches_wrapper {
      padding-top: 20px;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .row {
        width: 48%;
      }
    }
  }
}
.slider_container {
  margin: 10px 0px;
  position: relative;
  .top_div {
    // font-size: 12px;
    border-radius: 4px;
    border: 1px solid #e4e8ee;
    padding: 6px 10px;
    z-index: 2;
    p {
      font-size: 14px;
      text-align: center;
      margin: 0px;
    }
    span {
      color: var(--LP-black, #3e3f42);
      text-align: right;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 157.143% */
    }
  }
  .MuiSlider-root {
    position: relative;
    z-index: 1;
    top: 8px;
    margin-top: -11px;
    .MuiSlider-rail {
      color: #b7b7b7 !important;
    }
    .MuiSlider-track,
    .MuiSlider-thumb {
      color: #7d55c7;
      width: 18px;
      height: 18px;
    }
    .MuiSlider-track {
      height: 2px;
    }
  }
}

.stragySelector:hover {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
}
.button-Submit-Continue {
  width: 174px;
}


.SignupQuestionsNew-flexlineC {
  // margin-top: 20%;
  display: flex;
  align-items: center;
  //   justify-content: flex-start;
  gap: 15px;
  .submit_button{
    background-color: #d8d8d9;
  }
}
.SignupQuestionsNewDropdown{
   width: 250px;
}


.validation-error{
  margin-top: 10px;
  color: red;
leading-trim: both;
text-edge: cap;
font-family: Roboto;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 22px; /* 157.143% */
}