.keyword-message {
  position: relative;
  background-color: #7d55c7;
  padding: 20px;
  border-radius: 0px 30px 30px 30px;
  color: white;
  max-width: 65%;
  margin-bottom: 20px;
  font-family: "Arial", sans-serif;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 10px;

  .paragraph {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-weight: 400;

    h4 {
      margin-top: 15px;
    }
    ul {
      margin-top: 13px;
    }

    ul li strong {
      font-weight: 600;
      font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
      margin-right: 5px;
    }
  }

  p {
    color: #fff;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }

  ol {
    padding-left: 15px;
    list-style: disc;
    li {
      strong {
        color: #fff;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
        font-size: 14px;
        font-weight: 600;
        line-height: 19px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
      }
    }
  }

  .keyword-details {
    display: flex;
    padding: 15px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    border-radius: 20px;
    background: #fff;
    h3 {
      color: black;
    }

    p {
      color: #3e3f42;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 17px; 
      width: 100%;
      align-items: flex-start;
    }

    h4 {
      color: #3e3f42;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 800;
      line-height: 19px; 
    }

    ul,
    ol {
      list-style: disc;
      padding-left: 15px;
      li {
        color: #3e3f42;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 17px; 

        strong {
          color: #3e3f42;
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 17px;
        }
      }
    }

    strong {
      display: block;
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 5px;
    }

    code {
      background-color: #f3f4f6;
      padding: 4px 8px;
      border-radius: 4px;
      font-size: 13px;
      color: #d6336c;
    }

    pre {
      background-color: #f8f8f8;
      padding: 12px;
      border-radius: 6px;
      overflow-x: auto;
      margin: auto;
      font-family: "Courier New", monospace;
      margin-bottom: 20px;
      border: 1px solid #eaedf3;
      width: 100%;
      height: 100%;
      max-width: 530px;
      max-height: 386px;
      padding: 30px 20px 20px 20px;
      gap: 15px;
      border-radius: 33.97px;
      border: 1px 0px 0px 0px;
      opacity: 0px;
    }

    .menu-button {
      position: relative;
      margin-left: 10px;
      display: flex;
    }
  }

  .keyword-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    margin-bottom: 10px;

    strong {
      margin-top: 0;
    }
  }

  button {
    font-size: 1.2em;
    cursor: pointer;
    background: transparent;
    border: none;
    color: black;
  }
}

.apexcharts-canvas {
  margin-top: 15px;
  max-width: 100%;
}

.ReactMarkdown {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #333;
    margin-top: 15px;
    margin-bottom: 10px;
  }

  p {
    margin: 10px 0;
    color: #555;
  }

  a {
    color: #7d55c7;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }
}

// three button -----------------

// popup -
.feedback-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.feedback-modal {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 500px;
  text-align: center;
}

.close-modal-btn {
  margin-top: 20px;
  padding: 10px 20px;
  background: #ccc;
  border: none;
  cursor: pointer;
  border-radius: 4px;
}

.Recommendations_status {
  width: 85px;
  height: 26px;
  padding: 0.3em 0.5em;
  gap: 0px;
  border-radius: 5px;
  opacity: 1;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.Recommendations_status.Implemented {
  color: white;
  background-color: #302676;
}

.Recommendations_status.Scheduled {
  border: 1px solid #ef911a;
  background: #c1741026;
}

.Recommendations_status.Assigned {
  background-color: #006df526;
  border: 1px solid #006df5;
}

.Recommendations_status_text {
  font-family: Roboto;
  font-size: 11px;
}

.Recommendations_Container {
  display: flex;
  align-items: center;
  gap: 5px;
}

.KeyWords_piecharts {
    width: 100%;
    // height: 374px;
    display: flex;
    flex-direction: column;
    gap: 13px;
}
.KeyWords_LineGraph {
  width: 100%;
  height: 415px;
  display: flex;
  flex-direction: column;
  gap: 13px;
  margin-top: 30px;
}

.chart {
  width: 530px;
  padding: 30px 20px 20px 20px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 10px;
  border-radius: 33.974px;
  border: 1px solid #eaedf3;
  background: #fcfcfc;
}

li::before,
li::after {
  content: none; 
}

.bubble p {
  color: #fff;
  font-family: Roboto;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
}


.KeyWords_circular-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;

  .KeyWords_spinner {
    border: 3px solid transparent;
    border-top: 3px solid #007bff; /* Adjust color as needed */
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: spin 1s linear infinite;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.plusonemore{
  font-size: 10px;
}