.maintenance-table {
  overflow: auto;
  max-width: 88vw;

  h4 {
    font-family: Roboto;
    color: #3e3f42;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
  }

  .maintenance_table_wrapper {
    width: 100% !important;
    overflow: auto !important;
    .ant-table-wrapper {
      margin-top: 20px;
      border: 1px solid #eaedf3;
      border-radius: 5px;
      box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
      width: 100%;
      max-width: 100%;
      overflow: auto;
      .ant-table {
      }

      .ant-table-content {
        overflow: auto;
        max-width: 100vw;
        .ant-table-thead {
          tr {
            background: transparent;
            th {
              background: transparent;
              color: #000;
              text-wrap: nowrap;
            }
          }
        }
        tr {
          td {
            text-wrap: nowrap;
          }
        }
      }
      .ant-pagination {
        max-width: 100%;
      }
    }
  }

  // .styled-table {
  //   border-collapse: separate !important;
  //   border-spacing: 0 !important;

  //   border-top-left-radius: 20px !important;
  //   border-top-right-radius: 20px !important;
  //   border-bottom-left-radius: 20px !important;
  //   border-bottom-right-radius: 20px !important;

  //   thead {
  //     border-top-left-radius: 20px !important;
  //     border-top-right-radius: 20px !important;
  //     border-bottom-left-radius: 20px !important;
  //     border-bottom-right-radius: 20px !important;
  //   }
  //   tr {
  //     //   border-top-left-radius: 20px !important;
  //     //   border-top-right-radius: 20px !important;
  //     //   border-bottom-left-radius: 20px !important;
  //     //   border-bottom-right-radius: 20px !important;
  //   }
  //   .table_header {
  //     th {
  //       border: none;
  //       &:first-child {
  //         border-top-left-radius: 20px !important;

  //         border: none !important;
  //       }
  //       &:last-child {
  //         border-top-right-radius: 20px !important;
  //       }
  //     }
  //   }

  //   tbody {
  //     tr {
  //       &:last-child {
  //         border-bottom-left-radius: 20px !important;

  //         td {
  //           &:first-child {
  //             border-bottom-left-radius: 20px !important;
  //           }
  //         }
  //       }
  //     }

  //     tr {
  //       &:last-child {
  //         border-bottom-right-radius: 20px !important;

  //         td {
  //           &:last-child {
  //             border-bottom-right-radius: 20px !important;
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
}
