.investment_internal_container {
  margin-left: 10px;

  .investment_internal_nav_panel {
    display: grid;
    grid-template-columns: 40% 60%;
    align-items: center;
    height: 70px;
    @media screen and (max-width: 1100px ) {
    grid-template-columns: 100%;
    grid-gap: 30px;
    }
    box-shadow: (0px 1px 0px rgba(0, 0, 0, 0.06));
    .deals-room-nav.gp_nav.asset-nav.ass-nav {
      width: 100% !important;
      margin: 0 !important;
      border-bottom: 0 !important;
      padding-left: 30px;
      span {
        height: 56px !important;
        color: #6b6c6f !important;
        font-family: Roboto;
        font-size: 18px !important;
        font-style: normal;
        font-weight: 400;
        line-height: 28px; /* 155.556% */
        .no_of_notifications {
          color: #9ea0a5;
          font-family: Roboto;
          font-size: 14px !important;
          font-style: normal;
          font-weight: 400;
          line-height: 22px; /* 157.143% */
        }

        &.deals-room-nav-active {
          color: #3e3f42 !important;
          font-weight: 700 !important;
        }
      }
    }

    .rightSideMenu {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 18px;
      justify-items: center;
      align-items: center;
      padding-right: 30px;

      > div {
        width: 100%;
        border-right: 1px solid #eaedf3;

        &:last-child {
          border-right: 0;
        }
      }

      .searchBar_nav {
        padding-right: 18px;
        .search_bar_box {
          display: flex;
          flex-direction: row;
          gap: 10px;
          padding: 10px 20px;
          justify-content: center;
          padding-right: 25px;

          img {
            min-width: 22px;
            min-height: 22px;
          }

          &.active {
            border: 1px solid #d7d7d7b3;
            border-radius: 20px;
          }
          input {
            border: 0;
            outline: 0;
            padding-right: 10px;
            max-width: 100px;
            &:focus-visible {
              border: 0;
              outline: 0;
            }
          }
        }
      }

      .option_btn_container {
        display: flex;
        flex-direction: row;
        gap: 10px;
        padding-right: 18px;
        .option_btn_wrapper {
          button {
            width: 100%;
            min-width: 110px;
            height: 38px;
            border: 1px solid #e2e5ed;
            background: var(--ffffff-white, #fff);
            border-radius: 6px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #3e3f42;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px; /* 157.143% */

            &:first-child {
              gap: 20px;
            }
          }
        }
        .action_btn_wrapper {
          position: relative;
          .del_btn_wrapper {
            position: absolute;
            margin-left: 20px;
            margin-top: 2px;
            button {
              padding: 0 10px;
              max-width: 90px;
              min-width: min-content;
              color: red;
              width: 100%;
              img {
                width: 20px;
                height: 20px;
              }
            }
          }
        }
        .filter_btn_wrapper {
          position: relative;
          .filter_options_wrapper {
            position: absolute;
            z-index: 12;
            border: 1px solid #e2e5ed;
            background: var(--ffffff-white, #fff);
            border-radius: 6px;
            margin-top: 2px;
            .filter_options_dropdown_section {
              padding: 10px 20px;

              .filter_options_dropdown_header {
                text-wrap: nowrap;
                color: #9ea0a5;
                font-family: Roboto;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 18px; /* 150% */
                text-transform: uppercase;
                margin-bottom: 5px;
              }
              label {
                display: flex;
                color: var(--LP-black, #3e3f42);
                text-align: right;
                font-family: Roboto;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;
                align-items: center;
                gap: 10px;
                text-wrap: nowrap;

                input {
                  cursor: pointer;
                }
              }
            }
          }
        }
      }

      .cloud_download_btn {
        display: flex;
        justify-content: center;
        height: 100%;
        button {
          background: none;
          border: none;
          outline: none;
          padding-right: 18px;
          img {
            min-width: 22px;
            min-height: 22px;
          }
        }
      }
      .layout_btns {
        display: flex;
        justify-content: space-between;
        gap: 20px;
        height: 100%;
        @media screen and (max-width: 1000px ) {
        justify-content: center;
        gap: 0;
        }
        button {
          padding-right: 18px;
          background: none;
          border: none;
          outline: none;

          &.active_layout {
            svg {
              path {
                fill: #7d55c7;
              }
            }
          }

          img {
            min-width: 22px;
            min-height: 22px;
          }
        }
      }
    }
  }

  .investment_internal_content_container {
    padding: 30px;
    background: #fbfbfd;
    .investment_internal_content_wrapper {
      display: flex;
      flex-direction: column;
      gap: 31px;
      .topSection {
        display: flex;
        gap: 31px;
        width: 100%;
        justify-content: center;
        align-items: center;
        @media screen and (max-width: 1100px ) {
        flex-direction: column;
        }
        .chart_container {
          width: 100%;
          max-width: 730px;
          box-shadow: (0px 1px 3px rgba(0, 0, 0, 0.04));
          background: #fff;
        }
        .bar_chart_container {
          width: 100%;
          max-width: 350px;
          height: 100%;
          box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.04);
          background: #fff;

          .topSection {
            padding: 18px 30px;
            justify-content: space-between;
            border-bottom: 1px solid #eaecee;
            button {
              padding: 16px 8px;
              width: 100%;
              max-width: 134px;
              gap: 13px;
              height: 38px;
              border: 1px solid #e2e5ed;
              background: var(--ffffff-white, #fff);
              border-radius: 6px;
              display: flex;
              align-items: center;
              justify-content: center;
              text-wrap: nowrap;
            }
          }
        }
      }
      .bottomSection {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        @media screen and (max-width: 1100px ) {
        justify-content: flex-start;
        }
        .data_table_wrapper {
          width: 100%;
          border-radius: 4px;
          border: 1px solid #eaedf3;
          background: #fff;
          box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.04);
          @media screen and (max-width: 1100px ) {
          max-width: 100%;
          overflow: auto;
          }
        }

        .data_card_wrapper {
          width: 100%;
        }
      }
    }
  }
}
