.overview {
  display: flex;
  justify-content: center;
  background-image: url("../../assets/svg/overviewbottom.svg");

  background-position-y: 400px;
  background-repeat: no-repeat;
  .overview_content {
    height: 100vh;
    width: 85%;
    display: flex;
    margin-top: 80px;
    justify-content: space-between;

    @media screen and (max-width:1000px) {
      height: 100vh;
      width: 86%;
      display: flex;
      margin-top: 80px;
      justify-content: space-between;
      /* flex-direction: column-reverse; */
      flex-direction: column;
      align-items: center;
      margin: auto;
      padding-top: 50px;
      gap: 50px;
    }
    .left {
      padding-left: 60px;
      .PrimaryButton {
        margin-top: 0px;
        max-width: 160px;
        display: inline-flex;
        padding: 18px 40px;
        align-items: center;
        gap: 12px;
      }
      display: flex;
      flex-direction: column;
      gap: 65px;
      max-width: 560px;

      h1 {
        color: var(--purple-pantone-2665-c, #7d55c7);
        font-family: Roboto Slab;
        font-size: 48px;
        font-style: normal;
        font-weight: 300;
        line-height: 58px; /* 120.833% */

        @media screen and (max-width:1000px) {
          font-size: 40px;
        }
      }
      p {
        color: var(--LP-mid-gray, #6b6c6f);
        font-family: Roboto;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 25px; /* 138.889% */
      }
    }
  }
}
