.header-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-between;

  .header-container-box {
    border: 1px solid #eaedf3;
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.15);
    display: flex;
    flex: 1;
    padding: 5px 10px;
    align-items: center;
    &-data {
      display: flex;
      gap: 50px;
      padding: 10px;
      border: 1px solid #eaedf3;
      width: 100%;
      margin: 10px;
    }
  }

}

.box-data {
  .box-data-container-maindiv {
    .Error-Container {
      display: flex;
      flex-direction: column;
      align-items: center;

      .box-data-container-image {
        margin-bottom: 10px;
      }
    }
  }
}


@media (max-width: 768px) {
  .header-container {
    flex-direction: column;

    .header-container-box {
      width: 100%;
      margin-bottom: 10px;
    }

    .back-report {
      width: 100%;
    }
  }
}
