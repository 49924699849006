.Company-Settings-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 3fr)); /* Adjust the min and max width as needed */
  gap: 10px;
}

.Company-Settings-container .PrimaryButton {
  padding: 10px;
  margin: 0;
  height: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  border: none;
  font-size: 12px;
  color: var(--primary-gray-100-text, #9ea0a5);
  background-color: white;
  border-radius: 4px;
  border: 1px solid var(--Primary-Purple-50, #eaedf3);
  background: #fff;
  flex: 0 0 auto; 
  width: auto; 
  margin-bottom: 10px; 
  white-space: nowrap;
  overflow: hidden; 
  text-overflow: ellipsis; 
}

.Company-Settings-container .PrimaryButton.active {
  background: rgba(125, 85, 199, 0.07);
  color: var(--Black, #3E3F42);
  leading-trim: both;
  text-edge: cap;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; 
  text-transform: uppercase;
}

// drodown
// .container {
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
// }

.search-container {
  display: flex;
  align-items: center;
}

.search-input {
  padding: 10px;
  border: none;
  outline: none;
  font-size: 17px;
  border: 1px solid grey;
  width: calc(100% - 40px); /* Adjust width to accommodate the icon */
}

.search-button,
.normal-button,
.dropdown-button {
  padding: 10px;
  font-size: 17px;
  border: 1px solid grey;
  cursor: pointer;
  background-color: transparent;
}

.search-button:focus,
.normal-button:focus,
.dropdown-button:focus {
  outline: none;
}

.search-button svg,
.normal-button svg,
.dropdown-button svg {
  color: grey;
}

.dropdown {
  position: relative;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 120px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.button-container {
  display: flex;
  gap: 20px;
}

// data
.CompanySetting-DropDown-container {
  align-items: center;
}
.CompanySetting-DropDown {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.CompanySetting-DropDown select {
  padding: 10px;
  outline: none;
  border: 1px solid #ccc;
  border-radius: 5px;
  color: white;
  font-size: 14px;
  background: var(--Primary-Purple-500, #7d55c7);
}

.CompanySetting-DropDown select:hover {
  border-color: #aaa;
}

.CompanySetting-DropDown select:focus {
  border-color: #007bff;
}

// Dropdown
.dropdown-data-conatiner {
  margin-top: 10px;
}

.CompanySetting-dropdown-data {
  display: flex;
  gap: 20px;
  margin-top: 10px;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 10px;
}

.CompanySetting-dropdown-data select {
  border: none;
  color: var(--primary-gray-100-text, #9ea0a5);
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  align-items: center;
  text-transform: uppercase;
}

.hrtag {
  margin-top: 10px;
}

// Stack Holder

.CompanySetting-userdata {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.CompanySetting-userdata p {
  color: var(--Black, #3e3f42);
  leading-trim: both;
  text-edge: cap;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 128.571% */
  text-transform: uppercase;
}
.CompanySetting-details {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #eaedf3;
  border-radius: 10px;
  height: 70px;
  flex-shrink: 0;
  transition: transform 0.3s ease;
}

.CompanySetting-details span {
  color: var(--Action-Green, #50cd89);
  leading-trim: both;
  text-edge: cap;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin-right: 10px;
  line-height: 22px; /* 157.143% */
}
.CompanySetting-details > div {
  display: flex;
  align-items: center;
}

.CompanySetting-details p {
  margin-right: 10px;
}

.CompanySetting-details-profile {
  margin-left: 20px;
  flex-direction: row;
  gap: 20px;
}

.CompanySetting-details:hover {
  border: 1px solid #7d55c7;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.normal-button {
  border-radius: 5px;
  border: 1px solid grey;
}

@media screen and (max-width: 768px) {
  .Company-Settings-container {
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
  }
  .StackHolder-details {
    flex-direction: column;

    gap: 10px;
  }
}
