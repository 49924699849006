.deals-room profile {
}
.menu_border {
  border: 1px solid #c4c4c4;
  padding: 20px;
}

.hr {
  border-top: 1px solid #eaedf3;
  padding-bottom: 20px;
  width: 90%;
  margin: auto;
}

.profile {
  .profile_page_sidebar {
    @media only screen and (max-height: 800px) {
      max-height: 100vh;
      padding-bottom: 80px;
      overflow-y: auto;
    }
  }

  .profile_container {
    margin-left: 272px;
    padding: 30px !important;

    @media screen and (max-width: 1200px) {
      padding: 50px 20px 0px 80px !important;
    }
    @media screen and (max-width: 1000px) {
      padding: 50px 20px 0px 100px !important;
    }
    @media screen and (max-width: 900px) {
      padding: 50px 20px 0px 120px !important;
    }
  }
  .profile-active {
    width: 6px !important;
    height: 6px !important;
    border-radius: 50%;
  }

  .deal-title {
    border-bottom: 1px solid #eaedf3 !important;
  }

  .left-menu-sort {
    padding-left: 49px !important;
  }

  .left-menu-sort-active {
    color: var(--purple-pantone-2665-c, #7d55c7);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
  }

  .deals-menu-active {
    box-shadow: none !important;
    border-left: 4px solid !important;
  }

  .hamburger {
    display: none;
    background: none;
    border: none;
    position: absolute;
    top: 90px;
    left: 15px;
    z-index: 1000;
    cursor: pointer;

    img {
      width: 30px;
      height: 30px;
    }

    @media screen and (max-width: 900px) {
      display: block;
    }
  }

  .deals-room-left {
    transition: transform 0.3s ease-in-out;

    @media screen and (max-width: 900px) {
      transform: translateX(-100%);
      position: fixed;
      top: 0;
      left: 0;
      height: 100%;
      background: white;
      z-index: 999;
      overflow-y: auto;
    }

    &.open {
      transform: translateX(0);
    }
  }

  .file_btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.288);
    border: none;
    outline: 1px solid purple;
    color: white;
    padding: 5px;
    cursor: pointer;
    display: none;
    width: 80px;
  }

  .left_profile_img {
    width: 90px !important;
    height: 90px !important;
    overflow: hidden !important;
    border-radius: 50% !important;
    padding: 0 !important;
    // background-color: #38393ca4;
    border: 1px solid rgba(168, 161, 161, 0.678);
    // margin: auto;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .setting {
      position: absolute;
      top: 184px;
      left: 141px;
      z-index: 199999999999;
    }
    .options {
      display: flex;
      flex-direction: column;
      background-color: white;
      width: 80px;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      gap: 5px;
      border-radius: 10px;

      span {
        font-size: 12px;
        cursor: pointer;
        padding: 8px 10px 8px 10px;
        text-align: center;
      }
    }
  }

  .setting-data {
    margin-top: 20px;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  // Logout

  .setting-data-logout {
    justify-content: center;
    align-items: center;
    position: relative;
    margin-top: 10px;
    :hover {
      background-color: #f0f0f0;
    }
  }

  .setting-icon {
    border-radius: 50%;
    cursor: pointer;
    transition: transform 0.3s;
  }

  .setting-icon {
    border-radius: 50%;
    cursor: pointer;
    transition: transform 0.3s;
  }

  .setting-icon:hover {
    transform: scale(1.1) rotate(100deg);
  }

  .options {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    // border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    animation: fadeIn 0.3s ease-in-out;
    cursor: pointer;
    // right: 10px;
    z-index: 100;
  }

  .option-itembuttton {
    display: block;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.2s;
    color: #3e3f42;
    leading-trim: both;
    text-edge: cap;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
  }

  // @keyframes fadeIn {
  //   from {
  //     opacity: 0;
  //     transform: translateY(-10px);
  //   }
  //   to {
  //     opacity: 1;
  //     transform: translateY(0);
  //   }
  // }
}
.submenu_Profile {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
}

.submenu_Profile.show {
  max-height: 300px; /* Adjust this based on the expected content height */
  transition: max-height 0.3s ease-in;
}
