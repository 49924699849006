.investmentDashboard_main {
  .investmentDashboard_header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .investmentDashboard_header_buttons {
      display: flex;
      gap: 10px;
      margin-right: 20px;

      .InBox_button {
        display: flex;
        gap: 10px;
        padding: 8px 16px;
        background: #f6f7f9;
        border: 1px solid #eaedf3;
        border-radius: 4px;
        margin: 10px 0px;

        p {
          color: #3e3f42;
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px; /* 157.143% */
        }
        .InBox_message_count {
          width: 20px;
          height: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 6px;
          color: #fff;
          border-radius: 50%;
          background: #cb333b;
        }
      }
    }
  }

  .syndicate_main_lower {
    display: flex;
    gap: 20px;
  }

  // .syndicatePerformence {
  //   display: flex;
  //   flex-direction: column;
  //   gap: 20px;
  // }

  .syndicate_main_lower {
    display: grid;
    gap: 30px;
    width: 100%;
    grid-template-columns: 60% 40%;
  }

  .syndicateHighlight_upper_header {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 20px 0px;
    p {
      color: #9ea0a5;
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px; /* 150% */
      text-transform: uppercase;
    }
    .syndicateHighlight_timestemp {
      display: flex;
      gap: 10px;

      .timeStemp {
        display: flex;
        flex-direction: column;
        .timeStemp_child {
          color: var(--purple-pantone-2665-c-71, rgba(125, 85, 199, 0.71));
          text-align: center;
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 110%; /* 15.4px */
        }
        p {
          color: var(--purple-pantone-2665-c, #7d55c7);
          text-align: center;
          font-family: Roboto;
          font-size: 36px;
          font-style: normal;
          font-weight: 300;
          line-height: 110%; /* 39.6px */
        }
      }
    }
  }
  .syndicateHighlight_upper_middle {
    p {
      color: #9ea0a5;
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px; /* 150% */
      text-transform: uppercase;
    }
    h3 {
      color: var(--LP-black, #3e3f42);
      font-family: Roboto;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px; /* 122.222% */
      span {
        color: var(--LP-mid-gray, #6b6c6f);
        font-family: Roboto;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
      }
    }
  }
  .syndicateHighlight_lower {
    padding: 8px 20px;
    background-color: #7d55c7;
    display: flex;
    color: #ffffff;
    gap: 10px;
    align-items: center;
    border-radius: 6px;
    border: none;
    margin: 20px 0px;
  }

  .settings_main {
    display: flex;
    flex-direction: column;
    border: 1px solid #eaedf3;
    height: fit-content;

    .setting_lower {
      padding: 20px;
      display: flex;
      flex-direction: column;
      gap: 20px;

      div {
        display: flex;
        gap: 10px;
      }

      p {
        color: #1665d8;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 157.143% */
      }
    }

    .settings_header {
      display: flex;
      border-bottom: 1px solid #eaedf3;
      padding: 20px;
      h3 {
        color: #3e3f42;
        font-family: Roboto;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px; /* 155.556% */
      }
    }

    .setting_one {
      padding: 20px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      h3 {
        color: #3e3f42;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 150% */
      }
      p {
        color: #3e3f42;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 157.143% */

        &:last-child {
          padding-bottom: 20px;
          border-bottom: 1px solid #eaedf3;
        }
      }
    }
  }

  .syndicateHighlight_upper {
    display: flex;
    gap: 40px;
  }

  .syndicateHighlight_middle {
    display: flex;
    gap: 20px;
    border: 1px solid #eaedf3;
    .syndicateHighlight_middle_header {
      h3 {
        color: #3e3f42;
        font-family: Roboto;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px; /* 155.556% */
      }
    }

    .syndicateHighlight_middle_inner {
      display: grid;
      grid-template-columns: repeat(2, 2fr);
      .syndicate-heighlight_boxes {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 140px;
        height: 110px;

        p {
          color: #fff;
          text-align: center;
          font-family: Roboto;
          font-size: 30px;
          font-style: normal;
          font-weight: 300;
          line-height: 28px; /* 93.333% */
        }
        h3 {
          color: #fff;
          text-align: center;
          font-family: Roboto;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 28px; /* 155.556% */
        }
      }

      #syndicate-heighlight_boxes_one {
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        background-image: url("../../../../../../../assets/InvestmentDashboard/SyndicateBackgroundImg.png");
      }
      #syndicate-heighlight_boxes_two {
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        background-image: url("../../../../../../../assets/InvestmentDashboard/syndicateBackgroundImg2.png");
      }
      #syndicate-heighlight_boxes_three {
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        background-image: url("../../../../../../../assets/InvestmentDashboard/SyndicateBackground3.png");
      }
      #syndicate-heighlight_boxes_four {
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        background-image: url("../../../../../../../assets/InvestmentDashboard/SyndicateBackgroundImg4.png");
      }
    }
  }

  .syndicatePerformence_one {
    display: flex;
    gap: 20px;
    margin: 20px 0px;

    .syndicatePerformence_one_inner {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      p {
        color: var(--LP-mid-grey-_lib, #6b6c6f);
        text-align: center;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px; /* 150% */
        text-transform: uppercase;
      }
      div {
        h3 {
          color: var(--LP-black, #3e3f42);

          /* h3/Medium 24px */
          font-family: Roboto;
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
        p {
          color: var(--Green-2, #27ae60);
          text-align: center;
          font-family: Roboto;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px; /* 150% */
          text-transform: uppercase;
        }
      }
    }
  }

  .syndicatePerformence_two {
    .syndicate-performance {
      font-family: Arial, sans-serif;
      width: 100%;
      max-width: 800px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      // margin: 20px 0px;
    }

    .syndicate-performance_header {
      h3 {
        color: #9ea0a5;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px; /* 150% */
        text-transform: uppercase;
      }
    }

    .progress-bar {
      display: flex;
      height: 60px;
      border-radius: 10px;
      width: 100%;
      .progress-bar_one {
        width: 60%;
        background: #7d55c7;
      }
      .progress-bar_two {
        width: 25%;
        background: #9ea0a5;
      }
      .progress-bar_three {
        width: 15%;
        background: #eaedf3;
      }
    }

    .funded-stats {
      display: flex;
      justify-content: space-between;
      margin: 20px 0px;
      gap: 20px;

      .funded-percentage {
        display: flex;
        gap: 80px;
        .funded-percentage_one {
          display: flex;
          flex-direction: column;
          gap: 4px;
          &:first-child {
            color: #9ea0a5;
            font-family: Roboto;
            font-size: 12px !important;
            font-style: normal;
            font-weight: 500;
            line-height: 18px; /* 150% */
            text-transform: uppercase;
          }
        }

        .funded-percentage_two {
          display: flex;
          gap: 4px;
          p {
            color: var(--LP-Pantone, #7d55c7);
            text-align: center;
            font-family: Roboto;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px; /* 91.667% */
          }
        }
      }
    }

    .pledged-percentage {
      display: flex;
      gap: 80px;
      &:first-child {
        color: #9ea0a5;
        text-align: right;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px; /* 150% */
        text-transform: uppercase;
      }
      p {
        color: var(--LP-mid-grey-_lib, #6b6c6f);
        text-align: center;
        font-family: Roboto;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 122.222% */
      }
    }
  }

  .investment_summary_main {
    display: flex;
    flex-direction: column;
    border: 1px solid #eaedf3;

    .investment_summary_header {
      border-bottom: 1px solid #eaedf3;
      border-top: none;
      padding: 20px;
      h3 {
        color: #3e3f42;
        font-family: Roboto;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px; /* 155.556% */
      }
    }

    .investment_summary_middle {
      display: flex;
      padding: 20px 40px;
      border-bottom: 1px solid #eaedf3;
      flex-direction: column;
      gap: 20px;

      p {
        color: #9ea0a5;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 157.143% */
      }
      h3 {
        color: #3e3f42;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px; /* 157.143% */
      }
      span {
        color: #9ea0a5;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 157.143% */
      }

      .investment_summary_middle_one {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding-bottom: 10px;
        border-bottom: 1px solid #eaedf3;

        .investment_summary_middle_one_left {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 20px;
          width: 50%;
        }
        .investment_summary_middle_one_right {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 20px;
          width: 50%;
        }
      }
    }
  }

  .acquisition_details {
    display: flex;
    flex-direction: column;
    border: 1px solid #eaedf3;

    .acquisition_details_header {
      border-bottom: 1px solid #eaedf3;
      border-top: none;
      padding: 20px;
      h3 {
        color: #3e3f42;
        font-family: Roboto;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px; /* 155.556% */
      }
    }

    .acquisition_details_inner {
      display: flex;
      padding: 20px 40px;
      border-bottom: 1px solid #eaedf3;
      gap: 20px;

      p {
        color: #9ea0a5;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 157.143% */
      }
      h3 {
        color: #3e3f42;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px; /* 157.143% */
      }
      span {
        color: #9ea0a5;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 157.143% */
      }

      .investment_summary_middle_one {
        display: flex;
        justify-content: space-between;
        width: 100%;
        // border-bottom: 1px solid #eaedf3;
        padding-bottom: 10px;

        .investment_summary_middle_one_left {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 20px;
          width: 50%;
        }
        .investment_summary_middle_one_right {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 20px;
          width: 50%;
        }
      }
    }
  }

  .latest_activity {
    display: flex;
    flex-direction: column;
    border: 1px solid #eaedf3;

    .latest_activity_header {
      border-bottom: 1px solid #eaedf3;
      border-top: none;
      padding: 20px;
      h3 {
        color: #3e3f42;
        font-family: Roboto;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px; /* 155.556% */
      }
    }

    .latest_activity_one {
      .latest_activity_inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px;
        border-bottom: 1px solid #eaedf3;

        .latest_activity_inner_one {
          p {
            color: #9ea0a5;
            text-align: center;
            font-family: Roboto;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 28px; /* 155.556% */
          }
        }

        .latest_activity_inner_two {
          h3 {
            color: #3e3f42;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px; /* 157.143% */
          }
          .latest_activity_inner_two_lower {
            p {
              color: var(--56-c-568-success-positive-green, #56c568);
              font-family: Roboto;
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: 19px; /* 158.333% */
            }
            span {
              color: #9ea0a5;
              font-family: Roboto;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 18px; /* 150% */
            }
          }
        }
        .latest_activity_inner_three {
          padding: 8px 20px;
          border-radius: 4px;
          border: 1px solid #d8dce6;
          background: linear-gradient(0deg, #f6f7f9 0%, #fff 100%);
          box-shadow: 0px 2px 0px 0px rgba(255, 255, 255, 0.05) inset, 0px 1px 1px 0px rgba(22, 29, 37, 0.05);
          color: #3e3f42;
          text-align: center;
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px; /* 157.143% */
        }
        .latest_activity_inner_two_lower {
          display: flex;
        }
      }
    }
  }
}
