.EquityAdvisory-Conatiner {
  border: 1px solid #eaedf3;
  background: rgba(125, 85, 199, 0.08);
}
.EquityAdvisory-Conatiner-data {
  margin: 10px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
}
.EquityAdvisory-container-image {
  display: flex;
  gap: 20px;
}
.EquityAdvisory-container-image-data {
  display: flex;
  gap: 20px;
}
.EquityAdvisory-Data {
  border: 1px solid var(--Primary-Purple-50, #eaedf3);
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.15);
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.EquityAdvisory-Data p {
  width: 50%;
}

// Dropdown
.EquityAdvisory-wrapper {
  .EquityAdvisory {
    border: 1px solid #eaedf3;
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.15);
    .EquityAdvisory-accordion {
      cursor: pointer;
      background-color: white;
      padding: 15px;
      padding-top: 20px;
      padding-bottom: 20px;
      border: none;
      text-align: left;
      width: 600px;
      outline: none;
      leading-trim: both;
      text-edge: cap;
      font-family: Roboto;
      font-size: 16.123px;
      font-style: normal;
      font-weight: 500;
      line-height: 26.2px; /* 162.5% */
      i {
        float: right;
      }

      &:hover {
      }

      &.active {
        i {
          transform: rotate(180deg);
        }
      }
    }

    .EquityAdvisory-pannel {
      display: none;
      overflow: hidden;
      transition: max-height 0.2s ease-out;
      &.active {
        display: block;
        width: 610px;
        padding: 20px;
        color: var(--Primary-Gray-200, #9ea0a5);
        leading-trim: both;
        text-edge: cap;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 26px; /* 185.714% */
      }

      p {
        margin-left: 0;
      }
    }
  }
}

.EquityAdvisory-wrapper .EquityAdvisory .EquityAdvisory-accordion i {
  transition: transform 0.3s ease; /* Add transition for smooth rotation */
}

.EquityAdvisory-wrapper .EquityAdvisory .EquityAdvisory-accordion.active i {
  transform: rotate(180deg); /* Rotate the icon 180 degrees when the accordion is active */
}

.EquityAdvisory-wrapper .EquityAdvisory .EquityAdvisory-accordion i {
  font-size: 20px; /* Increase the size of the icon */
  color: var(--Primary-Purple-500, #7d55c7); /* Set the color of the icon */
  transition: transform 0.3s ease;
}

// flex-data
.EquityAdvisory-container {
  display: flex;
}
.EquityAdvisory-container-data {
  display: flex;
}
.linetag {
  height: 5px;
  background-color: #7d55c7;
}
.EquityAdvisory-flex {
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: center;
}
.EquityAdvisory-flex p {
  color: var(--Black, #3E3F42);
  leading-trim: both;
  text-edge: cap;
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  font-weight: 500;
}
.EquityAdvisory-Relevant {
  background-color: #eaedf3;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.EquityAdvisory-Data button {
  display: flex;
  width: 375px;
  height: 38px;
  padding: 0px 146px 0px 134px;
  align-items: center;
  border: none;
  color: white;
  font-size: 14px;
  flex-shrink: 0;
  border-radius: 4px;
  background: var(--Primary-Purple-500, #7d55c7);
}
.EquityAdvisory-button {
  display: flex;
  gap: 10px;
}

.EquityAdvisory-Schedule {
  padding: 0;
  margin: 0;
  height: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  border: none;
  color: white;
  background-color: white;
  border-radius: 4px;
  background: var(--Primary-Purple-500, #7d55c7);
  width: 200px;
}
.EquityAdvisory-Preview {
  padding: 0;
  margin: 0;
  height: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  border: none;
  color: black;
  background-color: white;
  border-radius: 4px;
  border: 1px solid var(--Primary-Purple-50, #eaedf3);
  background: #fff;
  width: 200px;
}

@media (min-width: 576px) {
  .EquityAdvisory-container-image-data {
    flex-direction: column;
  }

  .EquityAdvisory-Data {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .EquityAdvisory-Data {
    width: 50%; /* Adjust width to accommodate two columns on larger screens */
  }
}

@media (min-width: 992px) {
  .EquityAdvisory-container-image-data {
    flex-direction: column;
  }
}

@media (min-width: 1300px) {
  .EquityAdvisory-container-image-data {
    flex-direction: row;
  }
}

@media (max-width: 899px) {
  .EquityAdvisory-container-image-data {
    flex-direction: column;
  }

  .EquityAdvisory-Data {
    width: 80%;
    margin: 0 auto;
  }
}

@media screen and (max-width: 1200px) {
  .EquityAdvisory-Data {
    width: 100%;
  }
}
