.SingleDealsUpload {
  font-family: "Arial", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  gap: 20px;
  background: linear-gradient(135deg, #ffffff, #f0f8ff);
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.SingleDealsUpload-dropzone {
  border: 2px dashed #007bff;
  border-radius: 10px;
  padding: 30px;
  text-align: center;
  background-color: #f9f9f9;
  color: #333;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.SingleDealsUpload-dropzone:hover {
  background-color: #e6f7ff;
}

.SingleDealsUpload-file {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.SingleDealsUpload-file-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  font-size: 0.9rem;
}

.SingleDealsUpload-file-item p {
  margin: 0;
  color: #555;
}

.SingleDealsUpload-file-item-progress {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.SingleDealsUpload-progress-bar {
  flex: 1;
  height: 8px;
  border-radius: 5px;
  background-color: #e0e0e0;
  overflow: hidden;
}

.SingleDealsUpload-progress-bar div {
  height: 100%;
  background-color: #007bff;
  transition: width 0.3s ease;
}

.SingleDealsUpload-status {
  text-align: center;
  font-size: 1rem;
  color: #555;
}

.SingleDealsUpload-buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
}

.SingleDealsUpload-buttons button {
  flex: 1;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.SingleDealsUpload-buttons .cancel-button {
  background-color: #f0f0f0;
  color: #555;
}

.SingleDealsUpload-buttons .cancel-button:hover {
  background-color: #e0e0e0;
}

.SingleDealsUpload-buttons .submit-button {
  background-color: #007bff;
  color: #fff;
}

.SingleDealsUpload-buttons .submit-button:hover {
  background-color: #0056b3;
}
