.blockchain_sidebar {
  width: 285px;
  height: 1090px;
  flex-shrink: 0;
  flex-shrink: 0;
  background: #fff;
  box-shadow: 1px 0px 0px 0px #eaedf3;

  .search_header {
    .search {
      display: flex;
      align-items: center;
      justify-content: space-around;
      background: rgba(255, 255, 255, 0);
      box-shadow: 0px -1px 0px 0px #eaedf3 inset;

      input {
        border: none;
        outline: none;
        padding: 20px 0px 20px 5px;
      }
    }
  }

  .userList {
    display: flex;
    flex-direction: column;
    // gap: 10px;
    .singleUser {
      display: flex;
      align-items: center;
      gap: 15px;
      padding: 20px;
      background: rgba(255, 255, 255, 0);
      box-shadow: 0px -1px 0px 0px #eaedf3 inset;
      cursor: pointer;

      .userImg {
        width: 38px;
        height: 38px;
        border-radius: 50%;
        border-radius: 50%;
      }
      h2 {
        color: #3e3f42;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 150% */
      }
      p {
        color: #6b6c6f;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 157.143% */
        display: flex;
        align-items: center;
        gap: 6px;
      }
      .userStatusImg {
        height: 6px;
        width: 6px;
        border-radius: 50%;
      }
    }
    .selectedUser {
      background-color: #f8f6fb;
      border-left: 4px solid #7757c0;

    }
  }
}
