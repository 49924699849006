.recom {
  .lender_wrapper {
    padding-left: 0px;
    width: max-content !important;
    @media screen and (max-width:1400px) {
      display: flex !important;
      justify-content: center !important;
    }
  }

  .lender_table {
    width: max-content !important;
   
    .documents_box {
      border: 1px solid #eaedf3;

      border-radius: 4px;
      margin-bottom: 30px;

      .documents_box-T {
        padding: 15px 30px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #eaedf3;

        .left {
          gap: 20px;
          display: flex;

          p {
            color: #9ea0a5;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px;
            /* 157.143% */
          }

          h2 {
            margin-top: 5px;
            color: #3e3f42;
            font-family: Roboto;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 28px;
            /* 155.556% */
            text-wrap: nowrap;
          }
        }

        .right {
          display: flex;

          justify-content: right;

          .button {
            gap: 10px;
            display: flex;
            align-items: center;
            padding: 0px 10px;
            border-right: 1px solid #eaedf3;

            &:last-of-type {
              border: none;
            }
          }

          .button2 {
            margin-right: 10px;
            display: flex;
            align-items: center;
            padding: 0px 10px;
            border: 2px solid #eaedf3;
            border-radius: 5px;

            span {
              margin-left: 0px !important;
            }
          }

          &:last-child {
            margin-right: 0px;
          }

          .white {
            padding: 7px 14px;
            background: #fff;
            color: #3e3f42;
            border: none;
            margin-top: 0px;
            box-shadow: none;
          }
        }
      }

      .documents_box-B {
        padding: 40px 30px;

        &.table {
          // padding: 0px 30px;
          // border: ;
          padding: 0px;
        }

        .btn_green {
          width: 83px;
          height: 24px;
          text-transform: uppercase;
          color: #fff;
          text-align: center;
          font-family: Roboto;
          font-size: 12px;
          font-weight: 500;
          line-height: 18px;
          background-color: #34aa44;
          outline: none;
          border: none;
          border-radius: 3px;
        }
        .btn_blue {
          width: 83px;
          height: 24px;
          text-transform: uppercase;
          color: #fff;
          text-align: center;
          font-family: Roboto;
          font-size: 12px;
          font-weight: 500;
          line-height: 18px;
          background-color: #00629b;
          outline: none;
          border: none;
          border-radius: 3px;
        }

        p {
          color: var(--purple-pantone-2665-c, #7d55c7);
          font-family: Roboto;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          /* 111.111% */
        }

        .uploaded_docs {
          width: 100%;

          table {
            td {
              height: 90px;
              // justify-content: center;
              text-align: center !important;
              padding: 20px;

              .first {
                text-align: left;
              }
              @media screen and (max-width: 1200px) {
                .first {
                  display: flex;
                  flex-direction: column;
                  text-align: left;
                  align-items: start;
                }
              }

              p {
                color: #3e3f42;
                font-family: Roboto;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
              }

              /* 150% */
              span {
                color: #9ea0a5;
                font-family: Roboto;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
              }
              
              .text_wrapper {
                p {
                  text-wrap: nowrap;
                }

                color: #3e3f42;
                font-family: Roboto;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;

                /* 150% */
                span {
                  color: #9ea0a5;
                  font-family: Roboto;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 18px;
                }
              }
            }
            th {
              height: 50px;
              text-wrap: nowrap;
              color: #9ea0a5;
              font-family: Roboto;
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: 18px;
              text-transform: uppercase;
              padding: 0px 20px 0px 20px;
            }
          }
          .header {
            display: flex;
            padding: 14px 40px;
            width: 100%;
            justify-content: space-between;
            border-bottom: 1px solid #eaedf3;

            .left {
              display: flex;

              p {
                width: 150px;
              }
            }

            .right {
              p {
                margin-left: auto;
              }
            }

            p {
              text-wrap: nowrap;
              color: #9ea0a5;
              font-family: Roboto;
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: 18px;
              /* 150% */
              text-transform: uppercase;
            }
          }

          .table_row {
            padding: 10px 25px;
            border: 1px solid #eaedf3;
            display: flex;
            justify-content: space-between;
            border-top: none;
            border-bottom: 1px solid #eaedf3 !important;

            &.table_row2 {
              border-bottom: 1px solid #eaedf3;
            }

            p {
              color: var(--LP-black--_lib, #3e3f42);
              font-family: Roboto;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 22px;
              /* 157.143% */
            }

            span {
              color: #9ea0a5;
              font-family: Roboto;
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: 18px;
              /* 150% */
              text-transform: uppercase;
            }

            .MuiCheckbox-root {
              padding: none;
            }

            .MuiFormControlLabel-root {
              margin-right: 0px;
            }

            .left {
              display: flex;
              align-items: center;

              .item {
                display: flex;
                gap: 10px;
                align-items: center;
                width: 164px;

                &.first {
                  margin-right: 15px;
                  width: 280px !important;
                }

                .text_wrapper {
                  p {
                    text-wrap: nowrap;
                  }

                  color: #3e3f42;
                  font-family: Roboto;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 24px;

                  /* 150% */
                  span {
                    color: #9ea0a5;
                    font-family: Roboto;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 18px;
                  }
                }
              }
            }

            .right {
              display: flex;
              width: fit-content;

              .action_buttons {
                display: flex;
                gap: 10px;

                .button {
                  display: flex;
                  width: 50px;
                  justify-content: center;
                  align-items: center;
                  flex-shrink: 0;
                  border: 1px solid #eaedf3;
                  border-radius: 5px;
                }
              }
            }
          }
        }
      }
    }
    
  }

  .lender_table {
    .documents_box {
      width: 72vw;
      @media screen and (min-width:2000px) {
        width: 67vw;
      }

      @media screen and (max-width:1600px) {
        width: 77vw;
      }
      @media screen and (max-width:1400px) {
        width: 80vw;
      }
      @media screen and (max-width:1200px) {
        width: 92vw;
      }
      @media screen and (max-width:1000px) {
        width: 110vw;
      }
      @media screen and (max-width:900px) {
        width: 124vw;
      }
      @media screen and (max-width:800px) {
        width: 104vw;
      }

      .table_row {
        border: none !important;

        .left {
          .item {
            width: 150px !important;
            align-items: flex-start !important;
          }
        }

        .right {
          gap: 25px;
          align-items: center;

          .item {
            width: auto !important;
          }

          .action_buttons {
            height: 100%;
            align-items: center;

            .button {
              height: 100%;
            }
          }
        }
      }
    }
  }

  .comments_panel {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .comment_box {
      display: flex;
      gap: 20px;
      padding: 30px 20px;
      padding-top: 0px;
      padding-right: 0px;
      border-bottom: 1px solid #e2e5ed;

      .left {
      }

      .right {
        display: flex;
        gap: 10px;
        flex-direction: column;

        .right-T {
          display: flex;
          justify-content: space-between;

          p {
            color: #3e3f42;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;

            span {
              color: #3e3f42;
              font-family: Roboto;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 22px;
              /* 157.143% */
            }
          }

          span {
            color: #9ea0a5;
            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            /* 150% */
          }
        }

        .right-M {
          border: 1px solid #e2e5ed;
          border-radius: 5px;
          padding: 20px;
          padding-bottom: 10px;
          background: #fbfbfd;

          p {
            color: #3e3f42;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            /* 157.143% */
          }
        }

        .right-B {
          display: flex;
          justify-content: space-between;
          align-items: center;

          p {
            color: #9ea0a5;
            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            /* 150% */
          }

          span {
            color: #1665d8;
            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
      }
    }
  }

  .updates_panel {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;

    .updates_photos {
      display: flex;
      gap: 20px;
      align-items: flex-start;
      border-bottom: 1px solid #eaedf3;
      padding-bottom: 20px;

      .left {
        display: flex;
        align-items: center;
        gap: 10px;

        .updatesIcon {
          border-radius: 50%;
        }
      }

      .right {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .right-T {
          h3 {
            color: #3e3f42;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px;
            /* 157.143% */
          }

          p {
            color: #6b6c6f;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            /* 157.143% */
          }
        }

        .right-M {
          p {
            color: #3e3f42;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            /* 157.143% */
            margin-bottom: 10px;
          }
        }

        .right-B {
          span {
          }

          color: #9ea0a5;
          font-family: Roboto;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
          /* 150% */
        }
      }
    }
  }

  .react-tabs {
    // padding: 0px 30px;

    .inputBoxMain {
      margin-top: 0px;
    }

    .react-tabs__tab-list {
      border-bottom: 1px solid #eaedf3;
      // padding: 0% 14%;
      margin: 0 auto;
      display: flex;
      // justify-content: space-between;

      gap: 20px;

      li {
        display: flex;
        justify-content: center;
        color: var(--LP-mid-gray, #6b6c6f);
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        /* 171.429% */
        text-transform: capitalize;
        display: flex;
        height: 36px;
        align-items: flex-start;
        min-width: 60px;
      }

      .active {
        color: #3e3f42 !important;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        /* 171.429% */
        background: var(--ffffff-white, #fff);
        box-shadow: 0px -2px 0px 0px #7d55c7 inset;
      }
    }

    .react-tabs__tab-panel--selected {
      // padding-right: 15%;
      // padding-left: 10%;
      // padding-bottom: 4.5%;
      // padding-top: 3%;
      display: flex;
      margin-top: 40px;

      // border-bottom: 1px solid #eaedf3;
      &.innerTabination {
        padding-left: 0%;
        padding-top: 2%;
        padding-right: 0px;
      }

      .left {
        .react-tabs {
          display: flex;
          padding: 0px;

          .react-tabs__tab-list {
            flex-direction: column;
            justify-content: flex-start;
            align-items: baseline;

            padding: 0px;
            margin: 0px;

            li {
              padding: 5px 16px;
              display: flex;
              align-items: center;
              justify-content: start;
            }

            .active {
              box-shadow: 3px 0px 0px 0px #7d55c7 inset;
            }

            border-bottom: none;
          }

          .react-tabs__tab-panel--selected {
            padding: 0px;
            padding-top: 1%;
            border-bottom: none;
          }
        }

        .right {
          width: 100%;
          display: flex;
          justify-content: space-between;
          gap: 105px;

          .column {
            :first-child {
              margin-top: 0px !important;
            }

            .row {
              width: 286px;
              margin-top: 20px;

              p {
                color: var(--LP-black, #3e3f42);
                font-family: Roboto;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;
                /* 157.143% */
              }

              span {
                color: var(--LP-gray, #9ea0a5);
                text-align: right;
                font-family: Roboto;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;
                /* 157.143% */
              }

              .subCat {
                display: flex;
                justify-content: space-between;

                .subCat-L {
                  display: flex;
                  align-items: center;
                  gap: 10px;
                }
              }

              .option {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .MuiCheckbox-root {
                  padding: 5px;
                }

                .MuiFormControlLabel-root {
                  margin-left: 20px;
                }

                .MuiFormControlLabel-label {
                  color: var(--LP-black, #3e3f42);
                  font-family: Roboto;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 22px;
                  /* 157.143% */
                }

                .option-L {
                  display: flex;
                  align-items: center;
                  gap: 10px;
                }
              }
            }
          }
        }
      }

      .right {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .column {
          .row {
            width: 307px;
          }
        }
      }

      .location {
        width: 100%;
      }

      .asset {
      }
    }
  }

  .expanded_component {
    padding: 10px 25px;
    height: 200px;
    border: 1px solid #eaedf3;
    background: rgba(234, 237, 243, 0.05);
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.04), 0px 1px 8px 0px rgba(0, 0, 0, 0.08) inset;
    display: flex;
    flex-direction: column;
    gap: 40px;

    .expanded_component_header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      > div {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 40px;

        p {
          color: var(--purple-pantone-2665-c, #7d55c7);
          font-family: Roboto;
          font-size: 18px;
          font-weight: 500;
          line-height: 28px;
        }

        span {
          color: var(--LP-black, #3e3f42);
          font-family: Roboto;
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
        }
      }
    }

    .btns_wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      div {
        display: flex;
        align-items: center;
        margin-right: 10px;
      }
      .share {
        color: #9ea0a5;
        text-align: right;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
      }

      button {
        width: 113px;
        height: 38px;
        font-family: Roboto;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        outline: none;
        border: 1px solid #d8dce6;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4px;
        background-color: #ffffff;
        border-radius: 4px;
      }

      .btn_one {
        color: #ffffff;
        border: 1px solid var(--purple-pantone-2665-c, #7d55c7);
        background: var(--purple-pantone-2665-c, #7d55c7);
      }
    }

    img {
      height: 24px;
      width: 24px;
    }
  }
}


.pagination_LenderTable {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.pagination_LenderTable button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5rem; /* Adjust icon size */
  color: #007bff; /* Adjust color */
}

.pagination_LenderTable button:disabled {
  color: #ccc; /* Adjust disabled color */
  cursor: not-allowed;
}
