.market_fund_container {
  .market_fund_header {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 15px 15px 15px 20px;
    border-bottom: 1px solid rgb(211, 211, 211);
    h2 {
      color: #262727;
      font-weight: 550;
    }
    .cross {
      width: 45px;
      height: 45px;
      display: grid;
      place-items: center;
      border-radius: 10px;
      transition: all 0.3s;
      cursor: pointer;
      &:hover {
        background-color: #e3ecf4;
      }
    }
  }
  .market_fund_child {
    padding: 50px 100px;

    .market_fund_content {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      gap: 50px;

      .left_side_market_fund {
        display: flex;
        flex-direction: column;
        gap: 60px;
        width: 700px;
        .progreess {
          display: flex;
          flex-direction: column;
          gap: 20px;

          p {
            font-size: 0.875rem;
            font-weight: 500;
            color: black;
          }
        }
        .heading {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          gap: 20px;
          h1 {
            font-size: 1.25rem;
            font-weight: 500;
          }
          p {
            font-size: 0.875rem;
            color: rgba(29, 42, 41, 0.65);
            font-weight: 400;
          }
        }
      }

      .right_side_market_fund {
        width: 700px;
        display: flex;
        flex-direction: column;
        gap: 40px;

        .auth_signer {
          display: flex;
          flex-direction: column;
          gap: 10px;

          h1 {
            font-size: 1.25rem;
            font-weight: 550;
          }

          .Tabination {
            display: flex;
            flex-direction: column;
            gap: 20px;

            .tabination_header {
              display: flex;
              align-items: center;
              gap: 25px;

              span {
                cursor: pointer;
                padding: 15px;
                font-weight: 500;
                color: rgb(31, 30, 30);
              }
              .active {
                color: #7d55c7;
                box-shadow: 0px -2px 0px 0px #7d55c7 inset;
                transition: 0.4s;
              }
            }
            button {
              padding: 10px;
              background-color: #5f58ff;
              border: none;
              outline: none;
              color: white;
              border-radius: 5px;
              margin-top: 40px;
              cursor: pointer;
              font-size: 14px;
              transition: 0.5s;
            }
            .tabination_content {
              .inputs {
                display: flex;
                flex-direction: column;
                gap: 20px;
                padding: 20px 0;
              }
            }
          }
        }

        .heading {
          h1 {
            color: #002b31;
            font-size: 1.75rem;
            font-weight: 550;
          }
          p {
            font-weight: 400;
            color: rgba(29, 42, 41, 0.65);
            font-size: 0.875rem;
          }
        }
        .form_w9 {
          display: flex;
          flex-direction: column;
          gap: 20px;

          .form_w-9_heading {
            p {
              color: rgba(29, 42, 41, 0.65);
              font-weight: 400;
              font-size: 0.875rem;
            }
          }

          .form_content {
            display: flex;
            flex-direction: column;
            gap: 20px;
            justify-content: flex-start;
            div {
              display: flex;
              align-items: self-start;
              gap: 10px;

              input {
                margin-top: 5px;
              }
            }
            label {
              font-weight: 400;
              font-size: 0.875rem;
            }
          }
        }
      }
    }
  }
}

.final_submission_page {
  width: 700px;
  display: flex;
  flex-direction: column;
  gap: 40px;

  .heading {
    h1 {
      color: #002b31;
      font-size: 1.25rem;
      font-weight: 550;
    }
    p {
      font-weight: 400;
      color: rgba(29, 42, 41, 0.65);
      font-size: 0.875rem;
    }
  }

  .understand_check {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 0.375rem;
    outline: 1px solid rgb(236, 232, 232);
    padding: 20px;
    width: 550px;

    label {
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 25px;
      cursor: pointer;
    }
  }
  .view_agreement {
    display: flex;
    align-items: center;
    gap: 5px;
    p {
      color: #3a25f5;
      font-size: 0.9rem;
      font-weight: 500;
      cursor: pointer;
    }
  }
  .final_submit_btn {
    display: flex;
    align-items: center;
    gap: 20px;

    .submit_btn_final {
      background-color: #5f58ff;
      padding: 10px;
      border-radius: 6px;
      border: none;
      outline: none;
      color: white;
      font-size: 0.875rem;
      font-weight: 500;
      cursor: pointer;
    }
    .back_btn {
      background-color: #ebeceb;
      padding: 10px;
      border-radius: 6px;
      border: none;
      outline: none;
      color: black;
      font-size: 0.875rem;
      font-weight: 500;
      cursor: pointer;
    }
  }
  .bottom_info {
    p{
        color: #afabab;
        font-size: 0.900rem;
    }
  }
}
