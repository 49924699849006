.Report-header {
  border: 1px solid #eaedf3;
  display: flex;
  justify-content: space-between;
  margin-top: 0;
  padding: 20px;
}
.Report-header h4 {
  width: 300px;
  color: var(--Primary-Gray-200, #9ea0a5);
  leading-trim: both;
  text-edge: cap;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}
.Report-see-plan {
  margin-top: 0;
  width: 100px;
}

.All-report-data {
  display: flex;
  gap: 40px;
  padding: 10px;
}

.Report-Container {
  display: flex;
  flex-direction: column;
  gap: 50px;
}
.report-box-container {
  margin: 10px;
}
.All-report-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  border: 1px solid #eaedf3;
}

.Report-header-data {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  margin: 10px;
  border: 1px solid #eaedf3;
}
.Report-header-data h4 {
  color: var(--primary-gray-100-text, #9ea0a5);
  leading-trim: both;
  text-edge: cap;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
  text-transform: uppercase;
}
// container

.box-container {
  display: flex;
  justify-content: space-between;
}
.box-border {
  border: 1px solid #eaedf3;
  padding: 10px;
}

.box-container-data {
  display: flex;
  gap: 50px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.box-container-data h1 {
  font-size: 14px;
}
.box-container-data-flex {
  display: flex;
  align-items: center;
}

.box-container-data-flex > h1 {
  margin-right: 5px;
}

