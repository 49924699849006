.document {
    width: 100%;
    padding-right: 30px;

    .document_header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 74px;
        width: 100%;
        box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.10);
        position: absolute;
        left: 270px;
        top: 75px;
        width: calc(100% - 270px);
        padding: 0 20px;

        h2 {
            color: #3E3F42;
            font-family: Roboto;
            font-size: 20px;
            font-weight: 500;
            line-height: 30px;
        }

        .header_right {
            display: flex;
            align-items: center;
            gap: 10px;

            button {
                color: #ffffff;
                display: inline-flex;
                padding: 8px 20px;
                align-items: center;
                justify-content: center;
                gap: 12px;
                border-radius: 4px;
                border: 1px solid var(--purple-pantone-2665-c-71, rgba(125, 85, 199, 0.71));
                background: linear-gradient(0deg, rgba(125, 85, 199, 0.30) 0%, rgba(255, 255, 255, 0.00) 107.78%), #7D55C7;
                background-blend-mode: multiply, normal;
                box-shadow: 0px 1px 1px 0px rgba(19, 31, 21, 0.10), 0px 2px 0px 0px rgba(255, 255, 255, 0.06) inset;
            }
        }

        .search_bar {
            position: relative;
            width: 240px;
            height: 38px;
            border: 2px solid #eaedf3;
            border-radius: 5px;
            display: flex;
            align-items: center;

            input {
                all: unset;
                width: 220px;
                padding-right: 25px;
                padding-left: 2px;
                color: #9EA0A5;
                font-family: Roboto;
                font-size: 14px;
                font-weight: 400;
                line-height: 22px
            }

            img {
                position: absolute;
                right: 5px;
            }
        }
    }

    .document_table_box {
        width: 100%;
        border: 1px solid #EAEDF3;
        filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.04));
        margin-bottom: 20px;
        margin-top: 120px;
        background:#ffffff;
        .table_header {
            padding: 20px;
            height: 70px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            border-bottom: 1px solid #EAEDF3;
            display: flex;
            justify-content: space-between;
            align-items: center;

            h3 {
                color: #3E3F42;
                font-family: Roboto;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 28px;
            }
        }
    }

    .table_nav {
        margin-top: 26px;

        .asset-nav {
            border-bottom: none !important;
            height: 41px !important;

            span {
                color: #6B6C6F;
                font-family: Roboto;
                font-size: 18px !important;
                font-weight: 400;
                line-height: 28px;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 10px;

                p {
                    color: #9EA0A5;
                    font-family: Roboto;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 22px;
                }
            }

            .ut-nav-active {
                color: #3E3F42 !important;
                font-weight: 500;

            }
        }


    }

    .document-table {
        width: 100%;
        border-collapse: collapse;
        margin-top: 20px;
        padding: 0 20px;


        .table-row {
            display: flex;
            border-bottom: 1px solid #eaedf3;
            height: 60px;
            align-items: center;

            &:last-child {
                border-bottom: none;
            }

            .column {
                flex: 1;
                padding: 0 10px;
                color: #9EA0A5;
                font-family: Roboto;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 18px;
                text-transform: uppercase;
            }

            .column:first-child {
                width: 200px;
            }
        }

        .cell1 {
            flex: 1;
            padding: 0 10px;
            color: #3E3F42;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px;
            min-width: 300px;
            width: 300px;
        }

        .cell {
            flex: 1;
            padding: 0 10px;
            color: #9EA0A5;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
        }

        .right {
            text-align: right;
        }


        .header {
            font-size: 12px;
            font-weight: 500;
            color: #9EA0A5;
            line-height: 18px;
            text-transform: uppercase;
        }

    }
}


.upload_modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
}

.uploadbox {
    background: #ffffff;
    width: 540px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 4px;
    filter: drop-shadow(3px 0 13px #cddddd);

    .inputs {
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 20px;
   
        .attachment {
            border-radius: 4px;
            border: 1px dashed #E2E5ED;
            background: #FCFCFC;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05) inset;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 20px;
            height: 140px;
        
            span {
                color: #9EA0A5;
                text-align: center;
                font-family: Roboto;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;
            }
            p{
                color: #3E3F42;
                font-family: Roboto;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 22px;   
            }
        
        }
    }

    .users{
        background: #FBFBFD;
        height: 64px;
        display: flex;
        align-items: center;
        border-top: 1px solid #eaedf3;
        border-bottom: 1px solid #eaedf3;
        padding: 20px;
        .checkbox{
            padding-left:0px !important;
        }
    }
    .btns {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px;
    }

    .btn_right {
        color: #ffffff;
        display: inline-flex;
        padding: 7px 10px;
        align-items: center;
        justify-content: center;
        gap: 12px;
        border-radius: 4px;
        border: 1px solid var(--purple-pantone-2665-c-71, rgba(125, 85, 199, 0.71));
        background: linear-gradient(0deg, rgba(125, 85, 199, 0.30) 0%, rgba(255, 255, 255, 0.00) 107.78%), #7D55C7;
        background-blend-mode: multiply, normal;
        box-shadow: 0px 1px 1px 0px rgba(19, 31, 21, 0.10), 0px 2px 0px 0px rgba(255, 255, 255, 0.06) inset;
    }
}

.btn_cancel {
    outline: none;
    background: linear-gradient(#F6F7F9, #FFFFFF);
    border: 1px solid #D8DCE6;
    border-radius: 4px;
    padding: 7px 10px;
}