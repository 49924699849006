.property_card_wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    width: min-content;
    border-radius: 4px;
    margin-bottom: 30px;

    .property_card {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 12px 12px 0px 12px;
        width: 350px;
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
        flex-shrink: 0;

        border: 1px solid var(--LP-very-light, #eaedf3);
        background: #fff;
        box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.15);

        .property_card-T {
            .photo_wrapper {
                display: flex;
                flex-direction: column;

                .tag {
                    position: relative;
                    z-index: 9;
                    display: flex;
                    padding: 4px;
                    margin-bottom: -26px;
                    /* display: inline-flex; */
                    padding: 5px 10px;
                    align-items: center;
                    gap: 10px;
                    border-radius: 4px 0px;
                    background: #eaedf3;
                    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.1);
                    width: 120px;

                    p {
                        color: var(--LP-mid-grey-_lib, #6b6c6f);
                        text-align: center;
                        font-family: Roboto;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }
                }

                margin-bottom: 10px;
            }

            .text_wrapper {
                h3 {
                    color: var(--LP-black, #3e3f42);
                    font-family: Roboto;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 24px;
                    /* 133.333% */
                    margin-bottom: 6px;
                }

                p {
                    color: #9ea0a5;
                    font-family: Roboto;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 16px;
                    /* 133.333% */
                }
            }
        }

        .property_card-M {
            display: flex;
            flex-direction: column;
            gap: 10px;
            padding-bottom: 10px;

            .timer {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .left {
                    p {
                        color: var(--LP-mid-grey-_lib, #6b6c6f);
                        font-family: Roboto;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 18px;
                        /* 150% */
                    }
                }

                .right {
                    display: flex;
                    justify-content: space-around;
                    width: 220px;

                    .time {
                        display: flex;
                        flex-direction: column;

                        p {
                            color: var(--purple-pantone-2665-c, #7d55c7);
                            text-align: center;
                            font-family: Roboto;
                            font-size: 24px;
                            font-style: normal;
                            font-weight: 300;
                            line-height: 110%;
                            /* 26.4px */
                        }

                        span {
                            color: var(--purple-pantone-2665-c-71, rgba(125, 85, 199, 0.71));
                            text-align: center;
                            font-family: Roboto;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 110%;
                            /* 13.2px */
                        }
                    }
                }
            }

            .boxes_wrapper {
                display: flex;
                flex-direction: column;
                gap: 10px;

                .box {
                    display: flex;
                    width: 100%;
                    padding: 6px 7px;
                    flex-direction: column;
                    align-items: center;
                    gap: 4px;
                    color: var(--LP-mid-grey-_lib, #6b6c6f);
                    text-align: center;
                    font-family: Roboto;
                    font-size: 10px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    border-radius: 4px;
                    border: 0px solid var(--LP-very-light, #eaedf3);
                    background: rgba(125, 85, 199, 0.15);

                    span {
                        color: var(--LP-black--_lib, #3e3f42);
                        text-align: center;
                        font-family: Roboto;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 18px;
                        /* 128.571% */
                    }
                }

                .boxes1 {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                }

                .boxes2 {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    text-wrap: nowrap;

                    .box {
                        border-radius: 4px;
                        border: 1px solid var(--LP-very-light--_lib, #eaedf3);
                        background: rgba(234, 237, 243, 0.5);
                    }
                }

                .boxes3 {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    text-wrap: nowrap;

                    .box {
                        border-radius: 4px;
                        border: 1px solid var(--LP-very-light, #eaedf3);
                        background: var(--ffffff-white, #fff);
                        height: 60px;
                        justify-content: space-between;
                    }
                }
            }

            .progressbar_wrapper {
                .header {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 4px;

                    p {
                        color: #9ea0a5;
                        font-family: Roboto;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 18px;
                        /* 150% */
                    }

                    span {
                        color: var(--Purple, #7d59c4);
                        text-align: right;
                        font-family: Roboto;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 18px;
                        /* 128.571% */
                    }
                }
            }
        }

        .property_card-B {
            border-top: 1px solid #f8f7fa;

            .buttons {
                padding: 10px 0px;
                display: flex;
                justify-content: space-between;

                .button {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 5px;

                    span {
                        color: var(--LP-mid-gray, #6b6c6f);
                        text-align: center;
                        font-family: Roboto;
                        font-size: 10px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 16px;
                        /* 160% */
                    }
                }
            }

            .stats {
                display: flex;
                justify-content: space-between;
                padding: 4px 0px;

                .date,
                .views,
                .active {
                    display: flex;
                    gap: 6px;
                    color: var(--LP-gray, #9ea0a5);
                    font-family: Roboto;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 18px;

                    /* 150% */
                    span {
                        color: var(--LP-gray, #9ea0a5);
                        font-family: Roboto;
                        font-size: 11px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 18px;
                    }

                    .dotgreen {
                        height: 4px;
                        width: 4px;
                        border-radius: 200px;
                        background-color: #34aa44;
                    }
                }

                .active {
                    display: flex;
                    align-items: center;
                }
            }
        }
    }
}

.view_details_button {
    border-radius: 0px 0px 4px 4px;
    background: linear-gradient(0deg, rgba(125, 85, 199, 0.3) 0%, rgba(255, 255, 255, 0) 107.78%), #7d55c7;
    background-blend-mode: multiply, normal;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    p {
        color: var(--ffffff-white, #fff);
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
    }
}