.dot-menu {
  position: relative;
  display: inline-block;

  .dot-menu-icon {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 24px;
    height: 20px;
    cursor: pointer;

    .dot1 {
      width: 3px;
      height: 3px;
      display: flex;
      gap: 10px;
      background-color: black;
      border-radius: 100%;
    }
    .dot2 {
      width: 3px;
      height: 3px;
      display: flex;
      gap: 10px;
      background-color: black;
      border-radius: 100%;
    }
    .dot3 {
      width: 3px;
      height: 3px;
      display: flex;
      gap: 10px;
      background-color: black;
      border-radius: 100%;
    }
  }

  .menu {
    position: absolute;
    top: 28px;
    right: 0;
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1;
    border-radius: 5px;
    transition: transform 0.3s ease;
    .menu-item {
      padding: 8px 16px;
      cursor: pointer;
      transition: background-color 0.3s ease;
      color: #3e3f42;
      leading-trim: both;
      text-edge: cap;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px; /* 157.143% */
      transition: transform 0.3s ease;
      &:hover {
        background-color: #f1f1f1;
        transition: transform 0.3s ease;
      }
    }
  }
}

.stakeholder-popup-menu {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.stakeholder-popup-menu.show {
  opacity: 1;
}
