.borrower_profile_container {
  display: flex;
  flex-direction: column;
  gap: 30px;

  .basic_details {
    display: flex;
    flex-direction: column;
    gap: 15px;

    h1 {
      color: #9ea0a5;
      font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px; /* 150% */
      text-transform: uppercase;
    }
    .info {
      display: flex;
      align-items: center;
      gap: 30px;

      div {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        gap: 14px;

        p {
          color: #3e3f42;
          font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px; /* 157.143% */
        }
      }
    }
  }
  .bio_details {
    display: flex;
    flex-direction: column;
    width: 600px;
    gap: 15px;
    h1 {
      color: #9ea0a5;
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px; /* 150% */
      text-transform: uppercase;
    }
    .info {
      p {
        color: #6b6c6f;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 157.143% */
      }
    }
  }
  .tags_details {
    display: flex;
    flex-direction: column;
    gap: 15px;
    h1 {
      color: #9ea0a5;
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px; /* 150% */
      text-transform: uppercase;
    }
    .info {
      display: flex;
      align-items: center;
      gap: 10px;
      padding-bottom: 20px;
      border-bottom: 1px solid #eaedf3;

      .tagName {
        color: #fff;
        text-align: center;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px; /* 150% */
        text-transform: uppercase;
        width: max-content;
        // height: 24px;
        padding: 6px;
        border-radius: 3px;
      }
    }
  }
  .lent_details {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding-bottom: 25px;
    border-bottom: 1px solid #eaedf3;
    .info {
      display: flex;
      align-items: center;
      gap: 55px;

      div {
        display: flex;
        flex-direction: column;
        gap: 5px;

        h1 {
          color: var(--LP-black, #3e3f42);

          /* h3/Medium 24px */
          font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
        p {
          color: #9ea0a5;
          font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 18px; /* 150% */
          text-transform: uppercase;
        }
        span {
          color: #9ea0a5;
          font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px; /* 183.333% */
        }
      }
    }
  }
  .conditions_details {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 550px;
    .heading {
      display: flex;
      align-items: center;
      justify-content: space-between;

      h1 {
        color: #9ea0a5;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px; /* 150% */
        text-transform: uppercase;
      }
      p {
        color: #9ea0a5;
        text-align: right;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 157.143% */
display: flex;
align-items: center;
gap: 10px;
      }
    }
    .info {
      p {
        color: #6b6c6f;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 157.143% */
      }
    }
  }
}
