.opportunity_main {
  position: relative;
}
.opportunity_hero_img {
  width: 100% !important;
  height: 811px;
  // margin: auto;
  // @media screen and (max-width:1600px) {
  //   width: 1400px !important;
  // }
  // @media screen and (max-width:1500px) {
  //   width: 1300px !important;
  // }
  // @media screen and (max-width:1400px) {
  //   width: 1250px !important;
  // }
  // @media screen and (max-width:1300px) {
  //   width: 1100px !important;
  // }
  // @media screen and (max-width:1000px) {
  //   width: 767px !important;
  // }

  img {
    width: 100%;
    z-index: -199;
  }
}
.opportunity_search {
  position: absolute;
  top: 170px;
  left: 50%;
  padding-top: 50px;
  transform: translate(-50%, -50%);
  width: 1200px;
  height: 276px;
  border-radius: 10px;
  background: var(--ffffff-white, #fff);
  box-shadow: 0px 8px 15px 0px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;

  @media screen and (max-width: 1300px) {
    width: 1000px !important;
  }
  @media screen and (max-width: 1100px) {
    width: 850px !important;
  }
  @media screen and (max-width: 1000px) {
    width: 700px !important;
    height: none;
  }
  @media screen and (max-width: 800px) {
    width: 600px !important;
  }
  @media screen and (max-width: 700px) {
    width: 500px !important;
  }
  @media screen and (max-width: 600px) {
    width: 400px !important;
  }
  // @media screen and (max-width: 500px) {
  //   width: 200px !important;
  // }

  h1 {
    color: #3e3f42;
    text-align: center;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 300;
    line-height: 38px;
    @media screen and (max-width: 1000px) {
      margin-top: -50px;
      font-size: 26px;
    }
  }

  .opportunity_search_input {
    display: flex;
    align-items: center;
    gap: 39px;
    flex: 1 0 0;
    // padding: 16px 20px;
    border-radius: 8px 0px 0px 8px;
    background: #fff;
    border: 1px solid var(--LP-very-light-_lib, #eaedf3);
    box-shadow: 0px 1px 2px 0px rgba(102, 113, 123, 0.21) inset;

    input {
      padding: 16px 20px;
      border-radius: 8px 0px 0px 8px;
      background: #fff;
      border: none;

      @media screen and (max-width: 1100px) {
        font-size: 14px !important;
      }
    }
  }

  select {
    display: flex;
    width: 160px;
    padding: 16px 20px;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 0px 8px 8px 0px;
    background: linear-gradient(0deg, #f6f7f9 0%, #fff 100%);
    box-shadow: 0px -1px 0px 0px #eaedf3 inset, -1px 0px 0px 0px #eaedf3 inset, 0px 1px 0px 0px #eaedf3 inset;
    border: none;

    @media screen and (max-width: 1300px) {
      padding: 16px 10px;
    }
    @media screen and (max-width: 1100px) {
      font-size: 14px !important;
    }
    @media screen and (max-width: 1000px) {
      width: 126px !important;
      padding: 16px 2px !important;
    }
  }

  .opportunity_state_city_input {
    display: flex;

    align-items: center;
    gap: 39px;
    border-radius: 8px;
    border: 1px solid var(--LP-very-light-_lib, #eaedf3);
    background: #fff;
    box-shadow: 0px 1px 2px 0px rgba(102, 113, 123, 0.21) inset;

    input {
      padding: 16px 20px;
      width: 350px;
      border: none;

      @media screen and (max-width: 1300px) {
        width: 260px !important;
      }
      @media screen and (max-width: 1100px) {
        width: 200px !important;
        font-size: 14px !important;
      }
      @media screen and (max-width: 1000px) {
        width: 163px !important;
        padding: 16px 10px;
      }
    }
  }

  button {
    color: #fff;
    cursor: pointer;
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
    display: flex;
    width: 160px;
    padding: 16px 25px 16px 20px;
    justify-content: center;
    align-items: center;
    gap: 15px;
    border-radius: 4px;
    border: 1px solid var(--purple-pantone-2665-c-71, rgba(125, 85, 199, 0.71));
    background: linear-gradient(0deg, rgba(125, 85, 199, 0.3) 0%, rgba(255, 255, 255, 0) 107.78%), #7d55c7;
    background-blend-mode: multiply, normal;
    box-shadow: 0px 1px 1px 0px rgba(19, 31, 21, 0.1), 0px 2px 0px 0px rgba(255, 255, 255, 0.06) inset;
    @media screen and (max-width: 1300px) {
      width: 100px !important;
    }
    @media screen and (max-width: 1300px) {
      height: 40px !important;
    }
  }

  .opportunity_popular {
    display: flex;
    align-items: center;
    gap: 20px;
    color: #3e3f42;

    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */

    @media screen and (max-width: 1100px) {
      span {
        font-size: 13px !important;
      }
    }
    @media screen and (max-width: 1100px) {
      gap: 9px !important;
      margin-top: -20px;
    }
  }
}

.opportunity_search_div {
  display: flex;
  justify-content: center;
  gap: 20px;
  align-items: center;
  @media screen and (max-width: 1000px) {
    flex-direction: column;
    gap: 10px;
  }
  .opportunity_search_child {
    display: flex;
  }
  .opportunity_state_city {
    display: flex;
  }

  .opportunity_popular {
  }
}

.opportunity_details {
  // width: 1110px;
  height: max-content;
  border-radius: 8px;
  background: var(--ffffff-white, #fff);
  position: absolute;
  top: 220%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 8px 15px 0px rgba(0, 0, 0, 0.2);
  margin: auto;

  @media screen and (max-width: 1500px) {
    top: 215%;
  }
  @media screen and (max-width: 1400px) {
    top: 210%;
  }
  @media screen and (max-width: 1300px) {
    width: 1000px !important;
    top: 200%;
  }
  @media screen and (max-width: 1200px) {
    width: 1000px !important;
    top: 200%;
  }
  @media screen and (max-width: 1100px) {
    width: 800px !important;
    top: 325%;
  }
  @media screen and (max-width: 1000px) {
    width: 740px !important;
    top: 420%;
  }
  @media screen and (max-width: 900px) {
    width: 640px !important;
    top: 375%;
  }
  @media screen and (max-width: 800px) {
    top: 386%;
  }
  @media screen and (max-width: 700px) {
    top: 470%;
  }
  @media screen and (max-width: 600px) {
    top: 470%;
  }

  .recommended_users_heading {
    display: flex;
    align-items: center;
    gap: 25px;
    align-self: stretch;
    margin: 30px;
    width: max-content;
    @media screen and (max-width: 1100px) {
      padding-left: 10px !important;
    }
  }

  h1 {
    color: #3e3f42;

    /* h4/Regular 20px */
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    @media screen and (max-width: 1300px) {
      font-size: 18px !important;
    }
  }
  p {
    color: var(--LP-mid-grey-_lib, #6b6c6f);
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
  }
  .single_user {
    display: flex;
    width: 255px;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    border-radius: 4px 4px 0px 0px;
    background: rgba(196, 196, 196, 0);
    backdrop-filter: blur(3px);

    @media screen and (max-width: 1300px) {
      width: 230px;
    }

    @media screen and (max-width: 1000px) {
      width: 212px;
    }

    @media screen and (max-width: 768px) {
      width: 100%;
      // flex-direction: row;
      // align-items: flex-start;
      // gap: 20px;
      // padding: 20px;
    }

    .cover_img {
      margin-bottom: -42px;

      @media screen and (max-width: 768px) {
        width: 100px;
        margin-bottom: 0;
      }
    }

    .comapnies_grp_pic,
    .user_pic {
      @media screen and (max-width: 768px) {
        height: 40px;
        width: 40px;
      }
    }

    h2 {
      @media screen and (max-width: 768px) {
        font-size: 14px;
        line-height: 20px;
      }
    }

    .total_property {
      @media screen and (max-width: 768px) {
        span {
          font-size: 10px;
          line-height: 14px;
        }
      }
    }

    .matching,
    .mutual,
    .follow_see_cmt {
      @media screen and (max-width: 768px) {
        padding: 12px;
      }
    }

    .for_you {
      @media screen and (max-width: 768px) {
        p,
        span {
          font-size: 12px;
          line-height: 18px;
        }
      }
    }
  }
}

.users {
  .user_list {
    display: flex;
    width: 1030px;
    align-items: center;
    justify-content: center;
    gap: 50px;
    margin: auto;

    @media screen and (max-width: 1300px) {
      gap: 30px !important;
      width: max-content !important;
    }
    @media screen and (max-width: 1000px) {
      flex-direction: column;
      gap: 20px;
    }
    @media screen and (max-width: 800px) {
      flex-direction: column;
      gap: 20px;
    }
  }

  h2 {
    color: #3e3f42;

    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
  }

  .user_type {
    display: flex;
    padding: 4px 12px;
    align-items: center;
    gap: 7px;
    align-self: stretch;
    border-radius: 100px;
    border: 1px solid var(--d-4-d-4-d-4-light-grey, #d4d4d4);
  }
  .bulding_circle {
    height: 21px;
    width: 21px;
    background-color: #617d92;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      color: var(--LP-black-_lib, #3e3f42);
      text-align: center;
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 16.8px */
    }
  }
  .total_property {
    span {
      color: #6b6c6f;

      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 14.4px */
    }
  }
  .matching {
    display: flex;
    padding: 12px 20px;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
    background: var(--Transparent, rgba(255, 255, 255, 0));
    box-shadow: 0px -1px 0px 0px #eaedf3 inset, 0px 1px 0px 0px #eaedf3 inset;
  }
  .mutual {
    display: flex;
    padding: 2px 20px;
    align-items: flex-start;
    gap: 15px;
    align-self: stretch;

    p {
      color: #3e3f42;

      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%; /* 16px */
    }
    span {
      color: #9ea0a5;

      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 12px; /* 14.4px */
    }
  }
  .follow_see_cmt {
    display: flex;
    padding: 2px 20px 10px 20px;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;

    .cmt_btn,
    .eye_btn {
      display: flex;
      padding: 8px 11px;
      justify-content: center;
      align-items: center;
      gap: 6px;
      flex: 1 0 0;
      border-radius: 4px;
      border: 0px solid #d8dce6;
      background: var(--LP-very-light-_lib, #eaedf3);
      box-shadow: 0px 1px 1px 0px rgba(22, 29, 37, 0.05), 0px 2px 0px 0px rgba(255, 255, 255, 0.05) inset;

      @media screen and (max-width: 1300px) {
        padding: 9px 2px !important;
      }
    }

    .follow_btn {
      display: flex;
      padding: 8px 25px;
      justify-content: center;
      align-items: center;
      gap: 6px;
      flex: 1 0 0;
      border-radius: 4px;
      border: 0px solid #d8dce6;
      background: var(--LP-very-light-_lib, #eaedf3);
      box-shadow: 0px 1px 1px 0px rgba(22, 29, 37, 0.05), 0px 2px 0px 0px rgba(255, 255, 255, 0.05) inset;
      color: #3e3f42;

      text-align: center;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 157.143% */
    }
  }
  .show_more_btn {
    display: flex;
    width: 825px;
    padding: 8px 11px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    border: 1px solid #d8dce6;
    background: linear-gradient(0deg, #f6f7f9 0%, #fff 100%);
    box-shadow: 0px 1px 1px 0px rgba(22, 29, 37, 0.05), 0px 2px 0px 0px rgba(255, 255, 255, 0.05) inset;
    margin: auto;
    margin-top: 20px;

    @media screen and (max-width: 1300px) {
      width: 713px !important;
    }
    @media screen and (max-width: 1000px) {
      width: 598px !important;
    }
  }

  .comapnies_grp_pic {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background-color: #617d92;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      border: none;
    }
  }

  .for_you {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    p {
      color: var(--3-fa-2-f-7-links-informational-blue, #3fa2f7);
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 22px;
    }
    span {
      color: #3e3f42;

      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
    }
    b {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: grey;
    }
  }
}

.Syndicates_apply {
  .show_btn {
    display: flex;
    width: 825px;
    padding: 8px 11px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    border: 1px solid #d8dce6;
    background: linear-gradient(0deg, #f6f7f9 0%, #fff 100%);
    box-shadow: 0px 1px 1px 0px rgba(22, 29, 37, 0.05), 0px 2px 0px 0px rgba(255, 255, 255, 0.05) inset;
    margin: auto;
    margin-top: 60px;

    @media screen and (max-width: 1300px) {
      width: 713px !important;
    }
    @media screen and (max-width: 1000px) {
      width: 598px !important;
    }
  }
  .commercial_list {
    display: flex;
    align-items: flex-start;
    gap: 50px;
    flex-wrap: wrap;
    justify-content: center;

    @media screen and (min-width: 501px) and (max-width: 1100px) {
      justify-content: space-between;
      flex-direction: column;
    }

    @media screen and (max-width: 500px) {
      flex-direction: column;
      align-items: center;
    }
  }

  .single_commercial {
    width: calc(33.33% - 50px);

    flex-shrink: 0;
    border-radius: 4px;
    border: 1px solid var(--LP-very-light, #eaedf3);
    background: #fff;
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.15);
    padding: 6px;
    @media screen and (max-width: 1100px) {
      width: 80%;
      margin: auto;
    }
    @media screen and (max-width: 900px) {
      width: 60%;
      margin: auto;
    }
    @media screen and (max-width: 500px) {
      width: 40%;
      margin: auto;
    }

    @media screen and (max-width: 500px) {
      margin-bottom: 20px;
    }
  }

  .commercial_slider_btns {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 0px 0px 4px 4px;
    opacity: 0.5;
    background: var(--LP-black, #3e3f42);
    padding: 5px;
    bottom: 5px;
    width: 100%;
    z-index: 199999;
    color: white;

    p {
      color: #fff;
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    span {
    }
  }
  .single_commercial {
    span {
      border-radius: 4px 0px;
      background: var(--LP-very-light-_lib, #eaedf3);
      box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.1);
      display: inline-flex;
      padding: 5px 10px;
      align-items: center;
      gap: 10px;
      position: absolute;
      top: 0px;
      left: 0;
      font-size: 12px;
      color: var(--LP-mid-grey-_lib, #6b6c6f);
      text-align: center;
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .heading_location {
      display: flex;
      flex-direction: column;
      padding: 10px;
      gap: 10px;

      h2 {
        font-size: 14px;
        font-weight: 500;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
      }
      p {
        color: #9ea0a5;

        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 133.333% */
      }
    }
    .offer_text {
      color: var(--LP-mid-grey-_lib, #6b6c6f);
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 150% */
      padding-left: 10px;
      margin-top: 10px;
    }
    .time_period {
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin-top: 10px;

      div {
        p {
          color: var(--purple-pantone-2665-c, #7d55c7);
          text-align: center;
          font-family: Roboto;
          font-size: 24px;
          font-style: normal;
          font-weight: 300;
          line-height: 110%; /* 26.4px */
        }

        :nth-child(2) {
          color: var(--purple-pantone-2665-c-71, rgba(125, 85, 199, 0.71));
          text-align: center;
          font-family: Roboto;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 110%; /* 13.2px */
        }
      }
    }
    .LTV {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px;
      gap: 9px;

      div {
        display: flex;
        padding: 6px 7px;
        flex-direction: column;
        align-items: center;
        gap: 4px;
        flex: 1 0 0;
        border-radius: 4px;
        border: 0px solid var(--LP-very-light, #eaedf3);
        background: rgba(125, 85, 199, 0.15);

        p {
          color: var(--LP-mid-grey-_lib, #6b6c6f);
          text-align: center;
          font-family: Roboto;
          font-size: 10px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        h2 {
          color: var(--LP-black-_lib, #3e3f42);
          text-align: center;
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 18px; /* 128.571% */
        }
      }
    }

    .investments {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px;
      gap: 9px;

      div {
        display: flex;
        padding: 6px 2px;
        flex-direction: column;
        align-items: center;
        gap: 4px;
        flex: 1 0 0;
        align-self: stretch;
        border-radius: 4px;
        border: 1px solid var(--LP-very-light-_lib, #eaedf3);
        background: rgba(234, 237, 243, 0.5);

        h2 {
          color: var(--LP-black-_lib, #3e3f42);
          text-align: center;
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 18px; /* 128.571% */
        }

        p {
          color: var(--LP-mid-grey-_lib, #6b6c6f);
          text-align: center;
          font-family: Roboto;
          font-size: 10px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }

    .raised {
      display: flex;
      align-items: center;
      justify-content: space-between;
      // margin: 10px;
      margin: 10px;
      margin-bottom: 0px;

      img {
        padding-left: 10px;
      }
    }
    .view_details {
      width: 100%;
      height: 38px;
      flex-shrink: 0;
      border-radius: 0px 0px 4px 4px;
      background: linear-gradient(0deg, rgba(125, 85, 199, 0.3) 0%, rgba(255, 255, 255, 0) 107.78%), #7d55c7;
      background-blend-mode: multiply, normal;
      border: none;
      margin-top: 15px;
      color: white;
      color: var(--ffffff-white, #fff);
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 157.143% */
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
    }
  }
}

.commercial_list {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
}

.browse_city {
  @media screen and (max-width: 700px) {
    padding: 20px;
  }
  h1 {
    color: #3e3f42;

    /* h4/Regular 20px */
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .cities {
    display: flex;
    width: 825px;
    align-items: center;
    justify-content: center;
    gap: 50px;
    margin: auto;
    margin-bottom: 30px;

    @media screen and (max-width: 1000px) {
      width: 100%;
      padding: 10px;
    }

    @media screen and (max-width: 700px) {
      width: 100%;
      flex-direction: column;
      padding: 20px;
    }

    div {
      display: flex;
      padding: 15px 10px 10px 10px;
      flex-direction: column;
      align-items: center;
      gap: 20px;
      flex: 1 0 0;
      align-self: stretch;
      border: 1px solid var(--LP-very-light-_lib, #eaedf3);
      background: var(--LP-white-_lib, #fff);
      border-radius: 8px;

      img {
        max-width: 100%;
        height: auto; /* Maintain aspect ratio */
      }

      @media screen and (max-width: 700px) {
        img {
          width: 100%;
        }
      }

      p {
        color: #3e3f42;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 150% */
      }
    }
  }
}
