.bar_chart_wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  justify-content: center;
  height: 100%;
  padding: 30px;
  gap: 30px;
  .bar_chart_cylinder {
    display: flex;
    flex-direction: column;
    width: 190px;
    height: 343px;
    border-radius: 8px 0px 0px 8px;
    background: transparent;

    .bar_chart_cylinder_item {
      width: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: center;

      &:first-child {
        .bar_chart_cylinder_item_value {
          border-radius: 8px 8px 0px 0px;
        }
      }
      &:last-child {
        .bar_chart_cylinder_item_value {
          border-radius: 0px 0px 8px 8px;
        }
      }

      .bar_chart_cylinder_item_value {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        height: 100%;
      }
    }
  }

  .bar_chart_legends {
    display: flex;
    flex-direction: column;
    align-items: center;

    .bar_chart_legend_item {
      display: flex;
      align-items: center;
      margin: 5px 0;
      gap: 10px;
    }

    .bar_chart_legend_color {
      min-width: 7px;
      height: 7px;
      border-radius: 86%;
    }

    .bar_chart_legend_text {
      color: #9ea0a5;
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px; /* 150% */
    }
  }
}
