.deals-room profile {
}
.menu_border {
  border: 1px solid #c4c4c4;
  padding: 20px;
}

.hr {
  border-top: 1px solid #eaedf3;
  padding-bottom: 20px;
  width: 90%;
  margin: auto;
}

.profile {
  .center-wrapper {
    padding: 50px;

    @media screen and (max-width: 1200px) {
      padding: 50px 20px 0px 80px !important;
    }
    @media screen and (max-width: 1000px) {
      padding: 50px 20px 0px 100px !important;
    }
    @media screen and (max-width: 900px) {
      padding: 50px 20px 0px 120px !important;
    }
    .left-menu-sort.sub-menu-active {
      background-color: blue;
      color: white;
    }
  }
  .profile-active {
    width: 6px !important;
    height: 6px !important;
    border-radius: 50%;
  }

  .deal-title {
    border-bottom: 1px solid #eaedf3 !important;
  }

  .left-menu-sort {
    padding-left: 49px !important;
  }

  .left-menu-sort-active {
    // color: var(--purple-pantone-2665-c, #240d4f);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
  }

  .deals-menu-active {
    box-shadow: none !important;
    border-left: 4px solid !important;
  }
}
.file_btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.288);
  border: none;
  outline: 1px solid purple;
  color: white;
  padding: 5px;
  cursor: pointer;
  display: none;
  // width: 80px;
}
.left_profile_img {
  width: 90px !important;
  height: 90px !important;
  overflow: hidden !important;
  border-radius: 50% !important;
  padding: 0 !important;
  // background-color: #38393ca4;
  border: 1px solid rgba(168, 161, 161, 0.678);
  // margin: auto;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .setting {
    position: absolute;
    top: 184px;
    left: 141px;
    z-index: 199999999999;
  }
  .options {
    display: flex;
    flex-direction: column;
    background-color: white;
    width: 80px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    gap: 5px;
    border-radius: 10px;

    span {
      font-size: 12px;
      cursor: pointer;
      padding: 8px 10px 8px 10px;
      text-align: center;
      &:hover {
        background-color: rgba(231, 177, 231, 0.849);
      }
    }
  }
}

.center-wrapper.active {
  /* Styles for active state */
  /* For example, change the color of the content */
  color: #602fbc; /* Change this to your desired color */
}
.TreasurySidebar-more {
  color: var(--primary-gray-100-text, #9ea0a5);
  leading-trim: both;
  text-edge: cap;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  text-align: start;
  margin-left: 50px;
  font-weight: 500;
  line-height: 22px; /* 157.143% */
  text-transform: capitalize;
}

.left-menu-drop {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Push the arrow to the far right */
  padding: 10px;
  cursor: pointer;
}




.arrow-icon {
  float: right;
  position: absolute;
  left: 16%;

}

