.Financinghistory-details-chart {
  width: 65%;
  border: 1px solid #eaedf3;
}
@media screen and (max-width: 900px) {
  .Financinghistory-details-chart {
    width: 100%;
  }
}

.backend-data-all {
  background-color: blueviolet;
}

.textdata {
  margin: 2vw;
}

.Financinghistory-details-chart-box {
  gap: 20px;
  display: flex;
  // flex-wrap: wrap;
  justify-content: space-between;
}

@media screen and (max-width: 900px) {
  .Financinghistory-details-chart-box {
    flex-direction: column;
  }
}
@media screen and (max-width: 900px) {
  .View-details-chart {
    width: 100%;
  }
}

.second-table {
  display: flex;
  gap: 2vw;
  margin: 2vw;
}

@media screen and (max-width: 768px) {
  .Financinghistory-details-chart-data {
    margin: 2vw auto;
  }

  .textdata {
    margin: 3vw;
  }

  .second-table {
    flex-direction: column;
    gap: 3vw;
  }
}

.Financinghistory-graph {
  margin: 0;
  padding: 0;
}
.Financinghistory-Filter {
  padding: 0;
  margin: 0;
  height: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  border: none;
  color: black;
  background-color: white;
  border-radius: 4px;
  border: 1px solid var(--Primary-Purple-50, #eaedf3);
  background: #fff;
}

.Financinghistory-container {
  border: 1px solid #eaedf3;
}
.Financinghistory-container-data {
  padding: 20px;
  display: flex;
  gap: 20px;
  align-items: center;
}
.select-wrapper {
  outline: none;
  border: 1px solid #eaedf3;
}
.Financinghistory-main-conatiner {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.Financinghistory-cash {
  justify-content: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.Financinghistory-div-flex {
  display: flex;
  align-items: center;
  gap: 10px;
}

.Financinghistory-div-flex p {
  color: #3e3f42;
  leading-trim: both;
  text-edge: cap;
  font-family: Roboto;
  font-size: 13.453px;
  font-style: normal;
  font-weight: 400;
  line-height: 21.14px; /* 157.143% */
}
.Financinghistory-green {
  background: var(--Action-Green, #50cd89);
  width: 10px;
  height: 10px;
  border-radius: 5px;
}
.Financinghistory-blue {
  background: var(--Action-Blue, #1665d8);
  width: 8px;
  height: 8px;
  border-radius: 5px;
}
.Financinghistory-cash {
  span {
    color: #3e3f42;
    leading-trim: both;
    text-edge: cap;
    font-family: Roboto;
    font-size: 19.946px;
    font-style: normal;
    font-weight: 500;
    line-height: 26.906px; /* 134.894% */
    text-transform: capitalize;
  }
  h1 {
    color: var(--Black, #3e3f42);
    leading-trim: both;
    text-edge: cap;
    font-family: Roboto;
    font-size: 39.892px;
    font-style: normal;
    font-weight: 700;
    line-height: 43.881px; /* 110% */
    letter-spacing: -0.798px;
  }
  .Financinghistory-div-flex {
    .Financinghistory-green {
      p {
        color: #3e3f42;
        leading-trim: both;
        text-edge: cap;
        font-family: Roboto;
        font-size: 13.453px;
        font-style: normal;
        font-weight: 400;
        line-height: 21.14px; /* 157.143% */
      }
    }
  }
}

.continer-captab {
  width: fit-content;
}
.Financinghistory-image-container {
  display: flex;
  gap: 50px;
  margin-top: 50px;
}
