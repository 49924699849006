.custom-calendar {
  font-family: "Roboto", sans-serif;
  text-align: center;
  background-color: #fff;
  border-radius: 12px;
  padding: 16px;
  margin: 0 auto;
}

.calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.calendar-header button {
  background: none;
  border: none;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  padding: 3px 8px;
  border-radius: 6px;
  transition: background-color 0.2s ease;
}

.calendar-header button:hover {
  background-color: rgba(98, 0, 234, 0.1);
}

.calendar-header span {
  font-size: 14px;
  font-weight: bold;
  color: #333;
}

.calendar-grid {
  display: flex;
  flex-direction: column;
  gap: 17px;
  padding: 12px;
  border-radius: 6px;
  background: rgba(125, 85, 199, 0.07);
  .weekRow,
  .dateRow {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 10px;
  }
}

.calendar-day-header {
  color: var(--Black, #3e3f42);
  text-align: center;
  font-family: Roboto;
  font-size: 9.363px;
  font-style: normal;
  font-weight: 400;
  line-height: 119%; /* 11.142px */
}

.calendar-day {
  width: 25px;
  height: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 12px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.2s ease, color 0.2s ease;
  display: flex;
  padding: 7.16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--Black, #3e3f42);
  text-align: center;
  font-family: Roboto;
  font-size: 9.363px;
  font-style: normal;
  font-weight: 400;
  line-height: 121%; /* 11.142px */
}

.calendar-day:hover {
  background-color: rgba(98, 0, 234, 0.1);
}

.calendar-day.selected {
  border-radius: 22.912px;
  background: var(--Primary-Purple-500, #7d55c7);
  display: inline-flex;
  padding: 7.16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 7.16px;
  color: white;
}

.calendar-day.today {
  display: inline-flex;
  padding: 7.16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 7.16px;
  border-radius: 22.912px;
  color: white;
  background: var(--Primary-Purple-500, #7d55c7);
}

.calendar-day.outside-month {
  color: #ccc;
  cursor: not-allowed;
}

.calendar-day.outside-month:hover {
  background-color: transparent;
}

.DialogContent_input_calender {
  color: var(--Primary-Gray-200, #9ea0a5);
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
  text-transform: uppercase;
}

.DialogContent_input_calender_container {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.Assign_button_container {
  display: flex;
  align-items: center;
  justify-content: center;
  Button {
    display: flex;
    height: 34px;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 10px;
    background: #7d55c7;
  }
}

.assignTaskContainer {
  .MuiPaper-root {
    margin: 0;
    display: flex;
    width: 100%;
    max-width: 600px;
    padding: 20px;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    border: 1px solid #7d55c7;
    background: #fff;
    box-shadow: 0px 2.148px 7.16px 0px rgba(0, 0, 0, 0.15);
    h4 {
      color: var(--Black, #3e3f42);
      text-align: left;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 119%; /* 19.04px */
      margin-bottom: 20px;
      width: 100%;
    }
    .assignTaskWrapper {
      padding: 0;
      gap: 15px;

      .DialogContent_input_calender_container {
        gap: 5px;
        width: 100%;

        label {
          color: var(--Primary-Gray-200, #9ea0a5);
          font-family: Roboto;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 18px; /* 150% */
          text-transform: uppercase;
        }

        .calendarInputBox {
          display: flex;
          height: 34px;
          justify-content: space-between;
          align-items: center;
          border-radius: 10px;
          padding: 7px 7px 7px 15px;
          border: 1px solid #7d55c7;
          background: var(--White, #fff);
          input {
            margin: 0;
            border: 0;
            outline: 0;
            padding: 0;
            background: transparent;
            box-shadow: none;

            &:focus-within {
              border: 0;
              outline: 0;
            }
            &:focus-visible {
              border: 0;
              outline: 0;
            }
          }
        }
      }

      .DialogContent_input_selector_container {
        gap: 5px;
        width: 100%;
      }
    }

    .assignTaskBtnBox {
      padding: 0;
      margin-top: 30px;
      .Assign_button_container {
        button {
          width: 100%;
          max-width: 83px;
          display: flex;
          height: 34px;
          padding: 10px 20px;
          justify-content: center;
          align-items: center;
          gap: 6px;
          cursor: pointer;
          border-radius: 10px;
          background: #7d55c7;

          &:hover {
            background: #7d55c7 !important;
          }

          span {
            color: #fff;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
      }
    }
  }
}

.MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation8 MuiPopover-paper css-3bmhjh-MuiPaper-root-MuiPopover-paper {
  opacity: 1;
  transform: none;
  border-radius: 12px;
  padding: 5px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px;
  width: 300px;
  transition: opacity 388ms cubic-bezier(0.4, 0, 0.2, 1), transform 258ms cubic-bezier(0.4, 0, 0.2, 1);
  top: 516px !important;
  left: 493px;
  transform-origin: 0px 231.313px;
}

.popover-paper {
  opacity: 1;
  transform: none;
  transition: opacity 324ms cubic-bezier(0.4, 0, 0.2, 1), transform 216ms cubic-bezier(0.4, 0, 0.2, 1);
  top: 411px !important;
  left: 493px;
  width: 550px;
  transform-origin: 0px 231.313px;
}

.popover-user {
  padding: 10px;
  cursor: pointer;
  background-color: transparent;
  transition: background 0.2s;
}

.popover-user:hover {
  background-color: #f9f9f9;
}

.popover-user.selected {
  background-color: #f0f0f0;
}

.calendar-day.disabled {
  pointer-events: none;
  color: #ccc;
  cursor: not-allowed;
}
