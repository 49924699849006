.connections {
    border: 1px solid #eaedf3;
    .connection_box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
        padding: 20px;
        background-color: #ffffff;
        border: 1px solid #EAEDF3;
        filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.04));

        .connection_box_left {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 20px;

            .text1 {
                color: #9EA0A5;
                font-family: Roboto;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px
            }

            .name {
                color: #3E3F42;
                font-family: Roboto;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px
            }

            .text2 {
                color: #6B6C6F;
                font-family: Roboto;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px
            }

            img {
                height: 80px;
                width: 80px;
                border-radius: 100%;
            }
        }

        .connection_box_right {
            button {
                border-radius: 6px;
                background-color: rgba(255, 255, 255, 0);
                border: 1px solid #D8DCE6;
                padding: 0;
                height: 38px;
                width: 73px;
                color: #3E3F42;
            
            }
        }

    }

    .connections_header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding: 20px;
    }

    .search_bar {
        margin-top: -5px;
        position: relative;
        width: 220px;
        height: 38px;
        border: 2px solid #eaedf3;
        border-radius: 5px;
        display: flex;
        align-items: center;

        input {
            all: unset;
            width: 200px;
            padding-right: 25px;
            padding-left: 2px;
            color: #9EA0A5;
            font-family: Roboto;
            font-size: 14px;
            font-weight: 400;
            line-height: 22px
        }

        svg {
            position: absolute;
            right: 5px;
        }
    }
}