.threedotmenu-container {
  position: relative;
  display: inline-block;

  button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 20px;

    &:focus {
      outline: none;
    }
  }

  .threedotmenu-menu {
    position: absolute;
    top: 40px;
    right: 0;
    z-index: 100;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    left: 18px;
    opacity: 0;
    transform: translateY(-10px);
    transition: opacity 5s ease, transform 5s ease;
    width: 100%;
    min-width: 312px;
    padding: 19px;
    border-radius: 0px 20px 20px 20px;
    border: 1px solid #7d55c7;
    background: linear-gradient(0deg, rgba(125, 85, 199, 0.07) 0%, rgba(125, 85, 199, 0.07) 100%), #fff;

    button {
      cursor: pointer;
      border-radius: 10px;
      border: 1px solid #a1a1a1;
      background: #fff;
      display: flex;
      height: 34px;
      padding: 9px;
      align-items: center;
      gap: 6px;
      color: #989898;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      width: fit-content;

      &:hover {
        color: #3e3f42;
        border-radius: 10px;
        border: 1px solid #7d55c7;
        background: linear-gradient(0deg, rgba(125, 85, 199, 0.15) 0%, rgba(125, 85, 199, 0.15) 100%), #fff;
      }

      // &.threedotmenu-active {
      //   color: #3e3f42;
      //   border-radius: 10px;
      //   border: 1px solid #7d55c7;
      //   background: linear-gradient(0deg, rgba(125, 85, 199, 0.15) 0%, rgba(125, 85, 199, 0.15) 100%), #fff;
      // }
    }
  }

  .threedotmenu-menu.open {
    opacity: 1;
    transform: translateY(0);
  }
}

// Sheudle

.schedule-dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.schedule-dialog {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  min-width: 400px;
  max-width: 600px;
}

.close-schedule-dialog {
  background-color: #6b46c1;
  color: white;
  border: none;
  padding: 10px 15px;
  margin-top: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.close-schedule-dialog:hover {
  background-color: #5a3ba6;
}

.threedotmenu-container {
  position: relative;
}

.threedotmenu-menu {
  position: absolute;
  background: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  z-index: 1000;
  right: 0;
}

.threedotmenu-menu button {
  background: none;
  border: none;
  padding: 10px 20px;
  width: 100%;
  text-align: left;
  cursor: pointer;
}

.threedotmenu-active {
  background-color: #f0f0f0;
}
