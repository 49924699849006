.contact_details {
    width: 661px; 
    height: fit-content;
    padding: 20px;
    background-color: #ffffff;
    overflow-y: auto;
    position: absolute;
    right: 2px;
    top:0px;
    border: 1px solid #eaedf3;
    border-radius: 4px;
    z-index: 111111;
    max-height: 100vh;
    
    &.visible {
        animation: slideIn 0.3s ease-in-out;
      
    }
    @keyframes slideIn {
        from {
          width: 10px;
          overflow-y: hidden;
          box-shadow: none;
        }
      
        to {
          width: 661px;
          overflow-y: auto;
          box-shadow: 3px 0 13px #cddddd;
        }
      }
    .contact_details_header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        .left {
            display: flex;
            align-items: center;
            gap: 20px;

            .person {
                width: 100px;
                height: 100px;
                border-radius: 50%;
            }

            p {
                color: #3E3F42;
                font-family: Roboto;
                font-size: 20px;
                font-weight: 500;
                line-height: 30px;
                display: flex;
                align-items: center;
                gap: 6px;

            }

            span {
                color: #6B6C6F;
                font-family: Roboto;
                font-size: 14px;
                font-weight: 400;
                line-height: 22px;
            }
        }
    }

    .contact_details_middle {
        display: flex;
        flex-direction: column;
        gap: 20px;
        border-bottom: 1px solid #eaedf3;
        padding-bottom: 20px;


        span {
            color: #3E3F42;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
        }

        .contact_details_nav_wrapper {
            display: flex;
            align-items: center;
            gap: 20px;
            margin-top: 20px;

            .contact_details_nav {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 14px;
            }
        }

        .contact_details_bio {
            .info {
                color: #6B6C6F;
                font-family: Roboto;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;
                margin-top: 20px;
            }
        }

        .contact_details_tag {
            .tag_input_wrapper {
                margin-top: 5px;
                position: relative;
                padding: 0 26px;
                height: 38px;
                background-color: #FFFFFF00;
                border: 1px solid #eaedf3;
                border-radius: 5px;
                display: flex;
                align-items: center;
                width: 479px;

                input {
                    outline: none;
                    border: none;

                    &::placeholder {
                        color: #9EA0A5;
                        font-family: Roboto;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 22px;
                    }
                }

            }

            .tags_wrapper {
                display: flex;
                align-items: center;
                gap: 12px;
                margin-top: 20px;
            }

            .tag {
                border-radius: 4px;
                padding: 4px 10px;
                display: flex;
                align-items: center;
                gap: 4px;
                color: #ffffff;
                font-family: Roboto;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 18px;
                text-transform: uppercase;
            }

            .tag.green {
                background-color: #34aa44;
            }

            .tag.orange {
                background-color: #f6ab2f;
            }

            .tag.blue {
                background-color: #1665d8;
            }

        }

        .contact_details_assets {
            .header {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            .assets_wrapper{
                display: flex;
                align-items: center;
                gap: 20px;
                margin-top: 20px;
            }

            span {
                color: #9EA0A5;
                text-align: right;
                font-family: Roboto;
                font-size: 12px;
                font-weight: 400;
                line-height: 18px;
            }

            .name {
                color: #3E3F42;
                font-family: Roboto;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 18px;
                margin-top: 12px;
            }

            .desc {
                color: #6B6C6F;
                font-family: Roboto;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
            }
            img{
                height: 65px;
                width: 65px;
                border-radius: 4px;
            }
        }

    }


    .title {
        color: #9EA0A5;
        font-family: Roboto;
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        text-transform: uppercase;
    }

    .contact_details_activity {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .chat_btn {
        margin-top: 5px;
        position: relative;
        padding: 0 26px;
        height: 38px;
        background-color: #FFFFFF00;
        border: 1px solid #eaedf3;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 12px;
        color: #3E3F42;
        font-family: Roboto;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;

        img {
            height: 20px;
            width: 20px;
        }
    }
}