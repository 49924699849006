.document_details {
    background-color: #ffffff;
    padding: 20px;
    border: 1px solid #EAEDF3;
    filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.04));
    margin-top: 120px;
    height: 670px;
    .details_header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 12px;

        .details_header_left {
            display: flex;
            align-items: center;
            gap: 12px;

            h3 {
                color: #3E3F42;
                font-family: Roboto;
                font-size: 14px;
                font-weight: 500;
                line-height: 22px;
            }
        }

        .details_header_right {
            display: flex;
            align-items: center;
            gap: 12px;
        }
    }
    .document_content{
        .document_content_upper{
            padding: 20px 0;
            border-bottom: 1px solid #EAEDF3;
            h2{
                color: #3E3F42;
                font-family: Roboto;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 28px;   
                margin-bottom: 10px;  
            }
            p{
                color: #3E3F42;
                font-family: Roboto;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
            }
        }
        .document_content_lower{
            padding: 20px 0;
            p{
                color: #6B6C6F;
                font-family: Roboto;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;     
            }
        }
    }
}