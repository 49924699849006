.wrapper {
  border: 3px solid blue;
}


.barCompleted {
  background-color: lightblue;
  width: 80%;
}

.label {
  font-size: 20px;
  color: green;
}


.custom-progress-bar {
  height: 20px !important; 
  border-radius: 10px !important; 
}

.custom-progress-bar .barCompleted {
  height: 100%; 
}
