.CaptableStake {
  .title {
    margin: 10px 0;
  }

  .content {
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
      margin-bottom: 10px;
      .header-data {
        display: flex;
        align-items: center;
        gap: 20px;
      }
      .header-data p {
        color: var(--Black, #3e3f42);
        leading-trim: both;
        text-edge: cap;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px; /* 200% */
      }
      .calender-data {
        display: flex;
        align-items: center;
        gap: 10px;

        img {
          width: 20px;
          height: 20px;
        }
      }
    }

    .summary-data {
      margin-bottom: 10px;
    }

    .search-filter {
      display: flex;
      justify-content: space-between;
      padding: 10px;
      margin-bottom: 10px;
    }

    .table-container {
      overflow-x: auto;

      table {
        width: 100%;
        border-collapse: collapse;
        border: 1px solid #eaedf3;

        th,
        td {
          padding: 8px;
          text-align: left;
          padding-top: 20px;
          padding-bottom: 20px;
        }

        th {
          color: var(--primary-gray-100-text, #9ea0a5);
          leading-trim: both;
          text-edge: cap;
          font-family: Roboto;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 18px; /* 150% */
          text-transform: uppercase;
          padding-top: 10px;
          padding-bottom: 10px;
        }

        tbody tr:nth-child(even) {
          border: 1px solid #eaedf3;
          padding-top: 10px;
          padding-bottom: 10px;
        }
      }
    }
  }
}

.CaptableStake-data-container {
  display: flex;
  gap: 25px;

  justify-content: space-between;
}
.CaptableStake-data {
  border: 1px solid #eaedf3;
  height: 150px;
  justify-content: space-between;
  padding: 10px;
  width: 300px;
}
.CaptableStake-data-container {
  display: flex;
  gap: 25px;
}
.CaptableStake-data-total {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.CaptableStake-data {
  color: var(--primary-gray-100-text, #9ea0a5);
  leading-trim: both;
  text-edge: cap;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 233.333% */
  text-transform: uppercase;
}
.CaptableStake-data p {
  color: var(--primary-gray-100-text, #9ea0a5);
  leading-trim: both;
  text-edge: cap;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 233.333% */
  text-transform: uppercase;
}
.CaptableStake-data-total h1 {
  margin: 0;
  font-size: 24px;
}
.CaptableStake-Filterdata {
  padding: 0;
  margin: 0;
  height: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  border: none;
  color: black;
  background-color: white;
  border-radius: 4px;
  border: 1px solid var(--Primary-Purple-50, #eaedf3);
  background: #fff;
}
.search-filter-flex {
  display: flex;
}
.summary-details p {
  color: var(--Primary-Purple-500, #7d55c7);
  leading-trim: both;
  text-edge: cap;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 200% */
}

@media screen and (max-width: 1068px) {
  .CaptableStake-data-container {
    flex-direction: column;
  }
  .CaptableStake-data-container {
    width: 100%;
  }
  .CaptableStake-data {
    width: 100%;
  }
}
