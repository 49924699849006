.f-carousel {
  max-width: 100%;
  margin: 0 auto;
}

.f-carousel__slide {
  display: flex;
  align-items: center;
  justify-content: center;
}

.f-carousel__slide img {
  width: 100%;
  height: auto;
}
