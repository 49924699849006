.borrowerActivity_container {
  .active_icon {
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background-color: #34aa44;
    margin-top: 16px;
  }
  .user_info {
    display: flex;
    gap: 20px;
    align-items: start;
    border-bottom: 1px solid #eaedf3;
    padding-bottom: 25px;

    div {
      h1 {
        color: #3e3f42;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px; /* 157.143% */

        span {
          font-weight: 400;
        }
      }
    }

    .more_info {
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      gap: 12px;

      .postedCommentBox{
        width: 644px;
height: 95px;
flex-shrink: 0;
background-color: #e2e5ed3d;
padding: 10px;
box-shadow: 0px 0px 0px 1px #eaedf3;
border-radius: 2px;

p{
    color: #3E3F42;
font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 22px; /* 157.143% */
}
      }

      p {
        color: #9ea0a5;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 150% */
      }
    }
    .before_now {
      display: flex;
      align-items: center;
      gap: 10px;



      .before {
        width: 160px;
        height: 83px;
        flex-shrink: 0;
        border-radius: 10px;
        border: 1px solid var(--LP-very-light-_lib, #eaedf3);
        background: rgba(234, 237, 243, 0.5);
        padding: 0px 10px 0px 10px;
        display: flex;
        flex-direction: column;
        gap: 5px;
        justify-content: center;
        span {
          color: #9ea0a5;
          font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          
        }
        p {
          color: #9ea0a5;
          font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          
          text-transform: uppercase;
        }
      }
      .now {
        width: 160px;
        height: 83px;
        flex-shrink: 0;
        border-radius: 10px;
        border: 1px solid var(--LP-very-light-_lib, #eaedf3);
        background: var(--LP-white-_lib, #fff);
        padding: 0px 10px 0px 10px;
        display: flex;
        flex-direction: column;
        gap: 5px;
        justify-content: center;

        span {
            color: #9ea0a5;
            font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            
          }
          p {
            color: #9ea0a5;
            font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            
            text-transform: uppercase;
          }
      }
    }
  }
}
