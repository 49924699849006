.updates-section {
  display: flex;
  gap: 20px;
  flex-direction: column;
  max-width: 730px;
  .update-main {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .update {
    display: flex;
    gap: 20px;
    align-items: flex-start;
    border-bottom: 1px solid #e6e6e6;
    padding-bottom: 20px;
    .update-right {
      border: 1px solid #e6e6e6;
      border-radius: 8px;
      padding: 15px;
      margin-bottom: 20px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      width: 60vw;
    }

    &-header {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      gap: 10px;

      .avatar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-right: 10px;
      }
    }
  }
  .update-details {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .update-details_images {
      display: flex;
      gap: 10px;
    }

    .update-details_title {
      color: #3e3f42;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px; /* 157.143% */
    }

    span,
    p {
      color: #6b6c6f;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 157.143% */
    }
  }

  .before_now_div {
    display: flex;
    gap: 20px;
    align-items: center;

    .before_now_div_one {
      display: flex;
      gap: 5px;
      padding: 10px;
      flex-direction: column;

      border-radius: 10px;
      border: 1px solid var(--LP-very-light-_lib, #eaedf3);
      background: rgba(234, 237, 243, 0.5);

      span {
        color: #9ea0a5;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px; /* 233.333% */
      }
      p {
        color: #9ea0a5;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px; /* 233.333% */
        text-transform: uppercase;
      }
      h3 {
        color: var(--LP-mid-grey-_lib, #6b6c6f);
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 16px */
      }
      .now_amount {
        color: var(--LP-Pantone, #7d55c7);
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 16px */
      }
    }
  }
}
