.asset-card {
  width: 350px;
  // height: 524px;
  fill: #fff;
  border: 1px solid #eaedf3;
  stroke-width: 1px;
  stroke: #eaedf3;
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.04));
}
.asset-card-inner {
  padding: 0px 27px 0 30px;
  box-sizing: border-box;
}

.contact-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.contact-head > span {
  color: #9ea0a5;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
  text-transform: uppercase;
}
// .asset-heading {
//   margin: 10px 0 12px 0;
// }

.asset-heading > span {
  color: var(--LP-black, #3e3f42);
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 155.556% */
}

.ruppes-section {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 12px;
}

.rp-section-heading > span {
  color: #3e3f42;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 155.556% */
}

.ruppes-section > div:last-child > span {
  color: #9ea0a5;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}
.location-section {
  display: flex;
  align-items: center;
  gap: 18px;
  margin-bottom: 12px;
}

.location-section > div > span {
  color: #9ea0a5;
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.flex {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  gap: 16px;
}

.calender-section > div > span {
  color: #9ea0a5;
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}

.profile-section {
  margin-top: 24px;
}

.profile-section > div > span {
  color: var(--LP-black, #3e3f42);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; 
}


// new

.addedBy > div > span {
  color: var(--LP-black, #9ea0a5);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; 
}




.contactPhone > div > span {
  color: var(--LP-black, #9ea0a5);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; 
}







.contactWebsite > div > span {
  color: var(--LP-black, #9ea0a5);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; 
}




.contactBrochure > div > span {
  color: var(--LP-black, #9ea0a5);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; 
}


// other


.mob-section {
  gap: 16px;
  align-items: flex-start !important;
}

.mob-section > div > span {
  color: var(--LP-black, #3e3f42);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}

.contact-detail {
  color: var(--LP-black, #3e3f42);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}
.addnote {
  border-top: 1px solid #eaedf3;
  // margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
  padding: 10px 0;
}
.addnote span {
  color: var(--LP-black, #3e3f42);
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}

.ChatNow_button {
  width: 90%;
  text-align: center;
  padding: 10px;
  position: relative;
  border-radius: 20px;
  border: 2px solid;
  h4 {
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}

.Invest_button {
  background: rgb(25, 114, 88);
  width: 90%;
  text-align: center;
  padding: 10px;
  position: relative;
  border-radius: 20px;
  h4 {
    color: white;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

.addnote.disabled {
  pointer-events: none;
  // opacity: 0.5;
}

.AssetCard_input {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 296px;
  margin: auto;
}

/* InvestmentDialog.css */

.investment-dialog {
  border-radius: 10px;
  padding: 20px;
}

.investment-dialog-title {
  font-weight: bold;
  background-color: #f0f0f0;
  border-bottom: 1px solid #ddd;
}

.investment-dialog-content {
  padding: 20px;
}

.investment-dialog-text {
  margin-bottom: 20px;
}

.investment-dialog-detail {
  color: #333;
}

.investment-dialog-actions {
  padding: 10px 20px;
  background-color: #f0f0f0;
  border-top: 1px solid #ddd;
}

.investment-dialog-button {
  font-weight: bold;
  border-radius: 5px;
}

.Payments_image {
  width: 250px;
}

// payment details Show
.Payment_image {
  width: 50px;
}

/* InvestmentStatus.css */
.investment-status-container {
  // padding: 20px;
  /* border: 1px solid #ddd; */
  /* border-radius: 8px; */
  /* background-color: #f9f9f9; */
  max-width: 400px;
  margin: 0 auto;
  text-align: center;
}

.investment-status-header {
  color: var(--LP-black, #3e3f42);
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}

.investment-status-img {
  width: 55%;
  max-width: 300px;
  border-radius: 8px;
  margin-bottom: 10px;
}

.investment-status-details {
  color: var(--LP-black, #3e3f42);
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}

.add-investment-container {
  margin: 20px;
  text-align: center;
}

.investment-button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 1.2rem;
  color: #fff;
  background-color: #007bff;
  border-radius: 8px;
  text-decoration: none;
}

.investment-button:hover {
  background-color: #0056b3;
}

// details

.AssetCard_investment {
  display: flex;
  flex-direction: column;
  text-align: justify;
  gap: 9px;
  padding: 20px;
}

// another card
.card-AssetCard {
  width: 300px;
  border-radius: 10px;
  padding: 6px;
  display: flex;
  font-family: Arial, sans-serif;
  flex-direction: column;
  gap: 9px;
}
.asset-heading {
  // margin-bottom: 20px;
  font-size: 12px;
  color: #8e8e8e;

  span {
    font-size: 24px;
    margin: 0;
  }
}

.Location_icon {
  width: 39px;
}

.Calendar_icon {
  width: 23px;
  margin-left: 4px;
}

.asset-card2 {
  .calender-section {
    margin-bottom: 14px;
  }
  .MuiDateCalendar-root {
    width: 280px;
    max-height: 270px;
    .MuiPickersDay-root {
      height: 32px;
    }
    .Mui-selected {
      background-color: #8f72c682 !important;
      border-radius: 30%;
      color: #7d55c7b5;
    }
    .MuiYearCalendar-root {
      width: 280px;
    }
    .MuiPickersCalendarHeader-root {
      display: flex;
      flex-direction: row-reverse;
      padding: 0px;
      justify-content: space-between;
      width: 100%;
      .MuiPickersCalendarHeader-label {
        color: #637381;
        text-align: center;

        /* Text/Regular 16px */
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: capitalize;
        margin: 0px 10px;
      }
    }
  }
  .addnote {
    justify-content: space-between;
    padding: 0px 20px;
    .left {
      width: 55%;
      display: flex;
      justify-content: space-between;
      height: 100%;
      align-items: center;
      border-right: 1px solid #eaedf3;
      svg {
        margin: 0px 15px;
      }
    }
    .right {
      border-top: 1px solid #eaedf3;
      // margin-top: 24px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;

      .addnote span {
        color: var(--LP-black, #3e3f42);
        text-align: center;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 157.143% */
      }
    }
  }
  .ruppes-section {
    padding-bottom: 20px;
    margin-bottom: 0px;
    border-bottom: 1px solid #eaedf3;
  }
  .button_wrapper {
    margin-top: 80px;
    // margin-bottom: 20px;
    &.request {
      p {
        color: #3e3f42;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 157.143% */
      }
      .buttons {
        display: flex;
        gap: 10px;
        .PrimaryButton {
          display: flex;
          width: 100%;
          height: 38px;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;
          margin-top: 10px;
        }
        .white_buttons {
          display: flex;
          width: 130px;
          height: 38px;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;
          background: none;
          color: #3e3f42;
          background-color: #ffff;
          border: 1px solid #d8dce6;
        }
      }
    }
  }
  hr {
    color: grey;
    margin-bottom: 10px;
  }
  .react-tabs {
    // padding: 0px 30px;

    .inputBoxMain {
      margin-top: 0px;
    }
    .react-tabs__tab-list {
      border-bottom: 1px solid #eaedf3;
      // padding: 0% 14%;
      padding-top: 10px;
      margin: 0 auto;
      display: flex;
      gap: 20px;
      li {
        display: flex;
        justify-content: center;
        color: var(--LP-mid-gray, #6b6c6f);
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 171.429% */
        text-transform: capitalize;
        display: flex;
        height: 36px;
        align-items: flex-start;
        min-width: 60px;
      }
      .active {
        color: #3e3f42 !important;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 171.429% */
        background: var(--ffffff-white, #fff);
        box-shadow: 0px -2px 0px 0px #7d55c7 inset;
      }
    }
    .react-tabs__tab-panel--selected {
      .calender_wrapper {
        // display: flex;
        .button_wrapper {
          margin-top: 40px;
        }
        .time_wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .left {
            color: var(--LP-black, #3e3f42);
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            span {
              color: var(--LP-black, #3e3f42);
              text-align: center;
              font-family: Roboto;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 22px; /* 157.143% */
            }
          }
        }
        .PrimaryButton {
          margin-top: 20px !important;
        }
      }
      // padding-right: 15%;
      // padding-left: 10%;
      // padding-bottom: 4.5%;
      // padding-top: 3%;'
      padding-bottom: 20px;
      display: flex;
      border-bottom: 1px solid #eaedf3;
      &.innerTabination {
        padding-left: 0%;
        padding-top: 2%;
        padding-right: 0px;
      }
      .left {
        .react-tabs {
          display: flex;
          padding: 0px;
          .react-tabs__tab-list {
            flex-direction: column;
            justify-content: flex-start;
            align-items: baseline;
            width: 220px;

            padding: 0px;
            margin: 0px;
            li {
              padding: 5px 16px;
              display: flex;
              align-items: center;
              justify-content: start;
            }
            .active {
              box-shadow: 3px 0px 0px 0px #7d55c7 inset;
            }
            border-bottom: none;
          }
          .react-tabs__tab-panel--selected {
            padding: 0px;
            padding-top: 1%;
            border-bottom: none;
          }
        }
        .right {
          width: 100%;
          display: flex;
          justify-content: space-between;
          gap: 105px;
          .column {
            :first-child {
              margin-top: 0px !important;
            }
            .row {
              width: 286px;
              margin-top: 20px;

              p {
                color: var(--LP-black, #3e3f42);
                font-family: Roboto;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px; /* 157.143% */
              }
              span {
                color: var(--LP-gray, #9ea0a5);
                text-align: right;
                font-family: Roboto;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px; /* 157.143% */
              }
              .subCat {
                display: flex;
                justify-content: space-between;
                .subCat-L {
                  display: flex;
                  align-items: center;
                  gap: 10px;
                }
              }
              .option {
                display: flex;
                justify-content: space-between;
                align-items: center;
                .MuiCheckbox-root {
                  padding: 5px;
                }
                .MuiFormControlLabel-root {
                  margin-left: 20px;
                }
                .MuiFormControlLabel-label {
                  color: var(--LP-black, #3e3f42);
                  font-family: Roboto;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 22px; /* 157.143% */
                }
                .option-L {
                  display: flex;
                  align-items: center;
                  gap: 10px;
                }
              }
            }
          }
        }
      }
      .right {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .column {
          .row {
            width: 307px;
          }
        }
      }
      .location {
        width: 100%;
      }
      .asset {
      }
    }
  }
  .asset-heading-AssetCard2 {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 22px;
    span {
      font-weight: 600;
    }
    p {
      color: #9ea0a5;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 157.143% */
    }
  }
}
.bell_icon {
  width: 25px;
}

.pdf-bell-container {
  position: relative;
  display: inline-block;
}

.pdf-bell-icon {
  cursor: pointer;
  width: 20px;
  height: 20px;
}

.pdf-dropdown-menu {
  position: absolute;
  top: 40px;
  right: -11px;
  background-color: #fff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: 200px;
  z-index: 1000;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.pdf-dropdown-item {
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  color: #333;
  font-weight: bold;
  background-color: #f5f5f5;
  transition: background-color 0.3s ease;
}

.pdf-dropdown-item:hover {
  background-color: #e0e0e0;
}

.pdf-no-file {
  color: #888;
  font-style: italic;
}

.drag-handle {
  cursor: move; /* Show the move cursor when hovering over this area */
}
