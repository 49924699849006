.Financialreporting-Container {
  display: flex;
  gap: 20px;
}
.Financialreporting-Container-box {
  border: 1px solid #eaedf3;
}
.Financialreporting-Container-box-data {
  margin: 10px;
  padding: 10px;
  justify-content: center;
  align-items: center;
}
.Financialreporting-Container-box-data button {
  margin: 10px;
  padding: 10px;
  justify-content: center;
  align-items: center;
}
.Expense {
  display: flex;
  gap: 20px;
}
.Financialreporting-container-image {
  display: flex;
  gap: 20px;
}
.Financialreporting-container-image-data {
  display: flex;
  gap: 20px;
}


// faq
/* FAQDropdown.css */
/* FAQDropdown.css */
.faq-dropdown {
  font-family: Arial, sans-serif;
}

.faq-item {
  margin-bottom: 0; /* Remove bottom margin */
  border: 1px solid #000; /* Add border instead of background color */
}

.faq-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}

.faq-header:hover {
  background-color: #e0e0e0; /* Background color on hover */
}

.question {
  flex: 1;
}

.icon {
  font-size: 18px;
}

.faq-answer {
  background-color: #ffffff;
  padding: 10px;
  width: 8 0%; /* Set a fixed width */
  max-width: 500px; /* Set a maximum width if needed */
  overflow-wrap: break-word; /* Ensure long content wraps within the width */
}

.Financialreporting-container-image {
  display: flex;
  gap: 20px;
}
.Financialreporting-container-image-data {
  display: flex;
  gap: 20px;
}
@media screen and (max-width: 900px) {
  .Financialreporting-container-image-data {
    flex-direction: column;
  }
}
.Financialreporting-Data {
  border: 1px solid #eaedf3;
  padding: 10px;
  width: 419px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.15);
}





.Financialreporting-Data p {
  width: 50%;
}
.Financialreporting-Filter {
  padding: 0;
  margin: 0;
  height: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  border: none;
  color: black;
  border-radius: 4px;
  background: rgba(125, 85, 199, 0.07);
}
.Financialreporting-Filter-Upgrade {
  width: 150px;
  float: right;
  padding: 0;
  margin: 0;
  height: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  border: none;
  color: var(--Primary-Purple-500, #7d55c7);
  border-radius: 4px;
  background: white;

  box-shadow: none;
  border: 1px solid var(--Primary-Purple-50, #eaedf3);
}
