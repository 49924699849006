.profile_settings {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  max-width: 1600px !important;

  @media screen and (max-width: 1500px) {
    display: flex;
    gap: 20px;
    justify-content: start;
  }

  @media screen and (max-width: 1300px) {
    flex-direction: column;
    width: 550px;
  }
  @media screen and (max-width: 700px) {
    flex-direction: column;
    width: 350px;
  }
  @media screen and (max-width: 500px) {
    flex-direction: column;
    width: 250px;
  }

  @media screen and (min-width: 1301px) {
    .user_short_details {
      display: none;
    }
  }

  .soon {
    color: var(--c-4-c-4-c-4-mid-grey-3, #c4c4c4);
    text-align: right;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    margin-right: 20px;
  }

  .settings_left {
    border-radius: 4px;
    border: 1px solid #eaedf3;
    background: #fff;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.04);
    padding-top: 20px;
    width: 21%;

    // @media screen and (max-width: 1500px) {
    //   width: 260px !important;
    // }

    // @media screen and (max-width: 1300px) {
    //   // width: 225px !important;
    // }

    @media screen and (max-width: 1300px) {
      width: max-content !important;
      display: flex;
      flex-direction: column;
      // width: 100% !important;
      border: none;

      .profile_setting_tabination {
        display: flex;
        align-items: center;
        gap: 0px !important;

        div {
          //   margin-left: 10px !important;
          width: max-content !important;
          //   padding: 0px !important;
          padding: 5px !important;
        }
      }
    }

    .settings_left_upper {
      border-bottom: 1px solid #eaedf3;
      position: relative;

      .img_div {
        width: 130px !important;
        height: 130px !important;
        overflow: hidden !important;
        border-radius: 50% !important;
        padding: 0 !important;
        // background-color: #38393ca4;
        border: 1px solid rgba(168, 161, 161, 0.678);
        margin: auto;
        @media screen and (max-width: 1300px) {
          display: none !important;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .file_upload_btn {
        position: absolute;
        top: 30%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: none;
      }

      .upload_btn {
        width: 100%;
        cursor: pointer;
      }

      &:hover {
        .file_upload_btn {
          display: block;
        }
      }

      @media screen and (max-width: 1300px) {
        border: none !important;
      }

      @media screen and (max-width: 1300px) {
        display: flex;
        flex-direction: column;

        .setting_user_img {
          display: none !important;

          .file_upload_btn {
            display: none;
          }
        }

        .upload_btn {
          display: none;
        }
      }

      @media screen and (max-width: 1200px) {
        display: flex;
        flex-direction: column;
        align-items: start;
        width: 260px !important;

        .setting_user_img {
          width: 50% !important;
          margin: 0 !important;
          padding: 20px;
        }
      }

      div {
        padding: 10px;

        @media screen and (max-width: 1200px) {
          padding: 20px !important;
        }
      }

      p {
        color: #3e3f42;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
        font-size: 20px;
        font-weight: 500;
        line-height: 32px;

        @media screen and (max-width: 1300px) {
          display: none !important;
        }
      }

      span {
        color: #9ea0a5;
        font-family: Roboto;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;

        @media screen and (max-width: 1300px) {
          display: none !important;
        }
      }
    }

    .settings_left_lower {
      padding-top: 20px;

      > span {
        color: #38393c;
        font-family: Roboto;
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        text-transform: uppercase;
        padding: 30px;
        margin-bottom: 20px;

        @media screen and (max-width: 1300px) {
          padding: 0px !important;
        }
      }

      > div {
        margin-top: 20px;
      }

      .title_menu {
        color: #6b6c6f;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
      }

      .left_menu_title {
        color: #6b6c6f;
        font-family: Roboto;
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
      }

      .title_menu_active {
        color: #ffffff !important;
      }

      .soon_active {
        margin-right: 0px !important;
      }

      .profile_menu_active {
        background-color: #a386d7;
        width: 89%;
        margin: auto;
        border-radius: 4px;
        color: #ffffff !important;
        padding: 13px;
      }

      .int {
        background-color: #9ea0a5 !important;
        width: 89%;
        margin: auto;
        border-radius: 4px;
        color: #ffffff !important;
        padding: 13px;
      }
    }
  }

  .settings_right {
    width: 100%;

    @media screen and (max-width: 1300px) {
      // width: 600px !important;
    }
  }

  .lower {
    padding: 30px 20px;

    span {
      color: #9ea0a5;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.basic_header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 1px solid #eaedf3;
  padding: 20px;

  > div {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  p {
    color: #3e3f42;
    font-family: Roboto;
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
  }

  span {
    color: #9ea0a5;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
  }
}

.genral-input {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  //   div {
  //     width: 320px;
  //     // margin: 0 auto;
  //   }
}

.userFrame {
  width: 49%;
  height: 100%;
  border-radius: 50%;
  background-color: purple;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;

  p {
    color: white;
  }
}

// Loader

.upload_btnnew {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(25, 118, 210);
  color: rgb(255, 255, 255);
  padding: 4px 25px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  opacity: 1;
  width: 100%;
  margin-top: 15px;
}

.upload_btnnew:hover {
  background-color: #115293;
}

.upload_btnnew.loading {
  background-color: #e0e0e0;
  cursor: not-allowed;
}

.spinner-ProfileSettings {
  border: 3px solid rgb(206, 206, 206);
  border-radius: 100%;
  border-top: 3px solid rgb(40, 4, 109);
  width: 5px;
  height: 5px;
  animation: spin 1s linear infinite;
  margin-right: 10px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
