.colleagueInvitationContainer {
  .MuiPaper-root {
    .invitationHeader {
      padding: 23px 30px;
      height: 75px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      button {
        padding: 0;
        position: static;
      }
    }

    .invitationContent {
      padding: 26px 30px;

      .invitationSelectorBox {
        margin: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 7px;
        border-bottom: 1px solid #eaedf3;
        padding-bottom: 8px;
        h6 {
          color: #9ea0a5;
          font-family: Roboto;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 18px; /* 150% */
          text-transform: uppercase;
        }

        .invitationSelector {
          .avatarPic {
            width: 38px;
            height: 38px;
            border-radius: 4px;
          }

          p {
            color: #3e3f42;
            font-family: Roboto;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px; /* 150% */
          }
        }

        .MuiList-root {
          padding: 0;

          .MuiListItem-root {
            padding: 0;

            .MuiListItemAvatar-root {
              padding: 0;
              margin: 0;
            }

            .MuiListItemText-root {
              margin: 0;
              padding: 0;
            }
          }
        }
      }

      .addPeopleSelector {
        display: flex;
        gap: 10px;
      }

      .addPeopleSection {
        margin-top: 13px;
        margin-bottom: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 7px;
        h6 {
          color: #9ea0a5;
          font-family: Roboto;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 18px; /* 150% */
          text-transform: uppercase;
        }

        .selectorBox {
          display: flex;
          width: 100%;
          border-radius: 4px;
          border: 1px solid #e2e5ed;
          background: #fff;
          //   box-shadow: 0px 1px 2px 0px rgba(102, 113, 123, 0.21) inset;
        }

        .addPeopleInput {
          border-right: 1px solid #e2e5ed;
          fieldset {
            border: 0;
            outline: 0;
          }
          margin-right: 0;

          label {
            color: #9ea0a5;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px; /* 157.143% */
          }
          .MuiInputBase-root {
            color: #000000;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            height: 38px;
          }
        }
        .permissionSelector {
          height: 38px;
          margin-right: 0px;
          fieldset {
            border: 0;
            outline: 0;
          }
        }

        button {
          width: 100%;
          max-width: 66px;
          height: 38px;
          color: #fff;
          text-align: center;
          font-family: Roboto;
          font-size: 14px !important;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          border-radius: 4px;
          background: #7d55c7;
          box-shadow: 0px 1px 1px 0px rgba(19, 31, 21, 0.1), 0px 2px 0px 0px rgba(255, 255, 255, 0.06) inset;
          text-transform: capitalize !important;
        }
      }

      .inviteCaption {
        color: #9ea0a5;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 150% */
        width: 100%;
      }
    }

    .inviteeSection {
      width: 100%;
      .MuiList-root {
        padding: 0;
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 16px;

        .employeeDetailBox {
          padding: 0;

          .MuiListItemAvatar-root {
            margin-top: 0;
          }
          .employeeDetail {
            margin: 0;

            span {
              > div {
                h6 {
                  color: #3e3f42;
                  font-family: Roboto;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 22px; /* 157.143% */
                }
                p {
                  color: var(--LP-mid-gray, #6b6c6f);
                  font-family: Roboto;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 22px; /* 157.143% */
                }
              }
            }

            p {
              .MuiBox-root {
                .MuiBox-root {
                  display: flex;
                  flex-direction: column;
                  gap: 10px;
                  width: 100%;
                  p {
                    color: #9ea0a5;
                    font-family: Roboto;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 100%; /* 12px */
                  }

                  .employeeTags {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 5px;
                    .MuiChip-root {
                      width: fit-content;
                      border-radius: 4px;
                      border: 1px solid #d8dce6;
                      background: linear-gradient(0deg, #f6f7f9 0%, #fff 100%);
                      box-shadow: 0px 2px 0px 0px rgba(255, 255, 255, 0.05) inset, 0px 1px 1px 0px rgba(22, 29, 37, 0.05);

                      span {
                        color: #3e3f42;
                        text-align: center;
                        font-family: Roboto;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 22px; 
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .MuiDialogActions-root {
      padding: 23px 30px;
      margin: 0;
      margin: 0;

      button {
        color: #3e3f42;
        text-align: center;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; 
        display: flex;
        padding: 8px 11px;
        align-items: center;
        border-radius: 4px;
        border: 1px solid #d8dce6;
        background: linear-gradient(0deg, #f6f7f9 0%, #fff 100%);
        box-shadow: 0px 2px 0px 0px rgba(255, 255, 255, 0.05) inset, 0px 1px 1px 0px rgba(22, 29, 37, 0.05);
      }
    }
  }


}
