.recipient_type {
  position: relative;
  display: flex;
  align-items: center;
  padding-right: 10px;
  gap: 10px;
  cursor: pointer;

  .rotate {
    transform: rotateX(180deg);
    transition: 0.5s;
  }

  .recipient_modal {
    background-color: white;
    box-shadow: 0 0 0.03125rem 0 rgba(0, 0, 0, 0.37), 0 0.25rem 0.5rem 0 rgba(0, 0, 0, 0.04), 0 1.5rem 2rem 0 rgba(0, 0, 0, 0.02), 0 1rem 2.5rem 0 rgba(0, 0, 0, 0.04);
    width: 300px;
    border-radius: 20px;
    position: absolute;
    top: 40px;

    .input {
      input {
        padding: 10px;
        width: 300px;
        border: none;
        outline: none;
        border-bottom: 1px solid rgb(228, 220, 220);
      }
    }

    .values {
      display: flex;
      flex-direction: column;
      gap: 15px;

      .values_item {
        padding: 10px;
        display: flex;
        flex-direction: column;
        align-items: start;
        padding-left: 15px;
        &:hover {
          background-color: #e6e2e2;
        }

        span{
          color: rgb(83, 83, 83);
          font-weight: 500;
          font-size: 12px;
          cursor: pointer;
        }
      }

      .reset {
        padding: 10px 10px 20px 10px;
        p {
          color: #5f58ff;
          font-size: 14px;
          cursor: pointer;
        }

        button {
          background-color: #5f58ff;
          color: #ffff;
          font-family: Roboto;
          padding: 5px;
          border: none;
          outline: none;
          border-radius: 0.375rem;
          cursor: pointer;
        }
      }

      div {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:hover {
          border: none;
          outline: none;
        }
        p {
          color: rgb(59, 54, 54);
          font-weight: 500;
          font-size: 14px;
          cursor: pointer;
        }
      }
    }
    &:hover {
      outline: none;
    }
  }
}
