.blue_pie_chart {
  display: grid !important;
  grid-template-columns: 50% auto !important;
  align-items: center;
  .chart-container {
    flex: 1;
  }

  .legend-container {
    display: flex;
    flex-direction: column;
    margin-left: 20px;

    .legend-item {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      .color-box {
        width: 20px;
        height: 20px;
        margin-right: 10px;
        border-radius: 3px;
      }

      .legend-text {
        display: flex;
        flex-direction: column;

        .value {
          font-size: 18px;
          font-weight: bold;
        }

        .label {
          font-size: 14px;
          color: #6b6b6b;
        }
      }
    }
  }
}
