.Version-conatiner {
  margin-top: 50px;
  // @media screen and (max-width: 900px) {
  //   margin-left: -83px;
  //   margin-top: 25px;
  // }

  .deals-room-center-header {
    max-width: 76vw;
  }
}
.versions_header_Version {
  display: flex;
  align-items: center;
  padding: 40px 0px;
  justify-content: space-between;
  @media screen and (max-width: 900px) {
    flex-direction: column;
    display: flex;
    align-items: start;
    padding: 40px 0px;
    justify-content: space-between;
  }
  .right {
    display: flex;
    gap: 20px;
    align-items: center;
    @media screen and (max-width: 900px) {
      flex-direction: column;
      display: flex;
      align-items: start;
      padding: 40px 0px;
      justify-content: space-between;
    }
  }
  .right p {
    display: flex;
    align-items: center;
    gap: 5px;
    color: #3e3f42;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 28px;
  }
  .PrimaryButton {
    display: flex;
    width: 104px;
    height: 38px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    background-color: #fff;
    background: #fff;
    color: #3e3f42;
    border: 1px solid #d8dce6;
    margin-top: 0px;
  }
}

.versions_container {
  @media screen and (max-width: 900px) {
    flex-direction: column;
    margin: auto;
  }
}
