
.openai-chat-page {
  display: flex;
  height: 100vh;
  font-family: Arial, sans-serif;
  color: #333;
  background-color: #f9f9fc;
}


.openai-sidebar {
  width: 250px;
  background-color: #e6e8ec;
  padding: 20px;
  border-right: 1px solid #ccc;
  position: fixed;
  top: 75px;
  left: 0;
  bottom: 0;
  overflow-y: auto;
}


.openai-new-chat-button {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  background-color: #8a4de8; 
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
}

.openai-new-chat-button:hover {
  background-color: #6f3bc5;
}


.openai-sidebar-section h3 {
  font-size: 14px;
  color: #333;
  margin: 0 0 10px 0;
  font-weight: 700;
  text-transform: none;
}


.openai-sidebar-item {
  font-size: 14px;
  color: #7a7a7a;
  padding: 5px 0;
  margin: 5px 0;
  cursor: pointer;
  position: relative;
}


.openai-sidebar-item:hover {
  color: #333;
}


.openai-sidebar-divider {
  border: none;
  border-top: 1px solid #ccc;
  margin: 15px 0;
}

/* Chat Content */
.openai-chat-content {
  flex-grow: 1;
  margin-left: 250px;
  display: flex;
  flex-direction: column;
  background-image: url("../../assets/Openai/Chatbackground.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed; 
  position: relative;
  padding: 20px;
  height: 100vh;
  overflow: hidden;
}

/* Chat Messages */
.openai-chat-messages {
  flex-grow: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-right: 10px; 
}


.openai-message {
  display: flex;
  align-items: end;
}

/* Icon styling */
.openai-user-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.openai-received {
  flex-direction: row;
}

.openai-received .openai-user-icon {
  margin-right: 10px; 
}

.openai-received .openai-message-bubble {
  background-color: #f0f0f5;
  color: #333;
  border-radius: 30px 30px 30px 0px;
}


.openai-sent {
  flex-direction: row-reverse;
}

.openai-sent .openai-user-icon {
  margin-left: 10px; 
}

.openai-sent .openai-message-bubble {
  background-color: #6a5acd;
  color: #fff;
  border-radius: 30px 30px 0px 30px;
}


.openai-message-bubble {
  padding: 15px 20px;
  border-radius: 0px 10px 0px 0px;
  max-width: 60%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  position: relative;
}


.openai-chat-input {
  position: fixed;
  bottom: 20px;
  left: 55%;
  transform: translateX(-50%);
  width: 80%;
  max-width: 600px;
  padding: 10px 20px;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
}

.attachment-btn,
.send-btn {
  color: #fff;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.attachment-btn {
  margin-right: 10px;
}

.send-btn {
  margin-left: 10px;
  background: none;
}

.openai-chat-input input {
  flex-grow: 1;
  padding: 10px 15px;
  border: none;
  outline: none;
  border-radius: 20px;
  font-size: 14px;
  color: #333;
}

.openai-chat-input input::placeholder {
  color: #aaa;
}

.attachment-btn img,
.send-btn img {
  width: 25px;
  height: 25px;
}
