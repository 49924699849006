.disclaimer {
  margin-left: 20px;
  padding-bottom: 30px;
}

.disclaimer_header {
  margin-bottom: 20px;

  h2 {
    color: #3e3f42;
    font-family: Roboto;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    text-transform: capitalize;
  }

  button {
    padding: 16px 22px;
    outline: none;
  }

  .disclaimer_header_right {
    gap: 20px;
  }

  .btn_left {
    border-radius: 4px;
    border: 1px solid #d8dce6;
    background: linear-gradient(0deg, #f6f7f9 0%, #fff 100%);
    box-shadow: 0px 1px 1px 0px rgba(22, 29, 37, 0.05), 0px 2px 0px 0px rgba(255, 255, 255, 0.05) inset;
  }

  .btn_right {
    background-color: #7d55c7b5;
    border: none;
    border-radius: 4px;
    color: #ffffff;
  }
}

.disclaimer_container {
  padding: 0 30px;
  display: flex;
  gap: 30px;
  margin-top: 90px;
}

.disclaimer_center {
  width: 730px;
  min-height: 524px;
  padding: 0 !important;
  display: flex;
  flex-direction: column;
  gap: 20px;
  // background-color: rgba(15, 160, 227, 0.623);

  p {
    color: #6b6c6f;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
  }
}

.disclaimer_right {
  width: 350px;
  height: 524px;
  position: sticky !important;
  // top: 70px !important;
  // background-color: rgba(15, 227, 15, 0.623);
}
