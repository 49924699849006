.basic {
  border: 1px solid #eaedf3;
  border-radius: 8px;
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.04));

  .basic_header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border-bottom: 1px solid #eaedf3;
    padding: 20px;

    > div {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }

    p {
      color: #3e3f42;
      font-family: Roboto;
      font-size: 18px;
      font-weight: 500;
      line-height: 28px;
    }

    span {
      color: #9ea0a5;
      font-family: Roboto;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
    }
  }

  .inputs {
    color: #9ea0a5;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    padding: 20px;
    border-bottom: 1px solid #eaedf3;

    .input_box {
      border-bottom: 1px solid #eaedf3;
      padding-bottom: 20px;
    }

    span {
      color: var(--LP-black, #3e3f42);
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
      text-transform: uppercase;
      opacity: 0.5;
      margin-bottom: 7px;
    }

    .input_lower {
      margin-top: 20px;

      textarea {
        width: 100%;
        border: 1px solid #eaedf3;
        height: 71px;
        resize: none;
      }
    }
  }
  .dropDownOuterCon {
    margin-top: 0px !important;
  }
}

.start-syndicate-button-Basic {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  background-color: #684AB6;
  color: white;
  margin: 20px;
  width: 95%;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #A386D7;
  }

  &:disabled {
    background-color: #d3d3d3;
    cursor: not-allowed;
  }

  .spinner {
    border: 2px solid #f3f3f3;
    border-top: 2px solid #3498db;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
}


.TextArea_AllInput{
  width: 100%;
  height: 38px;
  border: 1px solid var(--LP-border, #e5e5e5);
  border-radius: 4px;
  padding: 8px 16px;
  outline: none;
  color: var(--LP-black, #3e3f42);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}