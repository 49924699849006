.custom-slider {
  /* display: flex; */
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  height: fit-content;
  cursor: pointer;
  border-radius: 9px;
}

#thumbnail-slider {
  position: absolute;
  bottom: 13px;
  width: 97%;
  background: rgba(0, 0, 0, 0.93);
  padding: 12px;
  border-radius: 0px;
  left: 10px;

  .splide__slide.is-active {
    border: 2px solid #7757c0;
  }
}

.thumbnail img {
  width: 100%;
  height: auto;
  border: 2px solid transparent;
  transition: border 0.3s ease, transform 0.3s ease;
}

.thumbnail.is-active img {
  border: 2px solid white;
  transform: scale(1.1);
}

.splide__slide img {
  border-radius: 5px;
}

.CustomSlider-thumb-nav {
  position: absolute;
  bottom: 29%;
  display: flex;
  gap: 10px;
  padding: 0 10px;
  right: 0;
}

.CustomSlider-thumb-nav button {
  font-size: 12px;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 5px;
  border: none;
  padding: 4px 8px;
  font-family: "Roboto";
  font-weight: 500;
  color: rgb(62, 63, 66);
  text-align: center;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  flex-shrink: 0;
}

.custom-slider {
  position: relative;
  box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.1);
  border: 1px solid #efefef;

  .upload-file {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: rgba(255, 255, 255, 0.6);
    border: none;
    padding: 8px 16px;
    font-family: "Roboto";
    font-weight: 500;
    color: rgb(62, 63, 66);
    cursor: pointer;
    z-index: 10;
  }

  .image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // background: rgba(0, 0, 0, 0.5);
    // box-shadow: -1px -1px 61px -10px #7757c0 inset !important;
    // -webkit-box-shadow: -1px -1px 61px -10px #7757c0 inset !important;
    // -moz-box-shadow: -1px -1px 61px -10px #7757c0 inset !important;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    border-radius: 5px;
    transition: opacity 0.3s ease;
    opacity: 1;
  }

  .splide__slide {
    position: relative;
  }
}

.no-image-placeholder {
  background-color: #007bff;
}

// image-upload

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup_imageupload .popup-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 500px;
  width: 100%;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.popup_imageupload .popup-content h2 {
  margin-bottom: 10px;
  font-size: 24px;
}

.popup_imageupload .popup-content .upload-label {
  display: block;
  font-size: 16px;
  margin-bottom: 10px;
}

.popup_imageupload .popup-content .upload-container-popup {
  border: 2px dashed #ccc;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
  position: relative;
}

.popup_imageupload .popup-content .upload-placeholder {
  cursor: pointer;
}

.popup_imageupload .popup-content .upload-placeholder .upload-icon-image-upload {
  width: 50px;
  margin-bottom: 10px;
}

.popup_imageupload .popup-content .upload-placeholder p {
  font-size: 14px;
  margin-bottom: 10px;
}

.popup_imageupload .popup-content .upload-placeholder small {
  font-size: 12px;
  color: #777;
}

.popup-imageupload .popup-content .image-previews {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
  justify-content: center;
}

.popup-imageupload .popup-content .image-preview {
  border: 2px solid #ccc;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
  transition: border-color 0.3s ease, transform 0.3s ease;
}

.popup-imageupload .popup-content .image-preview.selected {
  border-color: #007bff;
}

.popup-imageupload .popup-content .image-preview img {
  width: 70px;
  height: 70px;
  object-fit: cover;
  border-radius: 5px;
}

.popup-imageupload .popup-content .image-preview:hover {
  border-color: #0056b3;
  transform: scale(1.05);
}

.upload-file {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgba(255, 255, 255, 0.6);
  border: none;
  padding: 8px 16px;
  font-family: "Roboto";
  font-weight: 500;
  color: rgb(62, 63, 66);
  cursor: pointer;
  z-index: 10;
}

.upload-button {
  position: absolute;
  top: 15px;
  z-index: 1;
  border: 1px solid;
  padding: 13px;
  border-radius: 4px;
  left: 81%;
  color: white;
}

.upload-icon-button {
  width: 60px !important;
  height: 36px !important;
}

.onclickupload {
  position: relative;
  top: 8px;
  z-index: 11;
  color: white;
  font-size: 12px;
  border-radius: 5px;
  border: none;
  padding: 4px 8px;
  font-family: "Roboto";
  font-weight: 500;
  color: rgb(62, 63, 66);
  text-align: center;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  flex-shrink: 0;
  width: 204px;
  height: 45px;
  transition: all 0.3s ease-in-out;
}

.onclickupload:hover {
  background-color: #765ea8;
  color: rgb(255, 255, 255);
  transform: scale(1.05);
}

.upload_icon {
  width: 50px !important;
  height: 50px !important;
}

.no-image-placeholder {
  background-color: #007bff;
}

.image-preview-container {
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}

.image_preview img {
  height: 100% !important;
  width: 100% !important;
  object-fit: cover !important;
  border-radius: 5px !important;
}

.splide__arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 4px;
  color: white;
  font-size: 24px;
  padding: 10px;
  cursor: pointer;
  z-index: 1;
}

.splide__arrow--prev {
  left: 10px;
}

.splide__arrow--next {
  right: 10px;
}
