.general {
  margin-left: 20px;
  padding-bottom: 30px;
}

.general_header {
  margin-bottom: 20px;

  h2 {
    color: #3e3f42;
    font-family: Roboto;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    text-transform: capitalize;
  }

  button {
    padding: 16px 22px;
    outline: none;
  }

  .general_header_right {
    gap: 20px;
  }

  .btn_left {
    border-radius: 4px;
    border: 1px solid #d8dce6;
    background: linear-gradient(0deg, #f6f7f9 0%, #fff 100%);
    box-shadow: 0px 1px 1px 0px rgba(22, 29, 37, 0.05), 0px 2px 0px 0px rgba(255, 255, 255, 0.05) inset;
  }

  .btn_right {
    background-color: #7d55c7b5;
    border: none;
    border-radius: 4px;
    color: #ffffff;
  }
}

.general_container {
  padding: 0 20px;
  display: flex;
  gap: 30px;
  margin-top: 90px;
}

.general_center {
  width: 730px;
  min-height: 524px;
  padding: 0 !important;
  display: flex;
  flex-direction: column;
  gap: 20px;

  // background-color: rgba(15, 160, 227, 0.623);
  .general_upper {
    display: flex;
    flex-direction: column;
    gap: 0px;
    height: 379px;
    border: 1px solid #eaedf3;
    filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.04));
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.1);

    .bg {
      height: 308px;
      position: relative;

      .img_left {
        position: absolute;
        bottom: -40px;
        border-radius: 100%;
        left: 20px;
      }

      .bg_right {
        position: absolute;
        bottom: 15px;
        right: 20px;

        p {
          color: #fff;
          text-align: right;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          text-transform: uppercase;
        }
        .imgs {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 10px;
          margin-top: 10px;
          img {
            height: 38px;
            width: 38px;
            border-radius: 100%;
          }
        }
      }
    }

    .info {
      height: 71px;
      padding-left: 110px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;

      .btns {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;

        .btn_left {
          padding: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 4px;
          border: 1px solid #d8dce6;
          background: linear-gradient(0deg, #f6f7f9 0%, #fff 100%);
          box-shadow: 0px 1px 1px 0px rgba(22, 29, 37, 0.05), 0px 2px 0px 0px rgba(255, 255, 255, 0.05) inset;
        }

        .btn_right {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 10px;
          background-color: rgba(125, 85, 199, 0.7098039216);
          border: none;
          border-radius: 4px;
          color: #ffffff;
          padding: 12px 20px;
        }
      }
    }
  }

  .general_lower {
    z-index: -1;
  }
}

.general_nav {
  padding: 0 30px;
  max-width: 400px;

  .asset-nav {
    border: none !important;
    height: 69px !important;
    max-width: 400px;
  }

  .more_dropdown {
    border-radius: 4px;
    border: 1px solid #d8dce6;
    background: #fff;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.08);
    width: 180px;
    padding: 10px;
    position: absolute;
    top: 70px;

    p {
      color: #3e3f42;
      font-family: Roboto;
      font-size: 14px;
      font-weight: 400;
      line-height: 38px;
      text-transform: capitalize;
    }
  }
}

.general_right {
  width: 350px;
  height: 524px;
  position: sticky !important;
  // top: 70px !important;
  // background-color: rgba(15, 227, 15, 0.623);
}
