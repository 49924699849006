.Form-Container-header {
  display: flex;
  gap: 20px;
  justify-content: space-between;
}

@media screen and (max-width: 768px) {
  .Form-Container-header {
    flex-direction: column;
    align-items: stretch;
  }
}

.Form-Container-headerdata {
  padding: 20px;
  border: 1px solid #eaedf3;
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 80%;
}
.Form-Container-headerdatas p {
  color: var(--primary-gray-100-text, #9ea0a5);
  leading-trim: both;
  text-edge: cap;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  width: 70%;
}

.Form-Container-headerdatas {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.Form3921-button {
  display: flex;
  gap: 20px;
}

.Form3921-conatiner {
  border: 1px solid #eaedf3;
}
.Form3921-conatiner-data {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.Form3921-conatiner-data h1 {
  color: var(--primary-gray-100-text, #9ea0a5);
  leading-trim: both;
  text-edge: cap;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
  text-transform: uppercase;
}

.Form-rules-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 20px;
  margin-top: 20px;
}

.Form-rules-container-data {
  border: 1px solid var(--Primary-Purple-50, #eaedf3);
}
.Form-rules-container-second {
  border: 1px solid var(--Primary-Purple-50, #eaedf3);
}

.Form-rules-container-thid {
  border: 1px solid var(--Primary-Purple-50, #eaedf3);
}
.Form-rules-container-datafirst {
  padding: 20px;
  text-align: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
.Form-rules-container-secondbox {
  padding: 20px;
  text-align: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
.Form-rules-container-thiddata {
  padding: 20px;
  text-align: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
.Form3921-Filter {
  padding: 0;
  margin: 0;
  height: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  border: none;
  color: black;
  width: 150px;
  background-color: white;
  border-radius: 4px;
  border: 1px solid var(--Primary-Purple-50, #eaedf3);
  background: #fff;
}
.Form3921-Filter-data {
  padding: 0;
  margin: 0;
  height: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  border: none;
  color: white;
  width: 150px;
  border-radius: 4px;
  background: var(--Primary-Purple-500, #7d55c7);
}
.Form3921-formdata {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.Form3921-formdata-data {
  display: flex;
  gap: 20px;
}
.Form3921-color-text p {
  color: var(--primary-gray-100-text, #9ea0a5);
  leading-trim: both;
  text-edge: cap;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 200% */
}
// Form3921.scss

.Form-Container {
  // Your existing styles for Form Container
}

/// Form3921.scss

.Form-Container {
  // Your existing styles for Form Container
}

// Add the following styles for table alignment and spacing
.table-data {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px; // Add margin between header and body
}

.table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-basis: 100%;
  margin-bottom: 10px; // Add margin at the bottom
  color: #9ea0a5;
  leading-trim: both;
  text-edge: cap;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
  text-transform: uppercase;
  border: 1px solid #eaedf3;
}

.table-header th {
  padding: 8px 16px; // Adjust padding as needed
}

.table-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.table-data td {
  padding: 8px 16px; // Adjust padding as needed
  flex-basis: 33%; // Adjust width of columns
  margin-top: 10px; // Add margin at the top of each row
}
.download-button {
  padding: 0;
  margin: 0;
  height: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  border: none;
  color: black;
  background-color: white;
  border-radius: 4px;
  width: 150px;
  border: 1px solid var(--Primary-Purple-50, #eaedf3);
  background: #fff;
}

.Taxrules-image-footer {
  width: 109px;
  height: 109px;
  flex-shrink: 0;
  margin: auto;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 100%;
  border-radius: 218px;
  background: rgba(125, 85, 199, 0.12);
}
.Form-rules-container-datafirst span {
  color: var(--Primary-Gray-200, #9ea0a5);
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 157.143% */
}

.Form-rules-container-secondbox span {
  color: var(--Primary-Gray-200, #9ea0a5);
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 157.143% */
}

