.search-results-container {
  z-index: 100;
  position: absolute;
  top: 100%;
  margin-top: 4px;
  border-radius: 8px;
  background: linear-gradient(180deg, rgb(56, 56, 56) -7%, rgb(255, 255, 255) 2%);
  width: 100%;
  box-shadow: 0px 8px 15px 0px rgba(0, 0, 0, 0.2);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease, box-shadow 0.3s ease;
  max-height: 400px; 
  overflow-y: auto;
}

.search-results-container.open {
  opacity: 1;
  visibility: visible;
  right: 0;
}

.search-results-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

.search-results-header h2 {
  margin: 0;
}

.SearchResults-close-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.search-results-content {
  padding: 1rem;
}

.search-results-content ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.search-result-item {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  padding: 1rem;
  border-bottom: 1px solid #ddd;
  transition: background 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.search-result-item:hover {
  background: #f0f0f0;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.result-image img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 8px;
  border: 1px solid #ddd;
}

.result-details {
  flex: 1;
  overflow: hidden;
}

.result-details p {
  margin: 0.5rem 0;
  font-size: 0.9rem;
}

.result-details strong {
  font-weight: bold;
}

