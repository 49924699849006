.porfolio_performence_main {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 20px;
  .porfolio_performence_one {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(3, 1fr);
    @media screen and (max-width: 1100px ) {
    grid-template-columns: 100%;
    }
  }
  .porfolio_performence_heading {
    margin-bottom: 10px;
    h2 {
      color: #3e3f42;
      font-family: Roboto;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 30px; /* 150% */
    }
  }
  .investment_summary {
    display: flex;
    flex-direction: column;
    gap: 20px;
    @media screen and (max-width: 1100px ) {
    flex-direction: row;
    }
  }

  .investment_summary_one {
    display: flex;
    flex-direction: column;
    gap: 4px;
    p {
      color: #9ea0a5;
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px; /* 150% */
      text-transform: uppercase;
    }
    h3 {
      color: var(--LP-black, #3e3f42);

      /* h4/Regular 20px */
      font-family: Roboto;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  .investment_summary_two {
    display: flex;
    flex-direction: column;
    gap: 4px;
    p {
      color: #9ea0a5;
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px; /* 150% */
      text-transform: uppercase;
    }
    h3 {
      color: var(--LP-black, #3e3f42);

      /* h4/Regular 20px */
      font-family: Roboto;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  .porfolio_performence_two {
    display: grid;
    gap: 10px;
    // width: 100%;
    border: 1px solid #eaedf3;
    margin: 20px;
    padding: 20px;
    border-radius: 5px;
    grid-template-columns: 25% 75%;
    @media screen and (max-width: 1100px ) {
      grid-template-columns: 100%;
      }
      .investment_summary{
        @media screen and (max-width: 1100px ) {
          flex-direction: column;
          } 
      }
    .investment_summary_header {
      h3 {
        color: #3e3f42;
        font-family: Roboto;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px; /* 155.556% */
        text-wrap: nowrap;
      }
    }
  }
  .porfolio_performence_three {
    display: grid;
    gap: 10px;
    // width: 100%;
    border: 1px solid #eaedf3;
    margin: 20px;
    padding: 20px;
    border-radius: 5px;
    grid-template-columns: 25% 75%;
    @media screen and (max-width: 1100px ) {
      grid-template-columns: 100%;
      }
      .investment_summary{
        @media screen and (max-width: 1100px ) {
          flex-direction: column;
          } 
      }
    .investment_summary_header {
      h3 {
        color: #3e3f42;
        font-family: Roboto;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px; /* 155.556% */
        text-wrap: nowrap;
      }
    }
  }

  // Table One
  .porfolio_performence_tableOne {
    border: 1px solid #eaedf3;

    .porfolio_performence_tableOne_status {
      background-color: #34aa44;

      padding: 5px 10px;
      border-radius: 5px;

      color: #fff !important;
      text-align: center !important;
      font-family: Roboto;
      font-size: 12px !important;
      font-style: normal;
      font-weight: 500;
      line-height: 18px; /* 150% */
      text-transform: uppercase;
    }

    h3 {
      color: #3e3f42;
      font-family: Roboto;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px; /* 155.556% */
      text-wrap: nowrap;
      border-bottom: 1px solid #eaedf3;
      padding: 20px 10px;
    }
    table {
      td,
      th {
        padding: 10px !important;
        border-bottom: 1px solid #eaedf3;
        padding: 20px 0px;
      }
      th {
        text-wrap: wrap !important;
      }
      .porfolio_performence_tableOne_Exit_Date {
        color: #7f8083 !important;
        font-family: Roboto;
        font-size: 12px !important;
        font-style: normal;
        font-weight: 500;
        line-height: 18px; /* 150% */
        text-transform: uppercase;
      }
    }
  }
  .porfolio_performence_tableOne_investment {
    display: flex !important;
    gap: 20px !important;
    width: 100% !important;
    .PortfolioPerformence_investment_inner {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      h3 {
        border: none !important;
        padding: 0px !important;
      }
      p {
        padding: 0px !important;
      }
    }
  }
  .porfolio_performence_table_lower {
    display: flex;
    gap: 10px;
    @media screen and (max-width: 1100px ) {
    flex-direction: column;
    }
  }

  // Table Two

  .porfolio_performence_tableTwo {
    border: 1px solid #eaedf3;

    h3 {
      color: #3e3f42;
      font-family: Roboto;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px; /* 155.556% */
      text-wrap: nowrap;
      border-bottom: 1px solid #eaedf3;
      padding: 20px 10px;
    }
    table {
      td,
      th {
        padding: 10px !important;
        border-bottom: 1px solid #eaedf3;
        padding: 20px 0px;
      }
      th {
        text-wrap: wrap !important;
      }
      .porfolio_performence_tableTwo_Exit_Date {
        color: #7f8083 !important;
        font-family: Roboto;
        font-size: 12px !important;
        font-style: normal;
        font-weight: 500;
        line-height: 18px; /* 150% */
        text-transform: uppercase;
      }
    }
  }
  .porfolio_performence_tableTwo_investment {
    display: flex !important;
    gap: 20px !important;
    width: 100% !important;
    .PortfolioPerformence_investment_inner {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      h3 {
        border: none !important;
        padding: 0px !important;
      }
      p {
        padding: 0px !important;
      }
    }
  }

  // Table Three
  .porfolio_performence_tableThree {
    border: 1px solid #eaedf3;
    h3 {
      color: #3e3f42;
      font-family: Roboto;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px; /* 155.556% */
      text-wrap: nowrap;
      border-bottom: 1px solid #eaedf3;
      padding: 20px 10px;
    }

    .porfolio_performence_tableThree_status {
      background-color: #9ea0a5;

      padding: 5px 10px;
      border-radius: 5px;

      color: #fff !important;
      text-align: center !important;
      font-family: Roboto;
      font-size: 12px !important;
      font-style: normal;
      font-weight: 500;
      line-height: 18px; /* 150% */
      text-transform: uppercase;
    }

    table {
      td,
      th {
        padding: 10px !important;
        border-bottom: 1px solid #eaedf3;
        padding: 20px 0px;
      }
      th {
        text-wrap: wrap !important;
      }
      .porfolio_performence_tableThree_Exit_Date {
        color: #7f8083 !important;
        font-family: Roboto;
        font-size: 12px !important;
        font-style: normal;
        font-weight: 500;
        line-height: 18px; /* 150% */
        text-transform: uppercase;
      }
    }
  }
  .porfolio_performence_tableThree_investment {
    display: flex !important;
    gap: 20px !important;
    width: 100% !important;
    .PortfolioPerformence_investment_inner {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      h3 {
        border: none !important;
        padding: 0px !important;
      }
      p {
        padding: 0px !important;
      }
    }
  }
}
