.Conatiner-Securities {
  border: 1px solid #eaedf3;
  width: 75%;
}
.Conatiner-Securities-data {
  display: flex;
  margin: 5px;
  padding: 5px;
  justify-content: space-between;
}

@media screen and (max-width: 768px) {
  .Conatiner-Securities-data {
    flex-direction: column;
    align-items: start;
  }
  .Securitiestemplates-Conatiner{
    flex-direction: column;
    width: fit-content;
  }
  .Conatiner-Securities{
    width: 250px;
  }
  .Conatiner-Securities-header{
    flex-direction: column;
    gap: 30px;

    width: fit-content;
  }
  .Conatiner-Securities-header h1{
    flex-direction: column;
    gap: 30px;
  }
}

.Conatiner-Securities-header {
  display: flex;
  gap: 550px;
}
.Conatiner-Securities-data p {
  color: var(--primary-gray-100-text, #9ea0a5);
  leading-trim: both;
  text-edge: cap;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
  text-transform: uppercase;
}
.Securitiestemplates-Conatiner {
  display: flex;
  justify-content: space-between;
}
.Securitiestemplates-main-conatiner {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.Conatiner-Securities-header h1 {
  color: var(--Black, #3e3f42);
  leading-trim: both;
  text-edge: cap;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 128.571% */
  text-transform: uppercase;
}
