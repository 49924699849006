.SignupPricing {
  display: flex;
  flex-direction: column;
  align-items: center;
  //   justify-content: center;
  padding-bottom: 120px;
  min-height: 80vh;
  background-color: #fbfbfd;
  padding-top: 69px;
  .checkCon {
    svg {
      rect:nth-child(2) {
        display: none !important;
      }
    }
  }
  .bottomFooter {
    width: 100%;
    background-color: #ffffff;
    // background-color: red;
    position: fixed;
    bottom: -1px;
    padding: 0 30px;
    padding-top: 23px;
    padding-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.50);
    

    .SignupQuestionsNew-flexlineC {
      display: flex;
      align-items: center;
      gap: 15px;
      box-shadow: #3e3f42;
    }
    p {
      color: var(--LP-gray, #9ea0a5);
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 157.143% */
    }
  }
  h1 {
    color: var(--purple-pantone-2665-c, #7d55c7);
    font-family: Roboto Slab;
    font-size: 40px;
    font-style: normal;
    font-weight: 300;
    line-height: 58px; /* 145% */
    width: 100%;
  }
  .pricingContainer {
    .pricingContainerB {
      display: flex;
      gap: 30px;
      margin-top: 50px;
    }
    .pricingCard {
      width: 290px;
      height: 600px;
      flex-shrink: 0;
      border-radius: 4px;
      border: 1px solid #e2e5ed;
      background: #fff;
      padding: 45px 30px;
      position: relative;
      cursor: pointer;
      &:hover {
        .priceSelector {
          border: 1px solid var(--lp-pantone-71, rgba(125, 85, 199, 0.71));
        }
      }
      &.active {
        border-radius: 4px;
        border: 1px solid var(--purple-pantone-2665-c-71, rgba(125, 85, 199, 0.71));
        background: linear-gradient(0deg, rgba(125, 85, 199, 0.05) 0%, rgba(125, 85, 199, 0.05) 100%), #fff;

        .priceSelector {
          border-radius: 4px;
          border: 1px solid var(--purple-pantone-2665-c-71, rgba(125, 85, 199, 0.71));
          background: rgba(125, 85, 199, 0.1);
          color: var(--LP-black--_lib, #3e3f42);
        }
        .pricingCardSVGHead {
          path {
            // fill: var(--light-grad, linear-gradient(0deg, #F6F7F9 0%, #FFF 100%));
            stroke-width: 1px;
            stroke: var(--lp-pantone-71, rgba(125, 85, 199, 0.71));
          }
        }
      }
      &.disabled {
        background: var(--LP-very-light--_lib, #eaedf3);
        pointer-events: none;
        .checkCon {
          svg {
            rect {
              fill: var(--LP-very-light--_lib, #eaedf3);
            }
          }
        }
        .priceSelector {
          border: 1px solid #e2e5ed;
          color: var(--LP-grey-_lib, #9ea0a5);
          background: rgba(224, 229, 239, 0.5);
        }
        h1,
        h2,
        h3 {
          color: var(--Disable-bckg, #7b7b84);
        }
      }

      .pricingCardSVGHead {
        position: absolute;
        top: -22px;
        left: 50%;
        transform: translateX(-50%);
      }

      h2 {
        color: var(--LP-black--_lib, #3e3f42);
        text-align: center;
        font-family: Roboto;
        font-size: 24px;
        font-style: normal;
        font-weight: 300;
        line-height: 24px; /* 100% */
        margin-bottom: 20px;
      }
      h3 {
        width: 100%;
        // text-align: left;
        color: var(--LP-black--_lib, #3e3f42);
        text-align: center;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 157.143% */
      }
      .spacerLine {
        background: var(--LP-very-light, #eaedf3);
        width: 100%;
        height: 1px;
        margin-top: 15px;
        margin-bottom: 30px;
      }
      .pricingCardBtm {
        position: absolute;
        bottom: 45px;
      }
      .priceSelector {
        border-radius: 4px;
        border: 1px solid #e2e5ed;
        background: #fff;

        padding: 35px 0;
        color: var(--LP-mid-grey-_lib, #6b6c6f);
        text-align: center;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 137.5% */
        position: relative;
        width: 290px;
        .checkCon {
          position: absolute;
          bottom: 10px;
          right: 10px;
        }
      }
      .flCon {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
      .fl {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 10px;
        margin-bottom: 11px;
        max-width: 260px;
        svg {
          width: 30px;
        }
        h3 {
          text-align: left;
          width: 100%;
        }
        &:first-child {
          margin-top: 31px;
        }
      }
    }
  }
}
