.documents_profile_container {
  width: 825px;

  flex-shrink: 0;
  fill: #fff;
  stroke-width: 1px;
  stroke: #eaedf3;
  box-shadow: 0px 1px 3px rgba(15, 15, 15, 0.137);

  .header {
    width: 825px;
    height: 74px;
    flex-shrink: 0;
    background: rgba(255, 255, 255, 0);
    box-shadow: 0px 0px 0px 1px #eaedf3 inset;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px 0px 20px;

    .left_header {
      display: flex;
      align-items: center;
      gap: 40px;
      justify-content: space-between;

      div {
        display: flex;
        align-items: center;
        gap: 6px;
        cursor: pointer;
      }
      .all {
        border-right: 1px solid #c9cfda;
        padding-right: 20px;
        p {
          color: #6b6c6f;
          font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 28px; /* 155.556% */
        }
        span {
          color: #9ea0a5;
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px; /* 157.143% */
        }
      }
      .inbox {
        div {
          background-color: #ed962d;
          height: 22px;
          width: 22px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          text-align: center;
          font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 18px; /* 150% */
          border-radius: 50%;
        }
        p {
          color: #3e3f42;
          font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 28px; /* 155.556% */
        }
        span {
          color: #9ea0a5;
          font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px; /* 157.143% */
        }
      }
      .sent {
        p {
          color: #6b6c6f;
          font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 28px; /* 155.556% */
        }
        span {
          color: #9ea0a5;
          font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px; /* 157.143% */
        }
      }
      .drafts {
        p {
          color: #6b6c6f;
          font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 28px; /* 155.556% */
        }
        span {
          color: #9ea0a5;
          font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px; /* 157.143% */
        }
      }
    }
    .right_header {
      display: flex;
      align-items: center;
      gap: 20px;
      .search {
      }
      .filter {
        img {
          cursor: pointer;
        }
      }
      .add_docs {
        img {
          cursor: pointer;
        }
      }
    }
  }
  .sub_header {
    width: 823px;
    height: 43px;
    flex-shrink: 0;
    background: #fff;
    box-shadow: 0px 1px 0px 0px #eaedf3;
    display: flex;
    align-items: center;
    gap: 90px;
    padding: 0px 20px 0px 20px;

    div {
      p {
        color: #9ea0a5;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%; /* 14.4px */
        text-transform: uppercase;
      }
    }

    .doc {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }
  .documents_details {
    .document {
      display: flex;
      align-items: center;
      justify-content: space-around;
      gap: 50px;
      width: 800px;
      height: 70px;
      flex-shrink: 0;
      background: rgba(255, 255, 255, 0);
      box-shadow: 0px -1px 0px 0px #eaedf3 inset;
      padding-left: 10px;
      .pdf_file {
        display: flex;
        align-items: center;
        gap: 15px;

        p {
          color: #3e3f42;
          font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px; /* 157.143% */
        }
      }
      .date {
        p {
          color: #9ea0a5;
          font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px; /* 150% */
          display: flex;
          align-items: center;
          gap: 5px;
        }
        span {
          color: #3e3f42;
          font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px; /* 157.143% */
        }
      }
      .more {
        img {
          cursor: pointer;
        }
      }
    }
    .pagination {
      width: 825px;
      height: 80px;
      display: flex;
      align-items: center;
      flex-shrink: 0;
      padding-left: 20px;

      .paginate_buttons {
        box-shadow: 0px 0px 0px 1px #dbdee4 inset;
        display: flex;
        align-items: center;
        gap: 10px;
        border-radius: 5px;
      }

      button {
        width: 39px;
        height: 38px;
        flex-shrink: 0;
        background: rgba(255, 255, 255, 0);
        border: none;
        outline: none;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 0px 1px #dbdee4 inset;
        cursor: pointer;
      }
    }
  }
}
