.saveditems {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  justify-content: center;
  flex-wrap: wrap;
  @media screen and (max-width: 900px) {
    width: 700px;
  }
  @media screen and (max-width: 800px) {
    width: 600px;
  }
  @media screen and (max-width: 700px) {
    width: 500px;
  }
  @media screen and (max-width: 600px) {
    width: 400px;
  }
  @media screen and (max-width: 500px) {
    width: 300px;
  }
  @media screen and (max-width: 400px) {
    width: 200px;
  }
  .savedbox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    border: 1px solid #eaedf3;
    background: #fff;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.04);
    width: 350px;
    height: 309px;
    padding: 20px;
    border-radius: 4px;

    h2 {
      color: #3e3f42;
      text-align: center;
      font-family: Roboto;
      font-size: 18px;
      font-weight: 500;
      line-height: 28px;
    }

    p {
      color: #6b6c6f;
      text-align: center;
      font-family: Roboto;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
    }

    span {
      color: var(--purple-pantone-2665-c, #7d55c7);
      text-align: center;
      font-family: Roboto;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }
  .img {
    background: #f6f3fb;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    width: 100px;
    border-radius: 100%;
    .img {
      height: 48px;
      width: 48px;
    }
  }
}
