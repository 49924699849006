.main_table {
  width: 100%;
  overflow: hidden;
  .table_container {
    width: 100%;
    overflow-x: scroll;
    .table {
      width: 100%;
      overflow: auto;
      border-collapse: collapse;
      border-spacing: 0 10px;
      margin-top: 0 !important;
      .table_head {
        width: 100%;
        .head_row {
          width: 100%;
          border-radius: 8px;
          height: 49px;
          border-radius: 4px 4px 0px 0px;
          background: #fff;
          box-shadow: 0px 1px 0px 0px #a5a5a5;
          border-bottom: 1px solid #eaedf3;
          th {
            color: #9ea0a5;
            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px; /* 150% */
            text-transform: uppercase;
            text-wrap: nowrap;
            text-align: left;

            &:first-child {
              padding-left: 20px;
            }
            &:last-child {
              padding-right: 20px;
            }
            span {
              font-size: 12px;
            }
          }
        }
      }
      .table_tbody {
        width: 100%;
        .tbody_row {
          height: 90px;
          border-radius: 4px 4px 0px 0px;
          background: #fff;
          box-shadow: 0px 1px 0px 0px #a5a5a5;
          border-bottom: 1px solid #eaedf3;
          td {
            color: var(--LP-black, #3e3f42);
            text-align: right;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px; /* 157.143% */
            letter-spacing: 0.12px;
            text-align: left;
            &:first-child {
              padding-left: 20px;
            }
            &:last-child {
              padding-right: 20px;
            }
            .entity-wrapper{
              @media screen and (max-width: 1100px ) {

              width: 300px;
              }

            }
            div {
              color: var(--LP-black, #3e3f42);
              text-align: right;
              font-family: Roboto;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 22px; /* 157.143% */
              letter-spacing: 0.12px;
              @media screen and (max-width: 1100px ) {
              text-wrap: nowrap;
              }
            }
          }
        }
      }
    }
  }

  .pagination_container {
    display: flex;
    justify-content: center;
    margin-top: 19px;
    .MuiPagination-root.MuiPagination-text {
      .MuiPagination-ul {
        height: 42px;
        width: fit-content;
        border-radius: 42px;

        li {
          span {
            color: var(--White, #000);
            text-align: center;
            font-family: "Noto Sans";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
          .MuiPaginationItem-circular {
            color: var(--White, #000);
            text-align: center;
            font-family: "Noto Sans";
          }
          .Mui-selected {
          }
        }
      }
    }
  }
}

.total_row {
  height: 90px;
  border-radius: 4px 4px 0px 0px;
  background: #fff;
  box-shadow: 0px 1px 0px 0px #a5a5a5;
  td {
    color: var(--LP-black, #3e3f42);
    text-align: right;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px; /* 157.143% */
    letter-spacing: 0.12px;
    text-align: left;
    &:first-child {
      padding-left: 20px;
    }
    &:last-child {
      padding-right: 20px;
    }
    div {
      color: var(--LP-black, #3e3f42);
      text-align: right;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 157.143% */
      letter-spacing: 0.12px;
    }
  }
}
