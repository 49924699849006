.dashboard_main {
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin-left: 10px;

  .db_one {
    display: flex;
    flex-direction: column;
    gap: 25px;
  }

  .db_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 30px;
    border-bottom: 1px solid #eaedf3;
    background: #fff;
    box-shadow: 0px 1px 0px 0px #eaedf3, 0px 1px 3px 0px rgba(0, 0, 0, 0.05);
    @media screen and (max-width: 1000px) {
      flex-direction: column;
    }
    .db_header_box {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: center;
      justify-content: center;

      p {
        color: #9ea0a5;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        /* 150% */
        text-transform: uppercase;
      }

      h3 {
        color: #3e3f42;
        font-family: Roboto;
        font-size: 26px;
        font-style: normal;
        font-weight: 400;
        line-height: 38px;
        /* 146.154% */
      }
    }
  }

  .db_charts_wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: calc(100% - 60px);
    margin: auto;
    @media screen and (max-width: 1100px) {
      grid-template-columns: 100%;
    }
    > div {
      padding: 23px 30px;
      border: 1px solid #eaedf3;
      filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.04));
    }

    h2 {
      color: #3e3f42;
      font-family: Roboto;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
      /* 155.556% */
    }

    .chart {
      width: 100%;
    }
  }

  .db_table {
    width: calc(100% - 60px);
    margin: auto;
    @media screen and (max-width: 1100px) {
      max-width: calc(100vw - 270px);
      overflow-x: auto;
    }
    .upper {
      height: 74px;
      width: 100%;
      display: flex;
      align-items: center;
      gap: 10px;
      justify-content: space-between;
      @media screen and (max-width: 1100px) {
        gap: 40px;
        h2 {
          text-wrap: nowrap;
        }
      }
    }

    .upper_right {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 15px;

      button {
        background: linear-gradient(#f6f7f9, #ffffff);
        outline: none;
        border-radius: 4px;
        border: 1px solid #d8dce6;
        display: flex;
        justify-content: center;
        gap: 10px;
        align-items: center;
        width: 94px;
        height: 38px;
        flex-shrink: 0;
        color: #3e3f42;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
      }

      .search_bar {
        position: relative;
        width: 240px;
        height: 38px;
        border: 2px solid #eaedf3;
        border-radius: 5px;
        display: flex;
        align-items: center;
        padding: 0px 10px;

        input {
          all: unset;
          width: 220px;
          padding-right: 25px;
          padding-left: 2px;
          color: #9ea0a5;
          font-family: Roboto;
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
        }

        img {
          position: absolute;
          right: 5px;
        }
      }
    }
  }
}
