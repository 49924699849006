.budget {
    display: flex;
    // justify-content: center;
    gap: 30px;
    flex-wrap: wrap;
    .budget_box {
        height: 493px;
        width: 350px;
        border-radius: 4px;
        padding: 16px;
        border: 1px solid #EAEDF3;
        filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.04));
        display: flex;
        flex-direction: column;
        gap: 27px;
        .budget_box_header {
            h3 {
                color: var(--LP-black, #3E3F42);
                font-family: Roboto;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
            }

            span {
                color: #9EA0A5;
                font-family: Roboto;
                font-size: 12px;
                font-weight: 400;
                line-height: 16px
            }
        }

        .text {
            color: #9EA0A5;
            font-family: Roboto;
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
        }

        .details {
            display: flex;
            justify-content: space-between;
            width: 100%;
            align-items: center;

            p {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 12px;
                color: #3E3F42;
                font-family: Roboto;
                font-size: 14px;
                font-weight: 400;
                line-height: 22px;
            }

            .right {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: flex-end;
            }

            span {
                color: #9EA0A5;

                font-family: Roboto;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px
            }

            .left {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 12px;

                img {
                    height: 38px;
                    width: 38px;
                    border-radius: 100%;
                }
            }
        }

        .budget_box_lower {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 20px;

            button {
                height: 38px;
                width: 138px;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 6px;
                font-family: Roboto;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px
            }

            .btn_right {
                outline: none;
                border: 1px solid #eaedf3;
                background: linear-gradient(#F6F7F9, #FFFFFF);
            }

            .btn_left {
                background-color: #7D55C7;
                border-radius: 4px;
                outline: none;
                border: none;
                color: #ffffff;
            }
        }

    }

    .property_card-T {
        display: flex;
        justify-content: center;
        gap: 12px;

        .box {
            display: flex;
            width: 34px;
            height: 32px;
            padding: 6px 7px;
            flex-direction: column;
            align-items: center;
            gap: 4px;
            color: var(--LP-mid-grey-_lib, #6b6c6f);
            text-align: center;
            font-family: Roboto;
            font-size: 14px;
            font-weight: 400;
            border-radius: 4px;
            border: 1px solid var(--LP-very-light, #EAEDF3);
            background: rgba(234, 237, 243, 0.20);

            span {
                color: var(--LP-black--_lib, #3e3f42);
                text-align: center;
                font-family: Roboto;
                font-size: 10px;
                font-style: normal;
                font-weight: 500;
                line-height: 18px;
                /* 128.571% */
            }
        }

        .text {
            display: flex;
            flex-direction: column;

            p {
                display: flex;
                align-items: center;
                gap: 4px;
            }
        }

        .photo_wrapper {
            max-width: 110px;
            display: flex;
            flex-direction: column;


            margin-bottom: 10px;
        }

        .text_wrapper {
            max-height: 100px;

            h4 {
                color: #3E3F42;
                font-family: Roboto;
                font-size: 16px;
                font-weight: 500;
                line-height: 28px;
                padding: 10px 10px 10px 0;
            }

            h3 {
                color: var(--LP-black, #3e3f42);
                font-family: Roboto;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
                /* 133.333% */
                margin-bottom: 6px;
                text-wrap: nowrap;
            }

            p {
                color: #9ea0a5;
                font-family: Roboto;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
                /* 133.333% */
            }
        }
    }

}