.integration {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .integration_upper {
        border: 1px solid #EAEDF3;
        filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.04));

    }

    .header_lower {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        padding: 20px;
        border-bottom: 1px solid #EAEDF3;
        p{
        color: #6B6C6F;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        }
    }

    .integration_middle {
        border: 1px solid #EAEDF3;
        filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.04));
        height: 534px;
        padding: 0 0 20px 0;

        .integration_middle_header {
            border-bottom: 1px solid #EAEDF3;
            display: flex;
            justify-content: space-between;
            align-items:baseline;
            padding-right: 20px;

        }

        .search_bar {
            margin-top: -5px;
            position: relative;
            width: 120px;
            height: 38px;
            border: 2px solid #eaedf3;
            border-radius: 5px;
            display: flex;
            align-items: center;

            input {
                all: unset;
                width: 100px;
                padding-right: 25px;
                padding-left: 2px;
                color: #9EA0A5;
                font-family: Roboto;
                font-size: 14px;
                font-weight: 400;
                line-height: 22px
            }

            svg {
                position: absolute;
                right: 5px;
            }
        }
    }

    .integration_middle_lower {
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 30px;

        >div {
            display: flex;
            justify-content: space-between;
            max-width: 80%;
        }
    }


    .integration_nav {
        height: 95px;
        padding: 0 20px;

        .asset-nav {
            height: 75px !important;
            border: none !important;
            max-width: 360px;

            span {
                color: #6B6C6F;
                font-family: Roboto;
                font-size: 18px !important;
                font-weight: 400;
                line-height: 28px;
                display: flex;
                align-items: center;
                justify-content: center;
                gap:10px;
                @media screen and (max-width:900px) {
                    font-size: 14px !important;
                }

                p {
                    color: #9EA0A5;
                    font-family: Roboto;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 22px;
                }
            }

            .ut-nav-active {
                color: #3E3F42 !important;
                font-weight: 500;

            }
        }
    }

    .integration_lower {
        display: flex;
        flex-direction: column;
        border: 1px solid #EAEDF3;
        filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.04));

        .inputs {
            padding: 20px
        }

        span {
            color: var(--LP-black, #3e3f42);
            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px;
            text-transform: uppercase;
            opacity: 0.5;
            margin-bottom: 7px;
        }

        .submit_btn {
            background-color: #a386d7;
            border-radius: 4px;
            color: #ffffff !important;
            width: 145px;
            height: 38px;
            outline: none;
            border: none;
            font-size: 14px;
            margin-top: 20px;
        }

        textarea {
            width: 100%;
            border: 1px solid #eaedf3;
            height: 71px;
            resize: none;
        }
    }
}