.portfolio-container {
  font-family: Roboto;
  display: grid;
  justify-items: end;
  grid-template-columns: 100%;
  min-height: 100vh;
  background-color: white;

  // @media screen and (max-width: 960px) {
  //   grid-template-columns: 35%, 1fr;
  // }

  .sidebar {
    border-right: 1px solid #ccc;
    @media screen and (max-width: 700px) {
      width: 80px !important;
    }
    @media screen and (max-width: 1200px) {
      width: 281px !important;
    }
    @media screen and (max-width: 1000px) {
      width: 257px !important;
    }

    .sidebar-header {
      position: sticky;
      top: 76px;
      background-color: #fbfbfd;
      z-index: 10;
      display: flex;
      align-items: center;
      text-decoration: none;
      gap: 20px;
      border-bottom: 1px solid #eaedf3;
      padding: 0 20px;
      height: 73px;

      img {
        height: 48px;
        border-radius: 4px;
        width: 48px;
      }

      .sideheader-textWrapper {
        display: flex;
        flex-direction: column;
        row-gap: 5px;

        h2 {
          font-size: 16px;
          color: #3e3f42;
          font-weight: 500;
          @media screen and (max-width: 1000px) {
            font-size: 14px;
          }
        }

        h4 {
          text-transform: uppercase;
          font-weight: 500;
          font-size: 12px;
          color: #959595;
        }
      }

      &:focus,
      &:active {
        h2 {
          color: #7757c0;
        }

        background-color: #f8f6fb;
        border-left: 4px solid #7757c0;
      }
    }

    .search-input-wrapper {
      padding: 6px 10px;
      border-bottom: 1px solid #eaedf3;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-top: 69px;
      width: 100%;
      @media screen and (max-width: 1000px) {
        padding: 6px 5px;
      }
      @media screen and (max-width: 900px) {
        margin-top: 0px;
      }

      input {
        outline: none;
        border: none;
        height: 38px;
      }

      ::placeholder {
        font-family: Roboto;
        font-weight: 400;
        font-size: 14px;
        color: #9ea0a5;
      }

      .search-icons {
        display: flex;
        column-gap: 20px;
        align-items: center;
        @media screen and (max-width: 1200px) {
          gap: 10px;
        }

        img {
          min-width: 20px;
          min-height: 20px;
          cursor: pointer;
        }
      }
    }

    // Left Slider

    .sidebar-content {
      position: sticky;
      top: 155px;
      overflow-y: auto;
      max-height: calc(100vh - 142px);
      padding-bottom: 10px;
      display: flex;
      flex-direction: column;
      width: 100%;
      // @media screen and (max-width: 700px) {
      //   width: 80px !important;
      // }
      // @media screen and (max-width: 1200px) {
      //   width: 280px !important;
      // }
      // @media screen and (max-width: 1000px) {
      //   width: 277px !important;
      // }

      .nav-card {
        display: flex;
        flex-direction: row;
        // justify-content: space-evenly;
        column-gap: 20px;
        // padding: 20px 25px;
        padding: 20px;
        border-bottom: 1px solid #eaedf3;

        @media screen and (max-width: 500px) {
          .nav-card {
            width: 50px;
          }
        }

        @media screen and (max-width: 1200px) {
          flex-direction: column;
          gap: 10px;
        }

        img {
          width: 100%;
          height: 98px;
          border-radius: 5px;
          max-width: 100px;
        }

        .nav-card-info {
          font-family: Roboto;
          display: flex;
          flex-direction: column;
          // justify-content: space-around;
          h2 {
            font-size: 14px;
            font-weight: 500;
            color: #3e3f42;
            @media screen and (max-width: 1000px) {
              font-size: 14px;
            }
          }

          p {
            color: var(--primary-gray-100-text, #9ea0a5);
            leading-trim: both;
            text-edge: cap;
            font-family: Roboto;
            font-size: 10px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px;
          }

          h4 {
            color: var(--Black, #3e3f42);
            leading-trim: both;
            text-edge: cap;
            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px; /* 157.143% */
          }

          .price-wrapper {
            // display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            // width: 230px;

            @media screen and (max-width: 900px) {
              .price-wrapper {
                flex-direction: column;
              }
            }
            @media screen and (max-width: 400px) {
              .price-wrapper {
                width: 150px;
              }
            }
            .status-wrapper {
              display: flex;
              align-items: center;
              column-gap: 10px;
              p {
                width: 6px;
                height: 6px;
                border-radius: 100%;
              }

              h4 {
                color: var(--LP-black, #3e3f42);
                font-family: Roboto;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px; /* 150% */
              }
            }

            h2 {
              font-size: 14px;
              font-weight: 500;
              color: #3e3f42;
              margin-top: 10px;
              display: flex;
              @media screen and (max-width: 1000px) {
                font-size: 15px;
              }
            }
          }
        }

        &:focus,
        &:active {
          h2 {
            color: #7757c0;
          }

          background-color: #f8f6fb;
          border-left: 4px solid #7757c0;
        }

        &.selected {
          h2 {
            color: #7757c0;
          }

          background-color: #f8f6fb;
          border-left: 4px solid #7757c0;
        }
      }
    }
  }

  .main-container {
    .main-header {
      position: sticky;
      top: 71px;
      background-color: #fbfbfd;
      z-index: 5;
      display: flex;
      // justify-content: flex-end;
      align-items: center;
      border-bottom: 1px solid #dadada;
      padding: 0 20px;
      height: 74px;
    }

    .main-content {
      overflow-y: auto;

      .dashboard-container {
        background-color: #fbfbfd;
        padding: 20px;
        display: flex;
        flex-wrap: wrap;
        gap: 20px;

        .portfolioDatanew-card {
          width: calc(33.33% - 20px);
          padding: 10px;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          gap: 20px;

          .All-assect-images {
            width: 100%;
            height: 40%;
            max-height: 250px;
            border-radius: 10px;
          }

          .card-info {
            margin-top: 10px;
            font-family: Roboto;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            row-gap: 10px;
            gap: 10px;
            height: 100%;

            h2 {
              font-size: 16px;
              font-weight: 500;
              color: #3e3f42;
            }

            p {
              font-size: 14px;
              color: #9ea0a5;
              line-height: 20px;
            }

            .price-wrapper {
              display: flex;
              justify-content: space-between;
              h2 {
                color: #3e3f42;
                font-size: 18px;
              }

              p {
                font-size: 14px;
              }
            }

            .asset-link-btn {
              // width: 100%;
              font-size: 14px;
            }

            .upperSection,
            .lowerSection {
              display: flex;
              flex-direction: column;
              gap: 5px;
            }
          }
        }
      }

      .property-details-container {
        min-height: 100vh;
        overflow-x: hidden;
        .details-header-container {
          height: 48px;
          background: #fff;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 20px;

          @media screen and (max-width: 1000px) {
            height: 50px;
          }
          @media screen and (max-width: 900px) {
            padding: 0 0px !important;
            margin: 0px 25px !important;
          }

          .details-header-left {
            display: flex;
            align-items: center;
            gap: 20px;

            Link {
              align-items: center;
            }

            a {
              color: inherit;
              text-decoration: none;
              display: flex;
              flex-direction: row;
              align-items: center;
              column-gap: 10px;

              img {
                width: 20px;
                height: 20px;
              }

              span {
                font-family: Roboto;
                font-weight: 500;
                font-size: 12px;
                color: #6b6c6f;
                text-transform: uppercase;
              }

              &.active {
                color: #3e3f42;

                span {
                  color: #3e3f42;
                }
              }
            }

            a:not(:first-child) {
              border-left: 1px solid #eaedf3;
              /* Adjust the color and size as needed */
              padding-left: 10px;
              height: 40px;
            }
          }

          .details-header-right {
            display: flex;
            align-items: center;
            gap: 20px;

            h2 {
              color: #9ea0a5;
              font-family: Roboto;
              font-weight: 500;
              font-size: 12px;
            }
            .Greendot {
              height: 10px;
              width: 10px;
              background-color: green;
              border-radius: 50%;
              display: inline-block;
              margin-right: 5px; /* Adjust as needed */
            }

            .status-input-wrapper {
              display: flex;
              flex-direction: row;
              align-items: center;
              gap: 10px;

              p {
                width: 10px;
                height: 10px;
                border-radius: 100%;
              }

              @media screen and (max-width: 900px) {
                font-size: 14px !important;
              }
            }
          }
        }

        .property-details {
          display: grid;
          grid-template-columns: 66% 1fr;
          padding: 0px 20px;
          column-gap: 1rem;

          @media screen and (max-width: 1000px) {
            grid-template-columns: repeat(1, 1fr);
            padding: 0px 1rem !important;
          }

          .property-main-container {
            img {
              height: 460px;
              width: 100%;
              object-fit: cover;
              border-radius: 5px;
            }

            .temp {
              height: 430px;
              width: 100%;
              display: flex;
              justify-content: center;
            }

            .property-tabs {
              margin-top: 14px;
              display: flex;
              flex-direction: row;
              column-gap: 20px;
              border-bottom: 1px solid #eaedf3;

              a {
                font-family: Roboto;
                color: #6b6c6f;
                font-weight: 400;
                font-size: 12px;
                padding-bottom: 14px;

                &.active {
                  border-bottom: 2px solid #7d55c7;
                  transition: all 0.3;
                }
              }
            }

            .tab-content {
              padding: 30px 0px;

              .property-details-card {
                display: grid;
                grid-template-columns: 100%;
                border: 1px solid #eaedf3;
                border-radius: 5px;
                width: 100%;
                padding: 30px;
                box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.1);
                @media screen and (max-width: 1400px) {
                  grid-template-columns: repeat(1, 1fr);
                  gap: 20px;
                }

                .detail-card-left-container {
                  display: flex;
                  flex-direction: column;
                  justify-content: space-evenly;
                  row-gap: 27px;

                  .details_with_button {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    button {
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      width: 128px;
                      height: 38px;
                      padding: 10px;
                      border: none;
                      border-radius: 4px;
                      background-color: white; /* Blue background color */

                      font-size: 1rem; /* Adjust font size */
                      font-weight: bold; /* Bold text */
                      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Soft shadow */
                      cursor: pointer; /* Pointer cursor on hover */
                      transition: background-color 0.3s ease, transform 0.3s ease; /* Smooth transitions */
                    }

                    button:hover {
                      background: var(--Primary-Purple-500, #7d55c7);
                      color: white;
                      transform: translateY(-1px); /* Slight lift on hover */
                    }

                    button:active {
                      background-color: #003f7f; /* Even darker blue on active/click */
                      transform: translateY(0); /* Reset transform on active/click */
                    }

                    button:focus {
                      outline: none; /* Remove default outline */
                      box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5); /* Blue outline on focus */
                    }
                  }

                  @media screen and (max-width: 1000px) {
                    row-gap: 5px !important;
                  }

                  h4 {
                    text-transform: uppercase;
                    font-family: Roboto;
                    font-weight: 500;
                    font-size: 12px;
                    color: #9ea0a5;
                    line-height: 18px;
                  }

                  h1 {
                    font-family: Roboto;
                    font-weight: 400;
                    font-size: 28px;
                    color: #3e3f42;
                    line-height: 38px;

                    @media screen and (max-width: 1000px) {
                      font-size: 24px !important;
                    }
                  }

                  h2 {
                    font-family: Roboto;
                    font-weight: 400;
                    font-size: 14px;
                    color: #9ea0a5;
                    line-height: 22px;
                  }

                  p {
                    font-family: Roboto;
                    font-weight: 400;
                    font-size: 14px;
                    color: #6b6c6f;
                    max-width: 85%;
                    line-height: 22px;

                    @media screen and (max-width: 1000px) {
                      font-size: 13px !important;
                    }
                  }
                }

                .detail-card-right-container {
                  display: flex;
                  flex-direction: column;
                  // justify-content: space-evenly;
                  align-items: flex-end;
                  row-gap: 14px;

                  @media screen and (max-width: 1000px) {
                    row-gap: 2px;
                    .eco_pricing {
                      margin-top: 10px;
                    }
                  }

                  h4 {
                    display: flex;
                    flex-direction: row;
                    column-gap: 5px;
                    align-items: center;
                    text-transform: uppercase;
                    font-family: Roboto;
                    font-weight: 500;
                    font-size: 12px;
                    color: #9ea0a5;
                    line-height: 18px;

                    .info-icon {
                      width: 17px;
                      height: 17px;
                      margin-bottom: -2px;
                    }
                  }

                  h1 {
                    display: flex;
                    flex-direction: row;
                    column-gap: 10px;
                    align-items: last baseline;
                    font-family: Roboto;
                    font-weight: 500;
                    font-size: 20px;
                    color: #3e3f42;
                    line-height: 38px;
                    flex-wrap: wrap;
                    @media screen and (max-width: 1000px) {
                      font-size: 16px !important;
                    }

                    span {
                      font-family: Roboto;
                      font-size: 14px;
                      line-height: 18px;
                      font-weight: 400;
                      color: #6b6c6f;
                    }
                  }

                  h2 {
                    display: flex;
                    flex-direction: row;
                    column-gap: 5px;
                    align-items: center;
                    text-transform: uppercase;
                    font-family: Roboto;
                    font-weight: 500;
                    font-size: 12px;
                    color: #9ea0a5;
                    line-height: 18px;

                    .info-icon {
                      width: 17px;
                      height: 17px;
                      margin-bottom: -2px;
                    }
                  }

                  .economic-pricing-text {
                    // align-items: center;
                    span {
                      display: flex;
                      column-gap: 5px;
                      align-items: baseline;
                    }

                    p {
                      color: #009639;
                      font-size: 14px;
                      font-weight: 400;
                    }

                    img {
                      width: 20px;
                      height: 20px;
                      margin-bottom: -10px;
                    }
                  }
                }
              }
            }
          }

          .property-features-container {
            .property-features {
              border: 1px solid #eaedf3;
              border-radius: 5px;
              padding: 35px 30px;
              display: flex;
              flex-direction: column;
              row-gap: 25px;
              box-shadow: 5px 5px 9px rgba(0, 0, 0, 0);
              -webkit-box-shadow: 5px 5px 9px rgba(0, 0, 0, 0);
              -moz-box-shadow: 5px 5px 9px rgba(0, 0, 0, 0);

              @media screen and (max-width: 1000px) {
                .show_more_btn_portfolio,
                .view_floor_btn_portfolio,
                .edit_asset_btn_portfolio {
                  width: 300px;
                  margin: auto;
                }
              }
            }

            h2 {
              text-transform: uppercase;
              font-family: Roboto;
              font-weight: 500;
              font-size: 12px;
              color: #9ea0a5;
            }

            .details-wrapper {
              display: flex;
              flex-direction: column;
              row-gap: 15px;

              .detail-item {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                .item-type {
                  display: flex;
                  align-items: center;
                  column-gap: 8px;

                  img {
                    width: 20px;
                    height: 20px;
                  }

                  h4 {
                    font-size: 14px;
                    font-weight: 400;
                    color: #6b6c6f;
                  }
                }

                p {
                  font-weight: 400;
                  font-size: 14px;
                  color: #3e3f42;
                }
              }
            }

            .line {
              background-color: #eaedf3;
              height: 0px;
              border: solid 1px #eaedf3;
              width: 100%;
            }

            .PrimaryButton.white-bg.noMargin.secondary {
              @media only screen and (max-width: 1000px) {
                width: 100%;
                max-width: 300px;
                margin: 0 auto;
              }
            }
          }
        }

        .overview-container {
          padding: 0px 20px;
          width: 100%;
          @media screen and (max-width: 1000px) {
            grid-template-columns: repeat(1, 1fr);
            padding: 0px 3rem !important;
          }

          .overview-header {
            font-family: Roboto;
            color: #3e3f42;
            font-weight: 500;
            font-size: 18px;
            line-height: 28px;

            @media screen and (max-width: 1000px) {
              margin-top: 15px;
            }
          }

          .overview-tabs {
            margin-top: 14px;
            display: flex;
            flex-direction: row;
            column-gap: 20px;
            border-bottom: 1px solid #eaedf3;
            @media screen and (max-width: 900px) {
              // width: 90% !important;
              column-gap: 17px !important;
            }

            a {
              font-family: Roboto;
              color: #6b6c6f;
              font-weight: 400;
              font-size: 12px;
              padding-bottom: 14px;

              &.active {
                border-bottom: 2px solid #7d55c7;
                transition: all 0.3;
              }
            }
          }
        }

        .market-tab-container {
          padding: 0px 2rem;

          @media screen and (max-width: 1000px) {
            grid-template-columns: repeat(1, 1fr);
            padding: 0px 3rem !important;
          }

          .market-header {
            font-family: Roboto;
            color: #3e3f42;
            font-weight: 500;
            font-size: 18px;
            line-height: 28px;
          }

          .market-tabs {
            margin-top: 14px;
            display: flex;
            flex-direction: row;
            column-gap: 20px;
            border-bottom: 1px solid #eaedf3;

            a {
              font-family: Roboto;
              color: #6b6c6f;
              font-weight: 400;
              font-size: 12px;
              padding-bottom: 14px;

              &.active {
                border-bottom: 2px solid #7d55c7;
                transition: all 0.3;
              }
            }
          }

          .market-tab-content {
            padding: 30px 0px;

            .market-s-main-wrapper {
            }
          }
        }
      }
    }
  }
}

.financial-kpi-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px 10px;
  border: 1px solid #eaedf3;
  row-gap: 5px;
  position: relative;
  border-radius: 5px;
  h2 {
    font-family: Roboto;
    color: #9ea0a5;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 10px;
  }

  p {
    font-family: Roboto;
    color: #3e3f42;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
  }

  h1 {
    font-family: Roboto;
    color: #3e3f42;
    font-weight: 500;
    font-size: 26px;
    line-height: 38px;
  }

  .lineChart_box {
    .echarts-for-react {
      width: 100% !important;
      > div {
        width: 100% !important;

        canvas {
          width: 100% !important;
        }
      }
    }
  }

  .benchmark {
    display: flex;
    column-gap: 10px;
    align-items: center;

    .info-icon {
      width: 20px;
      height: 20px;
    }

    p {
      font-family: Roboto;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #34aa44;
    }
  }

  .rental-graph {
    width: 100%;
    height: 120px;
    object-fit: fill;
  }

  .kpi-owner-details-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .kpi-owner-details {
      padding-top: 10px;
      display: flex;
      flex-direction: column;
      row-gap: 8px;

      .owner-status-wrapper {
        display: flex;
        align-items: center;
        column-gap: 6px;
        padding-left: 5px;

        p {
          width: 6px;
          height: 6px;
          border-radius: 100%;
        }

        h4 {
          font-family: Roboto;
          font-size: 12px;
          font-weight: 500;
          color: #9ea0a5;
          text-transform: uppercase;
        }
      }
    }

    .owner-info-icon {
      width: 20px;
      height: 20px;
      fill: #9ea0a5;

      &.active {
        fill: #7d55c7b5;
      }
    }
  }

  .finance-card-info {
    position: absolute;
    top: 20px;
    right: 10px;
  }
}

.operating-income-cards {
  display: grid;
  grid-template-columns: 60% 0.8fr;
  grid-gap: 40px;
  margin-top: 33px;
  .income-expense-left-grid {
    flex: 1;
    padding: 0px 20px;
    row-gap: 40px;
    justify-content: center;

    .asset-benchmark-set-cards {
      margin-top: 20px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      column-gap: 20px;

      .asset-benchmark-income-card {
        border: 1px solid #eaecee;
        border-radius: 5px;
        width: 50%;

        h1 {
          font-family: Roboto;
          font-size: 18px;
          font-weight: 500;
          color: #3e3f42;
          line-height: 28px;
          padding: 30px 25px;
        }

        .line {
          background-color: #eaecee;
          height: 0px;
          border: solid 1px #eaecee;
          width: 100%;
        }

        .asset-card-table {
          color: #9ea0a5;
          display: flex;
          justify-content: space-around;
          padding: 10px 20px;

          p {
            font-family: Roboto;
            font-size: 12px;
            font-weight: 500;
            color: #9ea0a5;
            line-height: 18px;
            width: 20px;
            text-align: right;
          }

          &.content {
            padding: 30px 20px;

            p {
              color: #3e3f42;
            }
          }
        }
      }
    }
  }

  .income-expense-right-grid {
    padding: 0px 30px;
    display: flex;
    flex: 1;
    flex-direction: column;
    row-gap: 20px;
    width: 100%;
    border: 1px solid #eaedf3;
    border-radius: 2px;
    border-left: 3px solid #34aa44;
    justify-content: center;

    @media screen and (max-width: 1000px) {
      padding: 15px 30px !important;
    }

    .difference-wrapper {
      display: flex;
      justify-content: space-between;

      h2 {
        font-family: Roboto;
        font-size: 12px;
        line-height: 18px;
        font-weight: 500;
        color: #9ea0a5;
        text-transform: uppercase;
      }
    }

    .asset-benchmark {
      display: flex;
      flex-direction: column;
      row-gap: 10px;

      h2 {
        font-family: Roboto;
        font-size: 14px;
        line-height: 22px;
        font-weight: 500;
        color: #6b6c6f;
      }

      h1 {
        font-family: Roboto;
        font-size: 26px;
        line-height: 38px;
        font-weight: 500;
        color: #3e3f42;
      }
    }

    .progress-status {
      display: flex;
      justify-content: space-between;
      width: 100%;

      p {
        font-family: Roboto;
        font-size: 12px;
        font-weight: 400;
        color: #3e3f42;
        width: 20px;
      }
    }
  }
}

.market-analysis-card {
  margin-top: 16px;
  h1 {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    color: #3e3f42;
  }

  .market-analysis-grids {
    display: grid;
    grid-template-columns: 60% 0.5fr 0.4fr;
    grid-gap: 40px;
    margin-bottom: 40px;

    .market-analysis-grid-1 {
      height: 250px;
      width: 100%;
      // object-fit: contain;
      display: flex;
      flex: 1;
    }

    .market-analysis-grid-2 {
      display: flex;
      justify-content: space-between;
      height: 250px;
      width: 100%;
      column-gap: 10px;

      img {
        height: 100%;
        object-fit: contain;
      }

      .graph-labels {
        padding-top: 10px;
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        justify-content: end;

        .label {
          display: flex;
          align-items: center;
          column-gap: 6px;
          padding-left: 5px;

          p {
            width: 6px;
            height: 6px;
            border-radius: 100%;
          }

          h4 {
            font-family: Roboto;
            font-size: 12px;
            font-weight: 500;
            color: #9ea0a5;
            text-transform: uppercase;
          }
        }
      }
    }

    .market-analysis-grid-3 {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 230px;
      column-gap: 10px;
      align-items: end;
      padding-top: 20px;

      p {
        font-family: Roboto;
        font-size: 12px;
        font-weight: 500;
        color: #fff;
        padding: 5px 8px;
        background-color: #8ec14b;
        width: 38px;
        border-radius: 4px;
      }

      h1 {
        font-family: Roboto;
        font-size: 38px;
        font-weight: 300;
        line-height: 60px;
        color: #3e3f42;
      }

      h6 {
        font-family: Roboto;
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        color: #9ea0a5;
      }

      h2 {
        font-family: Roboto;
        font-size: 22px;
        font-weight: 400;
        line-height: 32px;
        color: #3e3f42;
      }
    }
  }
}

.utilities-card {
  h2 {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    color: #3e3f42;
    margin-bottom: 15px;
  }

  h1 {
    font-family: Roboto;
    font-size: 26px;
    line-height: 38px;
    font-weight: 400;
    color: #3e3f42;
  }

  .unit-details-grid {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
  }

  .unit-details {
    padding: 20px 0px;

    .unit-details-building-label {
      display: flex;
      align-items: center;
      column-gap: 6px;

      p {
        width: 10px;
        height: 10px;
        border-radius: 2px;
      }

      h4 {
        font-family: Roboto;
        font-size: 12px;
        font-weight: 500;
        color: #9ea0a5;
        text-transform: uppercase;
      }
    }
  }

  .unit-details:not(:first-child) {
    padding-left: 20px;
    border-left: 1px solid #eaedf3;
  }

  .unit-graph {
    margin: 20px 0px;
  }

  .consuming-value-grid {
    display: grid;
    grid-template-columns: 65% 0.8fr;
    grid-gap: 30px;
    margin-top: 20px;
    align-items: center;

    .consuming-value-header {
      h2 {
        margin-bottom: 20px;
      }
    }

    .consuming-value-content {
      display: grid;
      grid-template-columns: 32.5% 60%;
      grid-gap: 20px;
      align-items: center;

      .value-cost-header {
        flex: 1;

        .value-content-cost {
          display: flex;
          align-items: center;
          column-gap: 6px;
          padding-left: 5px;

          p {
            width: 10px;
            height: 10px;
            border-radius: 2px;
          }

          h4 {
            font-family: Roboto;
            font-size: 12px;
            font-weight: 500;
            color: #9ea0a5;
            text-transform: uppercase;
          }
        }
      }

      .value-budget-indicator {
        display: flex;
        flex: 1;
        flex-direction: row;
        column-gap: 10px;
        align-items: center;
        max-height: 50px;
        width: fit-content;
        h4 {
          font-family: Roboto;
          font-size: 14px;
          line-height: 22px;
          font-weight: 500;
          width: 90px;
        }

        img {
          width: 20px;
          height: 20px;
        }

        p {
          font-family: Roboto;
          font-size: 12px;
          font-weight: 500;
          line-height: 22px;
          padding: 2px 0px;
          height: 24px;
          width: 38px;
          text-align: center;
          color: white;
          border-radius: 2px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}

.maintenance-card {
  // display: grid;
  // grid-template-columns: 60% 1fr;
  column-gap: 50px;
  row-gap: 40px;
  align-items: center;

  h4 {
    font-family: Roboto;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    color: #9ea0a5;
    text-transform: uppercase;
  }

  .maintenance-bar-graph {
    flex: 1;

    img {
      height: 454px;
    }
  }

  .maintenance-pie-chart {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    row-gap: 30px;

    .pie-chart-wrapper {
      display: block;
      text-align: center;

      img {
        width: 250px;
        height: 250px;
      }
    }

    .pie-chart-details {
      display: grid;
      grid-template-columns: 20% 10px 1fr;
      grid-gap: 10px;
      width: fit-content;
      align-items: center;

      h4 {
        font-family: Roboto;
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
        color: #3e3f42;
        padding-right: 5px;
      }

      p {
        width: 10px;
        height: 10px;
        border-radius: 2px;
      }

      h6 {
        font-family: Roboto;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        color: #3e3f42;
        width: 110px;
      }
    }
  }
}

.news-summaries {
}

.pros-and-cons {
  display: grid;
  grid-template-columns: 45% 1fr;
  grid-gap: 40px;

  .map-wrapper {
    img {
      border-radius: 5px;
    }
  }

  .recommendations-container {
    .recommendations-header {
      margin: 20px 0px;
      margin-top: 0;
      display: flex;
      flex-direction: rows;
      align-items: center;
      justify-content: space-between;

      h2 {
        font-family: Roboto;
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
        color: #3e3f42;
      }
    }

    .recommendations-content {
      display: flex;
      flex-direction: column;
      row-gap: 20px;
    }
  }
}

.line {
  background-color: #eaedf3;
  height: 0px;
  border: solid 1px #eaedf3;
  width: 100%;
}

.pagination {
  .page-number {
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 5px 8px;
    margin: 0px 5px;
    color: #6b6c6f;

    &.active {
      color: #3e3f42;
      background-color: #ebedf2;
      border-radius: 2px;
    }
  }

  .pagination-button {
    color: #9ea0a4;
    background: transparent;
    border: none;
    cursor: pointer;

    &:hover {
      color: #7757c0;
    }
  }
}

.overview-header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .portfolioDatanew-overview-header-left {
    // margin-top: 14px;
    display: flex;
    flex-direction: row;
    column-gap: 40px;
    align-items: center;
    height: 100%;
    .search-icons {
      display: flex;
      align-items: center;
      gap: 20px;
    }
    a {
      font-family: Roboto;
      color: #6b6c6f;
      font-weight: 400;
      font-size: 16px;
      padding: 5% 0%;
      // height: 100%;

      &.active {
        border-bottom: 3px solid #7d55c7;
        transition: all 0.3;
        font-weight: 500;
        color: #3e3f42;
      }
    }
  }

  .overview-header-right {
    display: flex;
    column-gap: 10px;

    .filter-button {
      display: flex;
      column-gap: 5px;
      padding: 5px 20px;
      background-color: #fff;
      align-items: center;
      border: 1px solid #e2e5ed;
      border-radius: 5px;

      img {
        width: 20px;
        height: 20px;
      }

      h4 {
        font-family: Roboto;
        color: #3e3f42;
        font-weight: 400;
        font-size: 14px;
      }
    }
  }
}

.asset-header-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 5px;

  @media screen and (max-width: 1500px) {
    .invite-button {
      // img {
      //   display: none !important;
      // }
    }
  }
  @media screen and (max-width: 700px) {
    .invite-button {
      h4 {
        font-size: 8px !important;
      }
      // img {
      //   display: none !important;
      // }
    }
  }

  img {
    width: 153px;
    height: 38px;
    object-fit: contain;
  }

  h6 {
    font-family: Roboto;
    color: #9ea0a5;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-transform: uppercase;
    margin-right: 10px;
  }

  .invite-button {
    display: flex;
    column-gap: 5px;
    padding: 10px 15px;
    background-image: linear-gradient(to top, #f7f8fa, #fefefe);
    align-items: center;
    border: 1px solid #e2e5ed;
    border-radius: 5px;
    column-gap: 8px;

    img {
      width: 22px;
      height: 22px;
    }

    h4 {
      font-family: Roboto;
      color: #3e3f42;
      font-weight: 400;
      font-size: 14px;
    }
  }

  .vertical-line {
    // background-color: #eaedf3;
    height: 40px;
    border-left: solid 1px #eaedf3;
    width: 0px;
    margin: 0px 10px;
  }
}

.showAi {
  position: absolute;
  bottom: 77%;
  right: 366px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  z-index: 1111111111;
  transition: 0.3s ease;

  p {
    display: none;
  }
}

.ai_support_btn {
  position: fixed;
  bottom: 0;
  right: 12px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;

  img {
    width: 48px;
    height: 48px;
  }

  p {
    color: #6b6c6f;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }
}

.invitebox_modal {
  position: absolute !important;
  right: 0 !important;
  width: 100% !important;
}

.invitebox_wrapper_portfolioDatanew {
  width: 100% !important;
  top: 11px;
  border-radius: 4px;
  border: 1px solid #eaedf3;
  background: #fff;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.04);
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: static !important;
  max-height: 100% !important;
  overflow-y: auto;
  overflow-x: hidden;

  .invitebox_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 18px;
    h2 {
      color: #9ea0a5;
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
      /* 150% */
      text-transform: uppercase;
    }
  }

  .Portfolio_invitebox_people {
    display: flex;
    // gap: 20px;
    // padding-bottom: 16px;
    border-bottom: 1px solid #eaedf3;
    flex-direction: column;
    .All_people {
      align-items: center;
      gap: 10px;
      margin-left: 0 !important;

      img {
        height: 38px;
        width: 38px;
        margin-bottom: 32px;
      }

      .scrollable_container {
        max-height: 428px;
        overflow-y: auto;
      }

      .invitebox_profile {
        display: flex;
        // align-items: center;
        gap: 5px;
        justify-content: space-between;
        margin-bottom: 15px;

        img {
          height: 40px;
          width: 40px;
          border-radius: 50%; /* Optional: Rounded profile pictures */
        }

        div {
          flex: 1; /* Ensures the text div takes remaining space */
        }

        p {
          color: #3e3f42;
          font-family: Roboto;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px; /* Adjust line-height as needed */
          margin: 0; /* Reset margin to remove default spacing */
        }

        span {
          color: #9ea0a5;
          font-family: Roboto;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px; /* Adjust line-height as needed */
        }
      }
    }

    .text {
      color: var(--666666-mid-grey, #666);
      font-family: Roboto;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
    }
  }

  .add_people {
    > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
    }

    span {
      color: #9ea0a5;
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      /* 150% */
    }

    button {
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 16px;
      height: 38px;
      width: 66px;
      align-items: center;
      gap: 12px;
      border-radius: 4px;
      border: 1px solid var(--purple-pantone-2665-c-71, rgba(125, 85, 199, 0.71));
      background: linear-gradient(0deg, rgba(125, 85, 199, 0.3) 0%, rgba(255, 255, 255, 0) 107.78%), #7d55c7;
      background-blend-mode: multiply, normal;
      box-shadow: 0px 1px 1px 0px rgba(19, 31, 21, 0.1), 0px 2px 0px 0px rgba(255, 255, 255, 0.06) inset;
      .spinner {
        border: 4px solid rgba(0, 0, 0, 0.1);
        border-left-color: white;
        border-radius: 50%;
        width: 24px;
        height: 24px;
        animation: spin 1s linear infinite;
      }

      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }
    .investment_send_email {
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 16px;
      height: 38px;
      width: 144px;
      align-items: center;
      gap: 12px;
      border-radius: 4px;
      border: 1px solid var(--purple-pantone-2665-c-71, rgba(125, 85, 199, 0.71));
      background: linear-gradient(0deg, rgba(125, 85, 199, 0.3) 0%, rgba(255, 255, 255, 0) 107.78%), #7d55c7;
      background-blend-mode: multiply, normal;
      box-shadow: 0px 1px 1px 0px rgba(19, 31, 21, 0.1), 0px 2px 0px 0px rgba(255, 255, 255, 0.06) inset;
      .spinner {
        border: 4px solid rgba(0, 0, 0, 0.1);
        border-left-color: white;
        border-radius: 50%;
        width: 24px;
        height: 24px;
        animation: spin 1s linear infinite;
      }

      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }
  }
  .close-button-InviteBox {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 18px;
    background: none;
    border: none;
    padding: 12px;
    outline: none;
  }
}

.social_network_wrapper {
  display: flex;
  align-items: flex-start;
  gap: 40px;
  .filter_results {
    h2 {
      color: #3e3f42;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      margin-bottom: 16px;
    }

    .show {
      color: #9ea0a5;
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
      text-transform: uppercase;
    }

    .checkbox_wrapper {
      display: flex;
      flex-direction: column;
      gap: 6px;
      margin-top: 10px;

      p {
        color: #9ea0a5;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        margin-left: 30px;
      }
    }

    .checkbox {
      padding-left: 0px !important;
    }
  }
  @media screen and (max-width: 900px) {
    .hide {
      display: none !important;
    }
  }
}

.social_networks {
  h1 {
    padding: 20px 0;
    color: #3e3f42;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    /* 150% */
  }
}

.twitter_wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.twitter_post {
  width: 658px;
  height: 235px;
  border-radius: 4px;
  border: 1px solid #e2e5ed;
  background: #fff;
  display: flex;
  flex-direction: column;
  gap: 14px;

  .twitter_profile {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 0 20px;
    margin-top: 20px;

    img {
      height: 38px;
      width: 38px;
    }

    p {
      color: #3e3f42;
      font-family: Roboto;
      font-size: 19px;
      font-style: normal;
      font-weight: 700;
      line-height: 22px;
      display: flex;
      align-items: center;
      gap: 4px;
    }

    span {
      color: var(--LP-gray, #9ea0a5);
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      /* 157.143% */
    }
  }

  .tweet {
    padding: 0 20px;

    .text {
      color: #3e3f42;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      /* 157.143% */
    }
  }

  .tweet_details {
    padding: 0 20px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
    width: 100%;

    > div {
      display: flex;
      align-items: center;
      gap: 20px;
    }

    span {
      color: var(--LP-gray, #9ea0a5);
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }

  .tweet_lower {
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 38px;
    border-top: 1px solid #eaedf3;

    > div {
      display: flex;
      align-items: center;
      gap: 12px;

      p {
        color: var(--3-fa-2-f-7-links-informational-blue, #3fa2f7);
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
      }
    }
  }
}

.asset_reviews_wrapper {
  .asset_reviews {
    width: 658px;
  }
  .ratings {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
    padding: 20px;
    border-bottom: 1px solid #eaedf3;
    border-top: 1px solid #eaedf3;

    p {
      color: #3e3f42;
      font-family: Roboto;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
    }

    .rating_text {
      margin-left: 10px;
      color: #f9c125;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px; /* 157.143% */
    }
    .ratings_row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 20px;

      div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
      }

      span {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .review_wrapper {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 40px;

    .review_text {
      padding-bottom: 20px;

      .text2 {
        color: #6b6c6f;
        font-family: Roboto;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
      }
    }
  }

  .review_box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    border-bottom: 1px solid #eaedf3;
  }

  .review_box_upper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    width: 100%;

    .review_box_left {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;

      .text1 {
        color: #9ea0a5;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
      }

      .name {
        color: #3e3f42;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
      }

      img {
        height: 38px;
        width: 38px;
        border-radius: 100%;
      }
    }

    .review_box_right_wrapper {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;

      span {
        color: #9ea0a5;
        text-align: right;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        /* 157.143% */
      }
    }

    .review_box_right {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

// hangburger
.portfolio-container {
  .sidebar {
    width: 100%;
    max-width: 319px;
    transition: transform 0.3s ease-in-out;
    background-color: white;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    margin-top: 5px;
    // z-index: 10;

    @media only screen and (min-width: 1600px) {
      max-width: 20vw;
    }

    @media (max-width: 900px) {
      transform: translateX(-100%);
    }
  }

  .sidebar.open {
    @media (max-width: 900px) {
      transform: translateX(0);
      margin-left: 3px;
      margin-top: 148px;
      background-color: white;
      z-index: 2;
    }
  }

  .hamburger-icon {
    display: none;
    cursor: pointer;
    position: fixed;
    top: 20px;
    left: 20px;
    z-index: 11111;

    @media (max-width: 900px) {
      display: block;
      position: absolute;
      top: 96px;
    }

    span {
      display: block;
      width: 30px;
      height: 3px;
      background-color: #000;
      margin: 5px 0;
      transition: all 0.3s ease;
    }
  }

  .main-container {
    width: calc(100% - 319px);

    @media only screen and (min-width: 2238px) {
      width: 59vw;
    }

    @media only screen and (max-width: 1200px) {
      width: calc(100% - 281px);
    }

    @media only screen and (max-width: 1000px) {
      width: calc(100% - 257px);
    }

    @media (max-width: 900px) {
      margin-left: 0;
      width: 100%;
    }
  }
}

// .targeted-element {
//     /* border: 2px solid #000; */
//     /* background-color: #009639; */
//     margin-top: 4px;
//     position: relative;
//     top: 158px;
// }

.adjust-scroll-top {
  scroll-margin-top: 10px;
}

//
/* Add this to your CSS file */
.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full screen height */
}

/* Custom spinner styles */
.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #7152bc;
  animation: spin 1s ease infinite;
  margin: auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.No-Portfolio {
  margin: auto;
  margin-top: 50%;
}

// Simmer effect

/* propertyDetailsContent.css */

.property-details-card {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 20px;
}

.property-details-card .detail-card-right-container {
  text-align: right;
}

// .property-details-card .shimmer-effect {
//   background-color: #f0f0f0;
// }

.property-details-card .shimmer-effect .shimmer-line {
  background: linear-gradient(to right, #f0f0f0 10%, #e0e0e0 20%, #f0f0f0 30%);
  background-size: 200% auto;
  display: inline-block;
  width: 100%;
  height: 50px;
  border-radius: 10px;
  animation: shimmer 1.5s infinite linear;
}

@keyframes shimmer {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

// Simmer effect

.sidebar-content {
  .shimmer-effect {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    .shimmer-block {
      width: 100%;
      height: 100px; /* Adjust height as needed */
      background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%);
      background-size: 800px 104px; /* Adjust according to shimmer speed and size */
      animation: shimmer 1.5s infinite linear;
    }
  }
}

@keyframes shimmer {
  100% {
    background-position: 800px 0;
  }
}

// data Charts

.portfolioData-maintenance-card .charts-container {
  display: flex;

  @media only screen and (max-width: 1310px) {
    flex-direction: column;

    .portfolioData-maintenance-donut-chart {
      height: 50vh;
    }
  }
}

.portfolioData-maintenance-column-chart {
  margin: 10px;
  width: 50%;
  h4 {
    font-family: Roboto;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    color: #9ea0a5;
    text-transform: uppercase;
  }
}

.portfolioData-maintenance-donut-chart {
  margin: 10px;
  h4 {
    font-family: Roboto;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    color: #9ea0a5;
    text-transform: uppercase;
  }

  .apexcharts-canvas {
    height: 100% !important;

    svg {
      height: 100% !important;
    }

    .apexcharts-inner {
      width: 250px !important;
    }

    foreignObject {
      height: 100%;

      .apexcharts-legend {
        flex-direction: column !important;
        gap: 14px;
      }
    }
  }
}

// popup



// data

.portfolioData-maintenance-card {
  // margin: 20px;
  border-radius: 5px;
  background-color: #fff;
  width: 100%;
  margin-top: 40px;
}

.charts-container {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.styled-table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  font-family: "Arial", sans-serif;
  width: 100%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.styled-table thead tr {
  background-color: #7153bd;
  color: #ffffff;
  text-align: left;
}

.styled-table th,
.styled-table td {
  padding: 12px 15px;
  border: 1px solid #dddddd;
}

.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
  border-bottom: 2px solid #009879;
}

.styled-table tbody tr.active-row {
  font-weight: bold;
  color: #009879;
}

.overview-tab-content {
  padding: 40px 0px;

  .constiner_FinancialKPI {
    margin-top: 35px;
  }
  .financial-kpi-cards {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fit, minmax(227px, 1fr));

    @media screen and (max-width: 1550px) {
      margin-top: 23px;
    }
    @media screen and (max-width: 1150px) {
    }
  }
}

.overview-tabs {
  margin-top: 14px;
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  border-bottom: 1px solid #eaedf3;
  @media screen and (max-width: 900px) {
    // width: 90% !important;
    column-gap: 17px !important;
  }

  a {
    font-family: Roboto;
    color: #6b6c6f;
    font-weight: 400;
    font-size: 12px;
    padding-bottom: 14px;

    &.active {
      border-bottom: 2px solid #7d55c7;
      transition: all 0.3;
    }
  }
}

.market-header {
  font-family: Roboto;
  color: #3e3f42;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
}

.market-tabs {
  margin-top: 14px;
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  border-bottom: 1px solid #eaedf3;

  a {
    font-family: Roboto;
    color: #6b6c6f;
    font-weight: 400;
    font-size: 12px;
    padding-bottom: 14px;

    &.active {
      border-bottom: 2px solid #7d55c7;
      transition: all 0.3;
    }
  }
}

.market-tab-content {
  padding: 30px 0px;
}

.market-survey {
  padding: 30px;
}

.property-features {
  border: 1px solid #eaedf3;
  border-radius: 5px;
  padding: 35px 30px;
  display: flex;
  flex-direction: column;
  row-gap: 25px;
  box-shadow: 5px 5px 9px rgba(0, 0, 0, 0);
  -webkit-box-shadow: 5px 5px 9px rgba(0, 0, 0, 0);
  -moz-box-shadow: 5px 5px 9px rgba(0, 0, 0, 0);

  @media screen and (max-width: 1000px) {
    .show_more_btn_portfolio,
    .view_floor_btn_portfolio,
    .edit_asset_btn_portfolio {
      width: 300px;
      margin: auto;
    }
  }
}

// .scrollable_container {
//   max-height: 300px; /* Set the desired height */
//   overflow-y: auto; /* Enable vertical scrolling */
// }

// Popup for see details

/* Assuming you have a CSS file for your component */

.popup_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure popup is above other content */
}

.popup_container {
  background: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 90%; /* Adjusted to make it responsive */
  width: 34%;
  max-height: 80%;
  overflow-y: auto; /* Enable scrolling if content exceeds the height */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Add subtle shadow for depth */
  position: relative; /* Ensure absolute positioning of close button works */
}

.close_button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
  color: #aaa;
  transition: color 0.3s ease; /* Smooth color transition on hover */
}

.close_button:hover {
  color: #555; /* Darken color on hover */
}

.details_container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 24px;
}

.profile_image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-right: 20px;
  background: #f0f0f0;
  display: flex;
  align-items: stretch;
  overflow: hidden;
}

.details {
  /* flex: 1 1; */
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  gap: 6px;
}

.section {
  margin-top: 20px;
}

.section h3 {
  margin-bottom: 10px;
  color: #333; /* Darken heading text color */
}

.permissions-category {
  margin-bottom: 15px;
}

.permissions-category h4 {
  margin-bottom: 5px;
  color: #666; /* Lighten subheading text color */
}

.permissions-list {
  list-style-type: none;
  padding: 0;
}

.permissions-list li {
  margin-bottom: 5px;
  color: #444; /* Adjust list item text color */
}

.property-data {
  margin-top: 10px;
}

.property-data p {
  margin: 5px 0;
  color: #555; /* Adjust paragraph text color */
}

// popup assetdetails

/* Styles for the modal overlay */
.popup_overlay_PropertyDetailsContent {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure modal is above other content */
}

.popup_content_PropertyDetailsContent {
  background: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 600px;
  width: 100%;
  max-height: 80vh;
  overflow-y: auto;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
}

.popup_content_PropertyDetailsContent h2 {
  margin-top: 0;
  font-size: 1.8rem; /* Increase title font size */
  color: #333;
  border-bottom: 1px solid #ccc; /* Divider line for better separation */
  padding-bottom: 10px;
}

.popup_content_PropertyDetailsContent form {
  display: flex;
  flex-direction: column;
}

.popup_content_PropertyDetailsContent label {
  font-weight: bold;
  margin-top: 10px;
}

.popup_content_PropertyDetailsContent input,
.popup_content_PropertyDetailsContent textarea {
  width: 100%;
  padding: 12px;
  margin-top: 5px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1.2rem;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 183.333% */
  text-transform: capitalize;
}

.popup_content_PropertyDetailsContent button.save-button {
  margin-top: 15px;
  padding: 12px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 38px;
  padding: 10px;
  border: none;
  border-radius: 4px;
  background-color: white; /* Blue background color */

  font-size: 1rem; /* Adjust font size */
  font-weight: bold; /* Bold text */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Soft shadow */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s ease, transform 0.3s ease; /* Smooth transitions */
}

.popup_content_PropertyDetailsContent button.save-button:hover {
  background: var(--Primary-Purple-500, #7d55c7);
  color: white;
}

.popup_content_PropertyDetailsContent .close-icon {
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
  height: 20px;
}

//

.editbutton_div {
  float: right;
  margin-top: -38px;
  button {
    margin-top: -8px;
    padding: 12px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 38px;
    border: none;
    border-radius: 4px;
    background-color: white; /* Blue background color */
    width: 164px;
    font-size: 1rem; /* Adjust font size */
    font-weight: bold; /* Bold text */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Soft shadow */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s ease, transform 0.3s ease; /* Smooth transitions */
  }
}

// Financial KPI
.edit_popup_KPI {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure modal is above other content */
}

.popup_content_KPI {
  background: #ffffff;
  padding: 30px;
  border-radius: 10px;
  max-width: 600px;
  width: 100%;
  max-height: 80vh; /* Set maximum height for better responsiveness */
  overflow-y: auto; /* Enable scrolling if content exceeds the height */
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2); /* Shadow for a raised effect */
  position: relative;
}

.popup_content_KPI h2 {
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 600;
  color: #333;
}

.popup_content_KPI label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  color: #555;
}

.popup_content_KPI input[type="text"] {
  width: calc(100% - 40px);
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  color: #333;
  box-sizing: border-box;
}

.popup_content_KPI input[type="text"]:focus {
  border-color: #007bff;
  outline: none;
}

.popup_content_KPI .button-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.popup_content_KPI button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.popup_content_KPI .save-button {
  background-color: #007bff;
  color: white;
  margin-right: 10px;
}

.popup_content_KPI .save-button:hover {
  background-color: #0056b3;
}

.popup_content_KPI .close-button {
  background-color: #ccc;
  color: #333;
}

.popup_content_KPI .close-button:hover {
  background-color: #999;
}

.popup_content_KPI .new-entry {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.popup_content_KPI .new-entry label {
  flex: 1;
}

.popup_content_KPI .new-entry input[type="text"] {
  flex: 2;
  margin-right: 10px;
}

.popup_content_KPI .add-button {
  flex: none;
  padding: 10px 15px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
}

.popup_content_KPI .add-button:hover {
  background-color: #218838;
}

// OperatingIncomeAndExpenses

.edit_popup_OperatingIncomeAndExpenses {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup_content_OperatingIncomeAndExpenses {
  background: white;
  padding: 30px;
  border-radius: 8px;
  width: 80%;
  max-width: 600px;
  max-height: 80vh;
  overflow-y: auto;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
}

.popup_content_OperatingIncomeAndExpenses h2 {
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #333; /* Adjust text color */
}

.popup_content_OperatingIncomeAndExpenses .input-group {
  margin-bottom: 20px;
}

.popup_content_OperatingIncomeAndExpenses label {
  font-weight: bold;
  margin-bottom: 5px;
  display: block;
}

.popup_content_OperatingIncomeAndExpenses input[type="text"] {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color 0.3s ease;
}

.popup_content_OperatingIncomeAndExpenses input[type="text"]:focus {
  outline: none;
  border-color: #1665d8; /* Focus color */
}

.popup_content_OperatingIncomeAndExpenses .button-group {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.popup_content_OperatingIncomeAndExpenses .button-group button {
  margin-left: 10px;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  background-color: #1665d8; /* Blue background color */
  color: white; /* Text color */
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.popup_content_OperatingIncomeAndExpenses .button-group button:hover {
  background-color: #1252ad; /* Darker shade of blue on hover */
}

.Edit_OperatingIncomeAndExpenses {
  float: right;
  margin-top: -25px;
  button {
    margin-top: -30px;
    padding: 12px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 38px;
    border: none;
    border-radius: 4px;
    background-color: white;
    width: 164px;
    font-size: 1rem;
    font-weight: bold;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
}

// KPI

.Edit_FinancialKPI {
  float: right;
  margin-top: -25px;
  button {
    margin-top: -30px;
    padding: 12px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 38px;
    border: none;
    border-radius: 4px;
    background-color: white;
    width: 164px;
    font-size: 1rem;
    font-weight: bold;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
}

// MarketAnalysis
.Edit_MarketAnalysis {
  float: right;
  margin-top: -25px;
  button {
    margin-top: -8px;
    padding: 12px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 38px;
    border: none;
    border-radius: 4px;
    background-color: white; /* Blue background color */
    width: 164px;
    font-size: 1rem; /* Adjust font size */
    font-weight: bold; /* Bold text */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Soft shadow */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s ease, transform 0.3s ease; /* Smooth transitions */
  }
}
.edit_popup_MarketAnalysis {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure modal is above other content */
}

.popup_content_MarketAnalysis {
  background: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 600px;
  width: 100%;
  max-height: 80vh; /* Set maximum height for better responsiveness */
  overflow-y: auto; /* Enable scrolling if content exceeds the height */
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2); /* Shadow for a raised effect */

  display: flex;
  flex-direction: column;
  gap: 20px;

  h2 {
    color: #3e3f42;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px; /* 150% */
  }

  p {
    color: #3e3f42;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
  }

  button {
    height: 38px;
    border-radius: 4px;
    border: 1px solid #d8dce6;
    background: linear-gradient(0deg, #f6f7f9 0%, #fff 100%);
    box-shadow: 0px 2px 0px 0px rgba(255, 255, 255, 0.05) inset, 0px 1px 1px 0px rgba(22, 29, 37, 0.05);
  }
}

// Utilities

.Edit_Utilities {
  float: right;
  margin-top: -14px;
  button {
    margin-top: -8px;
    padding: 12px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 38px;
    border: none;
    border-radius: 4px;
    background-color: white; /* Blue background color */
    width: 164px;
    font-size: 1rem; /* Adjust font size */
    font-weight: bold; /* Bold text */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Soft shadow */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s ease, transform 0.3s ease; /* Smooth transitions */
  }
}

.edit_popup_Utilities {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure modal is above other content */
}

.edit_popup_content_Utilities {
  background: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 600px;
  width: 100%;
  max-height: 80vh; /* Set maximum height for better responsiveness */
  overflow-y: auto; /* Enable scrolling if content exceeds the height */
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2); /* Shadow for a raised effect */
}

.Edit_Maintenance {
  float: right;
  margin-top: -47px;
  button {
    margin-top: -8px;
    padding: 12px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 38px;
    border: none;
    border-radius: 4px;
    background-color: white;
    width: 164px;
    font-size: 1rem;
    font-weight: bold;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
}

.edit_popup_Maintenance {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.edit_popup_content_Maintenance {
  background: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 600px;
  width: 100%;
  max-height: 80vh;
  overflow-y: auto;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
}

.button_portfolioDatanew {
  /* margin-top: 15px; */
  // padding: 12px 20px;
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  /* height: 38px; */
  /* padding: 0px; */
  border: none;
  /* border-radius: 4px; */
  background-color: white;
  font-size: 11px;
  /* font-weight: bold; */
  /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  color: #3e3f42;
  leading-trim: both;
  text-edge: cap;
  font-family: Roboto;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}

.overlapping-images-portfolioDatanew {
  display: flex;
  align-items: center;
}

.image-container-portfolioDatanew {
  position: relative;
  margin-left: -15px;
}

.image-container-portfolioDatanew img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-shadow: 0 0 0 2px white;
}

.invite_email {
  width: 80px;
  height: 80px;
}

// Popup to show Permisson Details

.permission-Details {
  display: flex;
  gap: 10px;
}

// Buttton On-Off

// .toggle-wrapper {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   position: relative;
//   border-radius: 0.5em;
//   padding: 0.125em;
//   background-image: linear-gradient(to bottom, #d0c4b8, #f5ece5);
//   box-shadow: 0 1px 1px rgb(255 255 255 / 0.6);
// }

.toggle-checkbox {
  -webkit-appearance: none;
  appearance: none;
  position: absolute;
  z-index: 1;
  border-radius: inherit;
  width: 100%;
  height: 5%;
  opacity: 0;
  cursor: pointer;
}

.toggle-container {
  display: flex;

  position: relative;
  border-radius: 0.375em;
  width: 3em;
  height: 1.5em;
  background-color: red;
  box-shadow: inset 0 0 0.0625em 0.125em rgb(255 255 255 / 0.2), inset 0 0.0625em 0.125em rgb(0 0 0 / 0.4);
  transition: background-color 0.4s linear;

  .toggle-checkbox:checked + & {
    background-color: green;
  }
}

.toggle-button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0.0625em;
  border-radius: 0.3125em;
  width: 1.375em;
  height: 1.375em;
  background-color: white;
  box-shadow: inset 0 -0.0625em 0.0625em 0.125em rgb(0 0 0 / 0.1), inset 0 -0.125em 0.0625em rgb(0 0 0 / 0.2), inset 0 0.1875em 0.0625em rgb(255 255 255 / 0.3), 0 0.125em 0.125em rgb(0 0 0 / 0.5);
  transition: left 0.4s /*cubic-bezier(.65, 1.35, .5, 1)*/;

  .toggle-checkbox:checked + .toggle-container > & {
    left: 1.5625em;
  }
}

.toggle-button-circles-container {
  display: grid;
  grid-template-columns: repeat(3, min-content);
  gap: 0.125em;
  position: absolute;
  margin: 0 auto;
}

.toggle-button-circle {
  border-radius: 50%;
  width: 0.125em;
  height: 0.125em;
  background-image: radial-gradient(circle at 50% 0, #f6f0e9, #bebcb0);
}

// newdata

.toggle-section {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
}

.success_message {
  color: green;
}

.PropertyDetailsContent_AssetName {
  display: flex;
  justify-content: space-between;
}
.no_access {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  justify-content: center;
}

// Invitebox
/* Modal Wrapper */
.invitebox_modal {
  position: relative;
  max-width: 500px; /* Reduced width */
  margin: 0 auto;
  padding: 10px; /* Reduced padding */
  // background-color: #f9f9f9;
  border-radius: 6px; /* Reduced border radius */
  // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  font-family: "Arial", sans-serif;
  height: 70vh; /* Reduced height */
  display: flex;
  flex-direction: column;
}

/* Modal Content Wrapper */

.invitebox_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 8px;
  margin-bottom: 12px;
  border-bottom: 1px solid #ddd;
}

.invitebox_header h2 {
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
  margin: 0;
}

.close-button-InviteBox {
  background: none;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
  color: #666;
}

.close-button-InviteBox:hover {
  color: #ff5f5f;
}

.tab_navigation {
  display: flex;
  justify-content: space-around;
  margin-bottom: 10px;
}

.tab_navigation button {
  background: none;
  border: none;
  padding: 5px 10px;
  font-size: 0.9rem;
  cursor: pointer;
  border-bottom: 2px solid transparent;
  font-size: 14px;
  font-weight: 600;
  color: #3e3f42;
}

.active-tab {
  color: #007bff;
  border-bottom: 2px solid #007bff !important;
}

.scrollable_container {
  flex-grow: 1;
  overflow-y: auto;
  padding: 20px;
  margin-bottom: 12px;
}

.scrollable_container::-webkit-scrollbar {
  width: 6px;
}

.scrollable_container::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 3px;
}

.invitebox_profile {
  display: flex;
  align-items: center;
  padding: 6px;
  border: 1px solid #ddd;
  border-radius: 6px;
  margin-bottom: 6px;
  background-color: #fff;
}

.invitebox_profile img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  /* flex-direction: column; */
  justify-content: center;
}

.invitebox_profile p {
  margin: 0;
  font-size: 0.85rem;
  color: #333;
}

.button_portfolioDatanew {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 6px 10px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.85rem;
}

.button_portfolioDatanew:hover {
  background-color: #0056b3;
}

.Syndicate_data {
  padding: 10px;
  border-radius: 6px;
  margin-bottom: 12px;
  border: 1px solid #ddd;
  margin: 20px;
}

.text {
  font-size: 0.85rem;
  color: #666;
}

.add_people {
  position: sticky;
  bottom: 0;
  background: #ffffff;
  padding: 18px 21px;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #ddd;
}

.add_people input {
  padding: 6px;
  font-size: 0.85rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 275px;
}

.add_people button {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 6px 10px;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 8px;
  white-space: nowrap;
}

.Total_number_people {
  margin: 10px;
  margin-left: 24px;
}

.Buttons_for_send_email {
  display: flex;
  justify-content: center;
}

.error-input {
  border: 1px solid red;
}

.error-message {
  color: red;
  font-size: 12px;
}

// Nav-card -

.nav-card {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    background-color: white;

    .favorite-icon {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

.favorite-icon {
  position: absolute;
  top: 107px;
  right: 10px;
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease;
  z-index: 10;
  cursor: pointer;

  svg {
    width: 24px;
    height: 24px;
  }
}
