.Reviewcap-container {
  display: flex;
  gap: 10px;
}

.Reviewcap-cap {
  border: 1px solid #eaedf3;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.15);
}
.Reviewcap-conatiner {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.Reviewcap-cap-data {
  display: flex;
  justify-content: space-between;
  margin: 10px;
  padding: 10px;
  align-items: center;
}
.Reviewcap-cap-data-button {
  justify-content: space-between;
  display: flex;
  margin: 10px;
  gap: 10px;
  padding: 10px;
}
.required-data {
  display: flex;
  justify-content: space-between;
}
.required-box {
  border: 1px solid #eaedf3;
  margin: 10px;
  padding: 10px;
}
.Refresh-Result {
  border: 1px solid var(--Primary-Purple-50, #eaedf3);
}
.Refresh-Result-data {
  display: flex;
  width: 725px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 23px;
}
.reviewCap-container {
  border: 1px solid #eaedf3;
}
.reviewCap-container-data {
  margin: 10px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.reviewCap-container-data p {
  color: var(--Primary-Gray-200, #9ea0a5);
  leading-trim: both;
  text-edge: cap;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  width: 220px;
  line-height: 22px;
}
.reviewCap-container-data-link {
  margin: 10px;
  padding: 10px;
}
.Reviewcap-container-data {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.Brek-container {
  border: 1px solid #eaedf3;
}
.Brek-container p {
  color: var(--Primary-Gray-200, #9ea0a5);
  leading-trim: both;
  text-edge: cap;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 157.143% */
}
.Brek-container-data {
  margin: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.Active-container {
  height: 50vh;
  justify-content: center;
  align-items: center;
  display: flex;
}
.Active-container-data {
  width: 300px;
  height: 150px;
  justify-content: center;
  display: flex;
  align-items: center;
}
.Cross-button {
  padding: 0;
  margin: 0;
  height: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  border: none;
  color: black;
  background-color: white;
  border-radius: 4px;
  border: 1px solid var(--Primary-Purple-50, #eaedf3);
  background: #fff;
}
.Refresh-Result {
  background-color: rgb(169, 32, 32);
  font-size: 10px;
  border: 1px solid var(--Primary-Purple-50, #eaedf3);
  background: rgba(125, 85, 199, 0.08);
}
.Refresh-Result-data p {
  color: var(--Black, #3e3f42);
  leading-trim: both;
  text-edge: cap;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}
.Refresh-Result-button {
  padding: 0;
  margin: 0;
  width: 180px;
  height: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  border: none;
  color: black;
  background-color: white;
  border-radius: 4px;
  border: 1px solid var(--Primary-Purple-50, #eaedf3);
  background: #fff;
}
.background-color-image {
  display: flex;
  width: 40px;
  padding: 5px;
  height: 40px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background: var(--Action-Yellow, #ffc700);
}

@media screen and (max-width: 900px) {
  .Reviewcap-container {
   flex-direction: column;
    gap: 10px;
    width: fit-content;
  }
}
