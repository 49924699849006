.market-s-main-wrapper {
  width: 730px;
  //   height: 237px;
  flex-shrink: 0;
  fill: #fff;
  stroke-width: 1px;
  stroke: #eaedf3;
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.04));
  border: 1px solid #eaedf3 !important;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 24px 0 0 0 !important;
  margin-bottom: 30px;
  @media screen and (max-width: 960px) {
    width: 100% !important;
  }

  .market-s-u,
  .market-s-c,
  .market-s-d {
    padding: 0 30px;
  }

  .market-s-d {
    border-top: 1px solid #eaedf3;

    .groupA {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 30px;
    }

    .share_btn_wrapper {
      display: flex;
      flex-direction: row;
      gap: 14px;

      color: #3e3f42;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px; /* 157.143% */
    }
  }
}
.market-s-main-wrapper > *span {
  display: block;
}
.market-s-u {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 19px;
}
.market-s-u :first-child {
  display: flex;
  gap: 20px;
  align-items: center;
}

.market-s-u-h > span:first-child {
  color: #3e3f42;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 155.556% */
  text-transform: capitalize;

  @media screen and (max-width: 1000px) {
    font-size: 16px !important;
  }
}
.market-s-u-h > span:last-child {
  color: #9ea0a5;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */

  @media screen and (max-width: 1000px) {
    font-size: 12px !important;
  }
}

.market-s-u-d > span:first-child {
  color: #9ea0a5;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
  justify-content: flex-end;
}
.market-s-u-d > span:last-child {
  color: #9ea0a5;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */

  @media screen and (max-width: 1000px) {
    font-size: 12px !important;
  }
}
.market-s-c-d > span {
  color: #6b6c6f;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}
.full-artical {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}
.full-artical div:first-child {
  display: flex;
  align-items: center;
  gap: 12px;
}
.full-artical div:first-child > span {
  color: #1665d8;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}
.full-artical > span {
  color: #9ea0a5;
  text-align: right;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
}
.market-s-d {
  margin-top: 24px;
  height: 44px;
  border-top: 1px solid #9ea0a5;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.like-dislike {
  display: flex;
  gap: 12px;
}
.market-s-d > span {
  color: #6b6c6f;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}
