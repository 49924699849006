.StandAloneBeartacticAI-loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
  background: linear-gradient(90deg, #ff6b6b, #6a82fb, #fc5c7d, #6fe7dd);
  background-size: 400% 400%;
  animation: gradientAnimation 30s linear infinite;
  width: 100%;
}

.thinking-text {
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
  text-align: center;
  animation: fadeIn 2s ease-in-out infinite alternate;
}

@keyframes fadeIn {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.StandAloneBeartacticAI-message {
  display: flex;
  align-items: flex-end;
}

.StandAloneBeartacticAI-user-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.StandAloneBeartacticAI-received {
  flex-direction: column;
}

.StandAloneBeartacticAI-received .StandAloneBeartacticAI-user-icon {
  margin-right: 10px;
}

.StandAloneBeartacticAI-received .StandAloneBeartacticAI-message-bubble {
  background: #7d55c7;
  color: #ffffff;
  border-radius: 15px 15px 15px 0;
  max-width: 70%;
  padding: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  line-height: 1.5;
}

.StandAloneBeartacticAI-sent {
  flex-direction: row-reverse;
}

.StandAloneBeartacticAI-sent .StandAloneBeartacticAI-user-icon {
  margin-left: 10px;
}

.StandAloneBeartacticAI-sent .StandAloneBeartacticAI-message-bubble {
  background-color: #8a4de8;
  color: white;
  border-radius: 15px 15px 0 15px;
  max-width: 70%;
  padding: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  line-height: 1.5;
}

.StandAloneBeartacticAI-chat-input {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  background-color: white;
  position: fixed;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #eaedf3;
}

.input-chat {
  flex-grow: 1;
  padding: 10px 15px;
  border-radius: 20px;
  margin-right: 10px;
  border: 1px solid #dcdcdc;
  font-size: 16px;
}

.input-chat:focus {
  outline: none;
  border: 1px solid #8a4de8;
}

.StandAloneBeartacticAI-send-btn {
  background: none;
  border: none;
  cursor: pointer;
}

.StandAloneBeartacticAI-send-btn img {
  width: 35px;
  height: 35px;
}

.StandAloneBeartacticAI-loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
  flex-direction: column;
  background-color: #ffffff;
  gap: 20px;
}

// Loader

.loader {
  display: inline-block;
  text-align: center;
  line-height: 86px;
  text-align: center;
  position: relative;
  padding: 0 48px;
  font-size: 48px;
  font-family: Arial, Helvetica, sans-serif;
  color: #fff;
}
.loader:before,
.loader:after {
  content: "";
  display: block;
  width: 15px;
  height: 15px;
  background: currentColor;
  position: absolute;
  animation: load 3s infinite alternate ease-in-out;
  top: 0;
}
.loader:after {
  top: auto;
  bottom: 0;
}
@keyframes load {
  0% {
    left: 0;
    height: 43px;
    width: 15px;
    transform: translateX(0);
  }
  50% {
    height: 10px;
    width: 40px;
  }
  100% {
    left: 100%;
    height: 43px;
    width: 15px;
    transform: translateX(-100%);
  }
}

.StandAloneBeartacticAI-gradient {
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #ff8a80, #e1bee7, #aed581, #ffcc80);
  background-size: 400% 400%;
  animation: gradientAnimation 30s linear infinite;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 100%;
  }
  50% {
    background-position: 100% 0%;
  }
  100% {
    background-position: 0% 100%;
  }
}

.StandAloneBeartacticAI-fade-in {
  opacity: 0;
  animation: fadeIn 1s ease-in-out forwards;
  height: 100%;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.recommendation {
  background-color: white;
  padding: 12px;
  margin-top: 10px;
  border-radius: 12px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  position: relative;
}

.three-dots {
  width: 16px;
  height: 16px;
  cursor: pointer;
  position: absolute;
  top: 12px;
  right: 12px;
}

.options-menu {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  background-color: #f9f6fc;
  padding: 10px;
  border-radius: 12px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 100%;
  right: -60%;
  z-index: 1000;
  width: 60%;
}

@media (max-width: 768px) {
  .options-menu {
    right: -50%;
    top: -15px;
  }
}

@media (max-width: 480px) {
  .options-menu {
    right: -30%;
    flex-wrap: wrap;
  }
}

.option-button {
  background: #fff;
  border: 1px solid #d6d6d6;
  border-radius: 16px;
  padding: 8px 16px;
  font-size: 14px;
  color: #888;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.option-button:hover {
  background: #ececec;
}

.option-button:focus {
  outline: none;
  border: 1px solid #8a4de8;
  background: #f5f3fc;
}

.option-button:first-child {
  color: white;
  background: #8a4de8;
  border: 1px solid #8a4de8;
}

.options_menu_one,
.options_menu_two {
  display: flex;
  flex: 1 1 50%;
}

@media (max-width: 768px) {
  .options-menu {
    right: 0;
  }
}

@media (max-width: 480px) {
  .options-menu {
    width: 100%;
    right: 0;
  }
  .options_menu_one,
  .options_menu_two {
    flex-direction: column;
  }
}

.StandAloneBeartacticAI_chat_chat-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  font-family: "Roboto", sans-serif;
}

.StandAloneBeartacticAI_chat-content {
  flex-grow: 1;
  overflow: hidden;
}

.user-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

// chat Input

.ChatLeft_chat-input {
  max-width: 930px;
  margin-left: 80px;
  display: flex;
  padding: 20px 20px 20px 30px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid #eaedf3;
  background: #fff;
  max-height: 76px;

  input {
    flex-grow: 1;
    padding: 0;
    margin-right: 10px;
    border: none;
    width: 100%;
    color: #3e3f42;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: left;

    &::placeholder {
      color: #3e3f42;
      font-family: Roboto;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  .send_button {
    margin-right: 10px;
  }

  .sendchat {
    background: none;
    border: 0;
    outline: 0;
    min-width: 36px;
    height: 36px;
  }
}

.ChatLeft_chat-input .recommendation .three-dots {
  position: absolute;
  top: 5px;
  right: -25px;
  cursor: pointer;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.Text_Inputforchat {
  border: 1px solid #ccc;
  padding: 10px;
  outline: none;
}

.Text_Inputforchat:focus {
  border: none;
}
.lilypads_logo_Container {
  padding: 9px;
  border-radius: 82px;
  background: #dbdbdb;
}

@keyframes fadeInEffect {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

// chat section

// Violet Background

// White Background for Recommendations
.recommendations-block {
  background-color: white;
  color: black;
  margin-top: 10px;
  padding: 10px;
  border-radius: 12px;
}

// Scroll Chat Scroll----------------------

.StandAloneBeartacticAI-chat-messages::-webkit-scrollbar {
  width: 8px;
}

.StandAloneBeartacticAI-chat-messages::-webkit-scrollbar-track {
  background: transparent;
}

.StandAloneBeartacticAI-chat-messages::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

.StandAloneBeartacticAI-chat-messages::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.StandAloneBeartacticAI-chat-messages::-webkit-scrollbar-thumb:vertical {
  border-radius: 10px;
}

.StandAloneBeartacticAI-chat-messages::-webkit-scrollbar-button {
  display: none;
}

// Regenarte Button ----------------

.right-side-buttons {
  position: absolute;
  right: 263px;
  bottom: 194px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.right-side-buttons button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: #f0f0f0;
  }
}

.regen-btn {
  background-color: #4caf50;
  color: white;
}

.like-btn {
  background-color: #008cba;
  color: white;
}

.dislike-btn {
  background-color: #f44336;
  color: white;
}

// like message  -
.StandAloneBeartacticAI_message {
  position: relative;
  margin-bottom: 20px;
  display: flex;
  align-items: baseline;
  gap: 15px;
  max-width: 930px;

  .message-bubble {
    max-width: 70%;
    padding: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    word-break: break-word;
  }

  &.received {
    flex-direction: column;
    &::before {
      content: "";
      position: absolute;
      top: 14px;
      left: 0px;
      width: 20px;
      height: 20px;
      background-image: url("../../assets/chatCompAssets/icons/Vector.svg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  &.sent {
    flex-direction: column;
    align-items: end;
    position: relative;
  }

  &.sent .message-bubble {
    border-radius: 30px 0px 30px 30px;
    background: #efefef;
    &::before {
      content: "";
      position: absolute;
      top: 35px;
      right: 0px;
      width: 20px;
      height: 20px;
      background-image: url("../../assets/chatCompAssets/icons/VectorR.svg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }

    p {
      color: #3e3f42;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 19px;
    }
  }

  .right-side-buttons {
    position: absolute;
    bottom: 0px;
    right: -50px;
    display: flex;
    gap: 10px;
    margin-top: 10px;

    button {
      border-radius: 14px;
      background: #776d87;
      display: flex;
      width: 26px;
      height: 26px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      cursor: pointer;
      padding: 0;
    }

    // .regen-btn:hover {
    //   background-color: #f0f8ff;
    // }

    // .like-btn:hover {
    //   background-color: #dff0d8;
    // }

    // .dislike-btn:hover {
    //   background-color: #f2dede;
    // }
  }

  &.received {
    .message-bubble {
      padding: 20px;
      border-radius: 8px;
      max-width: 60%;
      position: relative;
      border-radius: 0px 30px 30px 30px;
      color: white;
      font-family: Roboto;
      font-size: 14px;
      font-weight: 400;
      line-height: 19px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;

      &.purple-background {
        background: #7d55c7;
        color: white;
        position: relative;
        overflow: hidden;
      }
    }
  }
}

.ChatLeft_chat_details p {
  color: #8a8c8e;
  text-align: start;
  font-family: Roboto;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  position: relative;
  left: 88px;

  span {
    color: #8a8c8e;
    text-align: center;
    font-family: Roboto;
    font-size: 12px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
  }
}

//typing - chat

.typing-indicator {
  display: flex;
}

.typing-dots {
  display: flex;
  gap: 4px;
}

.typing-dots span {
  width: 8px;
  height: 8px;
  background-color: #ffffff;
  border-radius: 50%;
  opacity: 0.5;
  animation: typing-dots 1.5s infinite;
}

.typing-dots span:nth-child(1) {
  animation-delay: 0s;
}

.typing-dots span:nth-child(2) {
  animation-delay: 0.3s;
}

.typing-dots span:nth-child(3) {
  animation-delay: 0.6s;
}

@keyframes typing-dots {
  0%,
  100% {
    opacity: 0.5;
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(1.2);
  }
}

.rest_text_data {
  font-family: "Arial", sans-serif;
  font-size: 14px;
  line-height: 1.6;

  p > strong {
    display: block;
    font-weight: bold;
    font-size: 18px;
    margin-top: 20px;
    margin-bottom: 10px;
    color: #f2f2f2;
  }

  p {
    margin: 10px 0;
    color: #ffffff;
    word-wrap: break-word;
  }

  ul,
  ol {
    margin: 10px 0 20px;
  }

  li {
    margin-bottom: 10px;
    color: #ffffff;
  }

  blockquote {
    margin: 15px 0;
    padding: 10px 15px;
    border-left: 4px solid #6c63ff; 
    background-color: rgba(255, 255, 255, 0.1);
    color: #ddd;
    font-style: italic;
    border-radius: 5px;
  }
}
