.document_wrapper {
  margin-left: 40px;
  display: grid;
  grid-template-columns: 67% 28%;
  grid-gap: 20px;
  margin-top: 20px;

  @media screen and (max-width: 1200px) {
    grid-template-columns: 96%;
  }
  .lower {
    max-width: calc(100vw - 240px);
    overflow: auto;
  }

  .upper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    h2 {
      text-wrap: nowrap;
    }
  }

  .upper_right {
    display: flex;
    justify-content: flex-end !important;
  }

  button {
    background: linear-gradient(#f6f7f9, #ffffff);
    outline: none;
    border-radius: 4px;
    border: 1px solid #d8dce6;
    display: flex;
    justify-content: center;
    gap: 10px;
    padding: 8px 11px;
    align-items: center;
    height: 38px;
    flex-shrink: 0;
    color: #3e3f42;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }

  .col_first {
    min-width: 300px;
  }
  .first {
    min-width: 287px;
    margin-left: 13px;
  }

  .text_wrapper {
    img {
      width: 20px !important;
      height: 20px !important;
      flex-shrink: 0;
    }
  }

  .checkbox {
    padding-left: 0;
  }

  .document_right {
    padding: 0 30px 30px 30px;
    border: 1px solid #eaedf3;
    filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.04));
    .right_header {
      height: 74px;
      border-bottom: 1px solid #eaedf3;
      display: flex;
      align-items: center;
      h2 {
        color: #3e3f42;
        font-family: Roboto;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
      }
    }

    .search_wrapper {
      padding: 30px 0;
      border-bottom: 1px solid #eaedf3;
    }
    .search_bar {
      position: relative;
      width: 240px;
      height: 38px;
      border: 2px solid #eaedf3;
      border-radius: 5px;
      display: flex;
      align-items: center;

      input {
        all: unset;
        width: 220px;
        padding-right: 25px;
        padding-left: 2px;
        color: #9ea0a5;
        font-family: Roboto;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
      }

      img {
        position: absolute;
        right: 5px;
      }
    }

    .category_wrapper {
      border-bottom: 1px solid #eaedf3;
      img {
        height: 20px;
        width: 20px;
      }
    }

    .date_wrapper {
      border-top: 1px solid #eaedf3;
      border-bottom: 1px solid #eaedf3;
      padding-bottom: 30px;
    }
    .heading_wrapper {
      display: flex;
      align-items: center;
      gap: 10px;
      justify-content: space-between;
      height: 60px;
      svg {
        height: 24px;
        width: 24px;
      }
      p {
        color: #3e3f42;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px; /* 157.143% */
      }
    }

    .checkbox {
      height: 50px;
      p {
        color: #3e3f42;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px; /* 157.143% */
      }
    }
  }
}
