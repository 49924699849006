h1 {
  color: #3e3f42;
  leading-trim: both;
  text-edge: cap;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}

.DashBoard-Conatiner {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.dashboard_container {
  margin-top: 10px;
  margin-bottom: 10px;
  color: var(--Black, #3e3f42);
  leading-trim: both;
  text-edge: cap;
  font-family: Roboto;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

.dashboard_header h1 {
  color: #3e3f42;
  leading-trim: both;
  text-edge: cap;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 155.556% */
}

.dashboard_box {
  border: 1px solid var(--Primary-Purple-50, #eaedf3);
  // background: var(--White, #fff);
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.dashboard_data {
  border: 1px solid var(--Primary-Purple-50, #eaedf3);
}

.dashboard_data h1 {
  font-size: 20px;
  width: fit-content;
  padding-top: 10px;
  padding-bottom: 15px;
  padding-left: 15px;
  align-items: center;
}
.dashboard_contentdata {
  width: 85%;
  margin: 15px;
  font-size: 12px;
  color: var(--Primary-Gray-200, #9ea0a5);
  leading-trim: both;
  text-edge: cap;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}

.dashboard_contentdata span {
  color: var(--Primary-Purple-500, #7d55c7);
  leading-trim: both;
  text-edge: cap;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}
.dashboard_button {
  padding-top: 30px;
  margin-top: 35px;
  padding: 20px;
}

.dashboard_header_data {
  display: flex;
  gap: 30px;
}

.second-box {
  background-color: #7d55c7;
  padding: 10px;
  display: flex;
  width: 530px;
  padding: 19px 20px 21px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  border: 1px solid var(--Primary-Purple-50, #eaedf3);
  background: var(--White, #fff);
}

.second-box-header h1 {
  color: #3e3f42;
  margin-top: 10px;
  leading-trim: both;
  text-edge: cap;
  font-family: Roboto;
  margin-left: 10px;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  letter-spacing: 0.072px;
}

.color {
  width: 100%;
  border: 1px solid var(--Primary-Purple-50, #eaedf3);
  width: 100%;
}

.second-box-number {
  display: flex;
  justify-content: space-between;
  margin: 10px;
}

.second-box-number h1 {
  color: var(--Text-Primary, #7d55c7);
  leading-trim: both;
  text-edge: cap;
  font-family: Roboto;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px; /* 110% */
  letter-spacing: -0.8px;
}
.second-box-number button {
  display: flex;
  height: 50px;
  padding: 2.852px 11.41px 2.852px 14.262px;
  justify-content: center;
  align-items: center;
  gap: 5.705px;
  border: none;
  border-radius: 16px;
  background: var(--Success-50, #ecfdf3);
}
.second-box-container {
  padding: 10px;
  width: 100%;
  padding: 19px 20px 21px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  border: 1px solid var(--Primary-Purple-50, #eaedf3);
  background: var(--White, #fff);
}
.second-box-container-data h1 {
  margin-top: 5px;
  color: var(--Black, #3e3f42);
  leading-trim: both;
  text-edge: cap;
  font-family: Roboto;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px; /* 110% */
  letter-spacing: -0.8px;
}
.second-box-container-data button {
  display: flex;
  height: 50px;
  padding: 2.852px 11.41px 2.852px 14.262px;
  justify-content: center;
  align-items: center;
  gap: 5.705px;
  border: none;
  border-radius: 16px;
  background: var(--Success-50, #ecfdf3);
}

.second-box-container-data {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

// Grid box

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.grid-item {
  padding: 10px;
  border: 1px solid var(--Primary-Purple-50, #eaedf3);
  flex-direction: column;
  gap: 30px;
}
.grid-item-data span {
  color: #3E3F42;
  leading-trim: both;
  text-edge: cap;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  width: 60%;
  line-height: 26px; /* 144.444% */
}

.grid-item-button button {
  display: flex;
  width: 304px;
  height: 38px;
  padding: 0px 89px 0px 77px;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #e2e5ed;
}
.grid-item-data-details {
  color: var(--Primary-Gray-200, #9ea0a5);
  leading-trim: both;
  height: 105px;
  text-edge: cap;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin-top: 20px;
  line-height: 22px;
}
.grid-item-data-details p{
  color: var(--Primary-Gray-200, #9EA0A5);
  leading-trim: both;
  text-edge: cap;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 157.143% */
}

.grid-item-data {
  display: flex;
  justify-content: space-between;
}

.DashBoard-Conatiner {
  // Other styles

  .grid-item-0 {
    background: var(--Action-Yellow, #ffc700);
    width: 50px;
    height: 50px;
    border-radius: 4px;
  }

  .grid-item-1 {
    border-radius: 4px;
    background: var(--Action-Green, #50cd89);
    width: 50px;
    height: 50px;
    flex-shrink: 0;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .grid-item-2 {
    border-radius: 4px;
    background: var(--Action-Red, #eb5757);
    width: 46px;
    height: 46px;
    flex-shrink: 0;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .grid-item-3 {
    border-radius: 4px;
    background: var(--Action-Gray, #bcc4d0);
    width: 46px;
    height: 46px;
    flex-shrink: 0;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .grid-item-4 {
    border-radius: 4px;
    background: var(--Action-Blue, #1665d8);
    width: 46px;
    height: 46px;
    flex-shrink: 0;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .grid-item-5 {
    border-radius: 4px;
    background: var(--Action-Orenge, #ed962d);
    width: 46px;
    height: 46px;
    flex-shrink: 0;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    display: flex;
  }
}

.Dashboard-Manage {
  padding: 0;
  margin: 0;
  height: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  border: none;
  color: white;

  border-radius: 4px;
  background: linear-gradient(180deg, #7D55C7 0%, #6C46BB 100%);
}
.Dashboard-Share {
  margin: 0;
  height: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  border: none;
  color: black;
  background-color: white;
  border-radius: 4px;
  border: 1px solid var(--Primary-Purple-50, #eaedf3);
  background: #fff;
}

@media screen and (max-width: 1000px) {
  .dashboard_header_data {
    flex-direction: column;
  }
  .second-box {
    width: 100%;
  }
}
