.summary {
    display: flex;
    flex-direction: column;
    gap: 30px;

    .summary_upper {
        display: flex;
        flex-direction: column;
        gap: 0;
        border: 1px solid #EAEDF3;
        height: 640px;
        padding: 0 20px;

        .upper_header {
            display: flex;
            align-items: center;
            height: 73px;
            color: #3E3F42;
            font-family: Roboto;
            font-size: 18px;
            font-weight: 500;
            line-height: 28px;
            border-bottom: 1px solid #EAEDF3;
        }

        .upper_info {
            display: flex;
            flex-direction: column;
            gap: 10px;

            span {
                color: #9EA0A5;
                font-family: Roboto;
                font-size: 12px;
                font-weight: 500;
                line-height: 18px;
                text-transform: uppercase
            }

            p {
                color: #6B6C6F;
                font-family: Roboto;
                font-size: 14px;
                font-weight: 400;
                line-height: 22px
            }

            .tag {
                color: #9EA0A5;
                font-family: Roboto;
                font-size: 12px;
                font-weight: 500;
                text-transform: uppercase;
            }

            .tags_wrapper {
                display: flex;
                align-items: center;
                gap: 10px;
            }


            .tags {
                color: #FFF;
                text-align: center;
                font-family: Roboto;
                font-size: 12px;
                font-weight: 500;
                text-transform: uppercase;
                padding: 4px 8px;
                border-radius: 4px;

            }

            .tags.green {
                background: #7EC331;
            }

            .tags.blue {
                background: linear-gradient(0deg, #1665D8 2.22%, #1F6FE5 98.44%)
            }

            .tags.orange {
                background-color: #ED962D;
            }

            .contacts {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 90%;
                margin-top: 20px;

                .left,
                .right {
                    display: flex;
                    flex-direction: column;
                    gap: 12px;
                }

                p {
                    color: #3E3F42;
                    font-family: Roboto;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 22px
                }

                .right span {
                    color: #9EA0A5;
                    font-family: Roboto;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 22px;
                    text-transform: none;
                }


            }
        }

   
        .upper_info_box {
            display: flex;
            justify-content: space-between;
            align-items: center;

            h3 {
                color: #3E3F42;
                font-family: Roboto;
                font-size: 24px;
                font-weight: 400;

            }

            span {
                color: #9EA0A5;
                font-family: Roboto;
                font-size: 12px;
                font-weight: 500;
                text-transform: uppercase;
            }
        }
        hr {
            border: 1px solid #EAEDF3;
            margin: 20px 0;
        }
    

    }

    .summary_lower {
        height: 393px;
        display: flex;
        flex-direction: column;
        gap: 0;
        border: 1px solid #EAEDF3;
        filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.04));

        .lower_header {
            display: flex;
            flex-direction: column;
            height: 73px;
            justify-content: center;
            padding: 20px;
            border-bottom: 1px solid #EAEDF3;

            h2 {
                color: #3E3F42;
                font-family: Roboto;
                font-size: 18px;
                font-weight: 500;
                line-height: 28px;
            }

            p {
                color: #9EA0A5;
                font-family: Roboto;
                font-size: 14px;
                font-weight: 400;
            }
        }

        .lower_info {
            display: flex;
            justify-content: space-between;
            gap: 30px;
            padding: 20px;

            h3 {
                color: #3E3F42;
                font-family: Roboto;
                font-size: 14px;
                font-weight: 500;
                line-height: 22px;
            }

            p {
                color: #3E3F42;
                font-family: Roboto;
                font-size: 14px;
                font-weight: 400;
                line-height: 22px
            }

            .left {
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 20px;
                .left_info {
                    width: 100%;
                    min-height: 124px;

                    div {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    }
                }
            }

            .info_wrapper {
                display: flex;
                flex-direction: column;
                gap: 8px;
            }

            .right {
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 20px;
                .right_info {
                    width: 100%;
                    min-height: 126px;

                    div {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    }
                }
            }
        }
        hr {
            border: 1px solid #EAEDF3;
            margin: 10px 0;
        }
    

    }

 
}