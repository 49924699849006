.loan-setup-nav {
  width: 810px !important;
}
.deals-room-nav {
  width: 810px;
}
.SetupLoanReq-deals-room-nav {
  height: 47px;
  display: flex;
  gap: 25px;
  margin-top: 20px;
  border-bottom: 1px solid var(--LP-very-light--_lib, #eaedf3);
  @media screen and (max-width: 900px) {
    margin-top: 40px;
  }
  span{
    color: var(--LP-mid-grey-_lib, #6b6c6f);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    text-transform: capitalize;
    text-wrap: nowrap;
    cursor: pointer;
  }
}

.general-container {
  height: 361px;
}

.sp {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 60px;
  height: 400px;

  .sp_text {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }

  .sp_btns {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
}

// .loan-req-container {
//   display: block !important;
//   @media screen and (max-width: 900px) {
//     margin-left: -137px;
//   }
// }

.setup-general {
  padding: 0 30px;
  box-sizing: border-box;
}

.genral-input {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  // div {
  //   width: 320px;
  //   // margin: 0 auto;
  // }
}

.link-inp {
  margin-left: 30px;
  margin-right: 30px;
}

.general-size {
  width: 320px;
}

.loan-details-container > div {
  margin-top: 30px;
}

.loan-details-inner {
  padding: 0 30px 30px 30px;
}

.loan-req-in-first {
  display: flex;
  gap: 30px;
  height: 162px;
}

.loan-req-in-first > div:first-child {
  width: 320px;
  // height: 162px;
  flex-shrink: 0;
}

.loan-req-in-first > div:last-child {
  display: flex;
  align-items: center;
}

.loan-req-in {
  padding-bottom: 0 !important;
  padding-left: 0 !important;
}

.loan-req-in-second {
  display: flex;
  gap: 30px;
}

.loan-req-in-second > div > span {
  color: #9ea0a5;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */
}

.input-box {
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 18px;
}

.input-box > label {
  color: #9ea0a5;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  /* 150% */
  text-transform: uppercase;
  margin-bottom: 7px;
}

.input-box > div {
  margin-top: 7px;
  display: flex;
  // width: 320px;
  height: 38px;
  // justify-content: space-between;

  align-items: center;
  border: 1px solid #eaedf3;
  border-radius: 4px;
  padding: 0 16px;
}

.area-box > div {
  min-height: 67px !important;
}

.input-box div > input {
  outline: none;
  border: none;
  padding: 9px;
  width: 100%;
}

.input-box div > textarea {
  outline: none;
  border: none;
  // padding: 9px;
  width: 100%;
  height: 100%;
  resize: none;
}

.slider-para {
  color: #9ea0a5;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  /* 150% */
  text-transform: uppercase;
  margin-top: 40px;
}

.input-box > div > span {
  color: #9ea0a5;
  text-align: right;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */
}

.inp-w {
  width: 320px;
}

.loan-req-in-third {
  display: flex;
  align-items: center;
  gap: 30px;
}

.loan-req-in-third > div {
  width: 320px;
}

.lender {
  display: flex;
  align-items: center;
  gap: 15px;
}

.lender > span {
  color: var(--LP-Pantone, #7d55c7);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.lender > span > span {
  color: var(--LP-Pantone, #7d55c7);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  /* 19.6px */
}

.texta-op {
  color: var(--LP-grey-_lib, #9ea0a5);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */
  margin-bottom: 30px;
}

.loan-d-i {
  display: flex;
  gap: 30px;
  margin-top: 20px;
}

.loan-d-i > div:first-child {
  width: 320px;
}

.add-fin {
  width: 730px;
  height: 198px !important;
  flex-shrink: 0;
}

.upload-doc {
  display: block;
  margin: 30px 0;
  color: var(--LP-gray, #9ea0a5);
  font-family: Roboto;
  font-size: 26px;
  font-style: normal;
  font-weight: 300;
  line-height: 38px;
  /* 146.154% */
}

.upload-doc-body {
  padding: 30px;
}

.select-doc {
  width: 670px;
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-radius: 4px;
  border: 1px dashed #e2e5ed;
  background: #fcfcfc;
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.05) inset;
}

.select-doc p {
  color: #9ea0a5;
  font-family: Roboto;
  font-size: 14px;
  line-height: 22px;
}

.select-doc span {
  text-decoration-line: underline;
}

.progress-cont {
  width: 670px;
  padding: 16px 22px;
  border-radius: 4px;
  margin-top: 30px;
  border: 1px solid #eaedf3;
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.04));
  display: flex;
  gap: 25px;
  align-items: center;
  box-sizing: border-box;
  justify-content: space-between;
}

.uploading-file {
  width: 570px;
}

.file-name {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.file-name > div {
  display: flex;
  gap: 15px;
}

.file-name > div span {
  color: #3e3f42;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}

.file-name span {
  color: #9ea0a5;
  text-align: right;
  font-family: Roboto;
  font-size: 12px;
  line-height: 18px;
}

.uploading-file .progress-bar {
  width: 570px;
  height: 4px;
  margin-top: 12px;
  border: 1px solid #eaedf3;
  border-radius: 5px;
}

.progress-bar .progress {
  width: 200px;
  height: 100%;
  border-radius: 2px;
  background: var(--purple-pantone-2665-c-71, rgba(125, 85, 199, 0.71));
  display: block;
}

// ---------------lender ------------
// ----------------------------------

.lender-inner {
  padding: 20px 30px;
}

.lender-inner > span:first-child {
  color: #3e3f42;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
}

.lender-inner > span:nth-child(2) {
  color: #9ea0a5;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */
  display: block;
}

.l-i-i {
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.lender-last {
  padding: 20px 0px;
}

.lender-last > span {
  color: #3e3f42;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
}

.key-deal-btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 30px;
}

.loan-details-inner {
  border-bottom: 1px solid #eaedf3;
}

.key-d-add {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 30px;
}

.key-d-add > button {
  display: flex;
  align-items: center;
  gap: 10px;
  background: none;
  border: none;

  p {
    color: var(--purple-pantone-2665-c, #7d55c7);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    /* 157.143% */
  }
}

.key-d-add button > span {
  color: #3e3f42;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */
}

.key-deal {
  margin: 0 auto;
}

.lender-table-wrapper {
  .lender-table-heading {
    padding: 15px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .search-btn {
      width: 273px;
      height: 38px;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      gap: 12px;
      border-radius: 4px;
      border: 1px solid var(--LP-very-light--_lib, #eaedf3);
      background: #fff;
      box-shadow: 0px 1px 2px 0px rgba(102, 113, 123, 0.21) inset;
      padding-left: 12px;

      input {
        border: none;
        outline: none;
        padding: 9px;
      }
    }

    .filter-btn {
      display: inline-flex;
      padding: 8px 11px;
      border-radius: 4px;
      border: 1px solid #d8dce6;
      background: linear-gradient(0deg, #f6f7f9 0%, #fff 100%);
      box-shadow: 0px 1px 1px 0px rgba(22, 29, 37, 0.05), 0px 2px 0px 0px rgba(255, 255, 255, 0.05) inset;
      display: flex;
      align-items: center;
      gap: 10px;

      span {
        color: #3e3f42;
        text-align: center;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        /* 157.143% */
      }
    }
  }

  .lender-table {
    .lender-table-header {
      width: 730px;
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      border-top: 1px solid #9ea0a5;
      border-bottom: 1px solid #9ea0a5;
      flex-shrink: 0;

      > div > span {
        color: #9ea0a5;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        /* 150% */
        text-transform: uppercase;
      }
    }

    .lender-table-main {
      height: 71px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      border-bottom: 1px solid #9ea0a5;
    }

    .img-wrapper {
      display: flex;
      align-items: center;
      gap: 16px;

      div > img {
        border-radius: 50%;
      }

      div > span {
        display: block;
      }

      div > span:first-child {
        color: #3e3f42;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        /* 157.143% */
      }

      div > span:last-child {
        color: #9ea0a5;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        /* 150% */
      }
    }

    .apr {
      color: #3e3f42;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      /* 157.143% */
    }

    .term {
      span {
        display: block;
      }

      > span:first-child {
        color: #3e3f42;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        /* 14px */
      }

      > span :last-child {
        color: var(--LP-mid-grey-_lib, #6b6c6f);
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
        /* 12px */
      }
    }

    .max-loan {
      color: #3e3f42;
      text-align: right;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      /* 157.143% */
    }

    .match {
      display: flex;
      gap: 10px;
    }

    .view {
      display: flex;
      padding: 8px 11px;
      align-items: center;
      gap: 10px;
      border-radius: 4px;
      border: 1px solid #d8dce6;
      background: linear-gradient(0deg, #f6f7f9 0%, #fff 100%);
      box-shadow: 0px 1px 1px 0px rgba(22, 29, 37, 0.05), 0px 2px 0px 0px rgba(255, 255, 255, 0.05) inset;
    }

    .center {
      justify-content: center;
    }
  }
}

.lenders-email {
  width: 670px;
  height: 68px;

  border-radius: 4px;
  border: 1px solid #e2e5ed;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(102, 113, 123, 0.21) inset;
  padding: 8px;

  div {
    border-radius: 4px;
    background: rgba(255, 255, 255, 0);
    display: flex;
    align-items: center;
    gap: 8px;
    padding-left: 10px;
    color: #3e3f42;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    /* 157.143% */
    border: 1px solid #9ea0a5;
    width: 184px;
    height: 24px;
    flex-shrink: 0;
  }
}

.input-box div > textarea::placeholder {
  margin-top: 5px;
  padding-top: 10px;
  color: var(--LP-grey-_lib, #9ea0a5);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */
}

.remove-pt-btn {
  display: inline-flex;
  padding: 8px 11px;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  border: 1px solid #d8dce6;
  background: var(--eaeaea-background-grey, #eaeaea);
  box-shadow: 0px 1px 1px 0px rgba(22, 29, 37, 0.05), 0px 2px 0px 0px rgba(255, 255, 255, 0.05) inset;

  > span {
    color: #3e3f42;
    text-align: center;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    /* 157.143% */
  }
}

.deal-main-wrapper {
  width: 612px;
  // height: 282px;
  display: flex;
  // align-items: center;
  flex-direction: column;
  padding: 30px;

  .budget {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 38px;
    width: 38px;
    border-radius: 100px;
    background: var(--LP-very-light, #eaedf3);
    margin-top: 28px;
  }

  div:first-child {
    // padding: 8px 13px 8px 14px;

    span {
      color: var(--LP-mid-gray, #6b6c6f) !important;
      text-align: center !important;
      font-family: Roboto !important;
      font-size: 18px !important;
      font-style: normal !important;
      font-weight: 400 !important;
      line-height: 22px !important;
      /* 122.222% */
    }
  }

  .loan-details-inner {
    width: 100%;
  }
}

.save-btn {
  margin-top: 0;
  padding-left: 10px;
  padding-right: 10px;
  align-items: center;
}

.loan-container {
  padding-bottom: 30px;
}

.financial-container {
  width: 730px;
  fill: #fff;
  stroke-width: 1px;
  stroke: #eaedf3;
  border: 1px solid #eaedf3;
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.04));
  margin-top: 30px;
  overflow: auto;

  @media screen and (max-width: 1440px) {
    width: 640px !important;
    margin: auto;
    margin-top: 30px;

    input {
      width: 75% !important;
    }

    select {
      width: 75% !important;
    }
  }

  @media screen and (max-width: 900px) {
    width: 540px !important;
    margin: 0;
    margin-top: 20px;
    input {
      width: 52% !important;
    }
  }
}

.key_deal-container {
  width: 730px;
  fill: #fff;
  stroke-width: 1px;
  stroke: #eaedf3;
  border: 1px solid #eaedf3;
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.04));
  margin-top: 30px;

  @media screen and (max-width: 1440px) {
    width: 640px !important;
    margin: auto;
    margin-top: 30px;

    input {
      width: 90% !important;

      @media screen and (max-width: 900px) {
        width: 75% !important;
      }
    }
  }
  @media screen and (max-width: 900px) {
    width: 490px !important;
  }
}

.browwer {
  padding: 0 30px;
}

.borrower-container {
  display: flex;
  justify-content: center;
}

.borro {
  display: flex;
  justify-content: space-between;
  padding-right: 30px;

  div {
    display: flex;
    gap: 10px;
    align-items: center;
  }
}

.b-i {
  align-items: flex-start !important;

  > div > span {
    color: var(--LP-grey-_lib, #9ea0a5);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    /* 157.143% */
  }
}

.team-wrapper {
  margin-top: 30px;

  > span {
    color: #3e3f42;
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    /* 122.222% */
  }

  .team-wrapper-inner {
    display: flex;
    gap: 30px;
    margin-top: 12px;

    .team-wrapper-inner-left {
      margin-top: 28px;
    }

    .team-wrapper-inner-right {
      width: 100%;

      .inp-we {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .team-input {
        width: 248px;
      }
    }
  }
}

.contact-body {
  display: block;
  margin: 18px 30px 30px 30px;
  // padding: 30px;
  padding-bottom: 0;

  .contact-header {
    text-align: center;
    color: var(--LP-black, #3e3f42);

    /* Text/Medium 14px */
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    justify-content: center;
    margin-bottom: 19px;
  }

  .additional-contact {
    display: flex;
    align-items: flex-end;
    justify-items: flex-end;
    justify-content: flex-end;
    gap: 255px;
    margin-top: 30px;
    margin-bottom: 19px;
  }

  .add-phone {
    display: flex;
    align-items: center;
    gap: 19px;
  }
}

.team-body {
  display: block;
}

.add-btn-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  .add-contact {
    width: 134px;
    height: 38px;
    flex-shrink: 0;
    fill: #fff;
    stroke-width: 1px;
    stroke: #eaedf3;
    filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.04));
    display: flex;
    align-items: center;
    gap: 9px;
    border: 1px solid #d8dce6;
    padding: 8px 11px;
    border-radius: 5px;

    span {
      color: #3e3f42;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      /* 157.143% */
    }
  }
}

// Button header
.header-button-SetupLoanReq {
  // display: flex;
  // padding: 8px 11px;
  // border-radius: 4px;
  // border: 1px solid #d8dce6;
  // background: linear-gradient(0deg, #f6f7f9 0%, #fff 100%);
  // box-shadow: 0px 1px 1px 0px rgba(22, 29, 37, 0.05), 0px 2px 0px 0px rgba(255, 255, 255, 0.05) inset;
  // margin: 10px;
  // align-items: center;
  // justify-content: space-around;
  // gap: 10px;
  // cursor: pointer;
  height: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  border: none;
  color: black;
  align-items: center;
  margin-top: 10px;
  width: 230px;
  gap: 10px;
  border-radius: 4px;
  border: 1px solid var(--Primary-Purple-50, #eaedf3);
  background: linear-gradient(0deg, #f6f7f9 0%, #fff 100%);
}
.header-button-SetupLoanReq > p {
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
}

// button Header

.SetupLoanReq-header-button-first {
  display: flex;
  padding: 8px 11px;
  border-radius: 4px;
  border: 1px solid var(--red-pantone-1797-c-72, rgba(203, 51, 59, 0.72));
  background: linear-gradient(0deg, #f6f7f9 0%, #fff 100%);
  box-shadow: 0px 1px 1px 0px rgba(22, 29, 37, 0.05), 0px 2px 0px 0px rgba(255, 255, 255, 0.05) inset;
  margin: 10px;
  align-items: center;
  justify-content: space-around;
  gap: 10px;
  cursor: pointer;
}

.header-button-first p {
  color: var(--Black, #3e3f42);
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 155.556% */
}

.SetupLoanReq-deals-room-center-header {
  height: 67px;
  background: #fff;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.04);
  display: flex;
  align-items: center;
  // position: sticky;
  // top: 0px;
  justify-content: space-between;
  // z-index: 100;
  @media screen and (max-width: 1300px) {
    width: 80%;
  }
  @media screen and (max-width: 1100px) {
    width: 76%;
  }
  // @media screen and (max-width: 900px) {
  //   width: 44%;
  //   flex-direction: column;
  // }
  // @media screen and (max-width: 700px) {
  //   width: 44%;
  //   flex-direction: column;
  // }

  > p {
    color: var(--LP-black, #3e3f42);
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px; /* 150% */
    margin: 22px 0 14px 0;
  }
}

.SetupLoanReq-deals-room-header-button {
  display: flex;
  gap: 10px;

}

.header-SetupLoanReq-navigate {
  height: 47px;
  display: flex;
  gap: 25px;
  margin-top: 20px;
  border-bottom: 1px solid var(--LP-very-light--_lib, #eaedf3);
}
