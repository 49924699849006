.background-color {
  padding: 20px;
  border: 1px solid var(--Primary-Purple-50, #eaedf3);
  background: var(--White, #fff);

  /* Shadow */
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.15);
}
.Boardconsents-Container-Header {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.Container-Header-data {
  display: flex;
  justify-content: space-between;
}
.Container-Header-button {
  display: flex;
  gap: 20px;
}
.Container-Header-button button {
  padding: 10px;
}
.Container-Header-meeting {
}
.Container-Boardconsents-time {
  display: flex;
  gap: 10px;
  align-items: center;
}
.Container-Boardconsents-Team-member {
  display: flex;
  align-items: center;
  gap: 10px;
}
.Boardconsents-Header {
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
}

.Conatiner-Boardconsents-box {
  border: 1px solid #eaedf3;
  width: 35%;
  height: fit-content;
}
.Conatiner-Boardconsents-box p {
  color: var(--primary-gray-100-text, #9ea0a5);
  leading-trim: both;
  text-edge: cap;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}
.Conatiner-Boardconsents-data h1 {
  width: fit-content;
}

.Conatiner-Boardconsents-data {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media screen and (max-width: 1400px) {
    flex-direction: column;
    width: fit-content;
  }
}
.Conatiner-Boardconsents-data-button {
  display: flex;
  gap: 20px;
  width: fit-content;
}

// box

.Boardconsents-rules-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 20px;
  margin-top: 20px;
}

.Boardconsents-rules-container-data {
  border: 1px solid var(--Primary-Purple-50, #eaedf3);
}
.Boardconsents-rules-container-second {
  border: 1px solid var(--Primary-Purple-50, #eaedf3);
}

.Boardconsents-rules-container-thid {
  border: 1px solid var(--Primary-Purple-50, #eaedf3);
}
.Boardconsents-rules-container-datafirst {
  padding: 20px;
  text-align: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
.Boardconsents-rules-container-secondbox {
  padding: 20px;
  text-align: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
.Boardconsents-rules-container-thiddata {
  padding: 20px;
  text-align: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

// table data

.Boardconsents-Header {
  table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #ccc;
  }

  th {
    padding: 8px;
    text-align: left;
  }
  td {
    padding: 8px;
    text-align: left;
  }

  tr:nth-child(even) {
  }
}

.Boardconsents-Container-Header-data {
  display: flex;
  gap: 30px;
}
.Boardconsents-text {
  display: flex;
  justify-content: space-between;
}
.Boardconsents-table-header {
  display: flex;
  padding: 10px;
  justify-content: space-between;
}
.Boardconsents-GENERAL {
  padding: 0;
  margin: 0;
  height: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  width: 150px;
  border: none;
  color: black;
  background-color: white;
  border-radius: 4px;
  background: rgba(125, 85, 199, 0.07);
}

.Boardconsents-USER {
  padding: 0;
  margin: 0;
  height: 40px;
  align-items: center;
  display: flex;
  width: 200px;
  justify-content: center;
  border: none;
  color: var(--primary-gray-100-text, #9ea0a5);
  background-color: white;
  border-radius: 4px;
  border: 1px solid var(--Primary-Purple-50, #eaedf3);
  background: #fff;
}
.Boardconsents-table-header {
  color: var(--Black, #3e3f42);
  leading-trim: both;
  text-edge: cap;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 128.571% */
  text-transform: uppercase;
}
.Boardconsents-rules-container-datafirst span {
  color: var(--Primary-Gray-200, #9ea0a5);
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 157.143% */
}

.Boardconsents-rules-container-secondbox span {
  color: var(--Primary-Gray-200, #9ea0a5);
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 157.143% */
}

.Boardconsents-rules-container-thiddata span {
  color: var(--Primary-Gray-200, #9ea0a5);
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 157.143% */
}

.Investorupdates-Schedule {
  padding: 0;
  margin: 0;
  height: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  border: none;
  width: 150px;
  color: black;
  background-color: white;
  border-radius: 4px;
  border: 1px solid var(--Primary-Purple-50, #eaedf3);
  background: #fff;
}
