/* ReactTable.scss */

.Table-head-data {
  border-collapse: collapse;
  width: 90%;
}

.header-box {
  border: 1px solid #eceef4;
  background-color: lightgray; 
}
.Table-head-data {
  border: 1px solid #eceef4;
  padding: 0;
}

.Table-head-data th {
  padding: 8px;
  color: #9ea0a5;
  leading-trim: both;
  text-edge: cap;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
  text-transform: uppercase;
  border-bottom: 1px solid #eceef4;
}

.Table-head-data td {
  padding: 8px;
  text-align: center; /* Align data in cells to the center */
  color: var(--Black, #3e3f42);
  leading-trim: both;
  text-edge: cap;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
  border-bottom: 1px solid #eceef4;
  padding-top: 10px;
}

.cell-box {
  border: 1px solid #eceef4; /* Add border to cell boxes */
  text-align: center; /* Align data in cell boxes to the center */
}

.table-container {
  margin-bottom: 20px;
}
