.Lender-Responses {
  width: 1115px;
  min-height: 1900px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 30px;
}
.LenderResponses-heightfix {
  min-height: 20px;
}
.lender_wrapper {
  @media screen and (max-width: 900px) {
    margin-left: -85px;
    margin-top: 18px;
  }
}

.LenderResponses-lender_table {
  .documents_box {
    width: 77vw;

    .table_row {
      border: none !important;

      .left {
        display: flex;
        flex-direction: column;

        .item {
          width: 150px !important;
          align-items: flex-start !important;
          display: flex;
        }
      }

      .right {
        display: flex;
        gap: 25px;
        align-items: center;

        .item {
          width: auto !important;
          display: flex;
        }

        .action_buttons {
          height: 100%;
          align-items: center;
          display: flex;

          .button {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }
}


