.Stakeholder-Conatiner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50vh;
  border: 1px solid #eaedf3;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.15);
}
.Stakeholder-Conatiner-data {
  justify-content: center;
  width: 350px;
  height: 150px;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.Stakeholderscharrings-conatiner {
  width: 85px;
  align-items: center;
  justify-content: center;
  height: 80px;
  display: flex;
  border-radius: 100%;
  margin: auto;
  background-color: #efebf8;
}
.Stakeholderscharrings-button {
  background-color: #efebf8;
  padding: 10px;
  width: 250px;
  text-align: center;
  border-radius: 5px;
}
