.businessplan {
    margin-left: 20px;
}


.businessplan_box {
    display: grid;
    grid-template-columns: 8% 92%;
    gap: 20px;
    padding: 20px;
    background-color: #ffffff;
    border: 1px solid #EAEDF3;
    filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.04));

    .businessplan_box_left {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 62px;
        width: 62px;
        border-radius: 50%;
        background-color: rgba(22, 101, 216, 0.1);
        color: #00629B;
    }

    .businessplan_box_right {
        h2 {
            color: #3E3F42;
            font-family: Roboto;
            font-size: 22px;
            font-style: normal;
            font-weight: 400;
            line-height: 32px;
        }

        p {
            color: #6B6C6F;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
        }
    }

}

.businessplan_header {
    h2 {
        color: #3E3F42;
        font-family: Roboto;
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
        text-transform: capitalize;
    }

    button {
        padding: 16px 22px;
        outline: none;

    }

    .businessplan_header_right {
        gap: 20px;
    }

    .btn_left {
        border-radius: 4px;
        border: 1px solid #D8DCE6;
        background: linear-gradient(0deg, #F6F7F9 0%, #FFF 100%);
        box-shadow: 0px 1px 1px 0px rgba(22, 29, 37, 0.05), 0px 2px 0px 0px rgba(255, 255, 255, 0.05) inset;
    }

    .btn_right {
        background-color: #7D55C7B5;
        border: none;
        border-radius: 4px;
        color: #ffffff;
    }
}


.businessplan_container {
    padding: 0 20px;
    display: flex;
    gap: 30px;
}

.businessplan_center {
    width: 730px;
    min-height: 524px;
    padding: 0 !important;
    display: flex;
    flex-direction: column;
    gap: 20px;

    // background-color: rgba(15, 160, 227, 0.623);
    .upper {
        padding: 20px;
        border: 1px solid #EAEDF3;
        filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.04));
        
        p {
            color: #6B6C6F;
            font-family: Roboto;
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
            display:flex;
            align-items: center;
            gap: 12px;
        }

        .upper_header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 22px;
        }

        .upper_info {
            display: flex;
            flex-direction: column;
            gap: 20px;
            .info_top {
                display: flex;
                justify-content: space-between;
                align-items: center;
                .info_top_left {
                    display: flex;
                    justify-content: flex-start;
                    gap: 20px;
                }
            }
        }
        .upper_video{
            width: 100%;
            video{
                width: 100%;
            }
        }
    }

    .lower {
        padding: 20px;
        border: 1px solid #EAEDF3;
        filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.04));

        h2 {
            color: #3E3F42;
            font-family: Roboto;
            font-size: 14px;
            font-weight: 500;
            line-height: 22px;
        }

        p {
            color: #6B6C6F;
            font-family: Roboto;
            font-size: 14px;
            font-weight: 400;
            line-height: 22px
        }

        .link {
            color: #8c69ce;
            text-decoration: underline;
        }
    }
}

.businessplan_right {
    width: 350px;
    height: 524px;
    position: sticky !important;
    // top: 70px !important;
    // background-color: rgba(15, 227, 15, 0.623);
}

.businessplan_nav {
    .asset-nav {
        border: none;
    }
}