.TaskDetailsModal-title {
  font-weight: bold;
  text-align: left;
  font-size: 1.5rem;
  padding-top: 8px;
  padding-bottom: 8px;
}

.TaskDetailsModal-content {
  padding: 24px;
  text-align: left;
  align-items: start;
}

.TaskDetailsModal-label {
  font-weight: 500;
  color: #555;
}

.TaskDetailsModal-value {
  font-size: 1rem;
}

.TaskDetailsModal-statusChip {
  margin-top: 4px;
}

.TaskDetailsModal-buttonContainer {
  display: flex;
  justify-content: flex-start;
  margin-top: 16px;
}

.TaskDetailsModal-closeButton {
  font-weight: bold;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 16px;
  background-color: #1976d2;
}

.TaskDetailsModal-closeButton:hover {
  background-color: #1565c0;
}

// List Task Section

.timetable_bar_details {
  .timetable_bar_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: linear-gradient(#f6f7f9, #ffffff);
    border-radius: 4px;
    border: 1px solid #d8dce6;
  }

  .timetable-container {
    margin-top: 16px;
    position: relative;

    .timetable-title {
      font-size: 0.875rem;
      font-weight: bold;

      margin-bottom: 8px;
      color: #9ea0a5;
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
      text-transform: uppercase;
      margin-top: 20px;
    }

    .time-slot {
      position: relative;
      display: grid;
      grid-template-columns: 50px 1fr;
      row-gap: 40px;
      padding-left: 8px;

      .time {
        font-size: 0.75rem;
        color: #888;
        text-align: right;
        padding-right: 12px;
        display: flex;
        align-items: center;
      }

      .divider {
        grid-column: 1 / -1;
        height: 1px;
        background-color: #e0e0e0;
        margin-left: 50px;
      }

      .event {
        grid-column: 2;
        background-color: #e8f5e9;
        border-left: 4px solid #4caf50;
        padding: 4px 8px;
        margin: 4px 0;
        border-radius: 4px;

        .event-title {
          font-weight: bold;
          color: #4caf50;
          margin: 0;
        }

        .event-time {
          font-size: 0.75rem;
          color: #4caf50;
          margin: 0;
        }
      }

      .timeline {
        position: absolute;
        left: 50px;
        width: calc(100% - 50px);
        height: 1px;
        background-color: #ff5722;
        top: 8px;
      }
    }
  }
}

// last Section

.timetable_bar_details {
  .timetable_bar_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }

  .timetable-container {
    margin-top: 16px;
    position: relative;

    .timetable-title {
      font-size: 0.875rem;
      font-weight: bold;

      margin-bottom: 8px;
      color: #9ea0a5;
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
      text-transform: uppercase;
      margin-top: 20px;
    }

    .time-slot {
      display: flex;
      flex-direction: column;
      gap: 40px;
      padding-left: 8px;

      .time-row {
        display: flex;
        align-items: center;

        .time {
          font-size: 0.75rem;
          color: #888;
          text-align: right;
          padding-right: 12px;
          width: 50px;
        }

        .timetable_bar_divider {
          flex-grow: 1;
          height: 1px;
          background-color: #e0e0e0;
        }
      }

      .event {
        background-color: #e8f5e9;
        border-left: 4px solid #4caf50;
        padding: 4px 8px;
        margin: 4px 0;
        border-radius: 4px;

        .event-title {
          font-weight: bold;
          color: #4caf50;
          margin: 0;
        }

        .event-time {
          font-size: 0.75rem;
          color: #4caf50;
          margin: 0;
        }
      }

      .timeline {
        position: absolute;
        left: 50px;
        width: calc(100% - 50px);
        height: 1px;
        background-color: #ff5722;
        top: 8px;
      }
    }
  }
}

// Task Popup modal -----------------------------

.taskmodelpopup-modal .MuiDialog-paper {
  border-radius: 12px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}

.taskmodelpopup-title {
  font-weight: bold;
  text-align: left;
  font-size: 1.5rem;
  padding: 16px 32px;
  border-bottom: 1px solid #ddd;
}

.taskmodelpopup-content {
  padding: 24px 32px;
  text-align: left;
}

.taskmodelpopup-label {
  font-weight: 600;
  color: #444;
  margin-bottom: 8px;
}

.taskmodelpopup-text {
  font-size: 1rem;
  color: #333;
  line-height: 1.6;
}

.taskmodelpopup-status {
  margin-left: 4px;
  background: none;
  // &.success {
  //   background-color: #d4edda;
  //   color: #155724;
  // }
  // &.default {
  //   background-color: #f8d7da;
  //   color: #721c24;
  // }
}

.taskmodelpopup-actions {
  display: flex;
  justify-content: flex-start;
  margin-top: 32px;
}

.taskmodelpopup-close-button {
  font-weight: bold;
  font-size: 0.875rem;
  padding: 10px 20px;
  border-radius: 6px;
  background-color: #1976d2;
  color: #fff;
  transition: all 0.3s ease;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.taskmodelpopup-close-button:hover {
  background-color: #1565c0;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

@media (max-width: 600px) {
  .taskmodelpopup-title {
    font-size: 1.25rem;
    text-align: center;
  }

  .taskmodelpopup-actions {
    justify-content: center;
  }

  .taskmodelpopup-label,
  .taskmodelpopup-text {
    font-size: 0.9rem;
  }
}
.timetable_bar_header {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px; 
}

.calender_date_data {
  white-space: nowrap; 
  font-size: 12px;
  font-weight: bold; 
  text-align: center; 
}
