.investment-entity-cell {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}

.cash_flow_cell {
  display: flex;
  flex-direction: column;
  gap: 5px;

  .progressbar_container {
    display: flex;
    flex-direction: column;

    &.wrapper1 {
      border-radius: 5px;
      //   background-color: #85abe8; /* Blue color */
    }
    &.wrapper2 {
      width: 60% !important;
      border-radius: 5px;
      background-color: #79e495; /* Green color */
    }
  }

  .progress-bar {
    border-radius: 5px;
    background-color: transparent;
  }

  .progress-bar.blue {
    width: 70% !important;
    background-color: #4285f4; /* Blue color */
  }

  .progress-bar.green {
    width: 50% !important;
    background-color: #34a853; /* Green color */
  }
}
