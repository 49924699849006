.blockchain_section {
  .pain_page_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 1440px;
    height: 70px;
    flex-shrink: 0;
    border-bottom: 1px solid #eaedf3;
    padding: 0px 20px 0px 20px;

    h1 {
      color: var(--LP-black, #3e3f42);
      font-family: Roboto;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 30px; /* 150% */
    }
  }
  .blockChain_container {
    display: flex;

    // .blockChainContent {
    //   display: flex;
    //   flex-direction: column;
    //   gap: 20px;
    // }  // justify-content: start;

    .userProfile {
      width: 825px;
      height: 228px;
      flex-shrink: 0;
      padding: 20px;
    }
    .tabination_header {
      height: 70px;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      margin: auto;
      gap: 35px;
      margin: 20px;
      background: #fff;
      box-shadow: 0px 1px 0px 0px #eaedf3;

      p {
        color: var(--LP-mid-grey-_lib, #6b6c6f);
        font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 171.429% */
        text-transform: capitalize;
        padding: 20px 0px 20px 0px;
        text-align: center;
        border-bottom: 1px solid rgb(119, 105, 105);
        border-bottom: 3px solid transparent;
        cursor: pointer;
      }
      .activeTab {
        //  box-shadow: 0px 0px 3px  #7D55C7 ;
        color: #7d55c7;
        border-bottom: 3px solid #7d55c7;
        cursor: pointer;
      }
    }
    .user_overview {
      // margin-top: 30px;
      padding: 20px;
    }
  }
}

.card {
  display: flex;
  flex-direction: column;
  gap: 18px;

  .prifileImg {
    img {
      width: 100px;
      height: 100px;
      border-radius: 50%;
    }
  }

  .user_details {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .name_and_type {
      img {
        width: 8px;
        height: 8px;
        border-radius: 50%;
      }
      h1 {
        color: #3e3f42;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 30px; /* 150% */
        display: flex;
        align-items: center;
        gap: 10px;
      }
      p {
        color: #6b6c6f;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 157.143% */
      }
    }

    .deal_match {
      display: flex;
      align-items: center;
      gap: 30px;

      div {
        display: flex;
        align-items: center;
        gap: 10px;
      }
      .deal {
        p {
          width: 34px;
          height: 34px;
          flex-shrink: 0;
          border-radius: 4px;
          background: var(--LP-gray, #9ea0a5);
          display: flex;
          align-items: center;
          justify-content: center;
          color: #ffffff;
        }
        span {
          color: #6b6c6f;
          font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px; /* 157.143% */
        }
      }
      .match {
        font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 14px */
        color: #6b6c6f;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 157.143% */
      }
    }
  }

  .userInfoMore {
    display: flex;
    align-items: center;
    gap: 20px;

    h1 {
      color: var(--LP-black, #3e3f42);

      /* h3/Medium 24px */
      font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      display: flex;
      align-items: center;
      gap: 5px;
    }

    div {
      display: flex;
      width: 180px;
      height: 97px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 3px;

      p {
        color: var(--LP-mid-grey-_lib, #6b6c6f);
        text-align: center;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px; /* 150% */
        text-transform: uppercase;
      }
      span {
        color: var(--Green-2, #27ae60);
        text-align: center;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 150% */
        text-transform: uppercase;
      }
    }
    .loan_term {
      span {
        color: var(--LP-mid-gray, #6b6c6f);
        text-align: center;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 150% */
        text-transform: uppercase;
      }
    }
  }
  .addDisputeBtn {
    display: flex;
    padding: 8px 11px;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    border: 1px solid #d8dce6;
    background: linear-gradient(0deg, #f6f7f9 0%, #fff 100%);
    box-shadow: 0px 1px 1px 0px rgba(22, 29, 37, 0.05), 0px 2px 0px 0px rgba(255, 255, 255, 0.05) inset;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
}
