.new-loan-general {
  width: 920px !important;
  margin-left: 230px;
}
.newloan-nav {
  width: 125px;
  .steps {
    gap: 30px;
  }
}

.steps .loan-progress {
  position: absolute;
  height: 610px;
  width: 3px;
  background: #d4d4d4;
  left: 89%;
  z-index: -1;
}
.new-loan-general {
  .addr {
    width: 670px;
    .inputBoxMain input {
      padding: 0;
    }
  }
  .location-body-loan {
    margin: 30px;
    width: 612px;
    .location-body-bottom {
      display: flex;
      align-items: center;
      gap: 30px;
      .addr-body {
        width: 320px;
        .inputBoxMain input {
          padding: 0;
        }
      }
    }
  }
}

.new-location-con {
  margin-top: 30px;
  h1 {
    color: #3e3f42;
    text-align: center;
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 155.556% */
    margin-bottom: 20px;
  }

  .manual-con {
    margin-top: 40px;
    display: flex;
    gap: 52px;
    width: 720px;
    align-items: center;
    div {
      span {
        color: #6b6c6f;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 25.6px */
      }
      .addressButton {
        margin-top: 29px;
        width: 65%;
      }
    }
  }
  .edi {
    width: 720px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .editbtn {
    display: inline-flex;
    padding: 8px 16px;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    border: 1px solid #d8dce6;
    background: linear-gradient(0deg, #f6f7f9 0%, #fff 100%);
    box-shadow: 0px 1px 1px 0px rgba(22, 29, 37, 0.05), 0px 2px 0px 0px rgba(255, 255, 255, 0.05) inset;
    span {
      color: #3e3f42;
      text-align: center;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 157.143% */
    }
  }
}
.map-wrapper {
  text-align: center;
  > span {
    color: #6b6c6f;
    text-align: center;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
    display: block;
    margin-top: 30px;
    margin-bottom: 28px;
  }

  .map-div {
    width: 917px;
    min-height: 441.285px;
    background-image: url("../../assets//newloanreq/map.png");
    .map-abs {
      top: 347px;
      right: 20px;
      transform: translate(0, 0);
    }
  }
}

.map-con {
  padding-bottom: 0;
}
