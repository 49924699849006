.todo_wrapper {
  @media screen and (max-width: 900px) {
    margin-left: -110px;
  }
}

.dialog-content {
  display: flex;
  flex-direction: column;
}

.task-field {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.task-field .MuiIconButton-root {
  margin-left: 8px;
  color: red;
}

.add-another-button {
  margin-top: 16px;
  color: blue;
}

.ToDo-see-plan {
  background-color: blue;
}

.deals-room-center-header-ToDo {
  background: #fff;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.04);
  /* display: flex; */
  // align-items: center;
  // justify-content: space-between;
  padding: 0 30px !important;
  margin: 0;
}

.todo_wrapper {
  background-color: #fbfbfd !important;
  font-family: "Arial", sans-serif;

  .deals-room-center-header-ToDo {
    margin-bottom: 20px;
  }

  .Graph-Data {
    padding: 10px;
  }

  .grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); // Responsive grid
    gap: 10%;
    width: 95%;
    margin: 0 !important;
    justify-items: center;
  }

  .ToDo-Discovery {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    width: 245px;

    .todo_chart_data {
      display: flex;
    }
  }

  .todo_header {
    display: grid;
    grid-template-columns: 1fr auto;
    margin-bottom: 0px !important;
    align-items: center;

    .left {
      h2 {
        font-size: 24px;
        color: #333;
      }
    }

    .right {
      display: grid;
      grid-template-columns: auto auto;
      gap: 10px;
      align-items: center;
    }
  }

  .todo_container {
  }

  .dialog-content {
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
  }
}

// .row {
//   background: white;
// }

.todo_wrapper {
  background-color: #fbfbfd !important;
  font-family: "Arial", sans-serif;
  position: relative;
  height: 100%;
  @media screen and (max-width: 900px) {
    margin-left: -110px;
  }

  .deals-room-center-header-ToDo {
    background: #fff;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.04);
    padding: 0 30px !important;
    margin-bottom: 20px;
  }

  .Graph-Data {
    padding: 10px;
  }

  .grid-container {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    scroll-behavior: smooth;
    gap: 10px;
    width: 100%;
  }

  .ToDo-Discovery {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-content: center;
    min-width: 245px;
    padding: 10px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);

    .todo_chart_data {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }
  }

  .ToDo-Discovery p {
    font-weight: 400;
    font-size: 14px;
    color: #3e3f42;
    text-align: center;

    &:last-child {
      color: #9ea0a5;
      font-size: 12px;
    }
  }

  .todo_header {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    padding: 0 30px !important;
    margin-bottom: 20px !important;
    max-width: calc(100vw - 260px);

    .left {
      h2 {
        font-size: 24px;
        color: #333;
      }
    }

    .right {
      display: grid;
      grid-template-columns: auto auto;
      gap: 10px;
      align-items: center;

      button {
        background: #fff;
        border: 1px solid #d8dce6;
        color: #3e3f42;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 157.143% */
        border-radius: 5px;
        padding: 12px;
        max-height: 38px;
        text-wrap: nowrap;
        &:hover {
          background: #7d55c7b5;
          color: #fff;

          img {
            filter: brightness(3.5);
          }
        }

        display: flex;
        align-items: center;
        gap: 10px;
      }
    }
  }

  .todo_container {
    margin-top: 0px !important;
    gap: 20px !important;
    margin: 0 30px !important;
    .todo_block {
      .todo_block-T {
        padding: 10px 20px 10px 0px !important;
        align-items: center;
        background: #ffffff;

        .MuiPaper-elevation {
          background: none !important;
          border: none !important;
          box-shadow: none !important;
        }
      }
      .todo_block-B {
        background: #ffffff;
      }
    }
  }

  .dialog-content {
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
  }

  .task-field {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    .MuiIconButton-root {
      margin-left: 8px;
      color: red;
    }
  }

  .add-another-button {
    margin-top: 16px;
    color: blue;
  }

  .ToDo-see-plan {
    background-color: blue;
  }
}

.slider-container {
  display: flex;
  align-items: center;

  .slider-button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 24px;
    padding: 0 10px;
    z-index: 1;
  }

  .slider-content {
    overflow: hidden;
    display: flex;
    align-items: center;
    width: 100%;

    #chart {
      .apexcharts-radialbar {
        .apexcharts-text.apexcharts-datalabel-value {
          font-size: 12px;
          transform: translate(0px, -10px);
        }
      }
    }
  }
}

.grid-container {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  scroll-behavior: smooth;
  gap: 10px;
  width: 100%;
}

.row {
  background: white;
}

.topHeader_components {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
  max-height: 67px;

  @media only screen and (max-width: 1320px) {
    flex-wrap: wrap;
    max-height: 100%;
    flex-direction: column;
    align-items: stretch;
  }

  .progress_container {
    display: flex;
    align-items: center;

    @media only screen and (max-width: 1320px) {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }

    .progress_box {
      display: flex;
      align-items: center;
      gap: 21px;
      height: 100%;
      padding: 20px;
      max-height: 67px;

      &:hover {
        background: linear-gradient(0deg, rgba(125, 85, 199, 0.03) 0%, rgba(125, 85, 199, 0.03) 100%), #fff;
        box-shadow: 0px -3px 0px 0px rgba(125, 85, 199, 0.7) inset, 0px 2px 8px 0px rgba(0, 0, 0, 0.06) inset;
        .progress_info {
          .progress_label {
            color: var(--purple-pantone-2665-c, #7d55c7);
          }
        }
      }

      .progress_info {
        .progress_label {
          color: var(--LP-black, #3e3f42);
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-wrap: nowrap;
        }
        .progress_ratio {
          color: #9ea0a5;
          font-family: Roboto;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px; /* 150% */
        }
      }
    }

    .handover_option {
      display: flex;
      align-items: center;
      gap: 10px;
      height: 100%;
      padding: 20px;
      max-height: 67px;

      .handoverimg {
        background: #f6f6f6;
        border-radius: 50%;
        padding: 8px;
        width: 40px;
        height: 40px;
        // img {
        //   margin: 8px;
        // }
      }
    }

    div {
      .progress_circle {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 39px;
        height: 39px;
        border-radius: 50%;

        p {
          font-size: 12px;
          font-weight: 500px;
          color: #666666;
        }
      }
    }
    .dueDiligence_progress {
      .dueDiligence_progress_circle {
        background: radial-gradient(closest-side, white 79%, transparent 80% 100%), conic-gradient(#7ec331 75%, #d4d4d4 0);
      }
    }
    .preClosing_progress {
      .progress_circle {
        background: radial-gradient(closest-side, white 79%, transparent 80% 100%), conic-gradient(#ed962d 75%, #d4d4d4 0);
      }
    }
    .postClosing_progress {
      .progress_circle {
        background: radial-gradient(closest-side, white 79%, transparent 80% 100%), conic-gradient(#cb333b 75%, #d4d4d4 0);
      }
    }
  }

  .topHeader_btns {
    display: flex;
    align-items: center;
    gap: 10px;

    @media only screen and (max-width: 1320px) {
      justify-content: space-between;
    }

    button {
      text-wrap: nowrap;
      background: #fff;
      border: 1px solid #d8dce6;
      color: #3e3f42;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 157.143% */
      border-radius: 5px;
      padding: 12px;
      max-height: 38px;
      text-wrap: nowrap;
      &:hover {
        background: #7d55c7b5;
        color: #fff;

        img {
          filter: brightness(3.5);
        }
      }

      display: flex;
      align-items: center;
      gap: 10px;
    }
  }
}

.toDoListContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  // height: 80vh;
}

.No_checklists {
  font-size: 1.5rem;
  color: #555;
}
