.FundraisingSAFEFinancings-first-box-data {
  border: 1px solid #eaedf3;
  display: flex;
}
.FundraisingSAFEFinancings-first-box-data-flex {
  display: flex;
  gap: 62px;
}
.text-data-safe h1 {
  color: #3e3f42;
  leading-trim: both;
  text-edge: cap;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px; /* 144.444% */
}



.container-data {
  padding: 10px;
  align-items: center;
}


.progress-bar {
  width: 50%;
}
.processbar-data {
  height: 50px;
  align-items: center;
}

.safe-flex-box {
  display: flex;
}
.flex-box-data {
  display: flex;
}

.data {
  padding: 20px;
  margin-top: 10px;
  border: 1px solid rgb(109, 109, 109);
}



.safe-fincal-target {
  border: 1px solid #eaedf3;
  
  padding: 20px;
}



.safe-finance-data {
  width: 450px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.safe-finance-data {
  line-height: 25px;
}

.Funding-SAFEFinancing {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.Funding-SAFEFinancing-data {
  border: 1px solid #eaedf3;
  width: 250px;
}

/* Safefinancings.scss */
.FundraisingSAFEFinancings {
  display: flex;
  gap: 30px;
}
@media screen and (max-width: 900px) {
  .FundraisingSAFEFinancings {
    flex-direction: column;
  }
}

.Safe-first-box-data {
  border: 1px solid #eaedf3;
  display: flex;
  flex-direction: column;
}

.text-data-safe p {
  width: 100%; /* Ensure text width adjusts for smaller screens */
}

.process-bar {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 10px;
  padding-top: 10px;
}

.button-components {
  display: flex;
  flex-wrap: wrap; /* Allow buttons to wrap */
  gap: 10px;
}

.button-componts-buttoncompo {
  display: flex;
  gap: 10px;
  margin: 10px;
  align-items: center;
}

.safe-received, .safe-pending {
  border: 1px solid #eaedf3;
  padding: 20px;
}

.safe-second-box {
  flex-wrap: wrap;
}

.Safe-Carta {
  display: flex;
  flex-direction: column;
  border: 1px solid #eaedf3;
  margin: 10px;
}
@media screen and (max-width: 900px) {
  .safe-finance-data {
    width: 100%;

  }
}

.Safe-Carta-data {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
