.popup-overlay-AccessPopup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
  }
  
  .popup-content-AccessPopup {
    background: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    max-height: 80vh;
    overflow-y: auto;
    margin-top: 5%;
  }
  
  .popup-content-AccessPopup h3 {
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
  }
  
  .popup-content-AccessPopup img {
    border-radius: 50%;
    // margin-bottom: 20px;
  }
  
  .popup-content-AccessPopup h1 {
    font-size: 20px;
    color: #555;
    margin-bottom: 10px;
  }
  
  .popup-content-AccessPopup p {
    margin-bottom: 20px;
    font-size: 16px;
    color: #777;
  }
  
  .popup-content-AccessPopup .checkbox-container {
    max-height: 300px;
    overflow-y: auto;
    margin-bottom: 20px;
    text-align: left;
  }
  
  .popup-content-AccessPopup .menu-item {
    margin-bottom: 10px;
  }
  
  .popup-content-AccessPopup .menu-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f9f9f9;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .popup-content-AccessPopup .menu-label:hover {
    background: #ececec;
  }
  
  .popup-content-AccessPopup .submenu {
    padding-left: 20px;
    margin-top: 5px;
  }
  
  .popup-content-AccessPopup .checkbox {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    background: #f9f9f9;
    border-radius: 5px;
    margin-bottom: 10px;
    height: 58px;
  }
  
  .popup-content-AccessPopup button {
    background: #764ec2;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin: 5px;
    font-size: 16px;
  }
  
  .popup-content-AccessPopup .close-button {
    background: rgb(212, 212, 212);
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin: 5px;
    font-size: 16px;
  }
  
  .popup-content-AccessPopup button:disabled {
    // background: #cccccc;
    cursor: not-allowed;
    // width: 250px;
    padding: 8px 40px;
  }
  

  .popup-content-AccessPopup .menu-label:after {
    content: "\25BC"; 
    font-size: 12px;
    color: #777;
  }
  
  .popup-content-AccessPopup .menu-label.open:after {
    content: "\25B2"; 
  }
  

  .popup-content-AccessPopup .submenu {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
  }
  
  .popup-content-AccessPopup .menu-label.open + .submenu {
    max-height: 500px; 
  }


  .popup-content-AccessPopup {
    padding: 20px;
  
    .invite_email {
      width: 100px;
      margin: 10px auto;
      display: block;
    }
  
    h1 {
      text-align: center;
      font-size: 24px;
      margin-bottom: 10px;
    }
  
    p {
      text-align: center;
      font-size: 16px;
    }
  
    .form-group {
      display: flex;
      /* align-items: center; */
      flex-direction: column;
      gap: 15px;
  
      label {
        width: 80px;
        text-align: left;
        font-size: 14px;
        font-weight: 500;
        color: #333;
      }
  
      .text-field {
        flex: 1;
        max-width: fit-content;
      }
    }
  
    .checkbox-container {
      margin-bottom: 16px;
    }
  
    .Buttons_for_send_email {
      display: flex;
      justify-content: space-between;
  
      button {
        padding: 10px 16px;
        font-size: 14px;
        cursor: pointer;
  
        &:disabled {
          cursor: not-allowed;
          opacity: 0.6;
        }
      }
  
      .send-button {
        background-color: #007bff;
        color: #fff;
        border: none;
        border-radius: 4px;
  
        &:hover {
          background-color: #0056b3;
        }
      }
    }
  }
  

  .text-field {
    .MuiInputBase-root {
      height: 36px;
      font-size: 14px; 
      width: 156px;
    }
  
    .MuiOutlinedInput-input {
      padding: 8px 12px;
    }
  
    .MuiFormHelperText-root {
      font-size: 12px; 
    }
  }
  .name_input_fields{
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    gap: 50px;
  }

.invite_employee_cardBox{
  display: flex;
  flex-direction: column;
  gap: 19px;
}  