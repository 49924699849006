.card-container {
  padding: 20px 20px;
  border-top: solid 1px #eaedf3;

  border-radius: 10px;

  .card-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .heading-container {
      display: flex;
      column-gap: 20px;
      // align-items: center;
      img {
        width: 38px !important;
        height: 38px !important;
      }

      .heading {
        h2 {
          font-family: Roboto;
          font-size: 18px;
          font-weight: 700;
          line-height: 28px;
          color: #3e3f42;

          @media  screen and (max-width:1000px) {
            font-size: 16px;
          }
        }
        p {
          font-family: Roboto;
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
          color: #9ea0a5;
        }
      }
    }

    .heading-side-container {
      text-align: right;

      p {
        font-family: Roboto;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        color: #9ea0a5;
      }
      h6 {
        font-family: Roboto;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        color: #6b6c6f;
      }
    }
  }

  .card-content {
    display: flex;
    column-gap: 20px;
    // margin-bottom: 20px;

    .directon-arrow {
      display: inline-block;
      text-align: center;
      font-family: Roboto;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;

      .arrow {
        display: inline-block;
        transform: rotate(40deg);
        margin-right: 5px;
        font-size: 28px;
      }
    }

    p {
      font-family: Roboto;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      color: #6b6c6f;
      max-width: 50%;
    }
  }

  .card-footer {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    p {
      font-family: Roboto;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      color: #6b6c6f;
    }

    .like-dislike-button-wrapper {
      display: flex;
      column-gap: 10px;
    }
    .share-button {
      display: flex;
      column-gap: 10px;
      align-items: center;

      cursor: pointer;
      h4 {
        font-family: Roboto;
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
        color: #3e3f42;
      }
    }
  }
}

.line {
  background-color: #eaedf3;
  height: 0px;
  border: solid 1px #eaedf3;
  width: 100%;
}
