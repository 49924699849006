.AValuations-Conatiner-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.AValuations-Conatiner {
  border: 1px solid var(--Primary-Purple-50, #eaedf3);
  background: rgba(125, 85, 199, 0.08);
}
.AValuations-Conatiner-data {
  margin: 10px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 900px) {
  .AValuations-Conatiner-data {
    flex-direction: column;
  }
}
.AValuations-container-image {
  display: flex;
}
.AValuations-container-image-data {
  display: flex;
  gap: 20px;
}

@media screen and (max-width: 900px) {
  .AValuations-container-image-data {
    flex-direction: column;
  }
}

.Fast-fact {
  width: 50%;
  border: 1px solid #eaedf3;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
}
@media screen and (max-width: 900px) {
  .Fast-fact {
    width: 100%;
  }
}
.Fast-fact p {
  color: var(--primary-gray-100-text, #9ea0a5);
  leading-trim: both;
  text-edge: cap;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.Learn-more {
  padding: 20px;
  display: flex;
  flex-direction: column;
  border: 1px solid #eaedf3;
  gap: 20px;
}
.Learn-more p {
  leading-trim: both;
  text-edge: cap;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}
// Dropdown
.wrapper {
  h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }

  border: none;

  .faq {
    border: 1px solid #eaedf3;

    .accordion {
      color: #333;
      cursor: pointer;
      padding: 15px;
      padding-top: 20px;
      padding-bottom: 20px;
      width: 100%;
      border: none;
      text-align: left;
      outline: none;
      transition: background-color 0.3s ease;
      background-color: white;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: var(--Black, #3e3f42);
      leading-trim: both;
      text-edge: cap;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 26px; /* 162.5% */
      i {
        transition: transform 0.3s ease;
      }

      &.active i {
        transform: rotate(180deg);
      }
    }

    .pannel {
      display: none;
      overflow: hidden;
      transition: max-height 0.2s ease-out;
      background-color: white;
      background-color: #7d55c7;
      &.active {
        display: block;
        max-height: 1000px;
        background-color: #7d55c7;
      }

      p {
        margin-top: 0;
        background-color: #7d55c7;
      }
    }
  }
}

// flex-data
.AValuations-container {
  display: flex;
}
.AValuations-container-data {
  background-color: chocolate;
}

.CabTable-Filter {
  padding: 0;
  margin: 0;
  height: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  border: none;
  color: black;
  background-color: white;
  border-radius: 4px;
  border: 1px solid var(--Primary-Purple-50, #eaedf3);
  background: #fff;
}
.AValuations-Conatiner-data-conatiner {
  line-height: 30px;
}
.AValuations-Conatiner-data-conatiner p {
  color: var(--Black, #3e3f42);
  leading-trim: both;
  text-edge: cap;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}
.Fast-fact-data {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding: 10px;
}
.CabTable-Filter {
  padding: 0;
  margin: 0;
  height: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  border: none;
  color: white;
  border-radius: 4px;
  background: var(--Primary-Purple-500, #7d55c7);
  border-radius: 4px;
  border: 1px solid var(--Primary-Purple-50, #eaedf3);
}
.faqBox {
  width: 100% !important;
  height: 100% !important;
  position: static !important;
  transform: none !important;
}
