.Equlaity-Dropdown {
  width: 250px;
}
.Equality-Container {
  border: 1px solid #eaedf3;
}
.Equality-Container-data {
  display: flex;
  align-items: center;
  padding: 10px;
  gap: 10px;
  justify-content: space-between;
  p{
    color: var(--Black, #3E3F42);
    leading-trim: both;
    text-edge: cap;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 200% */
  }

}
.Equality-Like-selector {
  border: 1px solid #eaedf3;
}
.Equality-Like-selector-data {
  margin: 10px;
  padding: 10px;
  display: flex;
  gap: 30px;
}
.Active-container {
  height: 50vh;
  justify-content: center;
  align-items: center;
  display: flex;
}
.Active-container-data {
  width: 300px;
  height: 150px;
  justify-content: center;
  display: flex;
  align-items: center;
}

.select-wrapper {
  position: relative;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  overflow: hidden;

  select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    border: none;
    outline: none;
    padding: 10px;
    font-size: 16px;
    width: 100%;
    cursor: pointer;
  }

  &::after {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    pointer-events: none;
  }
}

.Equityawards-Filter {
  padding: 0;
  margin: 0;
  height: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  border: none;
  color: black;
  background-color: white;
  border-radius: 4px;
  border: 1px solid var(--Primary-Purple-50, #eaedf3);
  background: #fff;
}
.Equality-Like-selector-data h5 {
  color: var(--primary-gray-100-text, #9ea0a5);
  leading-trim: both;
  text-edge: cap;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 128.571% */
  text-transform: uppercase;
}

.Report-see-View {
  width: 120px;
  padding: 0;
  margin: 0;
  height: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  border: none;
  color: black;
  background-color: white;
  border-radius: 4px;
  border: 1px solid var(--Primary-Purple-50, #eaedf3);
  background: #fff;
}
.Report-see-Request {
  padding: 0;
  margin: 0;
  height: 40px;
  width: 150px;
  align-items: center;
  display: flex;
  justify-content: center;
  border: none;
  outline: none;
  border: none;
  color: black;
  background-color: white;
  border-radius: 4px;
  border: 1px solid var(--Primary-Purple-50, #eaedf3);
  background: #fff;
}

.Report-see-plan-Option {
  width: 100px;
  padding: 0;
  margin: 0;
  height: 40px;
  width: 150px;
  align-items: center;
  display: flex;
  justify-content: center;
  border: none;
  color: black;
  background-color: white;
  border-radius: 4px;
  border: 1px solid var(--Primary-Purple-50, #eaedf3);
  background: #fff;
}

.Equality-Like-selector-data h5 {
  cursor: pointer;
}

.Equality-Like-selector-data h5.active {
  color: blue; 
  font-weight: bold; 
}
