.discovered_opportunity {
  .apply_header {
    display: flex;
    width: 1440px;
    height: 70px;
    justify-content: space-around;
    align-items: center;
    flex-shrink: 0;

    /* 3dp/Light */
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.1);

    .search {
      display: flex;

      align-items: center;
      gap: 39px;
      border-radius: 4px;
      border: 1px solid var(--LP-very-light-_lib, #eaedf3);
      background: #fff;
      box-shadow: 0px 1px 2px 0px rgba(102, 113, 123, 0.21) inset;

      input {
        width: 240px;
        padding: 8px 16px;
        border: none;
      }
    }
    .select {
      display: flex;

      justify-content: center;
      align-items: center;
      border-radius: 4px;
      border: 1px solid #d8dce6;
      background: #fff;
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.08);

      select {
        width: 184px;
        height: 38px;
        padding: 0px 1px;
        border: none;
      }
    }
    .location {
      display: flex;
      width: 190px;
      padding: 4px 16px;
      align-items: center;
      gap: 39px;
      border-radius: 4px;
      border: 1px solid var(--LP-very-light-_lib, #eaedf3);
      background: #fff;
      box-shadow: 0px 1px 2px 0px rgba(102, 113, 123, 0.21) inset;
    }
    .all_types {
      display: flex;

      justify-content: center;
      align-items: center;
      border-radius: 4px;
      border: 1px solid #d8dce6;
      background: #fff;
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.08);
      select {
        width: 162px;
        height: 38px;
        padding: 0px 1px;
        border: none;
      }
    }
    .date_added {
      display: flex;

      justify-content: center;
      align-items: center;
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.08);
      border: 1px solid #d8dce6;

      select {
        width: 150px;
        height: 38px;
        border: none;
      }
    }
    .match {
      display: flex;
      width: 160px;
      height: 38px;
      padding: 0px 1px;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      border: 1px solid #d8dce6;
      background: #fff;
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.08);
    }
    .apply_btn {
      button {
        display: flex;
        padding: 8px 20px;
        justify-content: flex-end;
        align-items: center;
        gap: 12px;
        border-radius: 4px;
        border: 1px solid var(--purple-pantone-2665-c-71, rgba(125, 85, 199, 0.71));
        background: linear-gradient(0deg, rgba(125, 85, 199, 0.3) 0%, rgba(255, 255, 255, 0) 107.78%), #7d55c7;
        background-blend-mode: multiply, normal;
        box-shadow: 0px 1px 1px 0px rgba(19, 31, 21, 0.1), 0px 2px 0px 0px rgba(255, 255, 255, 0.06) inset;
        color: #fff;

        text-align: center;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 157.143% */
      }
    }
  }

  .side_users {
    width: 365px;
    height: 610px;
    flex-shrink: 0;
    background: #fff;
    box-shadow: 1px 0px 0px 0px #eaedf3;

    .side_user_list {
      .single_side_user {
        width: 365px;
        height: 100px;
        flex-shrink: 0;
        background: #fff;
        box-shadow: 0px 1px 0px 0px #eaedf3 inset;
        display: flex;
        justify-content: flex-start;
        padding: 20px;
        gap: 20px;
      }
    }
    .single_user_descri {
      display: flex;
      flex-direction: column;
      gap: 4px;
      :nth-child(2) {
        color: #9ea0a5;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 133.333% */
      }
      :nth-child(3) {
        display: flex;
        padding: 0px 8px;
        align-items: center;
        gap: 4px;
        border-radius: 100px;
        opacity: 0.8;
        background: var(--LP-very-light-_lib, #eaedf3);
        color: var(--LP-black-_lib, #3e3f42);
        text-align: center;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 150% */
        width: max-content;
      }
    }

    .single_side_user_fund {
      width: 365px;
      height: 100px;
      flex-shrink: 0;
      background: #fff;
      box-shadow: 0px 1px 0px 0px #eaedf3 inset;
      display: flex;
      justify-content: flex-start;
      padding: 20px;
      gap: 20px;
    }
    .single_user_descri_fund {
      display: flex;
      flex-direction: column;
      gap: 2px;
    }
  }

  .side_users_heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;

    div {
      h2 {
        color: #3e3f42;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px; /* 157.143% */
      }

      p {
        color: #6b6c6f;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 157.143% */
      }
    }
  }
}
