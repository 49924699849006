.Taxrules-header-conatiner {
  border: 1px solid #eaedf3;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.Taxrules-container {
  justify-content: space-between;
  margin-bottom: 20px;
}

.Taxrules-box {
  padding: 20px;
  display: flex;
  border: 1px solid #eaedf3;
  justify-content: space-between;
  align-items: center;
}

.axrules-box h1 {
  font-size: 20px;
  margin-bottom: 10px;
}
.YTD-Container {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
}
.YTD-Container-data {
  display: flex;
  gap: 30px;
}
.Taxrules-Container-data {
  border: 1px solid #eaedf3;
}
.Taxrules-Container-WIRHHOLDING {
  display: flex;
  gap: 30px;
  padding: 20px;
}

.Taxrules-Container-WIRHHOLDING span {
  color: black; /* Default color for not active state */
  cursor: pointer; /* Add a pointer cursor to indicate clickable items */
}

.Taxrules-Container-WIRHHOLDING span.active {
  color: var(--Primary-Purple-500, #7d55c7);
}

.axrules-Container-WIRHHOLDING span {
  color: var(--Primary-Purple-500, #7d55c7);
  leading-trim: both;
  text-edge: cap;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  text-transform: uppercase;
}

// dropdown

.dropdown-content a:hover {
  background-color: #ddd;
}

.Taxrules-Container-new {
  display: flex;
  flex-direction: column;
  gap: 30px;

  .span-data {
    color: var(--primary-gray-100-text, #9ea0a5);
    leading-trim: both;
    text-edge: cap;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
  }
  .Taxrules-TYD {
    .Taxrules-box {
      h1 {
        color: #9ea0a5;
        leading-trim: both;
        text-edge: cap;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px; /* 150% */
        text-transform: uppercase;
      }
    }
    p {
      color: var(--Black, #3e3f42);
      leading-trim: both;
      text-edge: cap;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 157.143% */
    }
    .span-tag {
      color: var(--Action-Red, #eb5757);
      leading-trim: both;
      text-edge: cap;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 157.143% */
    }
  }
}
.Taxrules-Completed{
  padding: 0;
  margin: 0;
  height: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  border: none;
  color: white;
  border-radius: 4px;
  background: #34AA44;
}

.Taxrules-container {
  display: flex;
  justify-content: space-between;
}
.Taxrules-Container span {
  color: var(--primary-gray-100-text, #9ea0a5);
  leading-trim: both;
  text-edge: cap;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}
.Taxrules-container-rest-data {
  display: flex;
  gap: 20px;
}

.Taxrules-container-data {
  justify-content: space-between;
  margin: 10px;
}
.Taxrules-Container-data span {
  color: var(--primary-gray-100-text, #9ea0a5);
  leading-trim: both;
  text-edge: cap;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}
.Taxrules-container-first-data {
  display: flex;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  justify-content: space-between;
}
.Taxrules-container-first-data p {
  color: var(--primary-gray-100-text, #9ea0a5);
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
  text-transform: uppercase;
}

.container {
  display: flex;
  align-items: center;
}

.dot {
  width: 12px;
  height: 12px;

  border-radius: 50%;
  margin-right: 8px;
}

.user-details {
  display: flex;
  justify-content: space-between;
  margin: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.user-details-container {
  border: 1px solid #eaedf3;
  padding-top: 10px;
  padding-bottom: 10px;
  align-items: center;
}

.Taxrules {
  display: flex;
  flex-direction: column;
  gap: 0px;
}
.CabTable-data {
  margin: 10px;
  padding: 10px;
}

.colordata {
  border: 1px solid #eaedf3;
}

.search-filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #eaedf3;
}
.search-filter-data {
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  gap: 20px;
}
.Tax-rules-container {
  margin-top: 15px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 20px;
}

.Tax-rules-container-data {
  border: 1px solid var(--Primary-Purple-50, #eaedf3);
}
.Tax-rules-container-second {
  border: 1px solid var(--Primary-Purple-50, #eaedf3);
}

.Tax-rules-container-thid {
  border: 1px solid var(--Primary-Purple-50, #eaedf3);
}
.Tax-rules-container-datafirst {
  padding: 20px;
  justify-content: center;
  display: flex;
  gap: 10px;
  flex-direction: column;
  margin-top: 20px;
}
.Tax-rules-container-datafirst h1 {
  color: var(--Black, #3e3f42);
  leading-trim: both;
  text-edge: cap;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 137.5% */
}
.Tax-rules-container-datafirst span {
  color: var(--Primary-Purple-500, #7d55c7);
  leading-trim: both;
  text-edge: cap;
  font-family: Roboto;
  font-size: 14px;
  width: 60%;
  font-style: normal;
  font-weight: 400;
  line-height: 17px; /* 121.429% */
}
.text-area {
  color: var(--Primary-Purple-500, #7d55c7);
  leading-trim: both;
  text-edge: cap;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px; /* 121.429% */
  width: 100%;
}
.Tax-rules-container-datafirst p {
  color: var(--primary-gray-100-text, #9ea0a5);
  leading-trim: both;
  text-edge: cap;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px; /* 121.429% */
}

.Tax-rules-container-secondbox {
  padding: 20px;
  text-align: center;
  justify-content: center;
  display: flex;
  gap: 10px;
  flex-direction: column;
  margin-top: 20px;
}
.Tax-rules-container-secondbox p {
  color: var(--primary-gray-100-text, #9ea0a5);
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  width: 95%;
  font-weight: 400;
  line-height: 17px; /* 121.429% */
}
.Tax-rules-container-thiddata {
  padding: 20px;
  text-align: center;
  justify-content: center;
  display: flex;
  gap: 20px;
  flex-direction: column;
  margin-top: 20px;
}
.Upload-Conatiner {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 10px;
}

.Taxrules-Filter {
  padding: 0;
  margin: 0;
  height: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  border: none;
  color: white;
  width: 100px;
  border-radius: 4px;
  background: var(--Primary-Purple-500, #7d55c7);
}
.YTD-Container p {
  color: var(--Action-Red, #eb5757);
  leading-trim: both;
  text-edge: cap;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}
.Taxrules-image-footer {
  width: 109px;
  height: 109px;
  flex-shrink: 0;
  margin: auto;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 100%;
  border-radius: 218px;
  background: rgba(125, 85, 199, 0.12);
}
.Taxrules {
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 768px) {
  .Taxrules-container {
    flex-direction: column;
  }

  .Taxrules-box {
    flex-direction: column;
  }

  .YTD-Container {
    flex-direction: column;
  }

  .YTD-Container-data {
    flex-direction: column;
  }

  .Tax-rules-container {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
}

@media screen and (max-width: 768px) {
  .Taxrules-container-first-data {
    flex-wrap: wrap;
  }

  .Taxrules-container-first-data p {
    width: 50%;
    flex-basis: 50%;
  }

  .user-details {
    flex-direction: column;
  }

  .user-details h5 {
    width: 100%;
    flex-basis: 100%;
    text-align: center;
  }

  .user-details-container {
    flex-direction: column;
  }
}
.Taxrules-Filter {
  padding: 0;
  margin: 0;
  height: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  border: none;
  color: black;
  background-color: white;
  border-radius: 4px;
  border: 1px solid var(--Primary-Purple-50, #eaedf3);
  background: #fff;
}
