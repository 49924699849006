.team {
    margin-left: 20px;
}


.teambox_wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;

    .teambox {
        width: 100%;
        height: 390px;
        border-radius: 4px;
        border: 1px solid #EAEDF3;
        background: #FFF;
        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.04)
    }

    .teambox_upper {
        height: 70px;
    }

    .teambox_lower {
        padding: 0 10px;
        display: flex;
        flex-direction: column;
        gap: 40px;
        position: relative;
        
        div {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            gap: 4px;
            margin-top: 55px;

            span {
                color: #3E3F42;
                font-family: Roboto;
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
            }

            p {
                color: #6B6C6F;
                font-family: Roboto;
                font-size: 14px;
                font-weight: 400;
                line-height: 22px
            }
        }

        p {
            color: var(#3E3F42);
            font-family: Roboto;
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
        }

        span {
            color: var(--purple-pantone-2665-c, #7D55C7);
        }
        img{
            height: 80px;
            width: 80px;
            border-radius: 100%;
            position: absolute;
            top: -40px;
            left: 39%;
        }
    }


}

.team_header {
    margin-bottom: 20px;
    h2 {
        color: #3E3F42;
        font-family: Roboto;
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
        text-transform: capitalize;
    }

    button {
        padding: 16px 22px;
        outline: none;

    }

    .team_header_right {
        gap: 20px;
    }

    .btn_left {
        border-radius: 4px;
        border: 1px solid #D8DCE6;
        background: linear-gradient(0deg, #F6F7F9 0%, #FFF 100%);
        box-shadow: 0px 1px 1px 0px rgba(22, 29, 37, 0.05), 0px 2px 0px 0px rgba(255, 255, 255, 0.05) inset;
    }

    .btn_right {
        background-color: #7D55C7B5;
        border: none;
        border-radius: 4px;
        color: #ffffff;
    }
}


.team_container {
    padding: 0 30px;
    display: flex;
    gap: 30px;
}

.team_center {
    width: 730px;
    min-height: 524px;
    padding: 0 !important;
    display: flex;
    flex-direction: column;
    gap: 20px;
    // background-color: rgba(15, 160, 227, 0.623);s
}

.team_right {
    width: 350px;
    height: 524px;
    position: sticky !important;
    // top: 70px !important;
    // background-color: rgba(15, 227, 15, 0.623);
}