.Report-Container {
  display: flex;
  flex-direction: column;
  gap: 50px;
  padding: 20px;
}

.Report-header {
  border: 1px solid #eaedf3;
  display: flex;
  justify-content: space-between;
  margin-top: 0;
  padding: 20px;
  flex-wrap: wrap;
}

.Report-header h4 {
  width: 300px;
  color: var(--Primary-Gray-200, #9ea0a5);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
}

.Report-see-plan {
  margin-top: 0;
  width: 100px;
}

.All-report-data {
  display: flex;
  gap: 20px;
  align-items: center;
  flex-wrap: wrap;
}

.All-report-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  border: 1px solid #eaedf3;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.15);
}

.Report-header-data {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  margin: 10px;
  border: 1px solid #eaedf3;
  flex-wrap: wrap;
}

.Report-header-data h4 {
  color: var(--primary-gray-100-text, #9ea0a5);
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-transform: uppercase;
}

.report-box-container {
  margin: 10px;
}

.box-border {
  border: 1px solid #eaedf3;
  padding: 10px;
}

.box-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.box-container-data {
  display: flex;
  gap: 20px;
  margin: 5px 0;
  flex-wrap: wrap;
}

.box-container-data-flex {
  display: flex;
  align-items: center;
  gap: 20px;
}

.box-container-data-flex > h1 {
  color: #3e3f42;
  leading-trim: both;
  text-edge: cap;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
  text-transform: capitalize;
}

@media (max-width: 768px) {
  .Report-header {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .Report-header h4 {
    width: 100%;
  }

  .All-report-data {
    flex-direction: column;
    align-items: center;
  }

  .Report-header-data {
    flex-direction: column;
    align-items: center;
  }

  .Report-header-data h4 {
    margin-bottom: 10px;
  }

  .box-container {
    flex-direction: column;
    align-items: center;
  }

  .box-container-data {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
}
