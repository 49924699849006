.Companyfinancials-Container {
  display: flex;
  gap: 20px;
}
.Companyfinancials-Container button {
  width: 550px;
}

.Companyfinancials-container-data {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
}

.Companyfinancials-Cognier {
  justify-content: center;
  align-items: center;
  border: 1px solid #eaedf3;
  width: fit-content;
  padding: 20px;
}

.Companyfinancials-Cognier > div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Companyfinancials-Cognier img {
  margin-bottom: 10px;
}
.Companyfinancials-Income {
  padding: 0;
  margin: 0;
  height: 40px;
  align-items: center;
  display: flex;
  width: 150px;
  justify-content: center;
  border: none;
  color: black;
  background-color: white;
  border-radius: 4px;
  background: rgba(125, 85, 199, 0.07);
}

.Companyfinancials-balance {
  padding: 0;
  margin: 0;
  width: 150px;
  height: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  border: none;
  color: var(--primary-gray-100-text, #9ea0a5);
  background-color: white;
  border-radius: 4px;
  border: 1px solid var(--Primary-Purple-50, #eaedf3);
  background: #fff;
}

// Error
.Companyfinancials-data {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

.Companyfinancials-data-container {
  border: 1px solid #eaedf3;
  padding-top: 60px;
  width: 350px;
  height: 180px;
  align-items: center;
  text-align: center;
}
.Companyfinancials-data-container-image {
  background-color: #eaedf3;
  width: 80px;
  justify-content: center;
  display: flex;
  align-items: center;
  height: 80px;
  border-radius: 100%;
}

.Companyfinancials-Error-Container {
  border: 1px solid #eaedf3;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 350px;
  height: 200px;
  align-items: center;
  text-align: center;
}
.Companyfinancials-Error-Container p {
  color: #3e3f42;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px; /* 144.444% */
}
