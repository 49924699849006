.add {
  color: #ffffff;
  display: inline-flex;
  padding: 8px 20px;
  align-items: center;
  gap: 12px;
  border-radius: 4px;
  border: 1px solid var(--purple-pantone-2665-c-71, rgba(125, 85, 199, 0.71));
  background: linear-gradient(0deg, rgba(125, 85, 199, 0.3) 0%, rgba(255, 255, 255, 0) 107.78%), #7d55c7;
  background-blend-mode: multiply, normal;
  box-shadow: 0px 1px 1px 0px rgba(19, 31, 21, 0.1), 0px 2px 0px 0px rgba(255, 255, 255, 0.06) inset;
}

.compare_box_wrapper {
  width: 479px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px -1px 0px 0px #eaedf3;
  filter: drop-shadow(3px 0 13px #cddddd);
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: absolute;
  right: 20px;
  top: 44px;

  .compare_box_btns {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 20px;

    .btn_left {
      outline: none;
      background: linear-gradient(#f6f7f9, #ffffff);
      border: 1px solid #d8dce6;
      border-radius: 4px;
      padding: 8px 16px;
      color: #3e3f42;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 12px;
    }

    .btn_right {
      color: #fff;
      text-align: center;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      border-radius: 4px;
      border: 1px solid var(--purple-pantone-2665-c-71, rgba(125, 85, 199, 0.71));
      background: linear-gradient(0deg, rgba(125, 85, 199, 0.3) 0%, rgba(255, 255, 255, 0) 107.78%), #7d55c7;
      background-blend-mode: multiply, normal;
      box-shadow: 0px 1px 1px 0px rgba(19, 31, 21, 0.1), 0px 2px 0px 0px rgba(255, 255, 255, 0.06) inset;
      display: inline-flex;
      padding: 8px 20px;
    }
  }

  .compare_box_details {
    display: flex;
    align-items: center;
    gap: 20px;
    border-left: 1px solid #eaedf3;
    padding: 0 20px;
    border-bottom: 1px solid #eaedf3;
    padding-bottom: 16px;

    img {
      height: 88px;
      width: 100px;
    }

    h3 {
      color: #3e3f42;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      /* 150% */
    }

    p {
      color: #9ea0a5;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      /* 142.857% */
    }

    span {
      color: #3e3f42;
      text-align: right;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
      display: flex;
      width: 100%;
      justify-content: space-between;
      img {
        height: 20px;
        width: 20px;
      }
      /* 200% */
    }
  }
}

.right_btns {
  position: absolute;
  right: 20px;
  margin-top: 16px;
  z-index: 11;

  .search-wrapper {
    display: flex;
    align-items: center;
    gap: 12px;
  }
}

.add_compare {
  color: #ffffff;
  display: inline-flex;
  padding: 8px 20px;
  align-items: center;
  justify-content: center;
  gap: 12px;
  border-radius: 4px;
  border: 1px solid var(--purple-pantone-2665-c-71, rgba(125, 85, 199, 0.71));
  background: linear-gradient(0deg, rgba(125, 85, 199, 0.3) 0%, rgba(255, 255, 255, 0) 107.78%), #7d55c7;
  background-blend-mode: multiply, normal;
  box-shadow: 0px 1px 1px 0px rgba(19, 31, 21, 0.1), 0px 2px 0px 0px rgba(255, 255, 255, 0.06) inset;
  width: 100%;
}

.img_right {
  right: -24.5px;
  height: 52px !important;
  position: absolute;
  top: 23px;
  width: 233px;
}

.search-btn-compare {
  width: 469px;
  height: 38px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  gap: 12px;
  border-radius: 4px;
  border: 1px solid var(--LP-very-light--_lib, #eaedf3);
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(102, 113, 123, 0.21) inset;
  padding-left: 12px;
  justify-content: space-between;
  padding-right: 16px;

  input {
    border: none;
    outline: none;
    padding: 9px;
    width: 400px;
    &::placeholder {
      color: #9ea0a5;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
    }
  }
}

.compare {
  width: 1110px;
  margin-left: 165px;
  padding-bottom: 30px;
  .view_asset_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    padding-top: 100px;
  }

  .view_asset {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 255px;
    position: relative;

    .color_line {
      height: 4px;
      width: 100%;
      border-radius: 4px;
      background: #7d55c7;
    }

    .view_asset_box {
      .bg {
        border-radius: 4px;
        width: 255px;
        height: 145px;
      }

      .name {
        color: #3e3f42;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
      }

      .text {
        color: #9ea0a5;
        font-family: Roboto;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
      }

      .price {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;

        p {
          color: #3e3f42;
          font-family: Roboto;
          font-size: 18px;
          font-weight: 500;
          line-height: 28px;
        }

        span {
          color: #6b6c6f;
          text-align: right;
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
        }
      }

      .btn {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;

        img {
          height: 20px;
          width: 20px;
        }

        button {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 10px;
          width: 215px;
          height: 38px;
          background-color: #ffffff;
          outline: none;
          border: 1px solid #d8dce6;
        }
      }
    }
  }

  .nav_wrapper {
    margin-top: 60px;

    .chart {
      display: flex;
      justify-content: space-between;
      width: 80%;
      gap: 20px;
      margin: 30px 0 0 10px;

      h2 {
        color: #3e3f42;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
      }

      p {
        color: #3e3f42;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        margin: 20px;
      }
    }
  }

  .property_features {
    background-color: #f6f6f6;
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 60px;
    margin-bottom: 20px;

    p {
      color: #9ea0a5;
      font-family: Roboto;
      font-size: 12px;
      font-weight: 500;
      text-transform: uppercase;
    }
  }

  .property_features_box {
    height: 350px;
    width: 350px;
    padding: 20px;
    border-radius: 4px;
    border: 1px solid #eaedf3;
    background: #fff;

    .box_header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      p {
        color: #3e3f42;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
      }

      span {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 2px;
        color: #9ea0a5;
        font-family: Roboto;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
      }
    }

    .box_row {
      height: 70px;
      box-shadow: 0px -1px 0px 0px #eaedf3 inset;
      display: flex;
      flex-direction: column;
      justify-content: center;

      p {
        color: #3e3f42;
        font-family: Roboto;
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
      }
    }

    .box1 div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }

    .box2 div {
      display: flex;
      gap: 10px;
      width: 100%;
    }
  }

  .progressbar {
    background-color: rgb(192, 192, 192);
    width: 80%;
    border-radius: 15px;
  }

  .bar {
    background-color: rgb(116, 194, 92);
    color: white;
    padding: 1%;
    text-align: right;
    font-size: 20px;
    border-radius: 15px;
  }

  .bar1 {
    width: 70% !important;
  }

  .property_features_box_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .chartbox {
    margin-top: 50px;
    height: 374px;
    width: 100%;
    border: 1px solid #eaedf3;
    filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.04));

    .chart_wrapper {
      padding: 20px;

      img {
        margin-top: -90px;
      }
    }
  }

  .chartbox_header {
    padding: 20px;
    height: 70px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid #eaedf3;

    p {
      color: #3e3f42;
      font-family: Roboto;
      font-size: 18px;
      font-weight: 500;
      line-height: 28px;
    }
  }

  .similar_wrapper {
    margin-top: 40px;

    > div {
      display: flex;
      gap: 30px;
      margin-top: 25px;
    }

    p {
      color: #3e3f42;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
    }

    .comparision_box {
      width: 350px;

      .comparision_box_row {
        display: flex;
        align-items: center;
        gap: 20px;
        border-bottom: 1px solid #eaedf3;
        padding-bottom: 20px;

        span {
          color: #9ea0a5;
          font-family: Roboto;
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
        }

        img {
          width: 100px;
          height: 88px;
          border-radius: 4px;
        }

        .right {
          display: flex;
          flex-direction: column;
          gap: 10px;
          width: 100%;

          .right_upper {
            display: flex;
            flex-direction: column;
            gap: 0;
          }

          .right_lower {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            img {
              height: 20px;
              width: 20px;
              margin-left: 10px;
            }
          }
        }
      }
    }
  }

  .compare_nav {
    .asset-nav {
      border: none !important;
    }
  }
}
