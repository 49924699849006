.funds {
    h3 {
        color: #3E3F42;
        font-family: Roboto;
        font-size: 36px;
        font-weight: 300;
        line-height: 50px;
    }
    img{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 200px;
        margin-top: 20px;
    }
}