.investment_center_wrapper {
  margin-left: 0 !important;
  margin-top: 30px;
  @media screen and (max-width: 1300px) {
    max-width: calc(100vw - 240px);
  }
}

.investment-deals-room {
  display: grid !important;
  grid-template-columns: 270px 1fr;

  .investment_center_wrapper {
    margin-left: 272px !important;
    padding-bottom: 30px;
  }
  @media screen and (max-width: 1300px) {
    grid-template-columns: 240px 1fr;
  }
  .deals-left-menu.deals-menu-active {
    width: auto;
    background-color: rgba(125, 85, 199, 0.05) !important;
  }
}

.tab-container {
  display: flex;
  border-bottom: 2px solid #d3d3d3;
  margin-bottom: 20px;
}

.tab {
  padding: 10px 20px;
  cursor: pointer;
  border-bottom: 3px solid transparent;
  transition: all 0.3s ease;
}

.tab.active {
  border-bottom: 3px solid #8a2be2;
  color: #8a2be2;
}

.tab-content {
  padding: 20px;
}

.investment_sidebar {
  .upper {
    padding: 24px 10px;
  }

  .heading {
    padding: 0 20px;
  }

  .upper_box {
    height: 64px;
    border-radius: 10px;
    background: rgba(125, 85, 199, 0.1);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    margin-top: 5px;

    > div {
      display: flex;
      flex-direction: column;

      p {
        color: #3e3f42;
        font-family: Roboto;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 30px;
        /* 150% */
      }
    }
  }

  .lower {
    padding: 12px 28px;

    .lower_header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        color: #9ea0a5;
        text-align: right;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }

    .deal-title-text {
      font-size: 16px;
      color: #3e3f42;
      font-weight: 500;
    }
  }

  .sb-deal-left-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .new {
      border-radius: 100px;
      border: 1px solid #fff;
      background: var(--Orange, #ed962d);
      display: inline-flex;
      height: 21px;
      padding: 4px 7px;
      align-items: center;
      gap: 7px;
      flex-shrink: 0;
      color: #fff;
      text-align: center;
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
      /* 12px */
    }
  }

  .lower-2 {
    margin: 14px 0;
  }

  .lower-3 {
    margin: 10px 0;
  }

  .gp-number {
    border-radius: 100px;
    border: 1px solid #fff;
    background: #cb333b;
    display: inline-flex;
    padding: 5px 7px;
    align-items: center;
    gap: 7px;
    color: #fff;
    text-align: center;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    position: absolute;
    right: 12px;
    /* 12px */
  }

  .sb-deal-left-wrapper-2 {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
    span {
      text-wrap: nowrap;
      width: 100%;
    }
  }

  .sb-deal-left-wrapper {
    position: relative;
  }
  .sb-deals-wrapper {
    span:not(.gp-number) {
      display: flex;
      align-items: center;
      gap: 4px;
      text-wrap: nowrap;
      width: 100%;
    }

    .green-dot {
      width: 6px !important;
      height: 6px !important;
      display: flex;
      border-radius: 8px;
      background-color: rgba(52, 170, 68, 1);
    }
  }

  .search_bar {
    position: relative;
    width: 214px;
    height: 38px;
    border: 2px solid #eaedf3;
    border-radius: 5px;
    display: flex;
    align-items: center;
    margin: 20px auto auto auto;

    input {
      all: unset;
      width: 220px;
      padding-right: 25px;
      padding-left: 2px;
      color: #9ea0a5;
      font-family: Roboto;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
    }

    img {
      position: absolute;
      right: 5px;
    }
  }

  .sidebar_nav {
    max-width: 210px;
    margin: auto;
  }

  .title {
    color: var(--LP-gray, #9ea0a5);
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    /* 150% */
    text-transform: uppercase;
    display: flex;
    align-items: center;
    gap: 4px;
  }
}
