.record_container {
  .heading {
    // width: 825px;
    height: 70px;
    flex-shrink: 0;
    box-shadow: 0px 0px 0px 1px #eaedf3 inset;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;

    h1 {
      color: #3e3f42;
      font-family: Roboto;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px; /* 155.556% */
    }

    .search_export {
      display: flex;
      align-items: center;
      gap: 30px;

      .search {
        // width: 150px;
        display: flex;
        align-items: center;
        padding: 0px 20px 0px 20px;
        border-radius: 5px;

        flex-shrink: 0;
        box-shadow: 0px 0px 0px 1px #eaedf3;
        input {
          width: 150px;
          height: 38px;
          flex-shrink: 0;
          border: none;
          outline: none;

          &::placeholder {
            color: #9ea0a5;
            font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px; /* 157.143% */
          }
        }
      }
      button {
        display: inline-flex;
        padding: 8px 11px;
        align-items: center;
        gap: 10px;
        border-radius: 4px;
        border: 1px solid #d8dce6;
        background: linear-gradient(0deg, #f6f7f9 0%, #fff 100%);
        box-shadow: 0px 1px 1px 0px rgba(22, 29, 37, 0.05), 0px 2px 0px 0px rgba(255, 255, 255, 0.05) inset;
        color: #3e3f42;
        text-align: center;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 157.143% */
        cursor: pointer;
      }
    }
  }
  .records_details {
    margin-top: 20px;
    .records_header {
      display: flex;
      align-items: center;
      gap: 30px;
      background: #fff;
      box-shadow: 0px 1px 0px 0px #eaecee;
      padding-bottom: 20px;

      p {
        color: #9ea0a5;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%; /* 16.8px */
        text-transform: uppercase;
      }
    }
    .reocord_info {
      display: flex;
      align-items: center;
      gap: 90px;

      // height: 70px;
      padding: 20px 10px 20px 10px;
      flex-shrink: 0;
      background: rgba(255, 255, 255, 0);
      box-shadow: 0px -1px 0px 0px #eaedf3 inset;

      p {
        color: #3e3f42;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px; /* 157.143% */
      }
    }
    .pagination {
      width: 825px;
      height: 80px;
      display: flex;
      align-items: center;
      flex-shrink: 0;
      padding-left: 20px;

      .paginate_buttons {
        box-shadow: 0px 0px 0px 1px #dbdee4 inset;
        display: flex;
        align-items: center;
        gap: 10px;
        border-radius: 5px;
      }

      button {
        width: 39px;
        height: 38px;
        flex-shrink: 0;
        background: rgba(255, 255, 255, 0);
        border: none;
        outline: none;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 0px 1px #dbdee4 inset;
        cursor: pointer;
      }
    }
  }
}
