.standalone-ai {
  font-family: Arial, sans-serif;
  width: 300px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;

  .header {
    background-color: #6200ea;
    color: white;
    padding: 10px;
    text-align: center;

    .new-chat-btn {
      background-color: #ffffff;
      color: #6200ea;
      border: none;
      border-radius: 5px;
      padding: 8px 12px;
      font-size: 14px;
      cursor: pointer;
    }
  }

  .search-bar {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: white;
    border-bottom: 1px solid #ddd;

    input {
      flex: 1;
      padding: 8px;
      border: 1px solid #ddd;
      border-radius: 4px;
      outline: none;
    }

    .search-icon {
      margin-left: 8px;
      font-size: 18px;
      color: gray;
    }

    .settings-icon {
      margin-left: 10px;
      font-size: 18px;
      color: gray;
      cursor: pointer;
    }
  }

  .property-list {
    padding: 10px;

    .property-item {
      display: flex;
      align-items: center;
      margin-bottom: 15px;

      .property-image {
        width: 50px;
        height: 50px;
        border-radius: 4px;
        margin-right: 10px;
      }

      .property-details {
        .property-name {
          font-weight: bold;
        }

        .property-info {
          font-size: 12px;
          color: gray;
        }

        .property-status {
          font-size: 12px;
          display: flex;
          align-items: center;

          .dot {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            margin-right: 5px;

            &.active {
              background-color: green;
            }

            &.for-sale {
              background-color: blue;
            }
          }
        }

        .property-price {
          font-weight: bold;
          color: #6200ea;
        }
      }
    }
  }

  .insight-history {
    padding: 10px;

    h3 {
      margin-bottom: 10px;
    }

    .insight-item {
      display: flex;
      justify-content: space-between;
      margin-bottom: 8px;

      .insight-title {
        font-size: 14px;
        color: black;
      }

      .insight-date {
        font-size: 12px;
        color: gray;
      }
    }
  }
}

.StandAloneBeartacticAI_sidebar-content {
  position: sticky;
  // padding: 15px 0;
  top: 155px;
  overflow-y: auto;
  max-height: calc(54vh - 133px);
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-bottom: 1px solid #eaedf3;
  .nav-card {
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    padding: 10px 20px;
    align-items: center;
    // border-bottom: 1px solid #eaedf3;
    padding-left: 17px;
    transition: all 0.2s ease-in-out;

    @media screen and (max-width: 500px) {
      .nav-card {
        width: 50px;
      }
    }

    @media screen and (max-width: 1200px) {
      flex-direction: column;
      gap: 10px;
    }

    img {
      width: 100%;
      height: 94px;
      border-radius: 5px;
      max-width: 100px;
    }

    .nav-card-info {
      font-family: Roboto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 2px;
      h2 {
        color: #3e3f42;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        @media screen and (max-width: 1000px) {
          font-size: 14px;
        }
      }

      p {
        color: #9ea0a5;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; 
      }

      h4 {
        color: var(--LP-black, #3e3f42);
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; 
      }

      .price-wrapper {
        flex-direction: row;
        justify-content: end;
        align-items: center;


        @media screen and (max-width: 900px) {
          .price-wrapper {
            flex-direction: column;
          }
        }
        @media screen and (max-width: 400px) {
          .price-wrapper {
            width: 150px;
          }
        }
        .status-wrapper {
          display: flex;
          align-items: center;
          text-align: end;
          justify-content: flex-end;
          p {
            width: 6px;
            height: 6px;
            border-radius: 100%;
          }

          h4 {
            color: #3e3f42;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px; 
            text-transform: capitalize;
          }

          button {
            display: flex;
            width: 36px;
            height: 36px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 4px;
            border: 1px solid #d8dce6;
            background: linear-gradient(0deg, #f6f7f9 0%, #fff 100%);
            box-shadow: 0px 2px 0px 0px rgba(255, 255, 255, 0.05) inset, 0px 1px 1px 0px rgba(22, 29, 37, 0.05);
          }
        }

        h2 {
          font-size: 14px;
          font-weight: 500;
          color: #3e3f42;
          margin-top: 10px;
          display: flex;
          @media screen and (max-width: 1000px) {
            font-size: 15px;
          }
        }
      }
    }

    &:focus,
    &:active {
      h2 {
        color: #7d55c7;
      }

      border-left: 4px solid #7d55c7;
      background: rgba(125, 85, 199, 0.05);
    }

    &.selected {
      h2 {
        color: #7d55c7;
      }

      border-left: 4px solid #7d55c7;
      background: rgba(125, 85, 199, 0.05);
    }
  }

  .No-Portfolio {
    min-height: 136px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #3e3f42;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0 !important;
  }
}

.loading-container,
.no-history-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 10px;
  text-align: center;
}

// Chat Section -----------------------------------------------

//

.StandAloneBeartacticAI_deals-room-left-container {
  width: 100%;

  position: fixed;
  border-right: 1px solid #eaeaea;
  background: #fff;
  z-index: 1;
  transition: all 0.2s ease-in-out;
  &.visible {
    max-width: 334px;
  }

  &.notVisible {
    max-width: 113px;
  }

  .Start_NewChat {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 24px 31px 40px 24px;
    position: relative;
    button {
      font-family: Roboto;
      height: 44px !important;
      font-style: normal;
      font-weight: 500;
      line-height: 2px;
      display: flex;
      padding: 10px 20px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      width: 100%;
      text-wrap: nowrap;
      &.newChatBtn {
        color: #ffffff !important;
        font-size: 16px !important;
        border-radius: 4px !important;
        background: #7d55c7 !important;
        box-shadow: 0px 1px 1px 0px rgba(19, 31, 21, 0.1), 0px 2px 0px 0px rgba(255, 255, 255, 0.06) inset !important;
        border: 0;
        outline: 0;
        max-width: 134px;
      }

      &.manageReportsBtn {
        color: #7d55c7 !important;
        border-radius: 4px !important;
        outline: 0;
        background: none !important;
        border: 1px solid #7d55c7 !important;
        box-shadow: 0px 1px 1px 0px rgba(19, 31, 21, 0.1), 0px 2px 0px 0px rgba(255, 255, 255, 0.06) inset !important;
        max-width: 134px;
      }

      &.expandButton {
        height: 42px !important;
        border-radius: 30px !important;
        border: 1px solid #ededed !important;
        background: #feffff !important;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15) !important;
        position: absolute;
        width: 42px;
        right: -21px;
        padding: 0;
      }
    }
  }

  .StandAloneBeartacticAI_search-input-wrapper {
    padding: 15px 32px 15px 33px;
    border-bottom: 1px solid #eaedf3;
    height: 53px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    @media screen and (max-width: 1000px) {
      padding: 6px 5px;
    }
    @media screen and (max-width: 900px) {
      margin-top: 0px;
    }

    input {
      outline: none;
      border: none;
      height: 38px;
    }

    ::placeholder {
      font-family: Roboto;
      font-weight: 400;
      font-size: 14px;
      color: #9ea0a5;
    }

    .search-icons {
      display: flex;
      column-gap: 20px;
      align-items: center;
      @media screen and (max-width: 1200px) {
        gap: 10px;
      }

      img {
        min-width: 20px;
        min-height: 20px;
        cursor: pointer;
      }
    }
  }

  .StandAloneBeartacticAI_portfolio-container {
    font-family: Roboto;
    display: grid;
    justify-items: end;
    grid-template-columns: 100%;

    .sidebar {
      width: 100%;
      @media screen and (max-width: 700px) {
        width: 80px !important;
      }
      @media screen and (max-width: 1200px) {
        width: 281px !important;
      }
      @media screen and (max-width: 1000px) {
        width: 257px !important;
      }

      .sidebar-header {
        position: sticky;
        top: 76px;
        z-index: 10;
        display: flex;
        align-items: center;
        text-decoration: none;
        padding: 10px 65px 10px 35px;
        border-top: 1px solid #eaeaea;
        border-bottom: 1px solid #eaeaea;
        gap: 24px;
        img {
          height: 20px;
          width: 20px;
          border-radius: 4px;
        }

        .sideheader-textWrapper {
          display: flex;
          flex-direction: column;

          h2 {
            color: #3e3f42;
            font-family: Roboto;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px; /* 150% */
            @media screen and (max-width: 1000px) {
              font-size: 14px;
            }
          }

          h4 {
            color: var(--959595-mid-grey-2, #959595);
            font-family: Roboto;
            font-size: 12px !important;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-transform: uppercase;

            span {
              font-size: 12px !important;
            }
          }
        }

        &:focus,
        &:active {
          h2 {
            color: #7757c0;
          }

          background-color: #f8f6fb;
          border-left: 4px solid #7757c0;
        }
      }

      .search-input-wrapper {
        padding: 6px 10px;
        border-bottom: 1px solid #eaedf3;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-top: 34px;
        width: 100%;
        @media screen and (max-width: 1000px) {
          padding: 6px 5px;
        }
        @media screen and (max-width: 900px) {
          margin-top: 0px;
        }

        input {
          outline: none;
          border: none;
          height: 38px;
        }

        ::placeholder {
          font-family: Roboto;
          font-weight: 400;
          font-size: 14px;
          color: #9ea0a5;
        }

        .search-icons {
          display: flex;
          column-gap: 20px;
          align-items: center;
          @media screen and (max-width: 1200px) {
            gap: 10px;
          }

          img {
            min-width: 20px;
            min-height: 20px;
            cursor: pointer;
          }
        }
      }

      // Left Slider

      .sidebar-content {
        position: sticky;
        top: 155px;
        overflow-y: auto;
        max-height: calc(100vh - 142px);
        padding-bottom: 10px;
        display: flex;
        flex-direction: column;
        width: 100%;
        // @media screen and (max-width: 700px) {
        //   width: 80px !important;
        // }
        // @media screen and (max-width: 1200px) {
        //   width: 280px !important;
        // }
        // @media screen and (max-width: 1000px) {
        //   width: 277px !important;
        // }

        .nav-card {
          display: flex;
          flex-direction: row;
          // justify-content: space-evenly;
          column-gap: 20px;
          // padding: 20px 25px;
          padding: 20px;
          border-bottom: 1px solid #eaedf3;

          @media screen and (max-width: 500px) {
            .nav-card {
              width: 50px;
            }
          }

          @media screen and (max-width: 1200px) {
            flex-direction: column;
            gap: 10px;
          }

          img {
            width: 100%;
            height: 98px;
            border-radius: 5px;
            max-width: 100px;
          }

          .nav-card-info {
            font-family: Roboto;
            display: flex;
            flex-direction: column;
            // justify-content: space-around;
            h2 {
              font-size: 14px;
              font-weight: 500;
              color: #3e3f42;
              @media screen and (max-width: 1000px) {
                font-size: 14px;
              }
            }

            p {
              color: var(--primary-gray-100-text, #9ea0a5);
              leading-trim: both;
              text-edge: cap;
              font-family: Roboto;
              font-size: 10px;
              font-style: normal;
              font-weight: 500;
              line-height: 18px;
            }

            h4 {
              color: var(--Black, #3e3f42);
              leading-trim: both;
              text-edge: cap;
              font-family: Roboto;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 22px; /* 157.143% */
            }

            .price-wrapper {
              // display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              // width: 230px;

              @media screen and (max-width: 900px) {
                .price-wrapper {
                  flex-direction: column;
                }
              }
              @media screen and (max-width: 400px) {
                .price-wrapper {
                  width: 150px;
                }
              }
              .status-wrapper {
                display: flex;
                align-items: center;
                column-gap: 10px;
                p {
                  width: 6px;
                  height: 6px;
                  border-radius: 100%;
                }

                h4 {
                  color: var(--LP-black, #3e3f42);
                  font-family: Roboto;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 18px; /* 150% */
                }
              }

              h2 {
                font-size: 14px;
                font-weight: 500;
                color: #3e3f42;
                margin-top: 10px;
                display: flex;
                @media screen and (max-width: 1000px) {
                  font-size: 15px;
                }
              }
            }
          }

          &:focus,
          &:active {
            h2 {
              color: #7757c0;
            }

            background-color: #f8f6fb;
            border-left: 4px solid #7757c0;
          }

          &.selected {
            h2 {
              color: #7757c0;
            }

            background-color: #f8f6fb;
            border-left: 4px solid #7757c0;
          }
        }
      }
    }

    .main-container {
      .main-header {
        position: sticky;
        top: 75px;
        background-color: #fbfbfd;
        z-index: 5;
        display: flex;
        // justify-content: flex-end;
        align-items: center;
        border-bottom: 1px solid #dadada;
        padding: 0 20px;
        height: 74px;
      }

      .main-content {
        overflow-y: auto;

        .dashboard-container {
          background-color: #fbfbfd;
          padding: 20px;
          display: flex;
          flex-wrap: wrap;
          gap: 20px;

          .portfolioDatanew-card {
            width: calc(33.33% - 20px);
            padding: 10px;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            gap: 20px;

            .All-assect-images {
              width: 100%;
              height: 40%;
              max-height: 250px;
              border-radius: 10px;
            }

            .card-info {
              margin-top: 10px;
              font-family: Roboto;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              row-gap: 10px;
              gap: 10px;
              height: 100%;

              h2 {
                font-size: 16px;
                font-weight: 500;
                color: #3e3f42;
              }

              p {
                font-size: 14px;
                color: #9ea0a5;
                line-height: 20px;
              }

              .price-wrapper {
                display: flex;
                justify-content: space-between;
                h2 {
                  color: #3e3f42;
                  font-size: 18px;
                }

                p {
                  font-size: 14px;
                }
              }

              .asset-link-btn {
                // width: 100%;
                font-size: 14px;
              }

              .upperSection,
              .lowerSection {
                display: flex;
                flex-direction: column;
                gap: 5px;
              }
            }
          }
        }

        .property-details-container {
          min-height: 100vh;
          overflow-x: hidden;
          .details-header-container {
            height: 48px;
            background: #fff;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 20px;

            @media screen and (max-width: 1000px) {
              height: 50px;
            }
            @media screen and (max-width: 900px) {
              padding: 0 0px !important;
              margin: 0px 25px !important;
            }

            .details-header-left {
              display: flex;
              align-items: center;
              gap: 20px;

              Link {
                align-items: center;
              }

              a {
                color: inherit;
                text-decoration: none;
                display: flex;
                flex-direction: row;
                align-items: center;
                column-gap: 10px;

                img {
                  width: 20px;
                  height: 20px;
                }

                span {
                  font-family: Roboto;
                  font-weight: 500;
                  font-size: 12px;
                  color: #6b6c6f;
                  text-transform: uppercase;
                }

                &.active {
                  color: #3e3f42;

                  span {
                    color: #3e3f42;
                  }
                }
              }

              a:not(:first-child) {
                border-left: 1px solid #eaedf3;
                /* Adjust the color and size as needed */
                padding-left: 10px;
                height: 40px;
              }
            }

            .details-header-right {
              display: flex;
              align-items: center;
              gap: 20px;

              h2 {
                color: #9ea0a5;
                font-family: Roboto;
                font-weight: 500;
                font-size: 12px;
              }
              .Greendot {
                height: 10px;
                width: 10px;
                background-color: green;
                border-radius: 50%;
                display: inline-block;
                margin-right: 5px; /* Adjust as needed */
              }

              .status-input-wrapper {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 10px;

                p {
                  width: 10px;
                  height: 10px;
                  border-radius: 100%;
                }

                @media screen and (max-width: 900px) {
                  font-size: 14px !important;
                }
              }
            }
          }

          .property-details {
            display: grid;
            grid-template-columns: 66% 1fr;
            padding: 0px 20px;
            column-gap: 1rem;

            @media screen and (max-width: 1000px) {
              grid-template-columns: repeat(1, 1fr);
              padding: 0px 1rem !important;
            }

            .property-main-container {
              img {
                height: 460px;
                width: 100%;
                object-fit: cover;
                border-radius: 5px;
              }

              .temp {
                height: 430px;
                width: 100%;
                display: flex;
                justify-content: center;
              }

              .property-tabs {
                margin-top: 14px;
                display: flex;
                flex-direction: row;
                column-gap: 20px;
                border-bottom: 1px solid #eaedf3;

                a {
                  font-family: Roboto;
                  color: #6b6c6f;
                  font-weight: 400;
                  font-size: 12px;
                  padding-bottom: 14px;

                  &.active {
                    border-bottom: 2px solid #7d55c7;
                    transition: all 0.3;
                  }
                }
              }

              .tab-content {
                padding: 30px 0px;

                .property-details-card {
                  display: grid;
                  grid-template-columns: 100%;
                  border: 1px solid #eaedf3;
                  border-radius: 5px;
                  width: 100%;
                  padding: 30px;
                  box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.1);
                  @media screen and (max-width: 1400px) {
                    grid-template-columns: repeat(1, 1fr);
                    gap: 20px;
                  }

                  .detail-card-left-container {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-evenly;
                    row-gap: 27px;

                    .details_with_button {
                      display: flex;
                      align-items: center;
                      justify-content: space-between;
                      button {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 128px;
                        height: 38px;
                        padding: 10px;
                        border: none;
                        border-radius: 4px;
                        background-color: white; /* Blue background color */

                        font-size: 1rem; /* Adjust font size */
                        font-weight: bold; /* Bold text */
                        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Soft shadow */
                        cursor: pointer; /* Pointer cursor on hover */
                        transition: background-color 0.3s ease, transform 0.3s ease; /* Smooth transitions */
                      }

                      button:hover {
                        background: var(--Primary-Purple-500, #7d55c7);
                        color: white;
                        transform: translateY(-1px); /* Slight lift on hover */
                      }

                      button:active {
                        background-color: #003f7f; /* Even darker blue on active/click */
                        transform: translateY(0); /* Reset transform on active/click */
                      }

                      button:focus {
                        outline: none; /* Remove default outline */
                        box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5); /* Blue outline on focus */
                      }
                    }

                    @media screen and (max-width: 1000px) {
                      row-gap: 5px !important;
                    }

                    h4 {
                      text-transform: uppercase;
                      font-family: Roboto;
                      font-weight: 500;
                      font-size: 12px;
                      color: #9ea0a5;
                      line-height: 18px;
                    }

                    h1 {
                      font-family: Roboto;
                      font-weight: 400;
                      font-size: 28px;
                      color: #3e3f42;
                      line-height: 38px;

                      @media screen and (max-width: 1000px) {
                        font-size: 24px !important;
                      }
                    }

                    h2 {
                      font-family: Roboto;
                      font-weight: 400;
                      font-size: 14px;
                      color: #9ea0a5;
                      line-height: 22px;
                    }

                    p {
                      font-family: Roboto;
                      font-weight: 400;
                      font-size: 14px;
                      color: #6b6c6f;
                      max-width: 85%;
                      line-height: 22px;

                      @media screen and (max-width: 1000px) {
                        font-size: 13px !important;
                      }
                    }
                  }

                  .detail-card-right-container {
                    display: flex;
                    flex-direction: column;
                    // justify-content: space-evenly;
                    align-items: flex-end;
                    row-gap: 14px;

                    @media screen and (max-width: 1000px) {
                      row-gap: 2px;
                      .eco_pricing {
                        margin-top: 10px;
                      }
                    }

                    h4 {
                      display: flex;
                      flex-direction: row;
                      column-gap: 5px;
                      align-items: center;
                      text-transform: uppercase;
                      font-family: Roboto;
                      font-weight: 500;
                      font-size: 12px;
                      color: #9ea0a5;
                      line-height: 18px;

                      .info-icon {
                        width: 17px;
                        height: 17px;
                        margin-bottom: -2px;
                      }
                    }

                    h1 {
                      display: flex;
                      flex-direction: row;
                      column-gap: 10px;
                      align-items: last baseline;
                      font-family: Roboto;
                      font-weight: 500;
                      font-size: 20px;
                      color: #3e3f42;
                      line-height: 38px;
                      flex-wrap: wrap;
                      @media screen and (max-width: 1000px) {
                        font-size: 16px !important;
                      }

                      span {
                        font-family: Roboto;
                        font-size: 14px;
                        line-height: 18px;
                        font-weight: 400;
                        color: #6b6c6f;
                      }
                    }

                    h2 {
                      display: flex;
                      flex-direction: row;
                      column-gap: 5px;
                      align-items: center;
                      text-transform: uppercase;
                      font-family: Roboto;
                      font-weight: 500;
                      font-size: 12px;
                      color: #9ea0a5;
                      line-height: 18px;

                      .info-icon {
                        width: 17px;
                        height: 17px;
                        margin-bottom: -2px;
                      }
                    }

                    .economic-pricing-text {
                      // align-items: center;
                      span {
                        display: flex;
                        column-gap: 5px;
                        align-items: baseline;
                      }

                      p {
                        color: #009639;
                        font-size: 14px;
                        font-weight: 400;
                      }

                      img {
                        width: 20px;
                        height: 20px;
                        margin-bottom: -10px;
                      }
                    }
                  }
                }
              }
            }

            .property-features-container {
              .property-features {
                border: 1px solid #eaedf3;
                border-radius: 5px;
                padding: 35px 30px;
                display: flex;
                flex-direction: column;
                row-gap: 25px;
                box-shadow: 5px 5px 9px rgba(0, 0, 0, 0);
                -webkit-box-shadow: 5px 5px 9px rgba(0, 0, 0, 0);
                -moz-box-shadow: 5px 5px 9px rgba(0, 0, 0, 0);

                @media screen and (max-width: 1000px) {
                  .show_more_btn_portfolio,
                  .view_floor_btn_portfolio,
                  .edit_asset_btn_portfolio {
                    width: 300px;
                    margin: auto;
                  }
                }
              }

              h2 {
                text-transform: uppercase;
                font-family: Roboto;
                font-weight: 500;
                font-size: 12px;
                color: #9ea0a5;
              }

              .details-wrapper {
                display: flex;
                flex-direction: column;
                row-gap: 15px;

                .detail-item {
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;

                  .item-type {
                    display: flex;
                    align-items: center;
                    column-gap: 8px;

                    img {
                      width: 20px;
                      height: 20px;
                    }

                    h4 {
                      font-size: 14px;
                      font-weight: 400;
                      color: #6b6c6f;
                    }
                  }

                  p {
                    font-weight: 400;
                    font-size: 14px;
                    color: #3e3f42;
                  }
                }
              }

              .line {
                background-color: #eaedf3;
                height: 0px;
                border: solid 1px #eaedf3;
                width: 100%;
              }

              .PrimaryButton.white-bg.noMargin.secondary {
                @media only screen and (max-width: 1000px) {
                  width: 100%;
                  max-width: 300px;
                  margin: 0 auto;
                }
              }
            }
          }

          .overview-container {
            padding: 0px 20px;
            width: 100%;
            @media screen and (max-width: 1000px) {
              grid-template-columns: repeat(1, 1fr);
              padding: 0px 3rem !important;
            }

            .overview-header {
              font-family: Roboto;
              color: #3e3f42;
              font-weight: 500;
              font-size: 18px;
              line-height: 28px;

              @media screen and (max-width: 1000px) {
                margin-top: 15px;
              }
            }

            .overview-tabs {
              margin-top: 14px;
              display: flex;
              flex-direction: row;
              column-gap: 20px;
              border-bottom: 1px solid #eaedf3;
              @media screen and (max-width: 900px) {
                // width: 90% !important;
                column-gap: 17px !important;
              }

              a {
                font-family: Roboto;
                color: #6b6c6f;
                font-weight: 400;
                font-size: 12px;
                padding-bottom: 14px;

                &.active {
                  border-bottom: 2px solid #7d55c7;
                  transition: all 0.3;
                }
              }
            }
          }

          .market-tab-container {
            padding: 0px 2rem;

            @media screen and (max-width: 1000px) {
              grid-template-columns: repeat(1, 1fr);
              padding: 0px 3rem !important;
            }

            .market-header {
              font-family: Roboto;
              color: #3e3f42;
              font-weight: 500;
              font-size: 18px;
              line-height: 28px;
            }

            .market-tabs {
              margin-top: 14px;
              display: flex;
              flex-direction: row;
              column-gap: 20px;
              border-bottom: 1px solid #eaedf3;

              a {
                font-family: Roboto;
                color: #6b6c6f;
                font-weight: 400;
                font-size: 12px;
                padding-bottom: 14px;

                &.active {
                  border-bottom: 2px solid #7d55c7;
                  transition: all 0.3;
                }
              }
            }

            .market-tab-content {
              padding: 30px 0px;

              .market-s-main-wrapper {
              }
            }
          }
        }
      }
    }
  }
}

.Insight_History_Container {
  max-height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  padding: 20px;
  gap: 20px;
  h1 {
    color: #3e3f42;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .Insight_History {
    flex: 1;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    line-height: 18.75px;
    text-align: left;
    // min-height: 165px;
    max-height: 100px;
    overflow-y: auto;

    .loading-container,
    .no-history-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      text-align: center;
    }

    h1 {
      margin-bottom: 10px;
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      gap: 5px;
      flex-direction: column;
      li {
        cursor: pointer;
        transition: background-color 0.3s ease;
        border-radius: 6px;
        display: flex;
        padding: 0px 10px;
        align-items: flex-start;
        flex-direction: column;
        border: 1px solid transparent;

        p {
          color: #78797a;
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 26px;
        }

        span {
          color: #adb2b6;
          font-family: Roboto;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        &:hover {
          border: 1px solid #7d55c7;
          background: rgba(125, 85, 199, 0.05);
        }

        &.active-chat {
          border: 1px solid #7d55c7;
          background: rgba(125, 85, 199, 0.05);
          height: max-content;
        }
      }
    }
  }
}

.Insight_History ul li p {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #78797a;
}

.StandAloneBeartacticAI-chat-container {
  display: flex;
  max-width: 1106px;
  justify-content: center;
  margin-left: auto;
  min-height: 90vh;

  .StandAloneBeartacticAI-chat-content {
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 10px;
    gap: 20px;
    width: 100%;

    &.fade-in {
      opacity: 0;
      animation: fadeInEffect 0.3s forwards ease-in-out;
    }

    .StandAloneBeartacticAI-chat-messages-container {
      height: 100%;
      overflow: hidden;
      padding-top: 30px;
      padding-left: 80px;
      .StandAloneBeartacticAI-chat-messages {
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        margin: 0;
        box-sizing: border-box;
        padding-right: 60px;
        padding-top: 40px;
        display: flex;
        flex-direction: column;
        gap: 15px;
        max-height: 70vh;

        &::-webkit-scrollbar {
          width: 8px;
          padding-left: 8px;
          height: 8px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #7d55c7;
          height: 10px;
        }

        &::-webkit-scrollbar-track {
          background-color: #e0e0e0;
        }

        &::-webkit-scrollbar-thumb:hover {
          background-color: #7d55c7;
        }
      }
    }
  }
}

.StandAloneBeartacticAI_deals-room-left-container {
  &.visible {
    max-width: 334px;
    min-height: 100vh;

    .StandAloneBeartacticAI_portfolio-container,
    .Insight_History_Container {
      display: flex;
    }
  }

  &.notVisible {
    max-width: 113px;
    border-right: 1px solid transparent;

    .Start_NewChat {
      flex-direction: column-reverse;
      padding: 24px 29px 40px 40px;
      gap: 20px;
      button {
        width: 44px;
        height: 44px;
      }

      .newChatBtn {
        padding: 0;
      }

      .manageReportsBtn {
        display: none;
      }

      .expandButton {
        position: static;

        svg {
          transform: rotate(180deg);
        }
      }
    }

    .StandAloneBeartacticAI_portfolio-container,
    .Insight_History_Container {
      display: none;
    }
  }
}

.chatContainerEnvironment {
  max-width: 100vw;
  margin-left: 100px !important;

  .StandAloneBeartacticAI-chat-container {
    margin-left: 0;
    max-width: 100%;
    padding-right: 20px;

    .StandAloneBeartacticAI-chat-content {
      .StandAloneBeartacticAI-chat-messages-container {
        padding-left: 141px !important;

        .StandAloneBeartacticAI-chat-messages {
          .StandAloneBeartacticAI_message {
            max-width: 930px !important;
          }
        }
      }
    }

    .ChatLeft_chat-input {
      margin-left: 141px !important;
    }
  }
}

.loading-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 84vh;
}

.image-gallery {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2px;
  width: fit-content;
}


.chat-item-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 103%;
  position: relative; /* Allows child elements to use absolute positioning */
}

.three-dot-menu {
  cursor: pointer;
  padding: 0 10px;
  position: relative; /* Makes the delete button position relative to this container */
}

.three-dot-menu span {
  font-size: 18px;
}

.delete-button {
  position: absolute;
  top: 83%;
  left: 202px;
  background-color: red;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  z-index: 10;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  white-space: nowrap;
}

.delete-button:hover {
  background-color: darkred;
}
