.AssetSearch {
  min-width: 1440px;
  min-height: 750px;
  overflow-x: hidden;
  position: relative;
  .backGroundImg {
    position: absolute;
    top: 72px;
    overflow: hidden;
    z-index: 0;
    min-width: 1440px;
    min-height: 678px;
    // background-image: url(../../assets/Image/assetSearch.png);
    background-repeat: no-repeat;
  }
  .topBgImg {
    position: absolute;
    z-index: 2;
    top: 0px;
    overflow: hidden;
    min-width: 1440px;
    min-height: 192px;
    background-size: cover;
    background-position: center;
    background-image: url("../../assets/Image/assetDiscovery/BannerOne.png");
    background-repeat: no-repeat;
    &.Crowdfunding {
      background-image: url("../../assets/Image/assetDiscovery/BannerTwo.png");
    }
  }
}

.AssetDiscoverySearch-absoluteCon {
  position: absolute;
  top: 135px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
  display: flex;
  max-width: 100%;
  gap: 10px;
  .hoverClickDiv {
    height: 200px;
    width: 300px;
    position: absolute;
    left: 600px;
    top: 290px;
  }
  .AssetSearch_property_cards {
    margin-top: -61px;
    .cards_wrapper {
      overflow-y: scroll;
      overflow-x: hidden;
      height: 660px;
    }
    .property_card_mini_wrapper {
      display: flex;
      flex-direction: column;
      width: min-content;
      border-radius: 4px;
      margin-bottom: 30px;
      // border: 1px solid var(--LP-very-light, #eaedf3);
      background: #fff;
      box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.15);
      height: min-content;
      width: 350px !important;
      .react-tabs {
        .react-tabs__tab-list {
          display: flex;
          width: 100%;
          justify-content: space-around;
          .react-tabs__tab {
            display: flex;
            width: 58px;
            padding: 10px 0px;
            padding-bottom: 0px;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            gap: 8px;
            span {
              color: var(--LP-mid-gray, #6b6c6f);
              text-align: center;
              font-family: Roboto;
              font-size: 10px;
              font-style: normal;
              font-weight: 400;
              line-height: 16px; /* 160% */
              text-wrap: nowrap;
            }
            &.active {
              background: linear-gradient(0deg, rgba(186, 175, 207, 0.1) 0%, rgba(186, 175, 207, 0.1) 100%), #fff;
              box-shadow: 0px 2px 4px 0px rgba(125, 85, 199, 0.1) inset;
              svg {
                path {
                  fill: #7d55c7;
                }
              }
            }
          }
        }
        .react-tabs__tab-panel {
          .tab_panel_wrapper {
            position: relative;
            background: linear-gradient(0deg, rgba(186, 175, 207, 0.1) 0%, rgba(186, 175, 207, 0.1) 100%), #fff;
            padding: 15px 30px 0px 30px;
            // height: 235px;
            overflow-y: scroll;
            padding: 15px;
            .contact_container {
              .row {
                display: flex;
                gap: 15px;

                margin: 10px 0px;
                max-width: 280px;
                p {
                  color: var(--LP-black, #3e3f42);
                  font-family: Roboto;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 22px; /* 157.143% */
                }
                span {
                  color: #6b6c6f;
                  font-family: Roboto;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 22px; /* 157.143% */
                  text-decoration-line: underline;
                }
              }
            }
            .bookmark_container {
              .header {
                display: flex;
                align-items: center;
                gap: 10px;
                margin: 15px 0px;
                padding: 0px 10px;
                p {
                  color: var(--LP-mid-gray, #6b6c6f);
                  font-family: Roboto;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 22px; /* 157.143% */
                }
              }
              .labels_wrapper {
                display: flex;
                flex-direction: column;
                gap: 10px;
                &:last-child {
                  margin-bottom: 20px;
                }
                .button_wrapper {
                  display: flex;
                  gap: 10px;
                }
                .label {
                  display: flex;
                  padding: 0px 10px;
                  border-radius: 20px;
                  height: 38px;
                  justify-content: space-between;
                  align-items: center;
                  width: 100%;
                  border: 1px solid #e2e5ed;

                  input {
                    all: unset;
                    margin-bottom: 3px;
                  }
                  .left {
                    display: flex;
                    gap: 5px;
                    align-items: center;
                    img {
                      width: 20px;
                      height: 24px;
                    }
                    p {
                      color: var(--ffffff-white, #fff);
                      font-family: Roboto;
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 22px; /* 157.143% */
                      margin-top: 2px;
                    }
                  }
                  .right {
                    span {
                      color: var(--ffffff-white, #fff);
                      font-family: Roboto;
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 22px; /* 157.143% */
                    }
                  }
                }
                .colour_picker {
                  display: flex;
                  justify-content: space-between;
                  .circle {
                    height: 24px;
                    width: 24px;
                    box-shadow: inset 0.1em 0.1em 0.2em 0 rgba(151, 151, 151, 0.382), inset -0.2em -0.2em 0.2em 0 rgba(0, 0, 0, 0.01);
                    &.active {
                      border: 4px solid #fff;
                      box-shadow: rgba(50, 50, 50, 0.3) 0px 0px 10px 0px;
                    }
                  }
                }
              }
            }
            .notebook_wrapper {
              height: 100%;
              width: 290px;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              .messages_wrapper {
                height: 100%;
                padding-bottom: 30px;
                overflow: scroll;
                .message {
                  margin-bottom: 20px;
                  .header {
                    display: flex;
                    gap: 10px;
                    margin-bottom: 10px;
                    .left {
                    }
                    .right {
                      p {
                        color: #3e3f42;
                        font-family: Roboto;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 16px; /* 157.143% */
                      }
                      span {
                        color: #9ea0a5;
                        font-family: Roboto;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 16px; /* 150% */
                      }
                    }
                  }
                  .textbox {
                    padding: 8px 24px;
                    border-radius: 0px 10px 10px 10px;
                    background: linear-gradient(90deg, #6b5de7 0%, #915cf4 101.5%);
                    p {
                      color: #fff;
                      font-family: Roboto;
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 22px; /* 157.143% */
                    }
                    width: 80%;
                  }
                }
              }
              .form_wrapper {
                display: flex;
                position: absolute;
                width: 350px;
                left: 0px;
                bottom: 0px;
                width: 100%;
                fill: rgba(255, 255, 255, 0);
                border: 1px solid #eaedf3;

                background: #ffffff;
                form {
                  display: flex;
                  width: 100%;
                  justify-content: flex-start;

                  align-items: center;
                  padding: 14px 30px;
                  input {
                    all: unset;
                    padding: 0px !important;
                    margin: auto 0;
                    width: 280px;
                  }
                }
                button {
                  all: unset;
                  margin-top: 4px;
                }
              }
            }
            .comparision_wrapper {
              display: flex;
              flex-direction: column;
              gap: 20px;
              padding: 10px 0px;
              width: 290px;
              .top {
                display: flex;
                align-items: center;
                justify-content: space-between;

                button {
                  display: flex;
                  padding: 8px 11px;
                  align-items: center;
                  gap: 10px;
                  border-radius: 4px;
                  border: 1px solid #d8dce6;
                  background: linear-gradient(0deg, #f6f7f9 0%, #fff 100%);

                  box-shadow: 0px 1px 1px 0px rgba(22, 29, 37, 0.05), 0px 2px 0px 0px rgba(255, 255, 255, 0.05) inset;

                  p {
                    color: var(--purple-pantone-2665-c, #7d55c7);
                    text-align: center;
                    font-family: Roboto;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 22px; /* 157.143% */
                  }
                }
                p {
                  color: #3e3f42;
                  text-align: center;
                  font-family: Roboto;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 22px; /* 157.143% */
                }
              }
              .mid {
                .choice_list {
                  padding: 20px 0px;
                  display: flex;
                  flex-direction: column;
                  gap: 20px;
                  border-top: 1px solid #eaedf3;
                  border-bottom: 1px solid #eaedf3;
                  .row {
                    display: flex;
                    gap: 10px;
                    align-items: center;
                    text-wrap: nowrap;
                    .removeButton {
                      margin-left: 20px;
                    }
                  }
                }
              }
              .bottom {
                button {
                  display: inline-flex;
                  padding: 8px 20px;
                  align-items: center;
                  gap: 12px;
                  border-radius: 4px;
                  border: 1px solid var(--purple-pantone-2665-c-71, rgba(125, 85, 199, 0.71));
                  background: linear-gradient(0deg, rgba(125, 85, 199, 0.3) 0%, rgba(255, 255, 255, 0) 107.78%), #7d55c7;
                  background-blend-mode: multiply, normal;

                  box-shadow: 0px 1px 1px 0px rgba(19, 31, 21, 0.1), 0px 2px 0px 0px rgba(255, 255, 255, 0.06) inset;
                  p {
                    color: #fff;
                    text-align: center;
                    font-family: Roboto;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 22px; /* 157.143% */
                  }
                }
              }
            }
            .share_wrapper {
              .input_wrapper {
                display: flex;
                align-items: end;
                width: 100%;
                .inputBoxMain {
                  width: 100%;
                }
                .PrimaryButton {
                  margin: 0px;
                  height: 36px;
                  margin-bottom: 8px;
                  margin-left: -2px;
                  padding: 0px 10px;
                  display: flex;
                  align-items: center;
                }
              }
              .tags {
                display: flex;
                flex-direction: column;
                gap: 5px;
                padding-bottom: 20px;
                border-bottom: 1px solid #eaedf3;
                .tag {
                  gap: 5px;

                  border: 1px solid #eaedf3;
                  border-radius: 10px;
                  background: #fff;
                  align-items: center;
                  width: max-content;
                  padding: 3px 10px;
                  display: flex !important;

                  p {
                    color: #3e3f42;
                    font-family: Roboto;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 18px; /* 150% */
                    text-wrap: nowrap;
                  }
                }
              }
              .copy_card {
                margin-bottom: 20px;
                position: relative;
                .circle {
                  left: 26px;
                  position: relative;
                  top: 22px;
                  top: 20px;
                  width: 48px;
                  height: 48px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  background-color: #a386d7;
                  svg {
                  }
                }
                .box {
                  border-radius: 4px;
                  border: 1px solid #e2e5ed;
                  background: #fff;
                  padding: 28px 30px;
                  .text_wrapper {
                    span {
                      color: #6b6c6f;
                      font-family: Roboto;
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 22px; /* 157.143% */
                    }
                    p {
                      color: #3e3f42;
                      font-family: Roboto;
                      font-size: 16px;
                      font-style: normal;
                      font-weight: 500;
                      line-height: 24px; /* 150% */
                    }
                  }
                  .PrimaryButton {
                    width: 100px;
                    margin-top: 20px;
                    padding: 8px;
                  }
                }
              }
            }
            .investor_feed {
              .header {
                margin-top: 8px;
                display: flex;
                justify-content: space-between;
                p {
                  color: var(--LP-mid-gray, #6b6c6f);
                  font-family: Roboto;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 18px; /* 112.5% */
                }
                span {
                  color: var(--LP-mid-gray, #6b6c6f);
                  font-family: Roboto;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 13px;
                }
              }
              .bottom_wrapper {
                max-width: 290px;
                margin-top: 20px;
              }
            }
          }
        }
      }
      .property_card_mini {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 12px 12px 12px 12px;

        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
        flex-shrink: 0;
        width: 350px !important;

        .property_card_mini-T {
          display: flex;
          justify-content: space-between;
          gap: 14px;
          .photo_wrapper {
            display: flex;
            flex-direction: column;
            margin-bottom: 0px !important;
            img {
              width: 100px;
              height: 90px;
            }

            .tag {
              position: relative;
              z-index: 9;
              display: flex;
              padding: 4px;
              margin-bottom: -26px;
              /* display: inline-flex; */
              padding: 5px 10px;
              align-items: center;
              gap: 10px;
              border-radius: 4px 0px;
              background: #eaedf3;
              box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.1);
              width: 120px;
              p {
                color: var(--LP-mid-grey-_lib, #6b6c6f);
                text-align: center;
                font-family: Roboto;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }
            }
            margin-bottom: 10px;
          }
          .text_wrapper {
            h3 {
              text-wrap: nowrap;
              color: var(--LP-black, #3e3f42);
              font-family: Roboto;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 24px; /* 150% */
              display: flex;
            }
            p {
              margin-top: 5px;
              display: flex;
              color: #9ea0a5;
              font-family: Roboto;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 16px; /* 133.333% */
            }
            .price_wrapper {
              margin-top: 10px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              h2 {
                color: #3e3f42;
                font-family: Roboto;
                font-size: 20px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
              }
              span {
                color: var(--LP-gray, #9ea0a5);
                font-family: Roboto;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 100%; /* 12px */
              }
            }
          }
        }
        .property_card_mini-M {
          display: flex;
          flex-direction: row;
          gap: 10px;

          .boxes {
            display: flex;
            flex-direction: column;
            gap: 18px;
            .top {
              display: flex;
              gap: 8px;
              .box {
                display: flex;
                width: 100%;
                padding: 6px 7px;
                flex-direction: column;
                align-items: center;
                gap: 4px;
                color: var(--LP-mid-grey-_lib, #6b6c6f);
                text-align: center;
                font-family: Roboto;
                font-size: 10px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                border-radius: 4px;
                border: 0px solid var(--LP-very-light, #eaedf3);
                background: #eaeaea;
                color: var(--LP-gray, #9ea0a5);
                text-align: center;
                font-family: Roboto;
                font-size: 10px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                height: 50px;
                text-wrap: nowrap;
                span {
                  color: var(--LP-mid-gray, #6b6c6f);
                  text-align: center;
                  font-family: Roboto;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 18px; /* 128.571% */
                }
              }
            }
            .bottom {
              display: flex;
              justify-content: space-between;
              div {
                span {
                  color: var(--LP-mid-gray, #6b6c6f);
                  font-family: Roboto;
                  font-size: 10px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 100%; /* 10px */
                }
                color: var(--LP-gray, #9ea0a5);
                font-family: Roboto;
                font-size: 10px;
                font-style: normal;
                font-weight: 400;
                line-height: 100%;
              }
            }
          }
          .stats {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 4px 0px;
            gap: 10px;
            .date,
            .views,
            .active {
              display: flex;
              gap: 6px;
              color: var(--LP-gray, #9ea0a5) !important;
              font-family: Roboto;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 18px; /* 150% */
              span {
                color: var(--LP-gray, #9ea0a5);
                font-family: Roboto;
                font-size: 11px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
              }
              .dotgreen {
                height: 4px;
                width: 4px;
                border-radius: 200px;
                background-color: #34aa44;
              }
            }
            .active {
              display: flex;
              align-items: center;
            }
          }
          .timer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .left {
              p {
                color: var(--LP-mid-grey-_lib, #6b6c6f);
                font-family: Roboto;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px; /* 150% */
              }
            }
            .right {
              display: flex;
              justify-content: space-around;
              width: 220px;
              .time {
                display: flex;
                flex-direction: column;
                p {
                  color: var(--purple-pantone-2665-c, #7d55c7);
                  text-align: center;
                  font-family: Roboto;
                  font-size: 24px;
                  font-style: normal;
                  font-weight: 300;
                  line-height: 110%; /* 26.4px */
                }
                span {
                  color: var(--purple-pantone-2665-c-71, rgba(125, 85, 199, 0.71));
                  text-align: center;
                  font-family: Roboto;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 110%; /* 13.2px */
                }
              }
            }
          }
          .boxes_wrapper {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .box {
              display: flex;
              width: 100%;
              padding: 6px 7px;
              flex-direction: column;
              align-items: center;
              gap: 4px;
              color: var(--LP-mid-grey-_lib, #6b6c6f);
              text-align: center;
              font-family: Roboto;
              font-size: 10px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              border-radius: 4px;
              border: 0px solid var(--LP-very-light, #eaedf3);
              background: rgba(125, 85, 199, 0.15);
              span {
                color: var(--LP-black--_lib, #3e3f42);
                text-align: center;
                font-family: Roboto;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 18px; /* 128.571% */
              }
            }
            .boxes1 {
              display: flex;
              align-items: center;
              gap: 10px;
            }
            .boxes2 {
              display: flex;
              align-items: center;
              gap: 10px;
              text-wrap: nowrap;
              .box {
                border-radius: 4px;
                border: 1px solid var(--LP-very-light--_lib, #eaedf3);
                background: rgba(234, 237, 243, 0.5);
              }
            }
            .boxes3 {
              display: flex;
              align-items: center;
              gap: 10px;
              text-wrap: nowrap;
              .box {
                border-radius: 4px;
                border: 1px solid var(--LP-very-light, #eaedf3);
                background: var(--ffffff-white, #fff);
                height: 60px;
                justify-content: space-between;
              }
            }
          }
          .progressbar_wrapper {
            .header {
              display: flex;
              justify-content: space-between;
              margin-bottom: 4px;
              p {
                color: #9ea0a5;
                font-family: Roboto;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px; /* 150% */
              }
              span {
                color: var(--Purple, #7d59c4);
                text-align: right;
                font-family: Roboto;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px; /* 128.571% */
              }
            }
          }
        }
      }
      .property_card_mini-B {
        border-top: none;
        .buttons {
          padding: 10px 0px;
          display: flex;
          justify-content: space-between;

          .button {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 5px;
            span {
              color: var(--LP-mid-gray, #6b6c6f);
              text-align: center;
              font-family: Roboto;
              font-size: 10px;
              font-style: normal;
              font-weight: 400;
              line-height: 16px; /* 160% */
            }
          }
        }
        .stats {
          display: flex;
          justify-content: space-between;
          padding: 4px 0px;

          .date,
          .views,
          .active {
            display: flex;
            gap: 6px;
            color: var(--LP-gray, #9ea0a5);
            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px; /* 150% */
            span {
              color: var(--LP-gray, #9ea0a5);
              font-family: Roboto;
              font-size: 11px;
              font-style: normal;
              font-weight: 400;
              line-height: 18px;
            }
            .dotgreen {
              height: 4px;
              width: 4px;
              border-radius: 200px;
              background-color: #34aa44;
            }
          }
          .active {
            display: flex;
            align-items: center;
          }
        }
      }
      .view_details_button {
        border-radius: 0px 0px 4px 4px;
        background: linear-gradient(0deg, rgba(125, 85, 199, 0.3) 0%, rgba(255, 255, 255, 0) 107.78%), #7d55c7;
        background-blend-mode: multiply, normal;
        height: 38px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        p {
          color: var(--ffffff-white, #fff);
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px; /* 157.143% */
        }
      }
    }
  }

  // width: 1020px;
  .AssetDiscoverySearch {
    // background-color: #3e3f42;
    // width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    top: -40px;
    &.medium {
      width: 1020px;
      // height: 300px;
      .AssetDiscoverySearch-B {
        width: 1020px;
        height: 96px;
        .activeButton {
          border: 2px solid #7d55c7 !important;
        }
        .options_card {
          transform: translate(-45%, -0%);
          position: absolute;
          top: 50px;
          z-index: 10;
          fill: var(--ffffff-white, #fff);
          filter: drop-shadow(0px 3px 10px rgba(0, 0, 0, 0.15));
          padding: 19px 18px;
          background: white;
          border-radius: 10px;
          display: flex;
          gap: 10px;
          .switch_wrapper {
            text-wrap: nowrap;
          }
          .dropDownOuterCon {
            width: 150px;
            text-align: left;
            .MuiSlider-root {
              left: 0px;
            }
          }
        }
        .left {
          gap: 10px;
        }
        .right {
          margin-left: 25px;
          width: 60px;

          .top {
            display: flex;
            text-align: end;
            text-wrap: nowrap;
            margin-bottom: 12px;
            position: relative;
            left: -140px;
            svg {
              bottom: 2px;
              position: absolute;
              left: 180px;
            }
          }
        }
        .search_button {
          border-radius: 4px;
          border: 1px solid var(--purple-pantone-2665-c-71, rgba(125, 85, 199, 0.71));
          background: linear-gradient(0deg, rgba(125, 85, 199, 0.3) 0%, rgba(255, 255, 255, 0) 107.78%), #7d55c7;
          background-blend-mode: multiply, normal;
          box-shadow: 0px 1px 1px 0px rgba(19, 31, 21, 0.1), 0px 2px 0px 0px rgba(255, 255, 255, 0.06) inset;
          display: inline-flex;
          padding: 9px 16px;
          align-items: center;
          gap: 12px;
          width: auto !important;
          padding: 12px 12px;
          .svg-icon {
            margin-left: 12px;
          }
        }
      }
    }

    .AssetDiscoverySearch-T {
      display: flex;
      justify-content: space-between;
      .AssetDiscoverySearch-T-R {
        display: flex;
        gap: 10px;
        align-items: center;
        .white_buttons {
          margin-top: 15px;
          display: flex;
          padding: 0px;
          align-items: center;
          height: 38px;
          padding: 0px 14px;
          justify-content: center;
          gap: 10px;
          background: linear-gradient(0deg, #f6f7f9 0%, #fff 100%);
          box-shadow: 0px 1px 1px 0px rgba(22, 29, 37, 0.05), 0px 2px 0px 0px rgba(255, 255, 255, 0.05) inset;
          span {
            margin-top: 5px;
            color: #3e3f42;
            text-align: center;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px; /* 157.143% */
            font-weight: 500;
          }

          border-radius: 4px;
          border: 1px solid #d8dce6;
          background: linear-gradient(0deg, #f6f7f9 0%, #fff 100%);
          box-shadow: 0px 1px 1px 0px rgba(22, 29, 37, 0.05), 0px 2px 0px 0px rgba(255, 255, 255, 0.05) inset;
        }
      }
      .AssetDiscoverySearch-tabItem {
        width: 150px;
        height: 56px;
        margin-bottom: 20px;
        flex-shrink: 0;
        border-radius: 8px 8px 0px 0px;
        // background: green;
        display: flex;
        justify-content: center;
        padding-top: 14px;
        /* 6dp/Light */
        position: relative;
        top: 35px;
        z-index: 1;

        border-radius: 8px 8px 0px 0px;
        background: linear-gradient(0deg, rgba(234, 237, 243, 0.2) 0%, rgba(234, 237, 243, 0.2) 100%), #fff;

        /* 6dp/Light */
        box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.15);
        text-align: center;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px; /* 157.143% */
        cursor: pointer;
        p {
          color: var(--LP-mid-gray, #6b6c6f);
          position: relative;
          top: 0px;
        }
        &.active {
          top: 25px;
          border-radius: 8px 8px 0px 0px;
          background: var(--ffffff-white, #fff);
          z-index: 3;
          background: #fff;

          /* 6dp/Light */
          box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0);
          p {
            top: 4px;
            color: var(--LP-black, #3e3f42);
          }
        }
      }
    }
    .AssetDiscoverySearch-B {
      display: flex;
      position: relative;
      z-index: 2;
      border-radius: 8px;
      background: var(--ffffff-white, #fff);
      width: 1380px;
      height: 116px;
      border-radius: 8px;
      align-items: center;
      justify-content: space-between;
      padding: 0 30px;
      box-sizing: border-box;

      .left {
        width: 1040px;
        display: flex;
        justify-content: space-between;
      }

      .right {
        scale: 0.9;
        .top {
          text-align: end;
          p {
            color: #9ea0a5;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            span {
              color: #7d55c7;
              font-weight: 500;
            }
          }
          margin-bottom: 10px;
        }
        .bottom {
          display: flex;
          align-items: center;
          .switch_wrapper {
            scale: 0.9;
            .MuiSwitch-track {
              background-color: #764fc2 !important;
            }
            .Mui-checked {
              background-color: #764fc2 !important;
            }
          }
          .search_button {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 0px;
            width: 120px;
            margin-left: 0px;
            gap: 8px;
          }
          label {
            color: #6b6c6f;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px; /* 157.143% */
            text-wrap: nowrap;
          }
        }
      }
      //   background-color: red;
      .assetTypeItem {
        .buttons_wrapper {
          gap: 10px;
          align-items: baseline;
          display: flex;
          height: 100%;
          align-items: end;
          button {
            height: 38px;
            display: inline-flex;
            padding: 9px 11px;
            align-items: center;
            gap: 10px;
            border-radius: 4px;
            border: 2px solid #d8dce6;
            background: linear-gradient(0deg, #f6f7f9 0%, #fff 100%);
            box-shadow: 0px 1px 1px 0px rgba(22, 29, 37, 0.05), 0px 2px 0px 0px rgba(255, 255, 255, 0.05) inset;
          }
        }
        .assetTypeItem-T {
          height: 20px;
          display: flex;
          align-items: center;
          color: #9ea0a5;
          font-family: Roboto;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 18px; /* 150% */
          text-transform: uppercase;
          gap: 8px;
        }
        .assetTypeItem-B {
          // max-width: 170px;
          .inputBoxMain {
            margin-top: 10px;
            input {
              box-sizing: border-box;
            }
          }
          p {
            display: none;
          }
        }
      }
    }
    .AssetDiscoveryFilters {
      z-index: 100;
      position: absolute;
      top: 27%;
      margin-top: 4px;
      border-radius: 8px;
      background: linear-gradient(180deg, rgb(56, 56, 56) -7%, rgb(255 255 255 / 100%) 2%);

      width: 135.5%;
      .AssetDiscoveryFilters-T {
        padding-top: 30px;
        .react-tabs {
          padding: 0px 30px;

          .inputBoxMain {
            margin-top: 0px;
          }
          .react-tabs__tab-list {
            border-bottom: 1px solid #eaedf3;
            padding: 0% 14%;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;

            li {
              display: flex;
              justify-content: center;
              color: var(--LP-mid-gray, #6b6c6f);
              font-family: Roboto;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px; /* 171.429% */
              text-transform: capitalize;
              display: flex;
              height: 36px;
              align-items: flex-start;
              min-width: 60px;
            }
            .active {
              color: #3e3f42 !important;
              font-family: Roboto;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 24px; /* 171.429% */
              background: var(--ffffff-white, #fff);
              box-shadow: 0px -2px 0px 0px #7d55c7 inset;
            }
          }
          .react-tabs__tab-panel--selected {
            padding-right: 15%;
            padding-left: 10%;
            padding-bottom: 4.5%;
            padding-top: 3%;
            display: flex;
            border-bottom: 1px solid #eaedf3;
            &.innerTabination {
              padding-left: 0%;
              padding-top: 2%;
              padding-right: 0px;
            }
            .left {
              .react-tabs {
                display: flex;
                padding: 0px;
                .react-tabs__tab-list {
                  flex-direction: column;
                  justify-content: flex-start;
                  align-items: baseline;
                  width: 220px;

                  padding: 0px;
                  margin: 0px;
                  li {
                    padding: 5px 16px;
                    display: flex;
                    align-items: center;
                    justify-content: start;
                  }
                  .active {
                    box-shadow: 3px 0px 0px 0px #7d55c7 inset;
                  }
                  border-bottom: none;
                }
                .react-tabs__tab-panel--selected {
                  padding: 0px;
                  padding-top: 1%;
                  border-bottom: none;
                }
              }
              .right {
                width: 100%;
                display: flex;
                justify-content: space-between;
                gap: 105px;
                .column {
                  :first-child {
                    margin-top: 0px !important;
                  }
                  .row {
                    width: 286px;
                    margin-top: 20px;

                    p {
                      color: var(--LP-black, #3e3f42);
                      font-family: Roboto;
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 22px; /* 157.143% */
                    }
                    span {
                      color: var(--LP-gray, #9ea0a5);
                      text-align: right;
                      font-family: Roboto;
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 22px; /* 157.143% */
                    }
                    .subCat {
                      display: flex;
                      justify-content: space-between;
                      .subCat-L {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                      }
                    }
                    .option {
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      .MuiCheckbox-root {
                        padding: 5px;
                      }
                      .MuiFormControlLabel-root {
                        margin-left: 20px;
                      }
                      .MuiFormControlLabel-label {
                        color: var(--LP-black, #3e3f42);
                        font-family: Roboto;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 22px; /* 157.143% */
                      }
                      .option-L {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                      }
                    }
                  }
                }
              }
            }
            .right {
              width: 100%;
              display: flex;
              justify-content: space-between;
              .column {
                .row {
                  width: 307px;
                }
              }
            }
            .location {
              width: 100%;
            }
            .asset {
            }
          }
        }
      }

      .AssetDiscoveryFilters-B {
        display: flex;
        align-items: center;
        padding: 20px 30px;
        justify-content: space-between;
        .white_buttons {
          margin: 0px;
          height: 30px;
          border-radius: 4px;
          border: 1px solid #eaedf3;
          background: #fbfbfd;
        }
        .applyButton {
          .PrimaryButton {
            margin-top: 0px;
            padding: 8px 20px;
          }
        }
      }
    }
  }
  .AssetDiscoverySearch-B {
    width: 1020px;
  }
}

.Main-map {
  width: 73.5%;
  height: 100vh;
  position: absolute;
  top: 260px;
  z-index: 3;
}

.Main-map-small {
  width: 200px;
  height: 250px;
}
.mapdata {
  background-color: #6b5de7;
  width: 80%;
}
