.posts {
  .liked {
    color: #6b6c6f;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    height: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 10px 10px 10px;
    gap: 16px;
  }

  input {
    outline: none;
    border: none;
    height: 100%;
    margin-left: -10px;
  }

  input::placeholder {
    color: #9ea0a5;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }

  .counter-party-upper {
    width: 670px;
    //   height: 153px;
    flex-shrink: 0;
    border-radius: 4px;
    border: 1px solid #eaedf3;
    background: #fff;
    padding: 16px 20px 12px 20px;
    margin-bottom: 24px;

    .c-p-u-u {
      display: flex;
      gap: 16px;
      margin-bottom: 36px;

      > img {
        border-radius: 50%;
      }

      input {
        border: none;
        outline: none;

        &::placeholder {
          color: #9ea0a5;
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
          /* 157.143% */
        }
      }
    }

    .c-p-u-d {
      display: flex;
      gap: 10px;

      align-items: center;

      > div:first-child {
        padding: 8px 12px;
        border-radius: 4px;
        border: 1px solid #eaedf3;
        display: flex;
        align-items: center;
        gap: 10px;
      }

      .inp {
        margin-top: 0;
        width: 80%;
      }
    }
  }

  .counter-party-center {
    border-radius: 4px;
    border: 1px solid #eaedf3;
    background: #fff;

    > h3 {
      color: #3e3f42;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      /* 157.143% */
      margin-left: 20px;
    }

    .c-p-c-i {
      padding: 15px 20px 20px 20px;
      display: flex;

      gap: 10px;

      .pic-left > img {
        width: 376px;
        height: 340px;
        flex-shrink: 0;
        max-width: none;
      }

      pic-right {
        width: 245px;
        height: 165px;
        flex-shrink: 0;
        max-width: none;
        display: flex;
        flex-direction: column;
        gap: 15px;
      }
    }

    .c-p-comment {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 8px;

      > div {
        display: flex;
        align-items: center;
        gap: 133px;

        > div {
          display: flex;
          align-items: center;
          gap: 14px;

          > span {
            color: #3e3f42;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px;
            /* 157.143% */
          }
        }
      }
    }
  }

  .user-card {
    display: flex;
    justify-content: space-between;
    padding: 11px 20px;

    .left {
      display: flex;
      gap: 16px;

      > img {
        height: 38px;
        width: 38px;
        border-radius: 50px;
      }

      > div > h3 {
        color: #3e3f42;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        /* 157.143% */
      }

      > div > span {
        color: #9ea0a5;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        /* 150% */
      }
    }

    .right {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }

  .counter-party-down {
    border-radius: 4px;
    border: 1px solid #eaedf3;
    background: #fff;
    margin-top: 24px;
  }
}
