.project_list{
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px 0;
    max-width: 1097px;
    .checkbox{
        padding-left: 10px !important;
    }
    .assigned_wrapper{
        display: flex;
        align-items: center;
        gap: 4px;
    }
    .todo_block{
        background-color: #ffffff;
    }
    .todo_block-T {
      padding: 20px 20px;
      border: 1px solid #eaedf3;
      border-top: 3px solid #34aa44;
      display: flex;
      justify-content: space-between;
      position: relative;
      .L {
        display: flex;
        width: 200px;
        gap: 20px;
        align-items: center;
        p {
          color: #3e3f42;
          font-family: Roboto;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 28px;
          text-transform:capitalize;
        }
      }
      .R {
        display: flex;
        gap: 10px;
        button{
          cursor:pointer;
            padding: 8px 12px;
            outline: none;
            border: 1px solid #eaedf3;
            height: 38px;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
            color: #3E3F42;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px; 
            margin-right: 20px;
            border-radius: 4px;
            background-color: #FFFFFF00;
            cursor:pointer;
        }
      }
    }
    .todo_block-B {
      .header {
        display: flex;
        border: 1px solid #eaedf3;

        padding: 20px 40px;

        .left{
            width: 600px;
        }
        .right {
          width: 350px;
          display: flex;
          justify-content: space-between;
          margin-right: 100px;
        }
        span {
          color: #9ea0a5;
          font-family: Roboto;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 18px; /* 150% */
          text-transform: uppercase;
        }
      }
      .row {
        padding: 10px 20px;
        border: 1px solid #eaedf3;

        display: flex;
        justify-content: space-between;

        p {
          color: #3e3f42;
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px; /* 157.143% */
        }
        span {
          color: #9ea0a5;
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px; /* 157.143% */
        }
        .row-L {
          display: flex;
          align-items: center;
          width: 600px;
          span {
            margin: 0px 10px;
          }
          .title {
            margin-right: 30px;
            margin-left: 10px;
          }
        }
        .row-R {
          display: flex;
          align-items: center;
          gap: 100px;
          text-wrap:nowrap;
        }
      }
    }
}