.welcome-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem;
}

.welcome-card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: center;
}

.welcome-card {
  max-width: 500px;
  padding: 2rem 2.5rem;
  text-align: center;
  background-color: #ffffff;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  transition: transform 0.3s, box-shadow 0.3s;
}

.welcome-card:hover {
  transform: translateY(-5px);
  box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.15);
}

.welcome-card img {
  width: 100px;
  height: 100px;
  margin: 0 auto 1.5rem;
  display: block;
  border-radius: 12px;
}

.welcome-card h1 {
  font-size: 1.8rem;
  font-weight: bold;
  color: #2c3e50;
  margin-bottom: 1rem;
}

.welcome-card h2 {
  font-size: 1.2rem;
  color: #4d4d4d;
  margin-bottom: 1.5rem;
}

.welcome-card p {
  font-size: 1rem;
  line-height: 1.6;
  color: #666666;
  margin-bottom: 2rem;
}

.welcome-button {
  margin-top: 1rem;
  background-color: #7d55c7;
  color: #ffffff;
  font-size: 1rem;
  font-weight: bold;
  padding: 0.75rem 2rem;
  border-radius: 50px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

@media (max-width: 768px) {
  .welcome-card-container {
    flex-direction: column;
    align-items: center;
  }

  .welcome-card {
    width: 100%;
    max-width: 400px;
  }
}

.loader-container-LandingPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loader-LandingPage {
  border: 6px solid #f3f3f3;
  border-top: 6px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader-container-LandingPage p {
  margin-top: 10px;
  font-size: 1.2em;
  color: #333;
}
