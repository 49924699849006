.ScheduleFollowUp-modal {
  width: 100%;
  max-width: 515px !important;
  border-radius: 20px;

  .ant-modal-content {
    border: 0.5px solid #d6d6d8;
    background: #fff;
    box-shadow: 0px 2.148px 7.16px 0px rgba(0, 0, 0, 0.15);

    .ant-modal-header {
      margin-bottom: 0;

      .ant-modal-title {
        color: var(--Black, #3e3f42);
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 119%;
      }
    }
  }
}

.ScheduleFollowUp-container {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: space-around;
  margin: 0 auto;

  .ScheduleFollowUp-calendarSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 9px;
    width: 100%;
    max-width: 258px;

    .ScheduleFollowUp-calendarHeader {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      margin-bottom: 10px;

      .arrowSection {
        display: flex;
        align-items: center;
        gap: 10px;
        .ScheduleFollowUp-calendarButton {
          background: none;
          border: none;
          font-size: 1.2rem;
          cursor: pointer;
          font-weight: bold;
          display: flex;
          align-items: center;
        }
      }

      .ScheduleFollowUp_calender_name {
        color: var(--Black, #3e3f42);
        font-family: Roboto;
        font-size: 13.108px;
        font-style: normal;
        font-weight: 500;
        line-height: 119%; /* 15.599px */
      }
    }

    .ScheduleFollowUp-calendarGrid {
      border-radius: 5.728px;
      background: rgba(125, 85, 199, 0.07);
      width: 100%;
      padding: 11.456px 14.32px;
      .weekBox {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        gap: 10px;
        justify-items: center;
        margin-bottom: 17px;
        .ScheduleFollowUp-dayName {
          display: flex;
          justify-content: center;
          align-items: center;
          color: var(--Black, #3e3f42);
          text-align: center;
          font-family: Roboto;
          font-size: 9.363px;
          font-style: normal;
          font-weight: 400;
          line-height: 119%; /* 11.142px */
          width: 24px;
          height: 24px;
        }
      }

      .daysBox {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        gap: 0.82px;
        justify-items: center;
        .ScheduleFollowUp-dateCell {
          cursor: pointer;
          width: 24px;
          height: 24px;
          // color: var(--Black, #3e3f42);
          text-align: center;
          font-family: Roboto;
          font-size: 9.363px;
          font-style: normal;
          font-weight: 400;
          line-height: 121%; /* 11.142px */
          display: flex;
          padding: 7.16px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 7.16px;
          border-radius: 22.912px;

          &.ScheduleFollowUp-otherMonth {
            color: #b5b7c0;
          }
        }
      }
    }
  }

  .ScheduleFollowUp-timeSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-top: 10px;

    .ScheduleFollowUp-selectedTime {
      p {
        color: var(--Black, #3e3f42);
        font-family: Roboto;
        font-size: 13.108px;
        font-style: normal;
        font-weight: 500;
        line-height: 119%; /* 16.66px */
      }
    }

    .ScheduleFollowUp-timeColumns {
      display: flex;
      align-items: center;
      padding: 10px;
      border-radius: 5.728px;
      background: rgba(125, 85, 199, 0.07);
      gap: 10px;
      .ScheduleFollowUp-timeColumn {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;

        .ScheduleFollowUp-scrollContainer {
          height: 6rem;
          overflow-y: auto;
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
          height: 192px;
          padding-right: 5px;

          &::-webkit-scrollbar-track {
            border-radius: 5px;
            background: #e2dfe5;
          }

          &::-webkit-scrollbar {
            width: 6px;
            height: 6px;
            background: #e2dfe5;
            margin-left: 10px;
          }

          &::-webkit-scrollbar-thumb {
            border-radius: 5px;
            background: #7d55c7;
            -webkit-width: 100;
          }

          .ScheduleFollowUp-timeCell {
            cursor: pointer;
            display: flex;
            padding: 7px;
            justify-content: center;
            align-items: center;
            gap: 20px;
            width: 26px;
            height: 21px;
            color: var(--Black, #3e3f42);
            text-align: center;
            font-family: Roboto;
            font-size: 10px;
            font-style: normal;
            font-weight: 400;
            line-height: 119%; /* 11.9px */
            border-radius: 14px;
            &.ScheduleFollowUp-selected {
              color: #fff;
              background: #7d55c7;
            }
          }
        }
      }
    }
  }
}

.ScheduleFollowUp-dateCell:hover {
  background-color: #e9ecef;
}

.ScheduleFollowUp-scrollContainer::-webkit-scrollbar {
  width: 8px;
  background: rgba(0, 0, 0, 0.1);
}

.ScheduleFollowUp-scrollContainer::-webkit-scrollbar-thumb {
  background: #7d55c7;
  border-radius: 10px;
}

.ScheduleFollowUp-scrollContainer::-webkit-scrollbar-thumb:hover {
  background: #7d55c7;
}

.ScheduleFollowUp-scrollContainer::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.05);
}

.ScheduleFollowUp-calendarSection {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ScheduleFollowUp_calender_name {
  font-family: Roboto;
  font-size: 13.11px;
  font-weight: 500;
  line-height: 15.6px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.ScheduleFollowUp-dateCell:hover {
  background-color: #e9ecef;
  cursor: pointer;
}

.ScheduleFollowUp_button_container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  .ScheduleFollowUp-scheduleButton {
    width: 100%;
    max-width: 98px;
    display: flex;
    height: 34px;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    cursor: pointer;
    border-radius: 10px;
    background: #7d55c7;

    &:hover {
      background: #7d55c7 !important;
    }

    span {
      color: #fff;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}

.ScheduleFollowUp-dateCell.ScheduleFollowUp-today {
  border: 2px solid #7d55c7; 
  border-radius: 50%; 
  color: #000;
}

.ScheduleFollowUp-dateCell.ScheduleFollowUp-selected {
  background-color: #7d55c7;
  color: #fff;
  border-radius: 50%; 
}

.ScheduleFollowUp-dateCell:hover {
  background-color: #e9ecef; 
}

.ScheduleFollowUp-dateCell.ScheduleFollowUp-disabled {
  pointer-events: none;
  color: #ccc; 
  // background-color: #f9f9f9; 
  cursor: not-allowed;
}
