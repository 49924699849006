.passwordRecovery {
  .prFirstSection {
    display: flex;
  }
  .prThirdSection {
    display: flex;
    .top_header {
      margin-bottom: 80px;
    }
    p {
      color: var(--LP-gray, #9ea0a5);
      font-family: Roboto;
      font-size: 14px;
      font-style: top_headernormal;
      font-weight: 400;
      line-height: 22px; /* 157.143% */
    }
    .spacerLine30 {
      margin-top: 50px !important;
    }
  }
  .prFourthSection,
  .prFifthSection {
    display: flex;
    .top_header {
    }
    p {
      color: var(--LP-gray, #9ea0a5);
      font-family: Roboto;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; 
    }
    .spacerLine30 {
      margin-top: 80px !important;
    }
  }
  .prFifthSection {
    .top_header {
    }
  }

  .signupCon-L {
    .spacerLine30 {
      margin-top: 60px;
    }
  }
  .signupCon-R {
    height: 100%;
    .container {
      display: flex;
      height: 100%;
      width: 100%;
      &.flex_reverse {
        flex-direction: row-reverse;
      }
      .passwordRecovery_svg_wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        // margin-top: 10%;
        img {
        }
      }
      .color_stripes-data {
        height: 100vh;
        width: 20%;
        display: flex;
        .stripe1 {
          height: 100%;
          width: 80%;
          background: var(--purple-pantone-2665-c-71, rgba(125, 85, 199, 0.71));
          box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.08);
        }
        .stripe2 {
          width: 20%;
          background: #6b4d9f;
          box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.08);
        }
      }
    }
  }
}
