.PrimaryButton {
  max-width: 560px;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid var(--purple-pantone-2665-c-71, rgba(125, 85, 199, 0.71));
  background: linear-gradient(0deg, rgba(125, 85, 199, 0.3) 0%, rgba(255, 255, 255, 0) 107.78%), #7d55c7;
  background-blend-mode: multiply, normal;
  box-shadow: 0px 1px 1px 0px rgba(19, 31, 21, 0.1), 0px 2px 0px 0px rgba(255, 255, 255, 0.06) inset;
  color: var(--ffffff-white, #fff);
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
  margin-top: 50px;
  padding: 8px 0px;

  &.secondary {
    border: 1px solid #d8dce6;
    background: #fff;
    color: var(--LP-black, #3e3f42);
  }
  &.grey {
    display: flex;
    // width: 320px;
    height: 38px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    background: none;
    border: 1px solid #d8dce6;
    color: #3e3f42;
  }
  &.white-bg {
    display: flex;
    // width: 320px;
    height: 38px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border: 1px solid #d8dce6;
    color: #3e3f42;
  }
  &.noMargin {
    margin: 0px;
  }
  .svg-icon {
    margin-left: 8px;
    height: 16px;
    width: 16px;
  }
  &.white_buttons {
    background: none;
    border-color: #d8dce6;
    color: #3e3f42;
    margin: 0px;
    padding: 10px 10px;
    display: flex;
    align-items: center;
  }
}

.smallSvgButton {
  width: 135px;
  height: 38px;
  border-radius: 4px;
  background-color: #7d55c7;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--ffffff-white, #fff);
  text-align: center;
  font-family: Roboto;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
  cursor: pointer;
  .svgR {
    margin-left: 10px;
    position: relative;
    top: 0px;
  }
  .svgRbefore {
    // margin-left: 10px;
    position: relative;
    top: 0px;
    right: 20px;
    transform: rotate(180deg);
  }
  .svgL {
    display: none;
  }
  &.secondary {
    background-color: #fff;
    border: 1px solid #d8dce6;
    color: var(--LP-black, #3e3f42);
    .svgR {
      display: none;
    }
    .svgL {
      display: flex;
      margin-right: 10px;
      position: relative;
      left: -10px;
      top: 0px;
    }
  }
}


// Loading Button

.spinner-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #ffffff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
