.add-doc-main-wrapper {
  width: 100%;
  height: 1041px;
  box-sizing: border-box;
}
.add-doc-header {
  width: 1440px;
  height: 74px;
  flex-shrink: 0;
  border: 1px solid #eaedf3;
  padding: 0 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.doc-header-left > span {
  color: #3e3f42;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 155.556% */
}

.doc-header-right {
  display: flex;
  align-items: center;
  gap: 10px;
}

.doc-header-right > div {
  display: flex;
  padding: 8px 11px;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  border: 1px solid var(--red-pantone-1797-c-72, #d8dce6);
  background: linear-gradient(0deg, #f6f7f9 0%, #fff 100%);
  box-shadow: 0px 1px 1px 0px rgba(22, 29, 37, 0.05), 0px 2px 0px 0px rgba(255, 255, 255, 0.05) inset;
  cursor: pointer;
}

.doc-cancle-btn {
  display: flex;
  padding: 8px 11px;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: linear-gradient(0deg, #f6f7f9 0%, #fff 100%);
  color: var(--red-pantone-1797-c, #cb333b);
  border: 1px solid var(--red-pantone-1797-c-72, rgba(203, 51, 59, 0.72)) !important;
}

.doc-header-right > div > span {
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}

.doc-next-btn {
  background: #7d55c7 !important;
  color: #fff !important;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}

/*=================================*/
/* DOCUMENT NAME CONTAINER*/
/*=================================*/
.second-container {
  display: flex;
  justify-content: space-between;
  margin: 40px 30px 0 30px;

  @media screen and (max-width: 1300px) {
    flex-direction: column !important;
    gap: 25px !important;
  }
}
.docname-container {
  width: 1110px;
  padding-bottom: 60px;
  border-radius: 4px;
  border: 2px solid var(--purple-pantone-2665-c-71, rgba(125, 85, 199, 0.71));
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.08);
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.legendimg {
  width: 42px;
  height: 42px;
  position: absolute;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  fill: var(--purple-pantone-2665-c, #7d55c7);
  background-color: #7d55c7;
  top: -20px;
  left: 48%;
}
.legendimg img {
  z-index: 1;
}
.docname-container h2 {
  color: #3e3f42;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  margin-top: 35px;
}
.doc-form-container {
  width: 730px;
  height: 383px;
  margin-top: 30px;
  border-radius: 4px;
  stroke-width: 1px;
  stroke: #eaedf3;
  border: 1px solid #eaedf3;
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.04));
}
.form-heading {
  border-bottom: 1px solid #eaedf3;
  display: flex;
  gap: 16px;
  padding: 25px 30px;
}
.form-heading span {
  color: #9ea0a5;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 157.143% */
}
.form-content-container {
  width: 670px;
  margin: 30px 30px;
}
.form-content-container h1 {
  color: #9ea0a5;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
  text-transform: uppercase;
}
.form-content-container #input {
  padding: 8px 16px;
  width: 100%;
  margin: 7px 0;
  border-radius: 4px;
  border: 1px solid #e2e5ed;
  box-shadow: 0px 1px 2px 0px rgba(102, 113, 123, 0.21) inset;
  background-color: #fff;
  &:focus {
    border: none;
    outline: none;
  }
}

.form-content-container > p {
  color: #9ea0a5;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
  margin-bottom: 20px;
}
.form-btn-cont {
  display: flex;
  gap: 10px;
  border: none !important;
  box-shadow: none !important;
}
.form-btn {
  border-radius: 4px;
  border: 1px solid #eaedf3;
  background: #fbfbfd;
  display: flex;
  align-items: center;
  padding: 4px 12px;
  gap: 12px;
  cursor: pointer;
  transition: all 0.3s;
}
.form-btn:hover {
  background: #efeff1be;
}
.form-btn span {
  color: #3e3f42;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}

/*=================================*/
/* DOCUMENT NAME NAVIGATOR*/
/*=================================*/

.doc-navigator {
  width: 95px;
  height: 242px;
}

.doc-navigator .steps {
  display: flex;
  flex-direction: column;
  gap: 50px;
  position: relative;
}

.steps .step-cnt {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  cursor: pointer;
}

.steps .step-cnt .circle {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  border: 3px solid #d4d4d4;
  background: #fff;
}

.doc-navigator .steps .step-cnt.active .circle {
  border-color: #7d55c7;
}
.doc-navigator .steps .step-cnt.active span {
  color: #7d55c7;
}

.doc-navigator .steps .step-cnt span {
  color: var(--LP-mid-grey-_lib, #6b6c6f);
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  text-transform: capitalize;
}

.steps .progress-bar {
  position: absolute;
  height: 245px;
  width: 3px;
  background: #d4d4d4;
  left: 85%;
  z-index: -1;
}

/*=================================*/
/* DOCUMENT UPLOAD SECTION*/
/*=================================*/
.switch-btn {
  // width: 255px;
  height: 35px;
  border-radius: 6px;
  border: 1px solid #e2e5ed;
  background: var(--ffffff-white, #fff);
  display: flex;
  // width: 190px;
  // justify-content: center;
  padding: 0 4px;
  align-items: center;
  gap: 6px;
  cursor: pointer;
  transition: all 0.3s;
  width: max-content;
}
.switch-btn span {
  color: var(--LP-gray, #9ea0a5);
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}
.create-doc-cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.create-doc-btn {
  display: flex;
  padding: 8px 20px;
  align-items: center;
  gap: 12px;
  border-radius: 4px;
  margin-top: 30px;
  border: 1px solid var(--purple-pantone-2665-c-71, rgba(125, 85, 199, 0.71));
  background: linear-gradient(0deg, rgba(125, 85, 199, 0.3) 0%, rgba(255, 255, 255, 0) 107.78%), #7d55c7;
  background-blend-mode: multiply, normal;
  box-shadow: 0px 1px 1px 0px rgba(19, 31, 21, 0.1), 0px 2px 0px 0px rgba(255, 255, 255, 0.06) inset;
  cursor: pointer;
}
.create-doc-btn span {
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}

/*=================================*/
/* UPLOAD DOCUMENT */
/*=================================*/
.upload-doc-cont {
  width: 730px;
  fill: #fff;
  stroke-width: 1px;
  stroke: #eaedf3;
  border: 1px solid #eaedf3;
  border-radius: 4px;
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.04));
  margin-bottom: 30px;
}

.upload-doc-head {
  display: flex;
  align-items: center;
  padding: 25px 30px;
  gap: 16px;
  border-bottom: 1px solid #eaedf3;
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.04));

  span {
    color: #9ea0a5;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
  }
}

.upload-doc-body {
  padding: 30px;
}

.select-doc {
  width: 670px;
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-radius: 4px;
  border: 1px dashed #e2e5ed;
  background: #fcfcfc;
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.05) inset;

  @media screen and (max-width: 1440px) {
    width: 575px !important;
  }
  @media screen and (max-width: 900px) {
    width: 480px !important;
  }

  p {
    color: #9ea0a5;
    font-family: Roboto;
    font-size: 14px;
    line-height: 22px;
  }

  span {
    text-decoration-line: underline;
  }
}

.progress-cont {
  width: 670px;
  padding: 16px 22px;
  border-radius: 4px;
  margin-top: 30px;
  border: 1px solid #eaedf3;
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.04));
  display: flex;
  gap: 25px;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 1440px) {
    width: 575px !important;
  }
  @media screen and (max-width: 900px) {
    width: 480px !important;
  }
}

.uploading-file {
  width: 570px;

  @media screen and (max-width: 1440px) {
    width: 500px !important;
  }
  @media screen and (max-width: 900px) {
    width: 400px !important;
  }
}

.file-name {
  display: flex;
  align-items: center;
  justify-content: space-between;

  > div {
    display: flex;
    gap: 15px;

    span {
      color: #3e3f42;
      font-family: Roboto;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
    }
  }

  span {
    color: #9ea0a5;
    text-align: right;
    font-family: Roboto;
    font-size: 12px;
    line-height: 18px;
  }
}

.uploading-file .progress-bar {
  width: 570px;
  height: 4px;
  margin-top: 12px;
  border: 1px solid #eaedf3;
  border-radius: 5px;

  @media screen and (max-width: 1440px) {
    width: 502px !important;
  }
  @media screen and (max-width: 900px) {
    width: 400px !important;
  }

  .progress {
    width: 200px;
    height: 100%;
    border-radius: 2px;
    background: var(--purple-pantone-2665-c-71, rgba(125, 85, 199, 0.71));
    display: block;
  }
}

.back {
  display: flex;
  background: var(--LP-very-light, #eaedf3);
  border-radius: 6px;
  width: 124px;
  height: 28px;
  justify-content: center;
  align-items: center;
}

.uploaded-doc-head {
  padding: 23px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #eaedf3;
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.04));

  > div {
    display: flex;
    gap: 20px;
    align-items: center;

    span:nth-child(1) {
      color: #3e3f42;
      font-family: Roboto;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px; /* 155.556% */
    }

    span:nth-child(2) {
      color: var(--LP-grey-_lib, #9ea0a5);
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px; /* 200% */
    }
  }

  #delete span {
    color: var(--red-pantone-1797-c, #cb333b);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
  }
}

.doc-nav-header {
  padding: 0 80px 0 50px;
}

/*=================================*/
/* DOCUMENT MESSAGE*/
/*=================================*/
.message-body-cnt {
  width: 730px;
  fill: #fff;
  stroke-width: 1px;
  stroke: #eaedf3;
  border: 1px solid #eaedf3;
  border-radius: 4px;
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.04));
  margin-top: 30px;
}

.message-container {
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.04));
}

.sender-address {
  width: 100%;
  border-bottom: 1px solid #eaedf3;
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.04));
  display: flex;
  padding: 10px 30px;
  gap: 10px;

  > div > span {
    color: #9ea0a5;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
  }

  .first-mail {
    width: 171px;
    border-radius: 4px;
    border: 1px solid #e2e5ed;
    display: flex;
    align-items: center;
    padding: 3px 10px;
    background-color: #eaedf3;
  }

  .email span {
    color: var(--LP-black, #3e3f42);
    text-align: center;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 150% */
  }

  .second-mail {
    width: 185px;
    border-radius: 4px;
    border: 1px solid #e2e5ed;
    display: flex;
    align-items: center;
    padding: 3px 10px;
    display: flex;
    gap: 5px;
  }
}

.subject {
  width: 100%;
  padding: 10px 30px;
  display: flex;
  gap: 15px;
  border-bottom: 1px solid #eaedf3;
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.04));

  span {
    color: #9ea0a5;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
  }

  #input {
    border: none;
    width: 100%;
    background: #ffffff61;
    &:focus {
      border: none;
      outline: none;
    }
  }
}

.formatting {
  width: 730px;
  display: flex;
  gap: 45px;
  padding: 20px 15px 20px 30px;
  border-bottom: 1px solid #eaedf3;
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.04));

  div {
    display: flex;
    gap: 20px;
  }
}

.message-body {
  width: 100%;
  padding: 20px 30px;
  display: flex;
  flex-direction: column;

  #input-box {
    border: none;
    margin-bottom: 15px;
    background: #ffffff61;
    color: #3e3f42;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    width: 100%;
    &:focus {
      border: none;
      outline: none;
    }
  }

  #textarea {
    min-height: 110px;
    height: auto;
    color: #3e3f42;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    border: none;
    background: #ffffff61;
    width: 100%;
    resize: vertical;
    margin-bottom: 15px;
    &:focus {
      border: none;
      outline: none;
    }
  }

  .signature {
    display: flex;
    gap: 15px;
    padding-top: 15px;
    border-top: 1px solid #eaedf3;

    span {
      width: 130px;
      color: #9ea0a5;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 157.143% */
    }

    #input-box {
      color: #3e3f42;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 157.143% */
    }
  }
}

/*=================================*/
/* DOCUMENT FINISH*/
/*=================================*/

.finish-box-container {
  width: 730px;
  fill: #fff;
  border: 1px solid #eaedf3;
  border-radius: 4px;
  stroke-width: 1px;
  stroke: #eaedf3;
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.04));
}

.finish-head {
  padding-right: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #eaedf3;
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.04));

  div img:last-child {
    padding-left: 25px;
    border-left: 1px solid #eaedf3;
    cursor: pointer;
  }
}

.box-content {
  display: flex;
  gap: 30px;
  padding: 25px 30px;
}

.box-content-left {
  width: 375px;
  border: 1px solid #eaedf3;
  border-radius: 4px;
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.04));

  .building-img {
    position: relative;
    margin-bottom: 40px;

    #building {
      position: absolute;
      top: 83%;
      right: 70%;
    }

    .zoom-level {
      position: absolute;
      background-color: #fff;
      padding: 9px;
      display: flex;
      gap: 14px;
      border: 1px solid #eaedf3;
      border-radius: 4px;
      top: 3%;
      left: 67%;

      span {
        color: var(--LP-black--_lib, #3e3f42);
        text-align: center;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 157.143% */
      }
    }
  }

  .building-bottom {
    padding: 25px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(255, 255, 255, 0) 78.57%);

    > span:first-child {
      color: var(--333333-dark-grey, #333);
      font-family: Roboto Slab;
      font-size: 22px;
      font-style: normal;
      font-weight: 300;
      line-height: 47.998px; /* 218.172% */
      display: block;
    }

    > span:last-child {
      width: 202px;
      color: var(--LP-mid-grey-_lib, #6b6c6f);
      font-family: Roboto;
      font-size: 12.018px;
      font-style: normal;
      font-weight: 300;
      line-height: 140%; /* 16.826px */
      display: block;
      margin-bottom: 50px;
    }
  }

  #copyright {
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      width: 105px;
      color: var(--LP-mid-gray, #6b6c6f);
      font-family: Roboto;
      font-size: 12.018px;
      font-style: normal;
      font-weight: 300;
      line-height: 140%; /* 16.826px */
    }

    span {
      color: var(--LP-mid-gray, #6b6c6f);
      font-family: Roboto;
      font-size: 12.018px;
      font-style: normal;
      font-weight: 300;
      line-height: 140%; /* 16.826px */
      display: block;
      text-align: right;
    }
  }
}

#navigator {
  position: absolute;
  width: 160px;
  height: 38px;
  border: 1px solid #eaedf3;
  border-radius: 4px;
  display: flex;
  gap: 12px;
  background-color: #3e3f42;
  align-items: center;
  justify-content: center;
  top: 90%;
  left: 30%;

  img:first-child {
    transform: scaleX(-1);
  }

  span {
    color: var(--LP-white-_lib, #fff);
    text-align: center;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
    border-left: 1px solid #fff;
    border-right: 1px solid #fff;
    padding: 0 12px;
  }
}

.box-content-right {
  width: 270px;

  #img {
    width: 38px;
    height: 38px;
    border-radius: 50%;
  }

  > span:first-child {
    color: #9ea0a5;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 150% */
    text-transform: uppercase;
  }

  > p {
    width: 250px;
    color: var(--LP-black--_lib, #3e3f42);
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 122.222% */
    margin: 8px 0 16px 0;
  }

  > span:nth-child(3) {
    color: #9ea0a5;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 150% */
    text-transform: uppercase;
  }

  > img {
    margin: 20px 0;
  }
}

.infodown {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;

  > span {
    color: #9ea0a5;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 150% */
    text-transform: uppercase;
  }

  > div > span {
    color: #9ea0a5;
    text-align: center;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 183.333% */
  }

  > div {
    display: flex;
    gap: 10px;
  }
}

.user-detail {
  display: flex;
  gap: 15px;
  margin-bottom: 10px;

  span:first-child {
    color: #3e3f42;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 157.143% */
    display: block;
  }

  span:last-child {
    color: #6b6c6f;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 183.333% */
  }
}

.finish-bottom {
  padding: 0 30px;

  span {
    color: #9ea0a5;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 150% */
    text-transform: uppercase;
    margin-bottom: 20px;
    display: block;
  }

  p {
    color: #3e3f42;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
    margin-bottom: 60px;
  }
}

/*=================================*/
/* MANUAL DOCUMENT CREATION */
/*=================================*/

.manual-document-container {
  width: 1440px;
  display: flex;
}
.MDleft {
  width: 270px;
  border: 1px solid #eaedf3;
}
.left-head {
  height: 65px;
  display: flex;
  gap: 15px;
  align-items: center;
  padding-left: 20px;
  border-bottom: 1px solid #eaedf3;
  > span {
    color: #3e3f42;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 137.5% */
  }
  > img {
    width: 24px;
    height: 24px;
  }
}
.left-navigation {
  height: 65px;
  display: flex;
  gap: 15px;
  padding-left: 15px;
  align-items: center;
  border-bottom: 1px solid #eaedf3;
  > div {
    display: flex;
    gap: 5px;
    > span {
      color: #6b6c6f;
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 200% */
      text-transform: capitalize;
    }
  }
}
.MDright {
  width: 1120px;
}
.MDright-head {
  height: 65px;
  border: 1px solid #eaedf3;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
}
.head-right {
  display: flex;
  gap: 20px;
  > span {
    color: #3e3f42;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 137.5% */
    margin-bottom: 10px;
  }
}
.head-left {
  display: flex;
  align-items: center;
  gap: 10px;
  .zoom-level {
    display: flex;
    gap: 10px;
    padding: 9px;
    border: 1px solid #9ea0a5;
    border-radius: 4px;
    > span {
      color: var(--LP-mid-grey-_lib, #6b6c6f);
      text-align: center;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 157.143% */
    }
  }
  .restore {
    border: 1px solid #eaedf3;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 9px 20px 9px 12px;
    border-radius: 4px;
    > span {
      color: #3e3f42;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 157.143% */
    }
  }
  .save {
    padding: 8px 20px;
    border-radius: 4px;
    border: 1px solid var(--purple-pantone-2665-c-71, rgba(125, 85, 199, 0.71));
    background: linear-gradient(0deg, rgba(125, 85, 199, 0.3) 0%, rgba(255, 255, 255, 0) 107.78%), #7d55c7;
    background-blend-mode: multiply, normal;
    box-shadow: 0px 1px 1px 0px rgba(19, 31, 21, 0.1), 0px 2px 0px 0px rgba(255, 255, 255, 0.06) inset;
    color: #fff;
    text-align: center;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
  }
}
.MDright-body {
  display: flex;
}
.MDright-body-left {
  width: 790px;
  > div > img {
    height: 940px;
    width: 730px;
    margin: 55px 5px 20px 30px;
  }
}
.MDright-body-right {
  width: 380px;
  border-left: 1px solid #eaedf3;
  padding: 0 30px;
  box-sizing: border-box;
  .right-head {
    display: flex;
    gap: 20px;
    height: 65px;
    // align-items: center;
    border-bottom: 1px solid #eaedf3;
    > span {
      color: #6b6c6f;
      text-align: center;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 171.429% */
      text-transform: capitalize;
      cursor: pointer;
      margin-top: 20px;
    }
    > div {
      cursor: pointer;
      display: flex;
      gap: 5px;
      align-items: center;
      color: #6b6c6f;
      text-align: center;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 171.429% */
      text-transform: capitalize;
    }
  }
}
.MD-right-body {
  margin-top: 35px;
  h1 {
    color: #3e3f42;
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 155.556% */
    margin-bottom: 16px;
  }
}
.MD-right-body #text-filter {
  width: 125px;
  display: flex;
  gap: 8px;
  border: 1px solid #eaedf3;
  border-radius: 4px;
  padding: 4px 0 4px 12px;
  margin-bottom: 15px;
  cursor: pointer;
}
.MD-right-body > div > span {
  color: var(--LP-mid-grey-_lib, #6b6c6f);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}

.dropdown-height .dropDownDrop {
  max-height: 40vh !important;
}
.action-container {
  margin-top: 20px;
  > span {
    color: #9ea0a5;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 150% */
    text-transform: uppercase;
  }
  .actions {
    height: 35px;
    width: 320px;
    padding: 4px;
    display: flex;
    gap: 6px;
    align-items: center;
    border-radius: 6px;
    border: 1px solid #e2e5ed;
    background: var(--ffffff-white, #fff);
    margin-top: 8px;
    .action-active {
      background-color: #eaedf3;
    }
    > span {
      padding: 4px 20px;
      border-radius: 4px;
      color: var(--LP-mid-grey-_lib, #6b6c6f);
      text-align: center;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 157.143% */
      cursor: pointer;
    }
    > div {
      height: 25px;
      border: 1px solid #eaedf3;
    }
  }
}
.span-dot {
  color: #3e3f42;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 155.556% */
  margin: 25px 0 25px 3px;
}
