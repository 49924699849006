.not-found-page {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f9fa;

  .not-found-container {
    text-align: center;
    color: #343a40;
  }

  .icon {
    font-size: 100px;
    color: #ff6f61;
    margin-bottom: 20px;
    width: 100%;
  }

  h1 {
    font-size: 72px;
    margin: 0;
  }

  p {
    font-size: 24px;
    margin: 20px 0;
  }

  .home-button {
    padding: 10px 20px;
    font-size: 18px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  .home-button:hover {
    background-color: #0056b3;
  }
}
