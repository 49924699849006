.savedHeader {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding-bottom: 20px;
    border-bottom: 1px solid #EAEDF3;
    margin-bottom: 20px;
    flex-wrap: wrap;
    h2 {
        color: #3E3F42;
        font-family: Roboto;
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
        @media screen and (max-width:900px) {
         font-size: 16px !important;   
        }
    }

}