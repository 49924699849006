.pagination-TableUnitOver {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
    font-family: Arial, sans-serif;
  }
  
  .pagination-TableUnitOver button {
    background-color: #551d89;
    color: white;
    border: none;
    padding: 10px 20px;
    margin: 0 5px;
    cursor: pointer;
    font-size: 16px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .pagination-TableUnitOver button:hover:not(:disabled) {
    background-color: #53208b;
  }
  
  .pagination-TableUnitOver button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .pagination-TableUnitOver span {
    margin: 0 10px;
    font-size: 18px;
    font-weight: bold;
    color: #333;
  }
  