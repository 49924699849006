.crowdfunding_header {
  margin-bottom: 20px;

  h2 {
    color: #3e3f42;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
    text-transform: capitalize;
  }

  button {
    padding: 12px 16px;
    outline: none;
  }

  .crowdfunding_header_right {
    gap: 20px;
  }

  .btn_left {
    display: flex;
    gap: 4px;
    border-radius: 4px;
    border: 1px solid #d8dce6;
    background: linear-gradient(0deg, #f6f7f9 0%, #fff 100%);
    box-shadow: 0px 1px 1px 0px rgba(22, 29, 37, 0.05), 0px 2px 0px 0px rgba(255, 255, 255, 0.05) inset;
    color: #3e3f42;
    text-align: center;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }

  .btn_right {
    background-color: #7d55c7;
    border: none;
    border-radius: 4px;
    color: #ffffff;
    text-align: center;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }

  .reply-modal_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3999;

    .pledge_modal {
      background-color: #fff;
      display: flex;
      flex-direction: column;
      max-width: 445px;
      max-height: 576;
      overflow: auto;

      .pledge_modal_header {
        padding: 30px;
        display: flex;
        justify-content: space-between;
        h2 {
          color: #3e3f42;
          font-family: Roboto;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: 30px; /* 150% */
        }
      }

      .pledge_modal_one {
        padding: 30px;
        display: flex;
        flex-direction: column;
        gap: 30px;

        h3 {
          color: #3e3f42;
          font-family: Roboto;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px; /* 122.222% */
        }
        p {
          color: #3e3f42;
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px; /* 157.143% */
        }

        .inputField_div {
          display: flex;
          gap: 10px;
          flex-direction: column;

          label {
            color: #9ea0a5;
            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px; /* 150% */
            text-transform: uppercase;
          }

          input {
            border: 1px solid #9ea0a5;
          }
        }
      }
    }
  }
}
