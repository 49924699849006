.collapseHeader {
  padding: 0 30px;
  width: 100%;
  max-width: calc(100vw - 260px);
  z-index: 0;
  position: relative;
  .ant-collapse {
    background: transparent !important;
    .ant-collapse-item {
      border-radius: 4px !important;
      border: 1px solid #eaedf3 !important;
      background: #fff !important;
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.04) !important;

      .ant-collapse-header {
        border-bottom: 1px solid #7b6d6d1c;
        align-items: center;
      }
      .ant-collapse-content {
        .ant-collapse-content-box {
          padding: 20px 0;
          .btn_box {
            display: flex;
            gap: 20px;
            align-items: center;
            justify-content: center;
          }

          .ant-table-wrapper {
            .ant-table {
              .ant-table-container {
                overflow: auto;
                .ant-table-content {
                  .ant-table-thead {
                    tr {
                      text-wrap: nowrap;
                      th {
                        background: #fff !important;
                      }
                    }
                  }
                  .ant-table-tbody {
                    .ant-table-row {
                      text-wrap: nowrap;
                      .ant-table-cell {
                        .task_container {
                          display: flex;
                          align-items: center;
                          gap: 25px;

                          .task_info {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            gap: 15px;

                            > div {
                              display: flex;
                              align-items: center;
                              justify-content: center;
                              gap: 8px;
                            }
                          }
                        }

                        .assignedTo_container {
                          display: flex;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.toDo_table_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .header_box {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .right_grp {
    display: flex;
  }
}
.btn_box {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;

  button {
    background: #fff;
    border: 1px solid #d8dce6;
    color: #3e3f42;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
    border-radius: 5px;
    padding: 12px;
    max-height: 38px;
    text-wrap: nowrap;
    &:hover {
      background: #7d55c7b5;
      color: #fff;

      img {
        filter: brightness(3.5);
      }
    }

    display: flex;
    align-items: center;
    gap: 10px;
  }
}

.assign_container,
.edit_container {
  display: flex;
  align-items: center;
  button {
    background: none;
    border: 0;
  }
}

.setting_btn {
  border: 0 !important;
}

.profile_images_Todolist {
  width: 250px;
}
