.newBudgetTable_container {
  overflow: auto;
  max-width: 81vw;
  .newBudgetTable_wrapper {
    width: 100% !important;
    overflow: auto !important;
    .ant-table-wrapper {
      margin-top: 20px;
      border: 1px solid #eaedf3;
      border-radius: 5px;
      box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
      width: 100%;
      max-width: 100%;
      overflow: auto;
      .ant-table {
      }

      .ant-table-content {
        display: flex;
        align-items: center;
        gap: 18px;
        overflow: auto;
        max-width: 100vw;
        .ant-table-thead {
          tr {
            background: transparent !important;
            th {
              background: transparent !important;
              color: #000;
              text-wrap: nowrap;
            }
          }
        }
        tr {
          td {
            text-wrap: nowrap;
          }
        }
      }
      .ant-pagination {
        max-width: 100%;
      }
    }
  }
}

.table_filters_grp {
  display: flex;
  align-items: center;
  gap: 26px;
}

// Filter data

.newBudgetTable_container {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
}

.newBudgetTable_wrapper {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.table_filters_grp {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
}

.selector {
  flex: 1;
  min-width: 150px;
}

.account_Selector {
  flex: 0 1 200px;
}

.bookType_Selector {
  flex: 1;
}

.month_Selector,
.year_Selector {
  flex: 1;
  min-width: 150px;
}

.ant-table-wrapper {
  margin-top: 20px;
}

.ant-table {
  border-radius: 8px;
  overflow: hidden;
}

.ant-table-thead > tr > th {
  background-color: #f1f1f1;
  color: #333;
  font-weight: bold;
}

.ant-table-tbody > tr > td {
  padding: 8px 16px;
}

.ant-table-pagination {
  justify-content: flex-end;
}

.genearte_data {
  background: aqua;
  float: right;
  margin-top: 10px;
  margin-bottom: 10px;
}

.loading_indicator {
  height: 80vh;
  width: 100%;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.generate_data {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.NewBudgetTable_Button {
  padding: 0;
  margin: 0;
  height: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  border: none;
  color: white;
  width: 150px;
  border-radius: 4px;
  border: 1px solid rgba(125, 85, 199, 0);
  background: var(--Primary-Purple-500, #7d55c7);
  box-shadow: 0px 2px 0px 0px rgba(255, 255, 255, 0.06) inset, 0px 1px 1px 0px rgba(19, 31, 21, 0.1);
  margin-top: 20px;
  margin-bottom: 20px;
}
.generate_data h1 {
  font-family: Roboto;
  color: #3e3f42;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  font-weight: 500px;
  font-size: 12px;
  padding-bottom: 14px;
  color: #3e3f42;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  margin-left: 11px;
  font-weight: 500;
  line-height: 24px;
}

.button_Budget_Table {
  display: flex;
  align-items: center;
  gap: 20px;
}

// upload

.newBudgetTable_container {
  padding: 16px;
  background-color: #f5f5f5;
}

.newBudgetTable_wrapper {
  margin-top: 16px;
}

.generate_data {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.button_Budget_Table {
  display: flex;
  gap: 16px;
}

.table_filters_grp {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  gap: 16px;
}

.upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.MuiDialogTitle-root {
  // background-color: #f3f3f3;
  color: black;
  font-weight: bold;
  padding: 16px;
}

.MuiDialogContent-root {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

.MuiDialogActions-root {
  padding: 16px;
  display: flex;
  justify-content: flex-end;
}

.MuiButton-contained {
  background-color: #ffffff;
  color: black;
}

.MuiButton-contained:hover {
  background-color: #303f9f;
}

.upload-container input[type="file"] {
  display: none;
}

.upload-container label {
  width: 100%;
}

.upload-container button {
  width: 100%;
}

.MuiTypography-body2 {
  margin-bottom: 16px;
}

.NewBudgetTable-card {
  border-radius: 10px;

  width: 600px;
  height: 260px;
  background-color: #ffffff;
  padding: 10px 30px 40px;
  margin-top: 10px;
}

.NewBudgetTable-card h3 {
  font-size: 22px;
  font-weight: 600;
}

.NewBudgetTable-drop_box {
  margin: 10px 0;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 3px dotted #a3a3a3;
  border-radius: 5px;
  width: 100%;
}

.NewBudgetTable-drop_box h4 {
  font-size: 16px;
  font-weight: 400;
  color: #2e2e2e;
}

.NewBudgetTable-drop_box p {
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: 12px;
  color: #a3a3a3;
}

.NewBudgetTable-btn {
  text-decoration: none;
  background-color: #005af0;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  outline: none;
  transition: 0.3s;
}

.NewBudgetTable-btn:hover {
  text-decoration: none;
  background-color: #ffffff;
  color: #005af0;
  padding: 10px 20px;
  border: none;
  outline: 1px solid #010101;
}
.form input {
  margin: 10px 0;
  width: 100%;
  background-color: #e2e2e2;
  border: none;
  outline: none;
  padding: 12px 20px;
  border-radius: 4px;
}
