.edit-asset-container {
  background-color: #fbfbfd;
  .asset-header {
    position: sticky;
    top: 76px;
    display: grid;
    width: 100%;
    grid-template-columns: 70% 1fr;
    column-gap: 40px;
    background-color: #ffffff;
    z-index: 10;
    // display: flex;
    // justify-content: flex-end;
    align-items: center;
    border-bottom: 1px solid #eaedf3;
    padding: 0 24px;
    height: 73px;
    box-shadow: 5px 5px 9px rgba(0, 0, 0, 0);
    -webkit-box-shadow: 5px 5px 9px rgba(0, 0, 0, 0);
    -moz-box-shadow: 5px 5px 9px rgba(0, 0, 0, 0);

    h1 {
      font-family: Roboto;
      font-weight: 500;
      font-size: 20px;
      color: #3e3f42;
      line-height: 30px;
    }

    .header-buttons {
      display: flex;
      column-gap: 20px;

      a {
        text-decoration: none;
        font-family: Roboto;
        font-weight: 400;
        font-size: 14px;
        color: #3e3f42;
        padding: 10px 20px;
        border: 1px solid #d8dce6;
        border-radius: 5px;
        opacity: 1;
        transition: all 0.3s;

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }

  .asset-container {
    display: grid;
    width: 100%;
    grid-template-columns: 70% 1fr;
    column-gap: 40px;
    padding: 2% 24px 0% 24px;

    .side-container {
    }

    .main-container {
      margin-top: 15px;
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 40px;
    }
  }
}

.sidecard-container {
  position: fixed;
  top: 200px;
  //   height: calc(100vh - 90px);
  height: 552px;
  background-color: #fff;
  width: 350px;

  .sidecard-header {
    padding: 25px;
    h2 {
      font-family: Roboto;
      font-weight: 500;
      font-size: 16px;
      color: #3e3f42;
    }
    border-bottom: 1px solid #eaedf3;
  }
  .sidecard-content {
    height: 381px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0px;
    row-gap: 20px;
    img {
      width: 330px;
      height: 140px;
      border-radius: 5px;
    }
    h4 {
      width: 90%;
      text-align: right;
      font-family: Roboto;
      font-weight: 500;
      font-size: 18px;
      color: #3e3f42;
    }
    .property-details {
      display: grid;
      grid-template-columns: 30% 1fr;
      column-gap: 20px;
      row-gap: 10px;
      text-align: left;
      width: 85%;

      h6 {
        font-family: Roboto;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #9ea0a5;
      }
      p {
        font-family: Roboto;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #3e3f42;
      }

      h4 {
        font-family: Roboto;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        color: #3e3f42;
        margin-top: 10px;
        text-align: justify;
      }
    }
  }
}

.EditAssetInfo-card-container {
  background-color: #fff;
  // width: 730px;
  width: 100%;
  // height: 164px;
  border-radius: 3px;
  padding: 0px;
  border: 1px solid #eaedf3;

  .card-header {
    padding: 25px;
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    justify-content: flex-start;
    border-bottom: 1px solid #eaedf3;
    img {
      width: 20px;
      height: 20px;
    }
    .card-heading {
      font-family: Roboto;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: #9ea0a5;
    }
  }

  .card-content {
    padding: 10px 25px;

    .asset-name {
      font-family: Roboto;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: #3e3f42;
    }

    .description {
      width: 100%;

      textarea {
        width: 100%;
        outline: none;
        border: 1px solid #e2e5ed;
        border-radius: 5px;
        resize: none;
        padding: 10px 0px;
      }
      ::placeholder {
        font-family: Roboto;
        font-weight: 400;
        font-size: 14px;
        color: #9ea0a5;
        line-height: 22px;
      }
    }

    .price-input-container {
      position: relative;
      display: inline-block;
      width: 320px;
      height: 38px;

      input[type="text"] {
        padding-left: 10px;
        padding-right: 30px;
        width: 100%;
        height: 40px;
        border: 1px solid #e4e8ee;
        border-radius: 5px;
        box-sizing: border-box;
        font-size: 16px;
        text-align: right;
        outline: none;

        &::placeholder {
          color: #9ea0a5;
        }
        &:focus::placeholder {
          color: transparent;
        }
      }

      .currency-symbol {
        font-family: Roboto;
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 14px;
        color: #9ea0a5;
        font-weight: 400;
      }
    }

    .contacts-container {
      display: flex;
      flex-direction: column;
      row-gap: 5px;
      // align-items: center;
      width: 100%;
      h2 {
        font-family: Roboto;
        font-weight: 500;
        font-size: 14px;
        color: #3e3f42;
        text-align: center;
      }
      .additional-contact-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h2 {
          margin: 0;
          flex: 1;
          text-align: center;
        }
        img {
          width: 20px;
          height: 20px;
          cursor: pointer;
        }
      }

      .contact-button {
        display: flex;
        column-gap: 5px;
        padding: 10px 15px;
        width: fit-content;
        background-image: linear-gradient(to top, #f7f8fa, #fefefe);
        align-items: center;
        border: 1px solid #e2e5ed;
        border-radius: 5px;
        column-gap: 8px;
        margin: auto;
        img {
          width: 22px;
          height: 22px;
        }

        h4 {
          font-family: Roboto;
          color: #3e3f42;
          font-weight: 400;
          font-size: 14px;
        }
      }
    }
    .website-container {
      font-family: Roboto;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: #3e3f42;
    }
  }

  .photo-container {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    width: 100%;
    .photo-card-grid {
      display: grid;
      grid-template-columns: 48% 48%;
      gap: 30px;

      .photos-card {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        padding: 20px 15px;
        border: 1px solid #eaedf3;
        border-radius: 8px;
        .img {
          border-radius: 2px; /* Rounded corners */
          height: 100%; /* Take up full height of container */
          width: auto; /* Maintain aspect ratio */
          max-width: 100%; /* Prevent image from exceeding container width */
        }

        .controls-wrapper {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          img {
            width: 20px;
            height: 20px;
            cursor: pointer;
          }
        }
        .media-description {
          width: 100%;
          label {
            font-family: Roboto;
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            color: #9ea0a5;
            text-transform: uppercase;
            margin-bottom: 5px;
          }
          textarea {
            width: 100%;
            outline: none;
            border: 1px solid #e2e5ed;
            border-radius: 5px;
            resize: none;
            padding: 10px 0px;
          }
          ::placeholder {
            font-family: Roboto;
            font-weight: 400;
            font-size: 14px;
            color: #9ea0a5;
            line-height: 22px;
          }
        }
      }
    }
    h2 {
      font-family: Roboto;
      font-weight: 500;
      font-size: 12px;
      color: #9ea0a5;
      line-height: 18px;
      text-align: left;
    }
    .upload-media {
      width: 100%;
    }
    .link-input-wrapper {
      p {
        font-family: Roboto;
        font-weight: 400;
        font-size: 14px;
        color: #9ea0a5;
        line-height: 22px;
      }
    }
  }

  .marketing-container {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    width: 100%;
  }

  .property-details-container {
    width: 100%;
    .property-tabs {
      display: flex;
      flex-direction: row;
      column-gap: 20px;
      border-bottom: 1px solid #eaedf3;

      a {
        font-family: Roboto;
        color: #6b6c6f;
        font-weight: 400;
        font-size: 16px;
        padding-bottom: 18px;

        &.active {
          border-bottom: 2px solid #7d55c7;
          transition: all 0.3;
        }
      }
    }
    .property-tab-content {
      padding: 20px 0px;
    }
  }

  .utility-details-container {
    width: 100%;
    .utility-tabs {
      display: flex;
      flex-direction: row;
      column-gap: 20px;
      border-bottom: 1px solid #eaedf3;

      a {
        font-family: Roboto;
        color: #6b6c6f;
        font-weight: 400;
        font-size: 16px;
        padding-bottom: 18px;

        &.active {
          border-bottom: 2px solid #7d55c7;
          transition: all 0.3;
        }
      }
    }
    .utility-tab-content {
      padding: 20px 0px;
    }
  }

  .manager-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 20px;

    h2 {
      font-family: Roboto;
      color: #9ea0a5;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      text-transform: uppercase;
    }

    .manager-details {
      display: flex;
      justify-content: space-between;

      .manager-profile {
        display: flex;
        column-gap: 20px;
        align-items: center;
        img {
          width: 60px;
          height: 60px;
        }

        .manager-name {
          h4 {
            font-family: Roboto;
            color: #3e3f42;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
          }
          p {
            font-family: Roboto;
            color: #9ea0a5;
            font-weight: 400;
            font-size: 12px;
            line-height: 22px;
          }
        }
      }

      .manager-edit-button {
        margin: 20px 0px;
        display: flex;
        column-gap: 5px;
        padding: 10px 15px;
        width: fit-content;
        background-image: linear-gradient(to top, #f7f8fa, #fefefe);
        align-items: center;
        border: 1px solid #e2e5ed;
        border-radius: 5px;
        column-gap: 8px;
        img {
          width: 22px;
          height: 22px;
        }

        h4 {
          font-family: Roboto;
          color: #3e3f42;
          font-weight: 400;
          font-size: 14px;
        }
      }
    }

    .edit-manager {
      display: flex;
      flex-direction: column;
      row-gap: 10px;

      .edit-input-wrapper {
        display: grid;
        grid-template-columns: 75% 1fr;
        width: 100%;
        gap: 20px;
        align-items: center;
        input[type="text"] {
          height: 38px;
          border: 1px solid #e2e5ed;
          border-radius: 5px;
          outline: none;
          padding-left: 10px;
        }
        ::placeholder {
          font-family: Roboto;
          color: #9ea0a5;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
        }
        .btn-group {
          display: flex;
          column-gap: 20px;

          .edit-input-button {
            display: flex;
            padding: 10px 15px;
            width: fit-content;
            min-width: 66px;
            align-items: center;
            border: 1px solid #e2e5ed;
            border-radius: 5px;
            column-gap: 8px;

            h4 {
              font-family: Roboto;
              color: #3e3f42;
              font-weight: 400;
              font-size: 14px;
              margin: auto;
            }
          }
        }
      }

      p {
        font-family: Roboto;
        color: #9ea0a5;
        font-weight: 400;
        font-size: 14px;
      }
    }

    .add-collaborator-wrapper {
      display: grid;
      grid-template-columns: 60% 28% 10%;

      input[type="text"] {
        height: 38px;
        border: 1px solid #e2e5ed;
        border-radius: 5px;
        outline: none;
        padding-left: 10px;
      }
      ::placeholder {
        font-family: Roboto;
        color: #9ea0a5;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
      }
      .edit-input-button {
        margin-left: 20px;
        display: flex;
        padding: 10px 15px;
        width: fit-content;
        min-width: 66px;
        align-items: center;
        border: 1px solid #e2e5ed;
        border-radius: 5px;
        column-gap: 8px;

        h4 {
          font-family: Roboto;
          color: #3e3f42;
          font-weight: 400;
          font-size: 14px;
          margin: auto;
        }
      }
    }
    .add-collaborator-info {
      font-family: Roboto;
      color: #9ea0a5;
      font-weight: 400;
      font-size: 14px;
      max-width: 90% !important;
    }
    .member-permission-container {
      height: 450px;
      overflow-y: scroll;
      .member-permission-card {
        display: grid;
        grid-template-columns: repeat(8, 1fr);
        grid-template-rows: repeat(5, 1fr);
        grid-column-gap: 0px;
        grid-row-gap: 0px;

        .grid-1 {
          grid-area: 1 / 1 / 2 / 5;
        }
        .grid-2 {
          grid-area: 2 / 2 / 4 / 5;
        }
        .grid-3 {
          grid-area: 4 / 2 / 5 / 5;
        }
        .grid-4 {
          grid-area: 1 / 5 / 5 / 9;
        }
        .grid-5 {
          grid-area: 5 / 1 / 6 / 9;
        }

        .grid-5:not(:last-child) .line {
          margin: 20px 0px;
        }
        .grid-5:last-child .line {
          margin-top: 20px;
        }

        .member-detail {
          display: flex;
          column-gap: 20px;
          align-items: center;
          img {
            height: 38px;
            width: 38px;
            border-radius: 100%;
          }
          .member-name {
            h6 {
              font-family: Roboto;
              color: #3e3f42;
              font-weight: 500;
              font-size: 14px;
              line-height: 22px;
            }
            p {
              font-family: Roboto;
              color: #9ea0a5;
              font-weight: 400;
              font-size: 12px;
              line-height: 12px;
            }
          }
        }

        .member-chat {
          display: flex;
          flex-direction: column;
          justify-content: center;
          .start-chat-button {
            margin: 20px 0px;
            display: flex;
            column-gap: 5px;
            padding: 10px 15px;
            width: fit-content;
            background-image: linear-gradient(to top, #f7f8fa, #fefefe);
            align-items: center;
            border: 1px solid #e2e5ed;
            border-radius: 5px;
            column-gap: 8px;
            img {
              width: 22px;
              height: 22px;
            }

            h4 {
              font-family: Roboto;
              color: #3e3f42;
              font-weight: 400;
              font-size: 14px;
            }
          }
        }
        .remove-wrapper {
          display: flex;
          column-gap: 8px;
          align-items: center;
          cursor: pointer;

          img {
            width: 20px;
            height: 20px;
          }
          h6 {
            font-family: Roboto;
            color: #cb333b;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
          }
        }
        .permission-options {
          display: flex;
          flex-direction: column;
          .permission-module-header {
            display: flex;
            column-gap: 10px;
            align-items: center;
            p {
              font-weight: 500;
            }
          }
          .permission-submodules {
            padding-left: 10px;
            display: flex;
            flex-direction: column;
          }
        }
      }
    }
  }

  .tags-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 10px;

    .assign-input-wrapper {
      display: grid;
      grid-template-columns: 80% 1fr;
      width: 100%;
      gap: 20px;
      align-items: center;
      input[type="text"] {
        height: 38px;
        border: 1px solid #e2e5ed;
        border-radius: 5px;
        outline: none;
        padding-left: 10px;
      }
      ::placeholder {
        font-family: Roboto;
        color: #9ea0a5;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
      }

      .edit-input-button {
        display: flex;
        padding: 10px 15px;
        width: fit-content;
        min-width: 66px;
        align-items: center;
        border: 1px solid #e2e5ed;
        border-radius: 5px;
        column-gap: 8px;

        h4 {
          font-family: Roboto;
          color: #3e3f42;
          font-weight: 400;
          font-size: 14px;
          margin: auto;
        }
      }
    }
    .tags-list {
      display: flex;
      column-gap: 20px;

      .tag {
        display: flex;
        column-gap: 5px;
        align-items: center;
        background-color: #fbfbfd;
        border: 1px solid #eaedf3;
        border-radius: 5px;
        padding: 5px;

        h6 {
          font-family: Roboto;
          color: #3e3f42;
          font-weight: 400;
          font-size: 12px;
        }
        button {
          font-family: sans-serif;
          font-size: 14px;
          border: none;
          outline: none;
          background-color: transparent;
          color: #bcbcbc;
          cursor: pointer;
        }
      }
    }
  }
}

.property-info-container {
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  .info-row {
    display: grid;
    grid-template-columns: 32% 32% 32%;
    width: 100%;
    gap: 20px;

    .dropdown {
      p {
        font-family: Roboto;
        font-weight: 500;
        font-size: 12px;
        color: #9ea0a5;
        margin-bottom: 10px;
      }
    }
  }
}

.property-plan-container {
}

.property-documents-container {
}

.utility-arrangement-container {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  .arrangement-table-header {
    display: grid;
    grid-template-columns: 22% 22% 22% 22% 1fr;
    border-bottom: 1px solid #eaecee;

    h4 {
      font-family: Roboto;
      font-weight: 500;
      font-size: 12px;
      color: #9ea0a5;
      line-height: 18px;
      margin-bottom: 5px;
    }
  }
  .EditAssetInfo-arrangement-table-content {
    // display: grid;
    grid-template-columns: 22% 22% 22% 22% 1fr;
    align-items: center;
    border-bottom: 1px solid #eaecee;
    .EditAssetInfo-data {
      p {
        color: #3E3F42;
        leading-trim: both;
        text-edge: cap;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px; /* 157.143% */
      }
    }
    .flex-EditAssetInfo {
      display: flex;
      justify-content: space-between;
    }
    img {
      justify-self: end;
      margin-right: 10px;
    }
  }
  .utility-button {
    margin: 20px 0px;
    display: flex;
    column-gap: 5px;
    padding: 10px 15px;
    width: fit-content;
    background-image: linear-gradient(to top, #f7f8fa, #fefefe);
    align-items: center;
    border: 1px solid #e2e5ed;
    border-radius: 5px;
    column-gap: 8px;
    img {
      width: 22px;
      height: 22px;
    }

    h4 {
      font-family: Roboto;
      color: #3e3f42;
      font-weight: 400;
      font-size: 14px;
    }
  }
}
.line {
  background-color: #eaedf3;
  height: 0px;
  border: solid 1px #eaedf3;
  width: 100%;
}

.input-with-image {
  p {
    max-width: 100% !important;
  }
}
