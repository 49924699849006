.transaction_wrapper {
  .transaction_overview_wrapper {
    padding: 30px;
  }
  .row1_section {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 20px;

    .infobox {
      padding-right: 20px;
      border-right: 1px solid #eaedf3;

      &:last-child {
        border-right: 0;
      }

      .infobox_header {
        color: #9ea0a5;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px; /* 150% */
        text-transform: uppercase;
        text-wrap: nowrap;
      }
      .infobox_value {
        color: var(--LP-black, #3e3f42);
        font-family: Roboto;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        display: flex;
        flex-direction: column;
        span {
          color: #9ea0a5;
          font-family: Roboto;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px; /* 183.333% */
        }
      }
    }
  }

  .row2_section {
    margin-top: 30px;
    border-radius: 4px;
    border: 1px solid #eaedf3;
    background: #fff;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.04);
  }
}

.receivement_status_cell {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
  align-items: center;
  .status_wrapper {
    .status_box {
      display: flex;
      padding: 3px 10px;
      align-items: center;
      border-radius: 4px;
      background: transparent;
      color: #fff !important;
      text-align: center;
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px; /* 150% */
      text-transform: uppercase;
      &.not_received {
        background: var(--LP-gray, #9ea0a5);
      }

      &.received {
        background: var(--56-c-568-success-positive-green, #56c568);
      }
    }
  }
}

.capitalCall_table_wrapper {
  padding: 30px;

  .table_heading {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 25px;
    border-radius: 4px;
    border: 1px solid #eaedf3;
    background: #fff;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.04);

    p {
      color: #3e3f42;
      font-family: Roboto;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px; /* 155.556% */
    }

    .table_heading_right {
      display: flex;
      gap: 30px;
      align-items: center;
    }
  }

  .main_table {
    border-radius: 4px;
    background: #fff;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.04);
  }
  .main_table .table_container .table .table_tbody .tbody_row {
    height: 40px !important;
  }
}

.contribution_table_wrapper {
  padding: 30px;
  .table_heading {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 25px;
    border-radius: 4px;
    border: 1px solid #eaedf3;
    background: #fff;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.04);

    p {
      color: #3e3f42;
      font-family: Roboto;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px; /* 155.556% */
    }

    .table_heading_right {
      display: flex;
      gap: 30px;
      align-items: center;
    }
  }
  .main_table {
    border-radius: 4px;
    background: #fff;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.04);
    .table_container {
      .table {
        .table_head {
          .head_row {
            th:last-child {
              text-align: right;
            }
          }
        }
        .table_tbody {
          .tbody_row {
            height: 40px !important;
            td:last-child {
              text-align: right;
            }
          }
        }
        .total_row {
          height: 40px !important;
          td:last-child {
            text-align: right;
          }
        }
      }
    }
  }
}
.ownership_table_wrapper {
  padding: 30px;
  display: grid;
  grid-template-columns: 60% 40%;
  .table_heading {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 25px;
    border-radius: 4px;
    border: 1px solid #eaedf3;
    background: #fff;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.04);

    p {
      color: #3e3f42;
      font-family: Roboto;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px; /* 155.556% */
    }

    .table_heading_right {
      display: flex;
      gap: 30px;
      align-items: center;
    }
  }
  .main_table {
    border-radius: 4px;
    background: #fff;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.04);
    .table_container {
      .table {
        .table_head {
          .head_row {
            th:last-child {
              text-align: right;
            }
          }
          .header_Cell {
            display: flex;
            justify-content: flex-end;
            p {
              padding: 3px 10px;
              color: var(--ffffff-white, #fff);
              text-align: right;
              font-family: Roboto;
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: 18px; /* 150% */
              text-transform: uppercase;
              width: fit-content;
              border-radius: 3px;
              background: var(--blue-pantone-3015-c, #00629b);
            }
          }
        }
        .table_tbody {
          .tbody_row {
            height: 40px !important;
            div {
              text-align: left;
              display: flex;
              align-items: center;
              gap: 10px;
            }
            td:last-child {
              text-align: right;
            }
            td:first-child {
              width: 35%;
            }
          }
        }
        .total_row {
          height: 40px !important;
          td:last-child {
            text-align: right;
          }
        }
      }
    }
  }
}
