.Container-CompanyDocument {
  border: 1px solid #eaedf3;
}

.Container-CompanyDocument-box {
  padding: 20px;
  border: 1px solid #eaedf3;
  display: flex;
  flex-direction: column;

  .Container-CompanyDocument-box-data {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 10px;
    border: 1px solid #eaedf3;

    h1 {
      color: var(--primary-gray-100-text, #9ea0a5);
      font-family: Roboto;
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
      text-transform: uppercase;
      flex: 1;
    }
  }

  .Container-CompanyDocument-secondbox {
    border-radius: 4px 4px 0px 0px;
    border: 0px solid var(--Primary-Purple-50, #eaedf3);
    background: rgba(125, 85, 199, 0.08);
    box-shadow: 0px 1px 0px 0px #eaedf3;
    padding: 20px;
    margin-top: 10px;
    display: flex;
    justify-content: center;

    .Container-CompanyDocument-secondbox-data {
      text-align: center;
      flex: 1;
      h1 {
        color: var(--Primary-Purple-500, #7d55c7);
        font-family: Roboto;
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        text-transform: uppercase;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .Container-CompanyDocument-box-data {
    flex-direction: column;

    h1 {
      flex-basis: auto;
      flex-grow: 1;
    }
  }

  .Container-CompanyDocument-secondbox {
    .Container-CompanyDocument-secondbox-data {
      flex: none;
    }
  }
}
