.organisation {
    border: 1px solid #EAEDF3;
    filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.04));

    .inputs {
        padding: 20px;
    }

    .title {
        color: #3E3F42;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 500;
        line-height: 28px;
    }

    .add_btn {
        display: flex;
        align-items: center;
        gap: 10px;
        color: var(--LP-mid-gray, #6B6C6F);
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        margin: 20px 0;
    }
}