.newOfferings_container {
  margin-left: 10px;
  padding: 30px;
  box-sizing: border-box;
  .newOfferings_wrapper {
    .newOfferings_content {
      .main_content_box {
        .notifi_container {
          border-radius: 4px;
          border: 1px solid #eaedf3;
          background: #fff;
          box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.04);
          display: flex;
          flex-direction: column;
          max-width: calc(100vw - 340px);
          overflow: auto;
          > div {
            border-bottom: 1px solid #eaedf3;
          }

          .offer_notifi {
            padding: 30px;
            height: 100%;
            max-height: 74px;
            display: flex;
            align-items: center;
            cursor: pointer;
            h2 {
              color: #3e3f42;
              font-family: Roboto;
              font-size: 18px;
              font-style: normal;
              font-weight: 500;
              line-height: 28px; /* 155.556% */
            }
          }

          .no_offering_container {
            .no_offering_wrapper {
              .no_offering_content {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 17px;
                padding: 35px 0;

                .sectionA {
                  width: 100%;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;

                  h2 {
                    color: var(--LP-gray, #9ea0a5);
                    text-align: center;
                    font-family: Roboto;
                    font-size: 64px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 80px; /* 125% */
                  }
                  p {
                    color: #9ea0a5;
                    text-align: center;
                    font-family: Roboto;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 18px; /* 150% */
                    text-transform: uppercase;
                  }
                }

                .sectionB {
                  width: 100%;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                  gap: 15px;
                  p {
                    color: #6b6c6f;
                    font-family: Roboto;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 22px; /* 157.143% */
                  }

                  button {
                    color: #fff;
                    text-align: center;
                    font-family: Roboto;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 22px; /* 157.143% */
                    height: 38px;
                    display: inline-flex;
                    padding: 8px 20px;
                    align-items: center;
                    gap: 10px;
                    border-radius: 4px;
                    border: 1px solid var(--purple-pantone-2665-c-71, rgba(125, 85, 199, 0.71));
                    background: linear-gradient(0deg, rgba(125, 85, 199, 0.3) 0%, rgba(255, 255, 255, 0) 107.78%), #7d55c7;
                    background-blend-mode: multiply, normal;
                    box-shadow: 0px 2px 0px 0px rgba(255, 255, 255, 0.06) inset, 0px 1px 1px 0px rgba(19, 31, 21, 0.1);
                  }
                }
              }
            }
          }

          .notifi_header_wrapper {
            padding: 30px;
            height: 100%;
            max-height: 43px;
            display: flex;
            align-items: center;
            .notifi_header {
              width: 100%;
              display: grid;
              grid-template-columns: 39% auto 39%;

              h4 {
                text-align: left;
                color: #9ea0a5;
                font-family: Roboto;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 18px; /* 150% */
                text-transform: uppercase;
              }
            }
          }

          .notifi_wrapper {
            .information_detail {
              display: grid;
              grid-template-columns: 39% auto auto;
              padding: 12px 30px;

              .groupA {
                display: flex;
                flex-direction: row;
                gap: 15px;
                align-items: center;
                .investor_address {
                  display: flex;
                  flex-direction: row;
                  gap: 24px;
                  width: 100%;
                  img {
                    width: 66px;
                    height: 66px;
                  }

                  .address_detail {
                    h3 {
                      color: #3e3f42;
                      font-family: Roboto;
                      font-size: 16px;
                      font-style: normal;
                      font-weight: 500;
                      line-height: 24px; /* 150% */
                    }

                    p {
                      color: #9ea0a5;
                      font-family: Roboto;
                      font-size: 12px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 18px; /* 150% */
                    }

                    .unfinished_tag {
                      color: var(--LP-white-_lib, #fff);
                      font-family: Roboto;
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 22px; /* 157.143% */
                      display: inline-flex;
                      padding: 4px 12px;
                      align-items: center;
                      gap: 12px;
                      border-radius: 4px;
                      border: 0px solid var(--Orange, #ed962d);
                      background: var(--Orange, #ed962d);
                    }
                  }
                }
              }

              .groupB {
                display: flex;
                flex-direction: row;
                gap: 24px;
                align-items: center;

                .investor_personal_info {
                  width: 100%;
                  h4 {
                    color: #3e3f42;
                    font-family: Roboto;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 24px; /* 171.429% */
                  }
                  p {
                    color: #6b6c6f;
                    font-family: Roboto;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 22px; /* 157.143% */
                  }
                }
              }

              .action_btns {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 15px;
                position: relative;
                justify-content: center;
                .btn_container {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  gap: 15px;

                  button {
                    display: flex;
                    padding: 8px 16px 8px 11px;
                    align-items: center;
                    gap: 10px;
                    height: 38px;
                    border-radius: 4px;
                    border: 1px solid #d8dce6;
                    background: linear-gradient(0deg, #f6f7f9 0%, #fff 100%);
                    box-shadow: 0px 2px 0px 0px rgba(255, 255, 255, 0.05) inset, 0px 1px 1px 0px rgba(22, 29, 37, 0.05);
                    text-wrap: nowrap;
                    width: fit-content;
                    color: #3e3f42;
                    text-align: center;
                    font-family: Roboto;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 22px; /* 157.143% */
                    &.see_details {
                    }
                    &.proceed_continue {
                      background: #7d55c7;
                      color: #fff;
                      text-align: center;
                      font-family: Roboto;
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 22px; /* 157.143% */
                    }
                  }
                }
                .isStarted_tag {
                  position: absolute;
                  bottom: -5px;
                  width: 100%;
                  text-align: center;
                  left: 8px;
                  color: var(--LP-mid-grey-_lib, #6b6c6f);
                  text-align: center;
                  font-family: Roboto;
                  font-size: 12px;
                  font-style: italic;
                  font-weight: 400;
                  line-height: 22px; /* 183.333% */
                }
              }
            }

            .more_information_detail {
              padding: 15px 60px;
              display: flex;
              flex-direction: column;
              gap: 20px;
              .caption {
                color: var(--LP-mid-gray, #6b6c6f);
                text-align: left;
                font-family: Roboto;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px; /* 157.143% */
              }
              .more_info_box {
                display: grid;
                grid-template-columns: repeat(6, 1fr);
                column-gap: 19px;
                row-gap: 22px;

                .detail_box {
                  display: flex;
                  flex-direction: column;
                  text-wrap: nowrap;

                  label {
                    color: #9ea0a5;
                    font-family: Roboto;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 18px; /* 150% */
                    text-transform: uppercase;
                  }

                  value {
                    color: var(--LP-black, #3e3f42);
                    font-family: Roboto;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 140%; /* 19.6px */
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
