.TenderOffers-Conatiner {
  border: 1px solid #eaedf3;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.15);
  background: rgba(125, 85, 199, 0.08);
}
.TenderOffers-Conatiner-data {
  margin: 10px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
}
.TenderOffers-Data {
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.15);
}
.TenderOffers-container-image {
  display: flex;
  gap: 20px;
}
.TenderOffers-container-image-data {
  display: flex;
  gap: 20px;
}
.TenderOffers-Data {
  border: 1px solid #eaedf3;
  padding: 10px;
  width: 419px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.TenderOffers-Data p {
  width: 50%;
}

// Dropdown
.TenderOffers-wrapper {
  .TenderOffers {
    border: 1px solid #eaedf3;
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.15);
    .TenderOffers-accordion {
      cursor: pointer;
      background-color: white;
      width: 610px;
      padding: 15px;
      padding-top: 20px;
      padding-bottom: 20px;
      border: none;
      text-align: left;
      outline: none;
      leading-trim: both;
      text-edge: cap;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 26px; /* 162.5% */
      i {
        float: right;
      }

      &:hover {
      }

      &.active {
        i {
          transform: rotate(180deg);
  width: 610px;
        }
      }
    }

    .TenderOffers-pannel {
      padding: 0 15px;
      display: none;
      overflow: hidden;
      transition: max-height 0.2s ease-out;

      &.active {
          display: block;
        display: block;
        width: 610px;
        padding: 20px;
        color: var(--Primary-Gray-200, #9ea0a5);
        leading-trim: both;
        text-edge: cap;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 26px; /* 185.714% */      }

      p {
        margin-top: 0;
      }
    }
  }
}


// flex-data
.TenderOffers-container {
  display: flex;
}
.TenderOffers-container-data {
  display: flex;
}
.linetag {
  height: 5px;
  background-color: #7d55c7;
}
.TenderOffers-flex {
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: center;
}
.TenderOffers-flex p {
  color: var(--Black, #3e3f42);
  leading-trim: both;
  text-edge: cap;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}
.TenderOffers-Data button {
  display: flex;
  width: 375px;
  height: 38px;
  padding: 0px 146px 0px 134px;
  align-items: center;
  border: none;
  color: white;
  font-size: 14px;
  flex-shrink: 0;
  border-radius: 4px;
  background: var(--Primary-Purple-500, #7d55c7);
}

.TenderOffers-image {
  background-color: #efebf8;
  width: 100px;
  border-radius: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.TenderOffers-divconatiner {
  justify-content: center;
  display: flex;
  align-items: center;
  height: 150px;
}

.TenderOffers-Tender {
  padding: 0;
  margin: 0;
  height: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  border: none;
  color: black;
  border-radius: 4px;
  background: rgba(125, 85, 199, 0.07);
}
.TenderOffers-Add {
  padding: 0;
  margin: 0;
  height: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  border: none;
  width: 100px;
  float: right;
  color: blue;
  background-color: white;
  border-radius: 4px;
  border: 1px solid var(--Primary-Purple-50, #eaedf3);
  background: #fff;
}


@media (max-width: 1008px) {
  .OSBSTaxBrack-container-image {
    flex-direction: column;
  }

  .OSBSTaxBrack-Data {
    width: 80%; /* Adjust width for smaller screens */
  }

  .OSBSTaxBrack-Data p {
    width: 100%; /* Adjust width for smaller screens */
  }

  /* Add more media queries as needed */
}



@media screen and (max-width: 1200px) {
  .OSBSTaxBrack-Data {
    width: 100%;
  }
}
