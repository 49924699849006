.share-classes-container {
  padding: 20px;


  .share-classes-header {
    text-align: center;
    margin-bottom: 20px;

    h1 {
      font-size: 24px;
      color: #333;
    }
  }

  .share-classes-actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 20px;
    padding: 10px;
    a {
      color: #7d55c7;
      text-decoration: underline;
      margin-right: 20px;
    }

    .select-wrapper {
      margin-right: 20px;
      padding: 10px;
      align-items: center;
      justify-content: center;
      display: flex;
      width: 100px;
      select {
        padding: 5px;
        // border: 1px solid #ced4da;
        border-radius: 4px;
      }
    }
  }

  .share-classes-table {
    display: flex;
    flex-direction: column;
    border: 1px solid #eaedf3;
    border-radius: 8px;
    overflow: hidden;

    .share-classes-headers {
      display: flex;
      border-bottom: 1px solid #ced4da;

      .header-cell {
        flex: 1;
        padding: 10px;
        text-align: center;

        h2 {
          color: var(--primary-gray-100-text, #9EA0A5);
          leading-trim: both;
          text-edge: cap;
          font-family: Roboto;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 18px; /* 150% */
          text-transform: uppercase;
        }
      }
    }

    .share-classes-rows {
      .share-classes-row {
        display: flex;
        &:nth-child(even) {
          border: 1px solid #eaedf3;
        }

        .row-cell {
          flex: 1;
          padding: 10px;
          text-align: center;

          span {
            font-size: 14px;
            color: #212529;
          }
        }
      }
    }
  }
}

.ShareClasses-Filter {
  padding: 0;
  margin: 0;
  height: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  border: none;
  width: 150px;
  color: white;
  background-color: white;
  border-radius: 4px;
  background: var(--Primary-Purple-500, #7D55C7);
}
