.tostify_alert_container {
  position: absolute;
  top: 0;
  right: 0;

  .tostify_alert_wrapper {
    display: flex;
    width: 100%;
    min-width: 280px;
    padding: 20px;
    flex-direction: column;
    align-items: center;
    border-radius: 5.728px;
    border: 1px solid #8dc63f;
    background: #fff;
    box-shadow: 0px 2.148px 7.16px 0px rgba(0, 0, 0, 0.15);

    .crossBtn {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      background: none;
      border: 0;
      outline: 0;
      cursor: pointer;
      margin-bottom: 5px;
    }

    .tostify_alert_header {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 5px;
      .success_image_withText {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        h4 {
          color: #8dc63f;
          font-family: Roboto;
          font-size: 30px;
          font-style: normal;
          font-weight: 500;
          line-height: 119%; /* 35.7px */
        }
      }

      p {
        color: #7d7f84;
        text-align: center;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 119%; /* 14.28px */
        width: 100%;
      }
    }

    .tostify_alert_body {
      margin-top: 20px;
      margin-bottom: 15px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      .dead_line_div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        > p,
        span {
          color: var(--Black, #3e3f42);
          text-align: center;
          font-family: Roboto;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 119%; /* 14.28px */
        }
      }
    }

    .viewBtn {
      display: flex;
      height: 34px;
      padding: 10px 20px;
      justify-content: center;
      align-items: center;
      gap: 6px;
      border-radius: 10px;
      background: #7d55c7;
      width: 100%;
      max-width: 130px;
      color: #fff;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-wrap: nowrap;
      outline: 0;
      border: 0;
    }
  }
}

.Toastify__toast-theme--colored.Toastify__toast--success{
  background: none !important;
}

.Toastify__toast{
  --y: 0;
  position: relative;
  touch-action: none;
  min-height: var(--toastify-toast-min-height);
  box-sizing: border-box;
  margin-bottom: 1rem;
  padding: 8px;
  border-radius: var(--toastify-toast-bd-radius);
  box-shadow: none;
  display: flex;
  justify-content: space-between;
  max-height: var(--toastify-toast-max-height);
  font-family: var(--toastify-font-family);
  cursor: default;
  direction: ltr;
  z-index: 0;
  overflow: visible;
}

// error -

.Toastify__toast--error {
  background: none;
}

/* Custom Styling for OpenAI Error Toast */
.openai_error_container {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 9999; /* Ensure it is above other elements */
  width: 320px;
  animation: fadeIn 0.3s ease-in-out;

  .openai_error_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-radius: 10px;
    border: 1px solid #f5c6cb; /* Light red border */
    background: linear-gradient(120deg, #f8d7da, #f1b0b7); /* Gradient for a modern look */
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);

    .crossBtn {
      position: absolute;
      top: 10px;
      right: 10px;
      background: none;
      border: 0;
      cursor: pointer;

      svg {
        width: 18px;
        height: 18px;
        fill: #721c24; /* Dark red for contrast */
        transition: transform 0.2s;

        &:hover {
          transform: scale(1.2);
        }
      }
    }

    .openai_error_header {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      margin-bottom: 15px;

      .error_image_withText {
        display: flex;
        align-items: center;
        gap: 10px;

        img {
          width: 40px;
          height: 40px;
        }

        h4 {
          color: #721c24; /* Dark red text */
          font-family: "Roboto", sans-serif;
          font-size: 24px;
          font-weight: 600;
          margin: 0;
        }
      }

      p {
        color: #721c24;
        font-family: "Roboto", sans-serif;
        font-size: 14px;
        margin-top: 8px;
        line-height: 1.5;
      }
    }

    .openai_error_body {
      width: 100%;
      margin-top: 10px;

      .retryBtn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 10px;
        background: #dc3545; /* Danger red */
        color: #fff;
        border: none;
        border-radius: 5px;
        font-size: 14px;
        font-family: "Roboto", sans-serif;
        cursor: pointer;
        transition: background-color 0.3s;

        &:hover {
          background: #c82333; /* Darker red for hover effect */
        }
      }
    }
  }
}

/* Add fade-in animation for better UX */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
