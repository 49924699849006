.investment_center_wrapper {
  margin-top: 30px;
}
.tab-container {
  display: flex;
  border-bottom: 2px solid #d3d3d3;
  margin-bottom: 20px;
  width: 100%;
  margin-top: 20px;
  justify-content: center;
}

.tab {
  padding: 10px 20px;
  cursor: pointer;
  border-bottom: 3px solid transparent;
  transition: all 0.3s ease;
}

.tab.active {
  border-bottom: 3px solid #8a2be2;
  color: #8a2be2;
}

// .tab-content {
//   padding: 20px;
// }
