.reviews {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
    .search_bar {
        margin-top: -5px;
        position: relative;
        width: 200px;
        height: 38px;
        border: 2px solid #eaedf3;
        border-radius: 5px;
        display: flex;
        align-items: center;

        input {
            all: unset;
            width: 180px;
            padding-right: 25px;
            padding-left: 2px;
            color: #9EA0A5;
            font-family: Roboto;
            font-size: 14px;
            font-weight: 400;
            line-height: 22px
        }

        svg {
            position: absolute;
            right: 5px;
        }
    }
    .left {
        width: 455px;
        padding: 20px;

        .left_header {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            height: 50px;
        }

        hr {
            border: 1px solid #EAEDF3;
        }

   

        .ratings {
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            gap: 10px;
            margin-bottom: 20px;

            p {
                color: #3E3F42;
                font-family: Roboto;
                font-size: 14px;
                font-weight: 400;
                line-height: 22px
            }

            .ratings_row {
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 20px;

                div {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                }

                span {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }

        .review_wrapper {
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            gap: 40px;

            .review_text {
                padding-bottom: 20px;

                .text2 {
                    color: #6B6C6F;
                    font-family: Roboto;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 22px
                }

            }
        }

        .review_box {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 10px;
            border-bottom: 1px solid #EAEDF3;
        }

        .review_box_upper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 20px;
            width: 100%;

            .review_box_left {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 10px;

                .text1 {
                    color: #9EA0A5;
                    font-family: Roboto;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 18px
                }

                .name {
                    color: #3E3F42;
                    font-family: Roboto;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 24px
                }



                img {
                    height: 38px;
                    width: 38px;
                    border-radius: 100%;
                }
            }

            .review_box_right {
                display: flex;
                justify-content: center;
                align-items: center;

            }

        }
    }

    .right {
        width: 255px;
        border: 1px solid #EAEDF3;

        .right_upper {
            div {
                display: flex;
                align-items: center;
                gap: 0px;
                padding: 0 20px;
                height: 60px;
                border-bottom: 1px solid #EAEDF3;

                p {
                    color: #3E3F42;
                    font-family: Roboto;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 22px
                }
            }
        }

        .right_lower {
            padding: 30px;

            span {
                color: #9EA0A5;
                font-family: Roboto;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                text-transform: uppercase;
                margin-bottom: 20px;
            }
            >div{
                margin-top: 10px;
            }
        }
    }
}